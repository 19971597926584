import React, { useContext, useEffect, useState } from 'react'
// import React from 'react'

import moment from "moment"

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, makeStyles } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import Cookies from 'js-cookie';



import { useQuery } from '@apollo/react-hooks';
import ImageComponent from '../../component/component-image/ImageComponent'

import GpsOffsGrey from '../../Image/gps_off_grey.svg'
import PositionIcon from "../../Image/place_one.svg"
import SpeedIcon from '../../Image/black_speed.svg'
import MapGreenIcon from "../../Image/map_green.svg"
import GpsGrey from '../../Image/gps_grey.svg'
import GeolocationGrey from '../../Image/geolocation_grey.svg'
import AssetIcon from "../../Image/asset.svg"
import GreySearchIcon from '../../Image/grey_search.svg'

import MoveIcon from "../../Image/move.svg"
import StopIcon from "../../Image/stop.svg"
import IdleIcon from "../../Image/device-idle.svg"
import TowingIcon from '../../Image/towing.svg'
import LocationNotFoundIcon from "../../Image/default.svg"
import MotionStateIcon from "../../Image/motion_state_dark.svg"
import ClockIcon from "../../Image/clock_dark.svg"

import dvcBattHigh from "../../Image/battery-high.svg"
import dvcBattMed from "../../Image/battery-medium.svg"
import dvcBattLow from "../../Image/battery-low.svg"
import dvcBattUnk from "../../Image/battery-unknown.svg";
import dvcBattCharge from "../../Image/charging.svg"
import dvcBattDebug from '../../Image/dark_debugging.svg'
import dvcbattDark from "../../Image/battery_dark.svg"

import BatteryHigh from '../../Image/green-battery.svg'
import BatteryMedium from '../../Image/yellow-battery.svg'
import BatteryLow from '../../Image/red-battery.svg'
import BatteryUnknownIcon from '../../Image/grey-battery.svg'
import BatteryDark from '../../Image/black-battery.svg'
import BatteryDebugging from '../../Image/green-debugging.svg'


import FuelHighIcon from '../../Image/green-fuel.svg'
import FuelMediumIcon from '../../Image/yellow-fuel.svg'
import FuelLowIcon from '../../Image/red-fuel.svg'
import FuelDarkIcon from '../../Image/black-fuel.svg'




import Loaders from "../../component/component-loaders/Loaders"
import { APIGetSearchAssetnAssetGroup } from '../../config/config-restAPI/getAPICalled';
import { isEmptyObject } from '../../config/config-function/function';
import menuConfig from "../../config/config-menu/menu";
import { WebsocketContext } from "../../config/config-websocket/Websocket";
import OnHoverScrollContainer from "../../component/component-onHoverScroll/CustomScrollDiv"
import SnackBar from '../../config/config-snackbar/SnackBar'



const autoComplete = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            clearIndicator: {
                // position: "absolute",
                // bottom: "5px",
                // right: "25px"
                "&:hover":{
                    backgroundColor: "#FFFFFF",
                }
            },
            endAdornment:{
                top: "0px",
                height: "100%",
                display: "flex",
                alignItems: "center"
            },
            popupIndicator:{
                "&:hover":{
                    backgroundColor: "#FFFFFF",
                }
            }      
        },
        MuiIconButton:{
            label:{
                height:"24px",
                width: "24px",
                "&:hover":{
                    backgroundColor: "rgba(0,0,0,0.03)",
                    borderRadius: "5px",
                },
            }
        }
    }
});

const useStyles = makeStyles((theme) => ({
    textField: {
        backgroundColor: "#FFFFFF",
        width: 240,
        marginLeft: 0,
        outline: "none"
    },
    autocomplete: {
        backgroundColor: "#FFFFFF",
        width: 276,
        underline: { "&&&:before": { borderBottom: "none" }, "&&:after": { borderBottom: "none" } }
    },

}))



const TableTelemetric = ({ data, loading, searchValue, setSearchValue, error, setFilter, fx_changePage, filter, history}) => {

    // console.log("filter", filter)
    const classes = useStyles();
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');
    //this state for error handling on getQuery
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")
    const [loadings, setLoading] = useState(false); // apiCalled loading
    const [assetFlat, setAssetFlat] = useState([]); // list search for desktop (searchAssetNGroup)
    const filterObject = Object.keys(filter).length > 0 ? filter.name : ""

    // console.log(filterTest)

    const payloadContext = useContext(WebsocketContext);
    const { selectAsset, selected_asset } = payloadContext;

    const APICalled = async (searchType, searchKey) => {
        try {
            const result = await APIGetSearchAssetnAssetGroup(token, setLoading, agencyId, searchType, searchKey, false)
            if (result.data.status === 200) {
                const assetList = result.data.data.assets.length > 0 ? result.data.data.assets.map((x) => {
                    return x && x.name ? {
                        firstLetter: x.name.charAt(0).toUpperCase(),
                        pairId: x.pairId,
                        uuid: x.uuid,
                        name: x.name,
                        paired_assets: x && x.pairedAssets.length > 0 ? x.pairedAssets.map((x) => { return { uuid: x.uuid, name: x.name }}) : [],
                        type: 'asset',
                    } : { firstLetter: "", uuid: "", name: "", pairId: "", paired_assets: [], type: "asset" }
                }) : []
                setAssetFlat(assetList);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch(err) {
            setLoading(false);
        }
        
    }

    // console.log('assetFlat', assetFlat);

    useEffect(() => {
        if(token){
            APICalled("ASSET", '')
        }
    }, [])

    const onUserInput = (e, value) => {
        // console.log(value)
        if (value) {
            setFilter({})
            setSearchValue({ pairId: value.pairId, name: value.name, uuid: value.uuid, paired_asset: value.paired_asset })
        } else {
            setFilter({})
            setSearchValue({})
        }
    }

    const viewMap = (data) => {
        if(isEmptyObject(data)){
            fx_changePage(menuConfig.dashboard[1].key, menuConfig.dashboard[1].children[1].key)
            history.push("/dashboard/live/map")
        }else{
            selectAsset({pairId :data.pairId, getAssetInfo: false}, {asset_name: data.name, pairId: data.pairId, paired_asset: data.paired_assets});
            fx_changePage(menuConfig.dashboard[1].key, menuConfig.dashboard[1].children[1].key)
            history.push("/dashboard/live/map")
        }        
    }

    useEffect(() => {
        if(error){
            setSnOpen(true)
            setMessage("Error: Unable to retrieve data. Please try again later.")
            setSeverity("error")
        }
    }, [error])

    return (

        <div className="table-container">
            <div className="title">Telemetry</div>
            <div className="title-sub">View real-time incoming telemetry for an asset</div>
            <div className="click-container">

                <ThemeProvider theme={autoComplete}>
                    <Autocomplete
                        {...assetFlat}
                        name="asset_name"
                        id="combo-box-demo"
                        loading={true}
                        autoHighlight={true}
                        onChange={(e, value) => onUserInput(e, value)}
                        options={assetFlat.length > 0 ? assetFlat.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
                        groupBy={(option) => option.firstLetter}
                        getOptionLabel={(option) => option.name}
                        style={{ width: 342 }}
                        value={filterObject ? {name: filter.name, pairId: filter.pairId} : {name: searchValue.name, pairId: searchValue.pairId}} //this is for shortcut and 
                        className={classes.autocomplete}
                        renderOption={option => {
                            // console.log(option)
                            return <div className="autocomplete-container">
                                <img loading="lazy" style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} src={AssetIcon} alt="asset-icon" />
                                <div className="autocomplete-name">{option.name}</div>
                                { option && option.paired_assets && option.paired_assets.length > 0 ?
                                   option.name !== option.paired_assets[0].name ? <div className="autocomplete-pair-name"> <div className="paired-symbol">↹</div> {option.paired_assets[0].name}</div> : null  : null
                                }
                            </div>
                        }}
                        renderInput={(params) => 
                            <div style={{position: "relative"}} ref={params.InputProps.ref}>
                                {
        
                                   !params.inputProps.value && (
                                    <span 
                                        style={{
                                            position: "absolute",
                                            transform: "translate(1%, 90%)",
                                            marginLeft: "5px"
                                        }}
                                        >
                                    <img loading="lazy" style={{ height: "20px", width: "20px" }} src={GreySearchIcon} alt="grey-icon" />
                                </span>
                                    )
                                }
                                <TextField 
                                   {...params} 
                                   name="asset_name" 
                                   placeholder="Asset" 
                                   variant="outlined" 
                                   inputProps= {!params.inputProps.value ? {
                                    ...params.inputProps,
                                    style: { paddingLeft: "27px", position: "relative", top:"1px" } 
                                 }: {
                                     ...params.inputProps
                                 }}
                                />
                            </div>                         
                        }
                    />
                </ThemeProvider>
                <div className="box-map" style={{ cursor: "pointer" }} onClick={() => viewMap(searchValue)}>
                    <img src={MapGreenIcon} alt="icon" />
                    <div className="box-map-text">View Live Map</div>
                </div>

            </div>
               <Table data={data} loading={loading} searchValue={searchValue} error={error} setSnOpen={setSnOpen}  snOpen={snOpen} message={message} severity={severity}/>


            <style jsx>
                {
                    `
                    .table-container{
                        position: relative;
                        // top: 20px;
                        margin: 24px;
                    }
                    .title{
                        margin: 0px 0px 8px 0px;
                        color: #333333;
                        font-size: 24px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 32px;
                    }
                    .title-sub{
                        margin: 0px 0px 8px 0px;
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                    .box-shadow{
                        // box-shadow: 0px 2px 4px 0px #00000050;
                    }
                    .click-container{
                        display: flex;
                        // justify-content: space-between;
                        align-itmes: center;   
                    }
                    .autocomplete-name{
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 130px;
                    }
                    .autocomplete-pair-name{
                        display: flex;
                        align-items: center;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 150px;
                    }
                    .box-map{
                        margin: 0px 0px 0px 8px;
                        display: flex;
                        align-items: center;
                    }
                    .box-map-text{
                        margin: 0px 0px 0px 8px;
                        color: #509D91;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                    .bootstrap-container{
                        margin: 8px 0px 0px 0px;
                        // position: relative;
                        // top: 25px;
                        // height: 75vh;
                        // background-color: #ffffff;
                        // overflow-y: scroll;
                        // // overflow-x: scroll;
                    }
                    .header-classes{
                        color: #333333;
                    }
                    .wrapper-classes{
                        border-bottom-style: hidden !important;
                        overflow-y: scroll;
                    }
                    .autocomplete-container{
                        display: flex;
                        align-items: center;
                    }
                    .option-name{
                        margin: 0px 4px 0px 0px;
                    }
                    .option-paired{
                        margin: 0px 0px 0px 4px;
                    }
                    .paired-symbol{
                        color: #F5A623;
                        font-size: 14px;
                        margin: 0px 8px 0px 0px;
                    }
                    `
                }
            </style>
        </div>
    )
}

const Table = ({ searchValue, loading, data, setSnOpen,snOpen, message, severity, error}) => {

    const setBatteryStatus = (voltage) => {
        return voltage ? voltage >= 4.0 ? "high" : voltage >= 3.7 ? "medium" : voltage <= 3.6 && "low" : "unknown"
    }
    const convertTimestamp = (timestamp) => {
        const ctimestamp = moment(timestamp).format("X");
        const actualData = moment.unix(ctimestamp).format('DD/MM/YYYY hh:mm:ss a');
        return actualData;
    }

    const setGeoLocationStatus = (geolocation) => {
        switch (geolocation) {
            case 0:
                return "GPS" 
            case 16:
                return "Wifi"
            case 17:
                return "NoWifi"
            default:
                return "Garbage"
        }
    };

    const setMotionInference = (motion) => {
        switch (motion) {
            case 100 :
                return "Stop" 
            case 200 :
                return "Moving"
            case 400 :
                return "Idle"
            case 500 :
                return "In Tow"
            default:
                return "Undetermined"
        }
    }

    const settingBatteryIcon = (status) => {
        switch (status) {
            case "high":
                return <ImageComponent src={BatteryHigh} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} /> 
            case "medium":
                return <ImageComponent src={BatteryMedium} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
            case "low":
                return <ImageComponent src={BatteryLow} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} /> 
            case "unknown":
                return <ImageComponent src={BatteryUnknownIcon} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
            default:
                break;
        }
    }

    const dvcBatteryIcon = (status) => {
        switch (status) {
            case "high":
                return <ImageComponent src={dvcBattHigh} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} /> 
            case "medium":
                return <ImageComponent src={dvcBattMed} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
            case "low":
                return <ImageComponent src={dvcBattLow} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} /> 
            case "Undetermined":
                return <ImageComponent src={dvcBattUnk} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
            default:
                break;
        }
    }

    const motionInferenceIcon = (motion) => {
        switch (motion) {
            case 100 :
                return StopIcon 
            case 200 :
                return MoveIcon
            case 400 :
                return IdleIcon
            case 500 :
                return TowingIcon
            default:
                return LocationNotFoundIcon
        }
    }

    return (
        <div>
            <div className="header-table">
                <div className="text-header">
                    <ImageComponent src={ClockIcon} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
                    <div className="text-payload">Date/Time</div>
                </div>
                <div className="text-header-geolocation">
                    <ImageComponent src={PositionIcon} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
                    <div className="text-payload">Geolocation</div>
                </div>
                <div className="text-header">
                    <ImageComponent src={MotionStateIcon} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
                    <div className="text-payload">Motion State</div>
                </div>
                <div className="text-header">
                    <ImageComponent src={SpeedIcon} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
                    <div className="text-payload">Speed (km/h)</div>
                </div>
                <div className="text-header">
                    <ImageComponent src={BatteryDark} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
                    <div className="text-payload">Asset Battery (volts)</div>
                </div>
                <div className="text-header">
                    <ImageComponent src={dvcbattDark} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
                    <div className="text-payload">Device Battery (volts)</div>
                </div>
                <div className="text-header">
                    <ImageComponent src={FuelDarkIcon} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
                    <div className="text-payload">Fuel (litres)</div>
                </div>
                <div className="text-header-last-column">
                    <div className="text-payload">Connectivity</div>
                </div>
            </div>
            <OnHoverScrollContainer>
            <div className="white-box">
                {
                    loading ? (<div style={{ position: "relative", right: "2px", top: "40px" }}><Loaders /></div>) : error ? ( <SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/>) : isEmptyObject(searchValue) ? (<div></div>) : !loading && data.map(x => {
                        return (
                            <div className="card-table">

                                <div className="card-body">
                                    <div className="date-time-asset">{convertTimestamp(x.timestamp)}</div>
                                </div>
                                <div className="card-body">
                                    <div className="card-asset-row">
                                        {x.address ? x.address.localDisplay : ""}
                                        {x.latitude && x.longitude ? <a href={`https://www.google.com/maps/search/?api=1&query=${x.latitude},${x.longitude}`} rel="noopener noreferrer" target="_blank">{`${parseFloat(x.latitude).toFixed(6)},${parseFloat(x.longitude).toFixed(6)}`}</a> : ""}
                                        <div className="geolocation-subtitle">
                                            {x.geolocation ? <img src={setGeoLocationStatus(x.geolocationStatus).toLowerCase() === "gps" ? GpsGrey : setGeoLocationStatus(x.geolocationStatus).toLowerCase() === "wifi" ? GeolocationGrey : setGeoLocationStatus(x.geolocationStatus).toLowerCase() === "nowifi" ? GpsOffsGrey : setGeoLocationStatus(x.geolocationStatus).toLowerCase() === "garbage" ? GpsOffsGrey : GpsOffsGrey} style={{ height: "14px", width: "14px" }} alt="icon" className="img-icon-geolocation" /> : null}
                                            <div className="text-payload">{x.geolocation ? setGeoLocationStatus(x.geolocationStatus).toLowerCase() === "gps" ? "GPS" : setGeoLocationStatus(x.geolocationStatus).toLowerCase() === "wifi" ? "Wifi" : setGeoLocationStatus(x.geolocationStatus).toLowerCase() === "nowifi" ? "No Location Service" : setGeoLocationStatus(x.geolocationStatus).toLowerCase() === "garbage" ? "No Location Service" : "No Location Service" : null}
                                        </div></div>                                          
                                    </div>
                                </div>
                                <div className="card-body">
                                    {x.motionInference ? <img src={motionInferenceIcon(x.motionInference)} style={{ height: "16px", width: "16px" }} alt="icon" className="img-icon" /> : null}
                                    {/* <div className="text-payload">{x.motionInference ? setMotionInference(x.motionInference).toLowerCase() === "moving" ? "Moving" : setMotionInference(x.motionInference).toLowerCase() === "stop" ? "Stop" : setMotionInference(x.motionInference).toLowerCase() === "idle" ? "Idle" : "Unknown" : ""}</div> */}
                                    <div className="text-payload">{x.motionInference ? setMotionInference(x.motionInference) : "-"}</div>
                                </div>
                                <div className="card-body">
                                    <div className="text-payload">{x.kmh ? x.kmh.toFixed(2) : ""}</div>          
                                </div>
                                <div className="card-body">
                                    {x.dcVoltPerc || x.dcVolt ?  settingBatteryIcon(setBatteryStatus(parseInt(x.dcVoltPerc))) : null}
                                    <div className="text-payload-status">{x.dcVolt ? x.dcVolt.toFixed(2) : ""}</div>
                                </div>
                                <div className="card-body">
                                    {x.devSuppState || x.battVoltPerc ? x.devSuppState === 100 ? <ImageComponent src={dvcBattCharge} alt="dvc-bat-chg" style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} onClick={false}/>  : x.devSuppState === 200 && x.devSuppState === 300 ? dvcBatteryIcon(setBatteryStatus(parseInt(x.battVoltPerc))) : x.devSuppState === 400 ?  <ImageComponent src={dvcBattDebug} alt="dvc-bat-chg" style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} onClick={false}/>  : dvcBatteryIcon(setBatteryStatus(parseInt(x.battVoltPerc))) : null}
                                    <div className="text-payload-status">{x.battVolt ? x.battVolt.toFixed(2) : ""}</div>
                                </div>
                                <div className="card-body">
                                    {x ? x.fuelTankPerc ? <img src={x.fuelTankPerc >= 70 ? FuelHighIcon : x.fuelTankPerc >= 30 ? FuelMediumIcon : x.fuelTankPerc <= 29 ? FuelLowIcon : FuelLowIcon} style={{ height: "16px", width: "16px" }} alt="icon" className="img-icon" /> : null : null}
                                    <div className="text-payload">{x ? x.fuelVolume ? parseFloat(x.fuelVolume).toFixed(2) : "" : ""}</div>     
                                </div>
                                <div className="card-body">
                                    <div className="text-payload">{x.connClass ? x.connClass[0].toUpperCase() + x.connClass.substring(1) : ""}</div>
                                </div>
                            </div>
                        )
                    })
                }


            </div>
            </OnHoverScrollContainer>
            <style jsx global>
                {
                    `
                    .header-table{
                        margin: 8px 0px 8px 0px;
                        border-radius: 4px 4px 0 0;
                        background-color: #FFFFFF;
                        display: grid;
                        grid-template-columns: 1fr 1.5fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". . . . . . . ";
                        min-height: 64px;
                        max-height: 70;
                    }
                    .white-box::-webkit-scrollbar {
                        display: none;
                    }
                    .white-box{
                        height: 66vh;
                        background-color: #FFF;
                        overflow: auto;
                        scrollbarwidth: none;
                        msoverflowstyle: none;
                    }
                    .text-header-last-column{
                        display: flex;
                        align-items: center;
                        padding: 8px 8px 8px 12px;
                        color: #333333;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .card-table{
                        border-radius: 4px 4px 0 0;
                        background-color: #FFFFFF;
                        display: grid;
                        grid-template-columns: 1fr 1.5fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". . . . . . . ";
                    }
                    .card-body{
                        border-bottom: 1px solid #DDDDDD;
                        display: flex;
                        align-items: center;
                        padding: 8px 0px 8px 12px;
                        color: #333333;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .img-icon{
                        margin: 0px 4px 0px 0px;
                    }
                    .img-icon-geolocation{
                        position: relative;
                        top: 3px;
                        margin: 0px 4px 0px 0px;
                    }
                    .text-payload{
                        position: relative;
                        top: 2px;
                    }
                    .text-header{
                        display: flex;
                        align-items: center;
                        padding: 8px 0px 8px 12px;
                        color: #333333;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .text-header-geolocation{
                        display: flex;
                        align-items: center;
                        padding: 8px 0px 8px 12px;
                        color: #333333;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 19px;
                        max-width: 360px;
                        min-width: 20%;
                    }
                    .place-button{
                        position: relative;
                        bottom:2px;
                        // width: 16px;
                        // height: auto;
                    }
                    .wrapper{
                        word-wrap: break-word
                    } 
                    .card-asset-row{
                        display: flex;
                        flex-direction: column;
                        color: #424242;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .date-time-asset{
                        color: #424242;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .geolocation-subtitle{
                        display: flex;
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 19px;
                        letter-spacing: 0;
                        line-height: 19px;
                    }                   
                    `
                }
            </style>
        </div>

    )
}
export default TableTelemetric
