import React, {useState, useRef, useEffect} from 'react'
import {DrawPolygonMode} from "react-map-gl-draw";

//library

import * as turf from '@turf/turf'
import Cookies from 'js-cookie';

//material ui
import {TextField, createMuiTheme} from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";

//image
import BackButton from "../../Image/back.svg";



import { APIGetSearchAssetnAssetGroup } from '../../config/config-restAPI/getAPICalled';
import { APICreateGeofence } from '../../config/config-restAPI/postAPICalled';
import {getFitboundPoint} from '../../component/component-fitbound/Fitbound'
// import NotificationList from "./NotifcationList"
import AssetListAdd from "./AssetListAdd"
import AssetSearchAdd from "./AssetSearchAdd"
import SnackBar from '../../config/config-snackbar/SnackBar'
import GeofenceAreaAddCard from './GeofenceAreaAddCard'
import CommunicationAddCard from './CommunicationAddCard'

const useStyles = makeStyles({ 
    underline: { 
        "&&&:before": { borderBottom: "none" }, "&&:after": { borderBottom: "none" } 
    },
    input:{
        '&::placeholder': {
            color: '#3A3A3A'
        }
    }

});

const materialTheme = createMuiTheme({
    overrides : {
        MuiInputBase: {
            root: {
                fontSize: "18px",
                color: "#3A3A3A",
            }
            
        },
    }
})

const data = [
    {id:1, value:"Acme Transit PIC", communication:"telegram"},
    {id:2, value:"Driver Mike Tyson", communication:"telegram"},
    {id:3, value:"Fleet Management Admin", communication:"telegram"},
    {id:4, value:"Ice Cube Sdn Bhd Person In Charge", communication:"telegram"},
]



/**
 * Search Component
 * GeoAssetSearch -> the search component
 *      ||
 * GeoListCard     -> the search list component and onClick list
 */


/**
 * Selected List Compoenent
 * GeoAssetList -> display the selected list component and onclick remove function
 */


const DrawPolygon = {id: "drawPolygon", text: "Draw Polygon", handler: DrawPolygonMode}

const AddForm = ({fx_back, modeId, setModeId, setIsHoveredO, addGeofence, APICalled, setAddGeofence, setModeHandler, selectedFeatureIndex, setSelectedFeatureIndex, editorRef, polygons, setPolygons, setMapping, mapping, setSnOpen, setMessage, setSeverity}) => {
    const classes = useStyles()
    const [onDrawPolygon, setOnDrawPolygon] = useState(false)
    const [onDrawRadius, setOnDrawRadius] = useState(false)
    const [onkey, setOnKey] = useState("")
    const [assetGroup, setAssetGroup] = useState({})
    const [onSelected, setOnSelected] = useState([]) //onSelected for taking value from API to called the search asset API
    const [onDisplay, setOnDisplay] = useState({})
    const [listSelected, setListSelected] = useState(null)
    const isEdit = false;
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');

    const [pEnter, setPEnter] = useState(false)
    const [pLeave, setPLeave] = useState(false)
    const [polyciesN, setPolyciesN] = useState("")
    const [polygonN, setPolygonN] = useState([])
    const [loading, setLoading] = useState(false)
    const wrapperRef = useRef(null);

    //this state are for snackbar props
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("")
    const [allAssetnGroup, setAllAssetnGroup] = useState([]);

    const [notification, setNotification] = useState({
        enter: [],
        leave: [],
        smartLock: []
    })

    const [isHovered, setIsHovered] = useState({
        btnSave: false,
        btnBack: false
    })
  
    const btnPolygon = (id) => {
        const modeID = id === modeId ? null : id;
        const modeHandlers = modeID ? new DrawPolygon.handler() : null;
        setModeHandler(modeHandlers)
        setModeId(modeID)
        setOnDrawRadius(false)
        setOnDrawPolygon(modeID ? true : false)
    }


    const APISearchAssetGroupCalled = async (searchType, onkey, selectedConvert) => {
        try {
            const result = await APIGetSearchAssetnAssetGroup(token, setLoading, agencyId, searchType, onkey, selectedConvert)
            if (result.data.status === 200) {
                const assetList = result.data.data.assets.length > 0 ? result.data.data.assets.map((x) => {
                    return x && x.name ? {
                        firstLetter: x.name.charAt(0).toUpperCase(),
                        pairId: x.pairId,
                        uuid: x.uuid,
                        name: x.name,
                        paired_assets: x && x.pairedAssets.length > 0 ? x.pairedAssets.map((x) => { return { uuid: x.uuid, name: x.name }}) : [],
                        type: 'asset',
                    } : { firstLetter: "", uuid: "", name: "", pairId: "", paired_assets: [], type: "asset" }
                }) : []
                const assetGroupList = result.data.data.groups.length > 0 ? result.data.data.groups.map((x) => {
                    return x && x.name ? {
                        firstLetter: x.name.charAt(0).toUpperCase(), 
                        uuid: x.uuid, 
                        pairId: x.uuid, 
                        name: x.name,
                        type: 'group',
                        paired_asset: [] } : {
                            firstLetter: "", uuid: "", name: "", description: "", type: "group",paired_assets: []
                        }
                    }) : []
                const searchOptions = {
                    options: assetList.length > 0 || assetGroupList.length > 0 ? assetList.concat(assetGroupList) : []
                }

                const flatAssetIds = searchOptions.options.flat(2)
                setAllAssetnGroup(flatAssetIds);
                setListSelected(result.data.data.selected ? result.data.data.selected : null)
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch(err) {
            setLoading(false);
        }
        
    }

    useEffect(() => {
        if (token) {
            APISearchAssetGroupCalled('ALL', '', '');
        }
    }, [])

    const onDelete = (index) => {
        const cloneJson = [...polygons]
        if(index !== null && index >= 0){
          editorRef.deleteFeatures(index)
          cloneJson.splice(index, 1)
          setPolygons(cloneJson)
          setPolygonN([])
        }

    }

    const onChangeText = (e, i) => {
        let test = [...polygons]
        test[i].name = e.target.value
        setPolygons([...test])
    }
    
    const btnConfirm = async (e) => {
        try{
            e.preventDefault();
            const notifications = notification && {enter: notification.enter.map(x => {return {uuid: x.uuid}}), leave: notification.leave.map(x => {return {uuid: x.uuid}}), generatePin: notification.smartLock.map(x => {return {uuid: x.uuid}})}
            const reducer = { 
                geofence: {
                    name: polyciesN,
                    assets: listSelected ? listSelected.asset.length > 0 ? listSelected.asset.map((x) => x.uuid) : [] : [],
                    assetGroups: listSelected ? listSelected.group && listSelected.group.length > 0 ? listSelected.group.map((x) => x.uuid) : [] : [],
                    notification: notifications,
                    polygons: polygons
                }
            }
            const result = await APICreateGeofence(reducer, token, agencyId, setLoading);
            if (result.data.status === 200) {
                setSnOpen(true)
                setMessage("Record has been successfully added")
                setSeverity("success")
                setListSelected([]);
                setAllAssetnGroup([]);
                setPolygons([]);
                setPolyciesN("");
                setOnDisplay({});
                setAssetGroup({});
                setPEnter("");
                setPLeave("");
                APISearchAssetGroupCalled('ALL','','');
                APICalled('', '');
                fx_back();
            } else {
                setErrOpen(true)
                setErrMsg('Error, Please try again later')
                setErrSevere("error")
            }

        }catch(err){
            console.log(JSON.stringify(err, null, 2));
            setErrOpen(true)
            setErrMsg(JSON.stringify(err.message, null, 2))
            setErrSevere("error")
        }
    }

    const btnPolyList = (index, polygon) => {
        setSelectedFeatureIndex({selectedFeatureIndex: index})
        const selectedPolygon = turf.polygon(polygon.area.geometry.coordinates)
        const centralPolygons = turf.centroid(selectedPolygon)
        const fitbound = getFitboundPoint([centralPolygons])
        setMapping(fitbound)
    }


    const backButton = () => {
        setPolygons([])
        setIsHoveredO({add:false})
        fx_back()
    }
       
    return (
        <div className="add-container">
            <div className="title-back">
                <div className="input-title">
                    <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                        <img loading="lazy" src={BackButton}  alt="back-button" onClick={() => backButton()} className={isHovered.btnBack ? "back-hover-button-style" : "back-button-style"} onMouseEnter={() => setIsHovered((current) => ({...current, btnBack: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnBack: false}))}/>
                    <ThemeProvider theme={materialTheme}>
                        <TextField className="text-id" InputProps={{classes}} placeholder="Untitled" color="#3A3A3A" onChange={e => setPolyciesN(e.target.value)} fullWidth/>
                    </ThemeProvider>
                </div>
                <div className="btn-confirm" onMouseEnter={() => setIsHovered((current) => ({...current, btnSave: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnSave: false}))}>
                    <button className={`${isHovered.btnSave ? "isHovered-confirm": "confirm"}`} onClick={(e) => btnConfirm(e)}>Save</button>
                </div>
            </div>
            <GeofenceAreaAddCard polygons={polygons} wrapperRef={wrapperRef} selectedFeatureIndex={selectedFeatureIndex} btnPolyList={btnPolyList} onDelete={onDelete} onChangeText={onChangeText} btnPolygon={btnPolygon} onDrawPolygon={onDrawPolygon} modeId={modeId}/>
            <div className="assign-assets-list">
                <header className="assign-assets-text">Assign Assets</header>
                <AssetSearchAdd dataTwo={allAssetnGroup && allAssetnGroup} APICalled={APISearchAssetGroupCalled} searchLoading={loading} onkey={onkey} setOnKey={setOnKey} onSelected={onSelected} setOnSelected={setOnSelected} onDisplay={onDisplay} setOnDisplay={setOnDisplay} assetGroup={assetGroup} setAssetGroup={setAssetGroup}/>
                <AssetListAdd dataTwo={listSelected} APICalled={APISearchAssetGroupCalled} searchLoading={loading} onkey={onkey} setOnKey={setOnKey} onSelected={onSelected} setOnSelected={setOnSelected} onDisplay={onDisplay} setOnDisplay={setOnDisplay} assetGroup={assetGroup} setAssetGroup={setAssetGroup} addGeofence={addGeofence} setAddGeofence={setAddGeofence}/>
            </div>
            <CommunicationAddCard data={data} setNotification={setNotification} notification={notification} isEdit={isEdit} />
            <style jsx>
                {
                    `
                    .add-container{
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-auto-rows: minmax(min-content, max-content);
                        gap: 20px 0px;
                        grid-template-areas:
                          "."
                          "."
                          "."
                          ".";
                        background-color: #F3F3F3;
                    }
                    .input-title{
                        display: flex;
                        align-items: center;
                    }
                    .back-button{
                        cursor: pointer;
                    }
                    .text-id{
                        font-size: 14px;
                        margin: 0px 0px 0px 8px;
                    }
                    .polygon-name{
                        position: relative;
                        top: 2px;
                    }
                    .btn-confirm{
                        text-align: right;                   
                    }
                    .btn-confirm .confirm{
                        // height: 32px;
                        width: 130px;
                        color: #FFFFFF;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 17px;
                        text-align: center;
                        background-color: #509D91;
                        border-radius: 2px;
                        cursor: pointer;
                    }
                    .btn-confirm .isHovered-confirm{
                        width: 130px;
                        color: #FFFFFF;
                        font-size: 12px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 17px;
                        text-align: center;
                        background-color: #498f84;
                        border-radius: 2px;
                        cursor: pointer;
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                    }
                    .btn-confirm .disabled{
                        // height: 32px;
                        width: 130px;
                        color: #FFFFFF;
                        font-size: 12px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 17px;
                        text-align: center;
                        background-color: #509D91;
                        opacity: 0.7;
                        border-radius: 2px;
                        cursor: pointer;
                    }
                    .btn-list-area{
                        display: flex;
                        align-items: center;
                        margin: 8px 0px 0px 0px;
                    }
                    .draw-polygon{
                        display: flex;
                        margin: 0px 0px 0px 8px;
                        font-size: 13px;
                        color: #509D91;
                    }
                    .title-back{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 2fr 1fr; 
                        grid-template-rows: 1fr; 
                        gap: 0px 0px; 
                        grid-template-areas: 
                          ". ."; 
                        align-items: center;
                        justify-content: space-between;
                        padding: 20px 16px 0px 16px;
                    }
                    .btn-clear{
                        height: 16px;
                        color: #509D91;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                    .content-list{
                        cursor:pointer;
                    }
                    .content-list-area{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        height: 32px;
                    }
                    .content-list-area-polygon{
                        padding: 4px 4px 4px 4px;
                        border-style : groove none groove groove;
                        border-width: thin;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        cursor: pointer
                    }
                    .content-list-area-polygon-active{
                        padding: 4px 4px 4px 4px;
                        border-style : groove none groove groove;
                        border-width: thin;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        cursor: pointer;
                        background-color: #509D91;
                    }
                    .content-list-area-radius{
                        padding: 4px 4px 4px 4px;
                        border-style : groove;
                        border-width: thin;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        cursor:pointer;
                    }
                    .content-list-area-radius-active{
                        padding: 4px 4px 4px 4px;
                        border-style : groove;
                        border-width: thin;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        cursor:pointer;
                        background-color: #509D91;
                    }
                    .triggers-asset{
                        margin: 0px 0px 4px 0px;
                    }
                    .triggers-asset p {
                        font-size: 13px;
                        color: #333333;
                        letter-spacing: 0;
                        margin: 0px 0px 0px 8px;
                        line-height: 21px
                    }
                    .input-checkbox{
                        display: flex;
                        margin: 0px 0px 8px 0px;
                    }
                    .geofence-area-container{
                        display: flex;
                        flex-direction: column;
                        background-color: #FFF;
                        margin: 0px 16px 0px 16px;
                        padding: 16px;
                        border-radius: 4px;
                        background-color: #FFFFFF;
                    }
                    .channel-search-container{
                        
                    }
                    .geofence-area-text{
                        margin: 0px;
                        font-size: 14px;
                        font-weight : 600;
                    }
                    .assign-assets-text{
                        margin: 0px;
                        font-size: 14px;
                        font-weight : 600;
                        margin: 0px 0px 8px 0px;
                    }
                    .communication-container{
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: 30px minmax(0fr, 1fr) minmax(0fr, 1fr) minmax(0fr, 1fr);
                        gap: 0px 0px;
                        grid-template-areas:
                          "."
                          "."
                          "."
                          ".";
                        margin: 0px 16px 0px 16px;
                        background-color: #FFFFFF;
                        padding: 16px;
                        border-radius: 4px;
                    }
                    .communication-text{
                        margin: 0px;
                        font-size: 14px;
                        font-weight : 600;
                        margin: 0px 0px 8px 0px;
                    }
                    .clear-all-text{
                        margin: 0px;
                        font-size: 12px;
                        color: #509D91;
                        font-weight: 600;
                    }
                    .geofence-title{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 0px 0px 8px 0px;
                    }
                    .assign-assets-list{
                        margin: 0px 16px 0px 16px;
                        background-color: #FFFFFF;
                        padding: 16px;
                        border-radius: 4px;
                    }
                    .list-polygons{
                    }
                    .dark-polygon-img{
                    }
                    .polygon-container{
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                    }
                    .polygon-container-hover{
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        border-radius: 4px;
                    }
                    .close-icon{
                        cursor: pointer;
                        display: flex;
                    }
                    .input-text{
                        width: 100%; 
                        border-style: none;
                        outline: none;
                        padding: 0px 8px 0px 8px;
                    }
                    .input-text-highlight{
                        width: 100%;
                        border-style: none;
                        color: #509D91;
                        outline: none;
                        padding: 0px 8px 0px 8px;
                    }
                    .back-button-style{
                        cursor: pointer;
                        padding: 4px;
                    }
                    .back-hover-button-style{
                        background-color: rgba(0,0,0,0.03);
                        border-radius: 5px;
                        padding: 4px;
                        cursor: pointer;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default AddForm