import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks'
import { useMediaQuery } from 'react-responsive';
import Cookies from "js-cookie";
import axios from 'axios'
import {LOGIN } from '../../config/config-graphql/Graphql'
import {AuthContext} from '../../config/config-auth/Auth'
import {firebaseContext} from '../../config/config-firebase/FirebaseContext'
import Logo from '../../Image/horizon_logo_login.svg'
import SnackBar from '../../config/config-snackbar/SnackBar'
import sidebarPic from '../../Image/login-left-side.png'
import roleConfig from '../../config/config-role/role'
import menuConfig from '../../config/config-menu/menu'

const LoginPage = ({history}) => {
    const isDefault = useMediaQuery({ minWidth: 1025 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    const firebaseConfig = useContext(firebaseContext)
    const role = Cookies.get("role");
    const token = Cookies.get("jwtToken");
    const agencyID = Cookies.get("agency-id")
    const agencyName = Cookies.get("agency-name")
    const sessionID = Cookies.get("SessionID")
    const userId = Cookies.get("_id")
    const [messages, setMessages] = useState([]);
    const [noPrivileges, setNoPrivileges] = useState(false)


    //this state are for snackbar props
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("") 

    const settingPage = () =>{
            if(roleConfig.admin.includes(role)){
                const path = getDefaultPathBasedOnRole(role, "console") //to get the default path after login success. this go to admin page
                history.push(`/console/${path}`)
            } else if(roleConfig.dashboard.includes(role)){
                const path = getDefaultPathBasedOnRole(role, "dashboard") //to get the default path after login success. this go to dashboard bapge
                // console.log(path)
                history.push(`/dashboard/${path}`)
            } else{
                history.push("/login")
            }
    }

    // console.log( 'loginPage', {
    //     token, agencyID, role, agencyName, sessionID, userId
    // })

    useEffect(() => {
        settingPage()
    }, [token && agencyID && role && agencyName && sessionID && userId])

 
    const context = useContext(AuthContext);
    const [values, setValues] = useState({
        email: "",
        "encode-email": "",
        password: "",
        "encode-password": "",
        rememberMe: true
    });

    const onChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value, [`encode-${event.target.name}`]: context.Encode(event.target.value) });
    };
    const onSubmit = async (e) => {
        try{
            e.preventDefault();   
            await sessionLogin()
          }catch(err){
            console.log(JSON.stringify(err, null, 2))
            setErrOpen(true)
            setErrMsg(JSON.stringify(err.message, null, 2))
            setErrSevere("error")
          }
    };

    const getDefaultPathBasedOnRole = (role, page) => {
        let path = ""
        //this is to determined the default page based
        outerloop: for(let i=0; i<menuConfig[page].length; i++){    //to loop the menuConfig        
            if(menuConfig[page][i].role.includes(role)){ //check if the parent role same as cookie role and firebaseConfig value is true
                for(let j=0; j<menuConfig[page][i].children.length; j++){ //for loop for children
                    if(menuConfig[page][i].children[j].role.includes(role)){ //check if the children role same as cookie role and firebaseconfig value is true
                        path = menuConfig[page][i].children[j].path
                        
                        break outerloop;   
                    }
                }
            }
        } 

        return path
    }

    const [loginUser, { loading }] = useMutation(LOGIN, 
        {
            variables: { email: values["encode-email"], password: values["encode-password"], rememberMe: values.rememberMe }, 
        }
    );

    const sessionLogin = async () => {
        //first go to Login User mutation
        const loginResponse = await loginUser()
        
        if(loginResponse.errors){ //check if the login have error
            setErrOpen(true)
            setErrMsg(`${loginResponse.errors[0].extensions.code}: ${loginResponse.errors[0].message}`)
            setErrSevere("error")
        }else{
            const role = loginResponse.data.login && loginResponse.data.login.user.privileges[0].role.replace(/_/g," ") //this to take the fole and replace _ to ""
            if(role !== "None"){
                if(role !== "Basfare_Users" && role !== "Notifications"){
                    if(loginResponse.data.login.user.privileges.length > 1){ //if user have more than one privileges
                        history.push('/tenancy', loginResponse.data)
                    }else{
                        
                        if(loginResponse.data.login.user.privileges[0].isSuspended === true ){ //to check if is Suspended, null or not system admin
                            setErrOpen(true)
                            setErrMsg("Unable to login, agency suspended")
                            setErrSevere("error")
                        }else{                
                            if(role){
                                if(roleConfig.admin.includes(role)){ // login to admin
                                    context.login(loginResponse.data.login, "", loginResponse.data.login.user.privileges) //store the login response into cookies, in Auth context
                                    const path = getDefaultPathBasedOnRole(role, "console") //to get the default path after login success. this go to admin page
                                    history.push(`/console/${path}`)
                                }else if(roleConfig.dashboard.includes(role)){ //login to dashboard
                                    if(loginResponse.data.login.token){  //to check if the login response have token          
                                        if(role !== "Reports Viewer" && role !== "Land Transport Authority"){ //to check if the role are not report viewer and apad it will go to websocket
                                            //generate session ID
                                            const postResponse = await axios.post(process.env.REACT_APP_WEBSOCKET_LOGIN, { 
                                                token: loginResponse.data.login.token,
                                                agencyID: loginResponse.data.login.user.privileges[0].agencyId
                                            });                         
                                            // if sessionID generated it will send the data to context and redirect the page based on the role.
                                            if (postResponse.data && postResponse.data.sessionID){
                                                // console.log("go here dashboard")                        
                                                let clone = [...messages];
                                                clone.push(postResponse.data ? postResponse.data.message : "An error occured")
                                                setMessages(clone)  
                                                context.login(loginResponse.data.login, postResponse.data.sessionID, loginResponse.data.login.user.privileges, true)  //store the login response with session ID into cookies
                                                const path = getDefaultPathBasedOnRole(role, "dashboard") //to get the default path after login success. this go to dashboard bapge
                                                history.push(`/dashboard/${path}`)
                                            }
                                            else{ //else the websocket not connected (no sessionID generated)
                                                setErrOpen(true)
                                                setErrMsg(`Websocket not connected`)
                                                setErrSevere("error")
                                            }
                                        }else{ //else only context login
                                            const test = context.login(loginResponse.data.login, "", loginResponse.data.login.user.privileges)
                                            // console.log(context.user)
                                            const path = getDefaultPathBasedOnRole(role, "dashboard") //to get the default path after login success. this go to dashboard bapge
                                            history.push(`/dashboard/${path}`)
                                        }
        
                                    }else{
                                        history.push("/login") 
                                    }
                                }
                                else{
                                    history.push("/login")
                                } 
                            }
                            else {
                                setErrOpen(true)
                                setErrMsg("login unsuccesfull")
                                setErrSevere("error")
                            }        
                        }
                    }
                }else{
                    setErrOpen(true)
                    setErrMsg("Access Denied!")
                    setErrSevere("error")
                }
            }else{
                // console.log("go hre no privileges")
                //to change the message if use have no agency
                setNoPrivileges(true)
            }

        } 
    }  

    return (
        <div>
        {
            isDefault && !token && !agencyID && !role && !sessionID && !userId && (
            <section className="main">
                <div className="content-w3ls left">
                    <div className="text-pic">
                        <div className="image-placeholder">
                            <img loading="lazy" src={sidebarPic} alt="Traffic" className="img-cut"></img>
                        </div>
                        <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                        <div className="left-content">
                            <div className="text-header">
                                <p>{firebaseConfig.getValueByKey("login_header")} </p>
                            </div>
                            <div className="text-bodies">
                                <span>
                                    {firebaseConfig.getValueByKey("login_detail")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-w3ls right">
                  <div className="container">
                    <div className="container-logo">
                        <div className="text-center-logo">
                            {/* <p>{process.env.REACT_APP_ENVIRONTMENT}</p> */}
                            <span>
                                <img loading="lazy" src={Logo} alt="Horizon-logo" className="img-logo" />
                            </span>
                        </div>
                        {
                            !noPrivileges ? <div className="login-page-container">
                                <div className="header-sign">
                                    <p>Sign in to your Horizon</p>
                                </div>
                                <div>
                                <form onSubmit={(e) => onSubmit(e)} method="post">
                                    <div className="container-lable-input">
                                        <div className="txtLabel">
                                            <label>Email address</label>
                                        </div>
                                        <div className="field-group">
                                            <span className="fa fa-user" aria-hidden="true"></span>
                                            <div className="wthree-field">
                                                <input
                                                    name="email"
                                                    id="email"
                                                    type="email"
                                                    required
                                                    value={values.email}
                                                    onChange={(e) => onChange(e)}
                                                    placeholder="Username"
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-lable-input">
                                        <div className="txtLabel">
                                            <label>Password</label>
                                        </div>
                                        <div className="field-group">
                                            <span className="fa fa-lock" aria-hidden="true"></span>
                                            <div className="wthree-field">
                                                <input
                                                    name="password"
                                                    id="myInput"
                                                    type="Password"
                                                    value={values.password}
                                                    required
                                                    onChange={(e)=> onChange(e)}
                                                    placeholder="Password"
                                                ></input>
                                            </div>
                                        </div>
                                    </div>                                
                                    <div className="checklayout">
                                        <div className="check-box-container">
                                                <input type="checkbox" id="check" checked={values.rememberMe} onChange={() => setValues(prev=> ({...prev, rememberMe: !prev.rememberMe}))}></input>                                    
                                            <span className="span-me">Remember Me</span>
                                        </div>
                                        <div>
                                            <div onClick={() => history.push("/forgot_password")}>
                                            <a
                                                className="text-right"
                                                style={{ textDecoration: "none", fontSize: "14px", color: "#939598", cursor: "pointer"}}
                                            >
                                                Forgot your password?
                                            </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="wthree-fields">
                                    <button type="submit" className="btn">
                                        {loading ? "Loading..." : "Sign In"}
                                    </button>
                                    <p className="versioning">{firebaseConfig.getValueByKey("horizon_version")}</p>
                                    </div>
                                </form>
                            </div>
                            </div> : <div className="no-privileges-access">
                                <div className="header-sign-access">
                                    <p>Sorry! You do not have access to any agencies</p>
                                </div>
                                <div>
                                <form>
                                    <div className="access-textfield">
                                            Your access has been rescinded for all agencies
                                    </div>
                                    <div className="access-textfield">
                                            If you think this is an error, kindly contact your agency administrator or reach us at <a href="mailto:support@asiamobiliti.com">support@asiamobiliti.com</a>
                                    </div>                                

                                    <div style={{margin:"24px 0px 0px 0px"}} className="wthree-fields">
                                    <button type="submit" className="btn" onClick={() => window.location.reload()}>
                                        Back to Sign in
                                    </button>
                                    </div>
                                </form>
                            </div>
                            </div>
                        }


                    </div>

                </div>
                <div className="cpr">
                    <span style={{ color: " #939598", fontSize: "14px", fontFamily: "Roboto ,Arial, sans-serif" }}>Copyright © {new Date().getFullYear()} </span> &nbsp;
                    <span style={{ color: "#666666", fontWeight: "bold", fontSize: "14px", fontFamily: "Roboto ,Arial, sans-serif"   }}>
                    Asia Mobility Technologies Sdn Bhd
                    </span>
                    &nbsp;&nbsp;<span style={{ color: " #939598", fontSize: "14px", fontFamily: "Roboto ,Arial, sans-serif"   }}>|</span>
                    &nbsp; &nbsp;
                    <span style={{ color: "#666666", fontWeight: "bold", fontSize: "14px", cursor: "pointer", fontFamily: "Roboto ,Arial, sans-serif"  }} onClick={() => history.push("/privacy_policy")}>
                    {" "}
                    Privacy Policy
                    </span>
                    &nbsp;&nbsp;<span style={{ color: " #939598", fontSize: "14px", fontFamily: "Roboto ,Arial, sans-serif"   }}>|</span>
                    &nbsp;&nbsp;
                    <span style={{ color: "#666666", fontWeight: "bold", fontSize: "14px", cursor:"pointer", fontFamily: "Roboto ,Arial, sans-serif" }} onClick={() => history.push("/terms_of_service")}>
                    Terms of Service
                    </span>
                </div>
                </div>
            </section> 
            )
        }
        {
                isMobile && !token && !agencyID && !role && !sessionID && !userId && (
                    // <div>this is mobile</div>
                    <section className="mainMobile">
                        <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                        <div className="content-w3ls-mobile">
                            <div className="container-mobile">
                                <div className="container-logo-mobile">
                                    <div className="text-center-logo-mobile">
                                        {/* <p>{process.env.REACT_APP_ENVIRONTMENT}</p> */}
                                            <img loading="lazy" src={Logo} alt="Horizon-logo" className="img-logo" />
                                    </div>
                                    {
                                        !noPrivileges ? <div className="login-page-container-mobile">
                                            <div className="header-sign-mobile">
                                                <p style={{ margin: '0px 0px 16px 0px' }}>Sign in to your Horizon</p>
                                            </div>
                                            <div>
                                            <form onSubmit={(e) => onSubmit(e)} method="post">
                                                <div style={{ margin: '0px 0px 10px 0px '}} className="container-lable-input-mobile">
                                                    {/* <div className="txtLabel">
                                                        <label>Email address</label>
                                                    </div> */}
                                                    <div className="field-group-mobile">
                                                        <span className="fa fa-user" aria-hidden="true"></span>
                                                        <div className="wthree-field-mobile">
                                                            <input
                                                                name="email"
                                                                id="email"
                                                                type="email"
                                                                required
                                                                value={values.email}
                                                                onChange={(e) => onChange(e)}
                                                                placeholder="Username"
                                                            ></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="container-lable-input-mobile">
                                                    {/* <div className="txtLabel">
                                                        <label>Password</label>
                                                    </div> */}
                                                    <div className="field-group-mobile">
                                                        <span className="fa fa-lock" aria-hidden="true"></span>
                                                        <div className="wthree-field-mobile">
                                                            <input
                                                                name="password"
                                                                id="myInput"
                                                                type="Password"
                                                                value={values.password}
                                                                required
                                                                onChange={(e)=> onChange(e)}
                                                                placeholder="Password"
                                                            ></input>
                                                        </div>
                                                    </div>
                                                </div>                                
                                                <div className="checklayout-mobile">
                                                    <div className="check-box-container">
                                                        <input type="checkbox" id="check" checked={values.rememberMe} onChange={() => setValues(prev=> ({...prev, rememberMe: !prev.rememberMe}))}></input>                                    
                                                        <span className="span-me">Remember Me</span>
                                                    </div>
                                                    <div style={{ margin: '10px 0px 0px 0px'}}>
                                                        <div onClick={() => history.push("/forgot_password")}>
                                                        <a
                                                            className="text-right"
                                                            style={{ textDecoration: "none", fontSize: "12px", color: "#939598", cursor: "pointer"}}
                                                        >
                                                            Forgot your password?
                                                        </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="wthree-fields-mobile">
                                                    <button type="submit" className="btn">
                                                        {loading ? "Loading..." : "Sign In"}
                                                    </button>
                                                    <p className="versioning">{firebaseConfig.getValueByKey("horizon_version")}</p>
                                                </div>
                                            </form>
                                        </div>
                                        </div> : <div className="no-privileges-access">
                                            <div className="header-sign-access">
                                                <p>Sorry! You do not have access to any agencies</p>
                                            </div>
                                            <div>
                                                <form>
                                                    <div className="access-textfield">
                                                            Your access has been rescinded for all agencies
                                                    </div>
                                                    <div className="access-textfield">
                                                            If you think this is an error, kindly contact your agency administrator or reach us at <a href="mailto:support@asiamobiliti.com">support@asiamobiliti.com</a>
                                                    </div>                                

                                                    <div style={{margin:"24px 0px 0px 0px"}} className="wthree-fields-mobile">
                                                    <button type="submit" className="btn" onClick={() => window.location.reload()}>
                                                        Back to Sign in
                                                    </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="cpr-mobile">
                            <span style={{ color: " #939598", fontSize: "8px", fontFamily: "Roboto ,Arial, sans-serif" }}>Copyright © {new Date().getFullYear()} </span> &nbsp;
                            <span style={{ color: "#666666", fontWeight: "bold", fontSize: "8px", fontFamily: "Roboto ,Arial, sans-serif"   }}>
                            Asia Mobility Technologies Sdn Bhd
                            </span>
                            &nbsp;<span style={{ color: " #939598", fontSize: "8px", fontFamily: "Roboto ,Arial, sans-serif"   }}>|</span>
                            &nbsp;
                            <span style={{ color: "#666666", fontWeight: "bold", fontSize: "8px", cursor: "pointer", fontFamily: "Roboto ,Arial, sans-serif"  }} onClick={() => history.push("/privacy_policy")}>
                            {" "}
                            Privacy Policy
                            </span>
                            &nbsp;<span style={{ color: " #939598", fontSize: "8px", fontFamily: "Roboto ,Arial, sans-serif"   }}>|</span>
                            &nbsp;
                            <span style={{ color: "#666666", fontWeight: "bold", fontSize: "8px", cursor:"pointer", fontFamily: "Roboto ,Arial, sans-serif" }} onClick={() => history.push("/terms_of_service")}>
                            Terms of Service
                            </span>
                        </div>
                    </section>
                )
        } 
                {
                isTablet && !token && !agencyID && !role && !sessionID && !userId && (
                    // <div>this is mobile</div>
                    <section className="mainMobile">
                        <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                        <div className="content-w3ls-mobile">
                            <div className="container-mobile">
                                <div className="container-logo-mobile">
                                    <div className="text-center-logo-mobile">
                                        {/* <p>{process.env.REACT_APP_ENVIRONTMENT}</p> */}
                                            <img loading="lazy" src={Logo} alt="Horizon-logo" className="img-logo" />
                                    </div>
                                    {
                                        !noPrivileges ? <div className="login-page-container-mobile">
                                            <div className="header-sign-mobile">
                                                <p style={{ margin: '0px 0px 16px 0px' }}>Sign in to your Horizon</p>
                                            </div>
                                            <div>
                                            <form onSubmit={(e) => onSubmit(e)} method="post">
                                                <div style={{ margin: '0px 0px 10px 0px '}} className="container-lable-input-mobile">
                                                    {/* <div className="txtLabel">
                                                        <label>Email address</label>
                                                    </div> */}
                                                    <div className="field-group-mobile">
                                                        <span className="fa fa-user" aria-hidden="true"></span>
                                                        <div className="wthree-field-mobile">
                                                            <input
                                                                name="email"
                                                                id="email"
                                                                type="email"
                                                                required
                                                                value={values.email}
                                                                onChange={(e) => onChange(e)}
                                                                placeholder="Username"
                                                            ></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="container-lable-input-mobile">
                                                    {/* <div className="txtLabel">
                                                        <label>Password</label>
                                                    </div> */}
                                                    <div className="field-group-mobile">
                                                        <span className="fa fa-lock" aria-hidden="true"></span>
                                                        <div className="wthree-field-mobile">
                                                            <input
                                                                name="password"
                                                                id="myInput"
                                                                type="Password"
                                                                value={values.password}
                                                                required
                                                                onChange={(e)=> onChange(e)}
                                                                placeholder="Password"
                                                            ></input>
                                                        </div>
                                                    </div>
                                                </div>                                
                                                <div className="checklayout-mobile">
                                                    <div className="check-box-container">
                                                        <input type="checkbox" id="check" checked={values.rememberMe} onChange={() => setValues(prev=> ({...prev, rememberMe: !prev.rememberMe}))}></input>                                    
                                                        <span className="span-me">Remember Me</span>
                                                    </div>
                                                    <div style={{ margin: '10px 0px 0px 0px'}}>
                                                        <div onClick={() => history.push("/forgot_password")}>
                                                        <a
                                                            className="text-right"
                                                            style={{ textDecoration: "none", fontSize: "12px", color: "#939598", cursor: "pointer"}}
                                                        >
                                                            Forgot your password?
                                                        </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="wthree-fields-mobile">
                                                    <button type="submit" className="btn">
                                                        {loading ? "Loading..." : "Sign In"}
                                                    </button>
                                                    <p className="versioning">{firebaseConfig.getValueByKey("horizon_version")}</p>
                                                </div>
                                            </form>
                                        </div>
                                        </div> : <div className="no-privileges-access">
                                            <div className="header-sign-access">
                                                <p>Sorry! You do not have access to any agencies</p>
                                            </div>
                                            <div>
                                                <form>
                                                    <div className="access-textfield">
                                                            Your access has been rescinded for all agencies
                                                    </div>
                                                    <div className="access-textfield">
                                                            If you think this is an error, kindly contact your agency administrator or reach us at <a href="mailto:support@asiamobiliti.com">support@asiamobiliti.com</a>
                                                    </div>                                

                                                    <div style={{margin:"24px 0px 0px 0px"}} className="wthree-fields-mobile">
                                                    <button type="submit" className="btn" onClick={() => window.location.reload()}>
                                                        Back to Sign in
                                                    </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="cpr-mobile">
                            <span style={{ color: " #939598", fontSize: "8px", fontFamily: "Roboto ,Arial, sans-serif" }}>Copyright © {new Date().getFullYear()} </span> &nbsp;
                            <span style={{ color: "#666666", fontWeight: "bold", fontSize: "8px", fontFamily: "Roboto ,Arial, sans-serif"   }}>
                            Asia Mobility Technologies Sdn Bhd
                            </span>
                            &nbsp;<span style={{ color: " #939598", fontSize: "8px", fontFamily: "Roboto ,Arial, sans-serif"   }}>|</span>
                            &nbsp;
                            <span style={{ color: "#666666", fontWeight: "bold", fontSize: "8px", cursor: "pointer", fontFamily: "Roboto ,Arial, sans-serif"  }} onClick={() => history.push("/privacy_policy")}>
                            {" "}
                            Privacy Policy
                            </span>
                            &nbsp;<span style={{ color: " #939598", fontSize: "8px", fontFamily: "Roboto ,Arial, sans-serif"   }}>|</span>
                            &nbsp;
                            <span style={{ color: "#666666", fontWeight: "bold", fontSize: "8px", cursor:"pointer", fontFamily: "Roboto ,Arial, sans-serif" }} onClick={() => history.push("/terms_of_service")}>
                            Terms of Service
                            </span>
                        </div>
                    </section>
                )
        } 
        <style>
            {`
            .main {
                display: flex;
                flex-direction: row;
                height: 100vh;
                overflow: hidden;
                color: black;
            }
            .mainMobile {
                height: 100vh;
                overflow: hidden;
            }
            ::placeholder {
                color: #939598;
                // padding: 0px 0px 0px 8px;
              }
              
              :-ms-input-placeholder { /* Internet Explorer 10-11 */
               color: #939598;
            //    padding: 0px 0px 0px 8px;
              }
              
              ::-ms-input-placeholder { /* Microsoft Edge */
               color: #939598;
            //    padding: 0px 0px 0px 8px;
              }
            .content-w3ls .left{
                height: 100%;
                position: relative;
            }
            .content-w3ls-mobile {
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .checkbox-container{
                display: flex;
                align-items: center;
            }
            .image-placeholder{
                position: relative;
                width: 100%;
                height: 100vh;
                padding-left: 474px;
            }
            .image-placeholder img{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .text-pic {
                display: flex;
                justify-content: center;
                align-items: center;
                // position: relative;
                height: 100%;
            }

            .left-content{
                // height: 182px;
                // width: 352px;
                position: absolute;
            }

            .text-header {
                text-align:center;
                color: #FFFFFF;
                font-size: 19px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 32px;
                margin: 0px 24px 16px 24px;
            }

            .text-bodies {
                text-align: center;
                color: #FFFFFF;
                // height: 57px;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 19px;
                margin: 0px 24px 0px 24px;
            }
            .check-box-container{
                display:flex;
                align-items: center;
            }
            .btn-learn-more{
                 display: flex;
                 justify-content:center;
                 position
                 height: 32px;
                 margin: 17px 0px 0px 0px
            }
            .btn-learn-more .btn-learn{
                text-align: center;
                vertical-align: center;
                width:160px;
                background-color: #FFFFFF;
                margin 0px 0px 0px 0px;
           }
           .learn-more {
                margin: 0px 0px 0px 0px;
                color: #333333;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 31px;
                text-align: center
                
           }
           .container {
                // border-style: solid;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                padding: 0px 0px 0px 0px;
                height: 100%;
                width: 100%;
            }
            .container-mobile {

            }
            .text-center-logo{
                height: 61px;
                width: 210px;
                margin: 0px 0px 11px 0px;
            }
            .text-center-logo-mobile{

            }
            .container-logo{
                // height: 340px;
                // width: 570px;
                // // border-style: solid;
                margin: 0px 0px 0px 96px;
            }
            .container-logo-mobile {
                
            }
            .login-page-container{
                height: 340px;
                width: 570px;
            }
            .login-page-container-mobile{

            }
            .no-privileges-access{
                width: 100%;
            }
            .header-sign-access{
                height: 24px
                margin: 0px 0px 0px 0px;
            }
            .header-sign-access p {
                color: #EA2B1F;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 24px;
                margin 0px 0px 23px 0px;
            }
            .access-textfield{
                line-height: 24px;
                font-size: 13px;
            }
            .header-sign  {
                height: 24px
                margin: 0px 0px 0px 0px;
            }
            .header-sign-mobile {

            }
            .header-sign p {
                color: #3A3A3A;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 24px;
                margin 0px 0px 23px 0px;
            }
            .container-lable-input{
                height: 51px;
                width: 600px;
                margin: 0px 0px 22.5px 0px;
                line-height: 5px;
            }
            .container-lable-input-mobile{

            }
            .txtLabel label{
                height: 16px;
                color: #939598;
                font-size: 12px;
                font-weight: 500;
            }
            .field-group .wthree-field input {
                position: relative;
                bottom: 10px;
                box-sizing: border-box;
                height: 41px;
                width: 570px;
                border: 1px solid #939598;
                border-radius: 4px;
                background-color: #FFFFFF;
            }
            .field-group-mobile .wthree-field-mobile input {
                box-sizing: border-box;
                height: 30px;
                width: 200px;
                border: 1px solid #939598;
                border-radius: 4px;
                background-color: #FFFFFF;
            }
            .checklayout{
                display: flex;
                justify-content: space-between;
                margin: 0px 0px 23px 0px;
            }
            .checklayout-mobile{
                margin: 12px 0px 0px 0px;
            }
            .checklayout .span-me{
                color: #333333;
                font-size: 13px;
                letter-spacing: 0;
                line-height: 19px;
            }
            .checklayout-mobile .span-me{
                font-size: 12px;
            }
            .checklayout input{
                margin: 0px 8px 0px 0px;
            }
            .checklayout-mobile input{
                margin: 0px 8px 0px 0px;
            }

            .wthree-field .btn{
                width: 160px;
                height: 32px;
                background: #333333;
                border: none;
                color: #fff;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 16px;
                text-align: center;
                font-size: 12px;
                cursor: pointer;
            }
            .wthree-fields{
                display: flex;
                justify-content: space-between;
            }
            .wthree-fields-mobile{
                display: flex;
                flex-direction: column;
            }
            .wthree-fields .btn{
                height: 32px;
                width: 214px;
                border-radius: 2px;
                background-color: #509D91;
                border: none;
                color: #fff;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 16px;
                text-align: center;
                font-size: 14px;
                cursor: pointer;
            }
            .wthree-fields-mobile .btn{
                height: 32px;
                width: 130px;
                border-radius: 2px;
                background-color: #509D91;
                border: none;
                color: #fff;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 16px;
                text-align: center;
                font-size: 14px;
                cursor: pointer;
                margin: 10px 0px 0px 0px;
            }
            .wthree-fields .versioning{
                font-size: 12px;
                color: #939598;
                margin: 0px 0px 0px 0px;
                line-height: 40px;
            } 
            .wthree-fields-mobile .versioning{
                font-size: 12px;
                color: #939598;
                margin: 0px 0px 0px 0px;
                line-height: 40px;
            }
            .cpr {
                position: absolute;
                bottom: 0;
                right: 0;
                margin: 0px 28px 16px 0px;              
            }
            .cpr-mobile {
                position: fixed;
                bottom: 0;
                right: 0;
                margin: 0px 8px 4px 0px;              
            }
            `}
        </style>
        </div>
    );        
}

export default LoginPage