import React, { useState, useEffect } from "react";

//library
import { useQuery } from "@apollo/react-hooks";

//component
import { filterList } from "../../config/config-filter/filter"
import { assetGroupQuery } from "../../config/config-graphql/Graphql";
import Loaders from "../../component/component-loaders/Loaders"
import Overview from "./Overview"
import SnackBar from '../../config/config-snackbar/SnackBar'


const { getQuery } = assetGroupQuery;


const PanelAssetGroup = ({ filter, fx_changePage, setFilter, history, featureDisable }) => {// 👈 the filter props is for the filter that comes from other pages (view all)
    const [filterFinal, setFilterFinal] = useState({})
    const [preFilter, setPreFilter] = useState({})
    const [canUpdate, setCanUpdate] = useState(false) //default false and also this state for btn save mandatory field
    const [edit, setEdit] = useState(false);
    const [editValue, setEditValue] = useState()
    //this state for error handling on getQuery
    const [snOpen, setSnOpen] = useState(false)
    const [pageInd, setPageInd] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")
    const [isHovered, setisHovered] = useState({
        container: false,
        details: false
    })

    const PreFilter = (value) => {
        setPreFilter({ name: { type: "CONTAINS", value: [ value ] } })
    }

    const EnterFilter =(e) => {
        if(e.key === "Enter"){
            setFilterFinal(preFilter)
        }
    }
 
    //data - Asset Group data from mongo**
    const { data, loading, error } = useQuery(getQuery, { variables: {filter: Object.keys(filterFinal).length !== 0 ? filterFinal : filter }});

    //this useEffect are to go to details page
    //details page
    useEffect(() => {
    
    if(!loading && data && data.get_asset_groups){
        if(pageInd){
            setEdit(!edit)       
            setEditValue(data.get_asset_groups)
        }
    }
        
    }, [loading])

      //based on onclick from device agency hyperlink
    useEffect(() => {
        if(Object.keys(filter).length > 0){
            if(!loading){
                setEditValue(data.get_asset_groups)
                setEdit(!edit)     
        }
        }
    }, [loading])

    useEffect(() => {
        if(error){
            setSnOpen(true)
            setMessage("Error: Unable to retrieve data. Please try again later.")
            setSeverity("error")
        }
    }, [error])
    
    return loading ? <Loaders /> : error ? (
            <SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/>
    ) : (
        <Overview
            data={data.get_asset_groups ? data.get_asset_groups : []}
            query={{assetGroupQuery}}
            fx_changePage={fx_changePage}
            setLiveFilter={setFilter}
            filterFinal={filterFinal}
            setFilterFinal={setFilterFinal}
            filterList= {filterList.asset_group}
            preFilter={preFilter} 
            fx_PreFilter={PreFilter} 
            EnterFilter={EnterFilter}
            setPreFilter={setPreFilter}
            setCanUpdate={setCanUpdate} 
            canUpdate={canUpdate}
            edit={edit}
            setEdit={setEdit}
            editValue={editValue}
            setEditValue={setEditValue}
            setPageInd={setPageInd}
            pageInd={pageInd}
            history={history}
            featureDisable={featureDisable}
    />
    )
};

export default PanelAssetGroup;

