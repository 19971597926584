import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import Cookies from 'js-cookie';

//component
import { useQuery } from "@apollo/react-hooks";
import { useMediaQuery } from 'react-responsive';
import { GET_PAYLOAD } from "../../config/config-graphql/Graphql";
import { WebsocketContext } from "../../config/config-websocket/Websocket";
import { APISendTeltonikaCommand } from '../../config/config-restAPI/postAPICalled';
import SnackBar from '../../config/config-snackbar/SnackBar'
import firebase from "../../config/config-firebase-auth/firebase"
import menuConfig from "../../config/config-menu/menu";
import { isEmptyObject } from '../../config/config-function/function';
import Loaders from "../../component/component-loaders/Loaders"
import ImageComponent from '../../component/component-image/ImageComponent'
import LinkOffRoundedIcon from '@material-ui/icons/LinkOffRounded';
import LinkBroken from '../../Image/linkOffBroken.svg';

import BackButton from "../../Image/navigation_back_dark_24.svg";
import AssetGroupIcon from "../../Image/asset_group_card.svg";
import SpeedIcon from '../../Image/black_speed.svg'
import TelemetryIcon from "../../Image/telemety_dark.svg"
import SmartlockIcon from '../../Image/dark_smartlock.svg'
import GpsIcon from '../../Image/gps_fixed.svg'
import SNIcon from '../../Image/dark_sn.svg'
import GreySNIcon from '../../Image/grey_sn.svg'
import HumidityIcon from '../../Image/black_board.svg'
import GreyHumidityIcon from '../../Image/grey_board.svg'
import PairIcon from '../../Image/yellow_paired.svg'
import GreySpeedIcon from '../../Image/grey_speed.svg'
import GreySmartlockIcon from '../../Image/f_grey_smartlock.svg'
import GreyInfo from '../../Image/grey-info.svg'
import GreyAlert from '../../Image/alert_grey.svg'
import GreyTime from '../../Image/grey_time.svg'

import lqiExcellentIcon from "../../Image/excellent.svg"
import lqiGoodIcon from "../../Image/good.svg"
import lqiAverageIcon from "../../Image/average.svg"
import lqiPoorIcon from "../../Image/poor.svg"
import lqiUnknownIcon from "../../Image/unknown.svg"

import MoveIcon from "../../Image/move.svg"
import StopIcon from "../../Image/stop.svg"
import IdleIcon from "../../Image/device-idle.svg"
import TowingIcon from '../../Image/towing.svg'
import LocationNotFoundIcon from "../../Image/default.svg";

import BatteryChargingIcon from "../../Image/charging.svg"
import BatteryMediumIcon from '../../Image/yellow-battery.svg'
import BatteryLowIcon from '../../Image/red-battery.svg'
import BatteryHighIcon from '../../Image/green-battery.svg'
import BatteryUnknownIcon from '../../Image/grey-battery.svg'
import BatteryDebuggingIcon from '../../Image/dark_debugging.svg'

import DvcBatteryHigh from '../../Image/battery-high.svg'
import DvcBatteryMedium from '../../Image/battery-medium.svg'
import DvcBatteryLow from '../../Image/battery-low.svg'
import DvcBatteryUnknown from '../../Image/battery-unknown.svg'

import FuelHighIcon from '../../Image/green-fuel.svg'
import FuelMediumIcon from '../../Image/yellow-fuel.svg'
import FuelLowIcon from '../../Image/red-fuel.svg'
import GreyFuel from '../../Image/grey-fuel.svg'

import GeolocationIcon from '../../Image/dark_wifi.svg'
import GpsOffIcon from '../../Image/gps_offs.svg'

import GreenSmallWarehouse from '../../Image/green-small-warehouse.svg'
import DarkCurrentTelemetry from '../../Image/dark-telemetry-phone.svg'
import { indigo } from "@material-ui/core/colors";


moment.updateLocale('en', {
    relativeTime : {
        future: (diff) => (diff === 'a few second(s) ago' ? diff : `in ${diff}`),
        past: (diff) => (diff === 'a few second(s) ago' ? diff : `${diff}`),
        s:  'a few second(s) ago',
        ss: "a few second(s) ago",
        m:  "a minute ago",
        mm: "%d minutes ago",
        h:  "an hour ago",
        hh: "%d hours ago",
        d:  "a day ago",
        dd: "%d days ago",
        w:  "a week ago",
        ww: "%d weeks ago",
        M:  "a month ago",
        MM: "%d months ago",
        y:  "a year ago",
        yy: "%d years ago"
    }
});
moment.relativeTimeThreshold('s', 59);
moment.relativeTimeThreshold('m', 60);


//To set icon based on the location status

const AssetDetails = ({ setFilter, setSearchValue, filter, fx_changePage, history }) => {
    const isDefault = useMediaQuery({ minWidth: 1025 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    const payloadContext = useContext(WebsocketContext);    
    const { data: websocketData, new_payload_id, selected_asset, selected_asset_info, view_asset_card, view_asset_group_card, closeAssetCard, selectAsset } = payloadContext;
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');

    const { data, loading, error } = useQuery(GET_PAYLOAD, { variables: { pairId: selected_asset.pairId }});
    const [timestampDisplay, setTimestampDisplay] = useState("") //timestamp on the card (changing every second)
    const [info, setInfo] = useState({}); //the information to be displayed on the card
    const [timer, setTimer] = useState(null)
    const [displayLastSeen, setDisplayLastSeen] = useState(false);
    const analystic = firebase.analytics()
    const [hoveredO, setIsHoveredO] = useState({
        add: false,
        back: false
    })

      //the errOpen, errMsg and errSevere is for snackbar props for error 
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [errSevere, setErrSevere] = useState('')
    const [apiLoading, setApiLoading] = useState(false);
    //FIRST CLICK ASSET CARD
    useEffect(() => {

        if( !loading && data){

            //this is to check if different asset from info and selected_asset (clear interval)
            if(selected_asset.pairId !== info.pairId){
                clearInterval(timer)
            }

            //this is to set the interval in timestamp
            if(data.getPayloadInfo && data.getPayloadInfo.timestamp){

                // console.log("go here payloadInfo API")
                const realTimeStamp = moment(data.getPayloadInfo.timestamp).format("X");
                settingTimestamp(realTimeStamp) //display this timestamp first
                const new_interval = setInterval(() => settingTimestamp(realTimeStamp), 1000);
                setTimer(new_interval);

            }
            else{
                setInfo(reducer({}, false))  
                setTimestampDisplay(null)  
            }

            //this to take the API data into the state
            if(data.getPayloadInfo && data.getPayloadInfo.pairId){
                setInfo(reducer(data.getPayloadInfo, false))               
                const asset = data.getPayloadInfo !== null ? data.getPayloadInfo.primaryAsset ? {pairId: data.getPayloadInfo.pairId, asset_name: data.getPayloadInfo ? data.getPayloadInfo.primaryAsset.assetName : "", asset_uuid: data.getPayloadInfo.primaryAsset.assetUUID, paired_asset: data.getPayloadInfo && data.getPayloadInfo.secondaryAsset && data.getPayloadInfo.secondaryAsset } : selected_asset_info : null //activate marker
                selectAsset(selected_asset, { ...asset, ...deviceReducer(data.getPayloadInfo) } ) //activate marker

            }else{
                setInfo(reducer({}, false))   
                setTimestampDisplay(null)             
            }

            
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])
    

    //this useEffect is use to remove / clear  the previous blobID timestamp interval
    useEffect(() => {
        return () => {
            clearInterval(timer)
        }
    }, [timer])

    //UPDATE WEBSOCKET TO ASSET CARD
    // console.log(websocketData)
    useEffect(() => {

        //To add in the latest data (from WEBSOCKTET) and add in into the info state
        if(new_payload_id && info.pairId === websocketData.pairId){
            const realTimeStamp = moment(websocketData.timestamp).format("X");
            setInfo(reducer(websocketData, true))
            const new_interval = setInterval(() => settingTimestamp(realTimeStamp), 1000); // this will update every one minute

            setTimer(new_interval)
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [new_payload_id])


    //this is to reducer for the payload selectAsset (context)
    const deviceReducer = (device) => {

        // console.log(device)
        return device && {
            pairId: device.pairId,
            deviceSN: device.deviceSN,
            latitude: device.latitude,
            longitude: device.longitude,
            geoAccuracy: device.geoAccuracy,
            motionInference: setMotionInference(device.motionInference),
        }
    }

    //convert all the float number into 6 decimal
    const toSixDecimalPlaces = (number) => {
        return number ? parseFloat(number).toFixed(6) : 0
    }

    const geoLocationStatus = (geolocation) => {
        switch (geolocation) {
            case 0:
                return "GPS" 
            case 16:
                return "Wifi"
            case 17:
                return "NoWifi"
            default:
                return "Garbage"
        }
    }

    // this to display text for 
    const setMotionInference = (motion) => {
        switch (motion) {
            case 100 :
                return "Stop" 
            case 200 :
                return "Moving"
            case 400 :
                return "Idle"
            case 500 :
                return "In Tow"
            default:
                return "Undetermined"
        }
    }
    

    // this is for new reducer
    const reducer = (data, isFromSubscription) => {
        const assetPair = data && data.secondaryAsset ? data.secondaryAsset : null;
        return data && {
            id: data && data.primaryAsset ? data.primaryAsset._id : selected_asset.pairId ? selected_asset.pairId : '',
            asset_name: data && data.primaryAsset && data.primaryAsset.assetName ? data.primaryAsset.assetName : selected_asset.assetName ? selected_asset.assetName : "Unassigned",
            group_name: data && data.primaryAsset && data.primaryAsset.assetGroup && data.primaryAsset.assetGroup.grpName ? data.primaryAsset.assetGroup.grpName : "-",
            asset_assigned: data && data.primaryAsset && data.primaryAsset.assetName ? data.primaryAsset.assetName : selected_asset.assetName ? selected_asset.assetName : "Unassigned",
            pairId: data.pairId,
            deviceSN: data.deviceSN ? data.deviceSN : "-",
            signalQuality: data.signalQuality ? data.signalQuality : false,
            latitude: data.latitude ? toSixDecimalPlaces(data.latitude) : "",
            longitude: data.longitude ? toSixDecimalPlaces(data.longitude) : "",
            position: data.latitude && data.longitude ? `${toSixDecimalPlaces(data.latitude)},${toSixDecimalPlaces(data.longitude)}` : "-",
            timestamp: data.timestamp ? data.timestamp : null,
            geolocationStatus: !isNaN(data.geolocationStatus) ? geoLocationStatus(data.geolocationStatus) : "-", // geolocation
            motionInference: setMotionInference(data.motionInference), // status
            connClass: data.connClass ? data.connClass : false, // connectivity
            geoAccuracy: data.geoAccuracy ? data.geoAccuracy : '-', // accuracy
            devSuppState: data.devSuppState ? data.devSuppState : "-",
            battVolt: data.battVolt ? parseFloat(data.battVolt).toFixed(2) : "-",
            dcVolt: data.dcVolt ? parseFloat(data.dcVolt).toFixed(2) : "-",
            devCelsius: data.devCelsius ? parseFloat(data.devCelsius).toFixed(2) : "-",
            devRh:  data.devRh ? parseFloat(data.devRh).toFixed(2) : "-",
            lastSeenTimestamp : data && data.lastSeenTimestamp ? data.lastSeenTimestamp : '',
            dcVoltPerc: data.dcVoltPerc ? parseFloat(data.dcVoltPerc).toFixed(2) : 0,
            battVoltPerc: data.battVoltPerc ? parseFloat(data.battVoltPerc).toFixed(2) : 0,
            fuelVolume: data && typeof data.fuelVolume !== "undefined" ? data.fuelVolume ? parseFloat(data.fuelVolume).toFixed(2) : "-": "-",
            fuelTankPerc: data && typeof data.fuelTankPerc !== "undefined" ? data.fuelTankPerc ? parseFloat(data.fuelTankPerc).toFixed(2) : null : null,
            kmh: data && data.kmh && !isNaN(data.kmh) && typeof data.kmh !=="undefined" ? parseFloat(data.kmh).toFixed(2)  : "-", //speec
            nSat: !isNaN(data.nSat) ? data.nSat : "-",  //this is using nsat not gspQuality
            paired_asset: assetPair,
            paired_asset_name: assetPair ? assetPair.assetName : null,
            address: data.address && data.address.localDisplay ? data.address.localDisplay : "-",
            beacon: data && data.beacon ? data.beacon : null,
        }
    }

    // to display check location status
    useEffect(() => {
        // setDisplayLastSeen
        if(Object.keys(info)){
            const checkLocationStatus = () => {
                if (info.beacon) {
                    if (info.geolocationStatus === 'NoWifi' || info.geolocationStatus === 'Garbage') {
                        setDisplayLastSeen(false);
                    }
                } else {
                    if (info.geolocationStatus) {
                        // this to check if status is GPS
                        if ((info.geolocationStatus === "GPS") || (info.geolocationStatus === "Wifi")) {
                            setDisplayLastSeen(false); // not display the history location
                        
                          // to check if the status noWifi or garbage
                        } else if((info.geolocationStatus === "NoWifi") || (info.geolocationStatus === "Garbage")) {
                            // if lastSeenTimestamp, lat and lon have value
                            if(info.lastSeenTimestamp && info.latitude && info.longitude) {
                                setDisplayLastSeen(true); // display the history location
                            } else {
                                setDisplayLastSeen(false) // not display the history location
                            }
                        } else {
                            setDisplayLastSeen(false); // not display the history location
                        }
                    } else {
                        setDisplayLastSeen(false); // not display the history location
                    }
                }
            }
            
            checkLocationStatus();
        }
    }, [info])

    // console.log('infooo', info);

    //this is for the "last updated"
    const settingTimestamp = (timestamps) => {
        setTimestampDisplay(moment.unix(timestamps).fromNow());
    }
    const settingTimestampLocation = (time) => {
        const timestamp = moment(time).format("X");
        const realTimestamp = moment.unix(timestamp).fromNow();
        return realTimestamp;
    }

    // //setup the voltage value (high, medium, low, unknown)
    const setBatteryStatus = (voltage) => {
        // console.log(voltage)
        // return voltage ? voltage >= 70.00 && voltage <= 100.00 ? "high" : voltage >= 70.00 && voltage <= 30.00 ? "medium" : voltage >= 30.00 && voltage <= 0.00 ? "low" : "unknown" : "unknown"
        return voltage ? voltage <= 100.00 && voltage >= 45.00 ? "high" : voltage <= 45.00 && voltage >= 25.00 ? "medium" : voltage <= 25.00 && voltage >= 0.00 ? "low" : "unknown" : "unknown"
    }

    // //setting the status location (motion_status)
    const settingLocationIcon = (found, by, beacon) => {
        return found ? {           
            icon: <img loading="lazy" src={settingDeviceIcon(info.motionInference ? info.motionInference.toLowerCase() : null, by)} alt="Location Found"/>,
            by: settingFoundByIcon(by),
        } : found ? {
            icon: <img loading="lazy" src={settingDeviceIcon(info.motionInference ? info.motionInference.toLowerCase() : null, by)} alt="Location Found"/>,
            by: settingFoundByIcon(by),
        } : {
            icon: <img loading="lazy" src={settingDeviceIcon(info.motionInference ? info.motionInference.toLowerCase() : null, by)} alt="Location not found"/>,
            by: <ImageComponent src={GpsOffIcon} alt="gps-off" style={{heigh:"16px", width:"16px"}} onClick={false}/>,
        }
    }

    const settingFoundByIcon = (by) => {
        switch (by) {
            case "GPS":
                return <ImageComponent src={GpsIcon} alt="gps" style={{heigh:"16px", width:"16px"}} onClick={false}/>
            case "Wifi":
                return <ImageComponent src={GeolocationIcon} alt="WiFi" style={{heigh:"16px", width:"16px"}} onClick={false}/>
            case "Garbage":
                return <ImageComponent src={GpsOffIcon} alt="GPS off" style={{heigh:"16px", width:"16px"}} onClick={false}/>
            case "NoWifi":
                return <ImageComponent src={GpsOffIcon} alt="GPS off" style={{heigh:"16px", width:"16px"}} onClick={false}/>
            default:
                return <ImageComponent src={GpsOffIcon} alt="GPS off" style={{heigh:"16px", width:"16px"}} onClick={false}/>
        }
    }
    
    const iconBeaconSetting = () => {
        return <ImageComponent src={GpsIcon} alt="gps" style={{heigh:"16px", width:"16px"}} onClick={false}/>
    }
    const settingAccuracy = (by, accuracy, nsat, beacon) => {
        if (beacon) {
            switch (by) {
                case "GPS":
                    return `GPS Fix ${nsat !== "-" ? "|" + " " +nsat + " " + "Satellites" : ""}`
                case "Wifi":
                    return `aGPS | Accurate to ${accuracy} Meters` 
                default:
                    return "Indoor Tracking"
            }
        } else {
            switch (by) {
                case "GPS":
                    return `GPS Fix ${nsat !== "-" ? "|" + " " +nsat + " " + "Satellites" : ""}`
                case "Wifi":
                    return `aGPS | Accurate to ${accuracy} Meters` 
                default:
                    return "No Location Service"
            }
        }
    }

    // THIS IS FOR SIGNAL QUALITY
    const settingSignalQuality = (signal) => {
        if (signal) {
            if(signal > 75){
                return <ImageComponent src={lqiExcellentIcon} alt="lqiEx" style={false} onClick={false}/>
            } else if (signal <= 75 && signal > 50){
                return <ImageComponent src={lqiGoodIcon} alt="lqiG" style={false} onClick={false}/>
            } else if (signal <= 50 && signal > 25 ){
                return <ImageComponent src={lqiAverageIcon} alt="lqiAv" style={false} onClick={false}/>
            } else {
                return <ImageComponent src={lqiPoorIcon} alt="lqiP" style={false} onClick={false}/>
            }
        } else {
            return <ImageComponent src={lqiUnknownIcon} alt="lqiUkn" style={false} onClick={false}/>
        }
    }

    const settingBatteryIcon = (status) => {
        switch (status) {
            case "high":
                return <ImageComponent src={BatteryHighIcon} alt="batHigh" style={false} onClick={false}/> 
            case "medium":
                return <ImageComponent src={BatteryMediumIcon} alt="batMed" style={false} onClick={false}/> 
            case "low":
                return <ImageComponent src={BatteryLowIcon} alt="batLow" style={false} onClick={false}/> 
            case "unknown":
                return <ImageComponent src={BatteryUnknownIcon} alt="batUnk" style={false} onClick={false}/>
            default:
                break;
        }
    }

    const settingDvcBatteryIcon = (status) => {
        switch (status) {
            case "high":
                return <ImageComponent src={DvcBatteryHigh} alt="batHigh" style={false} onClick={false}/> 
            case "medium":
                return <ImageComponent src={DvcBatteryMedium} alt="batMed" style={false} onClick={false}/> 
            case "low":
                return <ImageComponent src={DvcBatteryLow} alt="batLow" style={false} onClick={false}/> 
            case "unknown":
                return <ImageComponent src={DvcBatteryUnknown} alt="batUnk" style={false} onClick={false}/> 
            default:
                break;
        }
    }
    const settingDeviceIcon = (status, gps_wifi) => {
        // console.log(status);
        if(gps_wifi === "wifi") return LocationNotFoundIcon;
        switch (status) {
            case "moving":
                return MoveIcon
            case "stop": 
                return StopIcon
            case "idle":
                return IdleIcon
            case "in tow":
                return TowingIcon
            case 'undetermined':
                return LocationNotFoundIcon
            default:
                return LinkBroken;
        }
    }
 
    // //shortcut telemetry
    const changePageTelemetry = () => {
        setFilter({pairId: info.pairId, name: info.asset_name})
        fx_changePage(menuConfig.dashboard[1].key, menuConfig.dashboard[1].children[2].key)
        clearInterval(timer)
        analystic.logEvent("asset_card", {pairId: info.pairId})
        history.push("/dashboard/live/telemetry")
    }

    //backButotn
    const backButtonAsset = () => {
        clearInterval(timer)
        setSearchValue({})
        closeAssetCard()
    }

    const smartLockBtn = (smartlocks) => {
        setFilter({ uuid: smartlocks })
        fx_changePage(menuConfig.dashboard[2].key, menuConfig.dashboard[2].children[1].key)
        history.push("/dashboard/asset_mgmt/asset")
    }

    const commandLatest = async () => {
        try {
            const reducer = { deviceIMEI : info.deviceSN}
            const result = await APISendTeltonikaCommand(reducer, token, agencyId, setApiLoading)
            if (result === 429) {
                setErrOpen(true);
                setErrMsg("Too many requests. Please try again later");
                setErrSevere("error");
            } else if (result.data.status === 200) {
                setErrOpen(true);
                setErrMsg("Command sent successfully");
                setErrSevere("success");
            } else {
                setErrOpen(true);
                setErrMsg("Something went wrong. Please try again later");
                setErrSevere("error");
            }
        } catch (err) {
            setErrOpen(true);
            setErrMsg("Something went wrong. Please try again later");
            setErrSevere("error");
        }
    }

	return (
		<div> 
        {
            isDefault && (
                <div className="asset-card-container">
                    {
                        loading ? (<div style={{position: "relative", right:"2px", top: "40px"}}><Loaders /></div> ) : (
                            <>
                                <section className="first-line-container">
                                    <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                                    <div className="buttons">
                                            <img loading="lazy" src={BackButton} alt="icon" className={hoveredO.add ? "add-hover-button-style" : "add-button-style"} onMouseEnter={() => setIsHoveredO((current) => ({...current, add: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, add: false}))} onClick={() => backButtonAsset()} />
                                        <div className="grid-2-column">
                                            <div></div>
                                            <img loading="lazy" src={TelemetryIcon} alt="icon" className={hoveredO.back ? "add-hover-button-style" : "add-button-style"} onMouseEnter={() => setIsHoveredO((current) => ({...current, back: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, back: false}))} onClick={() => changePageTelemetry()}/>
                                        </div>
                                    </div>
                                    <div className="live-updates-text">
                                        <p style={{margin: "0px", fontSize:"14px",lineHeight:"16px", color:"#939598"}}>Live Updates</p>
                                        <h1 style={{margin: "0px", fontSize:"30px", color:"#333333"}} className="asset-name-container">{info.asset_name}</h1>
                                        <div className="asset-group-container">
                                            <ImageComponent src={AssetGroupIcon} alt="icon" style={false}  onClick={false}/>
                                            <div>{info.group_name}</div>
                                        </div>
                                        {
                                            info.paired_asset ? info.asset_name !== info.paired_asset_name ? (
                                                <div className="paired-asset-live">
                                                    <div className="paired-asset-name-container">
                                                        <p style={{margin: "0px", fontSize:"14px",lineHeight:"16px", color:"#939598"}}>Paired Asset</p>
                                                        <div className="paired-asset-name-field">
                                                            <ImageComponent src={PairIcon} alt="p-icon" style={false} onClick={false}/>
                                                            <div className="paired-asset-name-details">{info.paired_asset_name}</div>
                                                        </div>
                                                    </div>
                                                    <div className="paired-asset-description-container">
                                                        <div className="divider-live-paired">   </div>
                                                        <ImageComponent src={GreyInfo} alt="info" style={{height:"16px", width:"16px"}} onClick={false}/>
                                                        <div className="pairing-asset-details">
                                                            Asset Pairing allows for the positioning and state telemetries to be shared between both assets. This card is only displaying the latest telemetry received from either asset. More information is available in the <a className="link-container" href={`https://asiamobiliti.freshdesk.com/a/solutions/articles/62000217633`} rel="noopener noreferrer" target="_blank">Paired Asset Documentation</a>.
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null : null
                                        }
                                        {
                                            info.asset_assigned === "Unassigned" ? (
                                            <div className="unnasigned-container">
                                                <div className="unnasigned-divider-live">   </div>
                                                <ImageComponent src={GreyAlert} alt="info" style={{height:"16px", width:"16px"}} onClick={false}/>
                                                <div className="last-location-details">
                                                    This asset has recently been added / removed. Its latest state will be updated soon.
                                                </div>
                                            </div>
                                            ) : null
                                        }
                                    </div>
                                </section> 
                                <div className="live-divider"/>   
                                <section className="second-line-container">
                                    <p style={{margin: "0px", fontSize:"14px", lineHeight:"16px", color:"#939598"}}>Asset</p>
                                    <div className="grid-2-column-live-update">                
                                            <div>               
                                                <div className="grid-24-icon-text">
                                                    { settingLocationIcon(info.latitude && info.longitude , info.geolocationStatus).icon }
                                                    <h2 style={{margin: "0px"}}>{info.motionInference}</h2>
                                                </div>
                                            </div>
                                            <div>
                                                <p style={{margin:"0px", fontSize:"10px", fontWeight: 400, lineHeight:"16px", color:"#939598"}}>{info.timestamp ? `Last updated ${ timestampDisplay }` : `No Recent Activity`}</p>
                                                <div className="grid-16-icon-text">
                                                    {info.beacon && (info.geolocationStatus === 'NoWifi' || info.geoLocationStatus === 'Garbage') ? iconBeaconSetting() : settingLocationIcon(info.latitude && info.longitude, info.geolocationStatus ? info.geolocationStatus : null).by }
                                                    {settingAccuracy(info.geolocationStatus ? info.geolocationStatus : null, info.geoAccuracy, info.nSat, info.beacon)}
                                                </div>
                                            </div>
                                    </div>
                                    <div className="address-container">
                                            <p style={{margin: "0px", fontSize:"10px", lineHeight:"16px", color:"#939598"}}>Location</p>
                                            {
                                                info.beacon && (
                                                <div style={{ marginTop: '5px', marginLeft: '1px' }} className="warehouse-container">
                                                    <ImageComponent src={GreenSmallWarehouse} style={{ marginRight: '9px' }} onClick={false} />
                                                    <div>{info.beacon.locationName}</div>
                                                </div>
                                                )
                                            }
                                            {
                                                displayLastSeen && (
                                                <div className="location-lastseen-container">
                                                    <div className="divider-location-lastseen">   </div>
                                                    <ImageComponent src={GreyTime} alt="info" style={{height:"16px", width:"16px"}} onClick={false}/>
                                                    <div className="last-location-details">
                                                        <div className="first-text-location-details">{`Displaying last seen location ${settingTimestampLocation(info.lastSeenTimestamp)}`}</div>
                                                        <div className="details-text-location-details">Unable to obtain GPS Fix. Asset may be located indoors, under a bridge or in a tunnel.</div>
                                                    </div>
                                                </div>                               
                                                )
                                            }
                                            <div className={info.position !== "-" ? "position-container" : "null-position-container"}>{info.position!== "-" ? <a className="link-container" href={`https://www.google.com/maps/search/?api=1&query=${info.position}`} rel="noopener noreferrer" target="_blank">{info.position}</a> : "-"}</div>
                                            <div className={info.address !== "-" ? "address" : "null-address"}>{info.address}</div>
                                    </div>
                                    <div className="grid-2-column">
                                        <div>
                                            <div className="speed-asset-icon-container">
                                                <div className="live-icon-title">Speed</div>
                                                <div className="icons-details-with-3-param">
                                                    <ImageComponent src={info.kmh !== "-" ? SpeedIcon : GreySpeedIcon} alt="icon" style={false}  onClick={false}/>
                                                    <div className={info.kmh !== "-" ? "icon-text-details" :  "null-value-with-24-text"}>{info.kmh}</div>
                                                    <div className="measurement-container">km/h</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="live-icon-title">Asset Battery</div>
                                                <div className="icons-details-with-3-param">
                                                    {info.dcVoltPerc !== "-" ? settingBatteryIcon(setBatteryStatus(parseInt(info.dcVoltPerc))) : info.dcVoltPerc}
                                                <div className={info.dcVolt !== "-" ? "icon-text-details": "null-value-with-24-text"}>{info.dcVolt}</div>
                                                <div className="measurement-container">Volt</div>
                                            </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="live-icon-title">Fuel</div>
                                            <div className="icons-details-with-3-param">
                                                {info.fuelTankPerc ? <ImageComponent src={info.fuelTankPerc >= 70 ? FuelHighIcon : info.fuelTankPerc >= 30 ? FuelMediumIcon : info.fuelTankPerc <= 29 ? FuelLowIcon : FuelLowIcon} alt="icon" style={false} onClick={false}/> : <ImageComponent src={GreyFuel} alt="icon" onClick={false} style={false}/>} 
                                                <div className={info.fuelVolume !== "-" ? "icon-text-details": "null-value-with-24-text"}>{info.fuelVolume}</div>
                                                <div className="measurement-container">litres</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="get-current-telemetry">
                                        <div className="inside-current-telemetry" onClick={() => commandLatest()}>
                                            <ImageComponent src={DarkCurrentTelemetry} style={{ marginRight: '16px' }} onClick={false} />
                                            <div>Get Latest Telemetry</div>
                                        </div>
                                    </div>                                        
                                </section> 
                                <div className="live-divider"/>
                                <section className="third-line-container">
                                    <div className="device-text">Device</div>
                                    <div className="grid-2-column">
                                        <div>
                                            <div className="device-metadata-container">
                                                <div className="live-icon-title">Device Metadata</div>
                                                <div className="icon-details-with-2-param">
                                                    <ImageComponent src={info.deviceSN !== "-" ? SNIcon : GreySNIcon} alt="icon" style={false}  onClick={false}/>
                                                    <div>
                                                        <div className={info.deviceSN !== "-" ? "live-dvc-container" : "null-live-dvc-container"}>{info.deviceSN}</div>
                                                        {/* <div className="live-SKU-container">{info.dvc_sku}</div> */}
                                                    </div>

                                                </div>
                                            </div>
                                            <div>
                                                <div className="live-icon-title">Network Type</div>
                                                <div className="icon-details-with-2-param">
                                                    { settingSignalQuality(info.signalQuality)}
                                                    <div className="tag-sigfox-cellular">
                                                        <div className={info.connClass ? info.connClass === "sigfox" ? "green-text" : "grey-text" : "grey-text"}>Sigfox</div>
                                                        <div className={info.connClass ? info.connClass === "cellular" ? "green-text" : "grey-text" : "grey-text"}>Cellular</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="speed-asset-icon-container">
                                                <div className="live-icon-title">Device Battery</div>
                                                <div className="icons-details-with-3-param">
                                                    { info.devSuppState === 100 ? 
                                                    <img src={BatteryChargingIcon} alt="batteryCharging"/> : 
                                                    info.devSuppState === 200 && info.devSuppState === 300 ? 
                                                    settingDvcBatteryIcon(setBatteryStatus(parseInt(info.battVoltPerc))) : 
                                                    info.devSuppState === 400 ? <img src={BatteryDebuggingIcon} alt="batteryDebuggin"/> : 
                                                    settingDvcBatteryIcon(setBatteryStatus(parseInt(info.battVoltPerc))) }
                                                    <div className={info.battVolt !== "-" ? "icon-text-details": "null-value-with-24-text"}>{ info.battVolt}</div>
                                                    <div className="measurement-container">Volt</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="live-icon-title">Board Condition</div>
                                                <div className="icons-details-with-3-param">
                                                    <ImageComponent src={info.devCelsius === "-" && info.devRh === "-" ? GreyHumidityIcon : HumidityIcon} alt="icon" style={false} onClick={false}/>
                                                    <div>
                                                        <div className={info.devCelsius !== "-" ? "board-container" : "null-value-with-14-text"}>{info.devCelsius}</div>
                                                        <div className={info.devRh !== "-" ? "board-container" : "null-value-with-14-text"}>{info.devRh}</div>
                                                    </div>
                                                    <div>
                                                        <div className="measurement-container">°C</div>
                                                        <div className="measurement-container">%RH</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section> 
                                <div className="live-divider"/>
                                <section className="fourth-line-container">
                                    <div className="device-text">Integrations</div>
                                    <div className={info.smartlocks !== "x 0" ? "smartlock-container" : "smartlocks-disabled"}>
                                        <div className="smart-lock-title">Smart Lock</div>
                                        <div className="smartlock-image-container" onClick={() => smartLockBtn(info.id)}>
                                            <ImageComponent src={info.smartlocks !== "x 0" ? SmartlockIcon : GreySmartlockIcon} alt="icon" style={false} onClick={false}/>
                                            {/* <div className={info.smartlocks !== "x 0" ? "black-text-smartlock" : "grey-text-smartlock"}>{info.smartlocks}</div> */}
                                        </div>
                                    </div>
                                </section>
                            </>
                        )
                            }
                </div>
            )
        }
        {
            isMobile && (
                <div className="asset-card-container">
                    {
                        loading ? (<div style={{position: "relative", right:"2px", top: "40px"}}><Loaders /></div> ) : (
                            <>
                                <section className="second-line-container-mobile">
                                    <p style={{margin: "0px", fontSize:"14px",lineHeight:"16px", color:"#939598"}}>Live Updates</p>
                                    <div className="grid-2-column-live-update-mobile">                
                                            <div style={{ margin: "0px 0px 12px 0px" }}>               
                                                <div className="grid-24-icon-text">
                                                    { settingLocationIcon(info.latitude && info.longitude , info.geolocationStatus).icon }
                                                    <h2 style={{margin: "0px"}}>{info.motionInference}</h2>
                                                </div>
                                            </div>
                                            <div style={{ margin: "0px 0px 16px 0px" }}>
                                                <p style={{margin:"0px", fontSize:"10px", fontWeight: 400, lineHeight:"16px", color:"#939598"}}>{info.timestamp ? `Last updated ${ timestampDisplay }` : `No Recent Activity`}</p>
                                                <div className="grid-16-icon-text-mobile">
                                                    {settingLocationIcon(info.latitude && info.longitude, info.geolocationStatus ? info.geolocationStatus : null).by }
                                                    {settingAccuracy(info.geolocationStatus ? info.geolocationStatus : null, info.geoAccuracy, info.nSat)}
                                                </div>
                                            </div>
                                    </div>
                                    <div className="address-container-mobile">
                                            <p style={{margin: "0px", fontSize:"10px", lineHeight:"16px", color:"#939598"}}>Location</p>
                                            {
                                                info.beacon && (
                                                <div style={{ marginTop: '4px' }} className="warehouse-container-mobile">
                                                    <ImageComponent src={GreenSmallWarehouse} style={{ marginRight: '9px' }} onClick={false} />
                                                    <div>{info.beacon.locationName}</div>
                                                </div>
                                                )
                                            }
                                            {
                                                displayLastSeen && (
                                                <div className="location-lastseen-container">
                                                    <div className="divider-location-lastseen">   </div>
                                                    <ImageComponent src={GreyTime} alt="info" style={{height:"16px", width:"16px"}} onClick={false}/>
                                                    <div className="last-location-details">
                                                        <div className="first-text-location-details">{`Displaying last seen location ${settingTimestampLocation(info.lastSeenTimestamp)}`}</div>
                                                        <div className="details-text-location-details">Unable to obtain GPS Fix. Asset may be located indoors, under a bridge or in a tunnel.</div>
                                                    </div>
                                                </div>                               
                                                )
                                            }
                                            <div className={info.position !== "-" ? "position-container-mobile" : "null-position-container"}>{info.position!== "-" ? <a className="link-container" href={`https://www.google.com/maps/search/?api=1&query=${info.position}`} rel="noopener noreferrer" target="_blank">{info.position}</a> : "-"}</div>
                                            <div className={info.address !== "-" ? "address" : "null-address"}>{info.address}</div>
                                    </div>
                                    <div className="grid-2-column-mobile">
                                        <div>
                                            <div className="speed-asset-icon-container">
                                                <div className="live-icon-title">Speed</div>
                                                <div className="icons-details-with-3-param">
                                                    <ImageComponent src={info.kmh !== "-" ? SpeedIcon : GreySpeedIcon} alt="icon" style={false}  onClick={false}/>
                                                    <div className={info.kmh !== "-" ? "icon-text-details-mobile" :  "null-value-with-24-text-mobile"}>{info.kmh}</div>
                                                    <div className="measurement-container">km/h</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="live-icon-title">Asset Battery</div>
                                                <div className="icons-details-with-3-param">
                                                    {info.dcVoltPerc !== "-" ? settingBatteryIcon(setBatteryStatus(parseInt(info.dcVoltPerc))) : info.dcVoltPerc}
                                                <div className={info.dcVolt !== "-" ? "icon-text-details-mobile": "null-value-with-24-text-mobile"}>{info.dcVolt}</div>
                                                <div className="measurement-container">Volt</div>
                                            </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="live-icon-title">Fuel</div>
                                            <div className="icons-details-with-3-param">
                                                {info.fuelTankPerc ? <ImageComponent src={info.fuelTankPerc >= 70 ? FuelHighIcon : info.fuelTankPerc >= 30 ? FuelMediumIcon : info.fuelTankPerc <= 29 ? FuelLowIcon : FuelLowIcon} alt="icon" style={false} onClick={false}/> : <ImageComponent src={GreyFuel} alt="icon" onClick={false} style={false}/>} 
                                                <div className={info.fuelVolume !== "-" ? "icon-text-details-mobile": "null-value-with-24-text-mobile"}>{info.fuelVolume}</div>
                                                <div className="measurement-container">litres</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="get-current-telemetry-mobile">
                                        <div className="inside-current-telemetry-mobile">
                                            <ImageComponent src={DarkCurrentTelemetry} style={{ marginRight: '16px' }} onClick={false} />
                                            <div>Get Current Telemetry</div>
                                        </div>
                                    </div>
                                </section> 
                                <div className="live-divider"/>
                                <section className="third-line-container">
                                    <div className="device-text">Device</div>
                                    <div className="grid-2-column">
                                        <div>
                                            <div className="device-metadata-container">
                                                <div className="live-icon-title">Device Metadata</div>
                                                <div className="icon-details-with-2-param">
                                                    <ImageComponent src={info.deviceSN !== "-" ? SNIcon : GreySNIcon} alt="icon" style={false}  onClick={false}/>
                                                    <div>
                                                        <div className={info.deviceSN !== "-" ? "live-dvc-container" : "null-live-dvc-container"}>{info.deviceSN}</div>
                                                        {/* <div className="live-SKU-container">{info.dvc_sku}</div> */}
                                                    </div>

                                                </div>
                                            </div>
                                            <div>
                                                <div className="live-icon-title">Network Type</div>
                                                <div className="icon-details-with-2-param">
                                                    { settingSignalQuality(info.signalQuality)}
                                                    <div className="tag-sigfox-cellular">
                                                        <div className={info.connClass ? info.connClass === "sigfox" ? "green-text" : "grey-text" : "grey-text"}>Sigfox</div>
                                                        <div className={info.connClass ? info.connClass === "cellular" ? "green-text" : "grey-text" : "grey-text"}>Cellular</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="speed-asset-icon-container">
                                                <div className="live-icon-title">Device Battery</div>
                                                <div className="icons-details-with-3-param">
                                                    { info.devSuppState === 100 ? 
                                                    <img src={BatteryChargingIcon} alt="batteryCharging"/> : 
                                                    info.devSuppState === 200 && info.devSuppState === 300 ? 
                                                    settingDvcBatteryIcon(setBatteryStatus(parseInt(info.battVoltPerc))) : 
                                                    info.devSuppState === 400 ? <img src={BatteryDebuggingIcon} alt="batteryDebuggin"/> : 
                                                    settingDvcBatteryIcon(setBatteryStatus(parseInt(info.battVoltPerc))) }
                                                    <div className={info.battVolt !== "-" ? "icon-text-details-mobile": "null-value-with-24-text-mobile"}>{ info.battVolt}</div>
                                                    <div className="measurement-container">Volt</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="live-icon-title">Board Condition</div>
                                                <div className="icons-details-with-3-param">
                                                    <ImageComponent src={info.devCelsius === "-" && info.devRh === "-" ? GreyHumidityIcon : HumidityIcon} alt="icon" style={false} onClick={false}/>
                                                    <div>
                                                        <div className={info.devCelsius !== "-" ? "board-container" : "null-value-with-14-text"}>{info.devCelsius}</div>
                                                        <div className={info.devRh !== "-" ? "board-container" : "null-value-with-14-text"}>{info.devRh}</div>
                                                    </div>
                                                    <div>
                                                        <div className="measurement-container">°C</div>
                                                        <div className="measurement-container">%RH</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section> 
                            </>
                        )
                            }
                </div>
            )
        }
        {
            isTablet && (
                <div className="asset-card-container">
                    {
                        loading ? (<div style={{position: "relative", right:"2px", top: "40px"}}><Loaders /></div> ) : (
                            <>
                                <section className="second-line-container-mobile">
                                    <div className="grid-2-column-live-update-mobile">                
                                            <div style={{ margin: "0px 0px 12px 0px" }}>               
                                                <div className="grid-24-icon-text">
                                                    { settingLocationIcon(info.latitude && info.longitude , info.geolocationStatus).icon }
                                                    <h2 style={{margin: "0px"}}>{info.motionInference}</h2>
                                                </div>
                                            </div>
                                            <div style={{ margin: "0px 0px 16px 0px" }}>
                                                <p style={{margin:"0px", fontSize:"10px", fontWeight: 400, lineHeight:"16px", color:"#939598"}}>{info.timestamp ? `Last updated ${ timestampDisplay }` : `No Recent Activity`}</p>
                                                <div className="grid-16-icon-text-mobile">
                                                    {settingLocationIcon(info.latitude && info.longitude, info.geolocationStatus ? info.geolocationStatus : null).by }
                                                    {settingAccuracy(info.geolocationStatus ? info.geolocationStatus : null, info.geoAccuracy, info.nSat)}
                                                </div>
                                            </div>
                                    </div>
                                    <div className="address-container-mobile">
                                            <p style={{margin: "0px", fontSize:"10px", lineHeight:"16px", color:"#939598"}}>Location</p>
                                            {
                                                info.beacon && (
                                                <div style={{ marginTop: '4px' }} className="warehouse-container-mobile">
                                                    <ImageComponent src={GreenSmallWarehouse} style={{ marginRight: '9px' }} onClick={false} />
                                                    <div>{info.beacon.locationName}</div>
                                                </div>
                                                )
                                            }

                                            {
                                                displayLastSeen && (
                                                <div className="location-lastseen-container">
                                                    <div className="divider-location-lastseen">   </div>
                                                    <ImageComponent src={GreyTime} alt="info" style={{height:"16px", width:"16px"}} onClick={false}/>
                                                    <div className="last-location-details">
                                                        <div className="first-text-location-details">{`Displaying last seen location ${settingTimestampLocation(info.lastSeenTimestamp)}`}</div>
                                                        <div className="details-text-location-details">Unable to obtain GPS Fix. Asset may be located indoors, under a bridge or in a tunnel.</div>
                                                    </div>
                                                </div>                               
                                                )
                                            }
                                            <div className={info.position !== "-" ? "position-container-mobile" : "null-position-container"}>{info.position!== "-" ? <a className="link-container" href={`https://www.google.com/maps/search/?api=1&query=${info.position}`} rel="noopener noreferrer" target="_blank">{info.position}</a> : "-"}</div>
                                            <div className={info.address !== "-" ? "address" : "null-address"}>{info.address}</div>
                                    </div>
                                    <div className="grid-2-column-mobile">
                                        <div>
                                            <div className="speed-asset-icon-container">
                                                <div className="live-icon-title">Speed</div>
                                                <div className="icons-details-with-3-param">
                                                    <ImageComponent src={info.kmh !== "-" ? SpeedIcon : GreySpeedIcon} alt="icon" style={false}  onClick={false}/>
                                                    <div className={info.kmh !== "-" ? "icon-text-details-mobile" :  "null-value-with-24-text-mobile"}>{info.kmh}</div>
                                                    <div className="measurement-container">km/h</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="live-icon-title">Asset Battery</div>
                                                <div className="icons-details-with-3-param">
                                                    {info.dcVoltPerc !== "-" ? settingBatteryIcon(setBatteryStatus(parseInt(info.dcVoltPerc))) : info.dcVoltPerc}
                                                <div className={info.dcVolt !== "-" ? "icon-text-details-mobile": "null-value-with-24-text-mobile"}>{info.dcVolt}</div>
                                                <div className="measurement-container">Volt</div>
                                            </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="live-icon-title">Fuel</div>
                                            <div className="icons-details-with-3-param">
                                                {info.fuelTankPerc ? <ImageComponent src={info.fuelTankPerc >= 70 ? FuelHighIcon : info.fuelTankPerc >= 30 ? FuelMediumIcon : info.fuelTankPerc <= 29 ? FuelLowIcon : FuelLowIcon} alt="icon" style={false} onClick={false}/> : <ImageComponent src={GreyFuel} alt="icon" onClick={false} style={false}/>} 
                                                <div className={info.fuelVolume !== "-" ? "icon-text-details-mobile": "null-value-with-24-text-mobile"}>{info.fuelVolume}</div>
                                                <div className="measurement-container">litres</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="get-current-telemetry-mobile">
                                        <div className="inside-current-telemetry-mobile">
                                            <ImageComponent src={DarkCurrentTelemetry} style={{ marginRight: '16px' }} onClick={false} />
                                            <div>Get Current Telemetry</div>
                                        </div>
                                    </div>
                                </section> 
                                <div className="live-divider"/>
                                <section className="third-line-container">
                                    <div className="device-text">Device</div>
                                    <div className="grid-2-column">
                                        <div>
                                            <div className="device-metadata-container">
                                                <div className="live-icon-title">Device Metadata</div>
                                                <div className="icon-details-with-2-param">
                                                    <ImageComponent src={info.deviceSN !== "-" ? SNIcon : GreySNIcon} alt="icon" style={false}  onClick={false}/>
                                                    <div>
                                                        <div className={info.deviceSN !== "-" ? "live-dvc-container" : "null-live-dvc-container"}>{info.deviceSN}</div>
                                                        {/* <div className="live-SKU-container">{info.dvc_sku}</div> */}
                                                    </div>

                                                </div>
                                            </div>
                                            <div>
                                                <div className="live-icon-title">Network Type</div>
                                                <div className="icon-details-with-2-param">
                                                    { settingSignalQuality(info.signalQuality)}
                                                    <div className="tag-sigfox-cellular">
                                                        <div className={info.connClass ? info.connClass === "sigfox" ? "green-text" : "grey-text" : "grey-text"}>Sigfox</div>
                                                        <div className={info.connClass ? info.connClass === "cellular" ? "green-text" : "grey-text" : "grey-text"}>Cellular</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="speed-asset-icon-container">
                                                <div className="live-icon-title">Device Battery</div>
                                                <div className="icons-details-with-3-param">
                                                    { info.devSuppState === 100 ? 
                                                    <img src={BatteryChargingIcon} alt="batteryCharging"/> : 
                                                    info.devSuppState === 200 && info.devSuppState === 300 ? 
                                                    settingDvcBatteryIcon(setBatteryStatus(parseInt(info.battVoltPerc))) : 
                                                    info.devSuppState === 400 ? <img src={BatteryDebuggingIcon} alt="batteryDebuggin"/> : 
                                                    settingDvcBatteryIcon(setBatteryStatus(parseInt(info.battVoltPerc))) }
                                                    <div className={info.battVolt !== "-" ? "icon-text-details-mobile": "null-value-with-24-text-mobile"}>{ info.battVolt}</div>
                                                    <div className="measurement-container">Volt</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="live-icon-title">Board Condition</div>
                                                <div className="icons-details-with-3-param">
                                                    <ImageComponent src={info.devCelsius === "-" && info.devRh === "-" ? GreyHumidityIcon : HumidityIcon} alt="icon" style={false} onClick={false}/>
                                                    <div>
                                                        <div className={info.devCelsius !== "-" ? "board-container" : "null-value-with-14-text"}>{info.devCelsius}</div>
                                                        <div className={info.devRh !== "-" ? "board-container" : "null-value-with-14-text"}>{info.devRh}</div>
                                                    </div>
                                                    <div>
                                                        <div className="measurement-container">°C</div>
                                                        <div className="measurement-container">%RH</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section> 
                            </>
                        )
                            }
                </div>
            )
        }
			<style>
				{`
                    .asset-card-container{
                        display: grid; 
                        grid-template-columns: 1fr; 
                        grid-template-rows: 0fr 2px 0fr 2px 0fr 2px 0fr; 
                        gap: 0px 0px; 
                        grid-template-areas: 
                          "."
                          "."
                          "."
                          "."; 
                    }
                    .buttons{
                        display:flex;
                        justify-content: space-between;
                    }
                    .first-line-container{
                        margin: 0px 0px 24px 0px;
                    }
                    .first-line-container-mobile{
                        margin: 0px 0px 12px 0px;
                    }
                    .second-line-container{
                        margin: 16px 0px 24px 0px;
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 1fr; 
                        grid-template-rows: 0fr 0fr 0fr 0fr; 
                        gap: 0px 0px; 
                        grid-template-areas: 
                          "."
                          "."
                          "."; 
                    }
                    .second-line-container-mobile{
                        margin: 0px 0px 24px 0px;
                    }
                    .first-text-location-details{
                        font-weight: 700;
                        font-size: 9px;
                        line-height: 10px;
                    }
                    .details-text-location-details{
                        font-size: 9px;
                        line-height: 10px;
                    }
                    .third-line-container{
                        margin: 16px 0px 16px 0px;
                    }
                    .asset-name-container{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        min-height: 0px;
                    }
                    .fourth-line-container{
                        margin: 16px 0px 16px 0px;
                    }
                    .live-divider{
                        color: #B0B0B0;
                        background-color: #B0B0B0;
                        height: 1px;
                    }
                    .live-updates-text{
                        margin: 16px 0px 0px 0px;
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 1fr; 
                        grid-template-rows: 0fr 1fr 0fr; 
                        gap: 8px 0px; 
                        grid-template-areas: 
                          "."
                          "."
                          "."; 
                    }
                    .asset-group-container{
                        display: grid; 
                        grid-template-columns: 24px 1fr; 
                        grid-template-rows: 1fr; 
                        gap: 0px 8px; 
                        grid-template-areas: 
                          ". ."; 
                    }
                    .grid-2-column{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: minmax(0, 1fr) minmax( 0, 1fr); 
                        grid-template-rows: 1fr; 
                        gap: 0px 8px; 
                        grid-template-areas: 
                          ". ."; 
                    }
                    .grid-2-column-mobile{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: minmax(0, 1fr) minmax( 0, 1fr); 
                        grid-template-rows: 1fr; 
                        gap: 0px 8px; 
                        grid-template-areas: 
                          ". ."; 
                    }
                    .grid-2-column-live-update{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: minmax(0, 1fr) minmax( 0, 1fr); 
                        grid-template-rows: 1fr; 
                        gap: 0px 8px; 
                        grid-template-areas: 
                          ". ."; 
                        margin: 0px 0px 24px 0px;
                    }
                   .address-container{
                        margin: 0px 0px 24px 0px;
                   }
                   .address-container-mobile{
                        margin: 0px 0px 12px 0px;
                   }
                   .warehouse-container{
                        display: flex;
                        align-item: center;
                        font-size: 14px;
                   }
                   .warehouse-container-mobile{
                        display: flex;
                        align-item: center;
                        font-size: 14px;
                        line-height: 32px;
                   }
                   .null-address{
                        font-size: 12px;
                        line-height: 16px;
                        color: #939598;
                        margin: 0px 0px 24px 0px;
                   }
                   .device-text{
                        color: #939598;
                        font-size: 14px;
                        line-height: 16px;
                        margin:0px 0px 16px 0px;
                   }
                   .address{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        height: 40px;
                        -webkit-line-clamp: 2; /* number of lines to show */
                        -webkit-box-orient: vertical;
                   }
                   .icons-details-with-3-param{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 0fr 0fr 0fr; 
                        grid-template-rows: 1fr; 
                        gap: 0px 8px; 
                        grid-template-areas: 
                        ". . ."; 
                        align-items:center;
                   }
                   .icon-details-with-2-param{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 0fr 0fr;
                        grid-template-rows: 1fr; 
                        gap: 0px 8px; 
                        grid-template-areas: 
                        ". . ."; 
                        align-items: center;
                   }
                   .speed-asset-icon-container{
                        margin: 0px 0px 16px 0px;
                   }
                   .iconButton{
                       cursor: pointer;
                   }
                   .grid-24-icon-text{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 24px 1fr; 
                        grid-template-rows: 1fr; 
                        gap: 0px 8px; 
                        grid-template-areas: 
                        ". ."; 
                        align-items: center;
                        margin: 8px 0px 0px 0px;
                   }
                   .grid-16-icon-text{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 16px 1fr; 
                        grid-template-rows: 1fr; 
                        gap: 0px 8px; 
                        grid-template-areas: 
                        ". ."; 
                        align-items: center;
                        margin: 4px 0px 0px 0px;
                        font-size: 12px;
                   }
                   .grid-16-icon-text-mobile{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 16px 1fr; 
                        grid-template-rows: 1fr; 
                        gap: 0px 14px; 
                        grid-template-areas: 
                        ". ."; 
                        align-items: center;
                        margin: 4px 0px 0px 4px;
                        font-size: 12px;
               }
                   .position-container{
                        font-size: 11px;
                        line-height: 16px;
                        margin: 8px 0px 0px 0px;
                   }
                   .position-container-mobile{
                        font-size: 11px;
                        line-height: 16px;
                        margin: 4px 0px 0px 0px;
                   }
                   .null-position-container{
                        font-size: 11px;
                        line-height: 16px;
                        color: #939598;
                        margin: 8px 0px 0px 0px;
                   }
                   .null-position-container-mobile{
                        font-size: 11px;
                        line-height: 16px;
                        color: #939598;
                        margin: 4px 0px 0px 0px;
                   }
                   .live-icon-title{
                        margin: 0px 0px 4px 0px;
                        font-size: 10px;
                        line-height: 16px;
                        color: #939598;
                   }
                   .smart-lock-title{
                        font-size: 10px;
                        line-height: 16px;
                        color: #939598;
                   }
                   .icon-text-details{
                        font-size: 24px;
                        line-height: 24px;
                        font-weight: 700;
                        height: 24px;
                        width: 80px;
                        color: #333333;
                   }
                   .icon-text-details-mobile{
                        font-size: 16px;
                        line-height: 28px;
                        font-weight: 700;
                        height: 24px;
                        width: 80px;
                        color: #333333;
                    }
                   .measurement-container{
                        font-size: 12px;
                        line-height: 14px;
                        color: #939598;
                        vertical-align: text-top;
                        height: 100%;
                   }
                   .board-container{
                        font-size: 14px;
                        line-height: 16px;
                        font-weight: 700;
                        color: #333333;
                        width: 80px;
                   }
                   .null-value-with-24-text{
                        font-size: 24px;
                        line-height: 24px;
                        height: 24px;
                        width: 80px;
                        font-weight: 700;
                        text-align: center;
                        color: #939598;
                   }
                   .null-value-with-24-text-mobile{
                        font-size: 16px;
                        line-height: 28px;
                        height: 24px;
                        width: 80px;
                        font-weight: 700;
                        text-align: center;
                        color: #939598;
                   }
                   .null-value-with-14-text{
                        font-size: 14px;
                        line-height: 16px;
                        width: 80px;
                        font-weight: 700;
                        text-align: center;
                        color: #939598;
                   }
                   .live-SKU-container{
                        font-size: 12px;
                        line-height: 16px;
                        color: #939598;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 120px;
                   }
                   .live-dvc-container{
                        font-size: 14px;
                        line-height: 16px;
                        color: #333333;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 120px;
                   }
                   .null-live-dvc-container{
                        font-size: 12px;
                        line-height: 16px;
                        color: #939598;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 120px;
                   }
                   .tag-sigfox-cellular{
                    display: flex;
                        background: #DDDDDD;
                        border-radius: 4px;
                        width: 120px;
                        height: 24px;
                   }
                   .green-text{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 60px;
                        font-size: 12px;
                        line-height: 16px;
                        text-align: center;
                        color: #FFFFFF;
                        border-radius: 4px;
                        background-color: #286551;
                   }
                   .grey-text{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 60px;
                        font-size: 12px;
                        line-height: 16px;
                        text-align: center;
                        border-radius: 4px;
                        color: #939598;
                   }
                   .smartlock-container{
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        background: #FFFFFF;
                        border: 1px solid rgb(147, 149, 152, 0.5);
                        border-radius: 4px;
                        width: 70px;
                        height: 54px;
                        padding: 8px; 
                        cursor: pointer;           
                   }
                   .smartlocks-disabled{
                        display: flex;
                        flex-direction: column;
                        border: 1px solid #DDDDDD;
                        border-radius: 4px;
                        width: 206px;
                        height: 54px;
                        padding: 8px; 
                    }
                   .smartlock-container:hover{
                        display: flex;
                        flex-direction: column;
                        background: #FFFFFF;
                        border: 1px solid #FFFFFF;
                        box-shadow: -1px 3px 4px rgba(0, 0, 0, 0.25);   
                        border-radius: 4px;
                        width: 70px;
                        height: 54px;
                        padding: 8px; 
                        cursor: pointer;
                   }
                   .smartlock-image-container{
                        text-align: center;
                        margin: 8px 0px 0px 0px;
                   }

                   .black-text-smartlock{
                       color:#333333;
                       white-space: nowrap;
                       overflow: hidden;
                       text-overflow: ellipsis;
                       width: 175px;
                   }
                   .grey-text-smartlock{
                        color: #939598;
                   }
                   .link-container{
                        text-decoration: none;
                        cursor: pointer;
                        color: #2176FF;
                   }
                   .link-container:hover{
                        text-decoration: none;
                        cursor: pointer;
                        color: #146eff;
                   }
                   .device-metadata-container{
                       margin: 0px 0px 8px 0px;
                   }
                   .paired-asset-live{
                        margin: 8px 0px 0px 0px;
                   }
                   .paired-asset-name{

                   }
                   .paired-asset-name-details{
                        font-size: 14px;
                        line-height: 16px;
                        font-weight: bold;
                        color: #F5A623;
                   }
                   .paired-asset-name-field{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 24px 1fr; 
                        grid-template-rows: 1fr; 
                        gap: 0px 8px; 
                        grid-template-areas: 
                        ". .";
                        align-items: center;
                        margin: 8px 0px 0px 0px;
                   }
                   .paired-asset-description-container{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 4px 16px 1fr; 
                        grid-template-rows: 1fr; 
                        gap: 0px 8px; 
                        grid-template-areas: 
                        ". . .";
                        align-items: center;
                        margin: 8px 0px 0px 0px;
                        background: rgba(245, 166, 35, 0.3);
                   }
                   .location-lastseen-container{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 4px 16px 1fr; 
                        grid-template-rows: 1fr; 
                        gap: 0px 8px; 
                        grid-template-areas: 
                        ". . .";
                        align-items: center;
                        margin: 8px 0px 0px 0px;
                        background: rgba(25, 120, 200, 0.3);
                   }
                   .unnasigned-container{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 4px 16px 1fr; 
                        grid-template-rows: 1fr; 
                        gap: 0px 8px; 
                        grid-template-areas: 
                        ". . .";
                        align-items: center;
                        height: 46px;
                        margin: 8px 0px 0px 0px;
                        background: rgba(234, 43, 31, 0.2);
                   }
                   .divider-location-lastseen{
                        border-left: 4px solid #1978C8;
                        height: 100%;
                        // background-color: #F5A623;                       
                   }
                   .divider-live-paired{
                        border-left: 4px solid #F5A623;
                        height: 100%;
                        // background-color: #F5A623;
                   }
                   .unnasigned-divider-live{
                        border-left: 4px solid #EA2B1F;
                        height: 100%;
                   }
                   .pairing-asset-details{
                        font-style: italic;
                        font-weight: normal;
                        font-size: 11px;
                        line-height: 11px;
                        color: #979797;
                        padding: 8px 4px 8px 0px;
                   }
                   .last-location-details{
                        font-style: italic;
                        font-weight: normal;
                        font-size: 11px;
                        line-height: 11px;
                        color: #979797;
                        padding: 8px 4px 8px 0px;
                    }
                    .add-button-style{
                        cursor: pointer;
                        padding: 2px
                    }
                    .add-hover-button-style{
                        background-color: rgba(0,0,0,0.03);
                        padding: 2px;
                        border-radius: 5px;
                        cursor: pointer;
                    }
                    .get-current-telemetry{
                        display: flex;
                        justify-content: center;
                        cursor: pointer;
                    }
                    .inside-current-telemetry{
                        margin: 18px 0px 0px 0px;
                        display: flex;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 24px;
                        color: #8A8A8A;
                        border: 1px solid #C2C2C2;
                        border-radius: 2px;
                        padding: 4px 21px 4px 21px;
                    }
                    .get-current-telemetry-mobile{
                        display: flex;
                        justify-content: center;
                        cursor: pointer;
                    }
                    .inside-current-telemetry-mobile{
                        margin: 18px 0px 0px 0px;
                        display: flex;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 24px;
                        color: #8A8A8A;
                        border: 1px solid #C2C2C2;
                        border-radius: 2px;
                        padding: 4px 21px 4px 21px;
                    }
            `}
			</style>
        </div>
	);
};

export default AssetDetails;
