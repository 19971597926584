import React from 'react'
import Assembly from './Assembly'

const Board = ({displayed, device, firebaseConfig, valBoard, requiredLabel, settingAssembly, fieldList, setIsHovered, isHovered}) => {
    return (
        <div>
            {
                displayed ? device[0].device_type !== "Smartlocks" ? 
                    <div className="board-container">
                        <div className="box-info">
                            <div className="title">Board</div>
                            <div className="title-detail">{firebaseConfig.getValueByKey("admin_device_board")}</div>
                            {
                                valBoard && valBoard.map((item,i) => (    
                                    <div key={i}>
                                        <Assembly data={item} setAssembly={settingAssembly} index={i} requiredLabel={requiredLabel} fieldList={fieldList} setIsHovered={setIsHovered} isHovered={isHovered}/>
                                    </div>
                                ))
                            } 
                        </div>
                    </div> : null : 
                    <div className="board-container">
                        <div className="box-info">
                            <div className="title">Board</div>
                            <div className="title-detail">{firebaseConfig.getValueByKey("admin_device_board")}</div>
                            {
                                valBoard && valBoard.map((item,i) => (    
                                    <div key={i}>
                                        <Assembly data={item} setAssembly={settingAssembly} index={i} requiredLabel={requiredLabel} fieldList={fieldList} setIsHovered={setIsHovered} isHovered={isHovered}/>
                                    </div>
                                ))
                            } 
                        </div>
                    </div>
            }
        </div>
    )
}

export default Board
