import React from 'react'
import FeatureSelect from '../../component/component-featureselect/FeatureSelect'

const Feature = ({selectedSkuFeatures, featureAdd, featureRemove, setFeatureAdd, setFeatureRemove, displayed, device, feature, setFeature, firebaseConfig, featureOption, setFeatureOption}) => {
    return (
        <div className="features-container">
            <div className="box-info">
                <div className="title">Features</div>
                <div className="title-detail">{firebaseConfig.getValueByKey("admin_device_feature")}</div>
            </div>
            <FeatureSelect staticFeatureList={selectedSkuFeatures} featureAdd={featureAdd} featureRemove={featureRemove} setFeatureAdd={setFeatureAdd} setfeatureRemove={setFeatureRemove} displayed={displayed} data={device} feature={feature} setFeature={setFeature} isSKU={false} setFeatureOption={setFeatureOption} featureOption={featureOption}/>
        </div>
    )
}

export default Feature
