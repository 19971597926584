import React, {useState, useEffect} from 'react'

//library
import { EditingMode, DrawPolygonMode } from "react-map-gl-draw";
import Cookies from 'js-cookie';

//component
import { APIGetGeofence } from '../../config/config-restAPI/getAPICalled';
// import MapSearch from './MapSearch'
import GeofencingList from './GeofencingList'
import Map from './Map'
import SnackBar from '../../config/config-snackbar/SnackBar'

const PanelGeofencing = ({size, featureDisable}) => {
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');

    const [modeId, setModeId] = useState(null)
    const [modeHandler, setModeHandler] = useState(null)
    const [selectedFeatureIndex, setSelectedFeatureIndex] = useState({selectedFeatureIndex : null})
    const [editorRef, setEditorRef] = useState(null)
    const [uuid, setUuid] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [geofenceList, setGeofenceList] = useState([]);
    const [geoJSON, setGeoJSON] = useState(null)
    const [polygons, setPolygons] = useState([])
    const [name, setName] = useState(null)
    // console.log('polygons', polygons);
    //this snOpen, message and severity is for snackbar props
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")

    const [mapping, setMapping] = useState({ width: "100%", height: "100%", latitude: 3.0858333333333334, longitude: 101.58666666666666, pitch: 0, bearing: 0, zoom: 10 })

    const [keySearch, setKeySearch] = useState("")

    useEffect(() => {
        if(modeId){
            setModeHandler(new DrawPolygonMode())
        }
        else{  
            setModeHandler(new EditingMode())
        }
    }, [modeId])

    //searchGeofence API

    const APICalled = async (searchKey, uuidString) => {
        try {
            const result = await APIGetGeofence(token, setLoading, agencyId, searchKey, uuidString)
            // console.log('resu;t', result);
            if (result.data.status === 200) {
                const { data } = result.data;
                setGeofenceList(data);
                setLoading(false);
            } else {
                setLoading(false);
                setError(true);
            }
        } catch(err) {
            setLoading(false);
            setError(true);
            setSnOpen(true);
            setMessage('Error, Please try again');
            setSeverity('error')
        }  
    }

    // this to called the APIGetGeofence function
    useEffect(() => {
      if (token) {
        APICalled('', '');
      }
    }, []);
    
    return (
        <div className="geofence-container">
            <div className="geofence-map" style={{height:`${size}px`}}>
                <SnackBar open={snOpen} setOpen={setSnOpen} severity={severity} message={message}/>
                <Map setModeId={setModeId} modeHandler={modeHandler} setModeHandler={setModeHandler} selectedFeatureIndex={selectedFeatureIndex} setSelectedFeatureIndex={setSelectedFeatureIndex} editorRef={editorRef} setEditorRef={setEditorRef} geoJSON={geoJSON} setGeoJSON={setGeoJSON} setName={setName} name={name} setPolygons={setPolygons} polygons={polygons} setMapping={setMapping} mapping={mapping}/>
            </div>
            <div className="geofence-list" style={{height:`${size}px`}}>
                <GeofencingList setKeySearch={setKeySearch} keySearch={keySearch} searchLoading={loading} setUuid={setUuid} searchError={error} APICalled={APICalled} searchGeofence={geofenceList} modeId={modeId} setModeId={setModeId} modeHandler={modeHandler} setModeHandler={setModeHandler} selectedFeatureIndex={selectedFeatureIndex} setSelectedFeatureIndex={setSelectedFeatureIndex} editorRef={editorRef} setEditorRef={setEditorRef} geoJSON={geoJSON} setGeoJSON={setGeoJSON} setName={setName} name={name} setPolygons={setPolygons} polygons={polygons} setMapping={setMapping} mapping={mapping} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} featureDisable={featureDisable}/>
            </div>
            <style jsx>
            {
                `
                    .geofence-container{
                        display: grid;
                        grid-template-columns: 4fr 468px;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". .";
                    }
                    .geofence-map-search{

                    }
                    .geofence-map{
                        width: 100%;
                    }
                    .geofence-list{
                        display: grid;
                        position: relative;
                        right: 0px;
                        background-color: rgb(243, 243, 243); 
                        overflow: scroll;                     
                    }
                `
            }
            </style>
        </div>
    )
}

export default PanelGeofencing
