import React, { useRef, useLayoutEffect } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

const FuelChart = ({ data, xAxis, yAxis, tags, threshold, speedValue }) => {

    const chart = useRef(null);
    useLayoutEffect(() => {
        am4core.options.minPolylineStep = 5;
        let x = am4core.create("chartdiv", am4charts.XYChart);

        x.paddingRight = 20;;

        x.data = data;

        let dateAxis = x.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.grid.template.disabled = true
        dateAxis.title.text = xAxis
        dateAxis.groupData = true
        dateAxis.min = new Date(speedValue.fromTime).getTime()
        dateAxis.max = new Date(speedValue.toTime).getTime()
        dateAxis.renderer.labels.template.rotation = 270;
        dateAxis.dateFormats.setKey("hour", "hh:mm a");
        dateAxis.periodChangeDateFormats.setKey("hour", "[bold]dd MMM[/]");

        let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;
        valueAxis.title.text = yAxis;

        let series = x.series.push(new am4charts.LineSeries());
        series.dataFields.dateX = "date";
        series.dataFields.valueY = "value";
        series.groupFields.valueY = "mean";
        series.tooltipText = "{valueY.formatNumber('#.00')}";
        series.fillOpacity = 0.2;
        x.cursor = new am4charts.XYCursor();

        let scrollbarX = new am4charts.XYChartScrollbar();
        scrollbarX.series.push(series);
        x.scrollbarX = scrollbarX;

        var scrollAxisX = x.scrollbarX.scrollbarChart.xAxes.getIndex(0);
        scrollAxisX.renderer.labels.template.rotation = 0;
        scrollAxisX.fontSize = 11;

        chart.current = x;
        return () => {
            x.dispose();
        };
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="charts-container">
                <div className="charts">
                    <div id="chartdiv" style={{ width: "100%", height: "100%", alignItems: "center", margin: "0px 0px 0px 0px" }}></div>
                </div>
            </div>

            <style>
                {
                    `
                .charts-container{
                    display: grid;
                    align-items: center;
                    grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
                    grid-auto-rows: 94px;
                    grid-gap: 20px;
                    width: 100%;
                    position: relative;
                    top: 130px;
                    margin-top: 24px;
                    // height: 362px;
                }
                .charts{
                    display: flex;
                    align-items: center;
                    // justify-content: space-between;
                    height: 362px;
                    background-color: #FFFFFF;
                }
                `
                }
            </style>
        </>
    )
}

export default FuelChart
