import React, {useEffect, useState} from "react";
import { useQuery } from "@apollo/react-hooks";

import ChevronLightIcon from '../../Image/chevron_light.svg'

import SnackBar from '../../config/config-snackbar/SnackBar'
import Loaders from "../../component/component-loaders/Loaders"
import Overview from './Overview'
import FunctionAdd from './FunctionAdd'
import FunctionEdit from './FunctionEdit'
import {GET_DRIVER} from "../../config/config-graphql/Graphql";
import { filterList } from "../../config/config-filter/filter"

const PanelDriver = ({fx_changePage, filter, setFilter, featureDisable}) => {
    const [filterFinal, setFilterFinal] = useState({})
    const [preFilter, setPreFilter] = useState({})
    const [canUpdate, setCanUpdate] = useState(false) //default false and also this state for btn save mandatory field
    const [edit, setEdit] = useState(false); //default false and also this state is to open the overview details edit form
    const [editValue, setEditValue] = useState() //this state is to store the all the selected value into the state.,

    const { data : driver_data, loading, error } = useQuery(GET_DRIVER, {variables: {filter: filterFinal}})
  
    //this state for error handling on getQuery
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")

    // !loading && console.log(channel_data)

    // !loading && console.log(driver_data)

    const PreFilter = (value) => {
        setPreFilter({ name: { type: "CONTAINS", value: [ value ] } })
    }


    const EnterFilter =(e) => {
        if(e.key === "Enter"){
            setFilterFinal(preFilter)
        }
    }

    useEffect(() => {
        if(error){
            setSnOpen(true)
            setMessage("Error: Unable to retrieve data. Please try again later.")
            setSeverity("error")
        }
      }, [error])

    // 
    return loading ? <Loaders /> : error ? (<SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/>) : (
        <div>
            <Overview 
             data={driver_data && driver_data.getDrivers !== null ? driver_data.getDrivers : []}  
             title="Driver" 
             addForm={FunctionAdd} 
             editForm={FunctionEdit} 
             Cards={Cards} 
             setMainFilter={setFilter} 
             filterFinal={filterFinal} 
             setFilterFinal={setFilterFinal} 
             preFilter={preFilter} 
             fx_PreFilter={PreFilter} 
             EnterFilter={EnterFilter} 
             keyFilter="name" 
             setPreFilter={setPreFilter} 
             setCanUpdate={setCanUpdate} 
             canUpdate={canUpdate} 
             filterList={filterList.driver}
             edit={edit} 
             setEdit={setEdit} 
             setEditValue={setEditValue} 
             editValue={editValue}
             loading={loading}
             featureDisable={featureDisable}
            />
        </div>
    )
}

export default PanelDriver

const Cards = ({data, setEdit, edit, setEditValue}) => {

    const [isHovered, setIsHovered] = useState({
        container: false,
        details: false
    })


    const btnDetails = (e) => {
        let driver = []
        driver.push(data)
        e.preventDefault(e);
        setEdit(!edit)
        setEditValue(driver)
    }


  return data && (
    <div className="driver-main-container" onMouseEnter={() => setIsHovered((current) => ({...current, container: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, container: false}))}>
        {/* <div className="driver-checkbox-container-channel">
            <input type="checkbox" className="check-type"/>
        </div> */}
        <div className="driver-o-container">
            <section className="driver-name-container">
                {data.firstName} {data.lastName}
            </section>
            <div className="line-box"></div>
            <section className="driver-id-container">
                <div className="driver-id-text">Driver ID</div>
                <div className="driver-id-details">{data.driverId}</div>
            </section>
            <div className="driver-onhover-container">
            
                { isHovered.container && (
                    <div className={`${isHovered.details ? "isHovered-button-edits": "button-edits"}`} onClick={e => btnDetails(e)} onMouseEnter={() => setIsHovered((current) => ({...current, details: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, details: false}))}>
                        <img src={ChevronLightIcon} alt="chev-light"/>
                    </div>
                ) }
            </div>
        </div>

        <style jsx>
        {
          `
            .driver-main-container{
                display: grid;
                height: 64px;
                margin-bottom: 8px;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
                gap: 0px 0px;
                grid-template-areas: ". .";
            }
            .driver-o-container{
                background-color: #FFFFFF;
                border-radius: 4px;
                display: grid;
                align-items: center;
                grid-template-columns: 1fr 1px 1fr 43px;
                grid-template-rows: 1fr;
                gap: 8px 8px;
                grid-template-areas:
                  ". . . .";  
                          
            }   
            .driver-checkbox-container-channel{
                display: flex;
                align-items: center;
            }
            .driver-onhover-container{
                justify-content: flex-end;
                background-color: #FFFFFF;
                width: 100%;
                height: 100%;
                flex-basis: 4%;
            }
            . check-type{
                margin: 0px 4px 0px 0px;
                height: 18px;
                width: 18px;
                background-color: #509D91;
            }  
            .driver-name-container{
                display: flex;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                margin: 0px 0px 0px 8px;
            } 
            .driver-id-container{
                display: flex;
                flex-direction: column;
                margin: 0px 0px 0px 8px;
            }
            .button-edits{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                border-radius: 0 4px 4px 0;
                background-color: #509D91;
                cursor: pointer;
                margin: 0;
            }
            .isHovered-button-edits{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                border-radius: 0 4px 4px 0;
                background-color: #498f84;
                cursor: pointer;
                margin: 0;
                font-weight: 600;
                box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
            }
            .line-box {
                height: 48px;
                margin: 8px;
                border: 1px solid #DDDDDD;
             }
             .driver-id-text{
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                color: #939598;
                height:16px;
                margin: 0px 0px 4px 0px;
             }
             .driver-id-details{
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
             }
          `
        }
        </style>
    </div>
  ) 
}