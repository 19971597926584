/**
 * **The right side content**
 */

import React, {useState, useEffect} from "react";
import {Route, Switch} from 'react-router-dom' ///just update remove for deployment
import Cookies from "js-cookie";
import menuConfig from "../../config/config-menu/menu";
import NoAccess from '../../component/component-no-access/NoAccess'

const Panel = ({ id_module, id_subModule, fx_changePage, page, fx_handleSidebar, filter, setFilter, sidebarFloat, size, history, disableFeature}) => {

    const { PanelSubModule, path, role } = id_module !== null && menuConfig[page][id_module].children.length > 0 ? menuConfig[page][id_module].children[id_subModule] : menuConfig[page][id_module];
    return (
        <div className={`panel-container ${sidebarFloat && "sidebar-float"}`}>
            <div className="panel-content">
                {/* this is panelSubModule component based on menu object component */}
                {/* if the path is empty it will go to no access component*/}
                {/* the reason for id_module > 0 && id_submodule === 0 is if the panel authorized */}
                { PanelSubModule && id_module !== null ?              
                    <Switch>
                        <Route path={`/${page}/${path}`}>
                            <PanelSubModule fx_changePage={fx_changePage} filter={filter} setFilter={setFilter} fx_handleSidebar={fx_handleSidebar} size={size} history={history} featureDisable={disableFeature}/> 
                        </Route>
                    </Switch> : id_module > 0 && id_subModule === 0 ? <Switch><Route><NoAccess size={size}/></Route></Switch> : null
                }
            </div>
            <style>
                {
                    `
                    .panel-container{
                        flex: 5;
                        height: 100%;
                        background-color: rgb(243, 243, 243);
                        transition: width 200ms linear;
                        overflow: overlay;
                        // z-index: -2;
                    }
                    .panel-container.sidebar-float{
                        margin-left: 76px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default Panel
