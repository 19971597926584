import React from 'react'

const UtilisationCard = ({data}) => {
    return(
            <div className="parent-container">
                <div className="utilization-container">
                    <div className="text-container">
                        
                        <p className="first-p">Max Speed</p>
                        <p className="second-p">{data && parseFloat(data.maxSpeed).toFixed(2)} km/h</p>
                    </div>
                </div>
                <div className="utilization-container">
                    <div className="text-container">
                        <p className="first-p">Total Avg Speed</p>
                        <p className="second-p">{data && parseFloat(data.totalAvgSpeed).toFixed(2)} km/h</p>
                    </div>
                </div>
            <style>
            {
                `
                .parent-container{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 12px;
                    grid-template-areas:
                      ". .";
                    margin: 16px 0px 16px 0px;
                }
                .utilization-container{
                    display: flex;
                    height: 86px;
                    width: 100%;
                    background-color: #FFFFFF;
                }
                .text-container{
                    display: grid;
                    place-items: center;
                    width: 100%;             
                }
                .first-p{
                    color: #939598;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 21px;
                    text-align: center;
                    margin: 10px 0px 0px 0px;

                }
                .second-p{
                    color: #333333;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 32px;
                    text-align: center;
                    margin:0px 0px 10px 0px;
                }
                `
            }
            </style>
        </div>
    )
}

export default UtilisationCard
