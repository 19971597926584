import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import SideBar from "../component-sidebar/Sidebar";
import Panel from "../component-panel/Panel";
import TopBar from "../component-topbar/Topbar";
import { useMediaQuery } from 'react-responsive';
import menuConfig from "../../config/config-menu/menu";
import Cookies from "js-cookie";
import { firebaseContext } from '../../config/config-firebase/FirebaseContext'
import {WebsocketContext} from "../../config/config-websocket/Websocket"



export default function Layout({ page, history }) {
    const isDefault = useMediaQuery({ minWidth: 1025 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    const firebaseConfig = useContext(firebaseContext)
    const payloadContext = useContext(WebsocketContext);    
    const { closeAssetCard, openAssetGroupCard} = payloadContext;
    const [size, setSize] = useState(0);
    const [activePanel, setActivePanel] = useState(0);
    const [activeSubModule, setActiveSubModule] = useState(0);
    const [collapseStatus, setCollapseStatus] = useState(false); //if collapse status true, it will collapse on mouse exit
    const [filter, setFilter] = useState({})
    const [sidebarFloat, setSidebarFloat] = useState(false)
    const [disableFeature, setDisableFeature] = useState(false)
    const cookRoles = Cookies.get("role")




    // console.log(window.location.href)
    const handlePanel = (index) => {
        setActivePanel(index);
        //this to only to render the sidebar menu list based on firebase remote config == true
        if(menuConfig[page][index].children){
            const subPanel = [ ...menuConfig[page][index].children]
            for(let i=0; i<subPanel.length; i++){
                if(firebaseConfig.getValueByKey(subPanel[i].firebaseKey) === "true"){
                    setActiveSubModule(i);
                    break;
                }
            }
        }
        
        closeAssetCard();//this to close live asset card when go to different panel module
        openAssetGroupCard(false,{}) //this to close live asset group gard when go to different panel module
    }


    useEffect(() => {
        if(cookRoles === "Agency Viewer"){
            setDisableFeature(true)
        }
    }, [cookRoles])




    //this to transfer the menu child index into setActiveSubModule index
    //onClick from sidebar handleSubmodule
    //this to display the panel (subModule)
    const handleSubModule = (index, parentIdx) => { 
        setActiveSubModule(index); //to activate the sidebar selection
        const {path} = menuConfig[page][parentIdx].children[index] //
        history.push(`/${page}/${path}`) // based onClick
    }

    /**
     * **This function only for view all**
     * @param _module = id for parent module
     * @param subModule = id for children module
     * this only applicable for panel and topBar
     * this function can be use for shortcut button
     */
    const handlePage = (_module, subModule) => {
        menuConfig[page].map((d, i) => {
            d.key === _module && setActivePanel(i)
            d.children.map((c, id) => {
                c.key === subModule && setActiveSubModule(id)
                return c;
            })
            return d;
        })
    }

    //this is to handle the sidebar collapse
    const handleSidebar = (value=null) => {
        value === null ? setCollapseStatus(!collapseStatus) : setCollapseStatus(value);
    }

        //-----------------this is new-----------------//
    //this to check default value when refreshed page //
    useEffect(() => {
        if(firebaseConfig.firebaseConfig){

            const URL = window.location.hostname === "localhost" ? "http://localhost:3000" : process.env.REACT_APP_MAIN_URL //this 

            outerloop: for(let i=0; i<menuConfig[page].length; i++){    //to loop the menuConfig  
                if(menuConfig[page][i].role.includes(cookRoles)){ //check if the parent role same as cookie role and firebaseConfig value is true
                    setActivePanel(i) // this to take the 
                    for(let j=0; j<menuConfig[page][i].children.length; j++){
                        const path = menuConfig[page][i].children[j].path
                        if(menuConfig[page][i].children[j].role.includes(cookRoles) && (window.location.href === `${URL}/${page}/${path}`)){
                            if(history.action === "POP"){ //this to check for the back and forward button 
                                setActiveSubModule(j)
                                history.replace(history.location.pathname, /* the new state */);
                            }else{
                                setActiveSubModule(j) //this to refesh based on the link
                            }
                            break outerloop;
                        }              
                    }
                    
                }
            } 
        }

    }, [firebaseConfig.firebaseConfig, history.location])



    // //this useEffect render the menu list based on firebase remote config == true
    // useEffect(() => {

    //     if(firebaseConfig.firebaseConfig){
    //         // console.log("go here if change")
    //         //this is to determined the default after login page this is for states.
    //         outerloop: for(let i=0; i<menuConfig[page].length; i++){    //to loop the menuConfig        
    //             if(menuConfig[page][i].role.includes(cookRoles) && firebaseConfig.getValueByKey(menuConfig[page][i].firebaseKey) === "true"){ //check if the parent role same as cookie role and firebaseConfig value is true
    //                 setActivePanel(i); //this to make the default for parent menu
    //                  for(let j=0; j<menuConfig[page][i].children.length; j++){ //for loop for children
    //                     if(menuConfig[page][i].children[j].role.includes(cookRoles) && firebaseConfig.getValueByKey(menuConfig[page][i].children[j].firebaseKey) === "true"){ //check if the children role same as cookie role and firebaseconfig value is true
    //                         setActiveSubModule(j) // make a default value for children menu
    //                         break outerloop;
    //                     }
    //                 }
    //             }
    //         }  
            
    //     }
    //     //---this is to disable the warning for useEffect----//
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [firebaseConfig.firebaseConfig])



    // console.log({
    //     isDefault, isMobile, isTablet
    // })

    ///--------------------------------------------//

    ///--------------this is for responsive height to fixed the empty space in big screen
    useLayoutEffect(() => {
        function updateSize() {
          if(isDefault) {
            setSize(window.innerHeight - 56); //take the windows height and - 56px. The 56px are height from top bar
          }
          if (isMobile || isTablet) {
              setSize(window.innerHeight - 48); //take the windows height and - 48px. The 48px are height from top bar
          }
        }
        window.addEventListener('resize', updateSize);
        updateSize(); //update the size based on screen change
        return () => window.removeEventListener('resize', updateSize); //return the updated height value, this value will add in div elements for sideBar and Panel
      }, []);

    return (
        <div>
            {
                isDefault && (
                    <div className="master-layout">         
                        <TopBar  id_module={activePanel} id_subModule={activeSubModule} page={page} fx_changePage={handlePage} fx_handleSidebar={handleSidebar} collapseStatus={collapseStatus} history={history}/>
                        <div className="content-container" style={{height: `${size}px`}}>
                            <SideBar active={activePanel} handlePanel={handlePanel} activeSubModule={activeSubModule} handleSubModule={handleSubModule} page={page} setCollapseStatus={setCollapseStatus} collapseStatus={collapseStatus} setFilter={setFilter} float={sidebarFloat} setFloat={setSidebarFloat} setActivePanel={setActivePanel} activePanel={activePanel}/>
                            <Panel id_module={activePanel} id_subModule={activeSubModule} fx_changePage={handlePage} page={page} fx_handleSidebar={handleSidebar} collapse={collapseStatus} filter={filter} setFilter={setFilter} sidebarFloat={sidebarFloat} size={size} history={history} disableFeature={disableFeature}/>           
                        </div>
                    </div>
                )
            }
            {
                isMobile && (
                    <div className="master-layout-mobile">         
                        <TopBar  id_module={activePanel} id_subModule={activeSubModule} page={page} fx_changePage={handlePage} fx_handleSidebar={handleSidebar} collapseStatus={collapseStatus} history={history}/>
                        <div className="content-container" style={{height: `${size}px`}}>
                            {/* <SideBar active={activePanel} handlePanel={handlePanel} activeSubModule={activeSubModule} handleSubModule={handleSubModule} page={page} setCollapseStatus={setCollapseStatus} collapseStatus={collapseStatus} setFilter={setFilter} float={sidebarFloat} setFloat={setSidebarFloat} setActivePanel={setActivePanel} activePanel={activePanel}/> */}
                            <Panel id_module={activePanel} id_subModule={activeSubModule} fx_changePage={handlePage} page={page} fx_handleSidebar={handleSidebar} collapse={collapseStatus} filter={filter} setFilter={setFilter} sidebarFloat={sidebarFloat} size={size} history={history} disableFeature={disableFeature}/>           
                        </div>
                    </div>
                )
            }
            {
                isTablet && (
                    <div className="master-layout-mobile">         
                        <TopBar  id_module={activePanel} id_subModule={activeSubModule} page={page} fx_changePage={handlePage} fx_handleSidebar={handleSidebar} collapseStatus={collapseStatus} history={history}/>
                        <div className="content-container" style={{height: `${size}px`}}>
                            {/* <SideBar active={activePanel} handlePanel={handlePanel} activeSubModule={activeSubModule} handleSubModule={handleSubModule} page={page} setCollapseStatus={setCollapseStatus} collapseStatus={collapseStatus} setFilter={setFilter} float={sidebarFloat} setFloat={setSidebarFloat} setActivePanel={setActivePanel} activePanel={activePanel}/> */}
                            <Panel id_module={activePanel} id_subModule={activeSubModule} fx_changePage={handlePage} page={page} fx_handleSidebar={handleSidebar} collapse={collapseStatus} filter={filter} setFilter={setFilter} sidebarFloat={sidebarFloat} size={size} history={history} disableFeature={disableFeature}/>           
                        </div>
                    </div>
                )
            }
            <style>
                {
                    `
                    .content-container{                
                        display: flex;
                    }
                    body {
                        color: rgba(0, 0, 0, 0.87);
                        margin: 0;
                        font-size: 0.875rem;
                        font-family: "Roboto", "Arial", "sans-serif";
                        font-weight: 400;
                        line-height: 1.43;
                        letter-spacing: 0.01071em;
                        background-color: #fafafa;
                    }
                    .remove-border{
                        outline: 0 !important;
                    }
                    .master-layout{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 1fr; 
                        grid-template-rows: 56px 1fr; 
                        gap: 0px 0px; 
                        grid-template-areas: 
                          "."
                          "."; 
                    }
                    .master-layout-mobile{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 1fr; 
                        grid-template-rows: 48px 1fr; 
                        gap: 0px 0px; 
                        grid-template-areas: 
                          "."
                          ".";   
                    }
                    `
                }
            </style>
        </div>
    )
}