export const setColumn = (data) => {
    let column = [];
    for(let x in data){
        if(x !== "__typename") {
            let row = {};
            row.text = x.replace("_", " ").toUpperCase();
            row.dataField = x;
            column.push(row);
        }
    }
    return column;
}

export const refactorName = (data, strInitial) => {
    let final_result = [];
    data.map((row) => {
        let result = {}
        for(let x in row){
            result[x.replace(strInitial,"")] = row[x];
        }
        final_result.push(result);
        return row;
    })
    return final_result;
}

export const customizeSelectRow = (columns, selectedRow, setSelectedRow) => {
    return {
        mode: "checkbox",
        onSelect: (row, isSelect, index) => {

            let result = {};
            let selected = [...selectedRow];
            columns.map(({ dataField }) => result[dataField] = row[dataField]);
            isSelect ?  selected.push(result) : selected.splice(selected.findIndex(x => x.uuid === row.uuid), 1);
            setSelectedRow(selected);
        },
        onSelectAll: (isSelect, rows, e) => {
            let selected = isSelect ? [ ...rows ] : [];
            setSelectedRow(selected)
        },
    }
}
export const getRequiredField = (obj) => {
    let result = [];
    for(let key in obj){
        if(obj[key].required){
            result.push(obj[key].name)
        }
    }
    return result;
}
export const settingFieldValue = (isBulk, obj, key) => isBulk ? settingBulkFieldValue(obj, key) : Array.isArray(obj) ? obj[0][key] : obj[key];
const settingBulkFieldValue = (obj, key) => obj.every((val, i, arr) => val[key] === arr[0][key]) ? obj[0][key] : "";
export const AllMandatoryFilled = (requiredField, input) => {

    return requiredField.every(a => {
        // console.log("function", input)
        // console.log(Object.keys(input))
        if(Object.keys(input).includes(a) && input[a]){          
            if(Array.isArray(input[a])){
                // console.log("go here array is array")
                return input[a].length > 0
            }
            // console.log("go here")
            return true;
        }
        // console.log("go here false")
        return false
    })
}

//requiredLable for add in required symbol input
export const requiredLabel = (required) => required ? "*" : "";
export const settingInputPlaceholder = (isBulkEdit, isAutocomplete) => isBulkEdit ? "multiple selected" : isAutocomplete ? "search" : "enter text"

export const isEmptyObject = (obj) => Object.keys(obj).length === 0 ? true : false;
export const isEmptyArray = (arr) => Array.isArray(arr) && arr.length === 0;

//value is from input, values is the state that keep the changes
export const onUserEdit = (e, value, isValue, onChange, values) => {
    const _values = [...values];
    if(isValue){
        _values.map((x,i) => { 
            onChange(e, i, {...value}); 
            return true
        })
    }else {
        _values.map((x,i) => { 
            x[e.target.name] = e.target.value; onChange(e, i); 
            return true
        })
    }
    return _values;
}

//value is from input, values is the state that keep the changes
export const onUserAdd = (e, value, isValue, onChange, values) => {
    let input = null;
    if(isValue){  
        onChange(e, { ...value })
        input = { ...values,  ...value };
    }else{
        onChange(e)
        input = { ...values, [e.target.name] : e.target.value };
    }
    return input;
}