import React, { useContext, useState, useEffect } from 'react'
import AssetGroupIcon from "../../Image/asset_groups.svg";
import AssetIcon from "../../Image/asset.svg";
// import PaireAssetDarkIcon from "../../Image/pair_dark.svg"
import { SEARCH_ASSET_N_GROUPS } from  '../../config/config-graphql/Graphql'
import { useQuery } from '@apollo/react-hooks';
// import Loader from "./Loader";
import { WebsocketContext } from "../../config/config-websocket/Websocket";
import OnHoverScrollContainer from "../../component/component-onHoverScroll/CustomScrollDiv"
import Loaders from '../../component/component-loaders/Loaders'

const ListCard = ({ viewGroup, setViewGroup, viewSearchResult, setViewSearchResult, searchVariables, setviewLive, viewLive, setGroupName}) => {
    const {data: searchData, loading: searchLoading} = useQuery(SEARCH_ASSET_N_GROUPS, {variables: {search_key: searchVariables, searchType: "ALL"} } )

    const [assetData, setAssetData] = useState([])
    const [groupData, setgroupData] = useState([])

    const searchAsset = !searchLoading && searchData && searchData.searchAssetnGroups && searchData.searchAssetnGroups.asset ? searchData.searchAssetnGroups.asset.map((asset) => {return asset && asset.name ? {blobID: asset.blobID, uuid: asset.uuid, name: asset.name, paired_assets: asset && asset.paired_assets &&  asset.paired_assets.length > 0 ? asset.paired_assets.map(x => {return {blobID: x.pairId, uuid: x.uuid, name:x.name}}) : []} : {blobID: "", uuid: "", name: "", paired_assets: []}}) : []

    const searchGroup = !searchLoading && searchData && searchData.searchAssetnGroups && searchData.searchAssetnGroups.group ? searchData.searchAssetnGroups.group.map((group) => {return group && group.name ? {uuid: group.uuid, name: group.name, description: group.description} : {uuid: "", name: "", description: ""}}) : []

    //useEffect for combine pair_asset and asset
    useEffect(() => {

        //take the searchAsset value and iterate it
        const mappedAssetIds = searchAsset.map(x => {
            return [{blobID: x.blobID, uuid: x.uuid, name: x.name },...x.paired_assets]
        }) 

        //make it flat (pair asset will add into new index)
        const flatAssetIds = {asset : mappedAssetIds.flat(2)} 

        setAssetData(flatAssetIds.asset)
        setgroupData(searchGroup)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchLoading])


    return (
        <div>
            <OnHoverScrollContainer>

            <div className={`asset-list-container ${viewSearchResult && "asset-list-active"}`}id="scrollable">
                {
                  searchLoading ? (<div style={{position:"relative", bottom: "20px"}}><Loaders /></div>) : (
                 <>

                    <div className="asset-list-content">
                            <div className="asset-list-title-id">
                                {
                                    assetData.length === 0 && searchGroup.length === 0 ? (
                                        <p>No Search Found</p>
                                    ) : (
                                        <div>
                                            <div>
                                                {
                                                    assetData.length > 0 ? <List assetData={assetData} setViewSearchResult={setViewSearchResult} setViewGroup={setViewGroup} setviewLive={setviewLive} viewLive={viewLive} setGroupName={setGroupName}/> :  searchGroup.length > 0 ? "" : <p>No Search found</p>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    searchGroup.length > 0 ?  <List data={groupData} setViewSearchResult={setViewSearchResult} setViewGroup={setViewGroup} setviewLive={setviewLive} viewLive={viewLive} setGroupName={setGroupName}/> : assetData.length > 0 ? "" : <p>No Search found</p>
                                                }
                                            </div>
                                        </div>
                                    )
                                }        
                                    
                            </div>
                        </div>

                </>
                 )

                }
            </div>
            <style jsx>
                {
                    `
                    .asset-list-container::-webkit-scrollbar {
                        display: none;
                    }
                    .asset-list-container{
                        // border-top-right-radius: 0px;
                        // border-top-left-radius: 0px;
                        // border-bottom-left-radius: 5px;
                        // border-bottom-right-radius: 5px;
                        // position: relative;
                        // background-color: white;
                        // min-height: 100px;
                        // max-height: 430px;
                        // overflow: auto;
                        // scrollbarwidth: none;
                        // msoverflowstyle: none;
                        // padding: 10px;
                        // box-sizing: border-box;
                        // visibility: hidden;
                        // opacity: 0;
                        // transition: visibility 0s, opacity 0.5s linear;
                        // box-shadow: 0px 1px 3px 0px #00000020;
                        position: relative;
                        background-color: white;
                        width: 100%;
                        // left: 4px;
                        min-height: 100px;
                        max-height: 430px;
                        overflow: auto;
                        scrollbarwidth: none;
                        msoverflowstyle: none;
                        padding: 10px;
                        box-sizing: border-box;
                        border-top-right-radius: 0px;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                        margin: 0px 0px 0px 4px;
                        transition: visibility 0s, opacity 0.5s linear;
                        box-shadow: 0px 1px 3px 0px #00000020;
                        z-index: 6;
                        cursor: pointer;
                    }

                    .asset-list-container.asset-list-active{
                        visibility: visible;
                        opacity: 1;
                        transition: visibility 1s, opacity 0.5s linear;
                        margin: 0px 0px 0px 0px;
                    }
                    .asset-list-content{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }
                    .asset-list-title-id{
                        display: flex; 
                        flex-direction: column;
                    }
                    .asset-list-details{
                        margin: 5px;
                        height: 30px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        border-radius: 5px;
                        cursor: pointer;
                        position: relative;
                        right: 10px;
                    }
                    .asset-list-details img{
                        width: 25px;
                    }
                    // .icon-label{
                    //     border-radius: 5px;
                    //     display: flex;
                    //     flex-direction: row;
                    //     align-items: center;
                    //     height: 30px;
                    //     padding: 10px;
                    //     box-sizing: border-box;
                    // }
                    // .icon-label .asset-name{
                    //     font-size: 15px;
                    //     line-height: 23px;
                    //     color: #3A3A3A;
                    //     margin-right: 10px;
                    //     margin-left: 10px;
                    // }
                    // .icon-label .asset-id{
                    //     color: #939598;
                    //     position: relative;
                    //     top: 1px;
                    //     right: 2px;
                    //     font-size: 14px;
                    //     line-height: 17px;
                    // }
                    `
                }
            </style>

            </OnHoverScrollContainer>
        </div>
        
    )
}


const List = ({ data, assetData, viewGroup, setViewGroup, setViewSearchResult, setviewLive, viewLive, setGroupName}) => {
    const payloadContext = useContext(WebsocketContext);    
    const { selectAsset, openAssetGroupCard } = payloadContext;

    const onClickAsset = (e, data) => {
        e.preventDefault()
        selectAsset({ pairId: data.blobID,  getAssetInfo: false }, {asset_id: data.uuid, asset_name: data.name, pairId: data.blobID})
        setViewSearchResult(false)
    }


    const onClickGroup = (e, data) => {
        // console.log(data)
        e.preventDefault()
        setViewSearchResult(false)
        setGroupName(data.name)
        setViewGroup(true)
        openAssetGroupCard(true, {name: data.name})
    }

    return(
        <>
            <div className="asset-list-title-id">
                {

                        <div>
                            {
                               typeof assetData !== "undefined" && assetData.map((docs, index) => {
                                    return(
                                        <div className="asset-list-details" onClick={e => {onClickAsset(e, docs)}}>
                                            <div className="icon-label">
                                                <img src={AssetIcon} loading="lazy" alt="asset-icon"/>
                                                <div className="asset-name">{docs && docs.name ? docs.name : ""}</div>
                                         </div>
                                    </div>

                                    )
                                } ) 
                            } 
                            {
                              typeof data !== "undefined" && data.map((docs, index) => {
                                    return(
                                        <div className="asset-list-details" onClick={e => {onClickGroup(e, docs)}}>
                                            <div className="icon-label">
                                                <img src={AssetGroupIcon} loading="lazy" alt="asset-icon"/>
                                                <div className="asset-name">{docs.name}</div>

                                            </div>
                                    </div>

                                    )
                                })
                            }                           
                        </div>
                }

            </div>
            <style>
                {
                    `
                    .asset-list-content{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }
                    .asset-list-title-id{
                        display: flex; 
                        flex-direction: column;
                    }
                    .asset-list-details{
                        margin: 5px;
                        height: 30px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        border-radius: 5px;
                        cursor: pointer;
                        position: relative;
                        right: 10px;
                    }
                    .asset-list-details img{
                        width: 25px;
                    }
                    .icon-label{
                        border-radius: 5px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        height: 30px;
                        padding: 10px;
                        box-sizing: border-box;
                    }
                    .icon-label .asset-name{
                        font-size: 14px;
                        line-height: 23px;
                        color: #3A3A3A;
                        margin-right: 10px;
                        margin-left: 10px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 120px;
                    }
                    .icon-label .asset-id{
                        color: #939598;
                        position: relative;
                        top: 1px;
                        right: 2px;
                        font-size: 12px;
                        line-height: 17px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 120px;
                    }
                    `
                }
            </style>
        </>
    )
}

export default ListCard
