import React, {useState, useEffect} from 'react'

//library
import BaseTable, { AutoResizer } from 'react-base-table'
import 'react-base-table/styles.css'
import moment from "moment-timezone";
import styled from 'styled-components'
import ReactTexty from 'react-texty'

//Images
import ExportIcon from '../../Image/export.svg'
import NavigationIcon from "../../Image/chevron_right.svg"

//component
import NoReportData from '../../component/component-noreport/NoReportData'
import SnackBar from '../../config/config-snackbar/SnackBar'




const Container = styled.div`
    width: 100%; 
    height: 66vh;  
`


const alert_report = [
    { key: "time", title: "Date/Time", dataKey: "time", width: 250, flexGrow: 1, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> },
    { key: "position", title: "Position", dataKey: `position`, width: 150, flexGrow: 1, cellRenderer: ({ cellData }) => <a href={`https://www.google.com/maps/search/?api=1&query=${cellData}`} rel="noopener noreferrer" target="_blank">{cellData}</a> },
    { key: "asset", title: "Asset", dataKey: "asset", width: 150, flexGrow: 1, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> },
    { key: "violation", title: "Violation", dataKey: "violation", width: 150, flexGrow: 1, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> }
]

const TableDriver = ({ speed, reportData, loading, fx_back, reportError }) => {


    //export csv file
    let csvFile = "Date,Latitude, Longitude, Asset, Violation\r\n"

    !loading && reportData && reportData.getAlertsRpt && reportData.getAlertsRpt !== null && reportData.getAlertsRpt.map(x => csvFile += `${moment(x.time).format("X") || "N/A"},${parseFloat(x.latitude).toFixed(6) || "N/A"},${parseFloat(x.longitude).toFixed(6) || "N/A"},${x.asset || "N/A"},${x.violation || "N/A"}\r\n`)

    const url = `data:text/json;charset=utf-8,${encodeURIComponent(csvFile)}`

    // console.log(reportData)

    //this state for error handling on getQuery
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")

    useEffect(() => {
        if(reportError){
            setSnOpen(true)
            setMessage("Error: Unable to retrieve data. Please try again later.")
            setSeverity("error")
        }
    }, [reportError])


    const setData = (data) => {
        if (!loading) {
            return data && data.map((docs, key) => {
                return {
                    key: key,
                    time: `${docs.time ? moment(docs.time).format('DD MMMM YYYY, hh:mm:ss a') : '-'}`,
                    position: `${docs.latitude !== 0 && docs.longitude !== 0 ? docs.latitude !== null && docs.longitude !== null ? `${parseFloat(docs.latitude).toFixed(6)},${parseFloat(docs.longitude).toFixed(6)}` : "-" : "-"}`,
                    asset: `${docs.asset ? docs.asset : '-'}`,
                    violation: `${docs.violation ? docs.violation === "harsh_acceleration" ? "Harsh Acceleration" : docs.violation === "harsh_braking" ? "Harsh Braking"  :  docs.violation === "none" ? "None" : "-" : '-'}`,
                }
            })


        }
    }
    return (
        <div className="telemetry-table-container">
            <div className="title-log">
                <div className="telemetry-title">
                    <div className="report-title" onClick={() => fx_back()}>Alert Log</div>
                    <div className="icon-title">
                        <img loading="lazy" src={NavigationIcon} alt="nav-icon" />
                    </div>
                    <div className="asset-title-name">
                        <div>{speed.alertType.name}</div>
                    </div>
                </div>
                <div className="export-container">
                    <img loading="lazy" src={ExportIcon} alt="export-icon" />
                    &nbsp; <a href={url} download="AlertReport.csv" style={{ alignItems: "center", verticalAlign: "bottom" }}>Export Log</a>
                </div>
            </div>
            {
                reportError ? <SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/> : !loading && reportData && reportData.getAlertsRpt && reportData.getAlertsRpt !== null && reportData.getAlertsRpt.length > 0 ?
                    (
                        <div className="white-box">
                            <Container>
                                <AutoResizer>
                                    {({ width, height }) => (
                                        <BaseTable columns={alert_report} width={width} height={height} data={setData(reportData.getAlertsRpt)} />
                                    )}
                                </AutoResizer>
                            </Container>
                        </div>
                    ) : <NoReportData />
            }
            <style>
                {
                    `
                    .telemetry-table-container{
                        padding: 16px;
                        // display: flex;
                        // align-items: center;
                    }
                    .header-table{
                        margin: 8px 0px 8px 0px;
                        margin: 
                        height: 51px;
                        border-radius: 4px 4px 0 0;
                        background-color: #FFFFFF;
                        display: grid;
                        grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". . . . . . . .";
                    }
                    .white-box{
                        height: 66vh;
                        background-color: #FFF;
                        overflow: auto;
                    }
                    .card-table{
                        height: 34px;
                        border-radius: 4px 4px 0 0;
                        background-color: #FFFFFF;
                        display: grid;
                        grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". . . . . . . .";
                    }
                    .card-body{
                        border-bottom: 1px solid #DDDDDD;
                        display: flex;
                        align-items: center;
                        padding: 16px;
                        color: #333333;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .img-icon{
                        margin: 0px 4px 0px 4px;
                    }
                    .text-header{
                        display: flex;
                        align-items: center;
                        padding: 16px;
                        color: #333333;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .place-button{
                        position: relative;
                        bottom:2px;
                        // width: 16px;
                        // height: auto;
                    }
                    .wrapper{
                        word-wrap: break-word
                    } 
                    .card-asset-row{
                        // margin: 0px 0px 8px 0px;
                        display: flex;
                        flex-direction: column;
                        color: #424242;
                        padding: 8px 0px 0px 16px;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .date-time-asset{
                        color: #424242;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .telemetry-title{
                        display: flex;
                        align-items: center;
                    }
                    .icon-title{
                        margin: 0px 8px 0px 0px;
                    }
                    .asset-title-name{
                        font-size: 18px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 24px;
                    }
                    .report-title{
                        margin: 0px 8px 0px 0px;
                        color: #333333;
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 21px;
                        cursor: pointer;
                    }
                    .export-css{
                        color: #333333;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19p
                    }
                    .export-container{
                        display: flex;
                        align-items: center;
                    }
                    .title-log{
                        display: flex;
                        justify-content: space-between;
                        margin: 0px 0px 16px 0px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default TableDriver
