import React, {useState, useEffect}  from 'react'

//library
import moment from "moment-timezone";

//image
import NavigationIcon from "../../Image/chevron_right.svg"
import ExportIcon from '../../Image/export.svg'

//component
import ReportAssetChart from "./ReportAssetChart"
import ReportTable from "./ReportTable"
import UtilisationCard from './UtilisationCard'
import NoReportData from '../../component/component-noreport/NoReportData'
import SnackBar from '../../config/config-snackbar/SnackBar'


const TableCard = ({ reportData, fx_back, loading, speed, reportError }) => {

    //export csv file
    let csvFile = "Time,Duration,Rate(%)\r\n"

    !loading && reportData && reportData.getAssetUtilisationRpt && reportData.getAssetUtilisationRpt.asset_utilisation !== null && reportData.getAssetUtilisationRpt.asset_utilisation.map(x => csvFile += `${moment(x.time).format("X") || "N/A"},${parseFloat(x.duration).toFixed(2) || "N/A"},${parseFloat(x.rate).toFixed(2) || "N/A"}\r\n`)

    const url = `data:text/json;charset=utf-8,${encodeURIComponent(csvFile)}`

    //this state for error handling on getQuery
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")

    useEffect(() => {
        if(reportError){
            setSnOpen(true)
            setMessage("Error: Unable to retrieve data. Please try again later.")
            setSeverity("error")
        }
    }, [reportError])

    return (
        <div>
            <div className="asset-utilisation-table-container">
                <div className="title-log">
                    <div className="asset-utilisation-title">
                        <div className="report-title" onClick={() => fx_back()}>Asset Utilisation</div>
                        <div className="icon-title">
                            <img loading="lazy" src={NavigationIcon} alt="nav-icon" />
                        </div>
                        <div className="asset-utilisation-title-name">
                            <div>{speed.value.name}</div>
                        </div>
                    </div>
                    <div className="export-container">
                        <img loading="lazy" src={ExportIcon} alt="export-icon" />
                        &nbsp; <a href={url} download="AssetUtilisationReport.csv" style={{ alignItems: "center", verticalAlign: "bottom" }}>Export Log</a>
                    </div>
                </div>

                {
                    reportError ? <SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/> :
                        !loading && reportData && reportData.getAssetUtilisationRpt && reportData.getAssetUtilisationRpt !== "null" ? (
                            <div className="result-containr">
                                <UtilisationCard data={reportData.getAssetUtilisationRpt} />
                                <ReportAssetChart data={reportData.getAssetUtilisationRpt.asset_utilisation.map((x, i) => { return { date: new Date(x.time).getTime(), value: x.duration } })} /* xAxis="Datetime" */ yAxis="Aggregated Duration (Min)" speedValue={speed} />
                                <ReportTable data={reportData.getAssetUtilisationRpt.asset_utilisation.map((x, i) => { return { id: i, time: moment(x.time).format('DD MMM YYYY, hh:mm:ss a'), duration: parseFloat(x.duration).toFixed(2), rate: parseFloat(x.rate).toFixed(2) } })} />
                            </div>

                        ) : (<NoReportData />)
                }


            </div>
            <style>
                {
                    `
                        .result-container{
                            display: grid;
                            grid-template-columns: 1fr;
                            grid-template-rows: 1fr 1fr 1fr;
                            gap: 16px px;
                            grid-template-areas:
                                "."
                                "."
                                ".";                        
                        }
                        .asset-utilisation-table-container{
                            padding: 16px;
                        }
                        .report-title{
                            margin: 0px 8px 0px 0px;
                            color: #333333;
                            font-size: 16px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 21px;
                            cursor: pointer;
                        }
                        .asset-utilisation-title{
                            display: flex;
                            align-items: center;
                        }
                        .icon-title{
                            margin: 0px 8px 0px 0px;
                        }
                        .asset-utilisation-title-name{
                            font-size: 18px;
                            font-weight: 600;
                            letter-spacing: 0;
                            line-height: 24px;
                        }
                        .export-css{
                            color: #333333;
                            font-size: 14px;
                            letter-spacing: 0;
                            line-height: 19p
                        }
                        .export-container{
                            display: flex;
                            align-items: center;
                        }
                        .title-log{
                            display: flex;
                            justify-content: space-between;
                        }
                    `
                }
            </style>

        </div>
    )
}

export default TableCard
