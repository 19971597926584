import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';

//image
import NavigationIcon from "../../Image/chevron_right.svg"

//component
import Loaders from "../../component/component-loaders/Loaders"
import Form from './Form'
import { APICreateBeacon } from '../../config/config-restAPI/postAPICalled'
import SnackBar from '../../config/config-snackbar/SnackBar'
import SaveCard from '../../component/component-save/SaveCard'
import { requiredField } from '../../config/config-table/table'
import { AllMandatoryFilled } from '../../config/config-function/function'

const AddFunction = ({ title, canUpdate, fx_back, setIsHoveredO, setSnOpen, setMessage, setSeverity, APICalled, isAdmin, isDashboard }) => {
  const [values, setValues] = useState({
    serialNumber: '',
    skuId: '',
    range: '',
    description: '',
  });
  const skuIdDisplay = '';
  const token = Cookies.get('jwtToken');
  const agencyId = Cookies.get('agency-id');
  const [addVariables, setAddVariables] = useState({});
  const [getVariables, setGetVariables] = useState({});
  const [apiLoading, setApiLoading] = useState(false);
  const [canAdd, setCanAdd] = useState(false) //only can add once all mandatory field filled
  const [displayed, setDisplayed] = useState(false)
  

  //the errOpen, errMsg and errSevere is for snackbar props for error 
  const [errOpen, setErrOpen] = useState(false)
  const [errMsg, setErrMsg] = useState("")
  const [errSevere, setErrSevere] = useState("")

  const rearrangeValue = (value) => {
    const beaconValue = {
      beacon: value
    }
    return beaconValue;
  }

  const mandatoryRequiredField = () => {
    const returnValue = AllMandatoryFilled(requiredField.beacon, values);
    return returnValue;
  }

  useEffect(() => {
    if (mandatoryRequiredField()) {
      setCanAdd(true) // this will enabled the button. Because false makes the button enabled
    } else {
      setCanAdd(false); // this will disabled the button, because true makes the button disabled
    }
  }, [values])
  

  const onSubmit = async (e) => {
    try{
      e.preventDefault();
      const reducer = rearrangeValue(values);
      const response = await APICreateBeacon(reducer, token, agencyId, setApiLoading);
      if (response.status === 200) {
        setSnOpen(true);
        setMessage("Record has been successfully added");
        setSeverity("success");
        APICalled();
        fx_back();
      } else {
        setErrOpen(true)
        setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
        setErrSevere("error")
      } 

    }catch(err){
      console.log(err)
      setErrOpen(true)
      setErrMsg(JSON.stringify(err, null, 2))
      setErrSevere("error")
    }

  };
  const onClickBack = () => {
    setIsHoveredO({add: false})
    fx_back()
  }
  return (
    <div className="form-container">
         <div className="navigation-title-placeholder">
              <div className="title-add">
                  <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                  <div onClick={() => onClickBack()} style={{cursor: "pointer"}} className="detail-title first-title">
                    {title}
                  </div>
                  <div className="detail-title">
                    <img loading="lazy" src={NavigationIcon} alt="Back"/>
                  </div>                      
                  <div className="detail-title">Add {title}</div>
              </div>
              <SaveCard onSubmit={onSubmit} canAdd={canAdd}/>
          </div>
          <Form setDisplayed={setDisplayed} skuIdDisplay={skuIdDisplay} displayed={displayed} values={values} setValues={setValues} isAdmin={isAdmin} isDashboard={isDashboard} />
        <style jsx>
          {`
            .title-add{
              display: flex;             
              align-items: center;              
            }
            .form-container{
              margin: 24px;
              display: grid;
            }
            .navigation-title-placeholder {
              font-size: 18px;
              font-weight: 545;
              height: 32px;
              margin-bottom: 16px;
              display: flex;
              justify-content: space-between;
            }
            .details {
              height: 40px;
              // margin-left: 24px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.54);
            }
            .autocomplete {
              display: flex;
              height: 45px;
              // margin-left: 24px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.54);
            }
            .textfield {
              position: relative;
              bottom: 40px;
            }
            .detail-title{
              padding: 8px;
            }
            .first-title{
              font-size: 16px; 
              padding-left: 0px;
            }
          `}
        </style>
    </div>
  )
}

export default AddFunction
