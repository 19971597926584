import React from 'react'

//component
import {  onUserEdit, AllMandatoryFilled } from "../../config/config-function/function";
import { FIRMWARE_REDUCER } from "../../config/config-reducer/reducer";
import {requiredField} from '../../config/config-table/table'
import Form from './Form'


const Edit = ({ values, onChange, setEditVariables, displayed, fx_back, query, setCanUpdate, canUpdate, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere}) => {

    // console.log(canUpdate)
    const onUserInput = (e) => {
        const _values = onUserEdit(e, null, false, onChange, values)
        const _check = AllMandatoryFilled(requiredField.firmware, _values[0])
        _check ? setCanUpdate(true) : setCanUpdate(false)
        setEditVariables({ updated_firmware: { ...FIRMWARE_REDUCER(_values[0])} })
    };

    return <Form firmware={values} onUserInput={onUserInput} displayed={displayed} fx_back={fx_back} query={query} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} setErrMsg={setErrMsg} setErrOpen={setErrOpen} setErrSevere={setErrSevere}/>
}

export default Edit
