import React, { useState } from 'react';
import Cookies from 'js-cookie';

//library
import { useMutation } from "@apollo/react-hooks";

//image
import NavigationIcon from "../../Image/chevron_right.svg"


//component
import Loaders from "../../component/component-loaders/Loaders"
import SnackBar from '../../config/config-snackbar/SnackBar'
import SaveCard from '../../component/component-save/SaveCard'
import { APICreateChannel } from '../../config/config-restAPI/postAPICalled';

const AddForm = ({title, fx_back, APICalled, FunctionForm, setIsHoveredO, query, setCanUpdate, canUpdate, featureList, setSnOpen, setMessage, setSeverity }) => {

    const [values, setValues] = useState({});
    const [addVariables, setAddVariables] = useState({});
    const [canAdd, setCanAdd] = useState(false) //only can add once all mandatory field filled
    const [displayed, setDisplayed] = useState(false)
    const [loading, setLoading] = useState(false);
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');

    //this state are for snackbar props
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("")


    const onChange = (e, value) => {
        const input = value ? { ...values, ...value }: { ...values, [e.target.name]: e.target.value };
        setValues(input)
    };

    const onSubmit = async (e) => {
      try{
        e.preventDefault();
        const response = await APICreateChannel(addVariables, token, agencyId, setLoading);
        if(response.data.status === 200) {
          setSnOpen(true);
          setMessage('Record has been succesfully added');
          setSeverity('success');
          setLoading(false);
          APICalled('', '');
          fx_back();
        } else {
          setLoading(false)
          setErrOpen(true)
          setErrMsg(`${response.data.status}: ${response.data.error.message}`)
          setErrSevere("error")
        }
      } catch(err) {
        setLoading(false)
        setErrOpen(true)
        setErrMsg(JSON.stringify(err, null, 2))
        setErrSevere("error")
      }
    }

    const onClickBack = () => {
      setIsHoveredO({add: false})
      fx_back()
    }

    if (loading) return <div style={{position: "relative", right:"2px", top: "40px"}}><Loaders /></div>
    return (
        <div className="form-container">
        <div className="navigation-title-placeholder">
            <div className="title-add">
                   <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                   <div onClick={() => onClickBack()} style={{cursor: "pointer"}} className="detail-title first-title">
                     {title}
                   </div>
                   <div className="detail-title">
                      <img loading="lazy" src={NavigationIcon} alt="Back"/>
                   </div>                      
                    <div className="detail-title">Add {title}</div>
            </div>
            <SaveCard onSubmit={onSubmit} canAdd={canAdd}/>
            {/* <button className={!canAdd ? "add-button-disabled" : `${isHovered.btnSave ? "isHovered-add-button": "add-button"}`} disabled={!canAdd} onClick={(e)=> onSubmit(e)} onMouseEnter={() => setIsHovered((current) => ({...current, btnSave: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnSave: false}))}>Save</button> */}
        </div>
        <FunctionForm
            values={values}
            onChange={onChange}
            query={query}
            setAddVariables={setAddVariables}
            setCanAdd={setCanAdd}
            canAdd={canAdd}
            displayed={displayed}
            setDisplayed={setDisplayed}
            setCanUpdate={setCanUpdate}
            canUpdate={canUpdate}
            featureList={featureList}
        />
        
    <style jsx>
      {`
        .title-add{
          display: flex;             
          align-items: center;             
        }
        .form-container{
          margin: 24px;
          display: grid;
        }
        .navigation-title-placeholder {
          font-size: 18px;
          font-weight: 545;
          height: 32px;
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
        }
        .details {
          height: 40px;
          // margin-left: 24px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.54);
        }
        .autocomplete {
          display: flex;
          height: 45px;
          // margin-left: 24px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.54);
        }
        .textfield {
          position: relative;
          bottom: 40px;
        }
        .detail-title{
          padding: 8px;
        }
        .first-title{
          font-size: 16px; 
          padding-left: 0px;
        }
      `}
    </style>
  </div>
    )
}

export default AddForm
