import React, {useRef, useState, useEffect} from 'react'
import ListChannelLeave from './ListChannelLeave'
import GreySearchIcon from '../../Image/grey_search.svg'

const ChannelSearchBar = ({setlistToggle, listToggle,  data, setNotification, notification, setSearchInput, APICalled, setAddNotification, addNotification, setRemoveNotification, removeNotification, isEdit }) => {

    const wrapperRef = useRef(null);
    const [open, setOpen] = useState(false)

    const handleClick = (e) => {
        if (wrapperRef.current.contains(e.target)) {
            // inside click
            return;
          }
          // outside click
        //   setViewSearchResult(false);
        setOpen(false)
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);            
        return () => {
            document.removeEventListener("mousedown", handleClick);
        }
    }, [])

    const onEnterKey = (event) => {
        if(event.key === "Enter"){
            // setOnKey(event.target.value)
            setSearchInput(event.target.value)
            setOpen(true)
            if(event.target.value){
                APICalled(`IS,${event.target.value}`)
            } else {
                APICalled('', 'Telegram');
            }
        }
    }

    return (
        <div ref={wrapperRef} style={{width: "100%", position:"relative"}}>
            <input type="text" autocomplete="off" name="enter" placeholder="Add Channel" className="geofence-add-channel-search" onKeyDown={onEnterKey}/>
            {open && <ListChannelLeave data={data} notification={notification} setNotification={setNotification} setOpen={setOpen} open={open} setAddNotification={setAddNotification} addNotification={addNotification} setRemoveNotification={setRemoveNotification} removeNotification={removeNotification} isEdit={isEdit} />}
            <style>
                {
                    `.geofence-add-channel-search{
                        position: relative;
                        width: 100%;
                        height: 33px;
                        box-shadow: 0px 1px 3px 0px #00000020;
                        border: 0px solid #ffffff;
                        border-radius: 4px;
                        font-size: 14px;
                        background-color: white;
                        background-image: url(${GreySearchIcon});
                        background-size: 20px;
                        background-position: 8px 6px; 
                        background-repeat: no-repeat;
                        padding: 0px 0px 0px 36px;
                        margin: 0px 0px 0px 4px;
                        outline: none;
                        -webkit-transition: width 0.4s ease-in-out;
                        transition: width 0.4s ease-in-out;
                        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                        box-sizing: border-box;         /* Opera/IE 8+ */    
                    }
                    `
                }
            </style>
        </div>
    )
}

export default ChannelSearchBar
