import React, {useState} from 'react'

//list of material ui
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IconButton, InputAdornment } from "@material-ui/core";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DarkCalendarIcon from '../../Image/calendar_dark.svg'

import moment from "moment-timezone";
import MomentUtils from '@date-io/moment'
import ReactTagInput from "@pathofdev/react-tag-input";

//list of component
import { filterTypeOption } from "../../config/config-filter/filter";

const tz = moment.tz.guess()
moment.tz.setDefault(tz)


const FilterByCard = ({canFilter, label, filterKey, option, SelectFilter, value, AddFilter, filterType, matchCase}) => {
    const [selectedType, setSelectedType] = useState(option[0].value)

    //onChange for select dropdownlist
    const selectTypes = (type) =>{
      setSelectedType(type)
      AddFilter(filterKey, value, type, filterType, matchCase)
    }

    return (
        <div className="filter">
            <div className="filter-checkbox">
                <div className="checkbox-container">
                    <input type="checkbox" name="filter" className="check-type" checked={value} onChange={(e) => SelectFilter(e, filterKey, filterType)}/>
                    <div className="div-name">{label}</div>
                </div>
                <div className="filter-select">
                    {
                        option.length > 1 ? (
                            <select className="select-container" value={selectedType} onChange={(e) => selectTypes(e.target.value)}>
                                {
                                    option.map(el => <option value={el.value}>{el.label}</option>)
                                }
                            </select>                            
                        ) : ( <input className="select-container" value={option[0].label} />)
                    }

                </div>
            </div>
            {
                canFilter && (
                  <>
                  {
                    filterType === filterTypeOption.tags || filterType === filterTypeOption.boolean ? (
                      <div className="tagging">
                        <ReactTagInput tags={value ? value : []} onChange={(_value) => AddFilter(filterKey, _value, selectedType, filterType, matchCase)} placeholder="Type a keyword and press enter.."/>
                      </div>   
                    ) : filterType === filterTypeOption.date ? (
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            name="fromTime"
                            value={moment(value).tz(tz).format()}
                            onChange={(_value) => AddFilter(filterKey, moment(_value).tz(tz).format(), selectedType, filterType, matchCase)}
                            format="dd/MM/yyyy"
                            inputVariant="outlined"
                            style={{ width: "100%" }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton style={{ outline: "none" }}>
                                            <img loading="lazy" src={DarkCalendarIcon} alt="Icon"/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                      </MuiPickersUtilsProvider>
                    ) : (<div></div>)
                                      
                  }
                  </>
                 
                )
            }
            

            <div className="line-seperator"></div>  
        </div>
    )
}

export default FilterByCard
