import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from 'apollo-boost';
import {IntrospectionFragmentMatcher} from 'apollo-cache-inmemory'
import { ApolloProvider } from "@apollo/react-hooks";
import { onError } from '@apollo/client/link/error'
import Cookies from "js-cookie";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [], // no types provided
    },
  },
});

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_HTTPLINK });

const authLink = new ApolloLink((operation, forward) => {
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
      headers: {
        "agency-id": Cookies.get("agency-id") ? Cookies.get("agency-id") : null,
        token: Cookies.get("jwtToken") ? Cookies.get("jwtToken") : null,
      }
  });
  // Call the next link in the middleware chain.
  return forward(operation);
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    // console.log("token", Cookies.get("jwtToken"))
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          // Apollo Server sets code to UNAUTHENTICATED
          // when an AuthenticationError is thrown in a resolver
          case 498:
            Cookies.remove("jwtToken")
            Cookies.remove("agency-id");
            Cookies.remove("agencyUUID")
            Cookies.remove("role");
            Cookies.remove("SessionID")
            Cookies.remove("agency-name")
            Cookies.remove("_id")
            Cookies.remove("privileges")
            console.log(
    
              `[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}, Code:${err.extensions.code}`
            )
            window.location.reload()
        }
      }
    
    // graphQLErrors.map(({ message, locations, path, extensions }) =>{
    //   //this to check if the graphql return invalid token.
    //   if(extensions.code === 498){ //error 498 are invalid token
    //     Cookies.remove("jwtToken")
    //     Cookies.remove("agency-id");
    //     Cookies.remove("agencyUUID")
    //     Cookies.remove("role");
    //     Cookies.remove("SessionID")
    //     Cookies.remove("agency-name")
    //     Cookies.remove("_id")
    //     Cookies.remove("privileges")
    //     console.log(

    //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code:${extensions.code}`
    //     )
    //   }
    // }  
    // );
  // if (networkError) console.log(`[Network error]: ${networkError}`);
});





const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),// Chain it with the HttpLink
  cache: new InMemoryCache({fragmentMatcher}),
});


client.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
