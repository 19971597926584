import React,{useContext, useState} from 'react'

import { useMutation } from "@apollo/react-hooks";


import AlertGrey from '../../Image/alert_grey.svg'

import {firebaseContext} from '../../config/config-firebase/FirebaseContext'
import {SMARTLOCK_SYNC, CHECK_SMARTLOCK_SYNC} from "../../config/config-graphql/Graphql";
import Loaders from "../../component/component-loaders/Loaders"
import SnackBar from '../../config/config-snackbar/SnackBar'

const PopUpSync = ({modal, setModal, data, setSnOpen, setMessage, setSeverity, getQuery}) => {
    const firebaseConfig = useContext(firebaseContext)

    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("")

    const { checkDevice } = getQuery

    const [isHovered, setIsHovered] = useState({
        confirm: false
    })

    // console.log(data)

    const btnSync = async (e) => {
        try{
            const response = await editData()
            if(response.errors){
                setErrOpen(true)
                setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
                setErrSevere("error")
              }
              else{
                setSnOpen(true)
                setMessage("Successfully synced with Igloodeveloper")
                setSeverity("success")
                setModal(!modal)
              }

        }catch(err){
            setErrOpen(true)
            setErrMsg(`catch error`)
            setErrSevere("error")
        }
    }

    const [editData, { loading: editLoading, error }] = useMutation(SMARTLOCK_SYNC, {
        variables: {},
        refetchQueries: () => [
            {
                query: checkDevice,
                variables: {filter: {}, type: "Smartlocks"},
            },
        ],
    });
    // if (editLoading) return <Loaders />

    // console.log(data)
    return (
        <div className="modal-container" style={{display: !modal && "none"}}>
            <div className="modal-background" onClick={() => setModal(!modal)}></div>
            <div className="modal-content">
            <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                <header className="title-header">Confirm Update</header>
                <main className="sync-container">
                    <section className="add-smart-container">
                        <p className="add-smart-text">Add ({data.length > 0 && data.add.length > 0 ? data.add.length : 0}) Smart Lock</p>
                        {
                            data && data.length > 0 && data.add.map(x => {
                                // console.log(x)
                                return(<div className="value-text">{x.sn}</div>)
                            })
                        }
                    </section>
                    <section className="remove-smart-container">
                        <p className="remove-smart-text">Remove ({data.length > 0 && data.remove.length > 0 ? data.remove.length : 0}) Smart Lock</p>
                        {
                            data && data.length > 0 && data.remove.map(x => {
                                return(<div className="value-text">{x.sn}</div>)
                            })
                        }
                    </section>
                </main>
                <footer className="modal-footer">
                    <section className="footer-details">
                        <img loading="lazy" src={AlertGrey} alt="alert-icon"/>
                        <p className="igloohome-details">{firebaseConfig.getValueByKey("popup_details_igloohome")}</p>
                    </section>
                    <section className="btn-footer">
                        <button className={`${isHovered.confirm ? "isHovered-btn-igloo-confirm" : "btn-igloo-confirm"}`} onClick={(e) => btnSync(e)} onMouseEnter={() => setIsHovered((current) => ({...current, confirm: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, confirm: false}))}>Confirm</button>
                    </section>

                </footer>
            </div>
            <style>
                {
                    `.modal-container{
                        position: fixed;
                        z-index: 1300;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                    }
                    .modal-background{
                        z-index: -1;
                        position: fixed;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                        background-color: rgba(0, 0, 0, 0.5);
                        -webkit-tap-highlight-color: transparent;
                    }
                    .modal-content{
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        width: 600px;
                        height: 700px;
                        border-radius: 4px;
                        padding: 19px;
                        transform: translate(-50%, -50%);
                        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
                        background-color: #FFFFFF;
                        display: block;
                    }
                    .sync-container{
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: 1fr 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          "."
                          ".";
                          height: 80%;
                    }
                    .footer-details{
                        display: flex;
                        align-items: center;
                        margin: 0px 0px 16px 0px;
                    }
                    .add-smart-text{
                        color: #286551;
                        font-size: 15px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 21px;
                        margin: 0px 0px 8px 0px;
                    }
                    .remove-smart-text{
                        color: #EA2B1F;
                        font-size: 15px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 24px;
                        margin: 0px 0px 8px 0px;
                    }
                    .title-header{
                        text-align: center;
                        font-size: 18px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 24px;
                        color: #333333;
                    }
                    .modal-footer{
                        
                    }
                    .btn-footer{
                        display: flex;
                        justify-content: center;
                    }
                    .btn-igloo-confirm{
                        height: 32px;
                        width: 213px;
                        border-radius: 2px;
                        background-color: #509D91;
                        color: #FFF;
                        display: block;
                        cursor: pointer;
                    }
                    .isHovered-btn-igloo-confirm{
                        height: 32px;
                        width: 213px;
                        border-radius: 2px;
                        font-weight: 600;
                        background-color: #498f84;
                        color: #FFF;
                        display: block;
                        cursor: pointer;
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                    }
                    .igloohome-details{
                        font-size: 13px;
                        letter-spacing: 0;
                        color: #939598;
                        margin: 0px 0px 0px 8px;
                    }
                    .value-text{
                        margin: 0px 0px 11px 0px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default PopUpSync
