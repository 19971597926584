import React, { useState, useEffect} from 'react'

//library
import { useMutation } from "@apollo/react-hooks";

//image
import NavigationIcon from "../../Image/chevron_right.svg"

//component
import { DELETE_ASSET, GET_ASSET, UPDATE_ASSET } from "../../config/config-graphql/Graphql";
import { ASSET_REDUCER } from "../../config/config-reducer/reducer";
import { onUserEdit, AllMandatoryFilled  } from "../../config/config-function/function";
import {requiredField} from '../../config/config-table/table'
import Form from "./Form"
import SnackBar from '../../config/config-snackbar/SnackBar'
import SaveCard from '../../component/component-save/SaveCard'
import DeleteCard from '../../component/component-deletecard/DeleteCard'

const EditForm = ({ title, fx_back, selected, pageInd, setLiveFilter, setPreFilter, setMainFilter, fx_changePage, featureList, setMandatoryfield, mandatoryField, setPageInd, setSnOpen, setMessage, setSeverity, setFilterFinal, history, featureDisable}) => {

  const staticDevice = selected[0].asset_device ? selected[0].asset_device.map(x => x) : []
  const staticDeviceList = staticDevice ? staticDevice.filter(x => x.device_type === "Telematics") : []
  const staticDeviceSmartLock = staticDevice ? staticDevice.filter(x => x.device_type === "Smartlocks") : []
  const staticPairedAsset = selected[0].paired_assets && selected[0].paired_assets.length > 0 ? selected[0].paired_assets[0] : {}


  const staticAG = selected[0].asset_group
  const [values, setValues] = useState(selected.map(a => ({...a})));
  const [editVariables, setEditVariables] = useState({});
  const [add_devices, setAdd_devices] = useState([])
  const [remove_devices, setRemove_devices] = useState([])
  const [add_smartLocks, setAdd_smartLocks] = useState([])
  const [remove_smartLocks, setRemove_smartLocks] = useState([])
  const [mainAssetSelection, setMainAssetSelection] = useState({})
  const [isHovered, setIsHovered] = useState({
    button: false
  })
  
  //the errOpen, errMsg and errSevere is for snackbar props for error 
  const [errOpen, setErrOpen] = useState(false)
  const [errMsg, setErrMsg] = useState("")
  const [errSevere, setErrSevere] = useState("")

  const onSubmit = async (e) => {
    try{
      e.preventDefault();
      const response = await editData({ variables: editVariables });
      if(response.errors){
        setErrOpen(true)
        setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
        setErrSevere("error")
      }
      else{
        setSnOpen(true)
        setMessage("Record has been successfully updated")
        setSeverity("success")
        setFilterFinal({})
        setPageInd(false)
        fx_back();
      }
    }catch(err){
      console.log(JSON.stringify(err, null, 2))
      setErrOpen(true)
      setErrMsg(JSON.stringify(err.message, null, 2))
      setErrSevere("error")
    }
  };

  //this to check the pair_asset if its selected asset for display list of pair asset
  useEffect(() => {
    setMainAssetSelection(selected[0])
            //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const [editData, {loading:assetLoading, error:assetError}] = useMutation(UPDATE_ASSET, {
      refetchQueries: () => [
          {
              query: GET_ASSET,
              variables: { filter: {} },
          },
      ],
  });

  const onUserInput = (e, value) => {
    let _values = null 

    if(e){
      _values = onUserEdit(e, null, false, onChange, values)
      setEditVariables({updated_asset: _values.map(el => ASSET_REDUCER(el)) })

    } else if(value){
      _values = onUserEdit(e, value, true, onChange, values)
      // console.log("onUserInput", _values)
      setEditVariables({updated_asset: _values.map(el => ASSET_REDUCER(el)) })
    }
    const _check = AllMandatoryFilled(requiredField.asset, _values[0])
    _check ? setMandatoryfield(true) : setMandatoryfield(false)

    // console.log(_check)
  };

  const onChange = (e, i, value) => {
      const _values = [...values];
      if(value){
          let keys = Object.keys(value)
          keys.map(key => _values[i][key] = value[key])
      } else{
          _values[i][e.target.name] = e.target.value;
      }
      setValues(_values);
  };

  const SelectAssetGroup = (uuid) => {
    if(staticAG && uuid !== staticAG.uuid){//change ag
      onUserInput(null, { add_group: { uuid }})
      onUserInput(null, { remove_group: { uuid: staticAG.uuid }})
    }
    else if(!staticAG){ //assign ag to unassigned asset
      onUserInput(null, { add_group: { uuid }})
    }

  }

  const PairAsset = (selected, fx_close) => {
    onUserInput(null, { pair_asset: { uuid: selected.value }, paired_assets: [{ uuid: selected.value, name: selected.label }] })
    setMainAssetSelection(selected)
    fx_close();
  }

  const UnpairAsset = (fx_close) => {
    if(Object.keys(staticPairedAsset).length > 0){ //This asset is paired to an asset since beginning
      onUserInput(null, { unpair_asset: { uuid: staticPairedAsset.uuid }, paired_assets: [] })
      fx_close();
    }
  }

  //Delete Asset mutation
  const [deleteAsset, {loading:astLoading, error: astError}] = useMutation(DELETE_ASSET, {
      refetchQueries: () => [
        {
            query: GET_ASSET,
            variables: { filter: {} },
        },
    ],
  });

  const onDelete =  async () => {
    try{
      const response = await deleteAsset({variables: { asset_uuid: selected.map(x => x.uuid) }});
      if(response.errors){ // to check if the response has error
        setSnOpen(true)
        setMessage(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
        setSeverity("error")
      }
      else{ // success
        setSnOpen(true)
        setMessage("Record has been successfully deleted")
        setSeverity("success")
        setPageInd(false)
        setFilterFinal({})
        fx_back()
      }
    }catch(err){
      console.log(JSON.stringify(err, null, 2))
      setErrOpen(true)
      setErrMsg(JSON.stringify(err.message, null, 2))
      setErrSevere("error")
    }

  }

  const onClickBack = () => {
    setLiveFilter({})
    setPreFilter({})
    setFilterFinal({})  
    setPageInd(false)
    fx_back()
  }

  return (
    
      <div className="form-container">
          <div className="navigation-title-placeholder">
              <div className="title-add">
                    <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                      <div onClick={() => onClickBack()} style={{cursor: "pointer"}} className="detail-title first-title">
                        {title}
                      </div>
                      <div className="detail-title">
                        <img loading="lazy" src={NavigationIcon} alt="Back"/>
                      </div>                      
                      <div className="detail-title">{values[0].name ? values[0].name : ""}</div>
                      <div className="detail-title"></div>

              </div>
              <div className="button-delete-save-container">
                {
                  featureDisable ? null : <DeleteCard 
                    fx_delete={onDelete} 
                    query={{deleteQuery: DELETE_ASSET, getQuery: GET_ASSET}}
                    fx_back={fx_back} 
                  />
                }
                {
                  featureDisable ? null : <SaveCard onSubmit={onSubmit} canAdd={mandatoryField}/> 
                }
              </div>

                
          </div>
          <Form asset={values} onUserInput={onUserInput} setLiveFilter={setLiveFilter} fx_changePage={fx_changePage} fx_back={fx_back} fx_delete={onDelete} staticDeviceList={staticDeviceList} staticDeviceSmartLock={staticDeviceSmartLock} addDeviceList={add_devices} removeDeviceList={remove_devices} setAddDevice={setAdd_devices} setRemoveDevice={setRemove_devices} featureList={featureList} fx_selectAssetGroup={SelectAssetGroup} fx_pairAsset={PairAsset} fx_unpair={UnpairAsset} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} setErrMsg={setErrMsg} setErrOpen={setErrOpen} setErrSevere={setErrSevere} add_smartLocks={add_smartLocks} setAdd_smartLocks={setAdd_smartLocks} remove_smartLocks={remove_smartLocks} setRemove_smartLocks={setRemove_smartLocks} staticSmartLockList={staticDeviceSmartLock} history={history} featureDisable={featureDisable} mainAssetSelection={mainAssetSelection}/>
          
      <style>
        {`
          .title-add{
            display: flex;
            align-items: center;             
          }
          .form-container{
            margin: 24px;
          }
          .navigation-title-placeholder {
            font-size: 18px;
            font-weight: 545;
            height: 32px;
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
          }
          .details {
            height: 40px;
            // margin-left: 24px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.54);
          }
          .autocomplete {
            display: flex;
            height: 45px;
            // margin-left: 24px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.54);
          }
          .textfield {
            position: relative;
            bottom: 40px;
          }
          .detail-title{
            padding: 8px;
          }
          .first-title{
            font-size: 16px; 
            padding-left: 0px;
          }
          .button-delete-save-container{
            display: flex;
          }
        `}
      </style>
    </div>
  )
}

export default EditForm
