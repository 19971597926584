import React, { useState, useEffect } from "react";
import { snakeCase } from "snake-case"

//libary
import { useQuery } from "@apollo/react-hooks";

//image
import SKUIcon from "../../Image/sku.svg"
import AgencyIcon from "../../Image/agencies.svg"
import ChevronLightIcon from "../../Image/chevron_light.svg"

//component
import FunctionAdd from './FunctionAdd'
import FunctionEdit from './FunctionEdit'
import DeviceChart from "./DeviceChart"
import Overview from "./Overview"
import Loaders from "../../component/component-loaders/Loaders"
import { filterList } from "../../config/config-filter/filter"
import { GET_DEVICES, UPDATE_DEVICES,  ADD_DEVICES, DELETE_DEVICES} from "../../config/config-graphql/Graphql";
import SnackBar from '../../config/config-snackbar/SnackBar'


const PanelDevice = ({ filter, setFilter, fx_changePage, history }) => {// 👈 the filter props is for the filter that comes from other pages (view all)

  const [filterFinal, setFilterFinal] = useState({})
  const [preFilter, setPreFilter] = useState({})
  const [canUpdate, setCanUpdate] = useState(false) //default false\
  const [filterType, setFilterType] = useState("All")
  const [edit, setEdit] = useState(false);
  const [editValue, setEditValue] = useState()
  const [dataOption, setDataOption] = useState()
  const [pageInd, setPageInd] = useState(false)
  const [missingSKU, setMissingSKU] = useState(false)


  const { data, loading, error } = useQuery(GET_DEVICES, { variables: { filter:  filterFinal, type: filterType }});

  const [snOpen, setSnOpen] = useState(true)
  const [message, setMessage] = useState("")
  const [severity, setSeverity] = useState("success")

  // console.log(filterFinal)
  // !loading && console.log(data)


  const PreFilter = (value) => {
    setPreFilter({ sn: { type: "CONTAINS", value: [ value ] } })
}

const EnterFilter =(e) => {
    if(e.key === "Enter"){
        setFilterFinal(preFilter)
    }
}


const setData = (data) => {
    if (data && data !== null){
      data.map((row) => {
        if((row.batch)){
          const batch_id = (row.batch.id);
          const batch_uuid = row.batch.uuid
          row.batch_id = batch_id
          row.batch_uuid = batch_uuid
        }

        if(row.firmware){
          const firmware = (row.firmware.id);
          row.firmware_id = firmware
          row.firmware_uuid = row.firmware.uuid
        }

        if(row.SKU){
          const manufacturer = (row.SKU.id);
          row.sku_id = manufacturer
          row.sku_uuid = row.SKU.sku_uuid
        }

        if(row.agency && row.agency.sigfox_deviceTypeId){
            const agency_id = (row.agency.id);
            const agency_uuid = (row.agency.uuid);
            const deviceTypeId = (row.agency.sigfox_deviceTypeId[0])
            row.agency_id = agency_id;
            row.agency_uuid = agency_uuid
            row.sigfox_deviceTypeId = deviceTypeId
        }
        return row;

    }) 

    }
    else{
      return []
    }
  return data;
}

//this useEffect are to go to details page and go for missingSKU (smartlocks)
useEffect(() => {
  
  if(!loading && data && data.get_devices){
    if(pageInd){
      setEdit(!edit)       
      setEditValue(data.get_devices.devices)
      // console.log(data.get_devices.devices)
    }
    if(data.get_devices.total_missing_sku > 0){
        // console.log("go here") 
        setMissingSKU(true) 
    }
    else{
      setMissingSKU(false)
    }
  }
  
}, [loading])

useEffect(() => {
    if(error){
        setSnOpen(true)
        setMessage("Error: Unable to retrieve data. Please try again later.")
        setSeverity("error")
    }
}, [error])



return loading ? <Loaders /> : error ? (
    <SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/>
  ) : (
      <div>
        <Overview 
            title="Device"
            data={data.get_devices !== null ? setData(data.get_devices.devices) : []}
            loading={loading}
            editForm={FunctionEdit}
            addForm={FunctionAdd}
            featureList={data.get_devices !== null ? data.get_devices.features.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) : []}
            query={{ editQuery: UPDATE_DEVICES, getQuery: GET_DEVICES , addQuery: ADD_DEVICES, deleteQuery: DELETE_DEVICES}}
            Cards={Cards}
            BarCharts={DeviceChart}
            filterFinal={filterFinal}
            setFilterMain={setFilter}
            fx_changePage={fx_changePage}
            setFilterFinal={setFilterFinal}
            filterList={filterList.device_admin}
            preFilter={preFilter} 
            fx_PreFilter={PreFilter} 
            EnterFilter={EnterFilter}
            setPreFilter={setPreFilter}
            setCanUpdate={setCanUpdate}
            canUpdate={canUpdate}
            setEdit={setEdit}
            edit={edit}
            setEditValue={setEditValue}
            editValue={editValue}
            setPageInd={setPageInd}
            setMissingSKU={setMissingSKU}
            missingSKU={missingSKU}
            totalMissing={data && data.get_devices && data.get_devices.total_missing_sku}
            history={history}
            pageInd={pageInd}
        />
      </div>
    )
};

export default PanelDevice;


const Cards = ({data, setEdit, edit, add, setAdd, setEditValue, featureList, setDetailsPage, setFilterFinal, setPageInd}) => {
      
    const btnDetails = (e, value) => {
      setFilterFinal({uuid: value.uuid})
      setPageInd(true)

    }

    // console.log(data)

    const [isHovered, setIsHovered] = useState({
      container: false,
      details: false
    })

    const getFeatures = (device) => {
      let featureSet = new Set();
      if (device && device.features && device.features.length > 0) {
        for (let i = 0; i < device.features.length; i++) {
          const el = device.features[i];
          featureSet.add(el)
          
        }
      }

      if(device.SKU){
        for (let y = 0; y < device.SKU.features.length; y++) {
          const el = device.SKU.features[y];
          featureSet.add(el)
        }
      }
      
      return Array.from(featureSet).map(uuid => {
        let feature = featureList.find(el => el.uuid === uuid)
        if(feature) return feature.icon
        return feature.icon
      })
    }


    return(
      <div onMouseEnter={() => setIsHovered((current) => ({...current, container: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, container: false}))}>
          <div className="container-device">
              {/* <div className="checkbox-container-device-admin">
                  <input type="checkbox" className="check-type"/>
              </div> */}
              <div className="device-o-container">
                  <div className="device-detail">
                    <div className="device-name">{data.sn}</div>
                    <div className="device-name-id">
                        <img src={SKUIcon} alt="test"/>
                        <div className="device-id">{data.SKU ? data.SKU.ID : "" }</div>
                    </div>
                  </div>
                  <div className="line-box"></div>
                  <div className="agency-detail">
                    <img src={AgencyIcon} alt="test"/>
                    <div className="agency-name">{data.agency ? data.agency.name : "" }</div>
                  </div>
                  <div className="line-box"></div>
                  <div className="features">
                      <div className="detail-feature">
                          <div className="feature-detail">Features</div>
                          <div className="featuImage">
                          {
                            featureList.length > 0 && featureList.map(feature => {
                                try{
                                  return getFeatures(data).includes(feature.icon) ? <img loading="lazy" className="img-feature" src={`${feature.icon}/icon_${snakeCase(feature.name)}_enabled.svg`} alt="v"/> : <img className="img-feature" src={`${feature.icon}/icon_${snakeCase(feature.name)}_disabled.svg`} alt="x"/>
                                } catch(err){
                                  // console.log(err)
                                } 
                            })
                                                              
                          }
                          </div>
                      </div>

                  </div>
                  {
                    isHovered.container && 
                    <div className="onhover-container" onMouseEnter={() => setIsHovered((current) => ({...current, details: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, details: false}))}>
                      <div className={`${isHovered.details ? "isHovered-button-edits" : "button-edits"}`} onClick={e => btnDetails(e, data)}>
                            <img src={ChevronLightIcon} alt="test"/>
                      </div>
                  </div>
                  }

              </div> 
          </div>
        <style jsx>
          {
            `
            .device-o-container{
                display: grid;
                grid-template-columns: minmax(0, 1fr) 10px 1fr 10px 2fr 43px;
                grid-template-rows: 1fr;
                gap: 0px 0px;
                grid-template-areas: ". . . . . .";
                height: 64px;
                // margin: 0px 0px 0px 8px;
                border-radius: 4px;
                background-color: #FFFFFF;
            }
            .feature-detail{
                color: #939598;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 16px;
            }
            .detail-feature{

            }
            .checkbox-container-device-admin{
                display: flex;
                align-items: center;
            }
            .check-type{
                margin: 0px 4px 0px 0px;
                height: 18px;
                width: 18px;
                background-color: #509D91;
            }
            .container-device{
                display: grid;
                margin-bottom: 4px;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
                gap: 0px 0px;
                grid-template-areas: ". ."
            }
            .device-detail{
                padding: 8px;
                white-space: nowrap;
                overflow: hidden;
            }
            .line-box{
                height: 46px;
                margin-right: 8px;
                margin-top: 8px;
                border: 1px solid #DDDDDD;
            }
            .agency-detail{
                display: flex;
                align-items: center;
                padding: 8px;
                white-space: nowrap;
                overflow: hidden;
            }
            .features{
                padding: 0px 0px 0px 8px;
                display: flex;
                align-items: center;
                white-space: nowrap;
                overflow: hidden;
            }
            .device-name{
                color: #333333;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 21px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .device-id{
                margin: 0px 0px 0px 8px;
                color: #333333;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 19px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

            }
            .device-name-id{
                display: flex;
                align-items: center;
            }
            .agency-name{
                margin-left: 8px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .onhover-container{
                display:flex;
                justify-content: flex-end;
                width: 100%;
            }
            .featuImage{
              display: flex;
              margin: 4px 0px 0px 0px;
            }
            .button-edits{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 43px;
                height: 64px;
                border-radius: 0 4px 4px 0;
                background-color: #509D91;
                cursor: pointer;
            }
            .isHovered-button-edits{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 43px;
                height: 64px;
                border-radius: 0 4px 4px 0;
                background-color: #498f84;
                cursor: pointer;
                box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
            }
            .img-feature{
              margin: 0px 16px 0px 0px;
            }
            `
          }
          </style>
      </div>
     
    )
  }


