import React, {useState, useContext} from 'react'

// import { useMutation, useQuery } from "@apollo/react-hooks";
import SnackBar from '../../config/config-snackbar/SnackBar'
// import Loaders from '../../component/component-loaders/Loaders'

const GenerateKeyModal = ({open, fx_close, onClickGenerate, valBoard}) => {
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("") 

    const [isHovered, setIsHovered] = useState({
        btnYes: false,
        btnNo: false
    })



    return (
        <div className="profile-container"style={{display: !open && "none"}} >
            <div className="profile-modal-background" onClick={fx_close}></div>
            <div className="profile-modal-content">
                <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                <div className="profile-body-modal">
                    <div className="generate-title">Confirmation</div>
                    <div className="generate-details">
                        Refreshing the public and private key pair will prevent the device from connecting to Google IoT Core until the private key has been updated on the device. Are you sure you want to continue?
                    </div>
                    <div className="generate-btn">
                        <button className={isHovered.btnNo ? "isHovered-generate-btn-no" : "generate-btn-no"} onClick={fx_close} style={{margin: "0px 24px 0px 0px"}} onMouseEnter={() => setIsHovered((current) => ({...current, btnNo: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnNo: false}))}>No</button>
                        <button className={isHovered.btnYes ? "isHovered-generate-btn-yes" : "generate-btn-yes"} onClick={(e) => onClickGenerate(e)} onMouseEnter={() => setIsHovered((current) => ({...current, btnYes: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnYes: false}))}>Yes</button>
                    </div>
                </div>
            </div>
            <style>
                {
                    `.profile-container{
                        position: fixed;
                        z-index: 1300;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                    }
                    .profile-modal-background{
                        z-index: -1;
                        position: fixed;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                        background-color: rgba(0, 0, 0, 0.5);
                        -webkit-tap-highlight-color: transparent;
                    }
                    .profile-modal-content{
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        width: 687px;
                        border-radius: 4px;
                        transform: translate(-50%, -50%);
                        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
                        background: #F3F3F3;
                        display: block;
                    }
                    .profile-body-modal{
                        padding: 16px;
                    }
                    .generate-title{
                        display: flex;
                        justify-content: center;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 24px;
                        margin: 0px 0px 8px 0px;
                    }
                    .generate-details{
                        text-align: center;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 24px;
                        color: #939598;
                        margin: 0px 0px 24px 0px;
                    }
                    .generate-btn{
                        display: flex;
                        justify-content: center;
                    }
                    .generate-btn-no{
                        border-radius: 2px;
                        border: 1px solid #509D91;
                        height: 32px;
                        width: 213px;
                        color: #509D91;
                        cursor: pointer;
                    }
                    .generate-btn-yes{
                        background: #509D91;
                        border-radius: 2px;
                        color: #FFFFFF;
                        height: 32px;
                        width: 213px;
                        cursor: pointer;
                    }
                    .isHovered-generate-btn-no{
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                        color: #498f84;
                        border-radius: 2px;
                        border: 1px solid #498f84;
                        height: 32px;
                        width: 213px;
                        font-weight: 600;
                        cursor: pointer;
                    }
                    .isHovered-generate-btn-yes{
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                        background: #498f84;
                        border-radius: 2px;
                        color: #FFFFFF;
                        height: 32px;
                        width: 213px;
                        font-weight: 600;
                        cursor: pointer;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default GenerateKeyModal
