import React, { useState, useRef } from 'react'
import { useQuery } from "@apollo/react-hooks";

//list of images
import DarkNavigationDown from "../../Image/dark_navigation_down.svg"
import SearchIcon from "../../Image/search.svg"
import LightCloseIcon from '../../Image/light_close_icon.svg'

//list of components
import { GET_DEVICES_STAT } from "../../config/config-graphql/Graphql";
import OverviewCard from "./OverviewCard"
import DeviceDetails from "./DeviceDetails"
import BulletChart from '../../component/component-bulletchart/BulletChart';
import FilterBy from "../../component/component-filterby/FilterBy"
import SnackBar from '../../config/config-snackbar/SnackBar'

const OverviewDeviceLive = ({ title, data, setDetailsData, loading, detailsData, viewDetails,  setViewDetails, setPageInd, pageInd,  setFilterFinal, filterFinal = {}, filterList, preFilter = {}, fx_PreFilter, EnterFilter, setPreFilter, featureDisable }) => {
  const { data: chartData, loading: chartLoading } = useQuery(GET_DEVICES_STAT, { variables: {} });
  const chartColors = ["#546A7B", "#0D1F2D", "#9EA3B0", '#E4C3AD', "#939598", "#CADF9E", "#ABB557", "#F46036", "#FAE1DF"]

  
  // console.log(detailsData)
  const [filter, setFilter] = useState(false)
  const [filterValue, setFilterValue] = useState(Object.keys(filterFinal).length > 0 ? filterFinal : preFilter)
  const [hoveredO, setIsHoveredO] = useState({
    add: false,
    filter: false
})

  //temporary
  const [isSmartLock, setisSmartLock] = useState(true)
  const [snOpen, setSnOpen] = useState(false)
  const [message, setMessage] = useState("")
  const [severity, setSeverity] = useState("")

  const getFeatureIcon = (device) => {
    // console.log(device)

    let featureSet = new Set()

    for (let i = 0; i < device.feature && device.features.length; i++) {
      const el = device.features[i];
      featureSet.add(el)     
    }
    for (let y = 0; y < device.SKU.features.length; y++) {
      const el = device.SKU.features[y];
      featureSet.add(el)
    }

    return Array.from(featureSet).map(uuid => {
      let feature = data && data.features.find(el => el.uuid === uuid)
      if(feature) return feature.icon;
      
      return feature.icon
    })
    // let feature = data && data.features.find(el => el.uuid && el.uuid === uuid)
    // return feature ? feature.icon : ""
  }


  const wrapperRef = useRef(null);

  //filter tags
  const filterDesign = () => {
    if (Object.keys(filterFinal).length > 0) {
      return "filter-tag"
    } else {
      return "filter-no-tag"
    }
  }

  //display filter count
  const filterCount = () => {
    if (filterFinal) {
      if (Object.keys(filterFinal).length > 0) {
        return <div style={{ display: "flex", alignItems: "center" }}>
          {`${Object.keys(filterFinal).length} Filter`}
          <img loading="lazy" src={LightCloseIcon} alt="close-icon" style={{ cursor: "pointer", height: "10px", width: "10px", margin: "0px 0px 0px 10px" }} onClick={(e) => onTagRemoveFilter(e)} />
        </div>

      }
      else {
        return null
      }
    } else {
      return null
    }
  }

  const onTagRemoveFilter = (e) => {
    setFilterValue({})
    setFilterFinal({})
    setPreFilter({})
  }
  return viewDetails ? <DeviceDetails title={title} setIsHoveredO={setIsHoveredO} device={detailsData} featureList={data.features } fx_back={() => setViewDetails(!viewDetails)} setFilterFinal={setFilterFinal} isSmartLock={isSmartLock} setPageInd={setPageInd} setMessage={setMessage} setSeverity={setSeverity} setSnOpen={setSnOpen} featureDisable={featureDisable}/> :
    (
      <div className="table-container">
        <div className="title-font">{title}</div>
        <SnackBar open={snOpen} setOpen={setSnOpen} severity={severity} message={message}/>
        <div className="chart-container">
          <div className="chart right-space">
            <BulletChart name="availbl_devices" title="Available" loading={chartLoading} data={chartData && chartData.get_devices_stat && chartData.get_devices_stat.available && chartData.get_devices_stat.available.map(el => { return { name: el.product_model && el.product_model.replace(/_/g, " "), total: el.total } })} colorList={chartColors} />
          </div>
          <div className="chart">
            <BulletChart name="assigned_devices" title="Assigned" loading={chartLoading} data={chartData && chartData.get_devices_stat && chartData.get_devices_stat.assigned && chartData.get_devices_stat.assigned.map(el => { return { name: el.product_model && el.product_model.replace(/_/g, " "), total: el.total } })} colorList={chartColors} />
          </div>
        </div>
        <div className="buttons">
          <div className="filter-container-device-client">
            <img loading="lazy" src={SearchIcon} alt="search-icon" />
            <img loading="lazy" src={DarkNavigationDown} alt="search-icon" onClick={() => setFilter(!filter)} className={hoveredO.filter ? "add-hover-button-style" : "add-button-style"} onMouseEnter={() => setIsHoveredO((current) => ({...current, filter: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, filter: false}))}  />
            <div className="input-filter">
              <div className={filterDesign()}>
                {filterCount()}
              </div>
              <input type="text" value={preFilter.sn ? preFilter.sn.value[0] : ""} onChange={(e) => fx_PreFilter(e.target.value)} onKeyDown={(e) => EnterFilter(e)} />
            </div>
          </div>
          <div style={{ position: "absolute", margin: "0px 0px 0px 20px" }} ref={wrapperRef}>
            {filter && <FilterBy setFilterFinal={setFilterFinal} filterFinal={filterFinal} filterList={filterList} filterValue={filterValue} setFilterValue={setFilterValue} />}
          </div>
        </div>
        <div className="table-responsive">
          {
            data && data.devices.map((device, index) => {
              return <OverviewCard data={device} featureList={data && data.features.length > 0 ? data.features.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) : []} featureIcons={getFeatureIcon(device)} pageInd={pageInd} setPageInd={setPageInd} setFilterFinal={setFilterFinal}/>
            })
          }
        </div>
        <style>
          {

            ` .table-container{
                      margin: 24px 24px;
                  }
                .chart-container{
                  display:flex;
                  margin: 0px 0px 24px 0px;
                }
                .chart{
                  padding: 8px;
                  width: 100%;
                  border-radius: 4px;
                  background-color: #FFFFFF;
                  display: flex;
                  flex-direction: column;
                  justify-content: center
                }
                .right-space{
                  display: flex;
                  flex-direction: column;
                  margin: 0px 24px 0px 0px;
                }
                .title-font{
                  height: 32px;
                  // width: 108px;
                  color: #333333;
                  font-size: 24px;
                  font-weight: 600;
                  letter-spacing: 0;
                  line-height: 32px;
                  margin-bottom: 10px;
                }
                .buttons{
                  display: flex;
                  padding: 8px 0px 8px 0px;
                }
                .table-boostrap{
                  // margin: 16px;
                  // height:80vh;
                  min-height: 75vh;
                  background-color: #ffffff;
                }
                .table-responsive{
                    height:60vh;
                    width: 100%;
                  //   width: 
                }
                .filter-button{
                    font-size: 12px;
                }
                .pagination-table{
                  position: relative;.
                }
                .img-pagination{
                  width: 19px,
                  height: auto;
                }
                .row-height{
                    height:
                }
                .wrapper{
                  word-wrap: break-word
                }
                .check-type{
                  margin: 0px 4px 0px 0px;
                  height: 18px;
                  width: 18px;
                  background-color: #509D91;
                }
                .buttons-all{
                    display: flex;
                    align-items: center;
                }
                .filter-container-device-client{
                  padding: 8px;
                  margin: 0px 0px 0px 0px;
                  display: flex;
                  box-sizing: border-box;
                  height: 40px;
                  width: 360px;
                  border: 1px solid #939598;
                  border-radius: 4px;
                }
                .filter-tag{
                  display: flex;
                  align-items: center;
                  color: #FFF;
                  border-radius: 4px;
                  width: 85px;
                  height: 24px;
                  text-align: center;
                  background-color: #509D91;
                  justify-content: center;
                  margin: 0px 8px 0px 0px;
              }
              .filter-no-tag{
                  height: 0px;
              }
              input[type=text] {
                width: 285px;
                background: transparent;
                border: none;
                outline: none;
              }
              .input-filter{
                display: flex;
                align-items: center;
              }
              .add-button-style{
                cursor: pointer;
              }
              .add-hover-button-style{
                  background-color: rgba(0,0,0,0.03);
                  border-radius: 5px;
                  cursor: pointer;
              }
              `
          }
        </style>
      </div>
    )
}

export default OverviewDeviceLive
