import React, { useState, useEffect } from 'react'

//library
import { useMutation } from "@apollo/react-hooks";

//image
import NavigationIcon from "../../Image/chevron_right.svg"

//component
import Form from "./Form";
import {requiredField} from '../../config/config-table/table'
import { AllMandatoryFilled, onUserEdit } from "../../config/config-function/function"
import { ASSET_GROUP_REDUCER } from "../../config/config-reducer/reducer";
import { UPDATE_ASSET_GROUP, DELETE_ASSET_GROUP, GET_ASSETGROUPS } from "../../config/config-graphql/Graphql"
import SnackBar from '../../config/config-snackbar/SnackBar'
import DeleteCard from '../../component/component-deletecard/DeleteCard'
import SaveCard from '../../component/component-save/SaveCard'


const EditFormNew = ({ title, fx_back, selected, setFilterValue, setPreFilter, setPageInd, setFilterFinal, setLiveFilter, setMandatoryfield, mandatoryField, setSnOpen, setMessage, setSeverity, featureDisable}) => {
  const staticAssetList = selected[0].assets ? selected[0].assets.map(x => x) : []
  const staticAssetArr = selected[0].assets ? selected[0].assets.map(x => x.uuid) : []
  const [values, setValues] = useState(selected.map(a => ({...a})));
  const [editVariables, setEditVariables] = useState({});
  const [displayed, setDisplayed] = useState(true)
  const [remove_asset, setRemove_asset] = useState([]);
  const [add_asset, setAdd_asset] = useState([])
  const [isHovered, setIsHovered] = useState({
    button: false
  })

  // console.log(GET_ASSETGROUPS)

  //the errOpen, errMsg and errSevere is for snackbar props for error 
  const [errOpen, setErrOpen] = useState(false)
  const [errMsg, setErrMsg] = useState("")
  const [errSevere, setErrSevere] = useState("")

  const onUserInput = (e, value) => {
    let _values = null 
    if(e){
       _values = onUserEdit(e, null, false, onChange, values)
      setEditVariables({updated_asset_group: ASSET_GROUP_REDUCER(_values[0]) })

    } else if(value){
      _values = onUserEdit(e, value, true, onChange, values)
      setEditVariables({updated_asset_group: ASSET_GROUP_REDUCER(_values[0]) })
    }

    const _check = AllMandatoryFilled(requiredField.asset_group, _values[0])
    _check ? setMandatoryfield(true) : setMandatoryfield(false)
  };


  //add asset
  useEffect(() => {
    if(add_asset){

      const addedAsset = staticAssetArr.concat(add_asset)
      const updatedAsset = addedAsset.filter(x => !remove_asset.includes(x))


      onUserInput(null, { add_asset: updatedAsset.map(x => { return { uuid: x}})})
    }
            //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [add_asset])

  useEffect(() => {
    if(remove_asset){
      onUserInput(null, { remove_asset: remove_asset.map(x => { return { uuid: x}})})
    }
            //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remove_asset])

  const onSubmit = async (e) => {
    try{
      e.preventDefault();
      // console.log("EDIT ASSET-GROUP", editVariables)
      const response = await editData({ variables: editVariables });
      // console.log(response)
      if(response.errors){
        setErrOpen(true)
        setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
        setErrSevere("error")
      }
      else{
        setSnOpen(true)
        setMessage("Record has been successfully updated")
        setSeverity("success")
        setFilterFinal({})//this to remove all the value in filter
        setPageInd(false)//true: redirect to details page, false: redirect to overview page
        fx_back();
      }
    }catch(err){
      console.log(JSON.stringify(err, null, 2))
      setErrOpen(true)
      setErrMsg(JSON.stringify(err.message, null, 2))
      setErrSevere("error")
    }

  };

  const [editData] = useMutation(UPDATE_ASSET_GROUP, {
      refetchQueries: () => [
          {
              query: GET_ASSETGROUPS,
              variables: { filter: {} },
          },
      ],
  });

const [deleteAssetGroup] = useMutation(DELETE_ASSET_GROUP, {
    refetchQueries: () => [
        {
            query: GET_ASSETGROUPS,
            variables: { filter: {} },
        },
    ],
});

const onChange = (e, i, value) => {
    const _values = [...values];
    if(value){
        let keys = Object.keys(value)
        keys.map(key => _values[i][key] = value[key])
    } else{
      _values[i][e.target.name] = e.target.value;
    }
    // checkInput(_values)
    setValues(_values);
};

  //onDelete assetGroup
  const onDelete = async () => {
    // console.log("click here delete")
    // console.log(selected.map(x => x.uuid))
      try{
        const response =  await deleteAssetGroup({variables: { group_uuid: selected.map(x => x.uuid) }});
        //  console.log(response)
        if(response.errors){ // to check if the response has error
          setSnOpen(true)
          setMessage(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
          setSeverity("error")
        }
        else{ // success
          // console.log("success delete go here")
          setSnOpen(true)
          setMessage("Record has been successfully deleted")
          setSeverity("success")
          setFilterFinal({})
          setPageInd(false)
          fx_back()
        }
      }catch(err){
        console.log(JSON.stringify(err, null, 2))
        setErrOpen(true)
        setErrMsg(JSON.stringify(err.message, null, 2))
        setErrSevere("error")
      }
  }

  //this function to go back to overview page
  //clear all the filter value
  const onClickBack = () => {
    setFilterValue({})
    setPreFilter({})
    setFilterFinal({}) 
    setLiveFilter({})   
    setPageInd(false) //true: go to details page, false: overview page.
    fx_back()
  }

    return (
      
        <div className="form-container">
            <div className="navigation-title-placeholder">
                <div className="title-add">
                      <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                       <div onClick={() => onClickBack()} style={{cursor: "pointer"}} className="detail-title first-title">
                         {title}
                       </div>
                       <div className="detail-title">
                          <img loading="lazy" src={NavigationIcon} alt="Back"/>
                       </div>                      
                       <div className="detail-title">Edit {title}</div>
                       <div className="detail-title"></div>

                </div>
                <div className="button-delete-save-container">
                  {
                    featureDisable ? null : (
                      <DeleteCard 
                        module="asset-group" 
                        fx_delete={onDelete} 
                        query={{deleteQuery: DELETE_ASSET_GROUP, getQuery: GET_ASSETGROUPS}} 
                        canDelete={staticAssetList && staticAssetList.length > 0 ? false : true }
                        setSnOpen={setSnOpen} 
                        setMessage={setMessage}
                        setSeverity={setSeverity}
                        setErrMsg={setErrMsg} 
                        setErrOpen={setErrOpen} 
                        setErrSevere={setErrSevere}
                      />
                    )
                  }
                  {
                    featureDisable ? null : (
                      <SaveCard onSubmit={onSubmit} canAdd={mandatoryField}/>
                    )
                  }
                </div>

               
            </div>
            <Form 
              asset_group={values} 
              setDisplayed={setDisplayed} 
              onUserInput={onUserInput} 
              displayed={displayed} 
              staticAssetList={staticAssetList} 
              addAssetList={add_asset} 
              setAddAsset={setAdd_asset} 
              removeAssetList={remove_asset} 
              setRemoveAsset={setRemove_asset} 
              featureDisable={featureDisable}
            />
            
        <style>
          {`
            .title-add{
              display: flex;
              align-items: center;            
            }
            .form-container{
              margin: 24px;
            }
            .navigation-title-placeholder {
              font-size: 18px;
              font-weight: 545;
              height: 32px;
              margin-bottom: 16px;
              display: flex;
              justify-content: space-between;
            }
            .details {
              height: 40px;
              // margin-left: 24px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.54);
            }
            .autocomplete {
              display: flex;
              height: 45px;
              // margin-left: 24px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.54);
            }
            .textfield {
              position: relative;
              bottom: 40px;
            }
            .detail-title{
              padding: 8px;
            }
            .first-title{
              font-size: 16px; 
            }
            .button-delete-save-container{
              display: flex;
            }
          `}
        </style>
      </div>
    )
}

export default EditFormNew
