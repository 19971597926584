import React from 'react'
import ReactTexty from 'react-texty'
import BaseTable, { AutoResizer } from 'react-base-table'
import moment from "moment-timezone";
import styled from 'styled-components'



const Container = styled.div`
    width: 100%; 
    height: 66vh;  
`


const fuel_report = [
    {key: "time", title: "Date/Time", dataKey: "time", width: 250, flexGrow: 1, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> },
    {key: "position", title: "Position", dataKey: `position`, width: 150, flexGrow: 1, cellRenderer: ({ cellData }) => <a href={`https://www.google.com/maps/search/?api=1&query=${cellData}`} rel="noopener noreferrer" target="_blank">{cellData}</a> },
    {key: "fuelVolume", title: "Fuel Volume (litres)", dataKey: "fuelVolume", width: 120, flexGrow: 1, cellRenderer: ({ cellData }) => (<ReactTexty>{cellData}</ReactTexty>)},
    {key: "fuelPercentage", title: "Fuel Percentage (%)", dataKey: "fuelPercentage", width: 120, flexGrow: 1, cellRenderer: ({ cellData }) => (<ReactTexty>{cellData}</ReactTexty>)},
]


const ReportTable = ({reportData, searchError}) =>  {
    // console.log(reportData)


    
    const setData = (data) => {
        return data && data.map((docs, key) => {
            return {
                key: key,
                time: `${docs.time ? moment(docs.time).format('DD MMMM YYYY, hh:mm:ss a') : '-'}`,               
                position: `${docs.latitude !== 0 && docs.longitude !== 0 ? docs.latitude !== null && docs.longitude !== null ? `${parseFloat(docs.latitude).toFixed(6)},${parseFloat(docs.longitude).toFixed(6)}` : "-" : "-"}`,
                fuelVolume: `${docs.fuelVolume ? parseFloat(docs.fuelVolume).toFixed(2) : '-'}`,
                fuelPercentage: `${docs.fuelPercentage ? parseFloat(docs.fuelPercentage).toFixed(2) : '-'}`
            }
        })
    }

    return (
        <div className="table-container">
             {
                <div className="white-box">
                    <Container>
                        <AutoResizer>
                            {({ width, height }) => (
                                <BaseTable columns={fuel_report} width={width} height={height} data={setData(reportData)} />
                            )}
                        </AutoResizer>
                    </Container>
                </div>
            }
            <style>
                {
                    `
                    .table-container{
                        display: grid;
                        align-items: center;
                        height: 35vh;
                        /* grid-auto-rows: 94px; */
                        /* grid-gap: 20px; */
                        width: 100%;
                        position: relative;
                        top: 264px;
                        margin-top: 24px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default ReportTable
