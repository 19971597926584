import React, {useState, useEffect} from 'react'
import 'react-base-table/styles.css'
import ReactTexty from 'react-texty'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import moment from 'moment'
import styled from "styled-components";
import { useMutation } from "@apollo/react-hooks";


import GreenAddIcon from '../../Image/green_add.svg'
import GreenCopyIcon from '../../Image/green-copy.svg'

import AssignMany from '../../component/component-assignmany/AssignMany'
import PinModal from './PinModal'
import {TableSelect, Column} from '../../component/component-tableSelect/index'
import { options } from '../../config/config-table/table';
import { GET_ASSET, UPDATE_ASSET , GET_DEVICES} from "../../config/config-graphql/Graphql";

const columns = [
    { key: "sn", title: "SN", dataKey: "sn", flexGrow: 1, width: 50, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> },
    { key: "startDate", title: "Valid From", dataKey: "startDate", flexGrow: 1, width: 50, cellRenderer: ({ cellData }) => <ReactTexty>{cellData ? moment(cellData).format('DD/MM/YYYY hh:mm:ss a') : ""}</ReactTexty> },
    { key: "endDate", title: "Valid To", dataKey: "endDate", width: 50, flexGrow: 1, cellRenderer: ({ cellData }) => <ReactTexty> {cellData ? moment(cellData).format('DD/MM/YYYY hh:mm:ss a') : ""}</ReactTexty> },
    { key: "PIN", title: "PIN Code", dataKey: "pin", flexGrow: 1, width: 50, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> }
]

const Content = styled.div`
flex: 1 1 auto;
height: 223px;
.BaseTable{
  box-shadow: 0px 0px #FFFFFF;
}

.BaseTable__row {
  /* color: #9ca2b3; */
}

.BaseTable__table-main {
  outline: none;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #d0d3db;
  }
}

.BaseTable__header-row {
  background: #fff;
}

.BaseTable__header-cell:hover *:not(span) {
  color: #6899f8;
  &:before,
  &:after {
    background: #6899f8;
  }
}

.BaseTable__header-cell-text {
  color: #939598;
  font-size: 0.9em;
  user-select: none;

  &:hover {
    color: #6899f8;
  }
}

.BaseTable__row-cell{
  font-size: 12px;

}
`;


const FormSmartLockCard = ({asset, device, isAdd,dvLoading, staticAvailableSmartLocks, staticSmartLockList, staticDeviceSmartLock, smartLocksOptions, add_smartLocks, setAdd_smartLocks, remove_smartLocks, setRemove_smartLocks, isSmartLocks, setSmartLocksOptions, setSLselected, sLselected, setSlDefault, slDefault, setFirstLoadSL, firstLoadSL, smartlockSchema, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere, removeDeviceList, featureDisable}) => {
    const [modal, setModal] = useState(false)
    const [copy, setCopy] = useState([])
    const [copyString, setCopyString] = useState("")
    const [rowsIndex, setRowsIndex] = useState([])
    const [count, setCount] = useState(0)
    const [isHovered, setIsHovered] = useState({
        apply: false
    })

    const [assetDevice, setAssetDevice] = useState([])
    const [assetUUID, setAssetUUID] = useState({})
    const [asset_device, setAsset_device] = useState([])

    // const { data, loading, error } = useQuery(GET_DEVICES, { variables: { filter: {uuid: }, type:"All" }, });

    useEffect(() => {
        let newFormat = copy.map(x => {return `sn: ${x.sn}, PIN: ${x.pin}`})
        setCopyString(newFormat.join('\n'))
        setCount(copy.length)
    }, [copy])
    
    useEffect(() => {
        if (!isAdd){
            const asset_device = asset.map(x => x.asset_device[0])
            const smartlock = asset_device && asset_device.length > 0 && typeof asset_device[0] !== "undefined" && asset_device.filter(x => x.deviceType === "Smartlocks")
            const reducer = smartlock && smartlock.length > 0 && smartlock.map(x => {return {sn: x.sn, endDate: x.smartlockPin ? x.smartlockPin.endDate : "", PIN: x.smartlockPin ? x.smartlockPin.pin : ""}})
            setAssetDevice(reducer)
        }
    }, [dvLoading])

    const btnApply = async (e) => {
        //this on edit page



        if(!isAdd){
            const add_device = smartlockSchema && smartlockSchema.length > 0 ? smartlockSchema.map(x => {return { uuid: x}}) : []
            const uuid = asset[0].uuid

            const response = await editData({variables: {updated_asset: {uuid: uuid, add_device: add_device}}})

            if(response.errors){
                setErrOpen(true)
                setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
                setErrSevere("error")
              }
              else{
                setSnOpen(true)
                setMessage("Record has been successfully added")
                setSeverity("success")
                if(sLselected.length > 0){ //this value after click apply but smartlock not yet assign
                    // console.log(add_smartLocks)
                    if(add_smartLocks.length > 0){
                        const listNewAssign = sLselected.filter(item => add_smartLocks.includes(item.value))
                        // console.log(staticDeviceList)
                        const listNewAssignValue = listNewAssign.map(x => {return {uuid: x.value, sn: x.label, startDate: "",  endDate:"", pin:"" }})
                        const remainingSmartlock = staticDeviceSmartLock.map(x => {return {uuid: x.uuid, sn:x.sn, startDate: x.smartlockPin.startDate, endDate: x.smartlockPin.endDate, pin:x.smartlockPin.pin}})

                        const combineAssignRemaining = remainingSmartlock.concat(listNewAssignValue)

                        setSlDefault(combineAssignRemaining)
                    }  
                    
                    
                }else{ //this value if the smartlock already assign and click apply it will add in to the state and able to generate the pin
                    //to check if there is removeDeviceList and use this to filter the list only remining
                    if(removeDeviceList.length > 0){
                        const listRemaining = staticDeviceSmartLock.filter(item => !removeDeviceList.includes(item.uuid))
                        setSlDefault(listRemaining.map(x => {return {uuid:x.uuid, sn: x.sn, startDate: x.smartlockPin.startDate, endDate: x.smartlockPin.endDate, pin:x.smartlockPin.pin}}))
                    }
                    // setSlDefault(staticDeviceSmartLock.map(x => {return {uuid:x.uuid, sn: x.sn, startDate: x.smartlockPin.startDate, endDate: x.smartlockPin.endDate, pin:x.smartlockPin.pin}}))
                }
              } 
        }else{
            if(sLselected.length > 0){ //this value after click apply but smartlock not yet assign
                setSlDefault(sLselected.map(x => {return {uuid: x.value, sn: x.label, startDate: "",  endDate:"", pin:"" }}))
            }else{ //this value if the smartlock already assign and click apply it will add in to the state and able to generate the pin
                setSlDefault(staticDeviceSmartLock.map(x => {return {uuid:x.uuid, sn: x.sn, startDate: x.smartlockPin.startDate, endDate: x.smartlockPin.endDate, pin:x.smartlockPin.pin}}))
            }
        }
    }   

    const [editData, {loading:assetLoading}] = useMutation(UPDATE_ASSET, {
        refetchQueries: () => [
            {
                query: GET_ASSET,
                variables: { filter: {} },
            },
        ],
    });
    
    return (
        <div className="smart-lock-card-container">
            <section>
                <div className="smart-title">
                    <p className="title-smart-locks">Smart Locks</p>
                    {featureDisable ? null : <button className={`${isHovered.apply ? "isHovered-apply-btn": "smart-btn-apply"}`} onClick={(e) => btnApply(e)} onMouseEnter={() => setIsHovered((current) => ({...current, apply: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, apply: false}))}>Apply</button>}
                </div>
                <AssignMany 
                    title="" 
                    description="" 
                    optionList={device}
                    staticAssignedList={staticDeviceSmartLock.map(x => x.uuid)}
                    staticAvailableList={staticAvailableSmartLocks}
                    addList={add_smartLocks}
                    setAddList={setAdd_smartLocks}
                    removeList={remove_smartLocks}
                    setRemoveList={setRemove_smartLocks}
                    filterDropdownOption={[]}
                    isSmartLocks={isSmartLocks}
                    setSLselected={setSLselected}
                    featureDisable={featureDisable}
                />
            </section>
            <section>
                <div className="pin-code-container">
                    <p className="pin-code-text">Pin Code</p>
                    <div className="img-button">
                            <CopyToClipboard text={copyString} >
                                <div className="copy-button-container">
                                    <img loading="lazy" src={GreenCopyIcon} alt="copy-icon" style={{height: "16px", width: "16px", marginRight:"8px"}}/>
                                    <p className="clipboard-text">Copy ({count}) to Clipboard</p>
                                </div>
                            </CopyToClipboard>
                            { featureDisable ? null :
                            <div className="copy-button-container" onClick={() => setModal(!modal)}>
                                <img loading="lazy" src={GreenAddIcon} alt="add-icon"  style={{height: "16px", width: "16px", marginRight:"8px"}} />
                                <p className="generate-text">Generate PIN Code</p>
                            </div>
                            }
                    </div>
                </div>
                <div className="table-selection-container">
                    <TableSelect data={slDefault} rowKey="sn" selectable setCopy={setCopy} copy={copy} headerHeight={35} rowHeight={34} setRowsIndex={setRowsIndex} removeSelection={true} Content={Content}>
                        {columns.map(({ dataKey, ...restProps }) => (
                            <Column key={dataKey} dataKey={dataKey} {...restProps} />
                        ))}
                    </TableSelect>
                </div>
            </section>
            {
                modal ? <PinModal setModal={setModal} modal={modal} slDefault={slDefault} setSlDefault={setSlDefault} isAdd={isAdd} asset={asset}/> : null
            }
            <style>
                {
                    `
                    .smart-lock-card-container{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 40px;
                        grid-template-areas:
                          ". .";
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        margin: 24px 0px 0px 0px;
                        padding: 16px;
                    }
                    .smart-title{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 0px 0px 8px 0px;
                    }

                    .title-smart-locks{
                        margin: 0px;
                        color: #333333;
                        font-size: 15px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 24px;
                    }
                    .smart-btn-apply{
                        box-sizing: border-box;
                        height: 33px;
                        width: 214px;
                        border: 1px solid #509D91;
                        border-radius: 2px;
                        color: #509D91;
                        font-weight: 500;
                        cursor: pointer;

                    }
                    .isHovered-apply-btn{
                        box-sizing: border-box;
                        height: 33px;
                        width: 214px;
                        border: 1px solid #498f84;
                        border-radius: 2px;
                        color: #498f84;
                        font-weight: 600;
                        cursor: pointer;
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                    }
                    .title-smart-locks{

                    }
                    .table-selection-container{

                    }
                    .copy-button-container{
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }
                    .pin-code-container{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: 40px;
                        margin: 0px 0px 16px 0px;
                    }
                    .img-button{
                        display: flex;   
                        align-items:center;
                    }
                    .clipboard-text{
                        margin: 0px 24px 0px 0px;
                        color: #509D91;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 24px;
                    }
                    .pin-code-text{
                        margin: 0px;
                        color: #333333;
                        font-size: 15px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 24px;
                        
                    }
                    .generate-text{
                        margin: 0px;
                        color: #509D91;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 24px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default FormSmartLockCard
