import React, { useState, useContext, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks';
import { FETCH_ALL_PAYLOAD } from '../../config/config-graphql/Graphql'
import { WebsocketContext } from "../../config/config-websocket/Websocket";
import { isEmptyObject } from "../../config/config-function/function"
import TableTelemetric from "./TableTelemetric"
import _cloneDeep from "lodash.clonedeep";

const PanelTelemetry = ({ filter, setFilter, fx_changePage, history }) => {
    const payloadContext = useContext(WebsocketContext);
    //this state for first page load default 0 and searchValue= ""
    const [searchValue, setSearchValue] = useState(filter)

    // console.log(filter);

    // variables value for fetchALlPayload
    const pairId = searchValue && searchValue.pairId;

    const { data: websocketPayload, new_payload_id } = payloadContext;
    const { data, loading, error } = useQuery(FETCH_ALL_PAYLOAD, { variables: { pairId: searchValue && searchValue.pairId} });

    // !loading && console.log(data)
    // console.log(loading);

    // !loading && console.log(data)
    const [dataPayload, setDataPayload] = useState([])
    // Only executed on first click
    useEffect(() => {
        // console.log("First Load useEffect Execution")
        if (!loading && data && typeof (searchValue) !== "undefined") {
            setDataPayload(data.fetchAllPayload)
        }
        else {
            setDataPayload([])
        }
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    // Executed with every new subscription payload entry
    useEffect(() => {
        // console.log()
        if (new_payload_id && websocketPayload.pairId === pairId) {
            // console.log(websocketPayload.blobID)
            const clone_data = _cloneDeep(dataPayload)
            clone_data.unshift(websocketPayload);
            setDataPayload([...clone_data])
        }
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [new_payload_id])

    return (
        <div>
            <TableTelemetric data={dataPayload} loading={loading} searchValue={searchValue} setSearchValue={setSearchValue} filter={filter} setFilter={setFilter} fx_changePage={fx_changePage} error={error} filter={filter} setFilter={setFilter} history={history}/>
        </div>
    )
}

export default PanelTelemetry
