import React from 'react'

//component
import tableConfig from "../../config/config-table/table";
import { requiredLabel } from "../../config/config-function/function";
import menuConfig from '../../config/config-menu/menu'

const { asset: fieldList } = tableConfig.fieldList;

const FormAssetInformation = ({asset, isAdd, fx_changePage, setLiveFilter, onUserInput, details, setIsHovered, isHovered, history, featureDisable}) => {

    const onChangePage = () => {
        if(asset[0] && asset[0].asset_group && asset[0].asset_group.name){
            setLiveFilter({name: {type: "IS", value: [asset[0] && asset[0].asset_group && asset[0].asset_group.name]}})
            fx_changePage(menuConfig.dashboard[2].key, menuConfig.dashboard[2].children[2].key)
            history.push("/dashboard/asset_mgmt/asset_group")
        }
    }

    // console.log(asset)
    return (
        <div className="first-box-container">
        <div className="asset-title">Asset Information</div>
        <div className="asset-input">
            <div className="asset-container">
                <div className="device-information">
                    <div className="details-input-asset" >
                        <div className="input-input">Name <p style={{color:"red", margin:0}}>{requiredLabel(fieldList.name.required)}</p></div> 
                        {featureDisable ? <label className="label-box">{Array.isArray(asset) && asset.length === 1 ? asset[0].name : asset.name}</label> : <input type="text" className={`${isHovered.name ? "isHovered-border" : "text-box"}`}  placeholder="-" name="name" onChange={(e) => onUserInput(e)} value={Array.isArray(asset) && asset.length === 1 ? asset[0].name : asset.name} onMouseEnter={() => setIsHovered((current) => ({...current, name: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, name: false}))}/>}
                    </div>
                    {!isAdd ? (                
                        <div className="details-input-asset" >
                            <div className="input-input">Asset Group</div> 
                            <a className="link-asset-group" onClick={() => onChangePage()} onMouseEnter={() => setIsHovered((current) => ({...current, asset_group: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, asset_group: false}))}>{asset[0] && asset[0].asset_group && asset[0].asset_group.name}</a>
                        </div>
                    ) : null}
                    
                    <div className="details-input-asset">
                        <div className="input-input">Type </div> 
                        {featureDisable ? <label className="label-box">{Array.isArray(asset) && asset.length === 1 ? asset[0].type : asset.type}</label> :<input type="text" className={`${isHovered.type ? "isHovered-border" : "text-box"}`}  placeholder="-" name="type" onChange={(e) => onUserInput(e)} value={Array.isArray(asset) && asset.length === 1 ? asset[0].type : asset.type} onMouseEnter={() => setIsHovered((current) => ({...current, type: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, type: false}))}/>}
                    </div>
                    <div className="details-input-asset" >
                        <div className="input-input">Model</div> 
                        {featureDisable ? <label className="label-box">{Array.isArray(asset) && asset.length === 1 ? asset[0].model : asset.model}</label> : <input type="text" className={`${isHovered.model ? "isHovered-border" : "text-box"}`}  placeholder="-" name="model" onChange={(e) => onUserInput(e)} value={Array.isArray(asset) && asset.length === 1 ? asset[0].model : asset.model} onMouseEnter={() => setIsHovered((current) => ({...current, model: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, model: false}))}/>}
                    </div>
                    <div className="details-input-asset" >
                        <div className="input-input">Manufacturer</div> 
                        {featureDisable ? <label className="label-box">{Array.isArray(asset) && asset.length === 1 ? asset[0].manufacturer : asset.manufacturer}</label> : <input type="text" className={`${isHovered.manufacturer ? "isHovered-border" : "text-box"}`}  placeholder="-" name="manufacturer" onChange={(e) => onUserInput(e)} value={Array.isArray(asset) && asset.length === 1 ? asset[0].manufacturer : asset.manufacturer} onMouseEnter={() => setIsHovered((current) => ({...current, manufacturer: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, manufacturer: false}))}/>}
                    </div>
                    <div className="details-input-asset" >
                        <div className="input-input">Description</div> 
                        {featureDisable ? <label className="label-box">{Array.isArray(asset) && asset.length === 1 ? asset[0].description : asset.description}</label> : <input type="text" className={`${isHovered.description ? "isHovered-border" : "text-box"}`}  placeholder="-" name="description" onChange={(e) => onUserInput(e)} value={Array.isArray(asset) && asset.length === 1 ? asset[0].description : asset.description} onMouseEnter={() => setIsHovered((current) => ({...current, description: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, description: false}))}/>}
                    </div>
                 </div>
            </div>
            <div className="device-details">{details}</div>
        </div>

    </div>
    )
}

export default FormAssetInformation
