import React, {useState} from 'react'

//image
import AddIcon from "../../Image/dark_add.svg";

//component
import SearchPolicy from './SearchPolicy'
import AddForm from './AddForm'
import EditForm from "./EditForm"
import Loaders from "../../component/component-loaders/Loaders"


const GeofenceList = ({setKeySearch , keySearch, searchLoading, searchError, searchGeofence, APICalled, setUuid, modeId, setModeId, modeHandler, setModeHandler, selectedFeatureIndex, setSelectedFeatureIndex, editorRef, setEditorRef, geoJSON, setGeoJSON, setName, name, setPolygons, polygons, setMapping, mapping, setSnOpen, setMessage, setSeverity, featureDisable}) => {
    const [addGeofence, setAddGeofence] = useState(false)
    const [editGeofence, setEditGeofence] = useState(false)
    const [polycies, setPolycies] = useState("")
    const [removePol, setRemovePol] = useState([])
    const [hoveredO, setIsHoveredO] = useState({
        add: false,
        filter: false
    })

    const backButton = (value) => {
        if(value === 'add'){
            setPolygons([]);
            setAddGeofence(!addGeofence);
            APICalled('','');
        } else {
            setPolygons([]);
            setEditGeofence(!editGeofence);
            APICalled('','');
        }
    }

    return searchLoading ? <Loaders /> : addGeofence ? (<AddForm fx_back={() => backButton('add')} APICalled={APICalled} setIsHoveredO={setIsHoveredO} modeId={modeId} setAddGeofence={setAddGeofence} addGeofence={addGeofence} setModeId={setModeId} modeHandler={modeHandler} setModeHandler={setModeHandler} selectedFeatureIndex={selectedFeatureIndex} setSelectedFeatureIndex={setSelectedFeatureIndex} editorRef={editorRef} setEditorRef={setEditorRef} geoJSON={geoJSON} setGeoJSON={setGeoJSON} setName={setName} name={name} setPolygons={setPolygons} polygons={polygons} setMapping={setMapping} mapping={mapping} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity}/>) : 
    editGeofence ? (<EditForm fx_back={() => backButton('edit')}  dataGeofence={!searchLoading && searchGeofence} APICalled={APICalled} getLoading={searchLoading} setEditGeofence={setEditGeofence} editGeofence={editGeofence} modeId={modeId} setModeId={setModeId} modeHandler={modeHandler} setModeHandler={setModeHandler} selectedFeatureIndex={selectedFeatureIndex} setSelectedFeatureIndex={setSelectedFeatureIndex} editorRef={editorRef} setEditorRef={setEditorRef} geoJSON={geoJSON} setGeoJSON={setGeoJSON} setName={setName} name={name} setPolygons={setPolygons} polygons={polygons} polycies={polycies} setPolycies={setPolycies} setMapping={setMapping} mapping={mapping} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} featureDisable={featureDisable}/>) :
    (
        <div className="geofenceList-container">
            <div className="search-container">
                <SearchPolicy setKeySearch={setKeySearch} keySearch={keySearch} APICalled={APICalled} />
                {
                    featureDisable ? null : (
                    <div className="button-add" onClick={() => setAddGeofence(!addGeofence)}>
                        <img loading="lazy" src={AddIcon} alt="Icon" className={hoveredO.add ? "add-hover-button-style" : "add-button-style"} onMouseEnter={() => setIsHoveredO((current) => ({...current, add: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, add: false}))} />
                    </div>
                    )
                }

            </div>
            <div>
                {
                    searchError ? (<p>error occured</p>) : searchLoading ? <Loaders /> : (
                        searchGeofence.length > 0 && searchGeofence.map((geofence, i) => {
                            return <ListGeo data={geofence} key={i} editGeofence={editGeofence} APICalled={APICalled} setUuid={setUuid} setEditGeofence={setEditGeofence} polycie={polycies} setPolycies={setPolycies} setRemovePol={setRemovePol} removePol={removePol} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity}/>
                        })
                    )
                }
            </div>
            <style jsx>
                {
                    `
                    .geofenceList-container{
                        margin: 16px 11px 11px 11px;
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: 43px 1fr;
                        gap: 10px 0px;
                        grid-template-areas:
                          "."
                          ".";
 
                    }
                    .search-container{
                        display: grid;
                        grid-template-columns: 1fr 24px;
                        grid-template-rows: 1fr;
                        gap: 0px 13px;
                        grid-template-areas:
                          "." ".";
                        align-items: center;

                    }
                    .button-add{
                        cursor: pointer;
                        width: 100%;
                        display: flex;
                        justify-content: center;                   
                    }
                    .add-button-style{
                        cursor: pointer;
                    }
                    .add-hover-button-style{
                        background-color: rgba(0,0,0,0.03);
                        border-radius: 5px;
                        cursor: pointer;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default GeofenceList

const ListGeo = ({ data, editGeofence, setEditGeofence, APICalled, key }) => {
    const [isHovered, setIsHovered] = useState({
        details: false
    })

    const onCard = (x) => {
        APICalled('', x.uuid);
        setEditGeofence(!editGeofence);
    }

    return (
        <div className="list-policies" onClick={() => onCard(data)}>    
            <div key={key} className={`${isHovered.details ? "isHovered-geo-card": "geo-card"}`} onMouseEnter={() => setIsHovered((current) => ({...current, details: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, details: false}))} >
                <div className={`${isHovered.details ? "isHovered-geo-name": "geo-name"}`} >{data.name}</div>
            </div>  
            <style jsx>
                {
                    `
                    .geo-name{
                        cursor: pointer;
                        width: 100%;
                        padding: 0px 0px 0px 8px;
                    }
                    .isHovered-geo-name{
                        cursor: pointer;
                        width: 100%;
                        font-weight: 600;
                        padding: 0px 0px 0px 8px;
                    }
                    .geo-card{
                        margin: 8px 0px 4px 0px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 50px;
                        border-radius: 4px;
                        background-color: #FFFFFF;     
                    }
                    .isHovered-geo-card{
                        margin: 8px 0px 4px 0px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 50px;
                        border-radius: 4px;
                        background-color: #FFFFFF; 
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);   
                    }
                    .button-remove{
                        cursor: pointer;
                    }
                    .list-policies::-webkit-scrollbar {
                        display: none;
                    }
                    .list-policies{
                        overflow: auto;
                        scrollbarwidth: none;
                        msoverflowstyle: none;
                        max-height: 88vh;
                    }
                    
                    `
                }
            </style>

        </div>
    )
}
