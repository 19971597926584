import React from 'react'
import {DrawPolygonMode} from "react-map-gl-draw";

//image
import DarkPolygonIcon from "../../Image/polygon_dark.svg"
import GreenPolygonsIcon from '../../Image/green_polygons.svg'
import CloseIcon from "../../Image/close.svg";
import GreenCloseIcon from '../../Image/green_close_button.svg'
import GreenAddIcon from '../../Image/green_add.svg'
import GreyAddIcon from '../../Image/grey_add.svg'

const DrawPolygon = {id: "drawPolygon", text: "Draw Polygon", handler: DrawPolygonMode}

const GeofenceAreaEdit = ({wrapperRef, selectedFeatureIndex, polygons, onChangeText, btnPolyList, onDelete, btnPolygon, modeId, featureDisable}) => {
    return (
        <div className="geofence-area-container-edit">
            <header className="geofence-title-edit">
                <p className="geofence-area-text-edit">Geofence Area</p>
            </header>
            {
                polygons && polygons.length > 0 ?  
                    (
                        <div ref={wrapperRef} className="list-polygons" >
                            {
                                (polygons.map((x, i) => {    
                                    return (featureDisable) ? (
                                    <div key={i} className='polygon-container-hover'>
                                        <div style={{width: "100%", display:"flex"}}>
                                            <img loading="lazy" src={ DarkPolygonIcon} alt="dark-polygon" className="dark-polygon-img"/>  
                                            <label className="input-text">{polygons[i].name}</label>                  
                                            {/* <input type="text" className={featureDisable ? 'input-text' : selectedFeatureIndex.selectedFeatureIndex !== i ? 'input-text' : 'input-text-highlight'} value={polygons[i].name} onChange={(e) => onChangeText(e, i)} placeholder="Enter Area Name"/> */}
                                        </div>

                                        {/* <div onClick={() => onDelete(i)} className="close-icon">
                                            <img loading="lazy" src={selectedFeatureIndex.selectedFeatureIndex !== i ? CloseIcon : GreenCloseIcon} alt="close-icon" />
                                        </div>                                                                                      */}
                                    </div> 
                                    ) : (x.action && x.action !== "DELETE") || (!x.action) ? (
                                     
                                    <div key={i} onClick={() => btnPolyList(i, x)} className='polygon-container-hover'>
                                        <div style={{width: "100%", display:"flex"}}>
                                            <img loading="lazy" src={selectedFeatureIndex.selectedFeatureIndex !== i ? DarkPolygonIcon : GreenPolygonsIcon} alt="dark-polygon" className="dark-polygon-img"/>                    
                                            <input type="text" className={featureDisable ? 'input-text' : selectedFeatureIndex.selectedFeatureIndex !== i ? 'input-text' : 'input-text-highlight'} value={polygons[i].name} onChange={(e) => onChangeText(e, i)} placeholder="Enter Area Name"/>
                                        </div>

                                        <div onClick={() => onDelete(i)} className="close-icon">
                                            <img loading="lazy" src={selectedFeatureIndex.selectedFeatureIndex !== i ? CloseIcon : GreenCloseIcon} alt="close-icon" />
                                        </div>                                                                                     
                                    </div>
                                    ) : null
                                    }
                                )
                                )
                            }
                        </div>
                    ) : <div></div>
            }
            {
                featureDisable ? null : (
                    <div className="btn-list-area">
                        <img loading="lazy" src={modeId ? GreyAddIcon : GreenAddIcon} alt="Icon" onClick={() => btnPolygon(DrawPolygon.id)} style={{cursor: "pointer"}}/>
                        <div >
                            <p className="draw-polygon">Draw Polygon</p>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default GeofenceAreaEdit
