import React, {useState, useEffect} from 'react'
import { snakeCase } from "snake-case"

import GreenCheckedIcon from '../../Image/green_checked.svg'
import GreyCloseIcon from '../../Image/grey-close.svg'

const FormFeatures = ({asset, featureList, isPaired, details}) =>  {
    const [mainFeatures, setMainFeatures] = useState([])
    const [pairedFeatures, setPairedFeatures] = useState([])
    const [mainFName, setmainFName] = useState([])
    const [pairedFName, setPairedFName] = useState([])

    const getFeatureIcon = (name) => {
        if(featureList){
            let feature = featureList.find(el => el.uuid === name)
            return feature ? feature.uuid : ""     
        } else{
            return null
        }
    }

    useEffect(() => {
        if(asset){
            const mainAsset = Array.isArray(asset) ? asset[0] : asset
            const pairedAsset = isPaired ? Array.isArray(asset) ? asset[0].paired_assets[0] : asset.paired_assets[0] : {}
            let mainFeatureSet = new Set()
            mainAsset.asset_device && mainAsset.asset_device.map(dv => {
                setmainFName(dv.features)
                dv.features.map(el => {
                    let icon = getFeatureIcon(el);
                    icon && mainFeatureSet.add(icon)
                    return null
                })
                return null
            })
            mainAsset.asset_device && mainAsset.asset_device.map(dv => {
                setmainFName(dv.SKU.features)
                dv.SKU.features.map(el => {
                    let icon = getFeatureIcon(el);
                    icon && mainFeatureSet.add(icon)
                    return null
                })
                return null
            })
            setMainFeatures(Array.from(mainFeatureSet))

            if(isPaired){
                let pairedFeatureSet = new Set()
                pairedAsset.asset_device && pairedAsset.asset_device.map(dv => {
                    setPairedFName(dv.features)
                    dv.features.map(el => {
                        let icon = getFeatureIcon(el);
                        icon && pairedFeatureSet.add(icon)
                        return null
                    })
                    return null
                })
                pairedAsset.asset_device && pairedAsset.asset_device.map(dv => {
                    setPairedFName(dv.SKU.features)
                    dv.SKU.features.map(el => {
                        let icon = getFeatureIcon(el);
                        icon && pairedFeatureSet.add(icon)
                        return null
                    })
                    return null
                })

                setPairedFeatures(Array.from(pairedFeatureSet))
            }
        }
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset])

    //display icon main feature
    const MainFeature = ({selectedName, featureList}) => {
        // console.log("mainSelected", featureName)
        // console.log("featureList", featureList)
        // console.log("selectedFeature", selectedName)
        if(selectedName.includes(featureList.uuid)){
            return(
                <div className="feature-checked-icon">
                    <img loading="lazy" src={GreenCheckedIcon} alt="green-checked-icon" style={{marginRight: "8px", height: "16px", width: "16px"}}/>
                </div>          
            )
        }else{
            return(
                <div className="feature-checked-icon">
                    <img loading="lazy" src={GreyCloseIcon} alt="grey-close-icon" style={{marginRight: "8px", height: "16px", width: "16px"}}/>
                </div>           
            )
        }
    }

    //display icon pairedFeature
    const PairedFeature = ({pairedSelectedName, featureList}) => {
        // console.log("paired", featureName)
        if(pairedSelectedName.includes(featureList.uuid)){
            return(
                <div className="feature-checked-icon">
                    <img loading="lazy" src={GreenCheckedIcon} alt="green-checked-icon" style={{marginRight: "8px", height: "16px", width: "16px"}}/>
                </div>
               
            )
        }else{
            return(
                <div className="feature-checked-icon">
                       <img loading="lazy" src={GreyCloseIcon} alt="grey-close-icon" style={{marginRight: "8px", height: "16px", width: "16px"}}/>
                </div>              
            )
        }
    }

    return (
        <div className="feature-container">
            <div className="asset-title">Features & Sensors</div>
            <div className="paired-asset-detail">{details}</div>
            <div className="feature-img">
                {
                    isPaired ? 
                    <div className="text-primary">
                        <div style={{fontSize:"11px", marginRight: "4px", width: "40px"}}>Primary</div>
                        <div style={{fontSize:"11px", color:"#F5A623", marginRight: "4px", width: "40px"}}>Paired
                        </div>
                    </div> : null
                }

                {
                    featureList.map((x, i) => {
                        try{
                            return(
                            <div className="checkbox-input">
                                {
                                    isPaired ? 
                                        <div className="paired-feature-container">
                                            <MainFeature selectedName={mainFeatures} featureList={x}/>
                                            <PairedFeature pairedSelectedName={pairedFeatures} featureList={x}/>
                                        </div> : 
                                        <div className="main-feature-container">
                                            <MainFeature selectedName={mainFeatures} featureList={x}/>
                                        </div>
                                }
                                <img loading="lazy" src={`${x.icon}/icon_${snakeCase(x.name)}_enabled.svg`} alt="test" className="images"/>
                                <div className="status">{x.name}</div>
                                <div className="details-status">{x.description}</div>
                            </div>
                            )
                        }catch(err){
                            console.log(err)
                        }
                    })
                }
                

            </div>
            <style>
                {
                    `.checkbox-input{
                        display: grid;
                        grid-template-columns:  80px 24px 150px 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". . . .";
                        align-items: center;
                        width: 100%;
                        margin: 0px 0px 8px 0px;
                    }
                    .feature-classes{
                        display: flex;
                        align-items: center;
                    }
                    .images{
                        margin-right: 8px;
                    }
                    .details-status{
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 23px;
                        margin: 0px 0px 0px 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .status{
                        margin: 0px 0px 0px 8px;
                    }
                    .text-primary{
                        display: flex;
                        margin: 0px 0px 4px 0px;
                    }
                    .paired-feature-container{
                        display: flex;
                    }
                    .feature-checked-icon{
                        display: flex;
                        // align-items: center;
                        justify-content: center;
                        width: 40px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default FormFeatures
