import React from 'react'
// import Loader from 'halogen/PacmanLoader'
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader"

const override = css`
  display: block;
  top: 50px;
  margin: 0 auto;
  border-color: red;
`;

const Loaders = ({isLoading}) => {
    return (
        <div>
            {/* <Loader color="#26A65B" size="16px" margin="4px"/> */}
            <FadeLoader color={"#36D7B7"} css={override}/>
        </div>
    )
}

export default Loaders
