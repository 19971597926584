import gql from "graphql-tag";

/*ALL THE GQL QUERY */

export const GET_ASSET = gql`
query get_assets($filter: AssetFilter) {
  get_assets(filter:$filter){
    assets{
      pairId
      uuid
      name
      type
      sequence
      manufacturer
      model
      description
      asset_group{
        uuid
        name
        description
      }
      asset_device{
        uuid
        sn
        description
        features
        SKU{
          product_model
          features
        }
        device_type
        smartlockPin{
          pin
          startDate
          endDate
        }
      }
      geofences{
        uuid
        name
      }
      status
      paired_assets{
        pairId
        uuid
        name
        type
        sequence
        manufacturer
        model
        description
        asset_group{
          uuid
          name
          description
        }
        asset_device{
          uuid
          sn
          description
          features
          SKU{
            product_model
            features
          }
        }
        geofences{
          uuid
          name
        }
      }
    }
    features{
      uuid
      name
      icon
      description
    }
  }
}
`;

export const GET_ASSET_TYPE_STATS = gql`
  query get_AssetType_Stat{
    get_AssetType_Stat{
      type
      total
    }
  }
`;

export const GET_PAIRABLE_ASSETS = gql`
  query get_pairable_assets($searchKey: String){
    get_pairable_assets(searchKey:$searchKey){
      uuid
      name
      pairId
    }
  }

`;

export const ADD_ASSET = gql`
mutation add_asset($new_asset: [AssetInput]) {
    add_asset(new_asset: $new_asset) {
      uuid
    }
  }
`;

export const SIGNUP_USER = gql`
mutation signup($input: UserInput) {
    signup(input: $input) {
      message
      status
      success
    }
  }
`;

export const UPDATE_ASSET = gql`
mutation update_asset($updated_asset: [AssetInput]) {
    update_asset(updated_asset: $updated_asset) {
      uuid
    }
  }
`;

export const UPDATE_USER = gql`
mutation updateUser($input: UserInput) {
    updateUser(input: $input) {
      message
      status
      success
    }
  }
`;


export const DELETE_ASSET = gql`
mutation delete_asset($asset_uuid: [ID]!) {
  delete_asset(asset_uuid:$asset_uuid){
    total
  }
  }
`;

export const DELETE_USER = gql`
mutation deleteUser($userId: ID, $agencyId: String) {
  deleteUser(userId:$userId, agencyId:$agencyId){
    message
    status
    success
  }
  }
`;

export const CONNECT_ASSET_DEVICE = gql`
  mutation connect_asset_device($device_id: String, $asset_id: String) {
    connect_asset_device(device_id: $device_id, asset_id: $asset_id) {
      asset_id
      asset_name
      asset_group{
        group_description
        group_id
        group_name
      }
    }
  }
`;

export const AVAILABLE_ASSETS = gql`
  query get_available_assets{
    get_available_assets{
      uuid
      name
      type
    }
  }
`;

export const assetQuery = { addQuery: ADD_ASSET, getQuery: GET_ASSET, editQuery: UPDATE_ASSET, deleteQuery: DELETE_ASSET }

export const GET_ASSETGROUPS = gql`
  query asset_group($filter: AssetGroupFilter) {
    get_asset_groups(filter: $filter){
      uuid
      name
      description
      assets{
        uuid
        name
        type
      }
      asset_count
    }
  }
`;

export const ADD_ASSET_GROUP = gql`
  mutation add_asset_group($new_asset_group: [GroupInput]) {
    add_asset_group(new_asset_group: $new_asset_group) {
      uuid
    }
  }
`;

export const UPDATE_ASSET_GROUP = gql`
mutation update_asset_group($updated_asset_group: GroupInput){
  update_asset_group(updated_asset_group: $updated_asset_group){
    uuid
  }
}
`;

export const DELETE_ASSET_GROUP = gql`
  mutation delete_asset_group($group_uuid: [ID]!) {
    delete_asset_group(group_uuid: $group_uuid) {
      total
    }
  }
`;

export const CONNECT_ASSET_ASSETGROUP = gql`
  mutation connect_asset_assetgroup($old_group_name: String!, $asset_id: String!, $new_group_name: String!) {
    connect_asset_assetgroup(old_group_name: $old_group_name, asset_id: $asset_id, new_group_name: $new_group_name) {
      success
      asset_group {
        group_id
        group_name
        group_description
      }
    }
  }
`;

export const assetGroupQuery  = { addQuery: ADD_ASSET_GROUP, getQuery: GET_ASSETGROUPS, editQuery: UPDATE_ASSET_GROUP, deleteQuery: DELETE_ASSET_GROUP }


export const GET_SKU = gql`
    query sku($filter: SKUFilter) {
      get_skus(filter:$filter){
        sku{
          uuid
          product_model
          product_category
          ID
          manufacturer
          product
          version
          project_name
          features
          device_count
          revision
          assemblies{
            uuid
            board_class
            assembly_id
            schematic_id
          }
        }
        feature_list{
          uuid
          icon
          name
          description
        }
      }
    }
`;



export const ADD_SKU = gql`
    mutation add_sku($new_sku: [SKUInput]) {
        add_sku(new_sku: $new_sku) {
          uuid
        }
    }
`;

export const UPDATE_SKU = gql`
mutation update_sku($updated_sku: [SKUInput] ){
    update_sku(updated_sku:$updated_sku){
          uuid
    }
  }
`;

export const DELETE_SKU = gql`
mutation delete_sku($sku_uuid:[ID]!){
    delete_sku(sku_uuid:$sku_uuid){
      total
    }
  }
`;

export const skuQuery = { addQuery: ADD_SKU, getQuery: GET_SKU, editQuery: UPDATE_SKU, deleteQuery: DELETE_SKU }

export const GET_AGENCIES = gql`
  query get_agencies($filter: AgencyFilter) {
    get_agencies(filter:$filter){
      uuid
      id
      name
      address{
        road
        city
        state
        postcode
        country
      }
      isActive
      contact_number
      contact_code
      contact_person
      total_devices
      sigfox_deviceTypeId
      timezone
      admin_user{
        uuid
        email
        firstName
        lastName
      }
      billing{
        uuid
        subscription_from
        subscription_to
      }
      total_users
      devices{
        uuid
        sn
        product_model
      }
    }
  }
`;

export const ADD_AGENCIES = gql`
 mutation add_agency($new_agency: AgencyInput){
  add_agency(new_agency: $new_agency){
        uuid
    }
 }
`;

export const UPDATE_AGENCIES = gql`
 mutation update_agency($updated_agency: AgencyInput){
  update_agency(updated_agency: $updated_agency){
        uuid
    }
 }
`;

export const DELETE_AGENCIES = gql`
 mutation delete_agency($agency: [AgencyInput]){
  delete_agency(agency: $agency){
    total
  }
 }
`;

export const GENERATE_SMARTLOCK_PIN = gql`
 mutation generate_smartlock_pin($input: GeneratePinInput){
  generate_smartlock_pin(input: $input){
    sn
    pin
    startDate
    endDate
  }
 }
`;

export const GET_AVAILABLE_DEVICES = gql`
 query get_available_devices($type:DeviceType){
   get_available_devices(type:$type){
    uuid
    sn
    product_model
   }
 }
`
export const GET_ASSIGNED_DEVICES = gql`
  query get_assigned_devices($Device: [Device]){
    get_assigned_devices(Device: $Device){
      uuid
      sn
      description
      SKU{
        uuid
        product_model
        product_category
        ID
        manufacturer
        product
        version
        revision
        project_name
        assemblies{
          uuid
          board_class
          assembly_id
          schematic_id
        }
      }
      agency{
        uuid
        sn
        description
      }
    }
  }
`;

export const GET_DEVICES_STAT = gql`
  query get_devices_stat{
    get_devices_stat{
      assigned{
        total
        product_model
      }
      available{
        total
        product_model
      }
    }
  }
`;

export const agenciesQuery = { addQuery: ADD_AGENCIES, getQuery: GET_AGENCIES, editQuery: UPDATE_AGENCIES, deleteQuery: DELETE_AGENCIES, getAvailableDevices: GET_AVAILABLE_DEVICES, getAssignDevices: GET_ASSIGNED_DEVICES}

export const GET_DEVICES = gql`
  query devices($filter: DeviceFilter, $type: DeviceType) {
    get_devices(filter: $filter, type: $type){
      devices{
        uuid
        sn
        description
        name
        SKU{
          uuid
          ID
          features
          product_model
        }
        agency{
          uuid
          id
          name
        }
        board{
          uuid
          assembly{
            uuid
            board_class
            assembly_id
            schematic_id
          }
          board_id
          batch_id
          privateKey
          firmware{
            uuid
            version
          }
          config{
            __typename
            ...on CellularConfig{
                deviceId
                projectId
                payloadInterval
                payloadIntervalIgnitionOff
                payloadIntervalOffline
                telitKeepAlive
                ignitionMode
                extSense_Fuel
                FuelSensingRatio
                extSense_Vibration
                rat
                blocked
                registryId
                lastHeartbeatTime
                lastEventTime
                lastStateTime
                lastConfigAckTime
                lastConfigSendTime
                lastErrorTime
                lastErrorStatus
            }
            ...on SigfoxConfig{
                deviceId
                payloadInterval
                payloadIntervalMotion
                gpsSeekPeriod
                variableInterval
                downlinkFrequency
                revertDefault
            }
          }
        }
        fuel_sensor{
          length
          width
          height
        }
        features
        smartlockPin{
          pin
          startDate
          endDate
        }
        device_type
        date_smartlock_pair
      }
      features{
        uuid
        icon
        name
        description
      }
      total_missing_sku
    }
  }
`;

export const GET_DEVICE_MANIFEST = gql`
  query devices($filter: DeviceFilter, $type: DeviceType) {
    get_devices(filter: $filter, type: $type){
      devices{
        uuid
        sn
        SKU{
          ID
        }
        board{
          uuid
          board_id
          privateKey
          config{
            __typename
            ...on CellularConfig{
                deviceId
                projectId
            }
            ...on SigfoxConfig{
                deviceId
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_DEVICES = gql`
  query searchDevices($searchKey: String, $boardClass: BoardClass) {
    searchDevices(searchKey: $searchKey, boardClass: $boardClass){
      uuid
      sn
      SKU{
        ID
      }
      board{
        board_id
      }
    }
  }
`;


export const GET_CHANNELS = gql`
  query get_channels($filter: ChannelFilter){
    get_channels(filter: $filter){
      uuid
      name
      type
      notifications{
        generateEOT
        generateEOD
      }
      userList{
        uuid
        email
        firstName
        lastName
        privileges{
          agencyId
          agencyName
          agencyUUID
          role
          chatBotEnabled
          tgNotification
        }
        verified
        telegram{
          username
          contactNo
          contactCode
          contactVerified
          chatId
        }
      }
    }
}
`;

export const GET_USERS = gql`
  query getAgencyUsers($filter: UserFilter){
    getAgencyUsers(filter: $filter){
      uuid
      email
      firstName
      lastName
      verified
      privileges{
        agencyId
        agencyName
        agencyUUID
        role
        chatBotEnabled
        tgNotification
      }
      telegram{
        username
        contactNo
        contactCode
        contactVerified
        chatId
      }
      masterUser
    }
}
`;


export const ADD_DEVICES = gql`
 mutation add_device($new_device: [DeviceInput]){
  add_device(new_device: $new_device){
    uuid
    sn
    description
    SKU{
      uuid
      ID
    }
    agency{
      uuid
      id
    }
    board{
      assembly{
        uuid
        board_class
        assembly_id
        schematic_id
      }
      board_id
      batch_id
    }
    fuel_sensor{
      length
      width
      height
    }
    features
  }

 }
`;

export const UPDATE_DEVICES = gql`
 mutation update_device($updated_device: [DeviceInput]){
  update_device(updated_device: $updated_device){
    uuid
    sn
    description
		agency{
      uuid
      id
    }
    board{
      assembly{
        uuid
        assembly_id
        board_class
				schematic_id
      }
      board_id
      batch_id
      firmware{
        uuid
        board_class
      }
    }
    fuel_sensor{
      length
      height
      width
    }
    features
  }

 }
`;

export const DELETE_DEVICES = gql`
 mutation delete_device($devices: [String]){
  delete_device(devices: $devices){
    total
  }
 }
`;

export const SUSPENDED_AGENCIES = gql`
 mutation suspend_agency($agency: SuspendAgencyInput){
  suspend_agency(agency: $agency){
    uuid
    id
  }
 }
`;

export const GET_FIRMWARE = gql`
  query firmware($filter: FirmwareFilter) {
    get_firmwares(filter:$filter){
      uuid
      version
      release_date
      board_class
      git_SHA
      confluence_link
      device_count
      custom_fields
  }
  }
`;



export const UPDATE_SIGFOX_CONFIG = gql `
  mutation update_sigfox_config($input: SigfoxConfigInput){
    update_sigfox_config(input:$input){
      payloadInterval
      payloadIntervalMotion
      gpsSeekPeriod
      variableInterval
      downlinkFrequency
      revertDefault
      deviceId
    }
  }
`
export const DELETE_SIGFOX_CONFIG = gql `
  mutation delete_sigfox_config($deviceID: SigfoxConfigInput){
    delete_sigfox_config(deviceID:$input){
      total
    }
  }
`

export const UPDATE_FIRMWARE = gql`
mutation update_firmware($updated_firmware: [FirmwareInput]) {
  update_firmware(updated_firmware: $updated_firmware){
      uuid
  }
}
`;

export const UPDATE_CHANNEL = gql`
mutation update_channel($updated_channel: ChannelInput) {
  update_channel(updated_channel: $updated_channel){
      uuid
      name
  }
}
`;

export const ADD_FIRMWARE = gql`
  mutation add_firmware($new_firmware: [FirmwareInput]) {
    add_firmware(new_firmware: $new_firmware) {
      uuid
    }
  }
`;

export const ADD_CHANNEL = gql`
  mutation add_channel($new_channel: ChannelInput) {
    add_channel(new_channel: $new_channel) {
      name
    }
  }
`;

export const DELETE_FIRMWARE = gql`
mutation delete_firmware($firmware_uuid: [ID]) {
  delete_firmware(firmware_uuid: $firmware_uuid){
    total
  }
}
`;

export const DELETE_CHANNEL = gql`
mutation delete_channel($channel_uuid: [ID]) {
  delete_channel(channel_uuid: $channel_uuid){
    total
  }
}
`;


export const LOGIN = gql`
    mutation login($email: String, $password: String, $rememberMe: Boolean) {
        login(email: $email, password: $password, rememberMe: $rememberMe) {
          token
          user{
            uuid
            email
            firstName
            lastName
            privileges{
              agencyId
              role
              agencyName
              agencyUUID
              chatBotEnabled
              tgNotification
              isSuspended
            }
            telegram{
              username
              contactNo
              contactCode
              contactVerified
              chatId
            }
          }
        }
    }
`;

export const FIND_ACTIVE_ASSET = gql`
    query findActiveAsset($group_name: String){
       findActiveAsset(group_name: $group_name){
        totalAssets
        totalActiveAssets
        assets{
          uuid
          name
          pairId
          motionInference
          paired_assets{
            uuid
            name
            pairId
          }
        }
    }
    }
`; 

export const FIND_TOTAL_ACTIVE_ASSET = gql`
    query findActiveAsset($group_name: String){
       findActiveAsset(group_name: $group_name){
        totalAssets
        totalActiveAssets
    }
    }
`;

export const GET_PAYLOAD = gql`
query getPayloadInfo($pairId:ID){
  getPayloadInfo(pairId:$pairId)
}
`; 



export const GET_PAYLOAD_BY_GROUP = gql`
query getPayloadByGroup($group_uuid:ID){
  getPayloadByGroup(group_uuid:$group_uuid)
}
`; 

export const SEARCH_ASSET_N_GROUPS = gql`
    query searchAssetnGroups($search_key: String, $searchType: SearchType){
      searchAssetnGroups(search_key: $search_key, searchType:$searchType){
        asset{
          pairId
          uuid
          name
          type
          sequence
          manufacturer
          model
          description
          status
          paired_assets{
            pairId
            uuid
            name
            type
            manufacturer
            sequence
            model
            description
          }
        }
        group{
          uuid
          name
          description
        }
      }
    }
`; 




export const FETCH_ALL_PAYLOAD = gql`
query fetchAllPayload($pairId: ID){
    fetchAllPayload(pairId:$pairId)
}
`;


export const GET_VEHICLE_SPEED = gql`
query getVehicleSpeedRpt($input: ReportInput){
	getVehicleSpeedRpt(input:$input){
        maxSpeed
        totalAvgSpeed
        data{
          speed
          time
          latitude
          longitude
        }
    }
}
`
export const GET_ASSET_UTILISATION = gql`
query getAssetUtilisationRpt($input: ReportInput){
	getAssetUtilisationRpt(input:$input){
      total_Utilisation
      Utilisation_Duration
      asset_utilisation{
        time
        duration
        rate
      }
    }
}
`
export const GET_ALERT = gql`
query getAlerts($input: AlertInput){
	getAlerts(input:$input){
      time
      value
    }
}
`

export const GET_SEARCH_ALL_ASSET_GROUP = gql`
query searchAllAssetsnGroups($key: String, $selected:[String]){
  searchAllAssetsnGroups(key:$key, selected: $selected){
    asset{
      uuid
      name
      paired_assets{
        uuid
        name
      }
    }
    group{
      uuid
      name
    }
    selected{
      asset{
        uuid
        name
      }
      group{
        uuid
        name
        assets{
          uuid
          name
        }
      }
    }
  }
}
`

export const ADD_GEOFENCE = gql`
mutation add_geofence($new_geofence: GeofenceInput){
  add_geofence(new_geofence:$new_geofence){
    uuid
  }
}
`
export const SEARCH_POLICIES = gql`
query searchGeofences($key: String){
  searchGeofences(key:$key){
    uuid
    name
    notification{
      enter {
        uuid
        name
      }
      leave {
        uuid
        name
      }
      generate_pin {
        uuid
        name
      }
    }
    assets{
      uuid
      name
    }
    asset_groups{
      uuid
      name
    }
    polygons{
      uuid
      name
      area
    }
  }
}
`

export const GET_GEOFENCES = gql`
query getGeofence($geofence_uuid: String){
  getGeofence(geofence_uuid:$geofence_uuid){
    uuid
    name
    notification{
      enter{
        uuid
        name
        type
      }
      leave{
        uuid
        name
        type
      }
      generate_pin{
        uuid
        name
        type
      }
    }
    assets{
      pairId
      uuid
      name
    }
    asset_groups{
      uuid
      name
    }
    polygons{
      uuid
      name
      area
    }
  }
}
`

export const DELETE_POLICIES = gql`
  mutation delete_geofence($geofence_uuid: [ID]){
    delete_geofence(geofence_uuid:$geofence_uuid){
      total
  }
}
`

export const EDIT_GEOFENCE = gql`
  mutation update_geofence($updated_geofence: GeofenceInput){
    update_geofence(updated_geofence:$updated_geofence){
      uuid
    }
}
`

export const GET_FEATURE = gql`
  query get_features{
    get_features{
      uuid
      icon
      name
      description
    }
}
`
export const GET_REPORT_TELEMETRY = gql`
  query getTelemetryLogRpt($input: ReportInput){
    getTelemetryLogRpt(input: $input)
}
`
export const GET_REPORT_TRIP = gql`
  query getTripRpt($input: ReportInput){
    getTripRpt(input: $input){
      start_time
      start_latitude
      start_longitude
      end_time
      end_latitude
      end_longitude
      total_duration
      moving_duration
      idle_duration
      trip_status
      estimate_distance
      fuel_usage
    }
}
`

export const GET_FUEL_REPORT = gql`
  query getFuelLogRpt($input: ReportInput){
    getFuelLogRpt(input: $input){
      time
      fuelPercentage
      fuelVolume
      latitude
      longitude
    }
}
`

export const GET_GEOFENCE_REPORT = gql`
  query getGeofenceLogRpt($input: GeofenceLogInput){
    getGeofenceLogRpt(input: $input){
      time
      asset_name
      geofence_name
      geofence_area
      state
      transition
    }
}
`

export const GET_ALERT_REPORT = gql`
  query getAlertsRpt($input: AlertInput){
    getAlertsRpt(input: $input){
      time
      latitude
      longitude
      asset
      violation
    }
}
`

export const CHECK_SMARTLOCK_SYNC = gql `
  query check_smartlock_sync{
    check_smartlock_sync{
      add{
        uuid
        sn
        smartlockPin{
          pin
          startDate
          endDate
        }
      }
      remove{
        uuid
        sn
        smartlockPin{
          pin
          startDate
          endDate
        }
      }
    }
  }
`

export const SMARTLOCK_SYNC = gql `
  mutation smartlock_sync{
    smartlock_sync{
      add{
        sn
      }
      remove{
        sn
      }
    }
  }
`

export const SEARCH_USER = gql `
  query searchUsers($key: String, $channelType: [ChannelType]){
    searchUsers(key: $key, channelType: $channelType){
      uuid
      email
      firstName
      lastName
      telegram{
        username
        contactNo
        contactCode
        contactVerified
        chatId
      }
    }
  }
`

export const SEARCH_CHANNEL = gql `
  query search_channel($key: String, $type:[ChannelType]){
    search_channel(key: $key, type: $type){
      uuid
      name
      type
    }
  }
`

export const GET_ASSEMBLIES = gql `
  query get_assemblies($field: AssemblyInput){
    get_assemblies(field: $field){
      uuid
      board_class
      assembly_id
      schematic_id
    }
  }
`

export const SET_USER_PASSWORD = gql `
  mutation setUserPassword($email: String, $passwordId: String, $password: String, $firstName: String, $lastName: String, $operation: PasswordOperation){
    setUserPassword(email: $email, passwordId: $passwordId, password: $password, firstName: $firstName, lastName: $lastName, operation: $operation){
      message
      status
      success
    }
  }
`
export const CHECK_USER_VERIFIED = gql `
  query checkUserVerified($passwordId: String){
    checkUserVerified(passwordId: $passwordId)
  }
`

export const GET_DRIVER = gql `
  query getDrivers($filter: DriverFilter){
    getDrivers(filter: $filter){
      uuid
      firstName
      lastName
      driverId
    }
  }
`

export const ADD_DRIVER = gql`
mutation addDriver($newDriver: DriverInput){
  addDriver(newDriver:$newDriver){
    uuid
  }
}
`

export const UPDATE_DRIVER = gql`
mutation updateDriver($updatedDriver: DriverInput){
  updateDriver(updatedDriver:$updatedDriver){
    uuid
  }
}
`

export const DELETE_DRIVER = gql`
mutation deleteDrivers($driverId: [ID]){
  deleteDrivers(driverId:$driverId){
    total
  }
}
`

export const REBOOT_DEVICE = gql `
  mutation rebootDevice($deviceId: ID){
    rebootDevice(deviceId:$deviceId){
      deviceId
      payloadInterval
      payloadIntervalIgnitionOff
      payloadIntervalOffline
      telitKeepAlive
      ignitionMode
      extSense_Fuel
      FuelSensingRatio
      extSense_Vibration
      rat
      blocked
      lastHeartbeatTime
      lastEventTime
      lastStateTime
      lastConfigAckTime
      lastConfigSendTime
      lastErrorTime
      lastErrorStatus
    }
  }
`
export const SET_BLOCK_DEVICE = gql `
  mutation setBlockDevice($deviceId: ID, $block:Boolean){
    setBlockDevice(deviceId:$deviceId, block:$block){
      deviceId
      payloadInterval
      payloadIntervalIgnitionOff
      payloadIntervalOffline
      telitKeepAlive
      ignitionMode
      extSense_Fuel
      FuelSensingRatio
      extSense_Vibration
      rat
      blocked
      lastHeartbeatTime
      lastEventTime
      lastStateTime
      lastConfigAckTime
      lastConfigSendTime
      lastErrorTime
      lastErrorStatus
    }
  }
`
export const CELLULAR_CONFIG = gql `
  mutation setCellularConfig($deviceId: ID, $input:CellularInput){
    setCellularConfig(deviceId:$deviceId, input:$input){
      deviceId
      payloadInterval
      payloadIntervalIgnitionOff
      payloadIntervalOffline
      telitKeepAlive
      ignitionMode
      extSense_Fuel
      FuelSensingRatio
      extSense_Vibration
      rat
      blocked
      lastHeartbeatTime
      lastEventTime
      lastStateTime
      lastConfigAckTime
      lastConfigSendTime
      lastErrorTime
      lastErrorStatus
    }
  }
`

export const REGENERATE_CELLULAR_KEY = gql `
  mutation regenerateCellularConfigKey($deviceId: ID){
    regenerateCellularConfigKey(deviceId:$deviceId){
      deviceId
      privateKey
    }
  }
`