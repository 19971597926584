import React,{useState, useEffect} from 'react'

import Form from './Form'
import {  AllMandatoryFilled, onUserAdd  } from "../../config/config-function/function";
import {requiredField} from '../../config/config-table/table'
import { Base64 } from 'js-base64';
import Cookies from "js-cookie";

import { USER_REDUCER } from "../../config/config-reducer/reducer";


const FunctionAdd = ({values, onChange, setAddVariables, setGetVariables, setCanAdd, canAdd, displayed, setDisplayed, setCanUpdate, canUpdate}) => {
    const agencyID = Cookies.get("agency-id")
    const agencyUUID = Cookies.get("agencyUUID")
    const [privileges, setPrivileges] = useState({agencyUUID:agencyUUID, agencyID: agencyID, role: "", chatBotEnabled: true})
    const [telegram, setTelegram] = useState({})
    const [password, setPassword] = useState("")

    const [contactCode, setContactCode] = useState("")
    const [contact_number, setContact_number] = useState("")
    const onUserInput = (e, value, isValue) => {

        let input = onUserAdd(e, value, isValue, onChange, values)

        if(input.privileges && input.privileges.role === "Notifications"){
            const _check_normal = AllMandatoryFilled(requiredField.userNotification, input)
            _check_normal ? setCanAdd(true) : setCanAdd(false)
        }else{
            const _check_normal = AllMandatoryFilled(requiredField.user, input)
            _check_normal ? setCanAdd(true) : setCanAdd(false)
        }
        
        setAddVariables({input: input === null ? USER_REDUCER(values) : USER_REDUCER(input)})
    }

    useEffect(() => {
        onUserInput(null, { telegram: {username: telegram.username, contactNo: telegram.contactNo, contactCode: contactCode} }, true)
          //---this is to disable the warning for useEffect----//
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [telegram])

    useEffect(() => {
        onUserInput(null, { privileges: privileges }, true)
          //---this is to disable the warning for useEffect----//
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [privileges])

    useEffect(() => {
        onUserInput(null, { password: Base64.encode(password) }, true)
          //---this is to disable the warning for useEffect----//
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password])

    return (
        <Form users={values} onChange={onChange} onUserInput={onUserInput} displayed={displayed} setPrivileges={setPrivileges} setTelegram={setTelegram} privileges={privileges} telegram={telegram} setPassword={setPassword} password={password} setContactCode={setContactCode} contact_number={contact_number}/>
    )
}
export default FunctionAdd
