import React, {useState} from 'react'

//image
import PairedIcon from "../../Image/pair_green.svg"
import ChevronLightIcon from "../../Image/chevron_light.svg"
import UnpairGreenIcon from "../../Image/unpair_green.svg"


const FormPairAsset = ({asset, fx_OpenModal, isPaired, details, featureDisable}) => {
const [isHovered, setIsHovered] = useState({
    paired: false
})

// console.log(asset)

    let paired_name = "";
    if(isPaired){
        if(Array.isArray(asset)){
            paired_name =  asset[0].paired_assets[0] ? asset[0].paired_assets[0].name : paired_name;

        } else{
            paired_name = asset.paired_assets[0] ? asset.paired_assets[0].name : paired_name;
        }
    }  
    return (
        <div className="asset-paired-asset">
            <div className="asset-paired-asset-input">
                <div className="asset-paired-asset-details">
                    <div className="paired-asset-title">Paired Asset</div>
                    <div className="paired-asset-detail">{details}</div>
                </div>
                <div className="details-input">
                    <div className="input-input asset-text">Asset</div>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div>{paired_name} </div>
                        {featureDisable ? null :
                        <div className="pair-asset-icon" style={{cursor:"pointer"}} onClick={() => fx_OpenModal()}>
                            <img src={isPaired ? PairedIcon : UnpairGreenIcon } alt="pairing" style={{height: "16px", width: "16px"}}/>
                            <div className="pair-asset-icon-text">{isPaired ? "Unpair Asset" : "Pair Asset" }</div>
                        </div>  
                        }                        
                    </div>
                </div>
            </div>
            <div className="onhover-container">
                <div className={`${isHovered.paired ? "isHovered-btn-edits": "button-edits"}`} onMouseEnter={() => setIsHovered((current) => ({...current, paired: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, paired: false}))}>
                        <img loading="lazy" src={ChevronLightIcon} alt="test" />
                </div>
            </div>
        </div>
    )
}

export default FormPairAsset
