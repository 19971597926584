import React, {useState} from 'react'
import LightSaveIcon from '../../Image/light_save.svg'
import GreySaveIcon from '../../Image/grey_save.svg'

const SaveCard = ({onSubmit, canAdd}) =>{
    const [isHovered, setIsHovered] = useState({
        btnSave: false
      })
    return (
        <div className={!canAdd ? "add-button-disabled" : `${isHovered.btnSave ? "isHovered-add-button": "add-button"}`} style={{margin: "0px 0px 0px 24px"}}  onClick={(e)=> canAdd && onSubmit(e)} onMouseEnter={() => setIsHovered((current) => ({...current, btnSave: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnSave: false}))}>
            {canAdd ? <img loading="lazy" src={LightSaveIcon} alt="light"/> : <img loading="lazy" src={GreySaveIcon} alt="grey"/>}
            <div style={{margin: "0px 0px 0px 14px"}}>Save</div>
            <style>
                {
                    `.add-button{
                        width: 213px; 
                        height: 34px;
                        color: #fff;
                        background: #509D91;
                        // background-repeat: no-repeat;
                        // background-image: url(${LightSaveIcon});
                        // background-position: 54px;
                        font-size: 14px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-transform: capitalize;
                        cursor: pointer;
                        outline: none;
                      }
                      .isHovered-add-button{
                        width: 213px; 
                        height: 34px;
                        color: #fff;
                        background: #498f84;
                        // background-repeat: no-repeat;
                        // background-image: url(${LightSaveIcon});
                        // background-position: 54px;
                        font-size: 14px;
                        display: flex;
                        font-weight: 600;
                        justify-content: center;
                        align-items: center;
                        text-transform: capitalize;
                        cursor: pointer;
                        outline: none;
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2); 
                      }
                      .add-button-disabled{
                        width: 213px; 
                        height: 34px;
                        color: #939598;
                        background: #DDDDDD;
                        // background-repeat: no-repeat;
                        // background-image: url(${GreySaveIcon});
                        // background-position: 54px;
                        font-size: 14px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-transform: capitalize;
                        cursor: pointer;
                        outline: none;
                        opacity: 0.7;
                      }
                    `
                }
            </style>
        </div>
    )
}

export default SaveCard
