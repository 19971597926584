//import from react library
import React, {useEffect} from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
// import {ErrorBoundary} from 'react-error-boundary'

//import component
import LoginPage from './pages/page-login/LoginPage'
import DashboardPage from './pages/page-dashboard/DashboardPage'
import AdminPage from './pages/page-admin/AdminPage'
// import ErrorComponent from './component/component-errorboundary/ErrorComponent'
import Cookies from "js-cookie";
import{FirebaseProvider} from './config/config-firebase/FirebaseContext'
import {AuthProvider} from './config/config-auth/Auth'
import {WebsocketProvider} from './config/config-websocket/Websocket'
import ResetPassword from './pages/page-reset-password/ResetPassword'
import TermCondition from './pages/page-terms-condition/TermCondition'
import Policy from './pages/page-policy/Policy'
import ForgotPassword from './pages/page-forgot-password/ForgotPassword'
import NewPassword from './pages/page-new-password/NewPassword'
import AgencySelector from './pages/page-agency-selector/AgencySelector'


import "@pathofdev/react-tag-input/build/index.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import 'mapbox-gl/dist/mapbox-gl.css';
import {history} from './config/config-history-routing/history'

function App() {
  
  return (
    <FirebaseProvider>
      <AuthProvider>
        <Router history={history}>
          {/* <Switch> */}
            <Route exact path="/"><Redirect to="/login" /></Route>
            <Route path="/login" component={LoginPage}></Route>
            <Route path="/reset/:id" component={(props) => (<ResetPassword props={props}/>)} />
            <Route path="/account_setup/:id" component={(props) => (<NewPassword props={props}/>) } />
            <Route path="/tenancy" component={AgencySelector}/>
            <Route path="/forgot_password" component={ForgotPassword} />
            <Route path="/terms_of_service" component={TermCondition}></Route>
            <Route path="/privacy_policy" component={Policy}></Route>
            <WebsocketProvider>
              <Route path="/dashboard" component={DashboardPage} />
            </WebsocketProvider>
            <Route path="/console" component={AdminPage}></Route>
          {/* </Switch> */}
        </Router>
      </AuthProvider>
    </FirebaseProvider>
  );
}

export default App;
