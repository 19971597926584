import React, { useState, useContext,useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks';
import { useMediaQuery } from 'react-responsive';
import Cookies from 'js-cookie';

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import { createMuiTheme, makeStyles } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import { WebsocketContext } from "../../config/config-websocket/Websocket";
import { SEARCH_ASSET_N_GROUPS } from  '../../config/config-graphql/Graphql'
// import { APIGetSearchAssetnAssetGroup } from '../../config/config-restAPI/getAPICalled';
import { APIGetSearchAssetnAssetGroup } from '../../config/config-restAPI/getAPICalled';

import Search from "../../Image/search_2.svg"
import GreySearchIcon from '../../Image/grey_search.svg'
import AssetGroupIcon from "../../Image/asset_groups.svg";
import AssetIcon from "../../Image/asset.svg";

import ListCard from "./ListCard";

const autoComplete = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            clearIndicator: {
                // position: "absolute",
                // bottom: "5px",
                // right: "25px"
                // height: '67px',
                "&:hover":{
                    backgroundColor: "#FFFFFF",
                }
            },
            endAdornment:{
                top: "0px",
                height: "100%",
                display: "flex",
                alignItems: "center"
            },
            popupIndicator:{
                "&:hover":{
                    backgroundColor: "#FFFFFF",
                },
            }
        
        },
        MuiIconButton:{
            label:{
                height:"24px",
                width: "24px",
                "&:hover":{
                    backgroundColor: "rgba(0,0,0,0.03)",
                    borderRadius: "5px",
                },
            }
        }
    }
});

const autoCompleteMobile = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            clearIndicator: {
                // position: "absolute",
                // bottom: "5px",
                // right: "25px"
                // height: '67px',
                "&:hover":{
                    backgroundColor: "#FFFFFF",
                }
            },
            endAdornment:{
                top: "0px",
                height: "100%",
                display: "flex",
                alignItems: "center"
            },
            popupIndicator:{
                "&:hover":{
                    backgroundColor: "#FFFFFF",
                },
            }
        
        },
        MuiIconButton:{
            label:{
                height:"24px",
                width: "24px",
                "&:hover":{
                    backgroundColor: "rgba(0,0,0,0.03)",
                    borderRadius: "5px",
                },
            }
        }
    }
});

const useStyles = makeStyles((theme) => ({
    // textField: {
    //     backgroundColor: "#FFFFFF",
    //     // width: ,
    //     marginLeft: 0,
    //     outline: "none"
    // },
    // autocomplete: {
    //     backgroundColor: "#FFFFFF",
    //     // width: 276,
    //     underline: { "&&&:before": { borderBottom: "none" }, "&&:after": { borderBottom: "none" } }
    // }
}))



const MapSearch = ({viewGroup, setViewGroup, searchVariables, searchValue, setSearchValue , setSearchVariables, setGroupName, setAssetSelection, assetSelection}) => {
    const isDefault = useMediaQuery({ minWidth: 1025 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    const payloadContext = useContext(WebsocketContext);    
    const { openAssetGroupCard, selectAsset, closeAssetCard  } = payloadContext;
    const [loading, setLoading] = useState(false);
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');
    const [assetnGroupValue, setAssetnGroupValue] = useState([])
    // console.log(payloadContext);
    const classes = useStyles();
    // console.log(searchValue);
    const [viewSearchResult, setViewSearchResult] = useState(false)
    const [assetFlat, setAssetFlat] = useState([]); // list search for desktop (searchAssetNGroup)
    const [assetListSnip, setAssetListSnip] = useState([]); // list search for mobile (searchAssetNGroup)

    const [viewLive, setviewLive] = useState(true) //using this state for display list asset and group

    const APICalled = async (searchType, searchKey) => {
        try {
            const result = await APIGetSearchAssetnAssetGroup(token, setLoading, agencyId, searchType, searchKey, false)
            if (result.data.status === 200) {
                const assetList = result.data.data.assets.length > 0 ? result.data.data.assets.map((x) => {
                    return x && x.name ? {
                        firstLetter: x.name.charAt(0).toUpperCase(),
                        pairId: x.pairId,
                        uuid: x.uuid,
                        name: x.name,
                        paired_assets: x && x.pairedAssets.length > 0 ? x.pairedAssets.map((x) => { return { uuid: x.uuid, name: x.name }}) : [],
                        type: 'asset',
                    } : { firstLetter: "", uuid: "", name: "", pairId: "", paired_assets: [], type: "asset" }
                }) : []
                const assetGroupList = result.data.data.groups.length > 0 ? result.data.data.groups.map((x) => {
                    return x && x.name ? {
                        firstLetter: x.name.charAt(0).toUpperCase(), 
                        uuid: x.uuid, 
                        pairId: x.uuid, 
                        name: x.name,
                        type: 'group',
                        paired_asset: [] } : {
                            firstLetter: "", uuid: "", name: "", description: "", type: "group",paired_assets: []
                        }
                    }) : []
                const searchOptions = {
                    options: assetList.length > 0 || assetGroupList.length > 0 ? assetList.concat(assetGroupList) : []
                }

                const flatAssetIds = searchOptions.options.flat(2)
                setAssetFlat(flatAssetIds);
                setAssetListSnip(assetList)
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch(err) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(token){
            APICalled("ALL", '')
        }
    }, [])
    
    //onUserInput from selected asset
    const onUserInput = (e, value) => {
        if (value) {
            if(value.type === "asset"){
                selectAsset({ pairId: value.pairId,  getAssetInfo: false }, {asset_id: value.uuid, asset_name: value.name, pairId: value.pairId})
                setSearchValue({ pairId: value.pairId, name: value.name, uuid: value.uuid, paired_asset: value.paired_asset })
            }else{
                openAssetGroupCard(true, {name: value.name})
                setSearchValue({ pairId: value.pairId, name: value.name, uuid: value.uuid, paired_asset: value.paired_asset })
            }
        } else {
            setSearchValue({})
        }
    }

    const handleChangeTextfield = (e) => {
        setSearchValue({ ...searchValue , name: e.target.value })
    }

    //clear indicator (close button)
    const clearIndicatorMUI = () => {
        openAssetGroupCard(false, {})
        closeAssetCard();
        setSearchValue({})
    }

    const CustomerPopper = (props) => {
        const modifiers = [
          {
            name: "flip",
            options: {
              fallbackPlacements: []
            }
          }
        ];
    
        return (
          <Popper
            {...props}
            modifiers={modifiers}
            popperOptions={{
              placement: "top"
            }}
          />
        );
      };
    
    return (
        <div>
            {
                isDefault && (
                    <div className="search-container-search-map">
                        <div className="live-update-title">Live Updates</div>
                        <ThemeProvider theme={autoComplete}>
                            <Autocomplete
                                {...assetFlat}
                                name="asset_name"
                                id="combo-box-demo"
                                loading={true}
                                autoHighlight={true}
                                onChange={(e, value) => onUserInput(e, value)}
                                options={assetFlat.length > 0 ? assetFlat.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
                                groupBy={(option) => option.firstLetter}
                                getOptionLabel={(option) => option.name}
                                value={searchValue.name}
                                className={classes.autocomplete}
                                onInputChange={(event, newInputValue, reason) => {
                                    if(reason === "clear"){
                                        clearIndicatorMUI()
                                    }
                                }}
                                renderOption={option => {
                                    return <div className="autocomplete-container">
                                        {
                                            option.type === "asset" ? <img loading="lazy" style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} src={AssetIcon} alt="asset-icon" /> : option.type === "group" ? <img loading="lazy" style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} src={AssetGroupIcon} alt="asset-icon" /> : null
                                        }
                                        <div className="autocomplete-name">{option.name}</div>
                                        { option && option.paired_assets && option.paired_assets.length > 0 ? 
                                        option.name !== option.paired_assets[0].name ? <div className="autocomplete-pair-name"> <div className="paired-symbol">↹</div> {option.paired_assets[0].name}</div> : null : null}
                                    </div>
                                }}
                                renderInput={(params) =>                        
                                    <div style={{position: "relative"}} ref={params.InputProps.ref}>
                                        {
                                            params.inputProps.value ? (
                                                <span 
                                                    style={{
                                                        position: "absolute",
                                                        transform: "translate(1%, 55%)",
                                                        marginLeft: "5px"
                                                    }}
                                                >
                                                    <img loading="lazy" style={{ height: "20px", width: "20px" }} src={AssetGroupIcon} alt="asset-icon" />
                                                </span>
                                            ) : (
                                                <span 
                                                    style={{
                                                        position: "absolute",
                                                        transform: "translate(1%, 55%)",
                                                        marginLeft: "5px"
                                                    }}
                                                    >
                                                <img loading="lazy" style={{ height: "20px", width: "20px" }} src={GreySearchIcon} alt="grey-icon" />
                                            </span>
                                            )
                                        }
                                        <TextField 
                                            {...params} 
                                            name="asset_name" 
                                            placeholder="Search" 
                                            variant="outlined"
                                            value={searchValue.name}
                                            onChange={(e) => handleChangeTextfield(e)}
                                            inputProps={{
                                                ...params.inputProps,
                                                style: { paddingLeft: "27px", position: "relative", top:"1px" } 
                                            }}
                                        />
                                    </div>
                                    
                                }
                            />
                        </ThemeProvider>
                        <div className="list-container">
                            { viewSearchResult && <ListCard viewGroup={viewGroup} setViewGroup={setViewGroup} viewSearchResult={viewSearchResult} setViewSearchResult={setViewSearchResult} searchVariables={searchVariables} setviewLive={setviewLive} viewLive={viewLive} setGroupName={setGroupName}/>}
                        </div>
                    </div>
                )
            }

            {
                isMobile && (
                <div className="search-container-search-map">
                    {/* <div className="live-update-title-mobile">Live Updates</div> */}
                    <ThemeProvider theme={autoCompleteMobile}>
                        <Autocomplete
                            // {...flatAssetIds}
                            name="asset_name"
                            id="combo-box-demo"
                            loading={true}
                            autoHighlight={true}
                            onChange={(e, value) => onUserInput(e, value)}
                            options={assetListSnip.length > 0 && assetListSnip.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.name}
                            value={searchValue.name}
                            className={classes.autocomplete}
                            onInputChange={(event, newInputValue, reason) => {
                                if(reason === "clear"){
                                    clearIndicatorMUI()
                                }
                            }}
                            PopperComponent={({ style, ...props }) => (
                                <Popper
                                  {...props}
                                  style={{ ...style, position: 'fixed', top: 0 }} // width is passed in 'style' prop
                                //   placement="bottom"
                                />
                              )}
                            renderOption={option => {
                                return <div className="autocomplete-container">
                                    <img loading="lazy" style={{ height: "15px", width: "15px", margin: "0px 4px 0px 0px" }} src={AssetIcon} alt="asset-icon" />      
                                    <div className="autocomplete-name-mobile">{option.name}</div>
                                    { option.paired_assets.length > 0 ? 
                                    option.name !== option.paired_assets[0].name ? <div className="autocomplete-pair-name-mobile"> <div className="paired-symbol">↹</div> {option.paired_assets[0].name}</div> : null : null}
                                </div>
                            }}
                            renderInput={(params) =>   
                                // console.log(params)                     
                                <div style={{position: "relative"}} ref={params.InputProps.ref}>
                                    {
                                        Object.keys(searchValue).length > 0 ? (
                                            <span 
                                                style={{
                                                    position: "absolute",
                                                    transform: "translate(1%, 65%)",
                                                    marginLeft: "10px"
                                                }}
                                            >
                                                <img loading="lazy" style={{ height: "15px", width: "15px" }} src={AssetIcon} alt="asset-icon" />           
                                            </span>
                                        ) : (
                                            <span 
                                                style={{
                                                    position: "absolute",
                                                    transform: "translate(1%, 65%)",
                                                    marginLeft: "5px"
                                                }}
                                                >
                                            <img loading="lazy" style={{ height: "15px", width: "15px" }} src={GreySearchIcon} alt="grey-icon" />
                                        </span>
                                        )
                                    }
                                    <TextField 
                                        {...params} 
                                        name="asset_name" 
                                        placeholder="Search" 
                                        variant="outlined"
                                        value={searchValue.name}
                                        onChange={(e) => handleChangeTextfield(e)}
                                        inputProps={{
                                            ...params.inputProps,
                                            style: { paddingLeft: "27px", position: "relative", top:"1px" },
                                            value: Object.keys(searchValue).length > 0 ? searchValue.name : '',
                                        }}
                                    />
                                </div>
                                
                            }
                        />
                    </ThemeProvider>
                </div>
                )
            }
            {
                isTablet && (
                <div className="search-container-search-map">
                    {/* <div className="live-update-title-mobile">Live Updates</div> */}
                    <ThemeProvider theme={autoCompleteMobile}>
                        <Autocomplete
                            // {...flatAssetIds}
                            name="asset_name"
                            id="combo-box-demo"
                            loading={true}
                            autoHighlight={true}
                            onChange={(e, value) => onUserInput(e, value)}
                            options={assetListSnip.length > 0 && assetListSnip.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                            groupBy={(option) => option.firstLetter}
                            getOptionLabel={(option) => option.name}
                            value={searchValue.name}
                            className={classes.autocomplete}
                            onInputChange={(event, newInputValue, reason) => {
                                if(reason === "clear"){
                                    clearIndicatorMUI()
                                }
                            }}
                            renderOption={option => {
                                return <div className="autocomplete-container">
                                    <img loading="lazy" style={{ height: "15px", width: "15px", margin: "0px 4px 0px 0px" }} src={AssetIcon} alt="asset-icon" />      
                                    <div className="autocomplete-name-mobile">{option.name}</div>
                                    { option.paired_assets.length > 0 ? 
                                    option.name !== option.paired_assets[0].name ? <div className="autocomplete-pair-name-mobile"> <div className="paired-symbol">↹</div> {option.paired_assets[0].name}</div> : null : null}
                                </div>
                            }}
                            renderInput={(params) =>   
                                // console.log(params)                     
                                <div style={{position: "relative"}} ref={params.InputProps.ref}>
                                    {
                                        Object.keys(searchValue).length > 0 ? (
                                            <span 
                                                style={{
                                                    position: "absolute",
                                                    transform: "translate(1%, 65%)",
                                                    marginLeft: "10px"
                                                }}
                                            >
                                                <img loading="lazy" style={{ height: "15px", width: "15px" }} src={AssetIcon} alt="asset-icon" />           
                                            </span>
                                        ) : (
                                            <span 
                                                style={{
                                                    position: "absolute",
                                                    transform: "translate(1%, 65%)",
                                                    marginLeft: "5px"
                                                }}
                                                >
                                            <img loading="lazy" style={{ height: "15px", width: "15px" }} src={GreySearchIcon} alt="grey-icon" />
                                        </span>
                                        )
                                    }
                                    <TextField 
                                        {...params} 
                                        name="asset_name" 
                                        placeholder="Search" 
                                        variant="outlined"
                                        value={searchValue.name}
                                        onChange={(e) => handleChangeTextfield(e)}
                                        inputProps={{
                                            ...params.inputProps,
                                            style: { paddingLeft: "27px", position: "relative", top:"1px" },
                                            value: Object.keys(searchValue).length > 0 ? searchValue.name : '',
                                        }}
                                    />
                                </div>
                                
                            }
                        />
                    </ThemeProvider>
                </div>
                )
            }
            <style>
                {
                    `
                    .search-container-search-map{
                        z-index: 9;
                    }
                    .geo-map-search-live{
                        width: 100%;
                        box-shadow: 0px 1px 3px 0px #00000020;
                        border: 0px solid #ffffff;
                        border-radius: 4px;
                        font-size: 14px;
                        background-color: white;
                        background-image: url(${Search});
                        background-position: 8px 8px;
                        background-repeat: no-repeat;
                        padding: 14px 40px 14px 40px;
                        -webkit-transition: width 0.4s ease-in-out;
                        transition: width 0.4s ease-in-out; 
                        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                        box-sizing: border-box;

                      }
                      .MuiInputBase-input {
                        height: 3px;
                        font-wight: 400;
                      }
                      .list-container{
                        position: fixed;
                        // top: 116px;
                        // margin-top: 14px;
                        z-index: 7;
                      }
                      .autocomplete-container{
                        display: flex;
                        align-items: center;
                    }
                    .autocomplete-name{
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 150px;
                        margin: 0px 8px 0px 0px;
                    }
                    .autocomplete-name-mobile{
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 150px;
                        font-size: 12px;
                        margin: 0px 8px 0px 0px;
                    }
                    .autocomplete-pair-name{
                        display: flex;
                        align-items: center;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 200px;
                    }
                    .autocomplete-pair-name-mobile{
                        display: flex;
                        align-items: center;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        font-size: 12px;
                        width: 200px;
                    }
                    .live-update-title{
                        margin: 0px;
                        font-size: 14px;
                        line-height: 16px;
                        color: rgb(147, 149, 152);
                        margin: 0px 0px 8px 0px;
                    }
                    .live-update-title-mobile{
                        margin: 0px;
                        font-size: 12px;
                        line-height: 16px;
                        color: rgb(147, 149, 152);
                        margin: 0px 0px 4px 0px;
                    }
                    .paired-symbol{
                        color: #F5A623;
                        font-size: 14px;
                        margin: 0px 8px 0px 0px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default MapSearch
