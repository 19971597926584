import React from 'react'
import moment from "moment";

const Billing = ({description, displayed, agencies, fieldList, settingFieldValue, isBulkEdit, settingBiling, setIsHovered, isHovered}) => {
  // console.log(agencies)

    return (
    <div className="biling-container">
        <div className="box-info">
          <div className="title">Billing</div>
          <div className="title-detail">{description}</div>
          <div className="assembly-container">
            <div className="details-assembly" onMouseEnter={() => setIsHovered((current) => ({...current, billing_from: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, billing_from: false}))}>
              <div className="input-input">From</div>
              <input type="date" className={`${isHovered.billing_from ? "isHovered-date" : "text-box-date"}`} placeholder="-" name="subscriptionFrom" value={ displayed ? moment(agencies[0].billing.subscriptionFrom).format("YYYY-MM-DD"): (settingFieldValue(isBulkEdit, agencies, new Date(fieldList.subscription_from.name)))} data-date-format="DD-MM-YYYY" onChange={(e) => settingBiling(e.target.name, e.target.value)} />
            </div>
            <div className="details-assembly" onMouseEnter={() => setIsHovered((current) => ({...current, billing_to: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, billing_to: false}))}>
              <div className="input-input">To</div>
              <input type="date" className={`${isHovered.billing_to ? "isHovered-date" : "text-box-date"}`} placeholder="-" name="subscriptionTo" value={displayed ? moment(agencies[0].billing.subscriptionTo).format("YYYY-MM-DD") : (settingFieldValue(isBulkEdit, agencies, new Date(fieldList.subscription_to.name)))} data-date-format="DD-MM-YYYY" onChange={(e) => settingBiling(e.target.name, e.target.value)} />
            </div>
          </div>
        </div>
    </div>
    )
}

export default Billing
