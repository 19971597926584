import React, {useState, useEffect, useRef} from 'react'
import Search from '../../Image/search.svg'

import UserSearchList from './UserSearchList'

const UserSearch = ({data, userListing, setUserListing, dataTwo, types, APIGetUserCalled, loading, setFilterSearch, setKeySearch, setIsHovered, isHovered}) => {
    const [viewSearchResult, setViewSearchResult] = useState(false)
    const [searchInput, setSearchInput] = useState("")

    const onEnterKey = (event) => {
        if(event.key === "Enter"){
            // setFilterSearch(event.target.value)
            setKeySearch(event.target.value)
            setViewSearchResult(true)
            setSearchInput("")
            APIGetUserCalled(types, event.target.value, 'SEARCH')        
        }
    }

    const wrapperRef = useRef(null);

    const handleClick = (e) => {
        if (wrapperRef.current.contains(e.target)) {
            // inside click
            return;
          }
          // outside click
          setViewSearchResult(false);
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);            
        return () => {
            document.removeEventListener("mousedown", handleClick);
        }
    }, [])

    return (
        <div ref={wrapperRef} style={{width: "100%", position:"relative"}}>
            <input type="text" autocomplete="off" name="addAsset" placeholder="Search" className={`${isHovered.search ? "isHovered-search": "channel-add"}`} onKeyDown={onEnterKey} onChange={(e) => setSearchInput(e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, search: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, search: false}))}/>
            {
                viewSearchResult && <UserSearchList data={data} dataTwo={dataTwo} types={types} loading={loading} viewSearchResult={viewSearchResult} setViewSearchResult={setViewSearchResult} setUserListing={setUserListing} userListing={userListing}/>
            }
            <style>
                {
                    ` .channel-add{
                        position: relative;
                        width: 331px;
                        height: 40px;
                        border: 1px solid #939598;
                        border-radius: 4px;
                        font-size: 14px;
                        background-color: white;
                        background-image: url(${Search});
                        background-position: 8px 8px; 
                        background-repeat: no-repeat;
                        padding: 0px 0px 0px 36px;
                        margin: 8px 0px 0px 0px;
                        outline: none;
                        -webkit-transition: width 0.4s ease-in-out;
                        transition: width 0.4s ease-in-out;
                        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                        box-sizing: border-box;         /* Opera/IE 8+ */    
                    }
                    .isHovered-search{
                        position: relative;
                        background-color: rgba(182, 186, 191, 0.2);
                        width: 331px;
                        height: 40px;
                        border: 1px solid #939598;
                        border-radius: 4px;
                        font-size: 14px;
                        background-image: url(${Search});
                        background-position: 8px 5px; 
                        background-repeat: no-repeat;
                        padding: 0px 0px 0px 36px;
                        margin: 8px 0px 0px 0px;
                        outline: none;
                        -webkit-transition: width 0.4s ease-in-out;
                        transition: width 0.4s ease-in-out;
                        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                        box-sizing: border-box;         /* Opera/IE 8+ */  
                    }
                    `
                }
            </style>
        </div>
      
    )
}

export default UserSearch
