import React, { useState, useRef, useEffect} from 'react'

//library
import { useQuery } from "@apollo/react-hooks";

//material ui
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//image
import DropdownIcon from "../../Image/dropdown.svg";

//this is for pagination 
// import LeftButton from "../../Image/chevron_left.svg"
// import RightButton from "../../Image/chevron_right.svg"


import DarkNavigationDown from "../../Image/dark_navigation_down.svg"
import SearchIcon from "../../Image/search.svg"
import LightCloseIcon from '../../Image/light_close_icon.svg'
import AddIcon from "../../Image/dark_add.svg";
import DisabledIcon from "../../Image/grey_add_plus.svg"
 
//this is for black add and delete icon for bulk edit / delete
// import EditIcon from "../../Image/dark_edit.svg";
// import DeleteIcon from "../../Image/dark_delete.svg";
import GreyEditIcon from '../../Image/grey_edit.svg'
import GreyDeleteIcon from '../../Image/grey_delete.svg'

//component
import AddForm from "./AddForm"
import EditForm from "./EditForm"
import ListCard from "./ListCard";
import BulletChart from "../../component/component-bulletchart/BulletChart";
import FilterBy from "../../component/component-filterby/FilterBy"
import { GET_ASSET_TYPE_STATS } from "../../config/config-graphql/Graphql";
import SnackBar from '../../config/config-snackbar/SnackBar'


const useStyles = makeStyles((theme) => ({ img: { width: 24, height: "auto", }, }));
const Overview = ({ title, data, featureList, fx_changePage, edit, setEdit, setEditValue, editValue, setPageInd, pageInd, setLiveFilter, setFilterFinal, filterFinal = {}, filterList, preFilter = {}, fx_PreFilter, EnterFilter, setPreFilter, requiredFiled, setCanUpdate, canUpdate, history, featureDisable }) => {
    const { data: chartData, loading: chartLoading} = useQuery(GET_ASSET_TYPE_STATS, { variables: {} });
    const chartColorList = ["#546A7B", "#0D1F2D", "#9EA3B0", '#E4C3AD', "#939598", "#CADF9E", "#ABB557", "#F46036", "#FAE1DF", "#D3FCD5", "#17BEBB","#912F56", "#C4AF9A" ]

    // !chartLoading && console.log(chartData && chartData.get_AssetType_Stat && chartData.get_AssetType_Stat.map(x => { return { name: x.type ? x.type : "Others", total: x.total } }).sort(x => {return }))
    // console.log(chartData)

    const classes = useStyles();
    const [collapsedAsset, setCollapsedAsset] = useState("")
    const [add, setAdd] = useState(false);
    const [filter, setFilter] = useState(false)
    const [filterValue, setFilterValue] = useState(Object.keys(filterFinal).length > 0 ? filterFinal : preFilter)
    const [hoveredO, setIsHoveredO] = useState({
        add: false,
        filter: false
    })
    // const [assetType, setAssetType] = useState([])

    //this snOpen, message and severity is for snackbar props
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")
    const assetType = !chartLoading && chartData && chartData.get_AssetType_Stat && chartData.get_AssetType_Stat.length > 0 ? chartData.get_AssetType_Stat.sort((a,b) => a.total - b.total) : []

    const wrapperRef = useRef(null);
    
    //filter tags
    const filterDesign = () => {
        if (Object.keys(filterFinal).length > 0) {
            return "filter-tag"
        } else {
            return "filter-no-tag"
        }
    }

    //display filter count
    const filterCount = () => {
        if (filterFinal) {
            if (Object.keys(filterFinal).length > 0) {
                return <div style={{ display: "flex", alignItems: "center" }}>
                    {`${Object.keys(filterFinal).length} Filter`}
                    <img loading="lazy" src={LightCloseIcon} alt="close-icon" style={{ cursor: "pointer", height: "10px", width: "10px", margin: "0px 0px 0px 10px" }} onClick={(e) => onTagRemoveFilter(e)} />
                </div>

            }
            else {
                return null
            }
        } else {
            return null
        }
    }

    const onTagRemoveFilter = (e) => {
        setFilterValue({})
        setFilterFinal({})
        setPreFilter({})
    }
    return add ? (<AddForm title={title} setIsHoveredO={setIsHoveredO} fx_back={() => setAdd(!add)} featureList={featureList} requiredFiled={requiredFiled} setMandatoryfield={setCanUpdate} mandatoryField={canUpdate} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} setPageInd={setPageInd} setFilterFinal={setFilterFinal}/>) : edit ? 
    (<EditForm title={title} fx_back={() => setEdit(!edit)} setFilterValue={setFilterValue} setPreFilter={setPreFilter} setPageInd={setPageInd} pageInd={pageInd} setLiveFilter={setLiveFilter} fx_changePage={fx_changePage} selected={editValue} featureList={featureList} requiredFiled={requiredFiled} setMandatoryfield={setCanUpdate} mandatoryField={canUpdate} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} setFilterFinal={setFilterFinal} history={history} featureDisable={featureDisable}/>) :
        (
            <div className="table-container">
                <div className="title-font">{title}</div>
                <SnackBar open={snOpen} setOpen={setSnOpen} severity={severity} message={message}/>
                <div className="chart-bullet">
                   <BulletChart name="asset_type" title="Top 10 Asset Types" loading={chartLoading} data={ assetType.length > 0 ? assetType.map(x => { return { name: x.type ? x.type : "Others", total: x.total } }) : []} colorList={chartColorList} />
                </div>             
                <div className="buttons">
                    {/* <div className="buttons-all">
                        <input type="checkbox" className="check-type" />
                    </div> */}

                    <div className="filter-container-asset">
                        <img loading="lazy" src={SearchIcon} alt="search-icon" />
                        <img loading="lazy" src={DarkNavigationDown} alt="search-icon" onClick={() => setFilter(!filter)} className={hoveredO.filter ? "add-hover-button-style" : "add-button-style"} onMouseEnter={() => setIsHoveredO((current) => ({...current, filter: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, filter: false}))} />
                        <div className="input-filter">
                            <div className={filterDesign()}>
                                {filterCount()}

                            </div>
                            <input type="text" value={preFilter.name ? preFilter.name.value[0] : ""} onChange={(e) => fx_PreFilter(e.target.value)} onKeyDown={(e) => EnterFilter(e)} />
                        </div>
                    </div>
                    <div className="add-button">
                        {
                            featureDisable ? null : (
                            <div onClick={() => setAdd(!add)}>
                                <img loading="lazy" className={hoveredO.add ? "add-hover-button-style" : "add-button-style"} onMouseEnter={() => setIsHoveredO((current) => ({...current, add: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, add: false}))} src={AddIcon} alt="Icon"/>  
                            </div>
                            )
                        }

                        {/* <IconButton className="remove-border" aria-label="add" style={{ paddingRight: "0px", paddingLeft: "0px", color:"#333333" }} onClick={() => setAdd(!add)}>
                            <img loading="lazy" src={AddIcon} alt="dropdown-icon" className={classes.img} />
                        </IconButton> */}
                        {/* <IconButton style={{ paddingLeft: "0px" }}> */}
                            {/* <img loading="lazy" src={DropdownIcon} alt="dropdown-icon" className={classes.img} /> */}
                        {/* </IconButton> */}
                        {/* <IconButton aria-label="edit" onClick={() => setEdit(!edit)} disabled={true} > */}
                            {/* <img loading="lazy" src={EditIcon} alt="dropdown-icon" className={classes.img} /> */}
                            {/* <img loading="lazy" src={GreyEditIcon} alt="Icon" /> */}
                        {/* </IconButton> */}
                        {/* <IconButton aria-label="delete" disabled={true}> */}
                            {/* <img loading="lazy" src={DeleteIcon} alt="dropdown-icon" className={classes.img} /> */}
                            {/* <img loading="lazy" src={GreyDeleteIcon} alt="Icon" /> */}
                        {/* </IconButton> */}
                    </div>

                    {/* the if else statement for filter component, temporary only */}
                    <div style={{ position: "absolute", margin: "0px 0px 0px 20px" }} ref={wrapperRef}>
                        {filter && <FilterBy setFilterFinal={setFilterFinal} filterFinal={filterFinal} filterList={filterList} filterValue={filterValue} setFilterValue={setFilterValue} />}
                    </div>


                </div>
                <div className="table-responsive">
                    {
                        data.map(el => {
                            return <ListCard data={el} collapsedAsset={collapsedAsset} setCollapsedAsset={setCollapsedAsset} featureList={featureList} fx_changePage={fx_changePage} setLiveFilter={setLiveFilter}  setFilterFinal={setFilterFinal} setPageInd={setPageInd} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} history={history} featureDisable={featureDisable}/>
                        })
                    }

                </div>
                {/* <div className="pagination-table">
                    <IconButton >
                        <img src={LeftButton} alt="left-icon" className="img-pagination" />
                    </IconButton>
                    <IconButton >
                        <img src={RightButton} alt="right-icon" className="img-pagination" />
                    </IconButton>
                1 - 3 of 3
            </div> */}
                {/* <div >
                <Copyright />
            </div> */}
                {/* <SuccessMessage text={isAdd ? `Records Added` : isEdit ? `Records Edited` : isDelete ? `Records Deleted` : ""} open={openSnackbar} handleClose={snackBarClose} /> */}
                <style>
                    {

                        ` .table-container{
                            margin: 24px 24px;
                       }
                       .chart-bullet{
                           background-color: #FFF;
                       }
                      .title-font{
                        height: 32px;
                        // width: 108px;
                        color: #333333;
                        font-size: 24px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 32px;
                        margin-bottom: 10px;
                      }
                      .buttons{
                        display: flex;
                        padding: 8px 8px 8px 0px;
                        margin-top: 10px;

                      }
                      .table-boostrap{
                        // margin: 16px;
                        // height:80vh;
                        min-height: 75vh;
                        background-color: #ffffff;
                      }
                      .table-responsive{
                          height:57vh;
                          width: 100%;
                        //   width: 
                      }
                      .filter-button{
                          font-size: 12px;
                      }
                      .pagination-table{
                        position: relative;.
                      }
                      .img-pagination{
                        width: 19px,
                        height: auto;
                      }
                      .row-height{
                          height:
                      }
                      .wrapper{
                        word-wrap: break-word
                      }
                      .check-type{
                        margin: 0px 4px 0px 0px;
                        height: 18px;
                        width: 18px;
                        background-color: #509D91;
                      }
                      .buttons-all{
                          display: flex;
                          align-items: center;
                      }
                      .filter-container-asset{
                        padding: 8px;
                        // margin: 0px 0px 0px 8px;
                        display: flex;
                        box-sizing: border-box;
                        height: 40px;
                        width: 360px;
                        border: 1px solid #939598;
                        border-radius: 4px;
                      }
                      .add-button{
                        margin: 0px 0px 0px 8px;
                        display: flex;
                        align-items: center;
                    }
                    .input-filter{
                        display: flex;
                        align-items: center;
                    }
                    input[type=text] {
                        width: 285px;
                        background: transparent;
                        border: none;
                        outline: none;
                    }
                    .filter-tag{
                        display: flex;
                        align-items: center;
                        color: #FFF;
                        border-radius: 4px;
                        width: 85px;
                        height: 24px;
                        text-align: center;
                        background-color: #509D91;
                        justify-content: center;
                        margin: 0px 8px 0px 0px;
                    }
                    .filter-no-tag{
                        height: 0px;
                    }
                    .add-button-style{
                        cursor: pointer;
                    }
                    .add-hover-button-style{
                        background-color: rgba(0,0,0,0.03);
                        border-radius: 5px;
                        cursor: pointer;
                    }
                    `
                    }
                </style>
            </div>
        )
}

export default Overview
