import React, {useState, useEffect} from 'react'
import { useQuery } from "@apollo/react-hooks";

import TelegramIcon from '../../Image/telegram-icon.svg'
import WhatsappIcon from '../../Image/whatsapp_icon.svg'
import SlackIcon from '../../Image/slack_icon.svg'
import GreyTelegramIcon from '../../Image/grey_telegram.svg'
import GreyWhatsappIcon from '../../Image/grey_whatsapp.svg'
import GreySlackIcon from '../../Image/grey_slack.svg'
import ChevronLightIcon from '../../Image/chevron_light.svg'
import UserVerifiedIcon from '../../Image/userVerified.svg'
import UserNonVerifiedIcon from '../../Image/userNonVerified.svg'

import Overview from './Overview'
import FunctionAdd from './FunctionAdd'
import FunctionEdit from './FunctionEdit'
import {GET_USERS} from "../../config/config-graphql/Graphql";
import { filterList } from "../../config/config-filter/filter"
import Loaders from "../../component/component-loaders/Loaders"
import SnackBar from '../../config/config-snackbar/SnackBar'


const PanelUsers = ({fx_changePage, filter, setFilter, featureDisable}) => {

    const [filterFinal, setFilterFinal] = useState({})
    const [preFilter, setPreFilter] = useState({})
    const [canUpdate, setCanUpdate] = useState(false) //default false and also this state for btn save mandatory field
    const [edit, setEdit] = useState(false); //default false and also this state is to open the overview details edit form
    const [editValue, setEditValue] = useState() //this state is to store the all the selected value into the state.,

    const { data : user_data, loading, error } = useQuery(GET_USERS, {variables: {filter: filterFinal}})


    //this state for error handling on getQuery
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")

    const PreFilter = (value) => {
        setPreFilter({ name: { type: "CONTAINS", value: [ value ] } })
    }

    const EnterFilter =(e) => {
        if(e.key === "Enter"){
            setFilterFinal(preFilter)
        }
    }

    useEffect(() => {
        if(error){
            setSnOpen(true)
            setMessage("Error: Unable to retrieve data. Please try again later.")
            setSeverity("error")
        }
      }, [error])

    return loading ? <Loaders /> : error ? (<SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/>) :(
        <div>
             <Overview 
               data={user_data && user_data.getAgencyUsers !== null ? user_data.getAgencyUsers : []}  
               title="Users" 
               addForm={FunctionAdd} 
               editForm={FunctionEdit} 
               Cards={Cards} 
               setMainFilter={setFilter} 
               filterFinal={filterFinal} 
               setFilterFinal={setFilterFinal} 
               preFilter={preFilter} 
               fx_PreFilter={PreFilter} 
               EnterFilter={EnterFilter} 
               keyFilter="name" 
               filterList={filterList.users}
               setPreFilter={setPreFilter} 
               setCanUpdate={setCanUpdate} 
               canUpdate={canUpdate} 
               edit={edit} 
               setEdit={setEdit} 
               setEditValue={setEditValue} 
               editValue={editValue}
               featureDisable={featureDisable}
            />
        </div>
    )
}

export default PanelUsers

const Cards = ({data, setEdit, edit, add, setAdd, setEditValue}) => {

    // console.log("list data", data)

    const [isHovered, setIsHovered] = useState({
        container: false,
        details: false
    })

     const Icon = ({comm}) => {
        switch (comm) {
            case "telegram":
                return <img loading="lazy" src={TelegramIcon} alt="msg-icon" style={{margin:"0px 16px 0px 0px"}}/>
            case "slack":
                return <img loading="lazy" src={SlackIcon} alt="msg-icon" style={{margin:"0px 16px 0px 0px"}}/>
            case "whatsapp":
                return <img loading="lazy" src={WhatsappIcon} alt="msg-icon" style={{margin:"0px 16px 0px 0px"}}/>
            default:
                break;
        }       
    }

    const NoIcon = ({comm}) => {
        switch (comm) {
            case "telegram":
                return <img loading="lazy" src={GreyTelegramIcon} alt="msg-icon" style={{margin:"0px 16px 0px 0px"}}/>
            case "slack":
                return <img loading="lazy" src={GreySlackIcon} alt="msg-icon" style={{margin:"0px 16px 0px 0px"}}/>
            case "whatsapp":
                return <img loading="lazy" src={GreyWhatsappIcon} alt="msg-icon" style={{margin:"0px 16px 0px 0px"}}/>
            default:
                break;
        } 
    }

    const btnDetails = (e, value) => {
        let users = []
        users.push(data)
        e.preventDefault(e);
        setEdit(!edit)
        setEditValue(users)
    }
  return data && (
    <div className="channel-main-container" onMouseEnter={() => setIsHovered((current) => ({...current, container: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, container: false}))}>

        <div className="channel-o-container">
            <section className="name-data">
                <div className="verified-container">
                   {data.verified ? <img loading="lazy" src={UserVerifiedIcon} alt="v-icon"/> : <img loading="lazy" src={UserNonVerifiedIcon} alt="v-icon"/>} {/* <img loading="lazy" src={} alt="v-icon"/> */}
                   <div className="email-text">{data.email}</div>
                </div>
                {/* <div style={{fontSize: "16px", fontWeight: 550}}>{`${data.firstName} ${data.lastName}`}</div> */}
                <p style={{margin: "0px 0px 0px 0px", fontSize:"14px", fontWeight: "normal"}}>{data && data.privileges[0] && data.privileges[0].role.replace(/_/g, " ")}</p>
            </section>
            <div className="line-box"></div>
            <section className="email-user-container">
                <div>
                    <p style={{margin: "0px 0px 4px 0px", fontSize:"12px", color:"#939598"}}>last login</p>
                    {/* <div style={{height: "24px"}}>{data.email}</div> */}
                </div>
            </section>
            <div className="line-box"></div>
            <section className="channel-icon-container">
                
                <p className="text-integration">Integrations</p>
                <div>
                    {
                        data && data.telegram && data.telegram.contactNo ? <Icon comm="telegram"/> : <NoIcon comm="telegram" />
                    }
                    {
                        data && data.whatsapp && data.whatsapp.contactNo ? <Icon comm="whatsapp"/> : <NoIcon comm="whatsapp" />
                    }
                    {
                        data && data.slack && data.slack.contactNo ? <Icon comm="slack"/> : <NoIcon comm="slack" />
                    }
                </div>

                
            </section>
            <div className="onhover-container">
                { isHovered.container && (
                    <div className={`${isHovered.details ? "isHovered-button-edits": "button-edits"}`} onClick={e => btnDetails(e)} onMouseEnter={() => setIsHovered((current) => ({...current, details: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, details: false}))}>
                        <img src={ChevronLightIcon} alt="chev-light"/>
                    </div>
                ) }
            </div>
        </div>

        <style jsx>
        {
          `
            .channel-main-container{
                display: grid;
                height: 64px;
                margin-bottom: 4px;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
                gap: 0px 0px;
                grid-template-areas: ". .";

            }
            .channel-o-container{
                padding: 0px 0px 0px 16px;
                background-color: #FFFFFF;
                border-radius: 4px;
                display: grid;
                align-items: center;
                grid-template-columns: 1fr 1px 1fr 1px 1fr 43px;
                // grid-template-columns: 2fr 1px 1fr 43px;
                grid-template-rows: 1fr;
                gap: 0px 0px;
                grid-template-areas:
                  ". . . .";            
            }   
            .checkbox-container-channel{
                display: flex;
                align-items: center;
            }
            .onhover-container{
                justify-content: flex-end;
                background-color: #FFFFFF;
                width: 100%;
                height: 100%;
                flex-basis: 4%;
            }
            . check-type{
                margin: 0px 4px 0px 0px;
                height: 18px;
                width: 18px;
                background-color: #509D91;
            }  
            .channel-icon-container{
                display: flex;
                flex-direction: column;
                align-items:start;
                height: 40px;
                margin: 0px 0px 0px 8px;
            } 
            .line-box{
                height: 48px;
                margin-right: 8px;
                border: 1px solid #DDDDDD;
            }
            .button-edits{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                border-radius: 0 4px 4px 0;
                background-color: #509D91;
                cursor: pointer;
                margin: 0;
            }
            .isHovered-button-edits{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                border-radius: 0 4px 4px 0;
                background-color: #498f84;
                cursor: pointer;
                margin: 0;
                font-weight: 600;
                box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
            }
            .text-integration{
                margin: 0px 0px 4px 0px;
                color: #939598;
                font-size: 11px;
                letter-spacing: 0;
                line-height: 16px;
            }
            .email-user-container{
                display:flex;
                justify-content: space-between;
                align-items: center;
                margin: 0px 8px 0px 8px;
            }
            .verified-container{
                display: grid; 
                grid-auto-columns: 1fr; 
                grid-template-columns: 16px 1fr; 
                grid-template-rows: 1fr; 
                gap: 0px 8px; 
                grid-template-areas: 
                  ". ."; 
                align-items:center;
            }
            .email-text{
                margin: 0px 0px 0px 8px;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
          `
        }
        </style>
    </div>
  ) 
}
