import React from "react";
import styled from "styled-components";

import Checkbox from "@material-ui/core/Checkbox";

const Container = styled.div`
  position: absolute;
  left: 0;
`;

class SelectionCell extends React.PureComponent {
  handleChange = (e) => {
    const { rowData, rowIndex, column } = this.props;
    const { onChange } = column;

    onChange({ selected: e.target.checked, rowData, rowIndex });
  };

  render() {
    const { rowData, column } = this.props;
    const { selectedRowKeys, rowKey } = column;

    const checked = selectedRowKeys.includes(rowData[rowKey]);

    return (
      <Container>
        <Checkbox
          className="check-col"
          color="primary"
          size="small"
          type="checkbox"
          checked={checked}
          onChange={this.handleChange}
        />
      </Container>
    );
  }
}

export default SelectionCell;