import React, { useState, } from 'react'

//library
import { useMutation } from "@apollo/react-hooks";

//images
import NavigationIcon from "../../Image/chevron_right.svg"

//component
import { AllMandatoryFilled, onUserAdd  } from "../../config/config-function/function"
import { ASSET_REDUCER } from "../../config/config-reducer/reducer";
import { ADD_ASSET, GET_ASSET } from "../../config/config-graphql/Graphql"
import {requiredField} from '../../config/config-table/table'
import Form from "./Form"
import SnackBar from '../../config/config-snackbar/SnackBar'
import Loaders from '../../component/component-loaders/Loaders'
import SaveCard from '../../component/component-save/SaveCard'


const AddForm = ({ title, fx_back, setIsHoveredO, setOpenSnackbar, setIsAdd, featureList, setMandatoryfield, mandatoryField, setSnOpen, setMessage, setSeverity, setPageInd, setFilterFinal }) => {
  const [values, setValues] = useState({});
  const [addVariables, setAddVariables] = useState({});
  const [canAdd, setCanAdd] = useState(false) //only can add once all mandatory field filled
  const [add_devices, setAdd_devices] = useState([])
  const [add_smartLocks, setAdd_smartLocks] = useState([])
  const [mainAssetSelection, setMainAssetSelection] = useState({})

  //this state are for snackbar props
  const [errOpen, setErrOpen] = useState(false)
  const [errMsg, setErrMsg] = useState("")
  const [errSevere, setErrSevere] = useState("")

  const [isHovered, setIsHovered] = useState({
    button: false
  })

  const onSubmit = async () => {
    try{
      const response = await addData();   
      if(response.errors){
        setErrOpen(true)
        setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
        setErrSevere("error")
      }
      else{
        setSnOpen(true)
        setMessage("Record has been successfully added")
        setSeverity("success")
        setPageInd(false)
        setFilterFinal({})
        fx_back();
      }   
    }catch(err){
      console.log(JSON.stringify(err, null, 2));
      setErrOpen(true)
      setErrMsg(JSON.stringify(err.message, null, 2))
      setErrSevere("error")
    }

  };

  const onUserInput = (e, value) => {
    let input = null
    if(e){
      input = onUserAdd(e, null, false, onChange, values)
      setAddVariables({ new_asset: [ASSET_REDUCER(input)] });        
    }else if(value){
      input = onUserAdd(e, value, true, onChange, values)
      setAddVariables({ new_asset: [ASSET_REDUCER(input)] });   
    }
    const _check = AllMandatoryFilled(requiredField.asset, input)

    _check ? setMandatoryfield(true) : setMandatoryfield(false)
  };

  const onChange = (e, value) => {
    setCanAdd(true)
    const input = value ? { ...values, ...value }: { ...values, [e.target.name]: e.target.value };
    setValues(input)
  };

    const [addData, {loading, error}] = useMutation(ADD_ASSET, {
        variables: addVariables,
        refetchQueries: () => [
            {
                query: GET_ASSET,
                variables: { filter: {}},
            },
        ],
    });


    const SelectAssetGroup = (uuid) => {
      onUserInput(null, { add_group: { uuid }})
    }

    const PairAsset = (selected, fx_close) => {
      onUserInput(null, { pair_asset: { uuid: selected.value }, paired_assets: [{ uuid: selected.value, name: selected.label }] })
      setMainAssetSelection(selected)
      fx_close();
    }

    const UnpairAsset = (fx_close) => {
        onUserInput(null, { pair_asset: {} })
        fx_close();
    }

    const onClickBack = () => {
      setIsHoveredO({add: false})
      fx_back()
    }

    if (loading) return <div style={{position: "relative", right:"2px", top: "40px"}}><Loaders /></div>

    return (
      <div className="form-container">
        <div className="navigation-title-placeholder">
          <div className="title-add">
            <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
            <div onClick={() => onClickBack()} style={{cursor: "pointer"}} className="detail-title first-title">
              {title}
            </div>
            <div className="detail-title">
              <img loading="lazy" src={NavigationIcon} alt="Back"/>
            </div>                      
            <div className="detail-title">Add {title}</div>
          </div>
          <div>
            <SaveCard onSubmit={onSubmit} canAdd={mandatoryField}/>
          </div>
        </div>
        <Form asset={values} onUserInput={onUserInput} fx_back={fx_back} addDeviceList={add_devices} canAdd={canAdd} setAddDevice={setAdd_devices} featureList={featureList} fx_selectAssetGroup={SelectAssetGroup} fx_pairAsset={PairAsset} fx_unpair={UnpairAsset} isAdd add_smartLocks={add_smartLocks} setAdd_smartLocks={setAdd_smartLocks} mainAssetSelection={mainAssetSelection}/>
            
        <style>
          {`
           .title-add{
            display: flex;             
            align-items: center;              
          }
          .form-container{
            margin: 24px;
          }
          .navigation-title-placeholder {
            font-size: 18px;
            font-weight: 545;
            height: 32px;
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
          }
          .details {
            height: 40px;
            // margin-left: 24px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.54);
          }
          .autocomplete {
            display: flex;
            height: 45px;
            // margin-left: 24px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.54);
          }
          .textfield {
            position: relative;
            bottom: 40px;
          }
          .detail-title{
            padding: 8px;
          }
          .first-title{
            font-size: 16px; 
            padding-left: 0px;
          }
          `}
        </style>
      </div>
    )
}

export default AddForm