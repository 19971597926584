import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from 'react-switch'
import PhoneInput from 'react-phone-input-2'
import countryList from 'react-select-country-list'
import 'react-phone-input-2/lib/style.css'

import TelegramIcon from '../../Image/telegram-icon.svg'
import WhatsappIcon from '../../Image/whatsapp_icon.svg'
import SlackIcon from '../../Image/slack_icon.svg'
import GreyCloseIcon from '../../Image/grey-close.svg'
import GreenCheckedIcon from '../../Image/green_checked.svg'
import UserVerifiedIcon from '../../Image/userVerified.svg'
import UserNonVerifiedIcon from '../../Image/userNonVerified.svg'

import { settingFieldValue, requiredLabel } from "../../config/config-function/function";
import tableConfig from "../../config/config-table/table";
import {GET_USERS, DELETE_USER, DELETE_CHANNEL} from "../../config/config-graphql/Graphql";

import DeleteCard from '../../component/component-deletecard/DeleteCard'

import DarkVisibilityIcon from '../../Image/dark_visibility.svg'
import DarkVisibilityOffIcon from '../../Image/dark_visibilityOff.svg'
import { Base64 } from 'js-base64';
import { set } from 'js-cookie';

const { users: fieldList } = tableConfig.fieldList;

const useStyles = makeStyles({});



const Form = ({users, onUserInput, displayed, fx_back, privileges, setPrivileges, telegram, setTelegram, setPassword, password, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere, setContactCode, contact_number, featureDisable}) => {

const classes = useStyles()
const [togglePassword, setTogglePassword] = useState(false)
const [inputType, setInputType] = useState('password')

const isBulkEdit = Array.isArray(users) && users.length > 1;
const [isHovered, setIsHovered] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    role: false,
    contact_number: false,
    username: false
})

const toggleOnOffPassword = () => {
    if(inputType === 'password'){
        setInputType('text')
        setTogglePassword(true)
    }else{
        setInputType('password')
        setTogglePassword(false)
    }
}

const onChTelegram = (key, value) => {
    let clone = {...telegram}
    clone[key] = value
    setTelegram(clone)
}

const onChPvi = (key, value) => {
    setPrivileges({...privileges, [key]: value})
}

const onPhoneNumber = (e, value) => {
    let contactCode = value.dialCode
    setTelegram({...telegram, contactNo: e})
    setContactCode(contactCode)
}

return (
    <div className="details-container">
        <div className="first-container-users">
            <section className="messenging-container">
                <p className="channel-title">Identity and Access Management (IAM)</p>
                <div className="details-input-iam" >
                        <div className="input-input">Role</div> 
                        <Select style={{width: 200, height: 26, fontSize: 13, position: "relative", top: "1px", backgroundColor: isHovered.role && "rgba(182, 186, 191, 0.2)", borderRadius: isHovered && "4px"}} value={privileges.role} disableUnderline onChange={(e) => onChPvi("role", e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, role: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, role: false}))} disabled={featureDisable ? true : false}>
                            <MenuItem value="None" >-</MenuItem>
                            {/* <MenuItem value="Developer_Access">Developer Access</MenuItem> */}
                            <MenuItem value="Agency_Administrator">Agency Administrator</MenuItem>
                            {/* <MenuItem value="Agency_Proxy">Agency Proxy</MenuItem>  */}
                            <MenuItem value="Agency_Viewer">Agency Viewer</MenuItem>
                            {/* <MenuItem value="Asset_Manager">Asset Manager</MenuItem>    */}
                            {/* <MenuItem value="Asset_Viewer">Asset Viewer</MenuItem>  */}
                            <MenuItem value="Reports_Viewer">Reports Viewer</MenuItem>
                            <MenuItem value="Notifications">Notifications</MenuItem>
                            <MenuItem value="Land_Transport_Authority">Land Transport Authority</MenuItem> 
                            {/* <MenuItem value="Basfare_Users">Basfare Users</MenuItem> */}
                        </Select>
                </div>
                {/* {
                    privileges[0] && privileges[0].role && privileges[0].role === "Notifications" ? 
                    null : <div className="details-input-iam">
                        <div className="input-input">Password<p style={{color:"red", margin:"0"}}>*</p></div> 
                        <input type={inputType} className={`${isHovered.password ? "isHovered-border": "text-box"}`} placeholder="-" name="password"  value={password} onChange={(e) => setPassword(e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, password: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, password: false}))}/>
                        {togglePassword ? <img lazy="loading" src={DarkVisibilityOffIcon} style={{cursor: "pointer"}} alt="visibility-icon" onClick={toggleOnOffPassword}/> : <img lazy="loading" src={DarkVisibilityIcon} style={{cursor:"pointer"}} alt="visibility-icon" onClick={toggleOnOffPassword}/>}
                    </div>  
                } */}

            </section>
            {
                privileges && privileges.role !== "Notifications" && privileges.role !== "" ? <section className="information-container">
                    <p className="channel-title">Users Information</p>
                    <div className="channel-input">
                        <div className="details-input-iam">
                            <div className="input-input">Email <p style={{color:"red", margin:"0"}}>*</p></div> 
                            {featureDisable ? <label className="text-box">{settingFieldValue(isBulkEdit, users, fieldList.email.name)}</label> : <input type="text" className={`${isHovered.email ? "isHovered-border": "text-box"}`} placeholder="-" name="email" value={settingFieldValue(isBulkEdit, users, fieldList.email.name)} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, email: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, email: false}))}/>}
                        </div>
                    </div>
                </section> : null
            }


        </div>
        <div className="second-container-user">
            <section className="channel-contact-container">
                <div className="msg-container">
                    <img lazy="loading" src={TelegramIcon} alt="msg-icon"/>
                    <p className="telegram-text">Telegram</p>
                </div>
                <div className="details-input">
                    <div className="input-input">Contact Number</div> 
                    {featureDisable ? <label>{displayed ? contact_number : telegram.contactNo}</label> : <PhoneInput searchClass="search-class" country={'my'} value={displayed ? contact_number : telegram.contactNo} onChange={(e, value) => onPhoneNumber(e, value)} enableSearch={true} onMouseEnter={() => setIsHovered((current) => ({...current, contact_number: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, contact_number: false}))}/>}
                </div>
                <div className="details-input">
                    <div className="input-input">Chatbot</div> 
                    <Switch onChange={(value) => onChPvi("chatBotEnabled", value)} checked={privileges.chatBotEnabled} onColor="#509D91" onHandleColor="#509D91" uncheckedIcon={false} checkedIcon={false} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={35} className="react-switch" id="material-switch" disabled={featureDisable ? true: false}/>
                </div>
                <div className="details-input">
                    <div className="input-input">Username</div> 
                    {featureDisable ? <label className="text-box">{telegram.username}</label> : <input type="text" className={`${isHovered.username ? "isHovered-border": "text-box"}`} placeholder="-" name="username" value={telegram.username} onChange={(e) => onChTelegram(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, username: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, username: false}))}/>}
                </div>
                <div className="details-input">
                    <div className="input-input">Verified</div>  
                    {
                        displayed ? users && users[0].telegram && users[0].telegram.contactVerified && users[0].telegram.contactVerified ? <img lazy="loading" src={UserVerifiedIcon} alt="check-icon"/> : <img lazy="loading" src={UserNonVerifiedIcon} alt="check-icon"/> : <img lazy="loading" src={UserNonVerifiedIcon} alt="check-icon"/> 
                    }
                </div>
            </section>
            {/* <section className="channel-contact-container">
                <div className="msg-container">
                    <img lazy="loading" src={SlackIcon} alt="msg-icon"/>
                    <p className="telegram-text">Slack</p>
                </div>
            </section>
            <section className="channel-contact-container">
                <div className="msg-container">
                    <img lazy="loading" src={WhatsappIcon} alt="msg-icon"/>
                    <p className="telegram-text">Whatsapp</p>
                </div>
            </section> */}

                {/* {
                    displayed && !featureDisable ? 
                    <section className="deletion-container">
                        <DeleteCard 
                            title="Delete" 
                            module="user" 
                            details="" 
                            query={{getQuery: GET_USERS, deleteQuery: DELETE_USER}} 
                            uuid={users[0].uuid} 
                            agency_id={users[0].privileges.agencyID}
                            schema_uuid="email" 
                            fx_back={fx_back} 
                            suspend_agency_query="" 
                            active={false}
                            canDelete={true}
                            setSnOpen={setSnOpen} 
                            setMessage={setMessage}
                            setSeverity={setSeverity}
                            setErrMsg={setErrMsg} 
                            setErrOpen={setErrOpen} 
                            setErrSevere={setErrSevere}
                        />
                    </section> : null
            } */}
        </div>
        <style>
            {
                `.details-container{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 24px;
                    grid-template-areas:
                      ". .";
                    //   height: 30px;
                }
                .information-container{
                    padding: 16px;
                    background-color: #FFFFFF;
                    border-radius: 4px;
                }
                .deletion-container{
                    padding: 16px;
                    background-color: #FFFFFF;
                    border-radius: 4px;
                }
                .channel-contact-container{
                    padding: 16px;
                    background-color: #FFFFFF;
                    border-radius: 4px;
                    margin: 0px 0px 24px 0px;
                }
                .channel-title{
                    margin: 0px 0px 8px 0px;
                    color: #333333;
                    font-size: 15px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 24px;
                }
                .details-input{
                    display: grid;
                    grid-template-columns: 1fr 2fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    align-items: center;
                    grid-template-areas:
                        ". .";
                    height: 30px;
                    // white-space: nowrap;
                    // overflow: hidden;

                    margin: 0px 0px 8px 0px;
                    height: 30px;
                }
                .details-input-iam{
                    display: grid;
                    grid-template-columns: 1fr 2fr 24px;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    align-items: center;
                    grid-template-areas:
                        ". . ."; 
                    height: 30px;
                    // margin: 0px 0px 8px 0px;
                }
                .input-input{
                    display: flex;
                    width: 133px;
                }
                .first-container-users{

                }
                .text-box{
                    display: flex;
                    align-items: center;
                    border-style: none;
                    height: 24px;
                    outline: none;
                }
                .isHovered-border{
                    border: 1px solid #b6babf;
                    height: 24px;
                    border-radius: 4px;
                }
                .selection-icon{
                    display: flex;
                    align-items: center;
                }
                .selection-text{
                    margin: 0px 0px 0px 4px;
                    font-size: 13px;
                }
                .btn-delete{
                    width: 70%;
                    background-color: #EA2B1F;
                    color: #FFFFFF;
                    font-weight: 600;
                    margin: 0px;
                }
                .div-btn-delete{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 16px 0px 0px 0px;
                }
                .users-details{
                    margin: 0px 0px 0px 0px;
                    color: #939598;
                    font-size: 11px;
                    letter-spacing: 0;
                    line-height: 16px;
                }
                .msg-container{
                    display: flex;
                    align-items: center;
                    margin: 0px 0px 8px 0px;
                }
                .telegram-text{
                    margin: 0px 0px 0px 8px;
                    color: #333333;
                    font-size: 15px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 24px;
                }
                .messenging-container{
                    padding: 16px;
                    grid-area: messenging-container;
                    background-color: #FFFFFF;
                    border-radius: 4px;
                    margin: 0px 0px 24px 0px;
                }
                .react-tel-input{
                    width: 0%;
                    }
    
                .react-tel-input .form-control{
                    border-bottom: none;
                    border-top: none;
                    border-right: none;
                    height: 26px;
                }
                .react-tel-input .flag-dropdown{
                    border-bottom: none;
                    border-top: none;
                    border-right: none;
                    border-left: none;
                }
                .react-tel-input .selected-flag{
                    padding: 0px;
                }
                .react-tel-input .flag-dropdown{
                    background-color: #FFF;
                }
                .react-tel-input .country-list .search-emoji {
                    width: 10%;
                }
                .react-tel-input .country-list .search {
                    display: flex;
                }
                .react-tel-input .country-list .country {
                    display: flex;
                    align-items: center;
                }
                .react-tel-input .country-list .flag {
                    width: 13%;
                    margin-top:0px;
                }
                .react-tel-input .country-list .flag {
                    font-size:14px;
                }
                .react-tel-input .country-list .country .dial-code{
                    font-size:14px;
                }
                .react-tel-input .country-list .country-name{
                    font-size:14px;
                }
                `
            }
        </style>
    </div>
)
}

export default Form
