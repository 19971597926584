import React from 'react'
import GreenCheckedIcon from '../../Image/green_checked.svg'
import GreyCloseIcon from '../../Image/grey-close.svg'

import { snakeCase } from "snake-case"

const FeatureView = ({ featureList, selectedFeature }) => {
    return (
        <div className="checkbox-container">
            {
                featureList.map((x, i) => {
                    try {
                      return (
                        <div className="checkbox-input">
                            <div className="classes">
                                {
                                    selectedFeature.includes(x.uuid) ? <img loading="lazy" src={GreenCheckedIcon} alt="green-checked" style={{marginRight: "8px", height: "16px", width: "16px"}}/> : <img loading="lazy" src={GreyCloseIcon} alt="grey-close" style={{marginRight: "8px", height: "16px", width: "16px"}}/>
                                }
                                <img src={`${x.icon}/icon_${snakeCase(x.name)}_enabled.svg`} alt="test" className="images"/>
                                <div className="status">{x.name}</div>
                            </div>
                            <div className="details-status">{x.description}</div>
                        </div>
                      )  
                    } catch (error) {
                        // console.log(error)
                    }
                    return null
            })
        }
                    
            <style jsx>
                {
                    `
                    .checkbox-container{
                        margin: 16px 0px 8px 0px;
                        flex-direction: column;
                        display: flex;
                        // align-items: center;
                    }
                    .checkbox-input{
                        display: grid;
                        grid-template-columns: 220px 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas: ". .";
                        align-items: center;
                        width: 100%;
                        margin: 0px 0px 8px 0px;
                    }
                    .classes{
                        display: flex;
                        align-items: center;
                    }
                    .checkboxes{
                        margin-right: 16px;
                    }
                    .images{
                        margin-right: 8px;
                    }
                    .status{

                    }
                    .details-status{
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 23px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default FeatureView
