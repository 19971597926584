import React, {useState} from 'react'
const DeleteCard = ({polycies, onRemovePolicies}) => {

    const [isHovered, setIsHovered] = useState({
        btnDel: false
    })
    return (
        <div className="delete-policy-container">
            <header className="delete-text">Delete Policy</header>
            <div className="delete-div-policy-container" onMouseEnter={() => setIsHovered((current) => ({...current, btnDel: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnDel: false}))}>
            
                <button className={`${isHovered.btnDel ? "isHovered-delete-btn-policy": "delete-btn-policy"}`} onClick={() => onRemovePolicies(polycies.uuid)}>Delete</button>
            </div>
            
        </div>
    )
}

export default DeleteCard
