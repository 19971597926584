import React, { useState} from 'react'

//library
import { useMutation } from "@apollo/react-hooks";

//image
import NavigationIcon from "../../Image/chevron_right.svg"

//component
import Loaders from "../../component/component-loaders/Loaders"
import {UPDATE_SIGFOX_CONFIG, GET_DEVICES_STAT, CELLULAR_CONFIG, SEARCH_DEVICES} from '../../config/config-graphql/Graphql'
import SnackBar from '../../config/config-snackbar/SnackBar'
import DeleteCard from '../../component/component-deletecard/DeleteCard'
import SaveCard from '../../component/component-save/SaveCard'



const EditForm = ({ title, setPageInd, fx_back, selected, fx_changePage, setFilterMain, Form, query, setCanUpdate, canUpdate, featureList, setSnOpen, setMessage, setSeverity, setFilterFinal, setPreFilter, setFilterValue, history, setSuccessTransaction}) => {

    const [values, setValues] = useState(selected && selected.map(a => ({...a})));
    const [editVariables, setEditVariables] = useState({});
    const [getVariables, setGetVariables] = useState({});
    const [sigfoxVariable, setSigfoxVariable] = useState({})
    const [cellularVariable, setCellularVariable] = useState({})
    const [isHovered, setIsHovered] = useState({
      btnDetails: false
    })
    const [genModal, setGenModal] = useState(false)

    //this state to mention if its on edit form
    //edit = true (edit form)
    //edit = false (add form)
    const [displayed] = useState(true)

    // console.log(values)
    //the errOpen, errMsg and errSevere is for snackbar props for error 
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("")

    const onSubmit = async () => {
      try{ 
        if(Object.keys(sigfoxVariable).length > 0){
          const upResponse = await editData({ variables: editVariables });  
          const upResSigfox = await updateSigfox({ variables: sigfoxVariable})
          if(upResponse.errors){
            setErrOpen(true)
            setErrMsg(`${upResponse.errors[0].extensions.code}: ${upResponse.errors[0].message}`)
            setErrSevere("error")
          }else if(upResSigfox.errors){
            setErrOpen(true)
            setErrMsg(`${upResSigfox.errors[0].extensions.code}: ${upResSigfox.errors[0].message}`)
            setErrSevere("error")
          }else{
            setSnOpen(true)
            setMessage("Record has been successfully updated")
            setSeverity("success")
            setFilterFinal({})//this to remove all the value in filter
            setPreFilter({})//this to remove all the value in filter
            setFilterValue({})//this to remove all the value in filter
            setPageInd(false)
            fx_back();
          }
        }else{ //cellular
          // console.log(editVariables);
          const upResponse = await editData({ variables: editVariables }); 
          // console.log(upResponse);    
          if(upResponse.errors){
            setErrOpen(true)
            setErrMsg(`Update Device:c${upResponse.errors[0].extensions.code}: ${upResponse.errors[0].message}`)
            setErrSevere("error")     
          }else {
            if (values[0].SKU.product_model !== 'Teltonika') {
              const celResponse = await cellularConfig({variables: {deviceId:cellularVariable.input.deviceId, input: {
                ignitionMode: cellularVariable.input.ignitionMode,
                payloadInterval: cellularVariable.input.payloadInterval,
                payloadIntervalIgnitionOff: cellularVariable.input.payloadIntervalIgnitionOff,
                payloadIntervalOffline: cellularVariable.input.payloadIntervalOffline,
                telitKeepAlive: cellularVariable.input.telitKeepAlive,
                rat: cellularVariable.input.rat,
                extSense_Fuel: cellularVariable.input.extSense_Fuel,
                FuelSensingRatio: cellularVariable.input.FuelSensingRatio,
                extSense_Vibration: cellularVariable.input.extSense_Vibration
              }}})
              if(celResponse.errors){
                setErrOpen(true)
                setErrMsg(`Update Cellular:c${upResponse.errors[0].extensions.code}: ${upResponse.errors[0].message}`)
                setErrSevere("error")
              }else{
                setSnOpen(true)
                setMessage("Record has been successfully updated")
                setSeverity("success")
                setFilterFinal({})//this to remove all the value in filter
                setPreFilter({})//this to remove all the value in filter
                setFilterValue({})//this to remove all the value in filter
                setPageInd(false)
                fx_back();
              }
            } else {
                setSnOpen(true)
                setMessage("Record has been successfully updated")
                setSeverity("success")
                setFilterFinal({})//this to remove all the value in filter
                setPreFilter({})//this to remove all the value in filter
                setFilterValue({})//this to remove all the value in filter
                setPageInd(false)
                fx_back();
            }
          }
        }

      }catch(err){
        console.log(JSON.stringify(err, null, 2))
        setErrOpen(true)
        setErrMsg(JSON.stringify(err.message, null, 2))
        setErrSevere("error")
      }
    };

    const { editQuery, getQuery } = query;

    const [editData, { loading: editLoading, error }] = useMutation(editQuery, {
        refetchQueries: () => [
            {
                query: getQuery,
                variables: {filter: {}, type:"All" },
            },
            {            
              query:SEARCH_DEVICES,
              variables:{searchKey:  "", boardClass: "Cellular" }

            },
            {
                query: GET_DEVICES_STAT,
            }
        ],
    });

    const [updateSigfox, { loading: upSigLoading, error: upSigError }] = useMutation(UPDATE_SIGFOX_CONFIG, {
      refetchQueries: () => [
          {
              query: getQuery,
              variables: {filter: {}, type:"All" },
          },
          {
            query: GET_DEVICES_STAT
          }
      ],
  });

  const [cellularConfig, { loading: cellularLoading, error: cellularError }] = useMutation(CELLULAR_CONFIG, {
    refetchQueries: () => [
        {
            query: getQuery,
            variables: {filter: {}, type:"All" },
        },
        {
          query: GET_DEVICES_STAT
        }
    ],
});

    const onChange = (e, i, value) => {
        const _values = [...values];
        if(value){
            let keys = Object.keys(value)
            keys.map(key => _values[i][key] = value[key])
        } else{
            _values[i][e.target.name] = e.target.value;
        }
        setValues(_values);
    };

    if (editLoading && upSigLoading) return <div style={{position: "relative", right:"2px", top: "40px"}}><Loaders /></div>

    const onClickBack = () => {
      setFilterFinal({})//this to remove all the value in filter
      setPreFilter({})//this to remove all the value in filter
      setFilterValue({})//this to remove all the value in filter
      setPageInd(false)
      fx_back()
    }



    return (
      
        <div className="form-container">
            <div className="navigation-title-placeholder">
                <div className="title-add">
                       <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                       <div onClick={onClickBack} style={{cursor: "pointer"}} className="detail-title first-title">
                         {title}
                       </div>
                       <div className="detail-title">
                          <img loading="lazy" src={NavigationIcon} alt="Back"/>
                       </div>                      
                       <div className="detail-title">Edit {title}</div>
                       <div className="detail-title"></div>
                </div>
                <div className="button-delete-save-container">
                  {
                    values[0].device_type !== "Smartlocks" ? 
                      <DeleteCard 
                        query={query} 
                        uuid={values[0].uuid} 
                        agency_id={values[0].agency ? values[0].agency.id : null} 
                        canDelete={values[0].agency && values[0].uuid ? false : true}
                        schema_uuid="devices" 
                        module="devices" 
                        fx_back={fx_back} 
                        setSnOpen={setSnOpen} 
                        setMessage={setMessage}
                        setSeverity={setSeverity}
                        setErrMsg={setErrMsg} 
                        setErrOpen={setErrOpen} 
                        setErrSevere={setErrSevere}
                        setPageInd={setPageInd}
                        setFilterFinal={setFilterFinal}
                        deleteTooltip = "Deleting an agency will erase all associated data, including all historical telemetry datapoints. For security reasons, deleting is only possible if there are no Assigned Devices."
                    /> : null
                  }
                  <SaveCard onSubmit={onSubmit} canAdd={canUpdate}/>

                </div>

            </div>
            <Form values={values} setPageInd={setPageInd} onChange={onChange} fx_changePage={fx_changePage} setFilterMain={setFilterMain} setFilterFinal={setFilterFinal} displayed={displayed} setEditVariables={setEditVariables} setGetVariables={setGetVariables} query={query} fx_back={fx_back} setCanUpdate={setCanUpdate} canUpdate={canUpdate} featureList={featureList} sigfoxVariable={sigfoxVariable} setSigfoxVariable={setSigfoxVariable} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} setErrMsg={setErrMsg} setErrOpen={setErrOpen} setErrSevere={setErrSevere} history={history} setCellularVariable={setCellularVariable} setGenModal={setGenModal} genModal={genModal} editData={editData} editVariables={editVariables}/> 
      
        <style jsx>
          {`
            .title-add{
              display: flex;
              align-items: center;            
            }
            .form-container{
              margin: 24px;
              display: grid;
            }
            .navigation-title-placeholder {
              font-size: 18px;
              font-weight: 545;
              height: 32px;
              margin-bottom: 16px;
              display: flex;
              justify-content: space-between;
            }
            .details {
              height: 40px;
              // margin-left: 24px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.54);
            }
            .autocomplete {
              display: flex;
              height: 45px;
              // margin-left: 24px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.54);
            }
            .textfield {
              position: relative;
              bottom: 40px;
            }
            .detail-title{
              padding: 8px;
            }
            .first-title{
              font-size: 16px; 
              padding-left: 0px;
            }
            .button-delete-save-container{
              display: flex;
            }
          `}
        </style>
      </div>
    )
}

export default EditForm
