import React, {useState, useContext} from 'react'
import Cookies from "js-cookie";
import EditPasswordIcon from '../../Image/white_edit.svg'
import VisibleIcon from '../../Image/dark_visibility.svg'
import VisibleOffIcon from '../../Image/dark_visibilityOff.svg'
import {UPDATE_USER, GET_USERS} from '../../config/config-graphql/Graphql'
import {AuthContext} from '../../config/config-auth/Auth'
import { useMutation, useQuery } from "@apollo/react-hooks";
import SnackBar from '../../config/config-snackbar/SnackBar'
import Loaders from '../../component/component-loaders/Loaders'

const Modal = ({open, fx_close, chgPassword, setChgPassword}) => {
    const agencyName = Cookies.get("agency-name")
    const userId = Cookies.get("_id")
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("") 
    const context = useContext(AuthContext);
    const [userFirst, setUserFirst] = useState({
        firstName: "",
        lastName: ""
    })



    const [passField, setPassField] = useState({
        currentPassword: "",
        "encode-currentPassword": "",
        newPassword: "",
        "encode-newPassword" : "",
        confirmPassword: "",
        "encode-confirmPassword" : "",
    })

    const [editData, { loading: editLoading, error }] = useMutation(UPDATE_USER);

    const [togglePassword, setTogglePassword] = useState({
        current_password: false,
        new_password: false,
        confirm_password: false
    })
    const [isHovered, setIsHovered] = useState({
        firstName: false,
        lastName: false,
        current_password: false,
        new_password: false,
        confirm_password: false
    })
    const [inputType, setInputType] = useState({
        current_password: 'password',
        new_password: 'password',
        confirm_password: 'password'
    })

    const changePassword = (e) => {
        setChgPassword(true)
    }

    const onChange = (event) => {
        setPassField({ ...passField, [event.target.name]: event.target.value, [`encode-${event.target.name}`]: context.Encode(event.target.value) });
    };

    const toggleOnOffCurPassword = () => {
        if(inputType.current_password === 'password'){
            setInputType({...inputType, current_password:'text'})
            setTogglePassword({...togglePassword, current_password: true})
        }else{
            setInputType({...inputType, current_password:'password'})
            setTogglePassword({...togglePassword, current_password: false})
        }
    }

    const toggleOnOffNewPassword = () => {
        if(inputType.new_password === 'password'){
            setInputType({...inputType, new_password:'text'})
            setTogglePassword({...togglePassword, new_password: true})
        }else{
            setInputType({...inputType, new_password:'password'})
            setTogglePassword({...togglePassword, new_password: false})
        }
    }

    const toggleOnOffConfirmPassword = () => {
        if(inputType.confirm_password === 'password'){
            setInputType({...inputType, confirm_password:'text'})
            setTogglePassword({...togglePassword, confirm_password: true})
        }else{
            setInputType({...inputType, confirm_password:'password'})
            setTogglePassword({...togglePassword, confirm_password: false})
        }
    }

    const updateFirstlastname = async (e) => {
        try{
            e.preventDefault();
            if(userFirst.firstName && userFirst.lastName){
                const response = await editData({variables: {input: {userId:userId, firstName:userFirst.firstName, lastName:userFirst.lastName}}})
                if(response.error){
                    setErrOpen(true)
                    setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
                    setErrSevere("error")
                }else{
                    setErrOpen(true)
                    setErrMsg("Record has been successfully updated!")
                    setErrSevere("success")
                }
            }else{

                setErrOpen(true)
                setErrMsg("Error 403: please fill in first name and last name")
                setErrSevere("error")
            }

        }catch(err){
            console.log(JSON.stringify(err, null, 2));
            setErrOpen(true)
            setErrMsg(JSON.stringify(err.message, null, 2))
            setErrSevere("error")
        }
    }

    //this to update the password
    const updatePassword = async (e) => {
        try{ 
            e.preventDefault()
            if(passField.newPassword && passField.currentPassword && passField.confirmPassword){
                if(passField.newPassword === passField.confirmPassword){ //check if new pass
                    // console.log({input: {userId:userId, currentPassword:passField['encode-currentPassword'], newPassword:passField['encode-newPassword']}})
                    const response = await editData({variables: {input: {userId:userId, password: {currentPassword:passField['encode-currentPassword'], newPassword:passField['encode-newPassword']}}}})
                    if(response.error){
                        setErrOpen(true)
                        setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
                        setErrSevere("error")
                    }else{
                        setErrOpen(true)
                        setErrMsg("Record has been successfully updated!")
                        setErrSevere("success")
                    }
    
                }else{
                    // console.log("password not same")
                    setErrOpen(true)
                    setErrMsg("Error 403: password not same")
                    setErrSevere("error")
                }
            }else{
                // console.log("password not same")
                setErrOpen(true)
                setErrMsg("Error 403: please fill in the password")
                setErrSevere("error")
            }


          }catch(err){
            console.log(JSON.stringify(err, null, 2))
            setErrOpen(true)
            setErrMsg(JSON.stringify(err.message, null, 2))
            setErrSevere("error")
          }
    }

    return (
        <div className="profile-container" style={{display: !open && "none"}}>
            <div className="profile-modal-background" onClick={fx_close}></div>
            <div className="profile-modal-content">
                <div className="profile-title-modal">
                    <div className="profile-title-detail-modal">Profile Settings</div>
                </div>
                <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                <div className="profile-body-modal">
                    <div className="profile-user-information-container">
                        <div style={{fontSize: "16px", fontWeight: 600, margin:"0px 0px 8px 0px"}}>User Information</div>
                        <div className="container-input">
                            <div className="input-container-profile">
                                <div className="input-title-profile">First Name <p style={{color:"red", margin:"0"}}>*</p></div>
                                <input type="text" className={`${isHovered.id ? "isHovered-border-profile-container" : "text-box-profile-container"}`} placeholder="-" name="firstName" value={userFirst.firstName} onChange={(e) => setUserFirst((prev) => ({...prev, firstName:e.target.value}) )}  onMouseEnter={() => setIsHovered((current) => ({...current, firstName: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, firstName: false}))}/>
                            </div>
                            <div className="input-container-profile">
                                <div className="input-title-profile">Last Name <p style={{color:"red", margin:"0"}}>*</p></div>
                                <input type="text" className={`${isHovered.id ? "isHovered-border-profile-container" : "text-box-profile-container"}`} placeholder="-" name="lastName" value={userFirst.lastName} onChange={(e) => setUserFirst((prev) => ({...prev, lastName: e.target.value}))}  onMouseEnter={() => setIsHovered((current) => ({...current, lastName: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, lastName: false}))}/>
                            </div>
                        </div>
                        <div className="btn-container-userProfile" onClick={(e) => updateFirstlastname(e)}>
                            <div className="btn-save-profile">Save</div>
                        </div>
                    {editLoading ? (<div style={{position: "absolute", left: "45%"}}><Loaders /></div>) : null}
                    </div>
                    {
                        chgPassword ? (
                        <div className="profile-input-password-container">
                            <div className="password-container-title">
                                <div style={{fontSize: "16px", fontWeight: 600, margin:"0px 0px 0px 0px"}}>Password</div>
                                <div className="input-password-title">Change the password for your account</div>
                            </div>
                            <div className="container-input">
                                <div className="input-container-profile-reset-password">
                                    <div className="input-title-profile">Current Password</div>
                                    <input type={inputType.current_password} className={`${isHovered.id ? "isHovered-border-profile-container" : "text-box-profile-container"}`} placeholder="-" name="currentPassword" value={passField.currentPassword} onChange={(e) => onChange(e)}   onMouseEnter={() => setIsHovered((current) => ({...current, current_password: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, current_password: false}))}/>
                                    {togglePassword.current_password ? <div className="visibility-password" onClick={toggleOnOffCurPassword}><img loading="lazy" src={VisibleOffIcon} alt="icon" style={{height: "24px", width: "24px"}}/></div>: <div className="visibility-password" onClick={toggleOnOffCurPassword}><img loading="lazy" src={VisibleIcon} alt="icon" style={{height: "24px", width: "24px"}}/> </div>}
                                </div>
                                <div className="input-container-profile-reset-password">
                                    <div className="input-title-profile">New Password</div>
                                    <input type={inputType.new_password} className={`${isHovered.id ? "isHovered-border-profile-container" : "text-box-profile-container"}`} placeholder="-" name="newPassword" value={passField.newPassword} onChange={(e) => onChange(e)} onMouseEnter={() => setIsHovered((current) => ({...current, new_password: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, new_password: false}))}/>
                                    {togglePassword.new_password ? <div className="visibility-password" onClick={toggleOnOffNewPassword}><img loading="lazy" src={VisibleOffIcon} alt="icon" style={{height: "24px", width: "24px"}}/></div>: <div className="visibility-password" onClick={toggleOnOffNewPassword}><img loading="lazy" src={VisibleIcon} alt="icon" style={{height: "24px", width: "24px"}}/> </div>}
                                </div>
                                <div className="input-container-profile-reset-password">
                                    <div className="input-title-profile">Confirm Password</div>
                                    <input type={inputType.confirm_password} className={`${isHovered.id ? "isHovered-border-profile-container" : "text-box-profile-container"}`} placeholder="-" name="confirmPassword" value={passField.confirmPassword} onChange={(e) => onChange(e)}  onMouseEnter={() => setIsHovered((current) => ({...current, confirm_password: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, confirm_password: false}))}/>
                                    {togglePassword.confirm_password ? <div className="visibility-password" onClick={toggleOnOffConfirmPassword}><img loading="lazy" src={VisibleOffIcon} alt="icon" style={{height: "24px", width: "24px"}}/></div>: <div className="visibility-password" onClick={toggleOnOffConfirmPassword}><img loading="lazy" src={VisibleIcon} alt="icon" style={{height: "24px", width: "24px"}}/> </div>}
                                </div>
                            </div>
                            <div className="btn-container-userProfile" onClick={(e) => updatePassword(e)}>
                                <div className="btn-save-profile">Reset Password</div>
                            </div>
                        </div>) : (
                        <div className="profile-password-container">           
                            <div className="">
                                <div style={{fontSize: "16px", fontWeight: 600, margin:"0px 0px 0px 0px"}}>Password</div>
                                <div className="input-password-title">Change the password for your account</div>
                            </div>
                            <div className="btn-password-container" onClick={(e) => changePassword(e)}>
                                <div className="btn-change-password-profile">
                                    <img loading="lazy" src={EditPasswordIcon} alt="edit" style={{height: "18px", width: "18px", margin: "0px 11px 0px 0px"}}/>
                                    Change Password
                                </div>
                            </div>
                        </div>
                        )
                    }

                    <div className="profile-agency-container">
                        <div className="">
                            <div style={{fontSize: "16px", fontWeight: 600, margin:"0px 0px 0px 0px"}}>Agencies</div>
                            <div className="input-password-title">Lists the agencies that you have access to</div>
                        </div>
                        <div className="agency-container">{agencyName}</div>
                    </div>

                </div>
            </div>
            <style>
                {
                    `.profile-container{
                        position: fixed;
                        z-index: 1300;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                    }
                    .profile-modal-background{
                        z-index: -1;
                        position: fixed;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                        background-color: rgba(0, 0, 0, 0.5);
                        -webkit-tap-highlight-color: transparent;
                    }
                    .profile-modal-content{
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        width: 788px;
                        border-radius: 4px;
                        transform: translate(-50%, -50%);
                        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
                        background: #F3F3F3;
                        display: block;
                    }
                    .profile-title-modal{
                        display:flex;
                        align-items: center;
                        position: fixed;
                        width: 100%;
                        height: 64px;
                        background: #FFFFFF;
                        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
                    }
                    .profile-body-modal{
                        padding: 24px;
                    }
                    .profile-title-detail-modal{
                        padding: 0px 0px 0px 24px;
                        font-weight: bold;
                        font-size: 24px;
                    }
                    .profile-user-information-container{
                        margin: 64px 0px 0px 0px;
                        padding: 16px;
                        background: #FFFFFF;
                        // height: 168px;
                    }
                    .profile-password-container{
                        margin: 24px 0px 0px 0px;
                        padding: 16px;
                        background: #FFFFFF;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }
                    .profile-input-password-container{
                        margin: 24px 0px 0px 0px;
                        padding: 16px;
                        background: #FFFFFF;
                    }
                    .profile-agency-container{
                        padding: 16px;
                        background: #FFFFFF;
                        margin: 24px 0px 0px 0px;
                    }
                    .container-input{
                    }
                    .isHovered-border-profile-container{
                        height: 24px;
                        outline: none;
                        border: 1px solid #b6babf;
                        border-radius: 4px;
                    }
                    .text-box-profile-container{
                        display: flex;
                        justify-content: center;
                        border-style: none;
                        border-radius: 4px;
                        outline: none;
                        height: 24px;
                    }
                    .btn-save-profile{
                        width: 213px;
                        height: 32px;
                        display: flex;
                        justify-content: center;
                        align-items:center;
                        color: #FFFFFF;
                        background: #509D91;
                        border-radius: 2px;
                        font-weight: bold;
                    }
                    .btn-container-userProfile{
                        display: flex;
                        justify-content: center;
                        cursor: pointer;
                        margin: 16px 0px 0px 0px;
                    }
                    .input-container-profile{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 250px 1fr; 
                        grid-template-rows: 1fr; 
                        gap: 0px 0px; 
                        grid-template-areas: 
                          ". ."; 
                        align-items: center;
                        margin: 8px 0px 0px 0px;
                    }
                    .input-container-profile-reset-password{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 250px 1fr 1fr; 
                        grid-template-rows: 1fr; 
                        gap: 0px 0px; 
                        grid-template-areas: 
                          ". ."; 
                        align-items: center;
                        margin: 8px 0px 0px 0px;
                    }
                    .input-title-profile{
                        font-size: 14px;
                        color: #939598;
                        display: flex;
                    }
                    .input-title-profile-password-reset{
                        font-size: 14px;
                        color: #939598;
                        display: flex;v
                    }
                    .input-password-title{
                        font-size: 14px;
                        color: #939598;
                    }
                    .btn-password-container{
                        display: flex;
                        justify-content: center;
                        cursor: pointer;
                        align-items: center;
                    }
                    .btn-change-password-profile{
                        width: 213px;
                        height: 32px;
                        display: flex;
                        justify-content: center;
                        align-items:center;
                        color: #FFFFFF;
                        background: #509D91;
                        border-radius: 2px;
                        font-weight: bold;
                        cursor: pointer;
                    }
                    .agency-container{
                        margin: 16px 0px 0px 0px;
                    }
                    .password-container-title{
                        margin: 0px 0px 16px 0px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default Modal
