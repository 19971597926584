import React, {useState, useEffect}  from 'react'
import moment from "moment-timezone";

//images
import ExportIcon from '../../Image/export.svg'
import NavigationIcon from "../../Image/chevron_right.svg"

//component
import FuelChart from './FuelChart'
import Table from './Table'
import NoReportData from '../../component/component-noreport/NoReportData'
import SnackBar from '../../config/config-snackbar/SnackBar'

const MainDetails = ({ reportData, fx_back, loading, speed, reportError }) => {

    //this state for error handling on getQuery
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")

    let csvFile = "Datetime ,Fuel_Percentages_(%),Fuel_Volume, Latitude, Longitude\r\n"

    !loading && reportData?.getFuelLogRpt !== null && reportData.getFuelLogRpt.map(x => csvFile += `${moment(x.time).format("X") || "N/A"},${parseFloat(x.fuelPercentage).toFixed(2) || "N/A"},${parseFloat(x.fuelVolume).toFixed(2) || "N/A"},${parseFloat(x.latitude).toFixed(6) || "N/A"},${parseFloat(x.longitude).toFixed(6) || "N/A"}\r\n`)

    const url = `data:text/json;charset=utf-8,${encodeURIComponent(csvFile)}`

    useEffect(() => {
        if(reportError){
            setSnOpen(true)
            setMessage("Error: Unable to retrieve data. Please try again later.")
            setSeverity("error")
        }
    }, [reportError])

    return (    
        <div>
            <div className="telemetry-table-container">
                <div className="title-log">
                    <div className="telemetry-title">
                        <div className="report-title" onClick={() => fx_back()}>Fuel log</div>
                        <div className="icon-title">
                            <img loading="lazy" src={NavigationIcon} alt="nav-icon" />
                        </div>
                        <div className="asset-title-name">
                            <div>{speed.value.name}</div>
                        </div>
                    </div>
                    <div className="export-container">
                        <img loading="lazy" src={ExportIcon} alt="export-icon" />
                        &nbsp; <a href={url} download="FuelLogReport.csv" style={{ alignItems: "center", verticalAlign: "bottom" }}>Export Log</a>
                    </div>
                </div>

                {
                    reportError ? (<SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/>):
                        !loading && reportData?.getFuelLogRpt !== null && reportData?.getFuelLogRpt.length > 0 ? (
                            <div>
                                <FuelChart data={reportData.getFuelLogRpt.map((x, i) => { return { date: new Date(x.time).getTime(), value: x.fuelPercentage } })} yAxis="Fuel Percentage (%)" speedValue={speed}/>
                                <Table reportData={reportData.getFuelLogRpt} reportError={reportError}/>
                            </div>

                        ) : (<NoReportData />)
                }


            </div>
            <style>
                {
                    `
                        .telemetry-table-container{
                            padding: 16px;
                            // display: flex;
                            // align-items: center;
                        }
                        .telemetry-title{
                            display: flex;
                            align-items: center;
                        }
                        .report-title{
                            margin: 0px 8px 0px 0px;
                            color: #333333;
                            font-size: 16px;
                            font-weight: bold;
                            letter-spacing: 0;
                            line-height: 21px;
                            cursor: pointer;
                        }
                        .icon-title{
                            margin: 0px 8px 0px 0px;
                        }
                        .asset-title-name{
                            font-size: 18px;
                            font-weight: 600;
                            letter-spacing: 0;
                            line-height: 24px;
                        }
                        .export-css{
                            color: #333333;
                            font-size: 14px;
                            letter-spacing: 0;
                            line-height: 19p
                        }
                        .export-container{
                            display: flex;
                            align-items: center;
                        }
                        .title-log{
                            display: flex;
                            justify-content: space-between;
                        }
                    `
                }
            </style>
        </div>
    )
}

export default MainDetails
