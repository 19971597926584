import React,{useState, useEffect, useContext} from 'react'
import BeaconInformation from './BeaconInformation'

import { settingFieldValue, requiredLabel } from "../../config/config-function/function";
import menuConfig from '../../config/config-menu/menu'
import {firebaseContext} from '../../config/config-firebase/FirebaseContext'
import tableConfig from "../../config/config-table/table";
import Cookies from 'js-cookie';
import { APIGetSKU } from '../../config/config-restAPI/getAPICalled';

const { beaconAdminConsole:fieldList } = tableConfig.fieldList

const Form = ({ beacon, onUserInput, skuIdDisplay, displayed, values, setValues, fx_changePage, history, selected, role, roleConfig, isAdmin, isDashboard, setMainFilter }) => {
    // console.log('roleConfig', roleConfig);
    // console.log('role', isAdmin)
    const firebaseConfig = useContext(firebaseContext)
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');
    const [apiLoading, setApiLoading] = useState(false);
    const [skuList, setSkuList] = useState([]);

    // console.log(setMainFilter);

    const onChangeTextField = (event) => {
        if (event.target.name === 'range') {
            // this convert it to integer
            const convert = parseInt(event.target.value)
            setValues({ ...values, [event.target.name]: convert })
        } else if (event.target.name === 'latitude' || event.target.name === 'longitude') {
            const convert = parseFloat(event.target.value);
            setValues({ ...values, coordinate: { ...values.coordinate, [event.target.name]: convert }  })
        }
        else {
            setValues({ ...values, [event.target.name]: event.target.value })
        }
    }
    const APICalled = async () => {
        try {
            const result = await APIGetSKU(token, setApiLoading, agencyId)
            // console.log(result);
            if (result.status === 200 || result.data.status === 200) {
                const filterBeacon = result.data.data.sku.filter(x => x.productCategory === 'Beacon')
                setSkuList(filterBeacon);
                // setSkuFeatures(result.data.data.features);
                setApiLoading(false);
            } else {
                setApiLoading(false);
            }
        } catch(err) {
            setApiLoading(false);
        }
    }
    
    useEffect(() => {
        if (token) {
            APICalled();
        }
    }, [])
    

    const [isHovered, setIsHovered] = useState({
        serialNumber: false,
        SKU: false,
        range: false,
        description: false,
        agency: false,
        manufacturer: false,
        battVolt: false,
        battLvl: false,
        lastUpdate: false,
        locationName: false,
        longitude: false,
        latitude: false,
        locationNote: false,
        macAddress: false,
    })

    // console.log(beacon);

    const onChangePage = () =>{
        // // console.log(valAgency)
        setMainFilter({uuid: beacon._id})
        fx_changePage(menuConfig.console[1].key, menuConfig.console[2].children[1].key)
        history.push("/console/agency_mgmt/agencies")
    }

    return (
        <div className="beacon-main-container">
            <div className="first-box-container">
                <BeaconInformation
                    setIsHovered={setIsHovered}
                    isHovered={isHovered}
                    displayed={displayed}
                    onChangePage={onChangePage}
                    values={values}
                    skuList={skuList}
                    onChangeTextField={onChangeTextField}
                    fieldList={fieldList}
                    requiredLabel={requiredLabel}
                    selected={beacon}
                    isAdmin={isAdmin}
                    isDashboard={isDashboard}
                    firebaseConfig={firebaseConfig}
                    skuIdDisplay={skuIdDisplay}
                />
            </div>
            {
                isDashboard && (
                <div className="second-box-container">
                    <div className="beacon-title" />
                    <div className="becons-info-details">{firebaseConfig.getValueByKey("client_beacon_information")}</div>
                </div>                   
                )
            }
            <style jsx>
                {
                    `
                        /* Chrome, Safari, Edge, Opera */
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                        }
                        

                        /* Firefox */
                        input[type=number] {
                        -moz-appearance: textfield;
                        }
                        .beacon-main-container{
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            grid-template-rows: 1fr;
                            gap: 0px 0px;
                            grid-template-areas: ". .";
                        }
                        .first-box-container{
                        }
                        .becons-info-details{
                            color: #939598;
                            font-size: 12px;
                            letter-spacing: 0;
                            line-height: 16px;
                            margin: 0px 0px 0px 32px;
                        }
                        .second-box-container{
                            border-radius: 4px;
                            background-color: #FFFFFF;
                            padding: 16px;
                            margin: 0px 0px 24px 0px;
                        }
                        .beacon-title{
                            height: 32px;
                            width: 100%;
                            color: #333333;
                            font-size: 16px;
                            font-weight: 600;
                            letter-spacing: 0;
                        }
                        .beacon-details-input{
                            display: grid;
                            grid-template-columns: 200px 2fr;
                            grid-template-rows: 1fr;
                            gap: 0px 0px;
                            align-items: center;
                            grid-template-areas:
                              ". .";
                            white-space: nowrap;
                            overflow: hidden;
                        }
                        .isHovered-border{
                            height: 25px;
                            outline: none;
                            border: 1px solid #b6babf;
                            border-radius: 4px;
                        }
                        .text-box{
                            display: flex;
                            align-items: center;
                            border-style: none;
                            height: 25px;
                            border-radius: 4px;
                            outline: none;
                        }
                        .isHovered-selection{
                            background-color: rgba(182, 186, 191, 0.2);
                            border-radius: 4px;
                            border-style:none;
                            height: 24px;
                            width: 200px;
                            margin: 0px;
                            outline: none;
                        }
                        .selection-board{
                            border-style:none;
                            background-color: #FFF;
                            height: 24px;
                            width: 200px;
                            margin: 0px;
                            outline: none;
                            border-radius: 4px;
                        }
                        .input-input{
                            height: 34px;
                            display: flex;
                            align-items: center;
                            color: #939598;
                        }
                        .link{
                            color: blue;
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    `
                }
            </style>
        </div>
    )
}

export default Form
