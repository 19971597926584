import axios from 'axios';

const url = process.env.REACT_APP_RESTAPI;
const xApiKey = process.env.REACT_APP_XAPIKEY;

// getAgencies
export const APIGetAgencies = async (token, setLoading, agencyId, filter, filterParams) => {
    // console.log(filter);
    setLoading(true);
    const result = await axios.get(`${url}/agencies`, {
        headers: {
            'Authorization': token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: {
            uuid: filter && filter.uuid ? filter.uuid : '',
            id: filterParams && filterParams.id ? filterParams.id : '',
            name: filterParams && filterParams.name ? filterParams.name : '',
            isActive: filterParams && filterParams.isActive && filterParams.isActive,
            subcriptionFrom: filterParams && filterParams.subscriptionFrom ? filterParams.subcriptionFrom : '',
            subscriptionTo: filterParams && filterParams.subscriptionTo ? filterParams.subscriptionTo : '',
        }
    }).catch((error) => error);
    return result;
}

export const APIGetAvailableDevices = async (token, setLoading, agencyId, filter) => {
    // console.log(filter);
    setLoading(true);
    const result = await axios.get(`${url}/availableDevices`, {
        headers: {
            'Authorization': token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: {
            deviceType: 'ALL'
        }
    }).catch((error) => error);
    return result;
}

export const APIGetAvailableBeacons = async (token, setLoading, agencyId, filter) => {
    // console.log(filter);
    setLoading(true);
    const result = await axios.get(`${url}/availableBeacons`, {
        headers: {
            'Authorization': token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: {
            deviceType: 'ALL'
        }
    }).catch((error) => error);
    return result;
}

//getSKU
export const APIGetSKU = async (token, setLoading, agencyId, filterFinal) => {
    setLoading(true);
    const result = await axios.get(`${url}/skus`, {
        headers: {
            'Authorization': token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        }, params: filterFinal
    }).catch((error) => error);
    return result;
}

export const APIGetBeacons = async (token, setLoading, agencyId, filterFinal) => {
    setLoading(true);
    const result = await axios.get(`${url}/beacons`, {
        headers: {
            'Authorization': token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        }, params: filterFinal
    }).catch((error) => error);
    return result;
}

export const APIGetSearchAssetnAssetGroup = async (token, setLoading, agencyId, searchType, searchKey, selectedIds) => {
    setLoading(true);
    const result = await axios.get(`${url}/searchAssetsnGroups`, {
        headers: {
            'Authorization': token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: {
            searchType: searchType,
            searchKey: searchKey,
            selectedIds: selectedIds,
        }
    }).catch((error) => error);
    return result;
}

export const APIGetPayloadByGroup = async (token, setLoading, agencyId, groupUuid) => {
    setLoading(true);
    const result = await axios.get(`${url}/payloadByGroup`, {
        headers: {
            'Authorization': token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: {
            groupUuid: groupUuid
        }
    }).catch((error) => error);
    return result;
}

export const APIGetGeofence = async (token, setLoading, agencyId, geofenceKey, geofenceUUID) => {
    setLoading(true);
    const result = await axios.get(`${url}/geofences`, {
        headers: {
            'Authorization': token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: {
            key: geofenceKey,
            uuid: geofenceUUID,
        }
    }).catch((error) => error);
    return result
}

export const APIGetChannel = async (token, setLoading, agencyId, filterName, filterType) => {
    setLoading(true);
    const result = await axios.get(`${url}/channels`, {
        headers: {
            'Authorization': token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: {
            name: filterName,
            type: filterType
        }
    }).catch((error) => error);
    return result;
}


export const APIGetUser = async (token, setLoading, agencyId, chanType, sKey, operation) => {
    setLoading(true);
    const result = await axios.get(`${url}/users`, {
        headers: {
            'Authorization': token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: {
            channelType: chanType,
            searchKey: sKey,
            operation: operation,
        }
    }).catch((error) => error);
    return result;
}

export const APIGetActiveAsset = async (token, setLoading, agencyId, groupName) => {
    setLoading(true);
    const result = await axios.get(`${url}/activeAssets`, {
        headers: {
            'Authorization': token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: {
            groupName: groupName,
        }
    }).catch((error) => error);
    return result;
}