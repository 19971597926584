import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const SnackBar = ({open, setOpen, severity, message}) => {
 
    //this is to position the snackbar
    const vertical = "top"
    const horizontal = "center"

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }  
        setOpen(false);
      };

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical, horizontal}}>
            {
            severity === "success" ? (
                <Alert onClose={handleClose} severity="success">{message}</Alert>
                ) : severity === "error" ? (
                <Alert onClose={handleClose} severity="error">{message}</Alert>
            ) : severity === "warning" ? (
                <Alert onClose={handleClose} severity="warning">{message}</Alert>
            ): null
            }
            </Snackbar>
        </div>
    )
}

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default SnackBar
