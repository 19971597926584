import React, {useState, useEffect, useRef} from 'react'

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, makeStyles } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import AssetGroupIcon from '../../Image/dark-asset-group.svg'
import AssetIcon from '../../Image/dark-asset.svg'
import GreySearchIcon from '../../Image/grey_search.svg'

import AssetSearchListAdd from './AssetSearchListAdd'

const autoComplete = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            clearIndicator: {
                // position: "absolute",
                // bottom: "5px",
                // right: "25px"
                "&:hover":{
                    backgroundColor: "#FFFFFF",
                }
            },
            endAdornment:{
                top: "0px",
                height: "100%",
                display: "flex",
                alignItems: "center"
            },
            popupIndicator:{
                "&:hover":{
                    backgroundColor: "#FFFFFF",
                },
            }
        
        },
        MuiIconButton:{
            label:{
                height:"24px",
                width: "24px",
                "&:hover":{
                    backgroundColor: "rgba(0,0,0,0.03)",
                    borderRadius: "5px",
                },
            }
        }
    }
});

const AssetSearchAdd = ({dataTwo, APICalled, setOnKey, onSelected, setOnSelected }) => {
    const [viewSearchResult, setViewSearchResult] = useState(false)
    const [searchInput, setSearchInput] = useState(null)
    const [viewList, setViewList] = useState(true)
    
    const onEnterKey = (event) => {
        if(event.key === "Enter"){
            setOnKey(event.target.value)
            setViewSearchResult(true)
            setSearchInput("")           
        }
    }

    const onUserInput = (e, value) => {
        if (value) {
            let assetValue = [...onSelected];
            assetValue.push(value.uuid)  
            const convertAsset = assetValue.toString();
            // console.log(convertAsset);
            setOnSelected(assetValue)  
            setSearchInput(value)
            APICalled('WITH_SELECTED', '', convertAsset);
        } else {
            setOnSelected({})
            setSearchInput('')
        }
    }

    const wrapperRef = useRef(null);
    const handleClick = (e) => {
        if (wrapperRef.current.contains(e.target)) {
            // inside click
            return;
          }
          // outside click
          setViewSearchResult(false);
    }



    useEffect(() => {
        document.addEventListener("mousedown", handleClick);            
        return () => {
            document.removeEventListener("mousedown", handleClick);
        }
    }, [])


    return (
        <div ref={wrapperRef} style={{width: '100%', position: 'relative'}}>
            <ThemeProvider theme={autoComplete}>
                    <Autocomplete
                        {...dataTwo}
                        name="asset_name"
                        id="combo-box-demo"
                        loading={true}
                        autoHighlight={true}
                        onChange={(e, value) => onUserInput(e, value)}
                        options={dataTwo.length > 0 && dataTwo.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                        groupBy={(option) => option.firstLetter}
                        getOptionLabel={(option) => option.name}
                        renderOption={option => {
                            return <div className="autocomplete-container">
                                {
                                    option.type === "asset" ? <img loading="lazy" style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} src={AssetIcon} alt="asset-icon" /> : option.type === "group" ? <img loading="lazy" style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} src={AssetGroupIcon} alt="asset-icon" /> : null
                                }
                                <div className="autocomplete-name">{option.name}</div>
                                <div className="autocomplete-pair-name">{option.length > 0 && option.paired_assets.length > 0 && ` ⇋ ${option.paired_assets[0].name}`}</div>
                            </div>
                        }}
                        renderInput={(params) =>                          
                            <div style={{position: "relative"}} ref={params.InputProps.ref}>
                            {
    
                               !params.inputProps.value && (
                                <span 
                                    style={{
                                        position: "absolute",
                                        transform: "translate(1%, 90%)",
                                        marginLeft: "5px"
                                    }}
                                    >
                                <img loading="lazy" style={{ height: "20px", width: "20px" }} src={GreySearchIcon} alt="grey-icon" />
                            </span>
                                )
                            }
                            <TextField 
                               {...params} 
                               name="asset_name" 
                               placeholder="Asset / Asset Group" 
                               variant="outlined" 
                               inputProps= {!params.inputProps.value ? {
                                ...params.inputProps,
                                style: { paddingLeft: "27px", position: "relative", top:"1px" } 
                             }: {
                                 ...params.inputProps
                             }}
                            />
                        </div>  
                            
                        }
                    />
                </ThemeProvider>
            <style jsx>
                {
                    `
                    .geo-add-asset{
                        position: relative;
                        width: 100%;
                        height: 33px;
                        box-shadow: 0px 1px 3px 0px #00000020;
                        border: 0px solid #ffffff;
                        border-radius: 4px;
                        font-size: 14px;
                        background-color: white;
                        background-position: 8px 8px; 
                        background-repeat: no-repeat;
                        padding: 0px;
                        padding: 0px 0px 0px 16px;
                        margin: 8px 0px 0px 0px;
                        outline: none;
                        -webkit-transition: width 0.4s ease-in-out;
                        transition: width 0.4s ease-in-out;
                        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                        box-sizing: border-box;         /* Opera/IE 8+ */    
                    }
                    .autocomplete-container{
                        display: flex;
                        align-items: center;
                    }
                    .autocomplete-name{
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 130px;
                    }
                    .autocomplete-pair-name{
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 150px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default AssetSearchAdd
