import React, {useState} from 'react'
import FuelIcons from '../../Image/fuel_tank_new.svg'
import tableConfig from '../../config/config-table/table'
import GreyInfoIcon from '../../Image/grey-info.svg'

import { Tooltip } from '@material-ui/core';


const {messagePayloadInterval} = tableConfig.cellularConfiguration;
const IOTConfiguration = ({setIsHovered, isHovered, displayed, setCellularConf, cellularConf, cellularSubmit, device}) => {


    const handleChangeSelection = (name, value) => {
        setCellularConf({...cellularConf, [name]:value})
}

    return (
        <div className="cellular-container">
        <div className="cellular-title-container">
            <p className="cellular-title">Device Configuration</p>
            <button className={`${isHovered.apply ? "isHovered-cellular-button-apply" : "cellular-button-apply"}`} onMouseEnter={() => setIsHovered((current) => ({...current, apply: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, apply: false}))} onClick={(e) => cellularSubmit(e)}>Apply</button>      
        </div>
        <div className="cellular-detail-container">
            <div>
                <div>
                    <div className="cellular-input-title">Messaging</div>
                    <div className="cellular-input-select">
                        <div className="cellular-lable">Messaging Interval <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                        <select className={`${isHovered.msgInterval ? "isHovered-cellular-selection" : "cellular-selection"}`} value={cellularConf.payloadInterval}  name="payloadInterval" onChange={e => handleChangeSelection(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, msgInterval: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, msgInterval: false}))}>
                            {
                                messagePayloadInterval.map(x => {
                                    return(
                                        <option value={x.value}>{x.text}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="cellular-input-select">
                        <div className="cellular-lable">Messaging Interval (Ignition Off) <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                        <select className={`${isHovered.msgIntervalOff ? "isHovered-cellular-selection" : "cellular-selection"}`} value={cellularConf.payloadIntervalIgnitionOff}  name="payloadIntervalIgnitionOff" onChange={e => handleChangeSelection(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, msgIntervalOff: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, msgIntervalOff: false}))}>
                            {
                                messagePayloadInterval.map(x => {
                                    return(
                                        <option value={x.value}>{x.text}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="cellular-input-select">
                        <div className="cellular-lable">Offline Message Logging <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                        <select className={`${isHovered.offlineMsgLog ? "isHovered-cellular-selection" : "cellular-selection"}`} value={cellularConf.payloadIntervalOffline}  name="payloadIntervalOffline" onChange={e => handleChangeSelection(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, offlineMsgLog: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, offlineMsgLog: false}))}>
                            {
                                messagePayloadInterval.map(x => {
                                    return(
                                        <option value={x.value}>{x.text}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                {
                    cellularConf.extSense_Fuel ? <div className="divider-container"></div> : null
                }
                {
                    cellularConf.extSense_Fuel ? <div className="fuel-sensor-container-with-image">
                    <div>
                        <div>
                            <div className="cellular-input-title">Fuel Sensor Integration</div>
                            <div className="cellular-input-select">
                                <div className="cellular-lable">Fuel Density Ratio <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                                <label className="labeling-fuel">1.0</label>
                            </div> 
                        </div>
                
                        <div>
                            <div className="cellular-input-title">Fuel Tank Calibration</div>
                            <div className="cellular-input-select">
                                <div className="cellular-lable">Length (L, m <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" />)</div>
                                <label className="labeling-fuel">{device[0] && device[0].fuel_sensor && device[0].fuel_sensor.length}</label>
                            </div>
                            <div className="cellular-input-select">
                                <div className="cellular-lable">Width (W, m) <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                                <label className="labeling-fuel">{device[0] && device[0].fuel_sensor && device[0].fuel_sensor.width}</label>
                            </div>
                            <div className="cellular-input-select">
                                <div className="cellular-lable">Height (H, m) <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                                <label className="labeling-fuel">{device[0] && device[0].fuel_sensor && device[0].fuel_sensor.height}</label>
                            </div>
                        </div>
                    

                    </div>
                    
                        <div className="image-fuel-sensor">
                            <img loading="lazy" src={FuelIcons} alt="fuel" style={{height:"152px", width:"152px"}}/>
                            <div className="text-icon-volumn-one">
                                <div className="text-key-in">Volumn(㎥):</div>
                                <label className="labeling-fuel">
                                    {
                                    device[0] && device[0].fuel_sensor &&  device[0].fuel_sensor.length && device[0].fuel_sensor.width && device[0].fuel_sensor.height ? parseFloat(device[0].fuel_sensor.length * device[0].fuel_sensor.width * device[0].fuel_sensor.height).toFixed(4) : "-"
                                    }
                                </label>
                            </div>
                            <div className="text-icon-volumn-two">
                                <div className="text-key-in">Volumn(l):</div>
                                <label className="labeling-fuel">512.00
                                    {
                                    device[0] && device[0].fuel_sensor &&  device[0].fuel_sensor.length && device[0].fuel_sensor.width && device[0].fuel_sensor.height ?  (parseFloat(device[0].fuel_sensor.length * device[0].fuel_sensor.width * device[0].fuel_sensor.height) * 1000).toFixed(2) : "-"
                                    }
                                </label>
                            </div>
                    </div> 

                </div> : null
                }

            </div>
        </div>
            <style>
            {
                `.cellular-container{
                    padding: 16px;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    margin: 0px 0px 24px 0px;
                }
                .cellular-title-container{
                    display: flex;
                    justify-content: space-between;
                    margin: 0px 0px 5px 0px;
                }
                .cellular-title{
                    margin: 0;
                    color: #333333;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 21px;
                    font-weight: 600;
                }
                .cellular-button-apply{
                    box-sizing: border-box;
                    height: 33px;
                    width: 161px;
                    border: 1px solid #509D91;
                    border-radius: 2px;
                    color: #509D91;
                    cursor: pointer;
                    outline: none;
                }
                .isHovered-cellular-button-apply{
                    box-sizing: border-box;
                    height: 33px;
                    width: 161px;
                    border: 1px solid #498f84;
                    border-radius: 2px;
                    color: #498f84;
                    cursor: pointer;
                    font-weight: 600;
                    outline: none;
                    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                }
                .cellular-detail-container{
                    margin: 0px 0px 8px 0px;
                }
                .cellular-input-select{
                    display: grid;
                    grid-template-columns: 250px 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    align-items: center;
                    margin: 0px 0px 8px 0px;
                }
                .cellular-input-title{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 34px;
                    color: #939598;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                    font-weight: 600;
                }
                .sigfox-description{
                    margin: 8px 0px 0px 8px;
                    color: #939598;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                }
                .sigfox-configuration-container{
                    display: flex;
                    align-items: center;
                }
                .cellular-selection{
                    width: 220px;
                    height: 24px;
                    border-style:none;
                    background-color: #FFF;
                    border-radius: 4px;
                    outline: none;
                }
                .isHovered-cellular-selection{
                    width: 220px;
                    height: 24px;
                    border-style:none;
                    background-color: #FFF;
                    border-radius: 4px;
                    outline: none;
                    background-color: rgba(182, 186, 191, 0.2);
                }
                .cellular-lable{
                    display: flex;
                    align-items: center;
                    font-weight: normal;
                    font-size: 14px;
                    height: 25px;
                    color: #939598;
                }
                .divider-container{
                    border: 1px solid #DDDDDD;
                    margin: 8px 0px 8px 0px;
                }
                .fuel-sensor-container-with-image{
                    display: grid; 
                    grid-auto-columns: 1fr; 
                    grid-template-columns: 1fr 1fr; 
                    grid-template-rows: 1fr; 
                    gap: 0px 8px; 
                    grid-template-areas: 
                      ". ."; 
                }
                .image-fuel-sensor{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items:center;
                }
                .cellular-text-box{
                    display: flex;
                    width:100%;
                    align-items: center;
                    border-style: none;
                    height: 25px;
                    border-radius: 4px;
                    outline: none;
                }
                .isHovered-border-textbox{
                    height:24px;
                    width:100%;
                    outline: none;
                    border: 1px solid #b6babf;
                    border-radius: 4px;
                }
                .labeling-fuel{
                    font-size: 13px;
                    line-height: 24px;
                    color: #939598;
                }
                `
            }
        </style>
        </div>
    )
}

export default IOTConfiguration
