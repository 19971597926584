import { isEmptyArray } from "../config-function/function"
import moment from 'moment-timezone'
import { Base64 } from 'js-base64'

const tz = moment.tz.guess()
moment.tz.setDefault(tz)


export const ASSET_REDUCER = (asset) => {
    return asset && {
        uuid: asset.uuid,
        name: asset.name,
        type: asset.type,
        manufacturer: asset.manufacturer,
        model: asset.model,
        description: asset.description,
        add_device: asset.add_devices ? asset.add_devices : [],
        remove_device: asset.remove_devices ? asset.remove_devices : [],
        pair_asset: asset.pair_asset ? asset.pair_asset : {},
        unpair_asset: asset.unpair_asset ? asset.unpair_asset : {}
    }
}

export const ASSET_GROUP_REDUCER = (asset_group) => {
    return asset_group && {
        uuid: asset_group.uuid,
        name: asset_group.name,
        description: asset_group.description,
        add_asset: asset_group.add_asset ? asset_group.add_asset.map(x => { return { uuid: x.uuid } }) : [],
        remove_asset: asset_group.remove_asset ? asset_group.remove_asset.map(x => { return { uuid: x.uuid } }) : []
    }
}

export const FIRMWARE_REDUCER = (firmware) => {

    return firmware && {
        uuid: firmware.uuid,
        version: firmware.version,
        release_date: firmware.release_date ? moment(firmware.release_date).tz(tz).format() : null,
        board_class: firmware.board_class,
        git_SHA: firmware.git_SHA,
        confluence_link: firmware.confluence_link,
        release_notes: firmware.release_notes,
    }
}

export const BATCH_REDUCER = (batch) => {
    return batch && {
        uuid: batch.uuid,
        id: batch.id,
        manufacturing_date: batch.manufacturing_date,
        name: batch.name,
        // uuid: batch.uuid,
    }
}

export const MANUFACTURER_REDUCER = (mfr) => {
    return mfr && {
        uuid: mfr.uuid,
        ID: mfr.id,
        name: mfr.name,
        type: mfr.type,
        url: mfr.url,
        contact_number: mfr.contact_number,
        country_of_origin: mfr.country_of_origin
    }
}

export const COMPONENT_REDUCER = (component) => {
    return component && {
        uuid: component.uuid,
        name: component.name,
        type: component.type,
        manufacturer_part_number: component.manufacturer_part_number,
        manufacturer: component.manufacturer ? { uuid: component.manufacturer.uuid } : component.mfr_uuid ? { uuid: component.mfr_uuid } : { uuid: "" }
    }
}

export const SCHEMATIC_REDUCER = (schematic) => {
    return schematic && {
        uuid: schematic.uuid,
        drawing_reference_id: schematic.drawing_reference_id,
        revision: schematic.revision,
        description: schematic.description
    }
}

export const SKU_REDUCER = (sku, assemblies, feature, featureRemove) => {
    console.log('reducer', sku);
    return sku && {
        uuid: sku.uuid,
        id:  sku.productModel === "Smart_Lock" || sku.productModel === 'Teltonika' ? sku.id : `${sku.manufacturer}${sku.product}${sku.version}${sku.revision}`,
        manufacturer: sku.manufacturer,
        version: sku.version,
        productModel: sku.productModel,
        productCategory: sku.productCategory,
        product: sku.product,
        projectName: sku.projectName,
        revision: sku.revision,
        features: sku.features,
        // feature_add: sku.feature_add,
        assemblies: Array.isArray(assemblies) && !isEmptyArray(assemblies) ? assemblies.map(x => { return { uuid: x.uuid, boardClass: x.boardClass, assemblyId: x.assemblyId, schematicId: x.schematicId } }) : [],
    }
}

export const ASSEMBLY_REDUCER = (assembly, componentData) => {
    // console.log(assembly);
    return assembly && {
        uuid: assembly.assembly_uuid,
        reference_id: assembly.reference_id,
        revision: assembly.revision,
        board_dimensions: assembly.board_dimensions,
        description: assembly.description,
        schematic: Array.isArray(assembly.schematic) ? { uuid: assembly.schematic[0].uuid } : assembly.schematic ? { uuid: assembly.schematic.uuid } : { uuid: assembly.uuid },
        components: componentData && componentData.map(data => { return { uuid: data.uuid } })
    }
}

export const DEVICE_REDUCER = (device) => {
    return {
        uuid: device.uuid,
        sn: device.sn,
        description: device.description,
        fuel_sensor: device.fuel_sensor,
        SKU: device && device.SKU && device.SKU.uuid ? {uuid: device.SKU.uuid} : {},
        add_features: device.add_features,
        remove_features: device.remove_features,
        boards: device.assembly
    }
}
export const UPDATE_SIGFOX_REDUCER = (device, id) => {
    return device && {
        deviceId: id,
        payloadInterval: device.payloadInterval,
        payloadIntervalMotion: device.payloadIntervalMotion,
        gpsSeekPeriod: device.gpsSeekPeriod,
        downlinkFrequency: device.downlinkFrequency,
        variableInterval: device.variableInterval,
        revertDefault: device.revertDefault
    }
}


export const UPDATE_CELLULAR_REDUCER = (device, id) => {
    return device && {
        deviceId: id,
        ignitionMode: device.ignitionMode === "true" ? true : false,
        payloadInterval: parseInt(device.payloadInterval),
        payloadIntervalIgnitionOff: parseInt(device.payloadIntervalIgnitionOff),
        payloadIntervalOffline: parseInt(device.payloadIntervalOffline),
        telitKeepAlive: parseInt(device.telitKeepAlive),
        rat: parseInt(device.rat),
        extSense_Fuel: device.extSense_Fuel,
        FuelSensingRatio: device.FuelSensingRatio,
        extSense_Vibration: device.extSense_Vibration
    }
}
export const AGENCY_REDUCER = (agency, code, selectedTimezone, staticUser, displayed) => {
    // console.log('reducer', agency)
    //this is for phone number and country code
    let codeLength = code && code.length
    let number = agency && agency.contactNumber ? agency.contactNumber.substring(codeLength) : null
    return {
        uuid: agency.uuid,
        id: agency.id,
        name: agency.name,
        contactNumber: isNaN(parseInt(number)) ? null : parseInt(number),
        contactCode: isNaN(parseInt(code)) ? null : parseInt(code),
        timezone: selectedTimezone.value,
        address: agency.address,
        billing: agency.billing,
        // devices: agency.devices_add,
        devicesAdd: agency.devices_add,
        devicesRemove: agency.devices_remove,
        beaconsAdd: agency.beacon_add,
        beaconsRemove: agency.beacon_remove,
        user: displayed ? staticUser !== null ? null : agency && agency.user && {userId:agency.user.uuid, email: agency.user.email, firstName: agency.user.firstName, lastName: agency.user.lastName} : agency && agency.user && {userId:agency.user.uuid, email: agency.user.email, firstName: agency.user.firstName, lastName: agency.user.lastName},
        sigfoxDeviceTypeId: agency.sigfoxDeviceTypeId,
        // devices: deviceData && deviceData.map(data => {return { uuid: data.uuid, sigfox_id: data.sigfox_id }})
    }
}

export const CHANNEL_REDUCER = (channel) => {
        return {
            uuid: channel.uuid,
            name: channel.name,
            type: channel.type,
            notifications: channel.notification,
            userList: channel && channel.userList && channel.userList.map(x => { return { uuid: x.uuid } })
        }
}

export const DRIVER_REDUCER = (driver) => {
    return {
        uuid: driver.uuid,
        firstName: driver.firstName,
        lastName: driver.lastName,
        driverId: driver.driverId,
    }
}

export const USER_REDUCER = (user) => {
      //this is for phone number and country code
    let cCode = user && user.telegram && user.telegram.contactCode
    let cCodele = cCode && cCode.length
    let numner = user && user.telegram && user.telegram.contactNo && user.telegram.contactNo.substring(cCodele)

    let intCode = cCode && isNaN(parseInt(cCode)) ? null : parseInt(cCode) 
    let intNo = numner && isNaN(parseInt(numner)) ? null : parseInt(numner)

    if(user && user.privileges && user.privileges.role !== "Notifications"){
        return {
            userId: user.uuid,
            email: user && user.email && Base64.encode(user.email),
            firstName: user.firstName,
            lastName: user.lastName,
            privileges: user.privileges,
            telegram: {username: user?.telegram?.username, contactCode: isNaN(intCode) ? 0 : intCode, contactNo: isNaN(intNo) ? 0 : intNo }
        }
    }else{
        return {
            userId: user.uuid,
            email: user && user.email && Base64.encode(user.email),
            firstName: user.firstName,
            lastName: user.lastName,
            privileges: user.privileges,
            telegram: {username: user?.telegram?.username, contactCode: isNaN(intCode) ? 0 : intCode, contactNo: isNaN(intNo) ? 0 : intNo }
        }
    }

}

