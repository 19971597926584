import React, {useState} from 'react'
import { Icon } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const AgencyAdmin = ({description, requiredLabel, userAdmistration, settingUserAdmin, displayed, staticUserAdministration, fieldList, setIsHovered, isHovered}) => {
    const [togglePassword, setTogglePassword] = useState(false)
    const [inputType, setInputType] = useState('password')

    const toggleOnOffPassword = () => {
        if(inputType === 'password'){
            setInputType('text')
            setTogglePassword(true)
        }else{
            setInputType('password')
            setTogglePassword(false)
        }
    }
    return (
        <div className="agency-administration">
            <div className="box-info">
                <div className="title">Agency Administrator</div>
                <div className="title-detail">{description}</div>
            </div>
            <div className="input-information">
                <div className="details-input-admin">
                    <div className="input-input">Email <p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.email.required)}</p> </div>
                    <input type="email" className={`${isHovered.email ? "isHovered-border" : "text-box"}`} placeholder="-" name="email" value={userAdmistration.email} onChange={(e) => settingUserAdmin(e.target.name, e.target.value)} disabled={displayed ? staticUserAdministration === ""  ? false : true : false} onMouseEnter={() => setIsHovered((current) => ({...current, email: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, email: false}))}/>
                    <div style={{height:"24px", width:"24px"}}></div>
                </div>
                {/* <div className="details-input-admin" >
                    <div className="input-input">Login Password <p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.password.required)}</p></div>
                    <input id="passwordInput" type={inputType} className={`${isHovered.login_password ? "isHovered-border" : "text-box"}`} placeholder="-" autocomplete="on" name="password" value={userAdmistration.password}  onChange={(e) => settingUserAdmin(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, login_password: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, login_password: false}))}/>
                    {togglePassword ? <div className="visibility-password" onClick={toggleOnOffPassword}><VisibilityOffIcon /></div>: <div className="visibility-password" onClick={toggleOnOffPassword}><VisibilityIcon /> </div>}
                </div> */}
                {/* <div className="details-input-admin" >
                    <div className="input-input">First Name <p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.firstName.required)}</p></div>
                    <input type="text" className={`${isHovered.firstName ? "isHovered-border" : "text-box"}`} placeholder="-" name="firstName" value={userAdmistration.firstName}  onChange={(e) => settingUserAdmin(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, firstName: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, firstName: false}))}/>

                </div>
                <div className="details-input-admin" >
                    <div className="input-input">Last Name<p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.lastName.required)}</p></div>
                    <input type="text" className={`${isHovered.lastName ? "isHovered-border" : "text-box"}`} placeholder="-" name="lastName" value={userAdmistration.lastName}  onChange={(e) => settingUserAdmin(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, lastName: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, lastName: false}))}/>
                </div> */}
            </div>
      </div>
    )
}

export default AgencyAdmin
