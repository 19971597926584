import {WebMercatorViewport} from 'react-map-gl';
import { isEmptyArray } from '../../config/config-function/function';

export const getFitboundPoint = (points) => {
    //   console.log(points)

      const filterPoints = points.filter(n => n && n.length !== 0)

      if(!isEmptyArray(filterPoints)){
          const pointsLong = filterPoints.map(point => point.geometry.coordinates[0])
          const pointsLat = filterPoints.map(point =>  point.geometry.coordinates[1])            
          const cornersLongLat = [
              [(Math.min.apply( null,pointsLong)), (Math.min.apply(null, pointsLat))],
              [(Math.max.apply(null, pointsLong)), (Math.max.apply(null, pointsLat))]
          ]
          const viewport = new WebMercatorViewport({ width: 800, height: 600 })

        //   console.log(cornersLongLat)
            
          const checkCorners = (cornerLongLat) => {
              if(Array.isArray(points) && points.length > 0){
                  return isFinite(cornerLongLat[0][0]) && isFinite(cornerLongLat[0][1]) && isFinite(cornerLongLat[1][0]) && isFinite(cornerLongLat[1][1])
              }         
          }
          if(checkCorners(cornersLongLat)){
              if(filterPoints.length > 1){
                const { longitude, latitude, zoom } = viewport.fitBounds(cornersLongLat, { padding: 200}) 
                return { longitude, latitude, zoom }  
              }else{
                const { longitude, latitude, zoom } = viewport.fitBounds(cornersLongLat, { padding: 200, maxZoom: 13}) 
                return { longitude, latitude, zoom }  
              }
      
          }
      }
      else{
          return { longitude: 101.6869, latitude: 3.1390, zoom:10 }
      }
}
