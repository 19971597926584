import React, {useState, useRef, useEffect} from 'react'
import { useQuery } from "@apollo/react-hooks";

//material UI
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//image
// import LeftButton from "../../Image/chevron_left.svg"
// import RightButton from "../../Image/chevron_right.svg"
import DarkNavigationDown from "../../Image/dark_navigation_down.svg"
import SearchIcon from "../../Image/search.svg"
import LightCloseIcon from '../../Image/light_close_icon.svg'
import DropdownIcon from "../../Image/dropdown.svg";
import AddIcon from "../../Image/dark_add.svg";
// import EditIcon from "../../Image/dark_edit.svg";
// import DeleteIcon from "../../Image/dark_delete.svg";
import GreyEditIcon from '../../Image/grey_edit.svg'
import GreyDeleteIcon from '../../Image/grey_delete.svg'
import DarkUploadIcon from '../../Image/dark_upload.svg'
// import DarkDownloadIcon from '../../Image/dark_download.svg'
import DarkDownloadIcon from '../../Image/dark_firmware_manifest.svg'

//component
import { GET_DEVICES_STAT } from "../../config/config-graphql/Graphql";
import AddForm from "./AddForm"
import EditForm from "./EditForm"
import FirmwareManifest from './FirmwareManifest'
import FilterBy from "../../component/component-filterby/FilterBy"
import BulletChart from '../../component/component-bulletchart/BulletChart'
import SnackBar from '../../config/config-snackbar/SnackBar'
import BulkUpload from '../../component/component-bulk/BulkUpload'
import ModalComplete from '../../component/component-bulk/ModalComplete'

const useStyles = makeStyles((theme) => ({ img: { width: 24, height: "auto", }, }));
const OverviewDevice = ({title, data, loading, setMissingSKU, missingSKU, totalMissing, addForm, editForm, query, Cards, BarCharts, setEditValue, editValue, setEdit, edit, setFilterMain, fx_changePage, setPageInd, featureList, setFilterFinal, setDetailsPage, filterFinal={}, filterList, preFilter={}, fx_PreFilter, EnterFilter, setPreFilter, setCanUpdate, canUpdate, history, pageInd}) => {
    const classes = useStyles();
    const { data: chartData, loading: chartLoading } = useQuery(GET_DEVICES_STAT, { variables: {} });
    const chartColors = ["#546A7B", "#0D1F2D", "#9EA3B0", '#E4C3AD', "#939598", "#CADF9E", "#ABB557", "#F46036", "#FAE1DF"]
    const [add, setAdd] = useState(false);
    const [successTransaction, setSuccessTransaction] = useState(false)
    const [modalComplete, setModalComplete] = useState(false)
    const [filter, setFilter] = useState(false)
    const [filterValue, setFilterValue] = useState(Object.keys(filterFinal).length > 0 ? filterFinal : preFilter)

    const wrapperRef = useRef(null);

    //this snOpen, message and severity is for snackbar props
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")
    const [hoveredO, setIsHoveredO] = useState({
        add: false,
        filter: false,
        bulk: false,
        firmwareManifest: false
    })
    const [bulk, setBulk] = useState(false) //state for display bulk upload
    const [manifest, setManifest] = useState(false)

    //filter tags
    const filterDesign = () => {
        if(Object.keys(filterFinal).length > 0){
            return "filter-tag"
        }else{
            return "filter-no-tag"
        }
    }

    useEffect(() => {
        if(successTransaction){
            // console.log("go heresuccess transaction")
            setSnOpen(true)
            setMessage("hello")
            setSeverity("success")
        }
    }, [])
    


    //display filter count
    const filterCount = () => {
        if(filterFinal){
            if(Object.keys(filterFinal).length > 0){
            return <div style={{display:"flex", alignItems:"center"}}>
                {`${Object.keys(filterFinal).length} Filter`}
                <img loading="lazy" src={LightCloseIcon} alt="close-icon" style={{cursor: "pointer", height:"10px", width:"10px", margin:"0px 0px 0px 10px"}} onClick={(e) => onTagRemoveFilter(e)}/>
            </div>
                
            }
            else{
                return null
            }
        }else{
            return null
        }
    }

    const onTagRemoveFilter = (e) => {
        setFilterValue({})
        setPreFilter({})
        setFilterFinal({})     
    }

    const missingSKUFilter = (e) => {
        e.preventDefault()
        setFilterFinal({missing_sku: true})
    }

    const closeModal = () => {
        setModalComplete(false)
    }

    // console.log(bulk)

    return add ? (<AddForm title={title} setIsHoveredO={setIsHoveredO} fx_back={() => setAdd(!add)} FunctionForm={addForm} query={query}  setCanUpdate={setCanUpdate} canUpdate={canUpdate} featureList={featureList} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity}/>) : edit ? (<EditForm setPageInd={setPageInd} title={title} fx_back={() => setEdit(!edit)} history={history} fx_changePage={fx_changePage} setFilterMain={setFilterMain} setPreFilter={setPreFilter} setFilterValue={setFilterValue} Form={editForm} query={query} selected={editValue} setFilterFinal={setFilterFinal} setCanUpdate={setCanUpdate} canUpdate={canUpdate} featureList={featureList} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} setSuccessTransaction={setSuccessTransaction}/>) :
     (
        <div className="table-container">
            <div className="title-font">{title}</div>
            <SnackBar open={snOpen} setOpen={setSnOpen} severity={severity} message={message}/>
            <BulkUpload open={bulk} setBulk={setBulk} setModalComplete={setModalComplete}/>
            <ModalComplete open={modalComplete} fx_close={closeModal}/>
            <FirmwareManifest open={manifest} setFirmwareManifest={setManifest}/>
            <div className="chart-container">
                <div className="chart right-space">
                    <BulletChart name="availbl_devices" title="Available" loading={chartLoading} data={chartData && chartData.get_devices_stat.available.map(el => { return { name: el.product_model && el.product_model.replace(/_/g, " "), total: el.total } })} colorList={chartColors} />
                </div>
                <div className="chart">
                    <BulletChart name="assigned_devices" title="Assigned" loading={chartLoading} data={chartData && chartData.get_devices_stat.assigned.map(el => { return { name: el.product_model && el.product_model.replace(/_/g, " "), total: el.total } })} colorList={chartColors} />
                </div>
            </div>
            <div className="buttons">
                <div className="filter-buttons">
                    {/* <div className="buttons-all">
                        <input type="checkbox" className="check-type"/>
                    </div>              */}
                    <div className="filter-container-device-admin">
                        <img loading="lazy" src={SearchIcon} alt="search-icon"/>
                        <img loading="lazy" src={DarkNavigationDown} alt="search-icon" onClick={() => setFilter(!filter)} className={hoveredO.filter ? "add-hover-button-style" : "add-button-style"} onMouseEnter={() => setIsHoveredO((current) => ({...current, filter: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, filter: false}))}/>
                        <div className="input-filter"> 
                            <div className={filterDesign()}>
                                {filterCount()}
                                
                            </div>                                   
                            <input type="text" value={preFilter.sn ? preFilter.sn.value[0] : ""} onChange={(e) => fx_PreFilter(e.target.value)} onKeyDown={(e) => EnterFilter(e)}/>
                        </div>
                    </div>
                    <div className="add-button">
                            <img loading="lazy" className={hoveredO.add ? "add-hover-button-style" : "add-button-style"} onClick={() => setAdd(!add)} onMouseEnter={() => setIsHoveredO((current) => ({...current, add: true}))}  onMouseLeave={() => setIsHoveredO((current) => ({...current, add: false}))} src={AddIcon} alt="Icon"/>  
                            <img loading="lazy" style={{margin:"0px 0px 0px 16px"}} onClick={() => setBulk(true)} className={hoveredO.bulk ? "add-hover-button-style" : "add-button-style"} src={DarkUploadIcon} alt="darkUpload" onMouseEnter={() => setIsHoveredO((current) => ({...current, bulk: true}))}  onMouseLeave={() => setIsHoveredO((current) => ({...current, bulk: false}))}/> 
                            <img loading="lazy" style={{margin:"0px 0px 0px 16px"}} src={DarkDownloadIcon} alt="dlicon" onClick={() => setManifest(true)} className={hoveredO.firmwareManifest ? "add-hover-button-style" : "add-button-style"} onMouseEnter={() => setIsHoveredO((current) => ({...current, firmwareManifest: true}))}  onMouseLeave={() => setIsHoveredO((current) => ({...current, firmwareManifest: false}))}/>
                        {/* <IconButton style={{ paddingLeft: "0px" }}>
                            <img loading="lazy" src={DropdownIcon} alt="dropdown-icon" className={classes.img} />
                        </IconButton> */}
                        {/* <IconButton aria-label="edit" onClick={() => setEdit(!edit)} disabled={true} > */}
                            {/* <img loading="lazy" src={EditIcon} alt="dropdown-icon" className={classes.img} /> */}
                            {/* <img loading="lazy" src={GreyEditIcon} alt="Icon" /> */}
                        {/* </IconButton> */}
                        {/* <IconButton aria-label="delete" disabled={true}> */}
                            {/* <img loading="lazy" src={DeleteIcon} alt="dropdown-icon" className={classes.img} /> */}
                            {/* <img loading="lazy" src={GreyDeleteIcon} alt="Icon"/> */}
                        {/* </IconButton> */}
                    </div>
                     {/* the if else statement for filter component, temporary only */}
                    <div style={{position: "absolute", margin: "0px 0px 0px 20px"}} ref={wrapperRef}>
                        { filter && <FilterBy   setFilterFinal={setFilterFinal} filterFinal={filterFinal} filterList={filterList} filterValue={filterValue}  setFilterValue={setFilterValue}/> }     
                    </div>
                </div>
                {missingSKU && <div className="missing-sku-container" onClick={(e) => missingSKUFilter(e)}>{`${totalMissing} missing SKU`}</div>}

               
            </div>
            <div className="table-responsive">
                {data && data.map((data, i) => {
                    return <Cards data={data} key={i} setEdit={setEdit} edit={edit} add={add} setAdd={setAdd} setEditValue={setEditValue} featureList={featureList} setDetailsPage={setDetailsPage} setFilterFinal={setFilterFinal} setPageInd={setPageInd}/>
                })}
                
            </div>
            {/* <div className="pagination-table">
                <IconButton >
                    <img src={LeftButton} alt="left-icon" className="img-pagination" />
                </IconButton>
                <IconButton >
                    <img src={RightButton} alt="right-icon" className="img-pagination" />
                </IconButton>
                1 - 3 of 3
            </div> */}
            {/* <div >
                <Copyright />
            </div> */}
            <style jsx>
                {
                    
                    ` .table-container{
                            margin: 24px 24px;
                       }
                       .chart-container{
                        display:flex;
                        margin: 0px 0px 24px 0px;
                       }
                       .chart{
                        padding: 8px;
                        width: 100%;
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        display: flex;
                        flex-direction: column;
                        justify-content: center
                      }
                      .right-space{
                        display: flex;
                        flex-direction: column;
                        margin: 0px 24px 0px 0px;
                      }
                      .title-font{
                        height: 32px;
                        // width: 108px;
                        color: #333333;
                        font-size: 24px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 32px;
                      }
                      .filter-buttons{
                          display: flex;
                      }
                      .buttons{
                        display: flex;
                        justify-content: space-between;
                        padding: 8px 0px 8px 0px;
                      }
                      .table-boostrap{
                        // margin: 16px;
                        // height:80vh;
                        min-height: 75vh;
                        background-color: #ffffff;
                      }
                      .table-responsive{
                          height:75vh;
                          width: 100%;
                        //   width: 
                      }
                      .filter-button{
                          font-size: 12px;
                      }
                      .pagination-table{
                        position: relative;.
                      }
                      .img-pagination{
                        width: 19px,
                        height: auto;
                      }
                      .missing-sku-container{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 34px;
                        width: 200px;
                        border-radius: 4px;
                        background-color: #EA2B1F;
                        cursor: pointer;
                        color: #FFFFFF;
                      }
                      .row-height{
                          height:
                      }
                      .wrapper{
                        word-wrap: break-word
                      }
                      .check-type{
                        margin: 0px 4px 0px 0px;
                        height: 18px;
                        width: 18px;
                        background-color: #509D91;
                      }
                      .buttons-all{
                        display: flex;
                        align-items: center;
                     }
                     .filter-container-device-admin{
                        padding: 8px;
                        // margin: 0px 0px 0px 26px;
                        display: flex;
                        box-sizing: border-box;
                        height: 40px;
                        width: 360px;
                        border: 1px solid #939598;
                        border-radius: 4px;
                      }
                    .add-button{
                        margin: 0px 0px 0px 8px;
                        display: flex;
                        align-items:center;
                    }
                    .input-filter{
                        display: flex;
                        align-items: center;
                    }
                    input[type=text] {
                        width: 285px;
                        background: transparent;
                        border: none;
                        outline: none;
                    }
                    .filter-tag{
                        display: flex;
                        align-items: center;
                        color: #FFF;
                        border-radius: 4px;
                        width: 85px;
                        height: 24px;
                        text-align: center;
                        background-color: #509D91;
                        justify-content: center;
                        margin: 0px 8px 0px 0px;
                    }
                    .filter-no-tag{
                        height: 0px;
                    }
                    .add-button-style{
                        cursor: pointer;
                    }
                    .add-hover-button-style{
                        background-color: rgba(0,0,0,0.03);
                        border-radius: 5px;
                        cursor: pointer;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default OverviewDevice
