import React, {useContext, useState} from 'react'
import { useMutation } from "@apollo/react-hooks";

//list of images
import FuelIcons from "../../Image/fuel_tank.svg"
import NavigationIcon from "../../Image/chevron_right.svg"

//list of components
import FeatureView from "../../component/component-featureview/FeatureView"
import Board from "./Board"
import IOTConfiguration from './IOTConfiguration'
import SigfoxConfig from '../../component/component-sigfoxConfig/SigfoxConfig'
import {firebaseContext} from '../../config/config-firebase/FirebaseContext'
import { UPDATE_DEVICES, GET_DEVICES, CELLULAR_CONFIG} from "../../config/config-graphql/Graphql";
import SnackBar from '../../config/config-snackbar/SnackBar'
import SaveCard from '../../component/component-save/SaveCard'


const cellular = [{
    deviceId: "ORNSIM01",
    payloadInterval: 10,
    payloadIntervalIgnitionOff: 10,
    extSense_Fuel: true,
    payloadIntervalOffline: 10,
}]

const Details = ({ title, device, fx_back, setIsHoveredO, featureList, isSmartLock, setFilterFinal, setPageInd, setMessage, setSeverity, setSnOpen, featureDisable}) => {
    const SKUFeatures = device[0].SKU.features
    const dvcFeatures = device[0].features

    const staticBoardID = device[0].board.map(x => x.config)
    let sigfox_config = device.some(x => x.device_type === "Telematics") ? device[0].board.some(x => x.batch_id === "Sigfox") ? device[0].board.map(x => x.config) :[] :[]
    let cellular_config = device.some(x => x.device_type === "Telematics") ? device[0].board.some(x => x.batch_id === "Cellular") ? device[0].SKU.product_model !== 'Teltonika' ? device[0].board.map(x => x.config) :[] :[] :[]

    const addedFeatures = SKUFeatures.concat(dvcFeatures)


    const [sigfoxConf, setSigfoxConf] = useState({
        deviceId: sigfox_config.length > 0 ? [sigfox_config[0].deviceId] : [],
        downlinkFrequency: sigfox_config.length > 0 ? sigfox_config[0].downlinkFrequency ? sigfox_config[0].downlinkFrequency : 4 : 4,
        gpsSeekPeriod: sigfox_config.length > 0 ? sigfox_config[0].gpsSeekPeriod ? sigfox_config[0].gpsSeekPeriod : 60 : 60,
        payloadInterval: sigfox_config.length > 0 ? sigfox_config[0].payloadInterval ? sigfox_config[0].payloadInterval : 3600 : 3600,
        payloadIntervalMotion: sigfox_config.length > 0 ? sigfox_config[0].payloadIntervalMotion ? sigfox_config[0].payloadIntervalMotion : 600 : 600,
        revertDefault: sigfox_config.length > 0 ? typeof sigfox_config[0].revertDefault == "boolean" ? sigfox_config[0].revertDefault : false : false,
        variableInterval: sigfox_config.length > 0  ? typeof sigfox_config[0].variableInterval === "boolean" ? sigfox_config[0].variableInterval : true : true
    })

    const [cellularConf, setCellularConf] = useState({
        deviceId: cellular_config.length > 0 ? cellular_config[0].deviceId : "",
        payloadInterval: cellular_config.length > 0 ? cellular_config[0].payloadInterval ? cellular_config[0].payloadInterval : 10 : 10,
        payloadIntervalIgnitionOff: cellular_config.length > 0 ? cellular_config[0].payloadIntervalIgnitionOff ? cellular_config[0].payloadIntervalIgnitionOff : 10 : 10,
        extSense_Fuel: cellular_config.length > 0 && cellular_config[0].extSense_Fuel,
        payloadIntervalOffline: cellular_config.length > 0 ? cellular_config[0].payloadIntervalOffline ? cellular_config[0].payloadIntervalOffline : 10 : 10,
    })


    const [inputState, setInputState] = useState("")
    const [editVariables, setEditVariables] = useState({});
    const firebaseConfig = useContext(firebaseContext)
    const [valuesName, setValues] = useState(device[0].name)

    // console.log(firebaseConfig.getValueByKey("component-sigfox-config"))

    //the errOpen, errMsg and errSevere is for snackbar props for error 
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("")

    const [isHovered, setIsHovered] = useState({
        name: false,
        sn: false,
        SKU: false,
        board_id: false,
        firmware: false,
        btnAdd: false,
        statInt: false,
        inMotionInt: false,
        gps: false,
        dLink: false,
        msgInterval: false,
        msgIntervalOff: false,
        offlineMsgLog: false,
        fDensityRatio: false,
        length:false,
        width:false,
        height:false
    })

    //this to take boardClass sigfox value for sigfox configuration. If the board has value "Sigfox" it will return else empty array
    const assemblyClass = device[0].device_type === "Telematics" ? device[0].board.map(x => x.assembly.board_class) :[]
    const removeDuplicate = assemblyClass.filter((value, index) => assemblyClass.indexOf(value) === index)
    const sigfoxFilter = removeDuplicate.filter(x => x === "Sigfox")
    const cellularFilter = removeDuplicate.filter(x => x === "Cellular")
    // console.log(sigfoxFilter)

    // console.log(cellularConf)


    const btnBack = (e) => {
        e.preventDefault();
        setFilterFinal({})
        setPageInd(false)
        setIsHoveredO({filter: false})
        fx_back()
    }

    //consubmit
    const onSubmit = async (e) => {
        try{
          e.preventDefault();
        //   console.log(editVariables)
          const response = await editData({ variables: editVariables});
          if(response.errors){
            setErrOpen(true)
            setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
            setErrSevere("error")
          }
          else{
            setSnOpen(true)
            setMessage("Record has been successfully updated")
            setSeverity("success")
            setFilterFinal({})
            setPageInd(false)
            fx_back();
          }
        }catch(err){
          console.log(JSON.stringify(err, null, 2))
          setErrOpen(true)
          setErrMsg(JSON.stringify(err.message, null, 2))
          setErrSevere("error")
        }
    };

    // console.log(cellularConf)

    const cellularSubmit = async () => {
        try{
            // console.log(cellularConf)
            const response = await cellularData({variables: {deviceId: cellularConf.deviceId, input: {
                payloadInterval: typeof cellularConf.payloadInterval === "number" ? cellularConf.payloadInterval : parseInt(cellularConf.payloadInteval),
                payloadIntervalIgnitionOff: typeof cellularConf.payloadIntervalIgnitionOff === "number" ? cellularConf.payloadIntervalIgnitionOff : parseInt(cellularConf.payloadIntervalIgnitionOff),
                payloadIntervalOffline: typeof cellularConf.payloadIntervalOffline === "number" ? cellularConf.payloadIntervalOffline : parseInt(cellularConf.payloadIntervalOffline),
                extSense_Fuel: cellularConf.extSense_Fuel
            }} })

            if(response.errors){
                setErrOpen(true)
                setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
                setErrSevere("error")
            }else{
                setErrOpen(true)
                setErrMsg("Record has been successfully updated")
                setErrSevere("success")
            }
        }catch(err){
            console.log(JSON.stringify(err, null, 2))
            setErrOpen(true)
            setErrMsg(JSON.stringify(err.message, null, 2))
            setErrSevere("error")
        }
    }



    const [editData, {loading:devLoading, error:devError}] = useMutation(UPDATE_DEVICES, {
        refetchQueries: () => [
            {
                query: GET_DEVICES,
                variables: { filter: {}, type: "All" },
            },
        ],
    });

    const [cellularData, {loading:cellularLoading, error:cellularError}] = useMutation(CELLULAR_CONFIG, {
        refetchQueries: () => [
            {
                query: GET_DEVICES,
                variables: { filter: {}, type: "All" },
            },
        ],
    });

    const onChange = (key, value) => {
        setValues(value)
        setEditVariables({updated_device :{uuid: device[0].uuid, [key]: value}})
    }

    return (
        <div className="form-container">
            <div className="navigation-title-placeholder">
                <div className="title-add">
                <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                    <div onClick={(e) => btnBack(e)} style={{cursor: "pointer"}} className="detail-title first-title">
                        {title}
                    </div>
                    <div className="detail-title">
                        <img loading="lazy" src={NavigationIcon} alt="Back"/>
                    </div>                      
                    <div className="detail-title">{device[0].sn ? device[0].sn : ""}</div>
                    <div className="detail-title"></div>

                </div>
                {
                    device[0].device_type === "Smartlocks" && !featureDisable ? <SaveCard onSubmit={onSubmit} canAdd={true}/>  : null
                }
            </div>
            <div className="first-box-container">
                <div className="device-title">Device Information</div>
                <div className="device-input">
                    <div className="device-container">
                        <div className="device-information">
                            {
                                device[0].device_type === "Smartlocks" ? 
                                <div className="details-input" >
                                    <div className="input-input">Name </div> 
                                    <input type="text" className={`${isHovered.name ? "isHovered-border" : "text-box"}`} placeholder="-" value={valuesName} name="name" onChange={(e) => onChange("name", e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, name: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, name: false}))} disabled={featureDisable ? true : false}/>
                                </div> : null
                            }
                            <div className="details-input" onMouseEnter={() => setIsHovered((current) => ({...current, sn: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, sn: false}))}>
                                <div className="input-input">Serial Number </div> 
                                <input type="text" className="text-box" placeholder="-" name="sn" value={device[0].sn} disabled={true}/>
                            </div>
                            <div className="details-input" onMouseEnter={() => setIsHovered((current) => ({...current, SKU: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, SKU: false}))}>
                                <div className="input-input">SKU </div> 
                                <input type="text" className="text-box" placeholder="-" name="sn" value={device[0].SKU ? device[0].SKU.ID : ""} disabled={true}/>
                            </div>
                         </div>
                    </div>
                    <div className="device-details">{firebaseConfig.getValueByKey("client_device_information")}</div>
                </div>

            </div>
            <div className="second-box-container" >
                <div className="board-features-container">
                    {
                        device[0].device_type === "Smartlocks" ? 
                        null : 
                        <div className="board-container">
                            <div className="box-info">
                                <div className="title">Board</div>
                                <div className="title-detail">{firebaseConfig.getValueByKey("client_device_board")}</div>
                                { 
                                    device &&  device[0].board && device[0].board.map((item) => (    
                                        <Board data={item} setIsHovered={setIsHovered} isHovered={isHovered}/>
                                    ))
                                } 
                            </div>
                        </div> 
                    }

                    <div className="features-container">
                        <div className="box-info">
                            <div className="title">Features</div>
                            <div className="title-detail">{firebaseConfig.getValueByKey("feature_and_sensors")}</div>
                        </div>
                        <FeatureView featureList={featureList} selectedFeature={addedFeatures}/>
                    </div>
                </div>
                {
                    device[0].device_type === "Smartlocks" ? null :
                    <div className="sensor-custom-delete-container">
                        {
                            sigfoxFilter.length > 0 ? <SigfoxConfig setSigfoxConf={setSigfoxConf} sigfoxConf={sigfoxConf} displayed={true} staticBoardID={staticBoardID} sigfoxFilter={sigfoxFilter} setErrMsg={setErrMsg} setErrOpen={setErrOpen} setErrSevere={setErrSevere} setIsHovered={setIsHovered} isHovered={isHovered} details={firebaseConfig.getValueByKey("component_sigfox_config")}/> : 
                            cellularFilter.length > 0  && cellular_config.length > 0 ? <IOTConfiguration isHovered={isHovered} setIsHovered={setIsHovered} setCellularConf={setCellularConf} cellularConf={cellularConf} cellularSubmit={cellularSubmit} device={device}/> : null
                        }
                        {/* <div className="fuel-container">
                            <div className="box-info">
                                <div className="fuel-title">
                                    <div className="title">Fuel Sensor Calibration</div>
                                    <div className= {device[0].fuel_sensor && device[0].fuel_sensor.length && device[0].fuel_sensor.width && device[0].fuel_sensor.height ? "caliration-box-green" : "calibration-box"}>
                                        <div className={device[0].fuel_sensor && device[0].fuel_sensor.length && device[0].fuel_sensor.width && device[0].fuel_sensor.height ? "calibrated-text" : "not-calibrated-text"}>
                                                {device[0].fuel_sensor && device[0].fuel_sensor.length && device[0].fuel_sensor.width && device[0].fuel_sensor.height ? "Calibrated" : "Not Calibrated" }
                                        </div>
                                    </div>
                                </div>                            
                                <div className="title-detail margin-change">{firebaseConfig.getValueByKey("client_fuel_sensor_calibration")}</div>
                            </div>
                            <div className="icons-container">
                                <div className="text-icon"> 
                                    <div className="text-key-in">Length(m):</div>
                                    <input type="number" className="text-boxes" placeholder="-" name="length" value={device[0] && device[0].fuel_sensor && device[0].fuel_sensor.length} disabled={true}/>
                                </div>
                                <div className="img-container">
                                    <img src={FuelIcons} alt="test"/>
                                    
                                </div>
                                <div className="text-icon-width">
                                    <div className="text-key-in">Width(m):</div>
                                    <input type="number" className="text-boxes" placeholder="-" name="width" value={device[0] && device[0].fuel_sensor && device[0].fuel_sensor.width} disabled={true}/>
                                </div>
                                <div className="text-icon-height">
                                    <div className="text-key-in">Height(m):</div>
                                    <input type="number" className="text-boxes" placeholder="-" name="height" value={device[0] && device[0].fuel_sensor && device[0].fuel_sensor.height} disabled={true}/>
                                </div>
                                <div className="input-icons">
                                    <div className="text-icon-volumn-one">
                                        <div className="text-key-in">Volumn(㎥):</div>
                                        <label className="label-text">
                                            {
                                                device[0].fuel_sensor && device[0].fuel_sensor.length && device[0].fuel_sensor.width && device[0].fuel_sensor.height ? (device[0].fuel_sensor.length * device[0].fuel_sensor.width * device[0].fuel_sensor.height).toFixed(4) : "-"
                                            }
                                        </label>
                                    </div>
                                    <div className="text-icon-volumn-two">
                                        <div className="text-key-in">Volumn(l):</div>
                                        <label className="label-text">
                                            {
                                                device[0].fuel_sensor && device[0].fuel_sensor.length && device[0].fuel_sensor.width && device[0].fuel_sensor.height ? ((device[0].fuel_sensor.length * device[0].fuel_sensor.width * device[0].fuel_sensor.height) * 1000).toFixed(2) : "-"
                                            }
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                }

            </div>
            <style>
                {
                    `
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                    }

                    /* Firefox */
                    input[type=number] {
                    -moz-appearance: textfield;
                    }
                    .fuel-title{
                        display: flex;
                        align-items: center;
                    }
                    .not-calibrated-text{
                        font-size: 10px;
                        color: #EA2B1F;
                    }
                    .calibrated-text{
                        font-size: 10px;
                        color: #286551;
                    }
                    .caliration-box-green{
                        box-sizing: border-box;
                        height: 25px;
                        width: 87px;
                        border: 1px solid #286551;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .calibration-box{
                        box-sizing: border-box;
                        height: 25px;
                        width: 87px;
                        border: 1px solid #EA2B1F;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .label-text{
                        margin: 0px 0px 0px 4px;
                    }
                    .text-icon-volumn-two{
                        display: flex;
                        align-items: center;
                        width: 150px;
                    }
                    .text-icon-volumn-one{
                        display: flex;
                        align-items: center;
                        width: 150px;
                        margin: 0px 0px 8px 0px;
                    }
                    .text-icon-height{
                        display: flex;
                        align-items: center;
                        position: relative;
                        bottom: 110px;
                        left: 150px
                    }
                    .text-icon-width{
                        position: relative;
                        bottom: 30px;
                        left: 130px;
                        display: flex;
                        align-items: center;
                    }
                    .details-assembly{
                        display: flex;
                        align-items: center;
                        padding: 0px 0px 8px 0px;
                    }
                    .input-input{
                        width: 133px;
                    }
                    .first-box-container{               
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        padding: 16px;
                    }
                    .second-box-container{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 1% 0px;
                        grid-template-areas: ". .";
                        margin: 24px 0px 0px 0px;
                    }
                    .device-container{
                        width: 100%;
                        margin: 0px 16px 0px 0px;
                    }
                    .device-title{
                        height: 32px;
                        width: 100%;
                        color: #333333;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                    .input-input{
                        // width: 268px;
                        display: flex;
                        align-items: center;
                    }
                    .title{
                        margin: 0px 8px 0px 0px;
                        height: 21px;
                        color: #333333;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 21px;
                      }
                    .title-detail{
                        margin: 4px 0px 8px 0px;
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                    .device-input{
                        display: flex;
                    }
                    .device-details{
                        height: 32px;
                        width: 100%;
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 16px;
                        margin: 0px 0px 0px 32px;
                    }
                    .details-input{
                        display: grid;
                        grid-template-columns: 200px 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". .";
                        margin: 0px 0px 8px 0px;
                        height: 30px;
                    }
                    .board-features-container{

                    }
                    .sensor-custom-delete-container{
                        margin: 0px 0px 0px 24px;
                    }
                    .box-info{
                        // margin: 0px 0px 16px 0px;
                      }
                    .text-box{
                        border-style: none;
                        height: 24px;
                        outline: none;
                        border-radius: 4px;
                    }
                    .isHovered-border{
                        height: 24px;
                        outline: none;
                        border: 1px solid #b6babf;
                        border-radius: 4px;
                      }
                    .board-container{
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        margin: 0px 0px 24px 0px;
                        padding: 16px;
                    }
                    .features-container{
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        padding: 16px;
                    }
                    .fuel-container{
                        margin: 0px 0px 0px 24px;
                        height: 236px;
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        padding: 16px;
                    }
                    .custom-container{
                        height: 137px;
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        margin: 24px 0px 0px 24px;
                        padding: 16px;
                    }
                    .delete-device-container{
                        height: 137px;
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        margin: 24px 0px 0px 24px;
                        padding: 16px;
                    }
                    .selection-board{
                        border-style:none;
                        background-color: #FFF;
                        height: 24px;
                        width: 30%;
                        margin-bottom: 8px;
                        outline: none;
                    }
                    .text-boxes{
                        border-style: none;
                        height: 24px;
                        width: 115px;
                        background: transparent;
                        outline: none;
                    }
                    .icons-container{
                        position: relative;
                        bottom: 25px;
                        transform: translate(28%);
                        height: 150px;
                    }
                    .input-icons{
                        position: relative;
                        bottom: 140px;
                        left: 280px;
                    }
                    .img-container{

                    }
                    .text-icon{
                        position: relative;
                        top: 135px;
                        right: 80px;
                        display: flex;
                        align-items: center;
                        margin: 0px 21px 0px 0px;
                    }
                    .text-key-in{
                        color: #939598;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .assembly-container{
                        box-sizing: border-box;
                        border: 1px solid #939598;
                        border-radius: 4px;
                        padding: 16px;
                        margin: 16px 0px 0px 0px;
                    }
                    .asse-text{
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .title-add{
                        display: flex;
                        align-items: center;            
                      }
                      .form-container{
                        margin: 24px;
                      }
                      .navigation-title-placeholder {
                        font-size: 18px;
                        font-weight: 545;
                        height: 32px;
                        margin-bottom: 16px;
                        display: flex;
                        justify-content: space-between;
                      }
                      .details {
                        height: 40px;
                        // margin-left: 24px;
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.54);
                      }
                      .autocomplete {
                        display: flex;
                        height: 45px;
                        // margin-left: 24px;
                        font-size: 14px;
                        color: rgba(0, 0, 0, 0.54);
                      }
                      .textfield {
                        position: relative;
                        bottom: 40px;
                      }
                      .detail-title{
                        padding: 8px;
                      }
                      .first-title{
                        font-size: 16px; 
                      }
                        .margin-change{
                            margin: 4px 0px 8px 0px;
                        }
                    `
                }
            </style>
        </div>
  )
}

export default Details
