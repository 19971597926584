import React, {useState, useEffect} from 'react'

import Cookies from 'js-cookie';

import ChannelSearchBarEnter from './ChannelSearchBarEnter'
import ChannelSearchBarLeave from './ChannelSearchBarLeave'
import ChannelSearchBarPin from './ChannelSearchBarPin'

import { APIGetChannel } from '../../config/config-restAPI/getAPICalled';

import ListChannelEnterCont from './ListChannelEnterCont'
import ListChannelLeaveCont from './ListChannelLeaveCont'
import ListChannelPinCont from './ListChannelPinCont'

const CommunicationEditCard = ({setNotification, notification, featureDisable, setAddNotification, addNotification, setRemoveNotification, removeNotification, isEdit, dataGeofence}) => {
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');
    const [searchInput, setSearchInput] = useState("")
    const [loading, setLoading] = useState(false);
    const [listChannel, setListChannel] = useState([]);

    const APICalled = async (filterName, filterType) => {
        try {
            const result = await APIGetChannel(token, setLoading, agencyId, filterName, filterType)
            if (result.data.status === 200) {
                const { data } = result.data;
                setListChannel(data);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch(err) {
            setLoading(false);
        }
    }

    useEffect(() => {
      if (token) {
        APICalled('', 'Telegram');
      }
    }, []);
    
    return (
    <div className="communication-container">
        <header className="communication-text">Communication</header>
            <div className="triggers-asset">  
                <div className="input-checkbox">
                    <p>Alert upon asset entering zone </p>
                </div>             
                {featureDisable ? null : <ChannelSearchBarEnter data={listChannel}  setNotification={setNotification} notification={notification} setSearchInput={setSearchInput} APICalled={APICalled} setAddNotification={setAddNotification} addNotification={addNotification} setRemoveNotification={setRemoveNotification} removeNotification={removeNotification} isEdit={isEdit} />}
                {notification && notification.enter.length > 0 && <ListChannelEnterCont notification={notification} setNotification={setNotification} featureDisable={featureDisable} setAddNotification={setAddNotification} addNotification={addNotification} setRemoveNotification={setRemoveNotification} removeNotification={removeNotification} isEdit={isEdit} dataGeofence={dataGeofence} /> }
            </div>
            <div className="triggers-asset">   
                <div className="input-checkbox">
                    <p>Alert upon asset leaving zone </p>
                </div> 
                {featureDisable ? null : <ChannelSearchBarLeave data={listChannel}  setNotification={setNotification} notification={notification} setSearchInput={setSearchInput} APICalled={APICalled} setAddNotification={setAddNotification} addNotification={addNotification} setRemoveNotification={setRemoveNotification} removeNotification={removeNotification} isEdit={isEdit}/>}
                {notification &&  notification.leave.length > 0 &&  <ListChannelLeaveCont notification={notification} setNotification={setNotification} featureDisable={featureDisable} setAddNotification={setAddNotification} addNotification={addNotification} setRemoveNotification={setRemoveNotification} removeNotification={removeNotification} isEdit={isEdit}/>}
            </div>
            <div className="triggers-asset">  
                <div className="input-checkbox">
                    <p>Generate Smart Lock PIN Code</p>
                </div>         
                {featureDisable ? null : <ChannelSearchBarPin data={listChannel}  setNotification={setNotification} notification={notification} setSearchInput={setSearchInput} APICalled={APICalled} setAddNotification={setAddNotification} addNotification={addNotification} setRemoveNotification={setRemoveNotification} removeNotification={removeNotification} isEdit={isEdit}/>}
                {notification &&  notification.smartLock.length > 0 &&  <ListChannelPinCont notification={notification} setNotification={setNotification} featureDisable={featureDisable} setAddNotification={setAddNotification} addNotification={addNotification} setRemoveNotification={setRemoveNotification} removeNotification={removeNotification} isEdit={isEdit}/>}
            </div>
    </div>
    )
}

export default CommunicationEditCard
