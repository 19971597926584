// overview Device Firmware Manifest
import React, {useState, useEffect} from 'react'
import SnackBar from '../../config/config-snackbar/SnackBar'
import ReactTexty from 'react-texty'
import SearchIcon from '../../Image/dark_search.svg'
import {TableSelect, Column} from '../../component/component-tableSelect/index'
import styled from "styled-components";
import {GET_DEVICES, SEARCH_DEVICES} from '../../config/config-graphql/Graphql'
import { useQuery } from "@apollo/react-hooks";

const columns = [
    { key: "uuid", dataKey:"uuid", hidden: true},
    { key: "sn", title: "SN", dataKey: "sn", flexGrow: 1, width: 50, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> },
    { key: "SKUID", title: "SKU ID", dataKey: "SKUID", flexGrow: 1, width: 50, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> },
    { key: "boardId", title: "Board ID", dataKey: "boardId", width: 50, flexGrow: 1, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> },
]

const Content = styled.div`
flex: 1 1 auto;
height: 500px;
.BaseTable{
  box-shadow: 0px 0px #FFFFFF;
}

.BaseTable__row {
  /* color: #9ca2b3; */
}

.BaseTable__table-main {
  outline: none;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #d0d3db;
  }
}

.BaseTable__header-row {
  background: #fff;
}

.BaseTable__header-cell:hover *:not(span) {
  color: #6899f8;
  &:before,
  &:after {
    background: #6899f8;
  }
}

.BaseTable__header-cell-text {
  color: #939598;
  font-size: 0.9em;
  user-select: none;

  &:hover {
    color: #6899f8;
  }
}

.BaseTable__row-cell{
  font-size: 12px;

}
`;

const FirmwareManifest = ({open, setFirmwareManifest }) => {

    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("") 
    const [rowsIndex, setRowsIndex] = useState([])
    const [copy, setCopy] = useState([]) //set copy ni untuk letak
    const [seachProtocol, setSeachProtocol] = useState("")
    const { data, loading, error } = useQuery(SEARCH_DEVICES, { variables: { searchKey:  seachProtocol, boardClass: "Cellular" }}); //this to for searchDevice api
    const { data:dataDevices, loading:loadingDevices, error:errorDeviecs } = useQuery(GET_DEVICES, { variables: { filter: {uuid: copy.length > 0 ? copy.map(x => x.uuid) : []}, type: "Telematics" }}); //this to for getDevice api

    const [isHovered, setIsHovered] = useState({
        btnYes: false,
        btnNo: false,
        download: false
    })

    //to convert to csv file
    let csvFile = "sn,boardId, privateKey, projectId, registryId\r\n"
    copy.length > 0 && !loadingDevices && dataDevices && dataDevices.get_devices && dataDevices.get_devices.devices.map(x => csvFile += `${x.sn || ""},${ x.board.length > 0 && x.board[0].board_id && x.board[0].board_id || ""},${ x.board.length > 0 && x.board[0].privateKey && ` "${JSON.stringify(x.board[0].privateKey)}"` || ""},${ x.board.length > 0 && x.board[0].config && x.board[0].config.projectId && x.board[0].config.projectId|| ""}, ${ x.board.length > 0 && x.board[0].config && x.board[0].config.registryId && x.board[0].config.registryId|| ""}\r\n`)
    const url = `data:text/json;charset=utf-8,${encodeURIComponent(csvFile)}`

    // !loading && console.log(data)

    //this function for close button and outside click
    const switchModal = () => {
        setCopy([]) //remove the list from table
        setFirmwareManifest(false) //to refresh the checkbox
    }

    //this function for onChange search text field
    const onChange = (e) => {
        setSeachProtocol(e.target.value) //search input onChange state
    }

    //reducer for searchData display in baseTable
    const setData = (data) => {
        if(!loading){
            return data && data.searchDevices ? data.searchDevices.map(x => {
                return{
                    uuid: x.uuid,
                    sn: x.sn,
                    SKUID: x.SKU && x.SKU.ID,
                    boardId: x.board[0] && x.board[0].board_id
                }
            }) : []
        }
    }

    //this to display error snackbar once the gql return error
    useEffect(() => {
        if(error){
            setErrOpen(true)
            setErrMsg("Error: Unable to retrieve data. Please try again later.")
            setErrSevere("error")
        }
    }, [error])

    

    return (
        <div className="manifest-modal-container"style={{display: !open && "none"}} >
            <div className="manifest-modal-background" onClick={() => switchModal()}></div>
            <div className="manifest-modal-content">
                <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                <div className="manifest-body-modal">
                    <div className="manifest-title-container">
                        <div className="manifest-modal-title">Firmware Manifest</div>
                    </div>
                    
                    <div className="manifest-search-input">
                        <input type="text" style={{width: "615px", border:"1px solid #939598", borderRadius:"4px", backgroundImage:`url(${SearchIcon})`, backgroundRepeat:"no-repeat", backgroundPosition:"8px 9px", padding:"0px 0px 0px 36px"}} className="manifest-input-search" value={seachProtocol} placeholder="Search" onChange={(e) => onChange(e)}/>
                    </div>
                    <div>
                    <TableSelect data={!loading && setData(data)} rowKey="sn" selectable setCopy={setCopy} copy={copy} headerHeight={35} rowHeight={34} setRowsIndex={setRowsIndex} removeSelection={open} Content={Content}>
                        {columns.map(({ dataKey, ...restProps }) => (
                            <Column key={dataKey} dataKey={dataKey} {...restProps} />
                        ))}
                    </TableSelect>
                    </div>
                    <div className="manifest-modal-btn">
                        <div className={isHovered.btnNo ? "isHovered-manifest-btn-no" : "manifest-btn-no"} onClick={() => switchModal()} style={{margin: "0px 24px 0px 0px"}} onMouseEnter={() => setIsHovered((current) => ({...current, btnNo: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnNo: false}))}>Cancel</div>
                        <a href={url} download="FirmwareManifest.csv" className={isHovered.btnYes ? "isHovered-manifest-btn-yes" : "manifest-btn-yes" } onMouseEnter={() => setIsHovered((current) => ({...current, btnYes: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnYes: false}))}>Download CSV</a>
                    </div>
                </div>
            </div>
            <style>
                {
                    `.manifest-modal-container{
                        position: fixed;
                        z-index: 1300;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                    }
                    .manifest-modal-background{
                        z-index: -1;
                        position: fixed;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                        background-color: rgba(0, 0, 0, 0.5);
                        -webkit-tap-highlight-color: transparent;
                    }
                    .manifest-modal-content{
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        width: 687px;
                        border-radius: 4px;
                        transform: translate(-50%, -50%);
                        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
                        background: #FFFFFF;
                        display: block;
                    }
                    .manifest-body-modal{
                        padding: 16px;
                    }
                    .manifest-modal-title{
                        display: flex;
                        justify-content: center;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 24px;
                        margin: 0px 0px 16px 0px;
                    }
                    .manifest-modal-btn{
                        display: flex;
                        justify-content: center;
                        margin: 24px 0px 0px 0px;
                    }
                    .manifest-btn-no{
                        border-radius: 2px;
                        border: 1px solid #509D91;
                        font-size: 14px;
                        height: 32px;
                        width: 213px;
                        color: #509D91;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items:center;
                    }
                    .manifest-btn-yes{
                        background: #509D91;
                        border-radius: 2px;
                        color: #FFFFFF;
                        font-size: 14px;
                        height: 34px;
                        width: 213px;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items:center;
                        text-decoration: none;
                    }
                    .isHovered-manifest-btn-no{
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                        color: #498f84;
                        border-radius: 2px;
                        border: 1px solid #498f84;
                        font-size: 14px;
                        height: 32px;
                        width: 213px;
                        font-weight: 600;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items:center;
                    }
                    .isHovered-manifest-btn-yes{
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                        background: #509D91;
                        font-size: 14px;
                        border-radius: 2px;
                        color: #FFFFFF;
                        height: 34px;
                        width: 213px;
                        font-weight: 600;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items:center;
                        text-decoration: none;
                    }
                    .manifest-input-search{
                        height: 40px;        
                    }
                    .manifest-search-input{
                        margin: 0px 0px 8px 0px;
                    }
                    .manifest-title-container{

                    }
                    `
                }
            </style>
        </div>
    )
}

export default FirmwareManifest

