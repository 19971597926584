import React, { createContext, useReducer, useEffect } from "react";
import firebase from '../config-firebase-auth/firebase'

const config = {
    horizon_version: {_value: "loading..."},
    login_detail: {_value: ""},
    login_header: {_value: ""},
    panelParam: {_value: ""},
    panel_agencies: {_value: ""},
    panel_alert_communication: {_value: ""},
    panel_asset: {_value: ""},
    panel_asset_group: {_value: ""},
    panel_asset_management: { _value: ""},
    panel_device_management: {_value: ""},
    panel_device_management_batch: {_value: ""},
    panel_device_management_device: {_value: ""},
    panel_device_management_firmware: {_value: ""},
    panel_device_management_telemetry: {_value: ""},
    panel_historical: {_value: ""},
    panel_historical_telemetry: {_value: ""},
    panel_historical_timeline: {_value: ""},
    panel_live: {_value: ""},
    panel_live_device: {_value: ""},
    panel_report: {_value: ""},
    panel_report_alerts: {_value: ""},
    panel_report_asset: {_value: ""},
    panel_report_trip_activity: {_value: ""},
    panel_report_vehicle: {_value: ""},
    panel_sku_management: {_value: ""},
    panel_sku_management_assembly: {_value: ""},
    panel_sku_management_component: {_value: ""},
    panel_sku_management_manufacturer: {_value: ""},
    panel_sku_management_schematic: {_value: ""},
    panel_sku_management_sku: {_value: ""},
    panel_user_management: {_value: ""},
    panel_user: {_value: ""},
    panel_channel: {_value: ""},
    panel_communication_channel: {_value: ""},
    panel_geofencing: {_value: ""},
    product_model_category: {_value: ""},
    panel_admin_telemetry: {_value: ""},
    product_model_agency: {_value: ""},
    panel_live_map: {_value: ""},
    panel_live_asset_status: {_value: ""},
    panel_report_geofence_log: {_value: ""},
    panel_report_trips: {_value: ""},
    panel_report_fuel_usage: {_value: ""},
    panel_geofencing_geofence: {_value: ""},
    panel_report_telemetry: {_value: ""},
    Report_Telemetry_Description: {_value: ""},
    report_telemetry_asset_decription: {_value: ""},
    report_telemetry_search_description: {_value: ""},
    report_search_description: {_value: ""},
    report_trip_description: {_value: ""},
    report_trip_asset_description: {_value: ""},
    panel_features: { _value: ""},
    report_fuel_log_description: { _value: ""},
    report_fuel_log_asset_description: { _value: ""},
    report_fuel_log_search_description: { _value: ""},
    report_vehicle_log_search_description: { _value: ""},
    report_vehicle_log_asset_description: { _value: ""},
    report_vehicle_log_description: { _value: ""},
    report_asset_ulitisation_description: {_value: ""},
    report_asset_utilisation_asset_description: {_value: ""},
    report_asset_utilisation_search_description: {_value: ""},
    report_Geofence_Description: {_value: ""},
    report_geofence_log_search_description: {_value: ""},
    report_geofence_log_asset_description: {_value: ""},
    report_geofence_log_policy: {_value: ""},
    report_geofence_log_alert: {_value: ""},
    report_alert_search_description: {_value: ""},
    report_alert_alertType_description: {_value: ""},
    report_alert_asset_description: {_value: ""},
    report_alert_description: {_value: ""},
    report_alert_driver_behaviour_description: {_value: ""},
    client_device_information: {_value: ""},
    client_device_board: {_value: ""},
    client_fuel_sensor_calibration: {_value: ""},
    feature_and_sensors: {_value: ""},
    client_asset_information: {_value: ""},
    client_device_assignment: {_value: ""},
    client_pair_asset: {_value: ""},
    client_pair_asset_popup: {_value: ""},
    client_unpair_asset_popup: {_value: ""},
    client_asset_geofence: {_value: ""},
    client_asset_feature: {_value: ""},
    client_asset_overview_detail: {_value: ""},
    client_delete_asset_popup: {_value: ""},
    client_asset_group_information: {_value: ""},
    client_asset_grouping: {_value: ""},
    client_delete_asset_group: {_value: ""},
    admin_firmware_delete: {_value: ""},
    admin_sku_id: {_value:""},
    admin_sku_feature: {_value:""},
    admin_sku_assembly: {_value:""},
    admin_sku_delete: {_value:""},
    admin_device_board: {_value:""},
    admin_device_fuel_sensor: {_value:""},
    admin_device_feature: {_value:""},
    admin_agency_administrator: {_value:""},
    admin_agency_device_assign: {_value:""},
    admin_agency_igloohome: {_value:""},
    admin_agency_sigfox_integration: {_value:""},
    admin_agency_billing: {_value:""},
    admin_agency_suspend: {_value:""},
    admin_agency_suspend_popup: {_value:""},
    panel_third_party_integration: {_value: ""},
    panel_igloohome: {_value: ""},
    popup_details_igloohome: {_value: ""},
    component_sigfox_config: {_value: ""},
    live_minute_rule: {_value: ""},
    panel_report_apad: {_value: ""},
    db_default: {_value: ""},
    asset_default: {_value: ""},
    report_default: {_value: ""},
    geofence_default: {_value: ""},
    channel_default: {_value: ""},
    users_default: {_value: ""},
    cl_default: {_value: ""},
    agency_default: {_value: ""},
    device_default: {_value: ""},
    third_default: {_value: ""},
    total_asset: {_value: ""},
    total_active_asset: {_value: ""},
    total_asset_count_seconds: {_value: ""},
    panel_basfare_module: {_value: ""},
    basfare_default: {_value: ""},
    panel_basfare: {_value:""},
    decode_secret_key: {_value:""},
    client_beacon_information: {_value:""}
}
const firebaseContext = createContext({ 
    firebaseConfig: null, 
    getValueByKey: (param) => {},
    remoteConfig: () => {}

})

const firebaseReducer = (state, action) => {
    // console.log("payload", action)
    switch (action.type) {
        case "FIREBASE":{
            //when a new payload comes in
            return {
                ...state, 
                firebaseConfig: action.firebaseConfig             
            };            
        }
        default:
        return state;
    }
}

const initialState = { firebaseConfig: null};

const FirebaseProvider = (props) => {
    const [state, dispatch] = useReducer(firebaseReducer, initialState);
   
    const remoteConfig = async () => {
        // console.log("testRemoteCOnfig")
            try{
                const remoteConfig = firebase.remoteConfig()
                remoteConfig.settings = {
                    minimumFetchIntervalMillis : 60000
                }   
                remoteConfig.defaultConfig = ({
                    'panelParam': '',
                });
               
                
                await remoteConfig.fetchAndActivate() 
                const response =  remoteConfig.getAll()      
                // console.log(response)  
                dispatch({ type: "FIREBASE", firebaseConfig: response})

            }catch(e){
                // console.log(e)
            }
        }
    
    useEffect(() => {
        remoteConfig()
    }, [])

    const getValueByKey = (param) => {
        if(!state.firebaseConfig && config[param]){
            // console.log("go here")
            // console.log(state.firebaseConfig[param]._value)
            // console.log(param)
           return config[param]._value
        }

       if(typeof state.firebaseConfig[param] !== "undefined"){
           return state.firebaseConfig[param]._value
       }
        
        
    }
    return (
        <firebaseContext.Provider value={{ firebaseConfig: state.firebaseConfig, getValueByKey, remoteConfig}} {...props} />
    );
}

export { firebaseContext, FirebaseProvider };
