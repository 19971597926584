import React, {useState} from 'react'
import { Tooltip } from '@material-ui/core';
import RedDeleteIcon from '../../Image/red_delete.svg'
import GreyDeleteIcon from '../../Image/grey_delete.svg'
import SuspendIcon from '../../Image/red_pause.svg'
import ReactiveIcon from '../../Image/green_active.svg'

//library
import { useMutation } from "@apollo/react-hooks";

//component
import {SUSPENDED_AGENCIES } from "../../config/config-graphql/Graphql";
import ModalDelete from './ModalDelete'
import ModalSuspend from './ModalSuspend'


const DeleteDeviceCard = ({title, setPageInd, buttonType, module, uuid, query, fx_back, schema_uuid, agency_id, setFilterFinal, suspend_agency_query, active, fx_delete, canDelete=true, details, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere, deleteTooltip}) => {


const [isHovered, setIsHovered] = useState({
  delete: false,
  suspend: false
})


const [delModal, setDelModal] = useState(false)
const [susModal, setSusModal] = useState(false)

//delete queries
const deleteQuery = query && query.deleteQuery;

//for refetchQueries mutation
const getQuery = query && query.getQuery;



//ALL delete module query
const [deleteData,{loading:delLoading, error:delError}] =  useMutation(deleteQuery, {
  refetchQueries: () => [
      {
          query: getQuery,
          variables: { filter: {} },
      },
  ],
    
 });

 //To call suspended API
 const [suspendAgency, {loading:suspLoading, error:suspError}] = useMutation(SUSPENDED_AGENCIES, {
  refetchQueries: () => [
      {
          query: getQuery,
          variables: { filter: {} },
      },
  ],
    
 });

 const switchModal = () => {
  setDelModal(!delModal)
 }

 const susSwithModal = () => {
  setSusModal(!susModal)
}

//  console.log(JSON.stringify(delError, null, 2));

//delete function
 const onDelete = async () => {
   try {
      if(fx_delete)fx_delete()
      else if(module === "agencies"){ //this call the agency DELETE API

        const response = await deleteData({ variables: {agency : [{uuid: uuid, id:agency_id}] } })
        if(response.errors){ //to check if the response has error
          setErrOpen(true)
          setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
          setErrSevere("error")
        }
        else{ // success
          setSnOpen(true)
          setMessage("Record has been successfully deleted")
          setSeverity("success")
          setFilterFinal({})
          setPageInd(false)
          fx_back()
        }
      }
      else if(module === "devices"){ //this for call the device DELETE API
        const response = await deleteData({ variables: {devices : [uuid]}  })
        if(response.errors){ // to check if the response has error
          setErrOpen(true)
          setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
          setErrSevere("error")
        }
        else{ //success
          setSnOpen(true)
          setMessage("Record has been successfully deleted")
          setSeverity("success")
          setFilterFinal({})
          setPageInd(false)
          fx_back()  
        }
      }
      else if(module === "user"){

        
        //module user variables are change from email to userId and agencyId
        const response = await deleteData({ variables: {userId : uuid, agencyId:agency_id} })

        if(response.errors){ // to check if the response has error
          // console.log("go here error")
          setErrOpen(true)
          setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
          setErrSevere("error")
        }
        else{ // success
          // console.log("go here not error")
          setSnOpen(true)
          setMessage("Record has been successfully deleted")
          setSeverity("success")
          fx_back()  
        }
      }
      else{ //this call for Firmware, SKU DELETE API, channel, DRIVER
        const response = await deleteData({ variables: {[schema_uuid] : [uuid]} })

        if(response.errors){ // to check if the response has error
          setErrOpen(true)
          setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
          setErrSevere("error")
        }
        else{ // success
          setSnOpen(true)
          setMessage("Record has been successfully deleted")
          setSeverity("success")
          fx_back()  
        }
      }
      // if(fx_back)   
   } catch (error) { //catch error
      console.log(error)
      setErrOpen(true)
      setErrMsg(JSON.stringify(error.message, null, 2))
      setErrSevere("error")
   }

}

//call suspended API
const onSuspend = async (e, value) => {
  try{
    // console.log("onSuspend");
    const response = await suspendAgency({ variables: {[schema_uuid] : {uuid: uuid, id: agency_id, active: value } } })
    if(response.errors){ //to check if the response has error
      setErrOpen(true)
      setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
      setErrSevere("error")
    }
    else{ //success
      if(active){
        setSnOpen(true)
        setMessage("Agency has been suspended")
        setSeverity("warning")
        setFilterFinal({})
        setPageInd(false)
        fx_back()
      }else{

        setSnOpen(true)
        setMessage("Agency has been reactivated")
        setSeverity("success")
        setFilterFinal({})
        setPageInd(false)
        fx_back()
      }

    }
  }catch(err){ //catch error
    setErrOpen(true)
    setErrMsg(JSON.stringify(err.message, null, 2))
    setErrSevere("error")
  }

}



//this is for the delete text details
const Details = () =>{
  return(
    details ? <div className="title-detail">{details}</div> : null
  )
}

// console.log(susModal)

// if (delLoading) return <div style={{position: "relative", right:"2px", top: "40px"}}><Loaders /></div>
// if (suspLoading) return <div style={{position: "relative", right:"2px", top: "40px"}}><Loaders /></div>

    return (
            <div className="box-info">
                {/* <div className="title">{title}</div> */}
                {/* <Details />             */}
                <div className="btn-container">
                  {
                    buttonType === "suspended" ? active ?
                    <div className="suspend-container">
                      <div className={`button-suspend-main ${isHovered.suspend ? "isHovered-btn-suspend" : "button-suspend"}`} onClick={e => setSusModal(true)} onMouseEnter={() => setIsHovered((current) => ({...current, suspend: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, suspend: false}))}>
                        <img loading="lazy" src={SuspendIcon} alt="suspend" />
                        <div style={{margin: "0px 0px 0px 14px"}}>Suspend</div>
                      </div>
                  </div> : <div className="suspend-container">
                      <div className={`button-active-main ${isHovered.suspend ? "isHovered-btn-active" : "button-active"}`} onClick={e => onSuspend(e, true)} onMouseEnter={() => setIsHovered((current) => ({...current, suspend: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, suspend: false}))}>
                        <img loading="lazy" src={ReactiveIcon} alt="active"/>
                        <div style={{margin: "0px 0px 0px 14px"}}>Activate</div>
                      </div>
                  </div> :  (
                       canDelete && buttonType !== "suspended" ? <div className={`button-delete  ${canDelete ? `${isHovered.delete ? "isHovered-btn-delete" : "delete-active"}` : "delete-disabled"}`}  onClick={() => setDelModal(true)} onMouseEnter={() => setIsHovered((current) => ({...current, delete: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, delete: false}))}>
                         <img loading="lazy" src={RedDeleteIcon} alt="delete"/>
                         <div style={{margin: "0px 0px 0px 14px"}}>Delete</div>
                       </div> :
                      //  <Tooltip title={deleteTooltip} placement="top" arrow={true}>
                         <div disabled={!canDelete} className={`button-delete  ${canDelete ? `${isHovered.delete ? "isHovered-btn-delete" : "delete-active"}` : "delete-disabled"}`} onClick={() => canDelete && setDelModal(true)} onMouseEnter={() => setIsHovered((current) => ({...current, delete: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, delete: false}))}>
                            <img loading="lazy" src={GreyDeleteIcon} alt="deleteDisable"/>
                            <div style={{margin: "0px 0px 0px 14px"}}>Delete</div>
                         </div>
                              
                      //  </Tooltip>                      
                  )
                  }
                    {/* { module === "agencies" ? 
                      ( 
                        // `${isHovered.delete ? "isHovered-btn-delete" : "delete-active"}`
                        // `${isHovered.suspend ? "isHovered-btn-suspend" : button-suspend}`
                        <div className="suspend-container">
                          <div className={`button-delete  ${canDelete ? `${isHovered.delete ? "isHovered-btn-delete" : "delete-active"}` : "delete-disabled"}`} onClick={canDelete && onDelete} onMouseEnter={() => setIsHovered((current) => ({...current, delete: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, delete: false}))}>Delete</div>
                          <div className={`button-delete ${isHovered.suspend ? "isHovered-btn-suspend" : "button-suspend"}`} onClick={e => active ? onSuspend(e, false) : onSuspend(e, true) } onMouseEnter={() => setIsHovered((current) => ({...current, suspend: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, suspend: false}))}>{ active ? "Suspended" : "Reactive" }</div>
                        </div>
                      )
                     : <button disabled={!canDelete} className={`button-delete  ${canDelete ? `${isHovered.delete ? "isHovered-btn-delete" : "delete-active"}` : "delete-disabled"}`} onClick={canDelete && onDelete} onMouseEnter={() => setIsHovered((current) => ({...current, delete: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, delete: false}))}>Delete</button>} */}

                     
                     <ModalDelete open={delModal} fx_close={switchModal} delFunction={onDelete}/>
                     {
                       buttonType === "suspended" && <ModalSuspend open={susModal} fx_close={susSwithModal} delFunction={onSuspend}/>
                     }
                     
                </div>

            <style jsx>
                {
                    `
                    .box-info{
                      }
                      .title{
                        margin: 0px 0px 4px 0px;
                        height: 21px;
                        color: #333333;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 21px;
                      }
                      .title-detail{
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 16px;
                      }
                      .title-no-detail{
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 16px;
                      }
                      .button-delete{
                        height: 32px;
                        width: 213px;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 16px;
                        text-align: center;
                        border-radius: 2px;
                      }
                      .button-suspend-main{
                        height: 32px;
                        width: 213px;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 16px;
                        text-align: center;
                        border-radius: 2px;
                      }
                      .button-active-main{
                        height: 32px;
                        width: 213px;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 16px;
                        text-align: center;
                        border-radius: 2px;
                      }
                      .delete-active{
                        border: 1px solid #EA2B1F;
                        color: #EA2B1F;
                        cursor: pointer;
                        display: flex;
                        align-items:center;
                        justify-content: center;
                      }
                      .isHovered-btn-delete{
                        border: 1px solid #EA2B1F;
                        color: #EA2B1F;
                        font-weight: 600;
                        cursor: pointer;
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                        display: flex;
                        align-items:center;
                        justify-content: center;
                      }
                      .button-suspend{
                        color: #EA2B1F;
                        border: solid 1px #EA2B1F;
                        cursor: pointer;
                        display: flex;
                        align-items:center;
                        justify-content: center;
                      }
                      .button-active{
                        color: #509D91;
                        border: solid 1px #509D91;
                        cursor: pointer;
                        margin: 0px 0px 0px 16px;
                        display: flex;
                        align-items:center;
                        justify-content: center;
                      }
                      .isHovered-btn-suspend{
                        color: #d1261b;
                        border: solid 1px #d1261b;
                        font-weight: 600;
                        cursor: pointer;
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                      .isHovered-btn-active{
                        color: #498f84;
                        border: solid 1px #498f84;
                        font-weight: 600;
                        cursor: pointer;
                        margin: 0px 0px 0px 16px;
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                      .delete-disabled{
                        border: 1px solid #939598;
                        color: #939598;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                      .btn-container{
                        width: 100%;
                      }
                      .suspend-container{
                        display: flex;
                        align-items: center;     
                      }
                    `
                }
            </style>
            </div>
    )
}

export default DeleteDeviceCard
