import React from 'react'


const UnpairedModel = ({ open, fx_close, fx_unpair, details}) => {

    return (
        <div className="unpair-modal-container" style={{display: !open && "none"}}>
            <div className="unpair-modal-background" onClick={fx_close}></div>
            <div className="unpair-modal-content">
                <div className="title-modal">Unpair Asset</div>
                <div className="title-desc">{details}</div>
                <div className="button-placeholder">
                    <button onClick={fx_close} className="unpair-button no-btn">No</button>
                    <button onClick={() => fx_unpair(fx_close)} className="unpair-button yes-btn">Yes</button>
                </div>
            </div>                    

            <style>
                {
                    `
                    .title-modal{
                        color: #F5A623;
                        font-size: 24px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 32px;
                        text-align: center;
                    }
                    .selection-input{
                        margin: 16px 0px 0px 0px;
                    }
                    .title-desc{
                        color: #939598;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                        text-align: center;
                    }
                    .unpair-modal-container{
                        position: fixed;
                        z-index: 1300;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                    }
                    .unpair-modal-background{
                        z-index: -1;
                        position: fixed;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                        background-color: rgba(0, 0, 0, 0.5);
                        -webkit-tap-highlight-color: transparent;
                    }
                    .unpair-modal-content{
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        // width: 680px;
                        // height: auto;
                        border-radius: 4px;
                        padding: 16px;
                        transform: translate(-50%, -50%);
                        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
                        background-color: #FFFFFF;
                        display: block;
                        
                    }
                    .unpair-modal-content h3{
                        text-align:center;
                    }
                    .button-placeholder{
                        display: flex;
                        justify-content: center;
                        margin: 24px 0px 0px 0px;
                    }
                    .unpair-button{
                        padding: 8px 70px;
                        font-size: 14px;
                        line-height: 16px;
                        border-radius: 5px;
                    }
                    .no-btn{
                        border: 1px solid #EA2B1F;
                        margin-right: 20px;
                        color: #EA2B1F;
                    }
                    .yes-btn{
                        background: #EA2B1F;
                        color: #fff;
                    }
                    .input-text{
                        box-sizing: border-box;
                        height: 40px;
                        width: 648px;
                        border: 1px solid #939598;
                        border-radius: 4px;
                      }
                    }
                    `
                }
            </style>     
        </div>

    )
}

export default UnpairedModel
