import React from 'react'

import TelegramIcon from '../../Image/telegram-icon.svg'
import SlackIcon from '../../Image/slack_icon.svg'
import WhatsappIcon from '../../Image/whatsapp_icon.svg'

const ListChannelLeave = ({data, notification, setNotification, setOpen, open, setAddNotification, addNotification, setRemoveNotification, removeNotification, isEdit}) => {

    const Icon = ({comm}) => {
        switch (comm) {
            case "Telegram":
                return <img loading="lazy" src={TelegramIcon} alt="msg-icon"/>
            case "slack":
                return <img loading="lazy" src={SlackIcon} alt="WiFi"/>
            case "whatsapp":
                return <img loading="lazy" src={WhatsappIcon} alt="GPS off"/>
            default:
                break;
        }  
    }

    const channelSelection = (e, x) => {
        e.preventDefault()
        let clone = [...notification.leave]
        clone.push(x)
        setNotification({...notification, leave: clone.filter((tag, index, array) => array.findIndex(t => t.uuid == tag.uuid) == index)}) 
        if (isEdit) {
            if (removeNotification.leave.includes(x.uuid)) {
                let test = removeNotification.leave.filter((i) => i !== x.uuid);
                setRemoveNotification({...removeNotification, leave: test.uuid});
            }
    
            if (!addNotification.leave.includes(x.uuid)) {
                setAddNotification({...addNotification, leave: [...addNotification.leave, x.uuid]})
            }
        }
        setOpen(false)  

    }
    return (
        <div className={`channel-container ${open && "channel-container-active"}`}>
            {
                data.map((x,i) => {
                    return(
                        <div className="list-data-container">
                            <div className="list-data-img">
                                <Icon comm={x.type} />
                            </div>
                            <div className="list-data-channel-name" onClick={(e) => channelSelection(e, x)}>{x.name}</div>
                        </div>
                    )
                })
            }
            <style>
                {
                    `.channel-container{
                        position: absolute;
                        background-color: white;
                        width: 100%;
                        left: 4px;
                        min-height: 100px;
                        max-height: 430px;
                        overflow: auto;
                        scrollbarwidth: none;
                        msoverflowstyle: none;
                        padding: 10px;
                        box-sizing: border-box;
                        border-top-right-radius: 0px;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                        transition: visibility 0s, opacity 0.5s linear;
                        box-shadow: 0px 1px 3px 0px #00000020;
                        z-index: 6;
                        cursor: pointer;
                    }
                    .list-data-container{
                        display: grid;
                        grid-template-columns: 24px 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 8px;
                        grid-template-areas:
                            ". .";
                    }
                    .channel-container.channel-container-active{
                        width: 100%;
                        visibility: visible;
                        opacity: 1;
                        transition: visibility 1s, opacity 0.5s linear;
                        margin: 0px 0px 0px 0px;
                    }
                    `
                }
            </style>
        </div>
    )
} 
export default ListChannelLeave
