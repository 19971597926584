import React, { useState, useEffect, useContext } from "react"; 
import Cookies from "js-cookie";
import menuConfig from "../../config/config-menu/menu";
import Tab from "../component-tab/Tab";
import {firebaseContext} from "../../config/config-firebase/FirebaseContext"


const SideBar = ({ active, handlePanel, activeSubModule, handleSubModule, page, collapseStatus, setFilter, float, setFloat }) => {
    const [collapse, setCollapse] = useState(false);
    const [timer, setTimer] = useState(true)
    const firebaseConfig = useContext(firebaseContext)
    const cookRoles = Cookies.get("role")

    // console.log(page)

    // console.log(handlePanel)
    /**
     * this is for the collapse mechanism
     * @param enter = true if onMouseEnter, false if onMouseLeave
     */
    const onHover = (enter) => {
        collapseStatus ? enter ? setCollapse(false) : setCollapse(true) : setCollapse(false);
        collapseStatus && setFloat(true)
    }

    //this use effect for on click on the topBar icon
    useEffect(() => {
        //on click on the topbar icon (navigation drawer icon)
        collapseStatus ? setCollapse(true) : setCollapse(false)
        setFloat(false);
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapseStatus])



    //this is to setTimeout timer
    useEffect(() => {
     setTimeout(() => {
          setTimer(false)
        }, 1000);
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return  (
    <div className={`sidebar-container ${collapse && "sidebar-collapse"} ${float && "sidebar-float"}`} onMouseEnter={(e) => onHover(true)} onMouseLeave={(e) => onHover(false)} >
      <div className="menu-placeholder">
          {
        //   timer ? <Loaders /> : <div className="menu-list">       
            timer ? <div>Loading...</div> : <div className="menu-list">        
                {
                  menuConfig[page].map( ({ icon, label, children, role, lightIcon, firebaseKey,}, i) => {             
                    return (role.includes(cookRoles) && firebaseConfig.getValueByKey(firebaseKey) === "true") ? (
                      <>
                        <Tab
                          style={{fontSize: "16px", lineSpacing: "16px"}}
                          key={i}
                          i={i}
                          page={page}
                          fx_click={handlePanel}
                          icon={icon}
                          lightIcon={lightIcon}
                          label={<span className="font">{label}</span>}
                          children={children}
                          active={active}
                          childrenActive={activeSubModule}
                          fx_children_click={handleSubModule}
                          collapse={collapse}
                          setFilter={setFilter}
                        />
                        </>
                    ) : null
                })
                }
            </div>
        }

      </div>
      {!collapse ? <p className="versioning">{firebaseConfig.getValueByKey("horizon_version")}</p> : null}
      <style>
        {`

          ::-webkit-scrollbar {
            width: 0;  /* Remove scrollbar space */
            background: transparent;  /* Optional: just make scrollbar invisible */
          }
          .sidebar-container {
            transition: width 200ms linear;
            left: 24px;
            padding: 0px;
            width: 237px;
            height: 100%;
            background: #ffffff;
            color: #fff;
            display: flex;
            justify-content: space-between;
            // z-index: 1100;
            box-sizing: border-box;
            flex-shrink: 0;
            flex-direction: column;
            line-spacing: 16px;
            // overflow: scroll;
          }
          .sidebar-container.sidebar-collapse{
              width: 76px;
              // box-shadow: 0px 0px 0px 0px #00000050;
          }
          .sidebar-container.sidebar-float{
              box-shadow: 0px 1px 3px 0px #00000020;
              position: fixed;
              left: 0;
              z-index: 10;
          }

          .user-placeholder {
            padding: 0 24px;
            display: flex;
            position: relative;
            align-items: center;
            border-bottom: 1px solid rgb(151, 151, 151);

          }
          .user-details {
            display: flex;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;
            box-sizing: inherit;
          }
          .user-img {
            height: 70px;
            width: 70px;
            border-radius: 50%;
          }
          .user-name-role {
            padding-left: 20px;
          }
          .user-name {
            color: #3a3a3a;
            font-size: 18px;
            font-weight: 550;
            font-family: "HelveticaNeue-Medium", "Helvetica Neue Medium",
              "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
          }
          .user-role {
            font-size: 16px;
            color: #333;
          }
          .menu-placeholder {
            flex: 1 1 auto;
            display: inline-block;
            position: relative;
            white-space: nowrap;
            overflow: scroll;
            overflow-x: hidden;
          }
          .versioning{
            font-size: 11px;
            color: #939598;
            text-align: left ;
            padding: 0px;
            margin: 12px 0px 12px 24px;
            
          }
          .menu-list {
            // padding: 24px;
            justify-content: center;
            flex-direction: column;
            display: flex;
            right: 24px;
            box-sizing: inherit;
            white-space: nowrap;
            font-size: 14px;
          }

          @media (min-width: 600px) .logo-placeholder, .user-placeholder {
            min-height: 64px;
          }
          .font{
            font-size: 12px;
            height: 40px;
            position: relative;
            bottom: 1px;
            line-height: 16px;
            color: #939598;
          }
          .icon{
            // padding: 0px 10px 0px 16px
          }
          span{
            // height: 40px;
            // padding-right: 24px;
          }
          .menu-list .menu-btn-placeholder .arrow-btn{
            color: #000;
            height: 40px;
            justify-content: flex-end;
            flex-direction: row;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            transition: all ease 200ms;
          }
        `}
      </style>
    </div>
  );
};

export default SideBar;
