import React, {useState} from 'react'

const ModalComplete = ({open, fx_close}) => {
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("") 

    const [isHovered, setIsHovered] = useState({
        btnYes: false,
    })

    return (
        <div className="confirm-modal-container"style={{display: !open && "none"}} >
            <div className="confirm-modal-background" onClick={fx_close}></div>
            <div className="confirm-modal-content">
                <div className="confirm-body-modal">
                    <div className="confirm-modal-title">Upload Success</div>
                    <div className="confirm-modal-details">CSV has been uploaded. An email will be sent once the device creation process is complete </div>
                    <div className="confirm-modal-btn">
                        <div className={isHovered.btnYes ? "isHovered-confirm-btn-yes" : "confirm-btn-yes"} onClick={fx_close}  onMouseEnter={() => setIsHovered((current) => ({...current, btnYes: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnYes: false}))}>Ok</div>
                    </div>
                </div>
            </div>
            <style>
                {
                    `.confirm-modal-container{
                        position: fixed;
                        z-index: 1300;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                    }
                    .confirm-modal-background{
                        z-index: -1;
                        position: fixed;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                        background-color: rgba(0, 0, 0, 0.5);
                        -webkit-tap-highlight-color: transparent;
                    }
                    .confirm-modal-content{
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        width: 687px;
                        border-radius: 4px;
                        transform: translate(-50%, -50%);
                        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
                        background: #F3F3F3;
                        display: block;
                    }
                    .confirm-body-modal{
                        padding: 16px;
                    }
                    .confirm-modal-title{
                        display: flex;
                        justify-content: center;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 24px;
                        margin: 0px 0px 8px 0px;
                    }
                    .confirm-modal-btn{
                        display: flex;
                        justify-content: center;
                        margin: 24px 0px 0px 0px;
                    }
                    .confirm-btn-yes{
                        background: #509D91;
                        border-radius: 2px;
                        color: #FFFFFF;
                        font-size: 14px;
                        height: 34px;
                        width: 213px;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items:center;
                    }
                    .isHovered-confirm-btn-yes{
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                        background: #509D91;
                        font-size: 14px;
                        border-radius: 2px;
                        color: #FFFFFF;
                        height: 34px;
                        width: 213px;
                        font-weight: 600;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items:center;
                    }
                    .confirm-modal-details{
                        text-align:center;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 24px;
                        color: #939598;
                        margin: 0px 0px 20px 0px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default ModalComplete
