import React from 'react'

const Board = ({ data, setIsHovered, isHovered }) => {
    return  (
        <div>
            <div className="assembly-container">
                <div className="details-assembly asse-text">{data.assembly ? data.assembly.board_class : ""} • {data.assembly.assembly_id} </div>
                <div className="details-assembly" onMouseEnter={() => setIsHovered((current) => ({...current, board_id: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, board_id: false}))}>
                    <div className="input-input">Board ID </div> 
                    <input type="text" className="text-box" placeholder="-" name="board_id" value={data.board_id ? data.board_id : ""} disabled={true}/>
                </div>
                <div className="details-assembly" onMouseEnter={() => setIsHovered((current) => ({...current, firmware: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, firmware: false}))}>
                    <div className="input-input">Firmware</div> 
                    <input type="text" className="text-box" placeholder="-" name="batch_id" value={data.firmware ? data.firmware.version : ""} disabled={true}/>
                </div>
            </div>
             
            <style>
                {
                    ` 
                    .assembly-container{
                        box-sizing: border-box;
                        border: 1px solid #939598;
                        border-radius: 4px;
                        padding: 16px;
                        margin: 16px 0px 0px 0px;
                    }
                    .details-assembly{
                        display: grid;
                        grid-template-columns: 184px 1fr;
                        grid-template-rows: 1fr;
                        gap: 1% 0px;
                        grid-template-areas: ". .";
                        align-items: center;
                        padding: 0px 0px 8px 0px;                      
                    }
                    .input-input{
                        width: 133px;
                        height: 34px;
                        display: flex;
                        align-items: center;
                    }
                    .selection-board{
                        border-style:none;
                        background-color: #FFF;
                        height: 24px;
                        width: 30%;
                        margin-bottom: 8px;
                        outline: none;
                    }
                    .text-box{
                        border-style: none;
                        height: 24px;
                        outline: none;
                    }
                    .asse-text{
                        font-size: 16px;
                        font-weight: 600;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default Board
