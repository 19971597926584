import React, { useState, useContext } from 'react'

//library
import { useQuery } from "@apollo/react-hooks";

//list of material ui
import TextField from '@material-ui/core/TextField';
import Autocomplete from "@material-ui/lab/Autocomplete";
import grey from "@material-ui/core/colors/grey";
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, InputAdornment } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

//date library
import moment from "moment-timezone";
import DateFnsUtils from '@date-io/date-fns';

//list of images
import AssetIcon from '../../Image/asset.svg'
// import PaireAssetDarkIcon from '../../Image/pair_dark.svg'
import CalendarIcon from '../../Image/calendar_dark.svg'
import GeofenceIcon from '../../Image/geofencing.svg'
import AlertIcon from "../../Image/alert.svg"
import GreySearchIcon from '../../Image/grey_search.svg'


//list of component
import {GET_GEOFENCE_REPORT, SEARCH_ASSET_N_GROUPS, SEARCH_POLICIES } from "../../config/config-graphql/Graphql";
import { firebaseContext } from "../../config/config-firebase/FirebaseContext"
import { isEmptyObject, isEmptyArray } from '../../config/config-function/function';
import Table from "./Table"
import Loaders from "../../component/component-loaders/Loaders"


const materialTheme = createMuiTheme({

    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: grey.A400,
            },
        },
        MuiPickerDTTabs: {
            tabs: {
                backgroundColor: grey.A400,
            }
        },
        MuiPickersDay: {
            day: {
                color: grey.A400,
            },
            daySelected: {
                backgroundColor: grey["400"],
            },
            dayDisabled: {
                color: grey["100"],
            },
            current: {
                color: grey["900"],
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: grey["400"],
            },
        },
        MuiPickersClock: {
            pin: {
                backgroundColor: grey["400"],
            }
        },
        MuiPickersClockPointer: {
            pointer: {
                backgroundColor: grey["400"],
            }
        },
    }
});

const autoComplete = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            clearIndicator: {
                // position: "absolute",
                // bottom: "5px",
                // right: "25px"
                "&:hover":{
                    backgroundColor: "#FFFFFF",
                }
            },
            endAdornment:{
                top: "0px",
                height: "100%",
                display: "flex",
                alignItems: "center"
            },
            popupIndicator:{
                "&:hover":{
                    backgroundColor: "#FFFFFF",
                }
            } 
        },
        MuiIconButton:{
            label:{
                height:"24px",
                width: "24px",
                "&:hover":{
                    backgroundColor: "rgba(0,0,0,0.03)",
                    borderRadius: "5px",
                },
            }
        }
    }
});

const useStyles = makeStyles((theme) => ({
    textField: {
        backgroundColor: "#FFFFFF",
        width: 240,
        marginLeft: 0,
        outline: "none"
    },
    autocomplete: {
        backgroundColor: "#FFFFFF",
        // outline: "none"
    }
}))

const PanelReportGeofence = () => {
    const firebaseConfig = useContext(firebaseContext)
    // moment.tz.setDefault("Asia/Kuala_Lumpur")
    // const browserUtcOffset = Math.abs(new Date().getTimezoneOffset() / 60);
    const tz = moment.tz.guess();
    moment.tz.setDefault(tz);
    const [tableView, seTableView] = useState(false)
    const [dataset, setDataset] = useState({
        fromTime: moment().tz(tz).hour(-24).format(),
        toTime: moment().tz(tz).format(),
        policyId: {},
        asset: [],
    })
    const [isHovered, setIsHovered] = useState({
        btnReport: false
    })
    const [alert, setAlert] = useState([])
    const [speed, setSpeed] = useState({})
    const { data: reportData, loading: reportLoading, error: reportError } = useQuery(GET_GEOFENCE_REPORT, { variables: { input: speed}});
    const { data: searchData, loading: searchLoading } = useQuery(SEARCH_ASSET_N_GROUPS, { variables: { search_key: "", searchType: "ASSET" } })
    const { data : searchGeofences, loading: geofenceLoading } = useQuery(SEARCH_POLICIES, { variables: { key: ""}});
    const classes = useStyles()

    const searchOptions = {
        options: !searchLoading && searchData && searchData.searchAssetnGroups && searchData.searchAssetnGroups.asset ? searchData.searchAssetnGroups.asset.map((asset) => { return asset && asset.name ? { uuid: asset.uuid, name: asset.name, paired_assets: asset && asset.paired && asset.paired_assets.length > 0 ? asset.paired_assets.map(x => { return { uuid: x.uuid, name: x.name } }) : [] } : { uuid: "", name: "", blobID: "", paired_assets: [] } }) : []
    };

    const geofenceOptions = {
        options: !geofenceLoading && searchGeofences && searchGeofences.searchGeofences ? searchGeofences.searchGeofences.map((x) => {return  x && x.name ? { firstLetter: x.name.charAt(0).toUpperCase(), uuid: x.uuid, name: x.name} : {firstLetter: "", uuid: "", name: ""}} ) :  []
    }

    //map the asset Ids
    const mappedAssetIds = searchOptions.options.map(x => {
        return [{ uuid: x.uuid, name: x.name, firstLetter: x.firstLetter}, ...x.paired_assets]
    })

    //flat the search options
    const flatAssetIds = {options: mappedAssetIds.flat(2)}

    //flatAssetOptions -- this fixed the nested array object
    const flatAssetOptions = flatAssetIds.options.map((x) => {
        return { uuid: x.uuid, name: x.name, firstLetter: x.name.charAt(0).toUpperCase() }
    })

    // console.log(flatAssetIds)
    // console.log("aaaa", flatAssetOptions)


    const onUserInput = (e, value) => {
        onChange(e, value)
    }

    //onChange based in onUserInput
    const onChange = (e, value) => {
        value ? setDataset({ ...dataset, ...value }) :
            setDataset({ ...dataset, ...e })
    };
    
    //when click the search button
    const onSearch = () => {
        setSpeed({
            fromTime: moment(`${dataset.fromTime}`).tz(tz).format(),
            toTime: moment(`${dataset.toTime}`).tz(tz).format(),
            policy_id: dataset.policyId.uuid,
            asset_id: dataset.asset.map(x => x.uuid),
            alert: alert
        })

        seTableView(!tableView)
    }

    const alertCheckbox = (e) => {
        if(e.target.checked){
            let clone = [...alert]
            clone.push(e.target.value)
            setAlert(clone)
        }else{
            setAlert(alert.filter( x => x !== e.target.value))
        }
    }

    return reportLoading ? (<div style={{ position: "relative", right: "2px", top: "40px" }}><Loaders /></div>) : tableView ? <Table fx_back={() => seTableView(!tableView)} reportData={reportData} loading={reportLoading} speed={dataset} reportError={reportError} /> : (
    <div className="r-telemetry-container">
            <div>
                <div className="panel-title">Geofence Log</div>
                <div className="panel-description">{firebaseConfig.getValueByKey("report_Geofence_Description")}</div>
            </div>
            <div className="input-container">
                <div className="geofence-policy-container">
                    <div className="icon-title">
                        <img loading="lazy" src={GeofenceIcon} alt="geofence-icon"/>
                        <div className="asset-title">Geofence Policy <p style={{color:"red", margin:"0px 0px 0px 2px"}}>*</p></div>
                    </div>
                    <div className="asset-details">{firebaseConfig.getValueByKey("report_geofence_log_policy")}</div>
                    <div className="asset-name-select">
                            <div className="asset-name">Policy Name</div>
                            <div className="asset-selection">
                                <ThemeProvider theme={autoComplete}>
                                    <Autocomplete
                                        {...geofenceOptions}
                                        name="asset_name"
                                        className={classes.autocomplete}
                                        id="combo-box-demo"
                                        loading={true}
                                        value={dataset.policyId}
                                        onChange={(e, value) => onUserInput(e, {"policyId": value})}
                                        getOptionLabel={(option) => option.name ? option.name : ""}
                                        options={geofenceOptions.options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                        groupBy={(option) => option.firstLetter}
                                        renderOption={option => {
                                            return <div className="autocomplete-container">
                                                <img loading="lazy" style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} src={AssetIcon} alt="asset-icon" />
                                                {option.name}
                                            </div>
                                        }}
                                        style={{ width: "100%" }}
                                        renderInput={(params) =>
                                            <div style={{position: "relative"}} ref={params.InputProps.ref}>
                                            {
                    
                                               !params.inputProps.value && (
                                                <span 
                                                    style={{
                                                        position: "absolute",
                                                        transform: "translate(1%, 96%)",
                                                        marginLeft: "5px"
                                                    }}
                                                    >
                                                <img loading="lazy" style={{ height: "20px", width: "20px" }} src={GreySearchIcon} alt="grey-icon" />
                                            </span>
                                                )
                                            }
                                            <TextField 
                                               {...params} 
                                               name="asset_name" 
                                               placeholder="search" 
                                               variant="outlined" 
                                               inputProps= {!params.inputProps.value ? {
                                                ...params.inputProps,
                                                style: { paddingLeft: "27px", position: "relative", top:"1px" } 
                                             }: {
                                                 ...params.inputProps
                                             }}
                                            />
                                        </div>
                                        }
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                </div>
                <div className="search-period-container">
                    <div className="icon-title">
                        <img loading="lazy" src={CalendarIcon} alt="asset-icon" />
                        <div className="asset-title">Search Period <p style={{color:"red", margin:"0px 0px 0px 2px"}}>*</p></div>
                    </div>
                    <div className="asset-details">{firebaseConfig.getValueByKey("report_geofence_log_search_description")}</div>
                    <div className="search-period-title">
                        <div className="search-from">
                            <div className="asset-name">From</div>
                            <div className="asset-selection">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <ThemeProvider theme={materialTheme}>
                                        <KeyboardDatePicker
                                            name="fromTime"
                                            value={dataset.fromTime}
                                            onChange={(e) => onUserInput({ "fromTime": e })}
                                            format="dd/MM/yyyy"
                                            inputVariant="outlined"
                                            className={classes.textField}
                                            style={{ width: "100%" }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton style={{ outline: "none" }}>
                                                            <img loading="lazy" src={CalendarIcon} alt="Icon"/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </ThemeProvider>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="search-to">
                            <div className="asset-name">To</div>
                            <div className="asset-selection">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <ThemeProvider theme={materialTheme}>
                                        <KeyboardDatePicker
                                            name="toTime"
                                            value={dataset.toTime}
                                            onChange={(e) => onUserInput({ "toTime": e })}
                                            format="dd/MM/yyyy"
                                            inputVariant="outlined"
                                            className={classes.textField}
                                            style={{ width: "100%" }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton style={{ outline: "none" }}>
                                                            <img loading="lazy" src={CalendarIcon} alt="Icon"/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </ThemeProvider>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="asset-input-container">
                    <div>
                        <div className="icon-title">
                            <img loading="lazy" src={AssetIcon} alt="asset-icon" />
                            <div className="asset-title">Asset</div>
                        </div>

                        <div className="asset-details">{firebaseConfig.getValueByKey("report_geofence_log_asset_description")}</div>
                        <div className="asset-name-select">
                            <div className="asset-name">Asset Name</div>
                            <div className="asset-selection">
                                <ThemeProvider theme={autoComplete}>
                                    <Autocomplete
                                        {...flatAssetIds}
                                        multiple
                                        name="asset_name"
                                        className={classes.autocomplete}
                                        id="asset_id"
                                        loading={true}
                                        value={dataset.asset}
                                        onChange={(e, value) => onUserInput(e, {"asset": value})}
                                        getOptionLabel={(option) => option.name ? option.name : ""}
                                        options={flatAssetOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                        groupBy={(option) => option.firstLetter}
                                        renderOption={option => {
                                            return <div className="autocomplete-container">
                                                <img loading="lazy" style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} src={AssetIcon} alt="asset-icon" />
                                                {option.name}
                                            </div>
                                        }}
                                        style={{ width: "100%" }}
                                        renderInput={(params) =>
                                            <div style={{position: "relative"}} ref={params.InputProps.ref}>
                                            {
                    
                                               !params.inputProps.value && (
                                                <span 
                                                    style={{
                                                        position: "absolute",
                                                        transform: "translate(1%, 96%)",
                                                        marginLeft: "5px"
                                                    }}
                                                    >
                                                <img loading="lazy" style={{ height: "20px", width: "20px" }} src={GreySearchIcon} alt="grey-icon" />
                                            </span>
                                                )
                                            }
                                            <TextField 
                                               {...params} 
                                               name="asset_name" 
                                               placeholder="search" 
                                               variant="outlined" 
                                               inputProps= {!params.inputProps.value ? {
                                                ...params.inputProps,
                                                style: { paddingLeft: "27px", position: "relative", top:"1px" } 
                                             }: {
                                                 ...params.inputProps
                                             }}
                                            />
                                        </div>
                                        }
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="alert-container">
                    <div>
                        <div className="icon-title">
                            <img loading="lazy" src={AlertIcon} alt="asset-icon" />
                            <div className="asset-title">Alert <p style={{color:"red", margin:"0px 0px 0px 2px"}}>*</p></div>
                        </div>

                        <div className="asset-details">{firebaseConfig.getValueByKey("report_geofence_log_alert")}</div>
                        <div className="alert-name-checkbox">
                            <div className="input-checkbox">
                                <input type="checkbox" value="Enter" onChange={(e) => alertCheckbox(e)}/>
                                <div className="text-policy" >Enter</div>
                            </div>
                            <div className="input-checkbox">
                                <input type="checkbox" value="Leave" onChange={(e) => alertCheckbox(e)}/>
                                <div className="text-policy">Leave</div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="button-click" onMouseEnter={() => setIsHovered((current) => ({...current, btnReport: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnReport: false}))}>
                <button className={
                    isEmptyObject(dataset.policyId) ? "btn-report-disabled" : 
                    isEmptyArray(alert) ? "btn-report-disabled" :
                    `${isHovered.btnReport ? "isHovered-btn-report": "btn-report"}`} 
                    
                    onClick={() => onSearch()} 
                    
                    disabled={
                        isEmptyObject(dataset.policyId) ? true :
                        isEmptyArray(alert)  ? "btn-report-disabled" : false} >
                        Run Report
                        </button>
                </div>
            </div>

            <style>
                {
                    `.r-telemetry-container{
                    padding: 24px;
                 }
                 .panel-title{
                    height: 32px;
                    color: #333333;
                    font-size: 24px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 32px;
                    margin: 0px 0px 4px 0px;
                 }
                 .panel-description{
                    height: 21px;
                    color: #939598;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 21px;
                 }
                 .input-container{
                    position: relative;
                    left: 55%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%); /* for IE 9 */
                    -webkit-transform: translate(-30%, 5%); /* for Safari */
                 }
                 .asset-input-container{
                     margin: 24px 0px 24px 0px;
                     padding: 16px;
                     width: 41.80vw;
                     border-radius: 4px;
                     background-color: #FFFFFF;
                 }
                 .search-period-container{
                    padding: 16px;
                    width: 41.80vw;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    // box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
                 }
                 .geofence-policy-container{
                    padding: 16px;
                    width: 41.80vw;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    margin: 0px 0px 24px 0px;
                    // box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
                 }
                 .alert-container{
                    margin: 24px 0px 0px 0px;
                    padding: 16px;
                    width: 41.80vw;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    margin: 0px 0px 24px 0px;
                    // box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
                 }
                 .asset-title{
                    display: flex;
                    margin: 0px 0px 0px 8px;
                    color: #333333;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 21px;
                 }
                 .asset-details{
                    margin: 8px 0px 16px 0px;
                    color: #939598;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 16px;
                 }
                 .icon-title{
                    display: flex;
                    align-items: center;     
                 }
                 .asset-name-select{
                    display: grid;
                    grid-template-columns: 0.5fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    align-items: center;
                 }

                 .alert-name-checkbox{
                     display:flex;
                     flex-direction: column;
                 }
                 .input-checkbox{
                     display: flex;
                     align-items: center;
                     margin: 0px 0px 8px 0px;
                 }
                 .text-policy{
                     margin: 0px 0px 0px 8px;
                 }
                 .asset-name{
                    height: 19px;
                    color: #939598;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                 }

                .autocomplete-container{
                    display: flex;
                    align-items: center;
                }
                .option-name{
                    margin: 0px 4px 0px 0px;
                }
                .asset-selection{
                }
                .search-period-title{

                }
                .search-from{
                    display: grid;
                    grid-template-columns: 0.5fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    align-items: center;
                    margin: 0px 0px 16px 0px;
                }
                .search-to{
                    display: grid;
                    grid-template-columns: 0.5fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    align-items: center; 
                }
                .button-click{
                    margin: 24px 0px 0px 0px;
                    width: 41.80vw;
                    display: flex;
                    justify-content: center;
                }
                .btn-report{
                    border-style: solid;
                    height: 32px;
                    width: 386px;
                    background-color: #509D91;
                    color: #FFF;
                }
                .isHovered-btn-report{
                    border-style: solid;
                    height: 32px;
                    width: 386px;
                    background-color: #498f84;
                    color: #FFF;
                    font-weight: 600;
                    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                }
                .btn-report-disabled{
                    border-style: solid;
                    height: 32px;
                    width: 386px;
                    background-color: #509D91;
                    color: #FFF;
                    font-weight: bold;
                    opacity: 0.7;
                }
                `
                }
            </style>
        </div>
    )
}
export default PanelReportGeofence
