import React, {useState, useEffect} from 'react'
import Form from './Form'

import { AllMandatoryFilled, onUserEdit} from "../../config/config-function/function";

import { CHANNEL_REDUCER } from "../../config/config-reducer/reducer";
import {requiredField} from '../../config/config-table/table'

const FunctionEdit = ({values, onChange, displayed, setEditVariables, setCanAdd, fx_back, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere, featureDisable}) => {
    const [types, setTypes] = useState(values[0].type)
    const canEdit = true;
    const [userListing, setUserListing] = useState(values[0].userList && [...values[0].userList.map(x => {return x})])
    const [notification, setNotification] = useState({
        generateEOT: values[0].notifications.generateEOT,
        generateEOD: values[0].notifications.generateEOD
    })
    const onUserInput = (e, value, isValue) => {
        let input = onUserEdit(e, value, isValue, onChange, values);
        const _check_normal = AllMandatoryFilled(requiredField.channel, input[0])
        _check_normal ? setCanAdd(true) : setCanAdd(false)
        setEditVariables({channel: input === null ? CHANNEL_REDUCER(values[0]) : CHANNEL_REDUCER(input[0])})
    }


    useEffect(() => {
        onUserInput(null, { type: types }, true)
                    //---this is to disable the warning for useEffect----//
            // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [types])

    useEffect(() => {
        onUserInput(null, {userList: userListing}, true)
                            //---this is to disable the warning for useEffect----//
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userListing])

    useEffect(() => {
        onUserInput(null, {notification: notification}, true)
                            //---this is to disable the warning for useEffect----//
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification])
    return (
        <Form channels={values} onUserInput={onUserInput} canEdit={canEdit} displayed={displayed} fx_back={fx_back} setUserListing={setUserListing} userListing={userListing} setTypes={setTypes} types={types} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} setErrMsg={setErrMsg} setErrOpen={setErrOpen} setErrSevere={setErrSevere} featureDisable={featureDisable} setNotification={setNotification} notification={notification}/>
    )
}
export default FunctionEdit
