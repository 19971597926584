import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie';

//image
import NavigationIcon from "../../Image/chevron_right.svg"

//component
import Loaders from "../../component/component-loaders/Loaders"
import { APIUpdateBeacon } from '../../config/config-restAPI/patchAPICalled'
import { APIDeleteBeacon } from '../../config/config-restAPI/deleteAPICalled'
import SnackBar from '../../config/config-snackbar/SnackBar'
import DeleteCard from '../../component/component-deletecardRest/DeleteCard'
import SaveCard from '../../component/component-save/SaveCard'
import Form from './Form'
import { requiredField } from '../../config/config-table/table'
import { AllMandatoryFilled } from '../../config/config-function/function'

const EditFunction = ({ title, setPageInd, fx_back, selected, fx_changePage,  history, setMainFilter, APICalled, setSnOpen, setMessage, setSeverity, role, roleConfig, isAdmin, isDashboard }) => {
  const [values, setValues] = useState({
    serialNumber: selected && selected.serialNumber ? selected.serialNumber : '',
    skuId: selected && selected.sku && selected.sku._id ? selected.sku._id : '',
    range: selected && selected.range ? selected.range : '',
    description: selected && selected.description ? selected.description : '',
    locationName: selected && selected.locationName ? selected.locationName : '',
    macAddress: selected && selected.macAddress ? selected.macAddress : '',
    coordinate: {
      latitude: selected && selected.coordinate ? selected.coordinate.latitude : null,
      longitude: selected && selected.coordinate ? selected.coordinate.longitude : null,
    },
    locationNote: selected && selected.locationNote ? selected.locationNote : '',
  });
  const skuIdDisplay = selected && selected.sku ? selected.sku.skuId : ''
  const token = Cookies.get('jwtToken');
  const agencyId = Cookies.get('agency-id');
  const [displayed, setDisplayed] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [isHovered, setIsHovered] = useState({
    btnDetails: false
  })

  // console.log(selected)
  //the errOpen, errMsg and errSevere is for snackbar props for error 
  const [errOpen, setErrOpen] = useState(false)
  const [errMsg, setErrMsg] = useState("")
  const [errSevere, setErrSevere] = useState("")

  const mandatoryRequiredField = () => {
    const returnValue = AllMandatoryFilled(requiredField.beacon, values);
    return returnValue;
  }

  useEffect(() => {
    if (mandatoryRequiredField()) {
      setCanEdit(true) // this will enabled the button. Because true makes the button enabled
    } else {
      setCanEdit(false); // this will disabled the button, because true makes the button disabled
    }
  }, [values])


  const rearrangeValue = (value) => {
    const beaconValue = {
      beacon: {}
    }
    beaconValue.beacon._id = selected._id
    if (value.serialNumber !== selected.serialNumber) {
      beaconValue.beacon.serialNumber = value.serialNumber;
    }
    if (value.range !== selected.range) {
      beaconValue.beacon.range = value.range;
    }
    if (value.skuId !== selected.sku._id) {
      beaconValue.beacon.skuId = value.skuId
    }
    if (value.description !== selected.description) {
      beaconValue.beacon.description = value.description
    }
    // if (value.locationName && (value.locationName !== selected.locationName)) {
    //   beaconValue.beacon.locationName = value.locationName
    // }
    beaconValue.beacon.locationName = value.locationName;
    if (value.macAddress !== selected.macAddress){
      beaconValue.beacon.macAddress = value.macAddress
    }
    if (value.coordinate.latitude) {
      if (selected.coordinate && (value.coordinate.latitiude !== selected.coordinate.latitude)) {
        beaconValue['beacon']['coordinate'] = {
          'latitude': value.coordinate.latitude,
          'longitude': value.coordinate.longitude,
        }
      } else {
        beaconValue['beacon']['coordinate'] = {
          'latitude': value.coordinate.latitude,
          'longitude': value.coordinate.longitude,
        }
      }
    }
    if (value.coordinate.longitude) {
      if (selected.coordinate && (value.coordinate.longitude !== selected.coordinate.longitude)) {
        beaconValue['beacon']['coordinate'] = {
          'latitude': value.coordinate.latitude,
          'longitude': value.coordinate.longitude
        }
      } else {
        beaconValue['beacon']['coordinate'] = {
          'latitude': value.coordinate.latitude,
          'longitude': value.coordinate.longitude
        }
      }
    }
    beaconValue.beacon.locationNote = value.locationNote;
    return beaconValue;
  }

  const onSubmit = async (e) => {
    try{
      e.preventDefault();
      const reducer = rearrangeValue(values);
      const response = await APIUpdateBeacon(token, agencyId, reducer, selected._id, setApiLoading);
      if (response.status === 200) {
        setSnOpen(true);
        setMessage("Record has been successfully added");
        setSeverity("success");
        APICalled();
        fx_back();
      } else {
        setErrOpen(true)
        setErrMsg(`Error!, Unable to update please try again`)
        setErrSevere("error")
      } 

    }catch(err){
      console.log(err)
      setErrOpen(true)
      setErrMsg(`Error!, Unable to update please try again`)
      setErrSevere("error")
    }

  };

  const onClickBack = () => {
    fx_back()
  }


  // console.log(setMainFilter)
  const onDelete = async () => {
    try {
      const reducer = { _ids: [selected._id]}
      const response = await APIDeleteBeacon(token, agencyId, reducer, setApiLoading);
      if (response.status === 200) {
        setSnOpen(true);
        setMessage("Record has been successfully deleted!");
        setSeverity("success");
        APICalled();
        fx_back();
      } else {
        setErrOpen(true)
        setErrMsg('Error!, Unable to update please try again')
        setErrSevere("error")
      } 

    } catch(err) {
      console.log(err)
      setErrOpen(true)
      setErrMsg('Error!, Unable to update please try again')
      setErrSevere("error")
    }
  }

  return (
    <div className="form-container">
      <div className="navigation-title-placeholder">
          <div className="title-add">
                <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                <div onClick={onClickBack} style={{cursor: "pointer"}} className="detail-title first-title">
                  {title}
                </div>
                <div className="detail-title">
                  <img loading="lazy" src={NavigationIcon} alt="Back"/>
                </div>   
                {
                  isAdmin ? (
                    <div className="detail-title">Edit {title}</div>
                  ) : (
                    <div className="detail-title">{selected !== undefined && selected.serialNumber}</div>
                  )
                }                   
                <div className="detail-title"></div>
          </div>
          <div className="button-delete-save-container">
              {
                isAdmin && (
                  <DeleteCard 
                  canDelete={true}
                  onDelete={onDelete}
              />
                )
              }

              <SaveCard onSubmit={onSubmit} canAdd={canEdit}/>
          </div>
      </div>
      <Form setDisplayed={setDisplayed} skuIdDisplay={skuIdDisplay} displayed={displayed} setMainFilter={setMainFilter} fx_changePage={fx_changePage} history={history} values={values} setValues={setValues} beacon={selected} role={role} roleConfig={roleConfig} isAdmin={isAdmin} isDashboard={isDashboard} />
      <style jsx>
        {
          `
          .title-add{
            display: flex;
            align-items: center;            
          }
          .form-container{
            margin: 24px;
            display: grid;
          }
          .navigation-title-placeholder {
            font-size: 18px;
            font-weight: 545;
            height: 32px;
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
          }
          .details {
            height: 40px;
            // margin-left: 24px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.54);
          }
          .autocomplete {
            display: flex;
            height: 45px;
            // margin-left: 24px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.54);
          }
          .textfield {
            position: relative;
            bottom: 40px;
          }
          .detail-title{
            padding: 8px;
          }
          .first-title{
            font-size: 16px; 
            padding-left: 0px;
          }
          .button-delete-save-container{
            display: flex;
          }
          `
        }
      </style>
    </div>
  )
}

export default EditFunction
