import React from 'react'

const NoAccess = ({size}) => {
    return (
        <div className="no-access-container" style={{height: `${size}px`}}>
            <div className="error-number">403</div>
            <div className="text-error">Access Forbidden</div>
            <style>
                {
                    `.no-access-container{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        width: 100%;
                        // margin: 24px 0px 0px 0px;
                    }
                    .error-number{
                        font-size: 150px;
                        font-weight: 600;
                    }
                    .text-error{
                        font-size: 24px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default NoAccess
