import React, {useState, useEffect}  from 'react'

//librarys
import BaseTable, { AutoResizer } from 'react-base-table'
import 'react-base-table/styles.css'
import moment from "moment-timezone";
import styled from 'styled-components'
import ReactTexty from 'react-texty'

//list of component
import NoReportData from '../../component/component-noreport/NoReportData'
import SnackBar from '../../config/config-snackbar/SnackBar'

//list of images
import GpsFixed from "../../Image/gps_fixed.svg"
import GpsOffs from "../../Image/gps_offs.svg"
import GeolocationIcon from "../../Image/geolocation_dark.svg"
import ExportIcon from '../../Image/export.svg'
import MoveIcon from "../../Image/move.svg"
import StopIcon from "../../Image/stop.svg"
import IdleIcon from "../../Image/device-idle.svg"
import TowingIcon from '../../Image/towing.svg'
import LocationNotFoundIcon from "../../Image/default.svg"
import NavigationIcon from "../../Image/chevron_right.svg"


const Container = styled.div`
    width: 100%; 
    height: 85vh;  
`

const motionIcon = (data) => {
    switch (data) {
        case 200:
            return MoveIcon 
        case 100:
            return StopIcon
        case 400:
            return IdleIcon
        case 500:
            return TowingIcon
        default:
            return LocationNotFoundIcon
    }
}

const motionText = (data) => {
    switch (data) {
        case 200:
            return "Moving" 
        case 100:
            return 'Stop'
        case 400:
            return 'Idle'
        case 500:
            return 'In Tow'
        default:
            return "-"
    }
}

const telemetry_report = [
    { key: "timestamp", title: "Date/Time", dataKey: "timestamp", width: 250, flexGrow: 1, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> },
    { key: "position", title: "Position", dataKey: `position`, width: 150, flexGrow: 1, cellRenderer: ({ cellData }) =>
    <ReactTexty>
        {cellData !== "-" ? <a href={`https://www.google.com/maps/search/?api=1&query=${cellData}`} rel="noopener noreferrer" target="_blank">{cellData}</a> : "-"}
    </ReactTexty>
     }
     ,
    // {
    //     key: "geolocation", title: "Geolocation", dataKey: "geolocation", width: 120, flexGrow: 1, cellRenderer: ({ cellData }) => (
    //         <ReactTexty>
    //             <div style={{display: "flex", alignItems:"center"}}>
    //                 <img src={cellData === "GPS" ? GpsFixed : cellData === "WIFI" ? GeolocationIcon : cellData === "NoWifi" ? GpsOffs : cellData === "Garbage" ? GpsOffs : cellData === "Wifi" ? GeolocationIcon : GpsOffs} style={{ height: "16px", width: "16px" }} alt="icon" className="img-icon" />
    //                 {cellData === "GPS" ? "GPS" : cellData === "WIFI" ? "WIFI" : cellData === "NoWifi" ? "NoWifi" : cellData === "Garbage" ? " -" : cellData === "Wifi" ? "WIFI" : "-"}
    //             </div>

    //         </ReactTexty>)
    // },
    {
        key: "motionInference", title: "Motion State", dataKey: "motionInference", width: 120, flexGrow: 1, cellRenderer: ({ cellData }) => (
            <ReactTexty>
                <div style={{display: "flex", alignItems:"center"}}>
                    {/* <img src={cellData === 200 ? MoveIcon : cellData === 100 ? StopIcon : cellData === 400 ? IdleIcon : cellData === 500 ? TowingIcon : LocationNotFoundIcon} style={{ height: "16px", width: "16px" }} alt="icon" className="img-icon" /> */}
                    <img src={motionIcon(cellData)} style={{ height: "16px", width: "16px" }} alt="icon" className="img-icon" />
                    {cellData && motionText(cellData)}
                </div>              
            </ReactTexty>)
    },
    { key: "kmh", title: "Speed (km/h)", dataKey: "kmh", width: 120, flexGrow: 1 },
    {
        key: "dcVolt", title: "Asset Battery (volt)", dataKey: "dcVolt", width: 120, flexGrow: 1, cellRenderer: ({ cellData }) => (
            <ReactTexty>
                {cellData ? cellData : "-"}
            </ReactTexty>)
    },
    {
        key: "battVolt", title: "Device Battery (volt)", dataKey: "battVolt", width: 120, flexGrow: 1, cellRenderer: ({ cellData }) => (
            <ReactTexty>
                {cellData ? cellData : "-"}
            </ReactTexty>)
    },
    {
        key: "fuelLevel", title: "Fuel (litres)", dataKey: "fuelLevel", width: 120, flexGrow: 1, cellRenderer: ({ cellData }) => (
            <ReactTexty>        
                {cellData ? cellData : "-"}
            </ReactTexty>)
    },
    { key: "connClass", title: "Connectivity", dataKey: "connClass", width: 120, flexGrow: 1 },
]

const Table = ({ searchValue, reportData, reportLoading, fx_back, searchError }) => {

    //this state for error handling on getQuery
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")


    //export csv file
    // let csvFile = "Date,Latitude,Longitude,Geolocation,Motion_State,Speed,Voltage,Fuel,Connectivity\r\n"
    let csvFile = "timestamp,latitude,longitude,motionInference,kmh,dcVolt,battVolt,fuelLevel,connClass\r\n"

    // !reportLoading && reportData && reportData.getTelemetryLogRpt && reportData.getTelemetryLogRpt !== null && reportData.getTelemetryLogRpt.map(x => csvFile += `${moment(x.time).format("X") || "N/A"},${parseFloat(x.latitude).toFixed(6) || "N/A"},${parseFloat(x.longitude).toFixed(6) || "N/A"},${x.geolocation || "N/A"},${x.motion_state || "N/A"},${x.speed || "N/A"},${x.devSuppState === 100 ? x.dcVolt : x.devSuppState === 200 && x.devSuppState === 300 ? x.battVolt : x.devSuppState === 400 ? 'debugging' : 'N/A' || "N/A"},${x.fuel || "N/A"},${x.connectivity || "N/A"}\r\n`)
    !reportLoading && reportData && reportData.getTelemetryLogRpt && reportData.getTelemetryLogRpt !== null && reportData.getTelemetryLogRpt.map(x => csvFile += `${moment(x.timestamp).format("X") || ""},${parseFloat(x.latitude).toFixed(6) || ""},${parseFloat(x.longitude).toFixed(6) || ""},${motionText(x.motionInference) || ""},${x.kmh ? parseFloat(x.kmh).toFixed(2) : ""},${x.dcVolt || ""},${x.battVolt || ""},${x.fuelLevel || ""},${x.connClass || ""}\r\n`)
    const url = `data:text/json;charset=utf-8,${encodeURIComponent(csvFile)}`

    // convert timestamp
    const convertTimestamp = (time) => {
        const convTs = moment(time).format("X");
        const result = moment.unix(convTs).format('DD MMMM YYYY, hh:mm:ss a');
        return result;
    }

    const setData = (data) => {
        // x.connClass[0].toUpperCase() + x.connClass.substring(1) 
        // console.log(data);
        if (!reportLoading) {
            return data && data.map((docs, key) => {
                // console.log(docs);
                return {
                    key: key,
                    connClass: `${docs.connClass ? docs.connClass : '-'}`,
                    fuelLevel: `${docs.fuelLevel ? parseFloat(docs.fuelLevel).toFixed(2) : '-'}`,
                    // geolocation: `${docs.geolocation ? docs.geolocation : '-'}`,
                    motionInference: docs.motionInference ? docs.motionInference : '-',
                    kmh: `${!isNaN(docs.kmh) ? parseFloat(docs.kmh).toFixed(2) : '-'}`,
                    timestamp: docs.timestamp ? convertTimestamp(docs.timestamp) : '-',
                    dcVolt: `${docs.dcVolt? parseFloat(docs.dcVolt) : '-'}`,
                    battVolt: `${docs.battVolt? parseFloat(docs.battVolt) : '-'}`,
                    position: `${docs.latitude !== 0 && docs.longitude !== 0 ? docs.latitude !== null && docs.longitude !== null ? `${parseFloat(docs.latitude).toFixed(6)},${parseFloat(docs.longitude).toFixed(6)}` : "-" : "-"}`
                }
            })
        }
    }

    useEffect(() => {
        if(searchError){
            setSnOpen(true)
            setMessage("Error: Unable to retrieve data. Please try again later.")
            setSeverity("error")
        }
    }, [searchError])
    return (
        <div className="telemetry-table-container">
            <div className="title-log">
                <div className="telemetry-title">
                    <div className="report-title" onClick={() => fx_back()}>Telemetry Log</div>
                    <div className="icon-title">
                        <img loading="lazy" src={NavigationIcon} alt="nav-icon" />
                    </div>
                    <div className="asset-title-name">
                        <div>{searchValue.asset_uuid.name}</div>
                    </div>
                </div>
                <div className="export-container">
                    <img loading="lazy" src={ExportIcon} alt="export-icon" />
                    &nbsp; <a href={url} download="TelemetryReport.csv" style={{ alignItems: "center", verticalAlign: "bottom" }}>Export Log</a>
                </div>
            </div>
            <div className="header-table">
            </div>
            {
                searchError ? <SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/> : !reportLoading && reportData && reportData.getTelemetryLogRpt && reportData.getTelemetryLogRpt !== null && reportData.getTelemetryLogRpt.length > 0 ?
                    (
                        <div className="white-box">
                            <Container>
                                <AutoResizer>
                                    {({ width, height }) => (
                                        <BaseTable columns={telemetry_report} width={width} height={height} data={setData(reportData.getTelemetryLogRpt)} />
                                    )}
                                </AutoResizer>
                            </Container>
                        </div>
                    ) : <NoReportData />
            }
            <style>
                {
                    `
                    .telemetry-table-container{
                        padding: 16px;
                        // display: flex;
                        // align-items: center;
                    }
                    .header-table{
                        // margin: 8px 0px 8px 0px;
                        // margin: 
                        // height: 51px;
                        // border-radius: 4px 4px 0 0;
                        // background-color: #FFFFFF;
                        // display: grid;
                        // grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                        // grid-template-rows: 1fr;
                        // gap: 0px 0px;
                        // grid-template-areas:
                        //   ". . . . . . . .";
                    }
                    .white-box{
                        height: 85vh;
                        display: grid;
                        background-color: #FFF;
                        overflow: auto;
                    }
                    .card-table{
                        height: 34px;
                        border-radius: 4px 4px 0 0;
                        background-color: #FFFFFF;
                        display: grid;
                        grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". . . . . . . .";
                    }
                    .card-body{
                        border-bottom: 1px solid #DDDDDD;
                        display: flex;
                        align-items: center;
                        padding: 16px;
                        color: #333333;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .img-icon{
                        margin: 0px 4px 0px 4px;
                    }
                    .text-header{
                        display: flex;
                        align-items: center;
                        padding: 16px;
                        color: #333333;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .place-button{
                        position: relative;
                        bottom:2px;
                        // width: 16px;
                        // height: auto;
                    }
                    .wrapper{
                        word-wrap: break-word
                    } 
                    .card-asset-row{
                        // margin: 0px 0px 8px 0px;
                        display: flex;
                        flex-direction: column;
                        color: #424242;
                        padding: 8px 0px 0px 16px;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .date-time-asset{
                        color: #424242;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .telemetry-title{
                        display: flex;
                        align-items: center;
                    }
                    .icon-title{
                        margin: 0px 8px 0px 0px;
                    }
                    .asset-title-name{
                        font-size: 18px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 24px;
                    }
                    .report-title{
                        margin: 0px 8px 0px 0px;
                        color: #333333;
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 21px;
                        cursor: pointer;
                    }
                    .export-css{
                        color: #333333;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19p
                    }
                    .export-container{
                        display: flex;
                        align-items: center;
                    }
                    .title-log{
                        display: flex;
                        justify-content: space-between;
                        margin: 0px 0px 24px 0px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default Table
