const tableConfig = {
    options: {
        selection: true,
    },
    columns: {
        agency: [
        { text: "ID", dataField: "agency_id" },
        { text: "Name", dataField: "agency_name" },
        { text: "Description", dataField: "agency_description" },
        ],
        firmware: [
        { text: "UUID", dataField: "uuid", hidden: true },
        { text: "ID", dataField: "id" },
        { text: "Name", dataField: "name" },
        { text: "Version", dataField: "version" },
        { text: "Release Date", dataField: "release_date" },
        { text: "Description", dataField: "description" },
        ],
        batch: [
        { text: "UUID", dataField: "uuid", hidden: true },
        { text: "ID", dataField: "id" },
        { text: "Name", dataField: "name" },
        { text: "Manufacturing Date", dataField: "manufacturing_date" },
        { text: "Count", dataField: "device_count"}
        ],
        asset: [
        { text: "UUID", dataField: "uuid", hidden: true},
        { text: "group_uuid", dataField: "group_uuid", hidden: true},
        { text: "device_uuid", dataField: "device_uuid", hidden: true },
        { text: "ID", dataField: "id",},
        { text: "Name", dataField: "name"},
        { text: "Type", dataField: "type"},
        { text: "Manufacturer", dataField: "manufacturer"},
        { text: "Model", dataField: "model" },
        { text: "Asset Group", dataField: "group_name" },
        { text: "Device", dataField: "sn" },
        { text: "Description", dataField: "description" },
        ],
        assetGroup: [
        { text: "UUID", dataField: "uuid", hidden: true},
        { text: "ID", dataField: "id" },
        { text: "Name", dataField: "name" },
        // { text: "Asset", dataField: "asset_id" },
        { text: "Description", dataField: "description" },
        ],
        manufacturer: [
            {text: "UUID", dataField: "uuid", hidden: true},
            { text: "Name", dataField: "name"},
            { text: "ID", dataField: "id"},
            { text: "Type", dataField: "type"},
            { text: "Contact number", dataField: "contact_number"},
            { text: "URL", dataField: "url"},
            { text: "Country of Origin", dataField: "country_of_origin"}
        ],
        component: [
            { text: "UUID", dataField: "uuid", hidden: true},
            { text: "Manufacturer Part Number", dataField: "manufacturer_part_number"},
            { text: "Name", dataField: "name"},
            { text: "Manufacturer", dataField: "mfr_name"},
            { text: "Type", dataField: "type"},
            { text: "mfr_UUID", dataField: "mfr_uuid", hidden: true},
        ],
        schematic: [
            {text: "UUID", dataField: "uuid", hidden: true},
            { text: "Drawing Reference ID", dataField: "drawing_reference_id"},
            { text: "Revision", dataField: "revision"},
            { text: "Description", dataField: "description"},
            // { text: "Revision", dataField: "revision"}
        ],
        assembly: [
            {text: "UUID", dataField: "assembly_uuid", hidden: true},
            {text: "schematic_uuid", dataField: "schematic_uuid", hidden: true},
            {text: "schematic_dwag_ref", dataField: "schematic.drawing_reference_id", hidden: true},
            { text: "Reference ID", dataField: "reference_id"},
            // { text: "Name", dataField: "revision"},
            { text: "Revision", dataField: "revision"},
            { text: "Dimensions", dataField: "board_dimensions"},
            { text: "Schematic", dataField: "assy_schematic"},
            { text: "Schematic", dataField: "schematic", hidden: true},
            { text: "Description", dataField: "description"},
        ],
        assembly_component: [
        { text: "UUID", dataField: "uuid", hidden: true},
        { text: "Manufacturer Part Number", dataField: "manufacturer_part_number"},
        { text: "Name", dataField: "name"},
        { text: "Manufacturer", dataField: "manufacturer.name"},
        { text: "Type", dataField: "type"},
        { text: "mfr_UUID", dataField: "manufacturer.uuid", hidden: true},

        ],
        sku: [
            { text: "UUID", dataField: "sku_uuid", hidden: true},
            { text: "ID", dataField: "id" },
            { text: "Name", dataField: "name" },
            { text: "Version", dataField: "version" },
            { text: "Internal Name", dataField: "internal_name", hidden: true },
            { text: "Internal Version", dataField: "internal_version", hidden: true },
        ],
        sku_assembly: [ 
            {text: "UUID", dataField: "assembly_uuid", hidden: true},
            {text: "schematic_uuid", dataField: "schematic.uuid", hidden: true},
            {text: "schematic_dwag_ref", dataField: "schematic.drawing_reference_id", hidden: true},
            { text: "Reference ID", dataField: "reference_id"},
            // { text: "Name", dataField: "revision"},
            { text: "Revision", dataField: "revision"},
            { text: "Dimensions", dataField: "board_dimensions"},
            { text: "Schematic", dataField: "assy_schematic"},
            { text: "Description", dataField: "description"},
        ],
        device: [
            { text: "UUID", dataField: "uuid", hidden: true},
            { text: "batch_UUID", dataField: "batch_uuid", hidden: true},
            { text: "firmware_UUID", dataField: "firmware_uuid", hidden: true},
            { text: "sku_UUID", dataField: "sku_uuid", hidden: true},
            { text: "SN", dataField: "sn"},
            { text: "Sigfox ID", dataField: "sigfox_id" },
            { text: "Cellular ID", dataField: "cellular_id"},
            // { text: "PAC", dataField: "pac"},
            { text: "Name", dataField: "name" },
            { text: "Firmware", dataField: "firmware_id" },
            { text: "SKU", dataField: "sku_id" },
            { text: "Batch", dataField: "batch_id" },
            { text: "Agency", dataField: "agency_id"},
            { text: "agency_uuid", dataField: "agency_uuid", hidden: true},
            // { text: "Sigfox Backend Integration", dataField: "sigfox_deviceTypeId", hidden:false},
        ],
        device_live: [
            { text: "SN", dataField: "sn"},
            { text: "Sigfox ID", dataField: "sigfox_id" },
            { text: "Cellular ID", dataField: "cellular_id"},
            { text: "Name", dataField: "name" },
            { text: "Firmware", dataField: "firmware_name" },
            { text: "SKU", dataField: "sku_name" },
        ],
        device_agencies: [
            { text: "UUID", dataField: "uuid", hidden: true},
            { text: "batch_UUID", dataField: "batch_uuid", hidden: true},
            { text: "firmware_UUID", dataField: "firmware_uuid", hidden: true},
            { text: "sku_UUID", dataField: "sku_uuid", hidden: true},
            { text: "ID", dataField: "id"},
            { text: "Sigfox ID", dataField: "sigfox_id" },
            { text: "Cellular ID", dataField: "cellular_id"},
            { text: "name", dataField: "name" },
            { text: "Firmware", dataField: "firmware_name" },
            { text: "SKU Name", dataField: "sku_name" },
            { text: "Batch", dataField: "batch_name" },
        ],
        telemetric: [
        // {text: "ID", dataField:"seqNumber", hidden: true},
        {text: "Date/Time", dataField:"dateTime"},
        {text: "Position", dataField:"position"},
        {text: "hidden-position", dataField:"hiddenPosition", hidden: true},
        {text: "GPS Fix", dataField:"gps_wifi"},
        {text: "Motion State", dataField:"device_status"},
        {text: "Speed (km/h)", dataField:"kmh"}, 
        {text: "Satelites in use", dataField:"nsat"},  
        {text: "Voltage (V)", dataField:"voltage"},
        {text: "Temperature (ºC)", dataField:"temp"},
        {text: "Operator Name", dataField:"operatorName"},
        {text: "Signal strength", dataField:"lqi"},

        ],
        agencies: [
            { text: "UUID", dataField: "uuid", hidden: true},
            { text: "groupID", dataField: "sigfox_groupId", hidden: true},         
            { text: "ID", dataField: "id"},
            { text: "Name", dataField: "name"},
            { text: "Contact Number", dataField: "contact_number"},
            { text: "Email", dataField: "email"},
            { text: "Address", dataField: "address"},
            { text: "Sigfox Backend Integration", dataField: "sigfox_deviceTypeId", hidden:false},
        ],
        agencies_device: [
            { text: "UUID", dataField: "uuid", hidden: true},
            { text: "batch_UUID", dataField: "batch_uuid", hidden: true},
            { text: "firmware_UUID", dataField: "firmware_uuid", hidden: true},
            { text: "sku_UUID", dataField: "sku_uuid", hidden: true},
            { text: "ID", dataField: "id"},
            { text: "Sigfox ID", dataField: "sigfox_id" },
            { text: "Cellular ID", dataField: "cellular_id"},
            { text: "name", dataField: "name" },
            { text: "Firmware", dataField: "firmware_name" },
            { text: "SKU Name", dataField: "sku_name" },
            { text: "Batch", dataField: "batch_name" }
        ],
        asset_reports : [
            { text: "ID", dataField: "id", hidden: true},
            { text: "Time", dataField: "time" },
            { text: "Duration (min)", dataField: "duration" }, 
            { text: "Rate (%)", dataField: "rate" },
        ],
        vehicle_reports : [
            {text: "ID", dataField: "id", hidden: true},
            { text: "Date", dataField: "date" },
            { text: "Average Speed (km/h)", dataField: "value" },
            { text: "Latitude", dataField: "latitude" },
            { text: "Longitude", dataField: "longitude" },
        ],
        alert_reports : [
            {text: "ID", dataField: "id", hidden: true},
            { text: "Date", dataField: "time" },
            // { text: "", dataField: "value" },
        ],
        telemetry_report: [
            {key: "time", title: "Date/Time", dataKey: "time", flexGrow:1, flexShrink : 1, resizable: true},
            {key: "position", title:"Position", dataKey:`position`, flexGrow:1, flexShrink : 1, resizable: true},
            {key: "geolocation", title: "Geolocation", dataKey: "geolocation", flexGrow:1, flexShrink : 1, resizable: true},
            {key: "motion_state", title: "Motion State", dataKey: "motion_state", flexGrow:1, flexShrink : 1, resizable: true},
            {key: "speed", title: "Speed", dataKey: "speed",  flexGrow:1, flexShrink : 1, resizable: true},
            {key: "voltage", title: "Voltage", dataKey: "voltage",  flexGrow:1, flexShrink : 1, resizable: true} ,
            {key: "fuel", title: "Fuel", dataKey: "fuel",  flexGrow:1 ,flexShrink : 1, resizable: true},
            {key: "connectivity", title: "Connectivity", dataKey: "connectivity", flexGrow:1,flexShrink : 1, resizable: true},
        ]
    },
    fieldList: {
        asset: {
            id: { name: "id", required: true, type: "text" },
            name: { name: "name", required: true, type: "text" },
            type: { name: "type", required: false, type: "text" },
            manufacturer: { name: "manufacturer", required: false, type: "text" },
            model: { name: "model", required: false, type: "text" },
            group: { id:"assetGroupName", name: "group_name", required: true, },
            description: { name: "description", required: false, type: "text"},
            device: { id:"deviceNamess", name:"asset_device", required: false}
        },
        asset_group: {
            id: { name: "id", required: true, type:"text" },
            name: { name: "name", required: true, type:"text"},
            description: { name:"description", required: false, type:"text" }
        },
        firmware: {
            name: { name: "name", required: true, type: "text"},
            version: { name: "version", required: true, type: "text" },
            release_date: { name: "release_date", required: true, type: "text"},
            board_class: {name: "board_class", required: true, type:"text"},
            git_SHA: { name: "git_SHA", required: true, type: "text"},
            confluence_link: { name: "confluence_link", required: false, type: "text"},
            release_notes: { name: "release_notes", required: false, type: "text"}
        },
        batch: {
            id: { name:"id", required: true, type: "text"},
            name: { name: "name", required: true, type: "text"},
            manufacturing_date: { name: "manufacturing_date", required: true, format:"MM/dd/yyyy"},
            device_count: { name: "device_count", required: false, disabled: true, type: "number"}
        },
        manufacturer: {
            name: { name: "name", required: true, type: "text"},
            id: { name:"id", required: false, type: "text"},
            type: { name: "type", required: false, type:"text"},
            contact_number: { name: "contact_number", required: false, type: "text"},
            url: { name: "url", required: false, type: "text"},
            country_of_origin: { name: "country_of_origin", required: false, type: "text"}
        },
        component: {
            manufacturer_part_number: { name: "manufacturer_part_number", required: true, type: "text"},
            name: { name:"name", required: true, type: "text"},
            manufacturer: { id:"manufacturer", name: "manufacturer", required: true, },
            type: { name: "type", required: true, type: "text"}
        },
        schematic: {
            drawing_reference_id: { name: "drawing_reference_id", required: true, type: "text"},
            revision: { name:"revision", required: true, type: "text"},
            description: { name: "description", required: false, type: "text"}
        },
        assembly: {
            reference_id: { name: "reference_id", required: true, type: "text"},
            revision: { name:"revision", required: true, type: "text"},
            board_dimensions: { name:"board_dimensions", required: false, type: "text"},
            schematic: { id:"schematic", name: "schematic", required: true, },
            components: { name: "component", required: true },
            description: { name: "description", required: false, type: "text" }
        },
        sku: {
            product_model: { name: "productModel", required: false, type: "text"},
            product_category: { name:"productCategory", required: false, type: "text"},
            id: { name: "id", required: false},
            manufacturer: { name: "manufacturer", required: true, type: "text"},
            product: { name: "product", required: true, type: "text"},
            version: { name: "version", required: true, type: "text"},
            assemblies: { name: "assemblies", required: false},
            revision: { name: "revision", required: true, type: "text" },
            project_name: { name: "projectName", required: false, type: "text" },
            feature: { name: "feature", required: false, type: "text" },
            board_class: {name: "boardClass", required: false},
            assembly_id: {name: "assemblyId", required: false, type: "text"},
            schematic_id: {name: "schematicId", required: false, type: "text"}
        },
        device:  {
            sn: { name: "sn", required: true, type: "text"},
            description: { name: "description", required: false, type: "text"},
            firmware: { name: "firmware", required: true},
            board_id: {name: "board_id", required: true},
            SKU: { name: "SKU", required: true},
            // batch: { name: "batch", required: true},
            // agency: { name: "agency", required: false},
        },
        beaconAdminConsole: {
            serialNumber: { name: 'serialNumber', required: true, type: "text"},
            macAddress: { name: 'macAddress', required: true, type: "text"},
            skuId: {name: 'skuId', required: true, type: "text"},
            range: {name: 'range', required: false, type: 'number'},
            description: {name: 'description', required: false, type: 'text'},
        },
        agencies: {
            id: { name: "id", required: true, type: "text"},
            name: { name: "name", required: true, type: "text"},
            contact_number: { name: "contactNumber", required: false, type: "text"},
            timezone: {name:"timezone", required: false, type:"text"},
            email: { name: "email", required: true, type: "text"},
            address: { name: "address", required: false, type: "text"},
            firstName: {name:"firstName", required: true, type: "text"},
            lastName: {name:"lastName", required: true, type: "text"},
            device: { name: "device", required: false},
            sigfox_deviceTypeId: { name: "sigfoxDeviceTypeId", required: false},
            subscription_from: {name: "subscriptionFrom", required: false},
            subscription_to: {name: "subscriptionTo", required: false},
            password: {name: "password", required: true, type:"text"}
        },
        channel:{
            name:{ name: "name", required: true, type: "text"},
            type: {name: "type", required: true, type: "text"}
        },
        users:{
            firstName: {name: "firstName", required: true, type: "text"},
            lastName: {name:"lastName", required: true, type: "text"},
            email:{name:"email", required: false, type: "text"},
            password: {name: "password", required: false, type: "text"},
            role: {name: "role", required: false, type: "text"}
        },
        driver:{
            firstName:{name: "firstName", required: true, type: "text"},
            lastName:{name: "lastName", required: true, type: "text"},
            driverId:{name: "driverId", required: true, type: "text"},
        }
    },
    sigfoxDownlink : {
        payloadInterval : [
            {text: "10 minutes", value: 600, disable: false},
            {text: "30 minutes", value: 1800, disable: false},
            {text: "1 hour", value: 3600, disable: false},
            {text: "2 hours", value: 7200, disable: false},
            {text: "3 hours", value: 10800, disable: false},
            {text: "4 hours", value: 14400, disable: false},
            {text: "6 hours", value: 21600, disable: false},
            {text: "8 hours", value: 28800, disable: false},
            {text: "12 hours", value: 43200, disable: false},
            {text: "24 hours", value: 86400, disable: false},
        ],
        payloadIntervalDL2 : [
            {text: "10 minutes", value: 600, disable: false},
            {text: "30 minutes", value: 1800, disable: false},
            {text: "1 hour", value: 3600, disable: false},
            {text: "2 hours", value: 7200, disable: false},
            {text: "3 hours", value: 10800, disable: false},
            {text: "4 hours", value: 14400, disable: false},
            {text: "6 hours", value: 21600, disable: false},
            {text: "8 hours", value: 28800, disable: false},
            {text: "12 hours", value: 43200, disable: false},
            {text: "24 hours", value: 86400, disable: true}
        ],
        payloadIntervalDL3: [
            {text: "10 minutes", value: 600, disable: false},
            {text: "30 minutes", value: 1800, disable: false},
            {text: "1 hour", value: 3600, disable: false},
            {text: "2 hours", value: 7200, disable: false},
            {text: "3 hours", value: 10800, disable: false},
            {text: "4 hours", value: 14400, disable: false},
            {text: "6 hours", value: 21600, disable: false},
            {text: "8 hours", value: 28800, disable: false},
            {text: "12 hours", value: 43200, disable: true},
            {text: "24 hours", value: 86400, disable: true}
        ],
        payloadIntervalDL4: [
            {text: "10 minutes", value: 600, disable: false},
            {text: "30 minutes", value: 1800, disable: false},
            {text: "1 hour", value: 3600, disable: false},
            {text: "2 hours", value: 7200, disable: false},
            {text: "3 hours", value: 10800, disable: false},
            {text: "4 hours", value: 14400, disable: false},
            {text: "6 hours", value: 21600, disable: false},
            {text: "8 hours", value: 28800, disable: true},
            {text: "12 hours", value: 43200, disable: true},
            {text: "24 hours", value: 86400, disable: true}
        ],
        downlinkFrequent : [
            {text: "1 request(s) / day", value: 1},
            {text: "2 request(s) / day", value: 2},
            {text: "3 request(s) / day", value: 3},
            {text: "4 request(s) / day", value: 4},
        ],
        gpsSeekPeriod:[
            {text: "70 seconds", value: 70},
            {text: "80 seconds", value: 80},
            {text: "90 seconds", value: 90},
            {text: "100 seconds", value: 100},
            {text: "110 seconds", value: 110},
            {text: "120 seconds", value: 120},
            {text: "130 seconds", value: 130},
            {text: "140 seconds", value: 140},
            {text: "150 seconds", value: 150},
            {text: "160 seconds", value: 160},
            {text: "170 seconds", value: 170},
            {text: "180 seconds", value: 180},
            {text: "190 seconds", value: 190},
            {text: "200 seconds", value: 200},
            {text: "210 seconds", value: 210},
            {text: "220 seconds", value: 220},
            {text: "230 seconds", value: 230},
            {text: "240 seconds ", value: 240},
            {text: "250 seconds", value: 250},
            {text: "260 seconds", value: 260},
            {text: "270 seconds", value: 270},
            {text: "280 seconds", value: 280},
            {text: "290 seconds", value: 290},
            {text: "300 seconds", value: 300},
        ]
    },
    cellularConfiguration: {
        operationMode: [
            {text: "External Power (DC Main)", value: "true"},
            {text: "Internal Battery", value: "false"}
        ],
        messagePayloadInterval : [
            {text: "every 10 seconds", value: 10},
            {text: "every 30 seconds", value: 30},
            {text: "every 1 minutes", value: 60},
            {text: "every 10 minutes", value: 600},
            {text: "every 30 minutes", value: 1800},
            {text: "every 1 hour", value: 3600},
            {text: "every 2 hour", value: 7200},
            {text: "every 6 hour", value: 21600},
            {text: "every 12 hour", value: 43200},
            {text: "every 24 hour", value: 86400}
        ],
        payloadInterval:[
            {text: "10 seconds", value: 10},
            {text: "30 seconds", value: 30},
            {text: "1 minutes", value: 60},
            {text: "10 minutes", value: 600},
            {text: "30 minutes", value: 1800},
            {text: "1 hour", value: 3600},
            {text: "2 hour", value: 7200},
            {text: "6 hour", value: 21600},
            {text: "12 hour", value: 43200},
            {text: "24 hour", value: 86400}
        ],
        radioAccessTechnology:[
            {text: "2G Only", value: 0},
            {text: "3G Only", value: 2},
            {text: "4G Only", value: 7},
            {text: "4G / 3G Fallback", value: 10},
        ]
    }
};

export const options = {
  hideSizePerPage: true,
  sizePerPage: 5
};

export const actions = (panel, row_field) => {
  return [
    {
      icon: "delete",
      tooltip: `Delete ${panel}`,
      onClick: (event, rowData) =>
        alert("You want to delete " + rowData[row_field]),
    },
    {
      icon: "edit",
      tooltip: `Edit ${panel}`,
      onClick: (event, rowData) => alert("edit " + rowData[row_field]),
    },
  ];
};

export const requiredField = {
    asset: ["name"],
    asset_group: ["name"],
    geofence: ["name", "trigger_enter", "trigger_leave"],
    sku: ["manufacturer", "product", "version", "revision"],
    device: ["sn"],
    device_sku: ["uuid"],
    board:["id", "firmware_add"],
    firmware: ["version", "release_date", "board_class", "git_SHA"],
    agency: ["id","name"],
    agency_user: ["email"],
    channel:["name","type"],
    user:["email"],
    userNotification:[],
    driver:["firstName", "lastName", "driverId"],
    beacon:["serialNumber", "skuId", 'macAddress']
}

export default tableConfig;
