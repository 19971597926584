import React, {useState, useRef} from 'react'

import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import DarkNavigationDown from "../../Image/dark_navigation_down.svg"
import DropdownIcon from "../../Image/dropdown.svg";
import SearchIcon from "../../Image/search.svg"
import LightCloseIcon from '../../Image/light_close_icon.svg'
import AddIcon from "../../Image/dark_add.svg";
import GreyEditIcon from '../../Image/grey_edit.svg'
import GreyDeleteIcon from '../../Image/grey_delete.svg'

import AddForm from "./AddForm"
import EditForm from "./EditForm"
import SnackBar from '../../config/config-snackbar/SnackBar'
import FilterBy from "../../component/component-filterby/FilterBy"

const useStyles = makeStyles((theme) => ({ img: { width: 24, height: "auto", }, }));
const Overview = ({title, data, loading, addForm, setMainFilter, editForm, query, Cards, setFilterFinal, filterFinal={}, filterList, preFilter={}, fx_PreFilter, EnterFilter, keyFilter, setPreFilter, setCanUpdate, canUpdate, setEdit, edit, setEditValue, editValue, featureDisable}) => {
    const classes = useStyles();
    const [add, setAdd] = useState(false);
    const [filter, setFilter] = useState(false)
    const wrapperRef = useRef(null);
    const [hoveredO,  setIsHoveredO] = useState({
        add: false,
        filter: false
    })
    // console.log(AddForm)

    //this snOpen, message and severity is for snackbar props
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")
    const [filterValue, setFilterValue] = useState([])



      //filter tags
      const filterDesign = () => {
        if (Object.keys(filterFinal).length > 0) {
        return "filter-tag"
        } else {
        return "filter-no-tag"
        }
    }

    //display filter count
    const filterCount = () => {
        if (filterFinal) {
        if (Object.keys(filterFinal).length > 0) {
            return <div style={{ display: "flex", alignItems: "center" }}>
            {`${Object.keys(filterFinal).length} Filter`}
            <img loading="lazy" src={LightCloseIcon} alt="close-icon" style={{ cursor: "pointer", height: "10px", width: "10px", margin: "0px 0px 0px 10px" }} onClick={(e) => onTagRemoveFilter(e)} />
            </div>

        }
        else {
            return null
        }
        } else {
        return null
        }
    }

    const onTagRemoveFilter = (e) => {
        setFilterValue({})
        setFilterFinal({})
        setPreFilter({})
    }

    return add ? (<AddForm title={title} setIsHoveredO={setIsHoveredO} fx_back={() => setAdd(!add)} FunctionForm={addForm} query={query} setCanUpdate={setCanUpdate} canUpdate={canUpdate} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} loading={loading}/>) : edit ? (<EditForm title={title} setIsHoveredO={setIsHoveredO} setMainFilter={setMainFilter} setFilterFinal={setFilterFinal} setPreFilter={setPreFilter} setFilterValue={setFilterValue} fx_back={() => setEdit(!edit)} FunctionForm={editForm} query={query} selected={editValue} setCanUpdate={setCanUpdate} canUpdate={canUpdate} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} loading={loading} featureDisable={featureDisable}/>) :
     (
        <div className="table-container" >
            <div className="title-font">{title}</div>
            <SnackBar open={snOpen} setOpen={setSnOpen} severity={severity} message={message}/>
            <div className="buttons"  >
                {/* <div className="buttons-all">
                    <input type="checkbox" className="check-type"/>
                </div> */}
                <div className="filter-container-channel">
                    <img loading="lazy" src={SearchIcon} alt="search-icon"/>
                    <img loading="lazy" src={DarkNavigationDown} alt="search-icon" onClick={() => setFilter(!filter)} onClick={() => setFilter(!filter)} className={hoveredO.filter ? "add-hover-button-style" : "add-button-style"} onMouseEnter={() => setIsHoveredO((current) => ({...current, filter: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, filter: false}))}/>
                    <div className="input-filter"> 
                        <div className={filterDesign()}>
                            {filterCount()}
                            
                        </div>                                   
                        <input type="text" value={preFilter[keyFilter] ? preFilter[keyFilter].value[0] : ""} onChange={(e) => fx_PreFilter(e.target.value)} onKeyDown={(e) => EnterFilter(e)}/>
                    </div>
                </div>
                <div className="add-button">
                    {
                        featureDisable ? null : (<div onClick={() => setAdd(!add)}>
                        <img loading="lazy" className={hoveredO.add ? "add-hover-button-style" : "add-button-style"} onMouseEnter={() => setIsHoveredO((current) => ({...current, add: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, add: false}))} src={AddIcon} alt="Icon"/>  
                    </div>)
                    }

                    {/* <IconButton style={{ paddingLeft: "0px" }}> */}
                        {/* <img loading="lazy" src={DropdownIcon} alt="dropdown-icon" className={classes.img} /> */}
                    {/* </IconButton> */}
                    {/* <IconButton aria-label="edit" onClick={() => setEdit(!edit)} disabled={true} > */}
                        {/* <img loading="lazy" src={EditIcon} alt="dropdown-icon" className={classes.img} /> */}
                        {/* <img loading="lazy" src={GreyEditIcon} alt="Icon" /> */}
                    {/* </IconButton> */}
                    {/* <IconButton aria-label="delete" disabled={true}> */}
                        {/* <img loading="lazy" src={DeleteIcon} alt="dropdown-icon" className={classes.img} /> */}
                        {/* <img loading="lazy" src={GreyDeleteIcon} alt="Icon"/> */}
                    {/* </IconButton> */}
                </div>
                {/* the if else statement for filter component, temporary only */}
                <div style={{position: "absolute"}} ref={wrapperRef}>
                    { filter && <FilterBy   setFilterFinal={setFilterFinal} filterFinal={filterFinal} filterList={filterList} filterValue={filterValue}  setFilterValue={setFilterValue}/> }     
                </div>         
            </div>
            <div className="table-responsive">
                {
                    data && data.map((details, index) => {
                        return <Cards data={details} setEdit={setEdit} edit={edit} add={add} setAdd={setAdd} setEditValue={setEditValue}/>
                    })
                }
                
            </div>
            {/* <div className="pagination-table">
                <IconButton >
                    <img src={LeftButton} alt="left-icon" className="img-pagination" />
                </IconButton>
                <IconButton >
                    <img src={RightButton} alt="right-icon" className="img-pagination" />
                </IconButton>
                1 - 3 of 3
            </div> */}
            {/* <div >
                <Copyright />
            </div> */}
            <style jsx>
                {
                    
                    ` .table-container{
                            margin: 24px 24px;
                       }
                      .title-font{
                        height: 32px;
                        // width: 108px;
                        color: #333333;
                        font-size: 24px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 32px;
                        margin: 0px 0px 8px 0px;
                      }
                      .buttons{
                        display: flex;
                        // justify-content: space-between;
                        height: "16px"; 
                        align-items: "center";
                        margin: 0px 0px 8px 0px;
                      }
                      .buttons-all{
                        display: flex;
                        align-items: center;
                      }
                      .check-type{
                        margin: 0px 4px 0px 0px;
                        height: 18px;
                        width: 18px;
                        background-color: #509D91;
                      }
                      .table-boostrap{
                        // margin: 16px;
                        // height:80vh;
                        min-height: 75vh;
                        background-color: #ffffff;
                      }
                      .table-responsive{
                          height:75vh;
                          width: 100%;
                        //   width: 
                      }
                      .filter-button{
                          font-size: 12px;
                      }
                      .pagination-table{
                        position: relative;.
                      }
                      .img-pagination{
                        width: 19px,
                        height: auto;
                      }
                      .row-height{
                          height:
                      }
                      .wrapper{
                        word-wrap: break-word
                      }
                      .filter-container-channel{
                        padding: 8px;
                        // margin: 0px 0px 0px 18px;
                        display: flex;
                        box-sizing: border-box;
                        height: 40px;
                        width: 360px;
                        border: 1px solid #939598;
                        border-radius: 4px;
                      }
                      .add-button{
                        margin: 0px 0px 0px 8px;
                        display: flex;
                        align-items: center;
                      }
                      .filter-tag{
                            display: flex;
                            align-items: center;
                            color: #FFF;
                            border-radius: 4px;
                            width: 85px;
                            height: 24px;
                            text-align: center;
                            background-color: #509D91;
                            justify-content: center;
                            margin: 0px 8px 0px 0px;
                        }
                        .filter-no-tag{
                            height: 0px;
                        }
                        input[type=text] {
                            width: 285px;
                            background: transparent;
                            border: none;
                            outline: none;
                        }
                        .input-filter{
                            display: flex;
                            align-items: center;
                        }
                        .add-button-style{
                            cursor: pointer;
                        }
                        .add-hover-button-style{
                            background-color: rgba(0,0,0,0.03);
                            border-radius: 5px;
                            cursor: pointer;
                        }
                    `
                }
            </style>
        </div>
    )
}
export default Overview

