import React from 'react'


const BeaconInformation = ({ setIsHovered, skuIdDisplay, isHovered, displayed, onChangePage, values, skuList, onChangeTextField, fieldList, requiredLabel, selected, isAdmin, isDashboard }) => {
  return (
    <div className="beacon-information-container">
        <div className="beacon-title">Beacon Information</div>
        <div>
            {
                isAdmin ? (
                <div className="beacon-details-input">
                    <div className="input-input">Serial Number (UUID)<p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.serialNumber.required)}</p></div>
                    <input type="text" className={`${isHovered.serialNumber ? "isHovered-border" : "text-box"}`} placeholder="-" name="serialNumber" value={values.serialNumber} onChange={(e) => onChangeTextField(e)}  onMouseEnter={() => setIsHovered((current) => ({...current, serialNumber: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, serialNumber: false}))}/>
                </div>
                ) : (
                <div className="beacon-details-input">
                    <div className="input-input">Serial Number (UUID)</div>
                    <div className="text-box text-colour-disabled">{values.serialNumber}</div>
                    {/* <input type="text" className={`${isHovered.serialNumber ? "isHovered-border" : "text-box"}`} placeholder="-" name="serialNumber" value={values.serialNumber} onChange={(e) => onChangeTextField(e)}  onMouseEnter={() => setIsHovered((current) => ({...current, serialNumber: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, serialNumber: false}))}/> */}
                </div> 
                )
            }
            {
                isAdmin ? (
                <div className="beacon-details-input">
                    <div className="input-input">MAC Address<p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.serialNumber.required)}</p></div>
                    <input type="text" className={`${isHovered.macAddress ? "isHovered-border" : "text-box"}`} placeholder="-" name="macAddress" value={values.macAddress} onChange={(e) => onChangeTextField(e)}  onMouseEnter={() => setIsHovered((current) => ({...current, macAddress: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, macAddress: false}))}/>
                </div>
                ) : (
                <div className="beacon-details-input">
                    <div className="input-input">MAC Address</div>
                    <div className="text-box text-colour-disabled">{values.macAddress ? values.macAddress : '-'}</div>
                    {/* <input type="text" className={`${isHovered.serialNumber ? "isHovered-border" : "text-box"}`} placeholder="-" name="serialNumber" value={values.serialNumber} onChange={(e) => onChangeTextField(e)}  onMouseEnter={() => setIsHovered((current) => ({...current, serialNumber: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, serialNumber: false}))}/> */}
                </div> 
                )
            }
            {
                isAdmin ? (
                <div className="beacon-details-input">
                    <div className="input-input">SKU<p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.skuId.required)}</p></div>
                    <select className={`${isHovered.SKU ? "isHovered-selection" : "selection-board"}`} disabled={displayed ? true : false} value={values.skuId} name="skuId" onChange={(e) => onChangeTextField(e)}  onMouseEnter={() => setIsHovered((current) => ({...current, SKU: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, SKU: false}))}>
                        <option value="">-</option>
                        {
                            skuList.length > 0 && skuList.map((x,i) => {
                                return <option value={x.uuid}>{x.id}</option>
                            })
                        }
                    </select>
                </div>
                ) : (
                <div className="beacon-details-input">
                    <div className="input-input">SKU</div>
                    <div className="text-box text-colour-disabled">{skuIdDisplay}</div>
                </div>
                )
            }
            {
                displayed && isAdmin &&  (
                <div className="beacon-details-input">
                    <div className="input-input">Manufacturer</div>
                    <div className="text-box text-colour-disabled">{selected && selected.sku ? selected.sku.skuProductModel : "-"}</div>
                </div>
                )
            }
            {
                isAdmin ? (
                <div className="beacon-details-input">
                    <div className="input-input">Range</div>
                    <input type="number" className={`${isHovered.range ? "isHovered-border" : "text-box"}`} placeholder="-" value={values.range} name="range" onChange={(e) => onChangeTextField(e)} onMouseEnter={() => setIsHovered((current) => ({...current, range: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, range: false}))}/>
                </div>
                ) : (
                <div className="beacon-details-input">
                    <div className="input-input">Range</div>
                    <div className="text-box text-colour-disabled">{values.range}</div>
                </div>
                )
            }
            {
                displayed && isAdmin && (
                <div className="beacon-details-input">
                    <div className="input-input">Battery Voltage (V)</div>
                    <div className="text-box text-colour-disabled">{selected && selected.batteryVoltage ? selected.batteryVoltage : '-'}</div>
                </div>
                )
            }
            {
                displayed && (
                <div className="beacon-details-input">
                    <div className="input-input">Battery Level (%)</div>
                    <div className="text-box text-colour-disabled">-</div>
                </div>
                )
            }
            {
                displayed && (
                <div className="beacon-details-input">
                    <div className="input-input">Last Updated</div>
                    <div className="text-box text-colour-disabled">-</div>
                </div>
                )
            }
            {
                isDashboard && (
                <div className="beacon-details-input">
                    <div className="input-input">Location Name</div>
                    <input type="text" className={`${isHovered.locationName ? "isHovered-border" : "text-box"}`} placeholder="-" name="locationName" value={values.locationName} onChange={(e) => onChangeTextField(e)}  onMouseEnter={() => setIsHovered((current) => ({...current, locationName: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, locationName: false}))}/>
                </div>
                )
            }
            {
                isDashboard && (
                <div className="beacon-details-input">
                    <div className="input-input">Latitude</div>
                    <input type="number" step="0.01" className={`${isHovered.latitude ? "isHovered-border" : "text-box"}`} placeholder="-" name="latitude" value={values.coordinate.latitude} onChange={(e) => onChangeTextField(e)}  onMouseEnter={() => setIsHovered((current) => ({...current, latitude: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, latitude: false}))}/>
                </div>
                )
            }
            {
                isDashboard && (
                <div className="beacon-details-input">
                    <div className="input-input">Longitude</div>
                    <input type="number" step="0.01" className={`${isHovered.longitude ? "isHovered-border" : "text-box"}`} placeholder="-" name="longitude" value={values.coordinate.longitude} onChange={(e) => onChangeTextField(e)}  onMouseEnter={() => setIsHovered((current) => ({...current, longitude: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, longitude: false}))}/>
                </div>
                )
            }
            {
                isDashboard && (
                <div className="beacon-details-input">
                    <div className="input-input">Location Note</div>
                    <input type="text" className={`${isHovered.locationNote ? "isHovered-border" : "text-box"}`} placeholder="-" name="locationNote" value={values.locationNote} onChange={(e) => onChangeTextField(e)}  onMouseEnter={() => setIsHovered((current) => ({...current, locationNote: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, locationNote: false}))}/>
                </div>
                )
            }
            {
                displayed && isAdmin &&  (
                <div className="beacon-details-input">
                    <div className="input-input">Agency</div>
                    {
                        selected && selected.agency ? (
                            <a className="link" onClick={onChangePage}>{selected.agency.agencyName}</a>
                        ) : (
                            <div className="text-box text-colour-disabled">-</div>
                        )
                    }

                </div>
                )
            }
            {
                isAdmin && (
                <div className="beacon-details-input">
                    <div className="input-input">Description</div>
                    <input type="text" className={`${isHovered.description ? "isHovered-border" : "text-box"}`} name="description" placeholder="-" value={values.description} onChange={(e) => onChangeTextField(e)} onMouseEnter={() => setIsHovered((current) => ({...current, description: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, description: false}))}/>
                </div>
                )
            }
        </div>
        <div></div>
         <style>
            {
                `.beacon-information-container{               
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    padding: 16px;
                    margin: 0px 0px 24px 0px;
                }
                .text-colour-disabled{
                    color: #939598;
                }
                `
            }
        </style>
    </div>
  )
}

export default BeaconInformation
