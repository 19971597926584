import React, { createContext, useReducer } from "react";
import moment from 'moment';
import Cookies from "js-cookie";
import axios from 'axios'

var socket;

const initialState = { channel: null, data: null, setChannel: (channel) => {}, CloseConnection: (value) => {}, payload: null, new_payload_id: null, view_asset_card: false, selected_asset_info: null, selected_asset: { pairId: "", getAssetInfo: false }, view_asset_group_card: false, selected_asset_group: {}, switchAgency: false };

const WebsocketContext = createContext({ 
    payload: null, 
    new_payload_id: null, 
    view_asset_card: false, 
    view_asset_group_card: false,
    selected_asset: { pairdId: "", getAssetInfo: false }, 
    selected_asset_info: null,
    selected_asset_group: {},
    switchAgency: false,
    selectAsset: (variables, asset) => {}, 
    closeAssetCard: () => {},
    openAssetGroupCard: (open, asset_group) => {}
})

const convertTimestamp = (timstamp) => {
    const convert = moment(timstamp).format("X");
    return convert
}

const WebsocketReducer = (state, action) => {
    switch (action.type) {
        case "DATA":{
            return {
                ...state,
                channel: action.channel,
                data: action.data,
                new_payload_id: action.data && action.data.timestamp && `${convertTimestamp(action.data.timestamp)}-${action.data.pairId}`,
            };            
        }
        case "ASSET":{
            //an asset selected either from map or card
            return {
                ...state,
                view_asset_card: true,
                selected_asset: action.selected_asset,
                selected_asset_info: action.asset_info
            }
        }
        case "CLOSE_ASSET_CARD":{
            //to close the asset card
            return {
                ...state,
                view_asset_card: false,
                selected_asset_info: null,
                selected_asset: null
            }
        }
        case "OPEN_ASSET_GROUP_CARD":{
            //open or close asset group card
            return {
                ...state,
                view_asset_group_card: action.open,
                selected_asset_group: action.asset_group
            }
        }
        default:
        return state;
    }
}


const WebsocketProvider = (props) => {
    const [state, dispatch] = useReducer(WebsocketReducer, initialState);  
    const CloseConnection = (value) => {
        if(socket){
            if(value === "disconnect"){
                socket.readyState === WebSocket.OPEN && socket.close(1000) //this to reconnect
            }
            if(value === "logout"){
                socket.readyState === WebSocket.OPEN && socket.close(3000) // this to logout
            }       
        }
    }
    const setChannel = (channel) => {
        socket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET}?session=${Cookies.get("SessionID")}`, "string")
        // Handle websocket open event
        // Sends the agency id as the channel name to "subscribe" to the "topic"
        socket.addEventListener("open", async (evt) =>{
            if(socket.readyState === 1){
                console.log(`Websocket Connection Established: ${channel} @ ${new Date().toISOString()}`)
                socket.send(channel) 
            }

        })

        // Handles the websocket close event
        // Attempts to refresh the Websocket SessionId if and only if jwtToken exists
        // This does NOT handle expired tokens, which should rightfully be handled by on Error
        socket.addEventListener("close", async (evt) => { 
            if (evt.code ===  1006) {
                console.log(`Server unexpectedly terminated Websocket connection: ${new Date().toISOString()}`)
            } else {
                console.log(`Websocket Connection Closed: ${new Date().toISOString()}`)  
            }
         
            //this to check if there is a switch agency, if there is switch agency or logout it will not go to this statement
            if(evt.code !== 1000 && evt.code !== 3000){
                if(typeof Cookies.get("jwtToken") !== "undefined"){
                    console.log(`Attempting to reconnect to Websocket: ${new Date().toISOString()}`)

                    await axios.post(process.env.REACT_APP_WEBSOCKET_LOGIN, { 
                        token: Cookies.get("jwtToken"),
                        agencyID: Cookies.get("agency-id")
                    }).then((res) => {
                        Cookies.set("SessionID", res.data.sessionID)
                        setChannel(Cookies.get("agency-id"))
                    }).catch((error) => {
                        console.log(`Something went wrong: ${error}`)
                        window.location.reload()
                    });                
                }
            }


        })
        
        // Handles the websocket error event
        // Removes existing cookies (assumption that the tokens have expired) and reloads the page
        // User should expect to be redirected to the '/' login page.
        socket.addEventListener("error", (evt) => {
            //if the token are already return error but the token has removed will not go to this if statement. this scenario if graphql sending in error 498 invalid token
            if(Cookies.get("jwtToken")){ 
                console.log(`Websocket Connection Error. Reauthentication required.`)
                Cookies.remove("jwtToken")
                Cookies.remove("agency-id");
                Cookies.remove("agencyUUID")
                Cookies.remove("role");
                Cookies.remove("SessionID")
                Cookies.remove("agency-name")
                Cookies.remove("_id")
                Cookies.remove("privileges")
                window.location.reload()
            }
        })
    
        socket.addEventListener("message", (evt) => {
            let payload = {}
            try {
                payload = JSON.parse(evt.data)
            } catch (error) {
                console.log(`Parse Websocket Data error`, error)
            }
            dispatch({ type: "DATA", data: payload, channel })
        })
    }

    const selectAsset = (variables, info) => {
        dispatch({ type: "ASSET", selected_asset: variables, asset_info: info  })
    }
    const closeAssetCard = () => {
        dispatch({ type: "CLOSE_ASSET_CARD" })
    }
    const openAssetGroupCard = (open, asset_group) => {
        dispatch({ type: "OPEN_ASSET_GROUP_CARD", open, asset_group})
    }

    return (
        <WebsocketContext.Provider value={{ channel: state.channel, data: state.data, setChannel, CloseConnection, payload: state.payload, new_payload_id: state.new_payload_id, view_asset_card: state.view_asset_card, selected_asset: state.selected_asset, selected_asset_info: state.selected_asset_info, selectAsset, closeAssetCard, view_asset_group_card: state.view_asset_group_card, openAssetGroupCard, selected_asset_group: state.selected_asset_group , switchAgency: state.switchAgency}} {...props} />
    );
}

export { WebsocketContext, WebsocketProvider };
