import React, {useState, useEffect} from 'react'

import Form from './Form'

import { AllMandatoryFilled, onUserAdd} from "../../config/config-function/function";

import { CHANNEL_REDUCER } from "../../config/config-reducer/reducer";
import {requiredField} from '../../config/config-table/table'

const FunctionAdd = ({values, onChange, setAddVariables, setGetVariables, setCanAdd, canAdd,  displayed,  setDisplayed, setCanUpdate, canUpdate}) => {

  const [types, setTypes] = useState()
  const canEdit = false;
  const [userListing, setUserListing] = useState([])
  const [notification, setNotification] = useState({
    generateEOT: false,
    generateEOD: false
})

  const onUserInput = (e, value, isValue) => {
      let input = onUserAdd(e, value, isValue, onChange, values)
      // console.log("ADD", input)
      const _check_normal = AllMandatoryFilled(requiredField.channel, input)
      

      _check_normal ? setCanAdd(true) : setCanAdd(false)
      setAddVariables({channel: input === null ? CHANNEL_REDUCER(values) : CHANNEL_REDUCER(input)})
  }

  useEffect(() => {
    onUserInput(null, { type: types }, true)
      //---this is to disable the warning for useEffect----//
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types])

  useEffect(() => {
    onUserInput(null, { userList: userListing }, true)
      //---this is to disable the warning for useEffect----//
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListing])

  useEffect(() => {
    onUserInput(null, { notification: notification }, true)
      //---this is to disable the warning for useEffect----//
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification])
    
    return (
        <Form channels={values} canEdit={canEdit} onUserInput={onUserInput} displayed={displayed} setUserListing={setUserListing} userListing={userListing} setTypes={setTypes} types={types} notification={notification} setNotification={setNotification}/>
    )
}
export default FunctionAdd
