import React from 'react'

const ImageComponent = ({src, alt, style, onClick }) => {
    return (
        <>
            <img loading="lazy" src={src} alt={alt} style={style ? style : null} onClick={onClick ? onClick : null} />
        </>
    )
}

export default ImageComponent
