import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import Cookies from "js-cookie";
import {useQuery } from "@apollo/react-hooks";
import { useMediaQuery } from 'react-responsive';

// import ProfileIcon from '../../Image/dark_profile.svg'
// import ProfileIcon from '../../Image/userIcon.svg'
import ProfileIcon from '../../Image/user.svg'
import logo from '../../Image/logo-horizon-new.svg'
import CollapseIcon from "../../Image/drawer_collapse.svg";
import MenuOpen from "../../Image/menu_open.svg"
import GreenVerified from '../../Image/green_verified.svg'


//component
import menuConfig from "../../config/config-menu/menu";
import { AuthContext } from "../../config/config-auth/Auth";
import {WebsocketContext} from '../../config/config-websocket/Websocket'
import Modal from '../component-modal/Modal'
import {GET_USERS} from '../../config/config-graphql/Graphql'


const TopBar = ({ id_module, id_subModule, page, fx_handleSidebar, collapseStatus, history, fx_changePage}) => {
    const isDefault = useMediaQuery({ minWidth: 1025 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    const context = useContext(AuthContext);
    const payloadContext = useContext(WebsocketContext)
    const { CloseConnection, setChannel,  } = payloadContext
    const [displayDropdown, setDisplayDropdown] = useState(false);
    const sessionID = Cookies.get("SessionID")
    const userId = Cookies.get("_id")
    const pCookies = Cookies.get("privileges")
    const token = Cookies.get("jwtToken")
    const priviList = pCookies ? JSON.parse(pCookies) : "" //take the privileges from cookies
    const agencyName = Cookies.get("agency-name")
    const agencyId = Cookies.get("agency-id")
    const [chgPassword, setChgPassword] = useState(false)
    const [messages, setMessages] = useState([]);
    const [activeList, setActiveList] = useState(null)
    const [nonActiveList, setNonActiveList] = useState(null)
    const role = Cookies.get("role")
    const [open, setOpen] = useState(false)
    const [test, setTest] = useState(false)
    const [hoveredO, setIsHoveredO] = useState({
        menu: false,
        my_profile: false,
        help_centre: false,
        agencies: false,
        sign_out: false,
        agency: false,
        agencyList: "",

    })

    const { data : user_data, loading: userLoading, error:errorUser } = useQuery(GET_USERS, {variables: {filter: {uuid: userId}}})

    const onSignout = async () => {
        try{
            if(role === "System Administrator") {
                context.logout(); 
                history.push("/login")
            }else{
                // window.location.reload(false)
                const response = await axios.delete(`${process.env.REACT_APP_WEBSOCKET_LOGOUT}?session=${sessionID}`, null, { credentials: 'same-origin' })
                // console.log(response);
                // testing
                if (response.status === 200) {
                    CloseConnection("logout")
                    // console.log(response);
                    context.logout(); 
                    history.push("/login")
                } else {
                    console.log('logout issue please try again')
                }
                // console.log(response)
            }

        }catch(err){
            console.log(err)
        }
    }
    
    useEffect(() => {
        if(!userLoading && user_data && user_data.getAgencyUsers){
            const getPrivileges = user_data.getAgencyUsers.map(x => x.privileges)
            const activeValue = getPrivileges[0].filter((j) => j.agencyName === agencyName)
            const nonActiveValue = getPrivileges[0].filter((j) => j.agencyName !== agencyName)

            setActiveList(activeValue)
            setNonActiveList(nonActiveValue)


        }
    }, [userLoading])

    const livePage = () => {
        fx_changePage(menuConfig.dashboard[1].key, menuConfig.dashboard[1].children[1].key)
        history.push("/dashboard/live/map")
    }

    const switchModal = () => {
        setOpen(!open)
        setChgPassword(!chgPassword)
    }

    //function to do the selection of agency
    const selectOtherAgency = async(privileges) => {

        try{
            // // window.location.reload(false)
            const response = await axios.delete(`${process.env.REACT_APP_WEBSOCKET_LOGOUT}?session=${sessionID}`, null, { credentials: 'same-origin' })
            // console.log('delete', response)
            if(response.status === 200){
                CloseConnection("disconnect")
                const postResponse = await axios.post(process.env.REACT_APP_WEBSOCKET_LOGIN, { 
                    token: token,
                    agencyID: privileges.agencyId
                }); 
        
                if (postResponse.data && postResponse.data.sessionID){
                    // console.log("go after dapat session ID")                        
                    let clone = [...messages];
                    clone.push(postResponse.data ? postResponse.data.message : "An error occured")
                    setMessages(clone) 
                    // console.log(postResponse) 
                    context.reConnect(privileges, postResponse.data.sessionID)  //store the login response with session ID into cookies
                    setChannel(privileges.agencyId)
                    window.location.reload()
    
        
                }else{ //else the websocket not connected (no sessionID generated)
                    // console.log("TOPBAR COMPONENT - sessionID error not generated")
                }
            }else{
                // console.log("endpoint logout ws, error", response)
                // console.log("response logout endpoint return not okay")
            }

        }catch(err){
            // console.log(err)
        }   
    }

    return (
        <div>
            {
                isDefault && (
                    <div className="topbar-container">
                        <div className="logo-placeholder">
                            <div className="btn-hide-menu">
                                <button className="icon-button remove-border" onClick={(e) => fx_handleSidebar()}>
                                {/* */}
                                    <img loading="lazy" src={collapseStatus ? MenuOpen  : CollapseIcon} alt="icon" className={hoveredO.menu ? "menu-hover-button-style" : "menu-button-style"} onMouseEnter={() => setIsHoveredO((current) => ({...current, menu: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, menu: false}))} />
                                </button>
                            </div>
                            <div className="logo" onClick={livePage}>
                                <img loading="lazy" className="logo-img" src={logo} alt="Horizon-logo" />
                                
                            </div>
                            {role === "System Administrator" ?  <div className="supply-container">
                                <div className="role-container">Console</div>
                            </div> : null
                            }
                        
                        </div>
                        {/* onMouseLeave={e => setDisplayDropdown(false)} */}
                        <div className="user-image-placeholder dropdown remove-border" onMouseLeave={e => setDisplayDropdown(false)}>
                        {/* onMouseEnter={e => setDisplayDropdown(false)} */}
                            <div className="title-font-size" onMouseEnter={e => setDisplayDropdown(true)}>
                                <p className="title-agency">{agencyName}</p>
                                <p className="title-role">{role}</p>
                            </div>
                            <div className="line" > </div>
                            {/* onMouseEnter={(e) => setDisplayDropdown(true)} */}
                            <div className="title-user" onClick={(e) => setDisplayDropdown(true)} onMouseEnter={(e) => setDisplayDropdown(true)}>
                                <img loading="lazy" src={ProfileIcon} alt="Icon" />
                            </div>               
                            {/* <div className="title-user" onClick={(e) => setDisplayDropdown(true)} onMouseEnter={(e) => setDisplayDropdown(true)}>
                                <img loading="lazy" src={ProfileIcon} alt="Icon" />
                            </div>  */}

                            {
                            displayDropdown &&(
                                    // onMouseOver={(e) => setDisplayDropdown(true)} onMouseLeave={(e) => setDisplayDropdown(false)}
                                <div className="dropdown-content" >
                                    <div>
                                        <div style={{margin: "0px 0px 8px 0px", padding:"0px 0px 0px 8px", height: "32px", cursor:"pointer", backgroundColor: hoveredO.my_profile ? `#DDDDDD` : `#f9f9f9`, display:"flex", alignItems: "center", borderRadius:"4px"}} onClick={() => setOpen(true)} onMouseEnter={() => setIsHoveredO((current) => ({...current, my_profile: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, my_profile: false}))}>My Profile</div>
                                        <a style={{margin: "0px 0px 8px 0px", padding:"0px 0px 0px 8px",  height: "32px", backgroundColor: hoveredO.help_centre ? `#DDDDDD` : `#f9f9f9`, display:"flex", alignItems: "center", borderRadius:"4px", textDecoration:"none", color: "#333333", cursor: "pointer" }} href="https://asiamobiliti.freshdesk.com/a/solutions" target="_blank" rel="noopener" onMouseEnter={() => setIsHoveredO((current) => ({...current, help_centre: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, help_centre: false}))}>Help Centre</a>
                                    </div>
                                    <div className="line-barrier"></div>
                                    <div style={{margin: "8px 0px 8px 0px"}}>
                                        <div style={{color: "#939598", fontSize:"12px",padding:"0px 0px 0px 8px"}} >Agency Workspace</div>
                                        {
                                            agencyId !== "admin" ? (
                                                <div>
                                                        {
                                                            activeList && activeList.length > 0 && activeList.map((x,i) => {
                                                                return(
                                                                <div key={i} className="agency-switch" style={{margin: "8px 0px 0px 0px", padding:"0px 0px 0px 8px", height: "32px"}} className="agency-list-container" name={x.agencyId} onClick={() => selectOtherAgency(x)}>
                                                                        <div>{x.agencyName}</div>
                                                                        <img loading="lazy" src={GreenVerified} alt="selected" style={{height: "13px", width: "13px", padding:"0px 8px 0px 0px"}}/>
                                                                    
                                                                </div> 
                                                                )
                                                            })
                                                        }
                                                        {
                                                            nonActiveList && nonActiveList.length > 0 && nonActiveList.map((x,i) => {
                                                                return(
                                                                <div key={i} className="agency-switch" style={{margin: "8px 0px 0px 0px", padding:"0px 0px 0px 8px", height: "32px"}} className="agency-list-container" name={x.agencyId} onClick={() => selectOtherAgency(x)}>
                                                                        <div>{x.agencyName}</div>                                                               
                                                                </div> 
                                                                )
                                                            })
                                                        }
                                                </div>
                                            ) : <div className="agency-switch" style={{margin: "8px 0px 0px 0px", padding:"0px 0px 0px 8px", height: "32px"}} className="agency-list-container">
                                                <div className="console-agency-name">
                                                    <div className="supply-container-topbar">
                                                        <div className="role-container-topbar">Console</div>
                                                    </div> 
                                                <div className="console-text-container">{agencyName}</div> 
                                                </div>   
                                                <img loading="lazy" src={GreenVerified} alt="selected" style={{height: "13px", width: "13px", padding:"0px 8px 0px 0px"}}/>                                                          
                                            </div> 
                                        }                 
                                    </div>
                                    <div className="line-barrier"></div>

                                    <div className="remove-border" style={{cursor:"pointer", margin: "8px 0px 0px 0px ", padding:"0px 0px 0px 8px", height: "32px", backgroundColor: hoveredO.sign_out ? `#DDDDDD` : `#f9f9f9`, display:"flex", alignItems: "center", borderRadius:"4px"}} onClick={() => onSignout()} onMouseEnter={() => setIsHoveredO((current) => ({...current, sign_out: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, sign_out: false}))}>Sign Out</div>
                                    <Modal open={open} fx_close={switchModal} chgPassword={chgPassword} setChgPassword={setChgPassword}/>
                                </div>
                            )
                            }
                            {/* {
                                displayDropdown && (
                                <div className="dropdown-content" onMouseOver={(e) => setDisplayDropdown(true)} onMouseLeave={(e) => setDisplayDropdown(false)}>
                                    <button className="remove-border" style={{cursor:"pointer"}} onClick={(e) => onSignout()}>Sign Out</button>
                                </div>
                            )
                            } */}

                        </div>  
                    </div>
                )
            }
            {
                isMobile && (
                    <div className="topbar-container-mobile">
                        <div className="logo-placeholder-mobile">
                            {/* <div className="btn-hide-menu">
                                <button className="icon-button remove-border" onClick={(e) => fx_handleSidebar()}>
                                    <img loading="lazy" src={collapseStatus ? MenuOpen  : CollapseIcon} alt="icon" className={hoveredO.menu ? "menu-hover-button-style" : "menu-button-style"} onMouseEnter={() => setIsHoveredO((current) => ({...current, menu: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, menu: false}))} />
                                </button>
                            </div> */}
                            <div className="logo">
                                <img loading="lazy" className="logo-img-mobile" src={logo} alt="Horizon-logo" />                          
                            </div>
                            {role === "System Administrator" ?  <div className="supply-container">
                                <div className="role-container">Console</div>
                            </div> : null
                            }
                        
                        </div>
                        {/* onMouseLeave={e => setDisplayDropdown(false)} */}
                        <div className="user-image-placeholder-mobile dropdown remove-border" onMouseLeave={e => setDisplayDropdown(false)}>
                        {/* onMouseEnter={e => setDisplayDropdown(false)} */}
                            {/* <div className="line-mobile" > </div> */}
                            {/* onMouseEnter={(e) => setDisplayDropdown(true)} */}
                            <div className="title-user-mobile" onClick={(e) => setDisplayDropdown(true)} onMouseEnter={(e) => setDisplayDropdown(true)}>
                                <img loading="lazy" src={ProfileIcon} alt="Icon" />
                            </div>               
                            {/* <div className="title-user" onClick={(e) => setDisplayDropdown(true)} onMouseEnter={(e) => setDisplayDropdown(true)}>
                                <img loading="lazy" src={ProfileIcon} alt="Icon" />
                            </div>  */}

                            {
                            displayDropdown &&(
                                    // onMouseOver={(e) => setDisplayDropdown(true)} onMouseLeave={(e) => setDisplayDropdown(false)}
                                <div className="dropdown-content-mobile" >
                                    <div style={{margin: "8px 0px 8px 0px"}}>
                                        <div style={{color: "#939598", fontSize:"12px",padding:"0px 0px 0px 8px"}} >Agency Workspace</div>
                                        {
                                            agencyId !== "admin" ? (
                                                <div>
                                                        {
                                                            activeList && activeList.length > 0 && activeList.map((x,i) => {
                                                                return(
                                                                <div key={i} className="agency-switch" style={{margin: "8px 0px 0px 0px", padding:"0px 0px 0px 8px", height: "32px"}} className="agency-list-container" name={x.agencyId} onClick={() => selectOtherAgency(x)}>
                                                                        <div>{x.agencyName}</div>
                                                                        <img loading="lazy" src={GreenVerified} alt="selected" style={{height: "13px", width: "13px", padding:"0px 8px 0px 0px"}}/>
                                                                    
                                                                </div> 
                                                                )
                                                            })
                                                        }
                                                        {
                                                            nonActiveList && nonActiveList.length > 0 && nonActiveList.map((x,i) => {
                                                                return(
                                                                <div key={i} className="agency-switch" style={{margin: "8px 0px 0px 0px", padding:"0px 0px 0px 8px", height: "32px"}} className="agency-list-container" name={x.agencyId} onClick={() => selectOtherAgency(x)}>
                                                                        <div>{x.agencyName}</div>                                                               
                                                                </div> 
                                                                )
                                                            })
                                                        }
                                                </div>
                                            ) : <div className="agency-switch" style={{margin: "8px 0px 0px 0px", padding:"0px 0px 0px 8px", height: "32px"}} className="agency-list-container">
                                                <div className="console-agency-name">
                                                    <div className="supply-container-topbar">
                                                        <div className="role-container-topbar">Console</div>
                                                    </div> 
                                                <div className="console-text-container">{agencyName}</div> 
                                                </div>   
                                                <img loading="lazy" src={GreenVerified} alt="selected" style={{height: "13px", width: "13px", padding:"0px 8px 0px 0px"}}/>                                                          
                                            </div> 
                                        }                 
                                    </div>
                                    <div className="line-barrier"></div>

                                    <div className="remove-border" style={{cursor:"pointer", margin: "8px 0px 0px 0px ", padding:"0px 0px 0px 8px", height: "32px", backgroundColor: hoveredO.sign_out ? `#DDDDDD` : `#f9f9f9`, display:"flex", alignItems: "center", borderRadius:"4px"}} onClick={() => onSignout()} onMouseEnter={() => setIsHoveredO((current) => ({...current, sign_out: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, sign_out: false}))}>Sign Out</div>
                                    <Modal open={open} fx_close={switchModal} chgPassword={chgPassword} setChgPassword={setChgPassword}/>
                                </div>
                            )
                            }
                            {/* {
                                displayDropdown && (
                                <div className="dropdown-content" onMouseOver={(e) => setDisplayDropdown(true)} onMouseLeave={(e) => setDisplayDropdown(false)}>
                                    <button className="remove-border" style={{cursor:"pointer"}} onClick={(e) => onSignout()}>Sign Out</button>
                                </div>
                            )
                            } */}

                        </div>  
                    </div>   
                )
            }   
            {
                isTablet && (
                    <div className="topbar-container-mobile">
                        <div className="logo-placeholder-mobile">
                            {/* <div className="btn-hide-menu">
                                <button className="icon-button remove-border" onClick={(e) => fx_handleSidebar()}>
                                    <img loading="lazy" src={collapseStatus ? MenuOpen  : CollapseIcon} alt="icon" className={hoveredO.menu ? "menu-hover-button-style" : "menu-button-style"} onMouseEnter={() => setIsHoveredO((current) => ({...current, menu: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, menu: false}))} />
                                </button>
                            </div> */}
                            <div className="logo">
                                <img loading="lazy" className="logo-img-mobile" src={logo} alt="Horizon-logo" />                          
                            </div>
                            {role === "System Administrator" ?  <div className="supply-container">
                                <div className="role-container">Console</div>
                            </div> : null
                            }
                        
                        </div>
                        {/* onMouseLeave={e => setDisplayDropdown(false)} */}
                        <div className="user-image-placeholder-mobile dropdown remove-border" onMouseLeave={e => setDisplayDropdown(false)}>
                        {/* onMouseEnter={e => setDisplayDropdown(false)} */}
                            {/* <div className="line-mobile" > </div> */}
                            {/* onMouseEnter={(e) => setDisplayDropdown(true)} */}
                            <div className="title-user-mobile" onClick={(e) => setDisplayDropdown(true)} onMouseEnter={(e) => setDisplayDropdown(true)}>
                                <img loading="lazy" src={ProfileIcon} alt="Icon" />
                            </div>               
                            {/* <div className="title-user" onClick={(e) => setDisplayDropdown(true)} onMouseEnter={(e) => setDisplayDropdown(true)}>
                                <img loading="lazy" src={ProfileIcon} alt="Icon" />
                            </div>  */}

                            {
                            displayDropdown &&(
                                    // onMouseOver={(e) => setDisplayDropdown(true)} onMouseLeave={(e) => setDisplayDropdown(false)}
                                <div className="dropdown-content-mobile" >
                                    <div style={{margin: "8px 0px 8px 0px"}}>
                                        <div style={{color: "#939598", fontSize:"12px",padding:"0px 0px 0px 8px"}} >Agency Workspace</div>
                                        {
                                            agencyId !== "admin" ? (
                                                <div>
                                                        {
                                                            activeList && activeList.length > 0 && activeList.map((x,i) => {
                                                                return(
                                                                <div key={i} className="agency-switch" style={{margin: "8px 0px 0px 0px", padding:"0px 0px 0px 8px", height: "32px"}} className="agency-list-container" name={x.agencyId} onClick={() => selectOtherAgency(x)}>
                                                                        <div>{x.agencyName}</div>
                                                                        <img loading="lazy" src={GreenVerified} alt="selected" style={{height: "13px", width: "13px", padding:"0px 8px 0px 0px"}}/>
                                                                    
                                                                </div> 
                                                                )
                                                            })
                                                        }
                                                        {
                                                            nonActiveList && nonActiveList.length > 0 && nonActiveList.map((x,i) => {
                                                                return(
                                                                <div key={i} className="agency-switch" style={{margin: "8px 0px 0px 0px", padding:"0px 0px 0px 8px", height: "32px"}} className="agency-list-container" name={x.agencyId} onClick={() => selectOtherAgency(x)}>
                                                                        <div>{x.agencyName}</div>                                                               
                                                                </div> 
                                                                )
                                                            })
                                                        }
                                                </div>
                                            ) : <div className="agency-switch" style={{margin: "8px 0px 0px 0px", padding:"0px 0px 0px 8px", height: "32px"}} className="agency-list-container">
                                                <div className="console-agency-name">
                                                    <div className="supply-container-topbar">
                                                        <div className="role-container-topbar">Console</div>
                                                    </div> 
                                                <div className="console-text-container">{agencyName}</div> 
                                                </div>   
                                                <img loading="lazy" src={GreenVerified} alt="selected" style={{height: "13px", width: "13px", padding:"0px 8px 0px 0px"}}/>                                                          
                                            </div> 
                                        }                 
                                    </div>
                                    <div className="line-barrier"></div>

                                    <div className="remove-border" style={{cursor:"pointer", margin: "8px 0px 0px 0px ", padding:"0px 0px 0px 8px", height: "32px", backgroundColor: hoveredO.sign_out ? `#DDDDDD` : `#f9f9f9`, display:"flex", alignItems: "center", borderRadius:"4px"}} onClick={() => onSignout()} onMouseEnter={() => setIsHoveredO((current) => ({...current, sign_out: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, sign_out: false}))}>Sign Out</div>
                                    <Modal open={open} fx_close={switchModal} chgPassword={chgPassword} setChgPassword={setChgPassword}/>
                                </div>
                            )
                            }
                            {/* {
                                displayDropdown && (
                                <div className="dropdown-content" onMouseOver={(e) => setDisplayDropdown(true)} onMouseLeave={(e) => setDisplayDropdown(false)}>
                                    <button className="remove-border" style={{cursor:"pointer"}} onClick={(e) => onSignout()}>Sign Out</button>
                                </div>
                            )
                            } */}

                        </div>  
                    </div>    
                )
            }        
            <style>
                {
                    `
                    .title-font-size{
                        position: relative
                        text-align: right;
                        color: #333333;
                        font-size: 16px;
                        letter-spacing: 0;
                        // margin-right: 24px;
                    }
                    .title-font-size-mobile{
                        position: relative
                        text-align: right;
                        color: #333333;
                        font-size: 16px;
                        letter-spacing: 0;
                        // margin-right: 24px;
                    }
                    .title-agency{
                        margin: 0px 0px 0px;
                        height:20px;
                        font-size: 15px;
                    }
                    .title-agency-mobile{
                        margin: 0px 0px 0px;
                        height: 10px;
                        font-size: 12px;
                    }
                    .title-role{
                        margin: 0px 0px 0px;
                        height: 20px;
                        font-size: 14px;
                        text-align: right;
                        color: #939598;
                    }
                    .title-role-mobile{
                        margin: 4px 0px 0px 0px;
                        height: 13px;
                        font-size: 11px;
                        text-align: right;
                        color: #939598;
                    }
                    .line {
                        box-sizing: border-box;
                        height: 40px;
                        margin-right: 24px;
                        margin-left: 24px;
                        width: 1px;
                        border: 1px solid #333333;
                    }
                    .line-mobile{
                        box-sizing: border-box;
                        height: 30px;
                        margin-right: 10px;
                        margin-left: 10px;
                        width: 1px;
                        border: 1px solid #333333;
                    }
                    .agency-switch{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    .title-icon{
                        position: relative;
                        top: 5px;
                        margin-right: 24px;
                    }
                    .title-user{ //this for updated UI
                        // height: 36px;
                        // width: 36px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                    }

                    .line-barrier{
                        width: 100%;
                        border: 1px solid #DDDDDD;
                    }
                    .topbar-container{
                        display: flex;
                        height: 56px;
                        width:100%;
                        position: relative;
                        top: 0;
                        flex-direction: row;
                        align-items: center;
                        box-shadow: 0px 2px 4px -1px #00000050;
                        z-index: 900;
                    }
                    .topbar-container-mobile {
                        display: flex;
                        height: 48px;
                        width:100%;
                        position: relative;
                        top: 0;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        box-shadow: 0px 2px 4px -1px #00000050;
                        z-index: 900;  
                    }
                    .logo-placeholder {
						display: flex;
                        padding: 0 0 0 24px;
                        height: 100%;
                        align-items: center;                
                    }
                    .logo-placeholder-mobile{
                        display: flex;
                        padding: 0 0 0 12px;
                        height: 100%;
                        align-items: center;
                    }
                    .logo{
                        display: flex;
                        align-items: center;
                    }
                    .title-user-mobile{
                        height: 100%;
                        display: flex;
                        align-items: center;
                    }
                    .logo-img {
                        margin-left: 25px;
                        cursor: pointer;
                        height: 24px;
                        width: 104px;
						height: auto;
                    }
                    .logo-img-mobile {
                        height: 20px;
                    }
					.icon-button {
                        outline: 0 !important;
						flex: 0 0 auto;
						color: rgba(0, 0, 0, 0.54);
						font-size: 1.5rem;
						text-align: center;
						transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
						border-radius: 50%;
						border: 0;
                        margin: 0;
                        padding: 0;
                        cursor: pointer;
						display: inline-flex;
						align-items: center;
						user-select: none;
						vertical-align: middle;
						justify-content: center;
						text-decoration: none;
						background-color: transparent;
						-webkit-appearance: none;
						-webkit-tap-highlight-color: transparent;
                    }
                    .space-between{
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                    }
                    h5{
                        font-size: 1.5rem;
                        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                        font-weight: 400;
                        line-height: 1.334;
                        letter-spacing: 0em;
                    }
                    .title-placeholder{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding-left: 20px;
                    }
                    .user-image-placeholder{
                        margin-right: 24px;
                        width: 100%;
                        height: 100%;
                        border-bottom: none;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;                       
                    }
                    .user-image-placeholder-mobile{
                        margin-right: 12px;
                        height: 100%;
                        border-bottom: none;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;   
                    }
                    .user-image-placeholder img{
                        width: 36px;
                        height: 36px;
                    }
                    .user-image-placeholder-mobile img{
                        width: 24px;
                        height: 24px;
                    }
                    .dropdown-content {
                        font-size: 14px;
                        width: 278px;
                        position: absolute;
                        top: 57px;
                        background-color: #f9f9f9;
                        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
                        border-radius: 4px;
                        padding: 8px;
                        z-index: 1;
                    }
                    .dropdown-content-mobile {
                        font-size: 14px;
                        width: 295px;
                        position: absolute;
                        top: 48px;
                        right: 1px;
                        background-color: #f9f9f9;
                        box-shadow: 0px 8px 10px rgb(0 0 0 / 14%), 0px 3px 14px rgb(0 0 0 / 12%), 0px 5px 5px rgb(0 0 0 / 20%);
                        border-radius: 4px;
                        padding: 8px;
                        z-index: 1;
                    }
                    .supply-container{
                        margin: 0px 0px 0px 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-sizing: border-box;
                        height: 25px;
                        width: 65px;
                        border: 1px solid #509D91;
                        border-radius: 4px;
                    }
                    .supply-container-topbar{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-sizing: border-box;
                        height: 25px;
                        width: 65px;
                        border: 1px solid #509D91;
                        border-radius: 4px;
                        margin: 0px 8px 0px 0px;
                    }
                    .role-container{
                        color: #509D91;
                        font-size: 12px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 16px;
                        text-align: center;
                    }
                    .role-container-topbar{
                        color: #509D91;
                        font-size: 12px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 16px;
                        text-align: center;
                        margin: 0px 4px 0px
                    }
                    .menu-button-style{
                        cursor: pointer;
                    }
                    .menu-hover-button-style{
                        background-color: rgba(0,0,0,0.03);
                        border-radius: 5px;
                        cursor: pointer;
                    }
                    .agency-list-container{
                        display:flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        background-color: #f9f9f9;
                    }
                    
                    .agency-list-container:hover{
                            display:flex;
                            justify-content: space-between;
                            align-items: center;
                            background-color: #DDDDDD;
                            border-radius: 5px;

                    }
                    .console-agency-name{
                        display: flex;
                        align-items: center;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .console-text-container{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    `
                }
            </style>       
        </div>

    )
}

export default TopBar
