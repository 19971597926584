import React, {useState} from 'react'
import Cookies from 'js-cookie';

//library
import { useMutation } from "@apollo/react-hooks";

import NavigationIcon from "../../Image/chevron_right.svg"

//component
import Loaders from "../../component/component-loaders/Loaders"
import SnackBar from '../../config/config-snackbar/SnackBar'
// import DeleteCard from '../../component/component-deletecard/DeleteCard'
import SaveCard from '../../component/component-save/SaveCard'
import { APIUpdateChannel } from '../../config/config-restAPI/patchAPICalled';
import { APIDeleteChannel } from '../../config/config-restAPI/deleteAPICalled';
import DeleteCard from '../../component/component-deletecardRest/DeleteCard'

const EditForm = ({title, selected, setMainFilter, setFilterFinal, APICalled, setPreFilter, setFilterValue, fx_back, FunctionForm, query, featureList, setCanUpdate, canUpdate, setSnOpen, setMessage, setSeverity, loading, featureDisable}) => {
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');
    const [values, setValues] = useState(selected.map(a => ({...a})));
    const [editVariables, setEditVariables] = useState({});
    const [getVariables, setGetVariables] = useState({});
    const [loadings, setLoadings] = useState(false);
    const [canAdd, setCanAdd] = useState(false) //only can add once all mandatory field filled
    const [displayed, setDisplayed] = useState(true)
    const [isHovered, setIsHovered] = useState({
      btnSave: false
    })

    //this state are for snackbar props
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("")

    const onChange = (e, i, value) => {
      const _values = [...values];
      if(value){
          let keys = Object.keys(value)
          keys.map(key => _values[i][key] = value[key])
      } else{
          _values[i][e.target.name] = e.target.value;
      }
      setValues(_values);
  };

  const onSubmit = async (e) => {
    try{
      e.preventDefault();
      const response = await APIUpdateChannel(token, agencyId, editVariables, setLoadings);
      if(response.data.status === 200) {
        setSnOpen(true);
        setMessage('Record has been succesfully added');
        setSeverity('success');
        setLoadings(false);
        APICalled('', '');
        fx_back();
      } else {
        setLoadings(false)
        setErrOpen(true)
        setErrMsg(`${response.data.status}: ${response.data.error.message}`)
        setErrSevere("error")
      }
    } catch(err) {
      setLoadings(false)
      setErrOpen(true)
      setErrMsg(JSON.stringify(err, null, 2))
      setErrSevere("error")
    }
  }

  const onDelete = async () => {
    try {
      const reducer = {
        channelUuid: [values[0].uuid],
      }
      const response = await APIDeleteChannel(token, agencyId, reducer, setLoadings);
      if (response.data.status === 200) {
        setSnOpen(true);
        setMessage("Record has been successfully deleted!");
        setSeverity("success");
        setLoadings(false)
        setFilterFinal({})
        APICalled('', '');
        fx_back();
      } else {
        setLoadings(false)
        setErrOpen(true)
        setErrMsg(`${response.data.status}: ${response.data.error.message}`)
        setErrSevere("error")
      } 

    } catch(err) {
      console.log(err)
      setLoadings(false)
      setErrOpen(true)
      setErrMsg('Error!, Unable to update please try again')
      setErrSevere("error")
    }
  }

  const onClickBack = () => {
    setFilterValue({})
    setPreFilter({})
    setFilterFinal({})  
    setMainFilter({})   
    fx_back()
  }

  if (loadings) return <div style={{position: "relative", right:"2px", top: "40px"}}><Loaders /></div>

    return (
        <div className="form-container">
        <div className="navigation-title-placeholder">
            <div className="title-add">
                   <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                   <div onClick={() => onClickBack()} style={{cursor: "pointer"}} className="detail-title first-title">
                     {title}
                   </div>
                   <div className="detail-title">
                      <img loading="lazy" src={NavigationIcon} alt="Back"/>
                   </div>                      
                  <div className="detail-title">Edit {title}</div>
            </div>
            <div className="button-delete-save-container">
              {
                featureDisable ? null : <DeleteCard canDelete={true} onDelete={onDelete} />
              }

              {featureDisable ? null : <SaveCard onSubmit={onSubmit} canAdd={canAdd}/> }
            </div>   
        </div>
        <FunctionForm
            values={values}
            onChange={onChange}
            displayed={displayed}
            setEditVariables={setEditVariables}
            setGetVariables={setGetVariables}
            query={query}
            fx_back={fx_back}
            setCanAdd={setCanAdd}
            setCanUpdate={setCanUpdate}
            canUpdate={canUpdate}
            featureList={featureList}
            setSnOpen={setSnOpen}
            setMessage={setMessage}
            setSeverity={setSeverity}
            setErrMsg={setErrMsg}
            setErrOpen={setErrOpen}
            setErrSevere={setErrSevere}
            loading={loadings}
            featureDisable={featureDisable} 
        />
    <style jsx>
      {`
        .title-add{
          display: flex;             
          align-items: center;             
        }
        .form-container{
          margin: 24px;
          display: grid;
        }
        .navigation-title-placeholder {
          font-size: 18px;
          font-weight: 545;
          height: 32px;
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
        }
        .details {
          height: 40px;
          // margin-left: 24px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.54);
        }
        .autocomplete {
          display: flex;
          height: 45px;
          // margin-left: 24px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.54);
        }
        .textfield {
          position: relative;
          bottom: 40px;
        }
        .detail-title{
          padding: 8px;
        }
        .first-title{
          font-size: 16px; 
          padding-left: 0px;
        }
        .button-delete-save-container{
          display: flex;
        }
      `}
    </style>
  </div>
    )
}
export default EditForm
