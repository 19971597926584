import React, {useState} from 'react'

//component
import {requiredField} from '../../config/config-table/table'
import { FIRMWARE_REDUCER } from "../../config/config-reducer/reducer";
import { AllMandatoryFilled, onUserAdd } from "../../config/config-function/function";
import Form from './Form'

const Add = ({ values, onChange, setAddVariables, displayed, query, setCanUpdate}) => {
    const [dates, setDates] = useState({})

    const onUserInput = (e) => {
        const input = onUserAdd(e, null, false, onChange, values)
        const _check = AllMandatoryFilled(requiredField.firmware, input)
        // console.log(_check)
        _check ? setCanUpdate(true) : setCanUpdate(false)
        setAddVariables({ new_firmware: FIRMWARE_REDUCER(input) });
        
    };

    return (
        <Form firmware={values} onUserInput={onUserInput} displayed={displayed} query={query} dates={dates} setDates={setDates}/>   
    );
}

export default Add
