import React, { useState, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks';
import Cookies from 'js-cookie';


import TableAssetStatus from './TableAssetStatus'
import { GET_PAYLOAD_BY_GROUP } from '../../config/config-graphql/Graphql'
import { APIGetPayloadByGroup } from '../../config/config-restAPI/getAPICalled'
import { WebsocketContext } from "../../config/config-websocket/Websocket";
import cloneDeep from 'lodash.clonedeep'


const PanelAssetStatus = ({ filter, setFilter, fx_changePage, history }) => {
    const payloadContext = useContext(WebsocketContext);
    const { data: websocketPayload, new_payload_id} = payloadContext;
    const [loadings, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState(filter)
    const [error, setError] = useState([])
    const [dataPayload, setDataPayload] = useState([])
    const [callAPIParams, setCallAPIParams] = useState(false)
    
    // const { data, loading, error } = useQuery(GET_PAYLOAD_BY_GROUP, { variables: { group_uuid: searchValue.uuid } });
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');
    /**
     * Executed once upon completion of page load
     */

     const APICalled = async (groupUuid) => {
        try {
            const result = await APIGetPayloadByGroup(token, setLoading, agencyId, groupUuid)
            // console.log(result);
            if (result.data.status === 200) {
                setDataPayload(result.data.data.map(x => x).sort((a, b) => {
                    if (isFinite(b._id -a._id)) { // sort -Infinity, NaN, Infinity to the end in random order
                        return b._id - a._id
                    } else {
                        return isFinite(b._id) ? -1 : 1;
                    }
                }))
                setLoading(false);
            } else {
                setLoading(false);
                setError(true)
            }
        } catch(err) {
            setLoading(false);
            setError(true)
        }
    }

    // console.log('payloadData', payloadData, dataPayload)

    useEffect(() => {
        if(token){
            APICalled('')
        }
    }, [])

    useEffect(() => {
        if(callAPIParams) {
            APICalled(searchValue.uuid)
            setCallAPIParams(false);
        }
    }, [callAPIParams])
    

    // console.log(websocketPayload);
    // !loading && console.log(data)

    // useEffect(() => {
    //     if (!loading) {
    //         const defaultData = data && data.getPayloadByGroup !== null ? data && data.getPayloadByGroup.map(x => { return x }) : []
    //         // console.log('go here')
    //         if (defaultData.length > 0) {
    //             // console.log(data.getPayloadByGroup.map(x => x).sort((a, b) => {
    //             //     if (isFinite(b.blobID - a.blobID)) { // sort -Infinity, NaN, Infinity to the end in random order
    //             //         return b.blobID - a.blobID
    //             //     } else {
    //             //         return isFinite(b.blobID) ? -1 : 1;
    //             //     }
    //             // }))
    //             setDataPayload(data.getPayloadByGroup.map(x => x).sort((a, b) => {
    //                 if (isFinite(b.pairId - a.pairId)) { // sort -Infinity, NaN, Infinity to the end in random order
    //                     return b.pairId - a.pairId
    //                 } else {
    //                     return isFinite(b.pairId) ? -1 : 1;
    //                 }
    //             }))
    //         }
    //         else {
    //             setDataPayload([]);
    //         }
    //     }
    //             //---this is to disable the warning for useEffect----//
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [loading])

    /**
     * Executed with every new payload entry from Subscription API
     */
    useEffect(() => {
        onNewSubscribeData(new_payload_id, websocketPayload)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [new_payload_id])

    const onNewSubscribeData = async (id, newPayload) => {

        if (dataPayload.length > 0) {

            // Clone dataPayload array for good measure
            const dataArrayClone = cloneDeep(dataPayload)

            // Get array index of the matched blobID
            const dataFind = dataArrayClone.indexOf(dataArrayClone.find(x => x.pairId === newPayload.pairId))

            // console.log({data:dataArrayClone, newPayload})

            // Insert new payload data into the new array, *dataArrayClone* at index, *dataFind*
            if (dataFind >= 0) {
                const newObj = websocketPayload
                dataArrayClone[dataFind] = newObj

                setDataPayload(dataArrayClone)
            }

        }

    }

    // console.log(dataPayload)

    return (
        <div>
            <TableAssetStatus data={dataPayload} loading={loadings} setCallAPIParams={setCallAPIParams} error={error} setError={setError} searchValue={searchValue} setSearchValue={setSearchValue} setFilter={setFilter} fx_changePage={fx_changePage} history={history}/>
        </div>
    )
}

export default PanelAssetStatus
