import React, { useState, useEffect } from 'react'
import { useQuery } from "@apollo/react-hooks";
import { GET_PAIRABLE_ASSETS } from "../../config/config-graphql/Graphql";
import DataListInput from "react-datalist-input";

const PairedModal = ({ open, fx_close, fx_pairAsset, mainAsset, details, mainAssetSelection, assetListTwo, detailsPage}) => {

    const [optionList, setOptionList] = useState([])
    const [selectedAsset, setSelectedAsset] = useState({})

    const { data, loading, error } = useQuery(GET_PAIRABLE_ASSETS, { variables: { searchKey: "" }})

    useEffect(() => {
        if(!detailsPage){
            if(error){
                setOptionList([])
            }
            
            else if(!loading && data){
                const optionData = data.get_pairable_assets && data.get_pairable_assets.filter(el => el.uuid !== mainAssetSelection.uuid)
                let option = optionData && optionData.map(el => { return { value: el.uuid, label: el.name }})
                option ? setOptionList(option) : setOptionList([])
            } else{
                setOptionList([])
            }
        }

        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainAssetSelection])

    useEffect(() => {
        if(detailsPage){
            if(error){
                setOptionList([])
            }
            else if(!loading && data){
                const optionData = data.get_pairable_assets && data.get_pairable_assets.filter(el => el.uuid !== mainAssetSelection.uuid)
                let option = optionData && optionData.map(el => { return { value: el.uuid, label: el.name }})
                option ? setOptionList(option) : setOptionList([])
            } else{
                setOptionList([])
            } 
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])


    return (
        <div className="pair-modal-container" style={{ display: !open && "none" }}>
            <div className="pair-modal-background" onClick={fx_close}></div>
            <div className="pair-modal-content">
                <div className="title-modal">Pair Asset</div>
                <div className="title-desc">{details}</div>
                <div className="selection-input">
                        <DataListInput placeholder="Select an asset" items={optionList} onSelect={(value => setSelectedAsset(value))}/>
                </div>
                <div className="button-placeholder">
                    <button onClick={(e) => fx_pairAsset(selectedAsset, fx_close)} className="pair-btn">Pair</button>
                </div>
            </div>                    

            <style>
                {
                    `
                    .title-modal{
                        color: #F5A623;
                        font-size: 24px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 32px;
                        text-align: center;
                    }
                    .selection-input{
                        border: 1px solid #999999;
                        border-radius: 4px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        padding: 0px 0px 0px 8px;
                        margin: 16px 0px 0px 0px;
                    }
                    .title-desc{
                        color: #939598;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                        text-align: center;
                    }
                    .pair-modal-container{
                        position: fixed;
                        z-index: 1300;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                    }
                    .pair-modal-background{
                        z-index: -1;
                        position: fixed;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                        background-color: rgba(0, 0, 0, 0.5);
                        -webkit-tap-highlight-color: transparent;
                    }
                    .pair-modal-content{
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        width: 680px;
                        height: 237px;
                        border-radius: 4px;
                        padding: 16px;
                        transform: translate(-50%, -50%);
                        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
                        background-color: #FFFFFF;
                        display: block;
                        
                    }
                    .pair-modal-content h3{
                        text-align:center;
                    }
                    .button-placeholder{
                        display: flex;
                        justify-content: center;
                        margin: 24px 0px 0px 0px;
                    }
                    .pair-modal-content button{
                        width: 160px;
                        height: 32px;
                        background-color: #509D91;
                    }
                    .pair-btn{
                        // border: 1px solid #000;
                        color: #FFF;
                    }
                    .yes-btn{
                        background: #000;
                        color: #fff;
                    }
                    .input-text{
                        box-sizing: border-box;
                        height: 40px;
                        width: 648px;
                        border: 1px solid #939598;
                        border-radius: 4px;
                      }
                    }
                    `
                }
            </style>     
        </div>

    )
}

export default PairedModal
