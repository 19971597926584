import React, { useState } from 'react'

//list of library
import moment from "moment-timezone";

//list of components
import { filterTypeOption } from "../../config/config-filter/filter";
import FilterByCard from '../component-filterbycard/FilterByCard'

moment.tz.setDefault("Asia/Kuala_Lumpur")

const FilterBy = ({ setFilterFinal, filterList, filterValue, setFilterValue }) => { 

    const [filterVariable, setFilterVariable] = useState({})
    const [disableButton, setDisableButton] = useState(true)

    //this is to select the filter
    const SelectFilter = (e, key, filterType) => {
        let _filter = { ...filterValue }
        if(e.target.checked){
          if(filterType !== filterTypeOption.date){
            _filter[key] = { type: "", value: [] };
            setFilterValue(_filter)
          } else{
            _filter[key] = { type: "BEFORE", value: new Date() };
            setFilterValue(_filter)
            setFilterVariable(_filter)
          }

        } else{
            _filter[key] && delete _filter[key]
            setFilterValue(_filter)
        }

        if(CanFilter(_filter)) setDisableButton(false)
        else setDisableButton(true)
    }

    //this to addTheFilterValue
    const AddFilterValue = (key, value, type, filterType, matchCase) => {
      let filter = { ...filterValue }

      if(filterType === filterTypeOption.tags || filterType === filterTypeOption.date){
        if(filter[key]){
          filter[key] = {type, value };
          setFilterValue(filter)
          setFilterVariable(filter)

        }
        setFilterVariable(filter)
      } else if(filterType === filterTypeOption.boolean){
        if(filter[key]){
          filter[key] = {type, value: [ value[0] ] };
          setFilterValue(filter)
          if(matchCase.truthy.includes(value[0].toLowerCase())){
            setFilterVariable({ ...filterVariable, [key]: true})
          } else if(matchCase.falsy.includes(value[0].toLowerCase())){
              setFilterVariable({ ...filterVariable, [key]: false})
          }
        }
      }
      if(CanFilter(filter)) setDisableButton(false)
      else setDisableButton(true)
    }


    const FilterData = () => {
        setFilterFinal(filterVariable)
    }


    const CanFilter = (filter) => {
      if(Object.keys(filter).length > 0){
        let objKeys = Object.keys(filter)
        let totalValueEntered = 0;
        for (let i = 0; i < objKeys.length; i++) {
          const el = objKeys[i];
          if(Array.isArray(filter[el].value) && filter[el].value.length > 0){
            totalValueEntered = totalValueEntered + filter[el].value.length;
          } else if(filter[el].value){
            totalValueEntered = totalValueEntered + 1;
          }
        }
        if(totalValueEntered > 0){
          return true
        } else return false;

      } else return false;
    }

    return (
        <div className="filter-by-container">
            {
                filterList.map(el => <FilterByCard canFilter={filterValue[el.key] ? true : false} label={el.label} filterKey={el.key} option={el.option} SelectFilter={SelectFilter} value={filterValue[el.key] ? filterValue[el.key].value : null} AddFilter={AddFilterValue} filterType={el.type} matchCase={el.matchCase} />)
            }
            <div className="btn-filter">
                <button className={`${disableButton ? "disabled-button" : "active-button"}`} disabled={disableButton} onClick={() => FilterData()}>Filter</button>
            </div>
            <style jsx>
                {
                    ` .filter-by-container{
                        position: absolute;
                        width: 412px;
                        background-color: #FFFFFF;
                        box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2);
                        margin: 40px 0px 0px 0px;
                        padding: 16px;
                      }
                      .filter-checkbox{
                        display: flex;
                        justify-content: space-between;                       
                        margin: 0px 0px 7px 0px;
                      }
                      .tagging{
                        margin: 0px 0px 4px 0px;
                      }
                      .checkbox-container{
                        display: flex;
                        align-items: center;
                      }
                      .check-type{
                        margin: 0px 4px 0px 0px;
                        height: 18px;
                        width: 18px;
                        background-color: #509D91;
                      }
                      .div-name{
                        color: #333333;
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 21px;
                      }
                      .select-container{
                        box-sizing: border-box;
                        height: 33px;
                        width: 177px;
                        border: 1px solid #979797;
                        border-radius: 4px;
                        background-color: #FFF;
                      }
                      .react-tag-input {
                        min-height: 32px;
                        background: #e1e1e1;
                        padding: 0px;
                      }
                      .react-tag-input__tag {
                        background: #509D91;
                        color: #FFF;
                      } 
                      .react-tag-input__tag__remove{
                        background: #509D91;
                        color: #FFF;
                      }
                      .react-tag-input__tag__remove:before {
                        background-color: #FFF;
                      }
                      .react-tag-input__tag__remove:after {
                        background-color: #FFF;
                      }
                      .line-seperator{
                        box-sizing: border-box;
                        height: 1px;
                        width: 380px;
                        border: 1px solid #DDDDDD;
                        margin: 0px 0px 7px 0px;
                      }
                      .btn-filter{
                        display: flex;
                        justify-content: flex-end;
                      }
                      .btn-filter button{
                        padding: 10px 20px;
                      }
                      .btn-filter .active-button{
                        background-color: #509D91;
                        color: #fff;
                      }
                      .btn-filter .disabled-button{
                        background-color: #939598;
                        color: #fff;
                      }
                    `
                }
            </style>
        </div>
    )
}
export default FilterBy
