import React,{useState, useEffect, useContext} from 'react'

//library
import { useQuery, useMutation } from "@apollo/react-hooks";

//image

import GreenRefreshIcon from '../../Image/green_refresh.svg'
import DarkGreenRefreshIcon from '../../Image/dark_green_refresh.svg'
import DarkGreenBlockIcon from '../../Image/dark_green_block.svg'
import GreenBlockIcon from '../../Image/green_block.svg'
import RedBlockIcon from '../../Image/red_block.svg'
//component
// import SigfoxConfiguration from './SigfoxConfiguration'
import SigfoxConfig from '../../component/component-sigfoxConfig/SigfoxConfig'
import tableConfig from "../../config/config-table/table";
import { GET_SKU, REBOOT_DEVICE, SET_BLOCK_DEVICE, GET_DEVICES, GET_DEVICES_STAT, CELLULAR_CONFIG, UPDATE_DEVICES, REGENERATE_CELLULAR_KEY} from "../../config/config-graphql/Graphql";
import DeviceInformation from './DeviceInformation'
import menuConfig from '../../config/config-menu/menu'
import { settingFieldValue, requiredLabel } from "../../config/config-function/function";
import {firebaseContext} from "../../config/config-firebase/FirebaseContext"
import Board from './Board'
import Feature from './Feature'
import IOTConfiguration from './IOTConfiguration'
import GenerateKey from './GenerateKey'
import GenerateKeyModal from './GenerateKeyModal'

const { device:fieldList } = tableConfig.fieldList;

const Form = ({ device, onUserInput, setPageInd, query, setFilterMain, fx_changePage, setFilterFinal, displayed, setValSku, valSku, valAgency, setValAgency, valfuel, setValfuel, valBoard, setValBoard, featureAdd, featureRemove, setFeatureAdd, setFeatureRemove, fx_back,  feature, setFeature,  staticAgency, setSigfoxConf, sigfoxConf, staticBoardID, setDeviceIDs, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere, history, setCellularConf, cellularConf, setGenModal, genModal, editData, editVariables}) => {

    const [selectedSkuFeatures, setSelectedSkuFeatures] = useState([])
    const [featureOption, setFeatureOption] = useState([]);
    const isBulkEdit = Array.isArray(device) && device.length > 1;
    const filterSKU = displayed ? {product_category: {type: "IS", value: device[0].device_type}} : {product_category: {type:"IS", value: "Telematics"}}
    const {data: dataSKU, loading: loadSKU} = useQuery(GET_SKU, {variables: {filter: filterSKU}});
    const firebaseConfig = useContext(firebaseContext)
    const [cellularKey, setCellularKey] = useState(null)
    const [blocked, setBlocked] = useState(null)
    const [comm, setComm] = useState(false)
    const [displayDevice, setDisplayDevice] = useState(false);
    const [isHovered, setIsHovered] = useState({
        serialNumber: false,
        SKU: false,
        description: false,
        board_id: false,
        firmware: false,
        apply: false,
        statInt: false,
        inMotionInt: false,
        gps: false,
        dLink: false,
        cellularApply: false,
        operationMode: false,
        msgInterval: false,
        msgIntervalOff: false,
        offlineMsgLog: false,
        cellModuleAlive: false,
        radioAccess: false,
        fDensityRatio: false,
        length: false,
        width: false,
        height: false,
        generateKey: false,
        reboot:false,
        block:false,
        copy: false
    })

    //this is to take fuel name for displaying fuel calibration
    // const FuelUUID = featureList.find(el => el.name === "Fuel")

    // console.log(setSnOpen)
    // console.log(setMessage)
    // console.log(setSeverity)

    const assemblyClasss = displayed ? device[0].device_type === "Telematics" ? valBoard.map(x => x.assembly ? x.assembly.board_class : null) : [] : valBoard.map(x => x.assembly ? x.assembly.board_class : null)
    const removeDuplicated = assemblyClasss.filter((value, index) => assemblyClasss.indexOf(value) === index)
    const sigfoxFilter = removeDuplicated.filter(x => x === "Sigfox")
    const cellularFilter = removeDuplicated.filter(x => x === "Cellular")

    const celularBoard = displayed ? device[0].device_type === "Telematics" ? valBoard.filter(x => x.batch_id === "Cellular") : [] : []
    const sigfoxBoard = displayed ? device[0].device_type === "Telematics" ? valBoard.filter( x => x.batch_id === "Sigfox") : [] : []
    

    useEffect(() => {
        if(displayed && cellularFilter.length > 0 && !comm && (device[0].SKU.product_model !== 'Teltonika')){
            const blockedComm = celularBoard[0].config.blocked 
            setBlocked(blockedComm)
        }
    }, [device])
    
    //this to take boardClass sigfox value for sigfox configuration. If the board has value "Sigfox" it will return else empty array

    //------------------ //

 
    //handle change sku
    const handleChangeSKU = (key, value) => {
        let clone = {...valSku}
        clone[key] = value
        setValSku(clone)
        if(value){
            const sku = dataSKU.get_skus.sku.find(el => el.uuid === value);
            if(sku){
                if(typeof sku.features[0] === "object") setSelectedSkuFeatures(sku.features.map(x => x.uuid))
                else setSelectedSkuFeatures(sku.features)
                let _board = [];
                for (let i = 0; i < sku.assemblies.length; i++) {
                    const el = sku.assemblies[i];
                    _board.push({ assembly: el})
                }
                setValBoard(_board)
            }else setValBoard([])
        } else{
            setValBoard([])
        }
    }

    useEffect(() => {
        if(displayed){
            if(device[0].SKU.product_model === 'Smart_Lock' || device[0].SKU.product_model === 'Teltonika'){
                setDisplayDevice(false)
            } else {
                setDisplayDevice(true);
            }
        }
    }, [device])

    useEffect(() => {
        if(!loadSKU && valSku){
            const sku = dataSKU && dataSKU.get_skus.sku.find(el => el.uuid === valSku.uuid);
            if(sku){
                if(typeof sku.features[0] === "object") setSelectedSkuFeatures(sku.features.map(el => el.uuid))
                else setSelectedSkuFeatures(sku.features)
            }            
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadSKU])

    const handleChangeFuel = (key, value) => {
        let clone = {...valfuel}
        clone[key] = value
        setValfuel(clone)
    }

    const settingAssembly = (i, key, value) => {
        let clone = [...valBoard]
        clone[i][key] = value
        setValBoard(clone)
        if(sigfoxFilter.length > 0){
            setDeviceIDs(clone.map(x => typeof x.board_id !== "undefined" && x.board_id.toString().toUpperCase().padStart(8,"0")))
        }     
    }

    const onChangePage = () =>{
        // console.log(valAgency)
        setFilterMain({uuid: valAgency.uuid})
        fx_changePage(menuConfig.console[1].key, menuConfig.console[2].children[1].key)
        history.push("/console/agency_mgmt/agencies")
    }

    // this function to reboot the device button (for cellular)
    const rebootDevice = async (e) => {
        try{
            const response = await rebootData({variables: {deviceId: celularBoard[0].board_id}})
            if(response.errors){
                setErrOpen(true)
                setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
                setErrSevere("error")
            }else{
                setErrOpen(true)
                setErrMsg("Reboot command sent.")
                setErrSevere("success")
            }
        }catch(err){
            setErrOpen(true)
            setErrMsg(JSON.stringify(err.message, null, 2))
            setErrSevere("error")
        }
        // console.log(device[0].uuid) //this for vale in reboot api
    }

    // console.log(cellularBoard)

    //this function are for allow & block comm button (for cellular)
    const commFunction = async (e, value) => {
        try{
            const response = await blockData({variables: {deviceId: celularBoard[0].board_id, block: value}})
            if(response.errors){
                setErrOpen(true)
                setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
                setErrSevere("error")
            }else{
                const resBlocked = response.data.setBlockDevice.blocked
                setBlocked(resBlocked)
                setComm(true)

                if(value){
                    setErrOpen(true)
                    setErrMsg("Device communication blocked")
                    setErrSevere("success")
                }else{
                    setErrOpen(true)
                    setErrMsg("Device communication allowed")
                    setErrSevere("success")
                }

            }
        }catch(err){
            setErrOpen(true)
            setErrMsg(JSON.stringify(err.message, null, 2))
            setErrSevere("error")
        }
    }

    const cellularApply = async (e) => {
        try{
            //this reducer for cellular config
            const reducer = {
                FuelSensingRatio: cellularConf.FuelSensingRatio,
                extSense_Fuel: cellularConf.extSense_Fuel,
                extSense_Vibration: cellularConf.extSense_Vibration,
                ignitionMode: cellularConf.ignitionMode === "true" ? true : false,
                payloadInterval: parseInt(cellularConf.payloadInterval),
                payloadIntervalIgnitionOff: parseInt(cellularConf.payloadIntervalIgnitionOff),
                payloadIntervalOffline: parseInt(cellularConf.payloadIntervalOffline),
                rat: parseInt(cellularConf.rat),
                telitKeepAlive: parseInt(cellularConf.telitKeepAlive)
            }

            const response = await cellularData({variables:{deviceId: celularBoard[0].board_id, input: reducer}})
            if(response.errors){
                setErrOpen(true)
                setErrMsg(`Update Cellular: ${response.errors[0].extensions.code}: ${response.errors[0].message}` )
                setErrSevere("error")
            }else{
                //this to update the fuel sensor
                const fuel_response = await editData({variables: {
                    updated_device: {
                        uuid: editVariables.updated_device[0].uuid,
                        fuel_sensor: editVariables.updated_device[0].fuel_sensor
                    }
                }})

                if(fuel_response.errors){
                    setErrOpen(true)
                    setErrMsg(`Update Device: ${fuel_response.errors[0].extensions.code}: ${fuel_response.errors[0].message}` )
                    setErrSevere("error")
                }else{
                    setErrOpen(true)
                    setErrMsg("Cellular configuration updated. The configuration will be propagated to the device when it connects.")
                    setErrSevere("success")
                }
            }
        }catch(err){
            // console.log("go here ")
            setErrOpen(true)
            setErrMsg(JSON.stringify(err.message, null, 2))
            setErrSevere("error")
        }
    }

    const switchModal = () => {
        setGenModal(!genModal)
    }

    const onClickGenerate = async (e) => {
        try{
            const response = await regenerateCellular({variables: {deviceId: celularBoard[0].board_id}})
            if(response.errors){
                setErrOpen(true)
                setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}` )
                setErrSevere("error")
            }else{
                const newPrivateKey = response.data.regenerateCellularConfigKey.privateKey
                setCellularKey(newPrivateKey)
                setErrOpen(true)
                setErrMsg("New key generated!")
                setErrSevere("success")
                setGenModal(!genModal)
            }
        }catch(err){
            setErrOpen(true)
            setErrMsg(JSON.stringify(err.message, null, 2))
            setErrSevere("error")
        }
    }



    const [rebootData, { loading: rebootLoading, error:rebootError }] = useMutation(REBOOT_DEVICE, {
        refetchQueries: () => [
            {
                query: GET_DEVICES,
                variables: {filter: {}, type:"All" },
            },
            {
                query: GET_DEVICES_STAT,
            }
        ],
    });

    const [blockData, { loading: blockLoading, error:blockError }] = useMutation(SET_BLOCK_DEVICE, {
        refetchQueries: () => [
            {
                query: GET_DEVICES,
                variables: {filter: {}, type:"All" },
            },
            {
                query: GET_DEVICES_STAT,
            }
        ],
    });

    const [cellularData, { loading: cellularLoading, error:cellularError }] = useMutation(CELLULAR_CONFIG, {
        refetchQueries: () => [
            {
                query: GET_DEVICES,
                variables: {filter: {}, type:"All" },
            },
            {
                query: GET_DEVICES_STAT,
            }
        ],
    });

    const [regenerateCellular, { loading: regenerateLoading, error:regenerateError }] = useMutation(REGENERATE_CELLULAR_KEY, {
        refetchQueries: () => [
            {
                query: GET_DEVICES,
                variables: {filter: {}, type:"All" },
            },
            {
                query: GET_DEVICES_STAT,
            }
        ],
    });


    return (
        <div className="device-main-container">
            <GenerateKeyModal open={genModal} fx_close={switchModal} onClickGenerate={onClickGenerate} valBoard={valBoard}/>  
            <div className="first-box-container">

                {
                    displayed ? displayDevice && cellularFilter.length > 0 ? <div className="btn-reboot-block-container">
                        <div style={{margin:"0px 16px 0px 0px"}} className={`${isHovered.reboot ? "isHovered-button-apply" : "button-apply"}`} onClick={(e) => rebootDevice(e)} onMouseEnter={() => setIsHovered((current) => ({...current, reboot: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, reboot: false}))}>
                            <img loading="lazy" src={DarkGreenRefreshIcon} alt="refresh"/>
                            <div style={{margin: "0px 0px 0px 14px"}}>Reboot Device</div> 
                        </div>
                        <div>
                            {
                                displayed ? !blocked ? <div className={`${isHovered.block ? "isHovered-button-apply-block-green" : "button-apply-block-green"}`} onClick={(e) => commFunction(e, true)} onMouseEnter={() => setIsHovered((current) => ({...current, block: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, block: false}))}>
                                    <img loading="lazy" src={DarkGreenBlockIcon} alt="block-icno"/>
                                    <div style={{margin: "0px 0px 0px 14px"}}>Block Communication</div>
                                </div> :
                                <div className={`${isHovered.block ? "isHovered-button-apply-block-red" : "button-apply-block-red"}`} onClick={(e) => commFunction(e, false)} onMouseEnter={() => setIsHovered((current) => ({...current, block: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, block: false}))}>
                                    <img loading="lazy" src={RedBlockIcon} alt="no-block"/>
                                    <div style={{margin: "0px 0px 0px 14px"}}>Allow Communication</div>
                                </div> : null
                            }
                            
                            
                        </div>
                    </div> : null : null
                }

                <DeviceInformation requiredLabel={requiredLabel} isHovered={isHovered} settingFieldValue={settingFieldValue} onUserInput={onUserInput} displayed={displayed} device={device} setIsHovered={setIsHovered} valSku={valSku} handleChangeSKU={handleChangeSKU} loadSKU={loadSKU} dataSKU={dataSKU} onChangePage={onChangePage} valAgency={valAgency} isBulkEdit={isBulkEdit} fieldList={fieldList}/>
                <Board displayed={displayed} device={device} firebaseConfig={firebaseConfig} valBoard={valBoard} requiredLabel={requiredLabel} settingAssembly={settingAssembly} fieldList={fieldList} setIsHovered={setIsHovered} isHovered={isHovered}/>
                <Feature selectedSkuFeatures={selectedSkuFeatures} featureAdd={featureAdd} featureRemove={featureRemove} setFeatureAdd={setFeatureAdd} setFeatureRemove={setFeatureRemove} displayed={displayed} device={device} feature={feature} setFeature={setFeature} firebaseConfig={firebaseConfig} setFeatureOption={setFeatureOption} featureOption={featureOption}/>
            </div>       
            <div className="second-box-container" >
                {
                    displayed ? device[0].device_type !== "Smartlocks" && cellularFilter.length > 0 ? <IOTConfiguration setIsHovered={setIsHovered} isHovered={isHovered} displayed={displayed} setCellularConf={setCellularConf} cellularConf={cellularConf} valfuel={valfuel} handleChangeFuel={handleChangeFuel} device={device} cellularConfig={CELLULAR_CONFIG} getDevice={GET_DEVICES} getDeviceState={GET_DEVICES_STAT} cellularApply={cellularApply} displayTeltonika={displayDevice} /> : device[0].device_type !== "Smartlocks" && sigfoxFilter.length > 0 ? <SigfoxConfig setSigfoxConf={setSigfoxConf} sigfoxConf={sigfoxConf} displayed={displayed} staticBoardID={staticBoardID} sigfoxFilter={sigfoxFilter} setErrMsg={setErrMsg} setErrOpen={setErrOpen} setErrSevere={setErrSevere} setIsHovered={setIsHovered} isHovered={isHovered} details={firebaseConfig.getValueByKey("component_sigfox_config")}/> :
                    null : null
                }
                {
                   displayed ? displayDevice && cellularFilter.length > 0 ? <GenerateKey isHovered={isHovered} displayed={displayed} setIsHovered={setIsHovered} setErrMsg={setErrMsg} setErrOpen={setErrOpen} setErrSevere={setErrSevere} setGenModal={setGenModal} valBoard={valBoard} setCellularKey={setCellularKey} cellularKey={cellularKey}/> : 
                   null : null                  
                }
            </div>
            <style jsx>
                {
                    `
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                    }

                    /* Firefox */
                    input[type=number] {
                    -moz-appearance: textfield;
                    }
                    .device-main-container{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 24px;
                        grid-template-areas: ". .";
                    }
                    .fuel-title{
                        display: flex;
                        align-items: center;
                    }
                    .not-calibrated-text{
                        font-size: 10px;
                        color: #EA2B1F;
                    }
                    .calibrated-text{
                        font-size: 10px;
                        color: #286551;
                    }
                    .caliration-box-green{
                        box-sizing: border-box;
                        height: 25px;
                        width: 87px;
                        border: 1px solid #286551;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .calibration-box{
                        box-sizing: border-box;
                        height: 25px;
                        width: 87px;
                        border: 1px solid #EA2B1F;
                        border-radius: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .label-text{
                        margin: 0px 0px 0px 4px;
                        color: #939598;
                    }
                    .link{
                        color: blue;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                    .text-icon-volumn-two{
                        display: flex;
                        align-items: center;
                        width: 150px;
                    }
                    .text-icon-volumn-one{
                        display: flex;
                        align-items: center;
                        width: 150px;
                        margin: 0px 0px 8px 0px;
                    }
                    .text-icon-height{
                        display: flex;
                        align-items: center;
                        position: relative;
                        bottom: 110px;
                        left: 150px
                    }
                    .text-icon-width{
                        position: relative;
                        bottom: 30px;
                        left: 130px;
                        display: flex;
                        align-items: center;
                    }
                    .details-assembly{
                        display: grid;
                        grid-template-columns: 184px 1fr;
                        grid-template-rows: 1fr;
                        gap: 1% 0px;
                        grid-template-areas: ". .";
                        align-items: center;
                        padding: 0px 0px 8px 0px;
                    }
                    .input-input-device-admin{
                        display: flex;
                        width: 133px;

                    }
                    .device-information-container{               
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        padding: 16px;
                    }
                    .second-box-container{
                        // margin: 58px 0px 0px 0px;
                    }
                    .first-box-container{
                    }
                    .device-container{
                        width: 100%;
                        margin: 0px 16px 0px 0px;
                    }
                    .device-title{
                        height: 32px;
                        width: 100%;
                        color: #333333;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                    .input-input{
                        height: 34px;
                        display: flex;
                        align-items: center;
                        color: #939598;
                    }
                    .title{
                        margin: 0px 8px 0px 0px;
                        height: 21px;
                        color: #333333;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 21px;
                      }
                    .title-detail{
                        margin: 4px 0px 0px 0px;
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                    .device-input{
                        display: flex;
                    }
                    .device-details{
                        height: 32px;
                        width: 100%;
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 16px;
                        margin: 0px 0px 0px 32px;
                    }
                    .agency-label{
                        border-style:none;
                        background-color: #FFF;
                        outline: none;
                        display: flex;
                        align-items: center;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        pointer: cursor;
                    }
                    .details-input{
                        display: grid;
                        grid-template-columns: 200px 2fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        align-items: center;
                        grid-template-areas:
                          ". .";
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .board-features-container{

                    }
                    .sensor-custom-delete-container{

                    }
                    .box-info{
                      }
                    .text-box{
                        display: flex;
                        align-items: center;
                        border-style: none;
                        height: 25px;
                        border-radius: 4px;
                        outline: none;
                    }
                    .isHovered-border{
                        height: 25px;
                        outline: none;
                        border: 1px solid #b6babf;
                        border-radius: 4px;
                    }
                    .board-container{
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        padding: 16px;
                        margin: 0px 0px 24px 0px;
                    }
                    .features-container{
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        padding: 16px;
                    }
                    .fuel-container{
                        margin: 0px 0px 24px 24px;
                        height: 236px;
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        padding: 16px;
                    }
                    .custom-container{
                        height: 137px;
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        margin: 24px 0px 0px 24px;
                        padding: 16px;
                    }
                    .delete-device-container{
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        margin: 0px 0px 0px 0px;
                        padding: 16px;
                    }
                    .selection-board{
                        border-style:none;
                        background-color: #FFF;
                        height: 24px;
                        width: 200px;
                        margin: 0px;
                        outline: none;
                        border-radius: 4px;
                    }
                    .isHovered-selection{
                        background-color: rgba(182, 186, 191, 0.2);
                        border-radius: 4px;
                        border-style:none;
                        height: 24px;
                        width: 200px;
                        margin: 0px;
                        outline: none;
                      }
                    .text-boxes{
                        border-style: none;
                        height: 24px;
                        width: 100%;
                        background: transparent;
                        outline: none;
                    }
                    .icons-container{
                        position: relative;
                        bottom: 25px;
                        transform: translate(28%);
                        height: 150px;
                    }
                    .input-icons{
                        position: relative;
                        bottom: 140px;
                        left: 280px;
                    }
                    .img-container{

                    }
                    .text-icon{
                        position: relative;
                        top: 135px;
                        right: 80px;
                        display: flex;
                        align-items: center;
                        margin: 0px 21px 0px 0px;
                    }
                    .text-key-in{
                        color: #939598;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;

                    }
                    .assembly-container{
                        box-sizing: border-box;
                        border: 1px solid #939598;
                        border-radius: 4px;
                        padding: 16px;
                        margin: 16px 0px 0px 0px;
                    }
                    .asse-text{
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .margin-change{
                        margin: 4px 0px 8px 0px;
                    }
                    .btn-reboot-block-container{
                         display: flex;
                         margin: 0px 0px 24px 0px;
                    }
                    .isHovered-button-apply{
                        box-sizing: border-box;
                        height: 34px;
                        width: 212px;
                        border: 1px solid #498f84;
                        border-radius: 2px;
                        color: #498f84;
                        cursor: pointer;
                        font-weight: 600;
                        outline: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                    }
                    .button-apply{
                        box-sizing: border-box;
                        height: 34px;
                        width: 212px;
                        border: 1px solid #509D91;
                        border-radius: 2px;
                        color: #509D91;
                        cursor: pointer;
                        outline: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .button-apply-block-green{
                        box-sizing: border-box;
                        height: 34px;
                        width: 212px;
                        border: 1px solid #509D91;
                        border-radius: 2px;
                        color: #509D91;
                        cursor: pointer;
                        outline: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .button-apply-block-red{
                        box-sizing: border-box;
                        height: 34px;
                        width: 212px;
                        border: 1px solid #EA2B1F;
                        border-radius: 2px;
                        color: #EA2B1F;
                        cursor: pointer;
                        outline: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .isHovered-button-apply-block-green{
                        box-sizing: border-box;
                        height: 34px;
                        width: 212px;
                        border: 1px solid #498f84;
                        border-radius: 2px;
                        color: #498f84;
                        cursor: pointer;
                        font-weight: 600;
                        outline: none;
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .isHovered-button-apply-block-red{
                        box-sizing: border-box;
                        height: 34px;
                        width: 212px;
                        border: 1px solid #EA2B1F;
                        border-radius: 2px;
                        color: #EA2B1F;
                        cursor: pointer;
                        font-weight: 600;
                        outline: none;
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    `
                }
            </style>
        </div>
  )
}

export default Form
