import React, {useState, useEffect} from 'react'
import { snakeCase } from "snake-case"

//library
import { useQuery } from "@apollo/react-hooks";

//component
import {GET_FEATURE} from "../../config/config-graphql/Graphql"

//staticFeatureList - features that is disabled and cannot uncheck
 const FeatureSelect = ({skuSelection, staticFeatureList, setFeatureAdd, featureAdd, featureRemove, setfeatureRemove, displayed, data, feature, setFeature, isSKU, featureOption, setFeatureOption}) => {
    const { data : data_feature, loading} = useQuery(GET_FEATURE)
    const url = "https://storage.googleapis.com/horizon-cloud-dev/feature-class"
    const [staticFeature, setStaticFeature] = useState([])
    // let featureList = displayed ? data && data[0].features && data[0].features.map((x,i) => {return x.uuid}): []

    useEffect(() => {
        setStaticFeature(staticFeatureList)
        setFeatureAdd(feature.length > 0 ? feature.map(x => {return {uuid: x}}) : [])
    }, [staticFeatureList])
   
    const handleChange = (e) => {
        // console.log(staticFeature)

        // console.log(e.target.value)
       
        if (e.target.checked){
            // console.log("checked")
            // console.log("test targte checked")
            let featureChecked = [...featureAdd]
            let featureOriginal = [...feature]

            featureChecked.push({uuid: e.target.value})
            featureOriginal.push(e.target.value)
            

            // setFeatureAdd(featureChecked)
            setFeature(featureOriginal)

        }else if(!staticFeature.includes(e.target.value)){
            // console.log("static fature")

            // console.log("static list")
            let remainingFeatures = feature.filter((el) => el !== e.target.value)
            // console.log(remainingFeatures)
            setFeature(remainingFeatures)
            // setFeatureAdd(feature)
            

            // console.log(feature)
           
            // if(displayed){
            //     console.log("edit here")
            //     if(featureList.includes(e.target.value)){
            //         console.log("edit here featurelist")
            //         const clone = [...featureRemove]
            //         clone.push({uuid: e.target.value})
            //         setfeatureRemove(clone)                 
            //     }               

            // }  

        }
        
    }

    //sort featurelist
    useEffect(() => {
        if(!loading && data_feature && data_feature.get_features){
            let feature = data_feature.get_features.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) 
            setFeatureOption(feature)
        }
    }, [loading])


    return (
        <div className="checkbox-container">
            {
                featureOption && featureOption.length > 0 && featureOption.map((x, i) => {
                    try {
                      return (
                        <div className={`checkbox-input ${!isSKU && staticFeature && staticFeature.includes(x.uuid) && "disable-feature"}`}>
                            <div className="classes">
                                <input type="checkbox" disabled={!isSKU && staticFeature && staticFeature.includes(x.uuid)} className="checkboxes" onChange={(e) => handleChange(e)} value={x.uuid} checked={feature.includes(x.uuid) || staticFeature.includes(x.uuid)}/>
                                <img src={`${x.icon}/icon_${snakeCase(x.name)}_enabled.svg`} alt="test" className="images" style={{ filter: !isSKU && staticFeature && staticFeature.includes(x.uuid) && "grayscale(100%)"}}/>
                                <div className="status">{x.name}</div>
                            </div>
                            <div className="details-status">{x.description}</div>
                        </div>
                      )  
                    } catch (error) {
                        // console.log(error)
                    }
                    return null
            })
        }
                    
            <style jsx>
                {
                    `
                    .disable-feature{
                        // color: #939598;
                    }
                    .disable-feature input{
                        background-color: #939598;
                    }
                    .checkbox-container{
                        margin: 16px 0px 8px 0px;
                        flex-direction: column;
                        display: flex;
                        // align-items: center;
                    }
                    .checkbox-input{
                        display: grid;
                        grid-template-columns: 220px 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". .";
                        align-items: center;
                        width: 100%;
                        margin: 0px 0px 8px 0px;
                    }
                    .classes{
                        display: flex;
                        align-items: center;
                        width: 201px;
                        // margin: 0px 79px 0px 0px;
                    }
                    .checkboxes{
                        margin-right: 16px;
                    }
                    .images{
                        margin-right: 8px;
                    }
                    .status{

                    }
                    .details-status{
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 23px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default FeatureSelect
