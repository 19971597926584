import React, { useEffect, useContext, useState, useRef } from "react";
import GreyExpandMore from '../../Image/grey-expand-more.svg'
import {firebaseContext} from "../../config/config-firebase/FirebaseContext"
import Cookies from "js-cookie";

const Tab = ({ i, fx_click, icon, page, lightIcon, label, active, children, childrenActive, fx_children_click, collapse, setFilter, mainActivate, activePanel}) => {

    const content = useRef(null)
    const cookRoles = Cookies.get("role")
    const [setActive, setActiveState] = useState("active");
    const [setHeight, setHeightState] = useState(`${content.current && content.current.scrollHeight}px`);
    const [setRotate, setRotateState] = useState("accordion__icon_tab");

    // console.log(i)
    const firebaseConfig = useContext(firebaseContext)

    //this function to change the panel based on parent index and child index
    const changeChildPage = (id, parentIdx) => {
        // console.log(parentIdx, id)
        setFilter({})
        fx_click(parentIdx);
        fx_children_click(id, parentIdx) //baru add 13/7/2021

    }
    
    useEffect(() => {
        firebaseConfig.remoteConfig()
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

   //this function is for the arrow accordion, if click
    const accordion = () =>{

        setActiveState(setActive === "active" ? "" : "active");

        setHeightState(
            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
        setRotateState(
            setActive === "active" ? "accordion__icon_tab rotate" : "accordion__icon_tab"
        );
    }

    return (
        <div className="tab-container">

            <div className="menu-btn-placeholder">
                {
                    !collapse && (
                        <button >
                            <span>
                                <div className="icon icon-padding"></div>
                                <div className="text-left">{!collapse && label}</div>                                          
                            </span>
                        </button>
                    )
                }
                {
                  children.length > 0 && (
                        <div className={collapse ?  `collapse-margin ${setRotate}` : `${setRotate}`} onClick={accordion}>
                            <img loading="lazy" src={GreyExpandMore} alt="icon"/>
                        </div>                                            
                    )                                   
                }
            </div>

                <div ref={content}  style={{ maxHeight: `${setHeight}` }} className="children-container">
                        {
                            children.map(({label, icon, lightIcon, role, firebaseKey, parentIdx, path}, id) => {
                                return (role.includes(cookRoles) && firebaseConfig.getValueByKey(firebaseKey) === "true" ) ? (
                                        <div className="children-details" onClick={() => changeChildPage(id, parentIdx)} >
                                            <button key={id} className="child-btn">
                                                <span key={id}>
                                                    <div key={id} className="icon icon-no-padding">{active === parentIdx && childrenActive === id ? lightIcon ? lightIcon : icon : icon && icon }</div>
                                                    <div key={id} className={active === parentIdx && childrenActive === id ? "text-unleft" : "text-left"}>{!collapse && label}</div>                                    
                                                </span>
                                            </button>
                                            {
                                                label === "APAD Report" ? <div className="container-beta">
                                                    <div className="beta-container">Beta</div>
                                                </div> : null
                                            }
                                        </div>


                                ) : null
                            })    
                        }
                </div>
            <style>
                {
                    `
                    button{
                        height: 40px;
                    }
                    .text-left{
                        text-align: left;
                        font-size: 13px;
                        color: #000000                                           
                    }
                    .text-unleft{
                        text-align: left;
                        font-size: 13px;
                        font-weight: 600;
                        color: #509D91;   
                    }
                    .tab-container{
                        color: #000;
                        margin: 0;
                        margin-top: 8px;
                        transition: all ease 200ms;
                    }
                    .children-details:hover{
                        background-color: #DDDDDD;
                    }
                    .children-details{
                        display: flex;
                        align-items: center;
                    }
                    .container-beta{
                        margin: 0px 0px 0px 34px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-sizing: border-box;
                        height: 21px;
                        width: 42px;
                        border: 1px solid #509D91;
                        background-color: #509D91;
                        border-radius: 4px;
                    }
                    .beta-container{
                        color: #FFFFFF;
                        font-size: 11px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 16px;
                        text-align: center;
                    }
                    .accordion__icon_tab{
                        // transition: transform 0.6s ease;
                        margin: 0px 8px 0px 0px;
                    }
                    .rotate {
                        transform: rotate(180deg);
                    }
                    .children-container{
                        display: flex;
                        flex-direction: column;
                        overflow: hidden;
                    }
                    button{
                        min-height: 40px;
                        background-color: transparent;
                        border: 0;
                        margin: 0;
                    }
                    .menu-btn-placeholder{
                        display: flex;
                        text-align: left;
                        align-items: center;
                        // height: 40px;
                       
                    }
                    .mouseHoverEnter{
                        color: red
                    }
                    .mouseHoverLeave{
                        color: black
                    }
                    .collapse-margin{
                        margin: 0px 0px 0px 24px;
                    }
                    .menu-list .menu-btn-placeholder button{
                        flex: 4;
                        // overflow: hidden;
                        position: relative;
                        font-size: 16px;
                        box-sizing: border-box;
                        text-align: center;
                        font-weight: 500;
                        line-height: 1.75;
                        white-space: normal;
                        letter-spacing: 0.02857em;
                        display: inline-flex;
                        outline: 0;
                        padding: 0;
                        align-items: center;
                        user-select: none;
                        border-radius: 0;
                        vertical-align: middle;
                        justify-content: center;
                        text-decoration: none;
                        background-color: transparent;
                        -webkit-appearance: none;
                        -webkit-tap-highlight-color: transparent;
                    }
                    span{
                        font-size: 16px;
                        font-weight: 500;
                        flex-direction: row;
                        // text-transform: capitalize;
                        // justify-content: flex-start;
                        width: 100%;
                        display: inline-flex;
                        align-items: center;
                    }
                    .icon{
                        // margin-bottom: 6px;
                        vertical-align: middle;
                        border-style: none;
                        font-size: 10px;
                    }
                    .icon-padding{
                        margin: 0px 8px 0px 16px;
                    }
                    .icon-no-padding{
                        padding: 0;
                        padding-right: 10px;
                    }
                    .arrow-click {
                        transform: rotate(-180deg);
                    }
                    .menu-list .menu-btn-placeholder .arrow-btn{
                        color: #000;
                        bottom: px;
                        justify-content: flex-end;
                        flex-direction: row;
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 20px;
                        transition: all ease 200ms;
                    }
                    .child-btn{
                        margin: 0px 0px 0px 24px;
                        padding: 0px;
                        outline: none;
                    }
                    .children-tab{
                        display: flex;
                        flex-direction: column;
                        transform-origin: top;
                        transition: all ease 300ms;
                        transform: scaleY(0);
                    }
                    .tab-click {
                        transform: scaleY(1);
                    }
                    .children-btn{
                        min-height: 40px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding-left: 24px;
                        // font-size: 16px;
                        margin-top: 8px;
                    }

                    .active{
                        background: #509D91 !important;
                        // background: #333333 !important;
                        color: #fff !important;
                        font-weight: 500 !important;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default Tab
