import React, {useState, useRef, useContext, useEffect} from 'react'
import { Tooltip } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';
import DeviceMovingIcon from "../../Image/move.svg";
import DeviceIdleIcon from "../../Image/device-idle.svg";
import DeviceStopIcon from "../../Image/stop.svg";
import DeviceTowingIcon from '../../Image/towing.svg';
import DeviceUnknownIcon from "../../Image/default.svg";
import AssetIcon from '../../Image/dark-asset.svg'
import GreyInfoIcon from '../../Image/grey-info.svg'

import DarkLiveIcon from '../../Image/live.svg'

import ExpandMore from '../../Image/expand_more.svg'

import { WebsocketContext } from "../../config/config-websocket/Websocket";
import { firebaseContext } from "../../config/config-firebase/FirebaseContext"
import ImageComponent from '../../component/component-image/ImageComponent'

import Loaders from '../../component/component-loaders/Loaders'


const deviceStatus = { 
    moving: <img loading="lazy" src={DeviceMovingIcon} alt="Moving Device"/>, 
    idle: <img loading="lazy" src={DeviceIdleIcon} alt="Idle Device"/>, 
    towing: <img loading="lazy" src={DeviceTowingIcon} alt="Towing Device" />,
    stop: <img loading="lazy" src={DeviceStopIcon} alt="Stop Device"/> ,
    unknown: <img loading="lazy" src={DeviceUnknownIcon} alt="Unknown Device"/> 
}


const AllAssetListStatus = ({ apiData, apiDataTotalAsset, apiLoading, apiError }) =>  {
    const isDefault = useMediaQuery({ minWidth: 1025 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    const payloadContext = useContext(WebsocketContext);    
    const firebaseConfig = useContext(firebaseContext)
    const { data: websocketPayload, new_payload_id  } = payloadContext;
    const [payloadData, setPayloadData] = useState(null)
    const [dataOption, setDataOption] = useState({ options: []}); //the information to be displayed on the card
    const [totalAsset, setTotalAsset] = useState(0)
    const [totalActiveAsset, setTotalActiveAsset] = useState(0)
    const [callAssetStatsAPI, setCallAssetStatsAPI] = useState(false)
    const seconds = parseInt(firebaseConfig.getValueByKey("total_asset_count_seconds"))

    const movingDevice = (data) => { return data.length > 0 ? data.filter(row => row.motionInference === 200) : []}
    const idleDevice = (data) => {return data.length > 0 ? data.filter(row => row.motionInference === 400 ) : []}
    const towingDevice = (data) => {return data.length > 0 ? data.filter(row => row.motionInference === 500 ) : []}
    const stopDevice = (data) => { return data.length > 0 ? data.filter(row => row.motionInference === 100) : [] }
    const unknownDevice = (data) => {return data.length > 0 ? data.filter(row => (row.motionInference !== 100) && (row.motionInference !== 200) && (row.motionInference !== 400) && (row.motionInference !==  500) ) : []}

    useEffect(() => {
        if (!apiLoading && apiData && Object.keys(apiData).length > 0 && apiData.assets.length > 0) {
            //to do comparison for new websocket
            setPayloadData(apiData.assets);
            //display all the payload
            setDataOption({
                options: apiData.assets.map((x) => x),
            })
            //display total asset 
            setTotalAsset(apiData.totalAssets);
            //display total tactive asset
            setTotalActiveAsset(apiData.totalActiveAssets);
                            //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, [apiLoading])
    

    //newPayload from ws
    useEffect(() => { 
        onNewSubscribeData(new_payload_id) //subscribe new ws to state
        setCallAssetStatsAPI(true) // setCallAssetStatsAPI set to true
        setTimeout(() => {
            callsCountAsset() //call the function (API)
        }, seconds);
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps


    }, [new_payload_id])


    const callsCountAsset = () => { //function call for API
        if(callAssetStatsAPI){ 
            if(!apiLoading && apiDataTotalAsset && Object.keys(apiDataTotalAsset).length > 0){
                setTotalAsset(apiDataTotalAsset.totalAssets) //display total Asset count
                setTotalActiveAsset(apiDataTotalAsset.totalActiveAssets) // display total active asset
                setCallAssetStatsAPI(false)
            }
            setCallAssetStatsAPI(false)
        }
    }


    const onNewSubscribeData = async (id) => {
        if(id && (Array.isArray(payloadData) || payloadData)){
            const clone_data = [...payloadData]
            const new_data = [];
            //if the device is not on the map yet
            if(clone_data.filter(x => x.pairId === websocketPayload.pairId).length < 1){ //if sn not exist in payloadData yet
                clone_data.push(reducer(websocketPayload))
                setDataOption({ options: clone_data.map(x => x) })

                setPayloadData(clone_data); 
            }
            //if the device is already on the map
            else{         
                clone_data && clone_data.map(x => {
                    if(x.pairId === websocketPayload.pairId && websocketPayload.snapshotState !== "removed"){
                        new_data.push(reducer(websocketPayload));
                    } else if(x.pairId !== websocketPayload.pairId){
                        new_data.push(x)
                    }
                })  
                const result = await Promise.all(new_data);
                setDataOption({ options: result.map(x => x) })
                setPayloadData(result);              
            }
        }
    }

    //this is for websocket
    const reducer = (data) => {
        return {
            name: data && data.primaryAsset && data.primaryAsset.assetName,
            pairId: data && data.pairId,
            paired_assets: data && data.secondaryAsset && [{
                 name: data.secondaryAsset.assetName,
                 pairId: data.secondaryAsset.pairId,
            }],
            motionInference: data && data.motionInference,
            uuid: data && data.pairId,
        }
    }

    return (
            <div>
                {
                    isDefault && (
                        <div className="assets-container">
                            {
                                apiLoading ? (<div style={{position:"relative", bottom:"19px"}}><Loaders /></div>) : (
                                    <>
                                    <div className="assets-title">
                                        <div className="total-asset">                              
                                                <div className="text-total-asset">
                                                    <div className="span-text-asset">Total Asset</div>
                                                    <Tooltip title={firebaseConfig.getValueByKey("total_asset")} placement="top" arrow={true}>
                                                        <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px"}} loading="lazy" />
                                                    </Tooltip>
                                                </div>
                                            <div className="total-number">
                                                <ImageComponent src={AssetIcon} alt="aIcon" style={false} onClick={false}/>
                                                <div className="span-text-number">{totalAsset}</div>
                                            </div>
                                        </div>
                                        <div className="total-asset">
                                            <div className="text-total-asset">
                                                <div className="span-text-asset">Active Asset</div>
                                                <Tooltip title={firebaseConfig.getValueByKey("total_active_asset")} placement="top" arrow={true}>
                                                    <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px"}} loading="lazy"/>
                                                </Tooltip>
                                            </div>
                                            <div className="total-number">
                                                <ImageComponent src={DarkLiveIcon} alt="aIcon" style={false} onClick={false}/>
                                                <div className="span-text-number">{totalActiveAsset}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="device-status">
                                                {   
                                                    !apiLoading && dataOption && dataOption.options ? <AssetList label="Moving" status="moving" data={movingDevice(dataOption.options)} icon={deviceStatus.moving} loading={apiLoading}/> : ""
                                                }
                                                {
                                                    !apiLoading && dataOption && dataOption.options ? <AssetList label="Idle" status="idle" data={idleDevice(dataOption.options)} icon={deviceStatus.idle} loading={apiLoading}/> : ""
                                                } 
                                                {
                                                    !apiLoading && dataOption && dataOption.options  ? <AssetList label="In Tow" status="towing" data={towingDevice(dataOption.options)} icon={deviceStatus.towing} loading={apiLoading} /> : ""
                                                }
                                                {
                                                    !apiLoading && dataOption && dataOption.options ?  <AssetList label="Stop" status="stop" data={stopDevice(dataOption.options)} icon={deviceStatus.stop} loading={apiLoading}/> : ""
                                                } 
                                                {
                                                    !apiLoading && dataOption && dataOption.options ?  <AssetList  label="Undetermined" status="Undetermined" data={unknownDevice(dataOption.options)} icon={deviceStatus.unknown} loading={apiLoading}/> : ""
                                                }
                                                
                                    </div> 
                                    </>
                                )
                            }
                        </div>
                    )
                }
                {
                    isMobile && (
                        <div className="assets-container-mobile">
                        {
                            apiLoading ? (<div style={{position:"relative", bottom:"19px"}}><Loaders /></div>) : (
                                <>
                                <div className="assets-title-mobile">
                                    <div className="total-asset">                              
                                            <div className="text-total-asset">
                                                <div className="span-text-asset">Total Asset</div>
                                                <Tooltip title={firebaseConfig.getValueByKey("total_asset")} placement="top" arrow={true}>
                                                    <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px"}} loading="lazy" />
                                                </Tooltip>
                                            </div>
                                        <div className="total-number">
                                            <ImageComponent src={AssetIcon} alt="aIcon" style={false} onClick={false}/>
                                            <div className="span-text-number">{totalAsset}</div>
                                        </div>
                                    </div>
                                    <div className="total-asset">
                                        <div className="text-total-asset">
                                            <div className="span-text-asset">Active Asset</div>
                                            <Tooltip title={firebaseConfig.getValueByKey("total_active_asset")} placement="top" arrow={true}>
                                                <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px"}} loading="lazy"/>
                                            </Tooltip>
                                        </div>
                                        <div className="total-number">
                                            <ImageComponent src={DarkLiveIcon} alt="aIcon" style={false} onClick={false}/>
                                            <div className="span-text-number">{totalActiveAsset}</div>
                                        </div>
                                    </div>
                                </div>
                                </>
                            )
                        }
                    </div>
                    )
                }   
                {
                    isTablet && (
                        <div className="assets-container-mobile">
                        {
                            apiLoading ? (<div style={{position:"relative", bottom:"19px"}}><Loaders /></div>) : (
                                <>
                                <div className="assets-title-mobile">
                                    <div className="total-asset">                              
                                            <div className="text-total-asset">
                                                <div className="span-text-asset">Total Asset</div>
                                                <Tooltip title={firebaseConfig.getValueByKey("total_asset")} placement="top" arrow={true}>
                                                    <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px"}} loading="lazy" />
                                                </Tooltip>
                                            </div>
                                        <div className="total-number">
                                            <ImageComponent src={AssetIcon} alt="aIcon" style={false} onClick={false}/>
                                            <div className="span-text-number">{totalAsset}</div>
                                        </div>
                                    </div>
                                    <div className="total-asset">
                                        <div className="text-total-asset">
                                            <div className="span-text-asset">Active Asset</div>
                                            <Tooltip title={firebaseConfig.getValueByKey("total_active_asset")} placement="top" arrow={true}>
                                                <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px"}} loading="lazy"/>
                                            </Tooltip>
                                        </div>
                                        <div className="total-number">
                                            <ImageComponent src={DarkLiveIcon} alt="aIcon" style={false} onClick={false}/>
                                            <div className="span-text-number">{totalActiveAsset}</div>
                                        </div>
                                    </div>
                                </div>
                                </>
                            )
                        }
                    </div>
                    )
                }

         <style>
             {
                 `
                  .assets-container{
                    background-color: #FFF; 
                    margin: 16px 0px 0px 0px;
                  }
                  .assets-container-mobile{
                    background-color: #FFF; 
                    margin: 8px 0px 0px 0px;
                  }
                  .assets-title{
                    display: grid; 
                    grid-auto-columns: 1fr; 
                    grid-template-columns: 1fr 1fr; 
                    grid-template-rows: 1fr; 
                    gap: 0px 24px; 
                    grid-template-areas: 
                      ". ."; 
                    align-items: center;
                    color: #333333;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 21px;
                    margin: 0px 0px 16px 0px;
                  }
                  .assets-title-mobile{
                    display: grid; 
                    grid-auto-columns: 1fr; 
                    grid-template-columns: 1fr 1fr; 
                    grid-template-rows: 1fr; 
                    gap: 0px 24px; 
                    grid-template-areas: 
                      ". ."; 
                    align-items: center;
                    color: #333333;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 21px;
                    margin: 0px 0px 0px 0px;
                  }
                  .total-asset{
                    background: #FFFFFF;
                    border: 1px solid #DDDDDD;
                    box-sizing: border-box;
                    border-radius: 4px;
                    padding: 8px;
                  }
                  .text-total-asset{
                    display: flex;
                    align-items: center;
                    margin: 0px 0px 4px 0px;
                  }
                  .total-number{
                    display: flex;
                    align-items: center;
                  }
                  .total-active-asset{

                  }
                  .span-text-asset{
                    font-size: 11px;
                    color: #939598;
                    margin: 0px 5px 0px 0px;
                    line-height: 16px;
                  }
                  .span-text-number{
                    font-size: 24px;
                    color: #333333;
                    margin: 0px 0px 0px 8px;
                    line-height: 26px;
                  }
                 `
             }
         </style>
        </div>
    )
}

const AssetList = ({ group, label, icon, status, data, loading }) => {
    const payloadContext = useContext(WebsocketContext);    
    const { payload: selected_asset_info, closeAssetCard, selectAsset  } = payloadContext;
    const labelColor = { moving: "#286551", idle: "#F5A623", stop: "#EA2B1F", towing: "#00abe7", unknown: "#666666" }
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [setRotate, setRotateState] = useState("accordion__icon_group_status");
    const content = useRef(null)

    const onClick = (docs, asset_name) => {

        if(selected_asset_info && selected_asset_info.pairdId === docs.pairId){ //if the box was selected before
            selectAsset({ pairdId:"", assetName: asset_name, getAssetInfo: true }, null)
            closeAssetCard();
        } else{
            selectAsset({pairId :docs.pairId, assetName: asset_name,  getAssetInfo: false}, {asset_name: asset_name, pairId: docs.pairId, paired_asset: docs.paired_assets})
        }     
    }
    const accordion = () =>{
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(
          setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
        setRotateState(
            setActive === "active" ? "accordion__icon_group_status" : "accordion__icon_group_status rotate"
        );
    }

    const checkedCount = (data) => { // paired asset count +1 for status count
        // console.log(data);
        if(data){
            const totalAsset = data.reduce((accumulator, currentValue) => {
                if(currentValue.paired_assets && currentValue.paired_assets.length > 0){
                    return accumulator + 2;
                } else return accumulator + 1;
            }, 0)
    
            return totalAsset  
        }
    }

    
    return (
        (
            <div className="asset-status-containers">
                <div className="asset-icon-label">
                    <div className="asset-device-label-icons-label">
                        { icon }
                        <div className="asset-label" style={{color: labelColor[status]}}>{ label }&nbsp;({checkedCount(data)}) </div>  
                    </div>                       
                    <div className={`${setRotate}`} style={{cursor:"pointer", color:"#939598"}} onClick={accordion}>
                        <img loading="lazy" src={ExpandMore} alt="Icon" style={{margin: "0px"}}/>
                    </div>                 
                </div>
                <div ref={content}  style={{ maxHeight: `${setHeight}` }}  className="device-asset-list">
                    {
                        data && data.map((docs, index) => {
                            // console.log(docs);
                            const asset_pair = docs && docs.paired_assets && docs.paired_assets.length > 0 ? docs.paired_assets.map(x => {return x}) : []
                            const asset_name = docs.name ? docs.name :  ""
                            return (
                                <div className={`device-asset-details`} key={docs.pairId} onClick={(e) => onClick(docs, asset_name)}>
                                    <div className="asset-names">{asset_name}</div>                                
                                    <div className="asset-id-paired-asset">{asset_pair.length > 0 ? 
                                      asset_name !== asset_pair[0].name ? <div className="pair-asset"><div className="paired-symbol">↹</div>{`${asset_pair[0].name}`}</div> : "" 
                                        : ""}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <style jsx="true">
                    {
                        `
                        .asset-dropdown{
                            //float:
                        }
                        .asset-status-containers{
                            margin: 16px 0px 0px 0px;
                            font-size: 16px;
                            line-height: 19px;
                        }
                        .asset-icon-label{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                        }
                        .asset-label{
                            position: relative;
                            top:3px;
                        }
                        .asset-icon-label img{
                            margin-right: 8px;
                        }
                        .device-asset-details{
                            height: 32px;
                            padding: 10px 0px 10px 32px;
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            border-radius: 5px;
                            cursor: pointer;
                            position: relative;
                            // overflow: hidden;
                            // white-space: nowrap;
                            // text-overflow: ellipsis;
                            // left: 25px;
                        }
                        .device-asset-details:hover{
                            background: #CCCCCC;
                        }
                        .accordion__icon_group_status{
                            margin: 0px;
                            // transition: transform 0.6s ease;
                        }
                        .rotate {
                            transform: rotate(180deg);
                            margin: 0px;
                          }
                        .device-asset-list{
                            display: flex;
                            flex-direction: column;
                            overflow : hidden;
                            // overflow-y: scroll;                           
                            transition: max-height 0.6s ease;
                        }
                        .asset-group-label{
                            display: flex;
                            justify-content: space-between;
                        }
                        .asset-device-label-icons-label{
                            display:flex;
                        }
                        .device-asset-details .asset-names{
                            color: #333333;
                            font-size: 13px;
                            line-height: 16px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 175px;
                        }               
                        .device-asset-details .asset-id-paired-asset{
                            position: relative;
                            left: 5px;
                            line-height: 18px;
                            font-size: 12px;
                            margin-right: 10px;
                            overflow: hidden;
                            white-space: nowrap;
                        }

                        .pair-asset{
                            display: flex;
                            align-items: center;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 120px;
                        }
                        .paired-symbol{
                            color: #F5A623;
                            font-size: 14px;
                            margin: 0px 8px 0px 0px;
                        }
                        `
                    }
                </style>
            </div>                                
        )
    )
}

export default AllAssetListStatus
