import React, {useState, useContext, useEffect} from 'react'
import { useHistory } from "react-router-dom";
import sidebarPic from '../../Image/login-left-side.png'
import Logo from '../../Image/horizon_logo_login.svg'
import SnackBar from '../../config/config-snackbar/SnackBar'
import {firebaseContext} from '../../config/config-firebase/FirebaseContext'
import {AuthContext} from '../../config/config-auth/Auth'
import Loaders from '../../component/component-loaders/Loaders'
import {SET_USER_PASSWORD, CHECK_USER_VERIFIED} from '../../config/config-graphql/Graphql'
import CryptoJS from "crypto-js"

import { useMutation, useQuery } from "@apollo/react-hooks";

const NewPassword = ({props}) => {
    const firebaseConfig = useContext(firebaseContext)
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("") 
    const [invalid, setInvalid] = useState(false) 
    const [success, setSuccess] = useState(false)
    const [disabledBtn, setDisabledBtn] = useState(true)
    const [css, setCss] = useState(false)
    const context = useContext(AuthContext);
    const [paramsDecode, setParamsDecode] = useState("")
    let history = useHistory();
    const [values, setValues] = useState({
        confirmPassword: "",
        "encode-confirmPassword": "",
        newPassword: "",
        "encode-newPassword" : "",
        firstName: "",
        lastName: ""
    });

    // const [setUserPassword, { loading: loading, error }] = useMutation(SET_USER_PASSWORD);
    const { data, loading, error } = useQuery(CHECK_USER_VERIFIED, { variables: {passwordId: paramsDecode}});


    const [setUserPassword, { loading: userLoading, error: userError }] = useMutation(SET_USER_PASSWORD);


    useEffect(() => {
        try {
            if (firebaseConfig.firebaseConfig) {
                const url = props.location.pathname
                const params = url.replace("/account_setup/","")
                const tempsecretkey = firebaseConfig.getValueByKey("decode_secret_key") //this is temporary will change to remote config
                const decodeParams = CryptoJS.AES.decrypt(params, tempsecretkey).toString(CryptoJS.enc.Utf8)
                setParamsDecode(decodeParams)
            } 
        } catch (err) {
            console.log('error in firebaseConfig, firebaseConfig', err);
        }
    }, [firebaseConfig])

    useEffect(() => {
        if(values.newPassword === values.confirmPassword && values.firstName && values.lastName && values.newPassword && values.confirmPassword){
            setDisabledBtn(false)
            setCss(true)
        }else{
            setDisabledBtn(true)
            setCss(false)
        }
    }, [values])


    useEffect(() => {
        if(!loading && !data.checkUserVerified){
            if(paramsDecode){

                const timestamp = parseInt(paramsDecode.slice(0, 8), 16) * 1000 + 
                Math.floor(parseInt(paramsDecode.slice(-6), 16) / 16777.217)

                const tenDigitTimestamp = timestamp / 1000 //change to 10digittimestamp
    
                //to check if valid 24 hour
                const remoteTimestamp = 60 * 60 * 24 * 1000  //this is temporary will change to remote config
                
                const day = new Date(tenDigitTimestamp * 1000);
                const curDay = new Date();
                
    
                if(curDay - day <= remoteTimestamp){
                    setInvalid(false)
                }else{
                    setInvalid(true)
                }
            }
            else{
                setInvalid(true)
            }
        }
        if(!loading && data.checkUserVerified){
            setInvalid(true)
        }
    }, [loading])

    const onChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value, [`encode-${event.target.name}`]: context.Encode(event.target.value) });
    };
    const onSubmit = async (e) => {
        try{
            e.preventDefault(); 
            // console.log(values)
                const response = await setUserPassword({ variables: {passwordId: paramsDecode, password: values['encode-newPassword'], firstName:values.firstName, lastName:values.lastName, operation:"SET_PASSWORD"}})
                if(response.data.setUserPassword.success){
                 setSuccess(true)
                }else{
                    setErrOpen(true)
                    setErrMsg(`${response.errors[0].extensions.code} - ${response.errors[0].message}`)
                    setErrSevere("error")
                }


          }catch(err){
            console.log(JSON.stringify(err, null, 2))
            setErrOpen(true)
            setErrMsg(JSON.stringify(err.message, null, 2))
            setErrSevere("error")
          }
    };

    return    loading ? (<Loaders />):(
        <>
            <section className="main">
                <div className="content-w3ls left">
                    <div className="text-pic">
                        <div className="image-placeholder">
                            <img loading="lazy" src={sidebarPic} alt="Traffic" className="img-cut"></img>
                        </div>
                        <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                        <div className="left-content">
                            <div className="text-header">
                                <p>{firebaseConfig.getValueByKey("login_header")} </p>
                            </div>
                            <div className="text-bodies">
                                <span>
                                    {firebaseConfig.getValueByKey("login_detail")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {
                      userLoading ? (
                        <div style={{position: "absolute", left: "60%", top:"40%"}}>
                    <Loaders />
                    </div>
                    ) :                  
                        (
                        <div className="content-w3ls right">
                        {
                            invalid ? (
                            <div className="container">
                                    <div className="container-logo">
                                        <div className="text-center-logo">
                                            {/* <p>{process.env.REACT_APP_ENVIRONTMENT}</p> */}
                                            <span>
                                                <img loading="lazy" src={Logo} alt="Horizon-logo" className="img-logo" />
                                            </span>
                                        </div>
                                        <div className="header-sign-link-expired">
                                            <p>Your account has already verified</p>
                                        </div>
                                        <div>
                                            <form onSubmit={() => history.push("/login")}>
                                            <div className="container-lable-input-f">
                                                    <div className="field-group">
                                                        <div className="text-forgot">
                                                            Please login to horizon.    
                                                        </div>
                                                    </div>
                                                </div>                                
                                                <div className="wthree-fields">
                                                    <button type="submit" className="btn">
                                                        Back to Sign in
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                
                                </div>
                            ) : (
                                success ? (
                                    <div className="container">
                                            <div className="container-logo">
                                                <div className="text-center-logo-confirm">
                                                    <span>
                                                        <img loading="lazy" src={Logo} alt="Horizon-logo" className="img-logo" />
                                                    </span>
                                                </div>
                                                <div className="header-sign">
                                                    <p>Password setup successfully!</p>
                                                </div>
                                                <div>
                                                    <form onSubmit={() => history.push("/login")} method="post">  
                                                        <div className="container-lable-input-f">
                                                            <div className="field-group">
                                                                <div className="text-forgot">
                                                                Congratulations! Your password has been changed successfully. Please click on the link below to return to the sign in page.     
                                                                </div>
                                                            </div>
                                                        </div>                             
                                                        <div className="wthree-fields">
                                                            <button type="submit" className="btn">
                                                                Back to Sign in
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                ) : (
                                    <div className="container">
                                    <div className="container-logo">
                                        <div className="text-center-logo">
                                            {/* <p>{process.env.REACT_APP_ENVIRONTMENT}</p> */}
                                            <span>
                                                <img loading="lazy" src={Logo} alt="Horizon-logo" className="img-logo" />
                                            </span>
                                        </div>
                                        <div className="header-sign">
                                            <p>Account Setup</p>
                                        </div>
                                        <div>
                                            <form onSubmit={(e) => onSubmit(e)} method="post">
                                            <div className="container-lable-input">
                                                    <div className="field-group">
                                                        <div className="firstname-lastname-input">
                                                            <input
                                                                className="input-firstname-lastname-input"
                                                                name="firstName"
                                                                id="myInput"
                                                                type="text"
                                                                value={values.firstName}
                                                                required
                                                                onChange={(e)=> onChange(e)}
                                                                placeholder="First Name"
                                                            ></input>
                                                            <input
                                                                className="input-firstname-lastname-input"
                                                                name="lastName"
                                                                id="myInput"
                                                                type="text"
                                                                value={values.lastName}
                                                                required
                                                                onChange={(e)=> onChange(e)}
                                                                placeholder="Last Name"
                                                            ></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="container-lable-input">
                                                    <div className="field-group">
                                                        <span className="fa fa-user" aria-hidden="true"></span>
                                                        <div className="wthree-field">
                                                            <input
                                                                name="newPassword"
                                                                id="myInput"
                                                                type="Password"
                                                                value={values.newPassword}
                                                                required
                                                                onChange={(e)=> onChange(e)}
                                                                placeholder="New password"
                                                            ></input>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="container-lable-input-input">
                                                    <div className="field-group">
                                                        <span className="fa fa-lock" aria-hidden="true"></span>
                                                        <div className="wthree-field">
                                                            <input
                                                                name="confirmPassword"
                                                                id="myInput"
                                                                type="Password"
                                                                value={values.confirmPassword}
                                                                required
                                                                onChange={(e)=> onChange(e)}
                                                                placeholder="Confirm password"
                                                            ></input>
                                                        </div>
                                                    </div>
                                                </div>                                
                                                {values.confirmPassword !== values.newPassword ?<div className="error-password" >*password does not match</div> : null}
                                                <div className="wthree-fields">
                                                    <button type="submit" className={css ? "btn": "disable-button"} disabled={disabledBtn}>
                                                        Save
                                                    </button>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                
                                </div>
                                )
      
                            )
                        }
    
    
                    <div className="cpr">
                        <span style={{ color: " #939598", fontSize: "14px", fontFamily: "Roboto ,Arial, sans-serif" }}>Copyright © {new Date().getFullYear()} </span> &nbsp;
                        <span style={{ color: "#666666", fontWeight: "bold", fontSize: "14px", fontFamily: "Roboto ,Arial, sans-serif"   }}>
                        Asia Mobility Technologies Sdn Bhd
                        </span>
                        &nbsp;&nbsp;<span style={{ color: " #939598", fontSize: "14px", fontFamily: "Roboto ,Arial, sans-serif"   }}>|</span>
                        &nbsp; &nbsp;
                        <span style={{ color: "#666666", fontWeight: "bold", fontSize: "14px", cursor: "pointer", fontFamily: "Roboto ,Arial, sans-serif"  }} onClick={() => history.push("/privacy_policy")}>
                        {" "}
                        Privacy Policy
                        </span>
                        &nbsp;&nbsp;<span style={{ color: " #939598", fontSize: "14px", fontFamily: "Roboto ,Arial, sans-serif"   }}>|</span>
                        &nbsp;&nbsp;
                        <span style={{ color: "#666666", fontWeight: "bold", fontSize: "14px", cursor:"pointer", fontFamily: "Roboto ,Arial, sans-serif" }} onClick={() => history.push("/terms_of_service")}>
                        Terms of Service
                        </span>
                    </div>
                    </div>
                    )
                }

            </section>
        <style jsx>
            {`
            .main {
                display: flex;
                flex-direction: row;
                height: 100vh;
                overflow: hidden;
                color: black;
            }
            ::placeholder {
                color: #939598;
                // padding: 0px 0px 0px 8px;
              }
              
              :-ms-input-placeholder { /* Internet Explorer 10-11 */
               color: #939598;
            //    padding: 0px 0px 0px 8px;
              }
              
              ::-ms-input-placeholder { /* Microsoft Edge */
               color: #939598;
            //    padding: 0px 0px 0px 8px;
              }
            .content-w3ls .left{
                height: 100%;
                position: relative;
            }
            .checkbox-container{
                display: flex;
                align-items: center;
            }
            .image-placeholder{
                position: relative;
                width: 100%;
                height: 100vh;
                padding-left: 474px;
            }
            .image-placeholder img{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .text-pic {
                display: flex;
                justify-content: center;
                align-items: center;
                // position: relative;
                height: 100%;
            }
            .text-forgot{
                font-size: 13px;
                margin: 0px 0px 16px 0px;
            }
            .left-content{
                // height: 182px;
                // width: 352px;
                position: absolute;
            }
            .firstname-lastname-input{
                display: grid; 
                grid-auto-columns: 1fr; 
                grid-template-columns: 276px 276px; 
                grid-template-rows: 1fr; 
                gap: 0px 17px; 
                grid-template-areas: 
                  ". ."; 
                height: 40px;
            }
            .text-header {
                text-align:center;
                color: #FFFFFF;
                font-size: 19px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 32px;
                margin: 0px 24px 16px 24px;
            }

            .text-bodies {
                text-align: center;
                color: #FFFFFF;
                // height: 57px;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 19px;
                margin: 0px 24px 0px 24px;
            }
            .check-box-container{
                display:flex;
                align-items: center;
            }
            .btn-learn-more{
                 display: flex;
                 justify-content:center;
                 position
                 height: 32px;
                 margin: 17px 0px 0px 0px
            }
            .btn-learn-more .btn-learn{
                text-align: center;
                vertical-align: center;
                width:160px;
                background-color: #FFFFFF;
                margin 0px 0px 0px 0px;
           }
           .learn-more {
                margin: 0px 0px 0px 0px;
                color: #333333;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 31px;
                text-align: center
                
           }
           .container {
                // border-style: solid;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                padding: 0px 0px 0px 0px;
                height: 100%;
                width: 100%;
            }
            .text-center-logo{
                height: 61px;
                width: 210px;
                margin: 0px 0px 11px 0px;
            }
            .container-logo{
                height: 340px;
                width: 100%;
                // border-style: solid;
                margin: 0px 0px 0px 96px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .header-sign  {
                height: 24px
                margin: 0px 0px 0px 0px;
            }
            .header-sign-link-expired{
                height: 24px
                margin: 0px 0px 0px 0px;
                color: #EA2B1F;
            }
            .header-sign p {
                color: #3A3A3A;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 24px;
                margin 0px 0px 23px 0px;
            }
            .container-lable-input{
                width: 600px;
                margin: 0px 0px 22.5px 0px;
                line-height: 5px;
            }
            .container-lable-input-input{
                width: 600px;
                line-height: 5px;
            }
            .txtLabel label{
                height: 16px;
                color: #939598;
                font-size: 12px;
                font-weight: 500;
            }
            .field-group .wthree-field input {
                box-sizing: border-box;
                height: 41px;
                width: 570px;
                border: 1px solid #939598;
                border-radius: 4px;
                background-color: #FFFFFF;
            }
            .checklayout{
                display: flex;
                justify-content: space-between;
                margin: 0px 0px 23px 0px;
            }
            .checklayout .span-me{
                color: #333333;
                font-size: 13px;
                letter-spacing: 0;
                line-height: 19px;
            }
            .checklayout input{
                margin: 0px 8px 0px 0px;
            }

            .wthree-field .btn{
                width: 160px;
                height: 32px;
                background: #333333;
                border: none;
                color: #fff;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 16px;
                text-align: center;
                font-size: 12px;
                cursor: pointer;
            }
            .wthree-fields{
                display: flex;
                justify-content: space-between;
            }
            .wthree-fields .btn{
                height: 32px;
                width: 214px;
                border-radius: 2px;
                background-color: #509D91;
                border: none;
                color: #fff;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 16px;
                text-align: center;
                font-size: 14px;
                margin: 22px 0px 0px 0px;
                cursor: pointer;
            }
            .wthree-fields .disable-button{
                height: 32px;
                width: 214px;
                border-radius: 2px;
                background-color: ##DDDDDD;
                border: none;
                color: #939598;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 16px;
                text-align: center;
                font-size: 14px;
                margin: 22px 0px 0px 0px;
            }
            .wthree-fields .versioning{
                font-size: 12px;
                color: #939598;
                margin: 0px 0px 0px 0px;
                line-height: 40px;
            } 
            .cpr {
                position: absolute;
                bottom: 0;
                right: 0;
                margin: 0px 28px 16px 0px;              
            }
            .input-firstname-lastname-input{
                border: 1px solid #939598;
                border-radius: 4px;
                background-color: #FFFFFF;
            }
            .error-password{
                position: absolute;
                margin: 1px 0px 0px 0px;
                font-size: 12px;
                color: red;
            }
            `}
        </style>
        </>
    )
}

export default NewPassword
