import React,{useState, useEffect} from 'react'
import GreenCopyIcon from '../../Image/green-copy.svg'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const GenerateKey = ({isHovered, displayed, setIsHovered, setGenModal, valBoard, setCellularKey, cellularKey}) => {
    const [toggleGenerate, setToggleGenerate] = useState(true)

    useEffect(() => {
        const cellular = valBoard.filter(x => x.batch_id === "Cellular")
        const privateKey = cellular[0].privateKey ? cellular[0].privateKey : ""
        setCellularKey(privateKey)
    }, [])
    // console.log(valBoard.filter)

    return (
        <div className="device-authentication-container">
        <div className="generate-title-container">
            <p className="generate-title">Device Authentication</p>  
            {
                displayed ? <button className={`${isHovered.generateKey ? "isHovered-generate-button-apply" : "generate-button-apply"}`} onClick={() => setGenModal(true)} onMouseEnter={() => setIsHovered((current) => ({...current, generateKey: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, generateKey: false}))}>Regenerate Key Pair</button> : null
            }     
        </div>
        <div>
            <div className="generate-detail-container">
                <div className="generate-input-title">Private Key Value</div>
                <div className="generate-input-select" >
                    {
                        toggleGenerate ? <div className="text-show-key" onClick={() => setToggleGenerate(false)}>Show Private Key</div> : <div className="text-show-key" onClick={() => setToggleGenerate(true)}>Hide Private Key</div>
                    }
                </div>
            </div>
            <div className="generate-detail-container">
                <div className="generate-input-title"></div>
                <div className="generate-input-select">
                    {
                        !toggleGenerate ? <div className="show-generate-key">
                            {cellularKey ? <div className="private-key-have-value">
                                <label>{JSON.stringify(cellularKey)}</label>
                                </div> : <label className="private-key"></label>
                            }
                           {cellularKey ? <CopyToClipboard text={JSON.stringify(cellularKey)}><div className="generate-copy-container-copy" onMouseEnter={() => setIsHovered((current) => ({...current, copy: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, copy: false}))}>
                               
                                    <img loading="lazy" src={GreenCopyIcon} alt="copy" style={{height:"16px", width:"16px"}}/>
                                    <div className={isHovered.copy ? "isHovered-generate-copy-text" : "generate-copy-text"}>Copy</div>
                               
                           </div></CopyToClipboard> : null} 
                         </div>: null
                    }
                </div>
            </div>
        </div>
        <style>
            {
                `.device-authentication-container{
                    padding: 16px;
                    background-color: #FFFFFF;
                    margin: 0px 0px 24px 0px;
                }
                .generate-title-container{
                    display: flex;
                    justify-content: space-between;
                    margin: 0px 0px 5px 0px;
                }
                .generate-title{
                    margin: 0;
                    color: #333333;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 21px;
                    font-weight: 600;
                }
                .isHovered-generate-button-apply{
                    box-sizing: border-box;
                    height: 33px;
                    width: 161px;
                    border: 1px solid #498f84;
                    border-radius: 2px;
                    color: #498f84;
                    cursor: pointer;
                    font-weight: 600;
                    outline: none;
                    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                }
                .generate-button-apply{
                    box-sizing: border-box;
                    height: 33px;
                    width: 161px;
                    border: 1px solid #509D91;
                    border-radius: 2px;
                    color: #509D91;
                    cursor: pointer;
                    outline: none;
                }
                .generate-detail-container{
                    display: grid; 
                    grid-auto-columns: 1fr; 
                    grid-template-columns: 250px 1fr; 
                    grid-template-rows: 1fr; 
                    gap: 0px 8px; 
                    grid-template-areas: 
                      ". ."; 
                }
                .generate-input-title{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 34px;
                    color: #939598;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                    font-weight: 600;
                }
                .generate-input-select{
                    display: flex;
                    align-items:center;
                    word-break: break-word;
                }
                .text-show-key{
                    color: #509D91;
                    font-weight: bold;
                    font-size: 13px;
                    cursor: pointer;
                    line-height: 24px;
                }
                .private-key{
                    height: 80px;
                    overflow: scroll;
                }
                .private-key-have-value{
                    height: 100px;
                    overflow: scroll;
                }
                .show-generate-key{
                    display: grid; 
                    grid-auto-columns: 1fr; 
                    grid-template-columns: 1fr 55px; 
                    grid-template-rows: 1fr; 
                    gap: 0px 8px; 
                    grid-template-areas: 
                      ". ."; 
                    width: 100%; 
                    height: 100%; 
                }
                .generate-copy-container-copy{
                    display: flex;
                }
                .generate-copy-text{
                    color: #509D91;
                    font-size: 13px;
                    margin: 0px 0px 0px 4px;
                    cursor: pointer;
                }
                .isHovered-generate-copy-text{
                    color: #498f84;
                    font-size: 13px;
                    font-weight: 600;
                    cursor: pointer;
                    margin: 0px 0px 0px 4px;
                }
                `
            }
        </style>
        </div>
    )
}

export default GenerateKey
