import React, {useState} from 'react'
import RedDeleteIcon from '../../Image/red_delete.svg'
import GreyDeleteIcon from '../../Image/grey_delete.svg'
import SuspendIcon from '../../Image/red_pause.svg'
import ReactiveIcon from '../../Image/green_active.svg'

//component
import ModalDelete from './ModalDelete'
import ModalSuspend from './ModalSuspend'


const DeleteCard = ({ onDelete, canDelete, buttonType, active, onSuspend }) => {


const [isHovered, setIsHovered] = useState({
  delete: false,
  suspend: false
})


const [delModal, setDelModal] = useState(false)
const [susModal, setSusModal] = useState(false)

const switchModal = () => {
  setDelModal(!delModal)
}

const susSwithModal = () => {
  setSusModal(!susModal)
}


  return (
          <div className="box-info">
              {/* <div className="title">{title}</div> */}
              {/* <Details />             */}
              <div className="btn-container">
                  {
                    buttonType === "suspended" ? active ?
                    <div className="suspend-container">
                      <div className={`button-suspend-main ${isHovered.suspend ? "isHovered-btn-suspend" : "button-suspend"}`} onClick={e => setSusModal(true)} onMouseEnter={() => setIsHovered((current) => ({...current, suspend: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, suspend: false}))}>
                        <img loading="lazy" src={SuspendIcon} alt="suspend" />
                        <div style={{margin: "0px 0px 0px 14px"}}>Suspend</div>
                      </div>
                  </div> : <div className="suspend-container">
                      <div className={`button-active-main ${isHovered.suspend ? "isHovered-btn-active" : "button-active"}`} onClick={() => onSuspend()} onMouseEnter={() => setIsHovered((current) => ({...current, suspend: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, suspend: false}))}>
                        <img loading="lazy" src={ReactiveIcon} alt="active"/>
                        <div style={{margin: "0px 0px 0px 14px"}}>Activate</div>
                      </div>
                  </div> :  (
                       canDelete && buttonType !== "suspended" ? <div className={`button-delete  ${canDelete ? `${isHovered.delete ? "isHovered-btn-delete" : "delete-active"}` : "delete-disabled"}`}  onClick={() => setDelModal(true)} onMouseEnter={() => setIsHovered((current) => ({...current, delete: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, delete: false}))}>
                         <img loading="lazy" src={RedDeleteIcon} alt="delete"/>
                         <div style={{margin: "0px 0px 0px 14px"}}>Delete</div>
                       </div> :
                      //  <Tooltip title={deleteTooltip} placement="top" arrow={true}>
                         <div disabled={!canDelete} className={`button-delete  ${canDelete ? `${isHovered.delete ? "isHovered-btn-delete" : "delete-active"}` : "delete-disabled"}`} onClick={() => canDelete && setDelModal(true)} onMouseEnter={() => setIsHovered((current) => ({...current, delete: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, delete: false}))}>
                            <img loading="lazy" src={GreyDeleteIcon} alt="deleteDisable"/>
                            <div style={{margin: "0px 0px 0px 14px"}}>Delete</div>
                         </div>
                              
                      //  </Tooltip>                      
                  )
                  }
                    {/* { module === "agencies" ? 
                      ( 
                        // `${isHovered.delete ? "isHovered-btn-delete" : "delete-active"}`
                        // `${isHovered.suspend ? "isHovered-btn-suspend" : button-suspend}`
                        <div className="suspend-container">
                          <div className={`button-delete  ${canDelete ? `${isHovered.delete ? "isHovered-btn-delete" : "delete-active"}` : "delete-disabled"}`} onClick={canDelete && onDelete} onMouseEnter={() => setIsHovered((current) => ({...current, delete: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, delete: false}))}>Delete</div>
                          <div className={`button-delete ${isHovered.suspend ? "isHovered-btn-suspend" : "button-suspend"}`} onClick={e => active ? onSuspend(e, false) : onSuspend(e, true) } onMouseEnter={() => setIsHovered((current) => ({...current, suspend: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, suspend: false}))}>{ active ? "Suspended" : "Reactive" }</div>
                        </div>
                      )
                     : <button disabled={!canDelete} className={`button-delete  ${canDelete ? `${isHovered.delete ? "isHovered-btn-delete" : "delete-active"}` : "delete-disabled"}`} onClick={canDelete && onDelete} onMouseEnter={() => setIsHovered((current) => ({...current, delete: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, delete: false}))}>Delete</button>} */}

                     
                     <ModalDelete open={delModal} fx_close={switchModal} delFunction={onDelete}/>
                     {
                       buttonType === "suspended" && <ModalSuspend open={susModal} fx_close={susSwithModal} delFunction={onSuspend}/>
                     }
                     
                </div>

          <style jsx>
              {
                  `
                  .box-info{
                    }
                    .title{
                      margin: 0px 0px 4px 0px;
                      height: 21px;
                      color: #333333;
                      font-size: 16px;
                      font-weight: 600;
                      letter-spacing: 0;
                      line-height: 21px;
                    }
                    .title-detail{
                      color: #939598;
                      font-size: 12px;
                      letter-spacing: 0;
                      line-height: 16px;
                    }
                    .title-no-detail{
                      color: #939598;
                      font-size: 12px;
                      letter-spacing: 0;
                      line-height: 16px;
                    }
                    .button-delete{
                      height: 32px;
                      width: 213px;
                      font-size: 14px;
                      letter-spacing: 0;
                      line-height: 16px;
                      text-align: center;
                      border-radius: 2px;
                    }
                    .button-suspend-main{
                      height: 32px;
                      width: 213px;
                      font-size: 14px;
                      letter-spacing: 0;
                      line-height: 16px;
                      text-align: center;
                      border-radius: 2px;
                    }
                    .button-active-main{
                      height: 32px;
                      width: 213px;
                      font-size: 14px;
                      letter-spacing: 0;
                      line-height: 16px;
                      text-align: center;
                      border-radius: 2px;
                    }
                    .delete-active{
                      border: 1px solid #EA2B1F;
                      color: #EA2B1F;
                      cursor: pointer;
                      display: flex;
                      align-items:center;
                      justify-content: center;
                    }
                    .isHovered-btn-delete{
                      border: 1px solid #EA2B1F;
                      color: #EA2B1F;
                      font-weight: 600;
                      cursor: pointer;
                      box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                      display: flex;
                      align-items:center;
                      justify-content: center;
                    }
                    .button-suspend{
                      color: #EA2B1F;
                      border: solid 1px #EA2B1F;
                      cursor: pointer;
                      display: flex;
                      align-items:center;
                      justify-content: center;
                    }
                    .button-active{
                      color: #509D91;
                      border: solid 1px #509D91;
                      cursor: pointer;
                      margin: 0px 0px 0px 16px;
                      display: flex;
                      align-items:center;
                      justify-content: center;
                    }
                    .isHovered-btn-suspend{
                      color: #d1261b;
                      border: solid 1px #d1261b;
                      font-weight: 600;
                      cursor: pointer;
                      box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                    .isHovered-btn-active{
                      color: #498f84;
                      border: solid 1px #498f84;
                      font-weight: 600;
                      cursor: pointer;
                      margin: 0px 0px 0px 16px;
                      box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                    .delete-disabled{
                      border: 1px solid #939598;
                      color: #939598;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                    .btn-container{
                      width: 100%;
                    }
                    .suspend-container{
                      display: flex;
                      align-items: center;     
                    }
                  `
              }
          </style>
          </div>
  )
}

export default DeleteCard
