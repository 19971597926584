import React, {useContext, useState} from 'react'
import moment from 'moment'

//component
import DeleteCard from "../../component/component-deletecard/DeleteCard"
import { settingFieldValue, requiredLabel } from "../../config/config-function/function";
import tableConfig from "../../config/config-table/table";
import {firebaseContext} from '../../config/config-firebase/FirebaseContext'


const { firmware:fieldList } = tableConfig.fieldList;

const Form = ({ firmware, onUserInput, displayed, query, fx_back, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere}) => {
    const firebaseConfig = useContext(firebaseContext)
    const isBulkEdit = Array.isArray(firmware) && firmware.length > 1;

    const [isHovered, setIsHovered] = useState({
      version: false,
      SHA: false,
      conflienceLink: false,
      release_date: false,
      board_class: false
    })

    return (
        <div>
            <div className="form-containers">
                <div className="firmware-information">Firmware Information</div>
                <div className="labeling">
                    <div >
                        <div className="detail-label" >
                            <label className="label-placeholder">Version <p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.version.required)}</p></label>
                            <div className={`textbox-container ${isHovered.version && "isHovered-border"}`}>
                                <input type="text" className="text-box" name="version" placeholder="-" value={settingFieldValue(isBulkEdit, firmware, fieldList.version.name)} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, version: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, version: false}))}/>
                            </div>
                        </div>
                        <div className="detail-label" >
                            <label className="calendar-container">Release Date <p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.release_date.required)}</p>   </label>  
                            <div style={{position: 'relative', bottom: '6px',left: '4px'}}>
                                <input type="date" className={`${isHovered.release_date ? "isHovered-date" : "text-box-date"}`} placeholder="-" name="release_date" value={displayed ? moment(firmware[0].release_date).format("YYYY-MM-DD"): (settingFieldValue(isBulkEdit, firmware, fieldList.release_date.name))} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, release_date: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, release_date: false}))}/>
                            </div>                           
                        </div>
                        <div className="detail-label">
                            <label className="label-placeholder">Board Class <p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.board_class.required)}</p></label>
                            <div className="textbox-container">
                                <select className={`${isHovered.board_class ? "isHovered-selection" : "selection-board"}`} name="board_class" value={settingFieldValue(isBulkEdit, firmware, fieldList.board_class.name)} onChange={(e) => onUserInput(e)} disabled={displayed ? firmware && firmware[0].device_count > 0 ? true : false: false} onMouseEnter={() => setIsHovered((current) => ({...current, board_class: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, board_class: false}))}>
                                    <option>-</option>
                                    <option value="Cellular">Cellular</option>
                                    <option value="Sigfox">Sigfox</option>
                                </select>
                            </div>
                        </div> 
                        <div className="detail-label" >
                            <label className="label-placeholder">Git Commit SHA<p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.git_SHA.required)} </p>  </label>
                            <div className={`textbox-container ${isHovered.git_SHA && "isHovered-border"}`}>
                                <input type="text" className="text-box" placeholder="-" name="git_SHA" value={settingFieldValue(isBulkEdit, firmware, fieldList.git_SHA.name)} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, git_SHA: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, git_SHA: false}))}/>
                            </div>

                        </div>
                        <div className="detail-label" >
                            <label className="label-placeholder">Confluence Link <p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.confluence_link.required)} </p>  </label>  
                            <div className={`textbox-container ${isHovered.conflienceLink && "isHovered-border"}`}>
                                <input type="text" className="text-box input-date-box" placeholder="-" name="confluence_link" value={settingFieldValue(isBulkEdit, firmware, fieldList.confluence_link.name)} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, conflienceLink: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, conflienceLink: false}))}/>
                            </div>                 
                        </div>
                    </div>
                    {/* <div>
                        <div className="detail-label">
                            <label className="label-placeholder">Release Notes <p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.release_notes.required)}</p></label>
                            <div className="textbox-container">
                                <input type="text" className="text-box" placeholder="-" name="release_notes" value={settingFieldValue(isBulkEdit, firmware, fieldList.release_notes.name)} onChange={(e) => onUserInput(e)}/>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* {
                displayed ?             
                <div className="second-boxs">
                    <div className="delete-container">
                        <DeleteCard 
                            title="Delete Firmware" 
                            details={firebaseConfig.getValueByKey("admin_firmware_delete")}
                            uuid={firmware[0].uuid} 
                            query={query} 
                            schema_uuid="firmware_uuid" 
                            fx_back={fx_back} 
                            canDelete={firmware[0].device_count > 0 ? false : true}
                            setSnOpen={setSnOpen} 
                            setMessage={setMessage}
                            setSeverity={setSeverity}
                            setErrMsg={setErrMsg} 
                            setErrOpen={setErrOpen} 
                            setErrSevere={setErrSevere}
                        />
                    </div>
                </div> : null
            } */}
            <style jsx>{
              `
              .textfield {
                  // position: relative;
                  // bottom: 40px;
                }
              .details{
                  // position: relative;
                  // bottom: 40px;
              }
              .textbox-container{
                width: 100%;
                display: flex;
              }
              .calendar-container{
                display: flex;
                align-items: center;
                color: #939598;
              }
              .isHovered-selection{
                background-color: rgba(182, 186, 191, 0.2);
                border-radius: 4px;
                border-style:none;
                width: 80px;
              }
              .isHovered-border{
                border: 1px solid #b6babf;
                border-radius: 4px;
              }
              .selection-board{
                  border-style:none;
                  width: 80px;
                  background-color: #FFF;
                  outline: none;
              }
              .second-boxs{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr;
                gap: 0px 0px;
                grid-template-areas: ". .";
              }
              .p-custom-properties{
                color: #939598;
                font-family: Roboto;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 16p
              }
              .title-custom-properties{
                height: 21px;
                color: #333333;
                font-family: Roboto;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 21px;
              }
              .text-box{
                border-style: none;
                height: 24px;
                outline: none;
                border-radius: 4px;
              }
              .text-box-date{
                border-style: none;
                height: 24px;
                width: 200px;
                outline: none;
                position: relative;
                font-family: Helvetica Neue,Helvetica,Arial,sans-serif; 
                font-size: 13ßpx;
                top: 6px;
              }
              .isHovered-date{
                border-style: none;
                height: 24px;
                width: 200px;
                outline: none;
                position: relative;
                font-family: Helvetica Neue,Helvetica,Arial,sans-serif; 
                font-size: 13ßpx;
                top: 6px;
                background-color: rgba(182, 186, 191, 0.2);
                border-radius: 4px;
                border-style:none;
              }
              .labeling{
              }
              .label-placeholder{
                  display: flex;
                  align-items: center;
                  color: #939598;

              }
              .input-date-box{
                  font: roboto;
              }
              .text-id{
                  position: relative;
                  bottom: 5px;
              }
              .autocomplete-id{
                  position: relative;
                  top: 19px;
              }
              .detail-label{
                display: grid;
                grid-template-columns: 150px 1fr;
                grid-template-rows: 1fr;
                gap: 0px 0px;
                grid-template-areas:
                  ". .";
                margin: 0px 0px 8px 0px;
                height: 30px;
              }
              .form-containers{
                padding: 16px 16px 16px 16px; 
                margin: 0px 0px 24px 0px;
                border-radius: 4px;
                background-color: #FFFFFF;

              }
              .title-detail{
                color: #939598;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 16px;
                margin: 0px 0px 8px 0px;
              }
              .firmware-information{
                height: 21px;
                color: #333333;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 21px;
                margin-bottom: 16px;
              }
              .custom-properties{
                padding: 16px 16px 16px 16px;
                height: 137px;
                border-radius: 4px;
                background-color: #FFFFFF;
              }
              .delete-container{
                border-radius: 4px;
                background-color: #FFFFFF;
                padding: 16px;
              
              }
              .MuiInput-underline:before {
                border-bottom: 0px
              }
              .MuiInput-underline:after {
                border-bottom: 0px
              }
              `
            }</style>
        </div>
    );
}

export default Form
