import React, { useEffect, useState } from 'react'

import moment from "moment-timezone";
import Cookies from 'js-cookie';

import CalendarGreyIcon from "../../Image/calendar_grey.svg"
import DeviceGreyIcon from "../../Image/device-grey.svg"
import UserGreyIcon from "../../Image/user_grey.svg"
import ChevronLightIcon from "../../Image/chevron_light.svg"

import { APIGetBeacons } from '../../config/config-restAPI/getAPICalled'

import { filterList } from "../../config/config-filter/filter"
import SnackBar from '../../config/config-snackbar/SnackBar'
import Loaders from "../../component/component-loaders/Loaders"
import Overview from './Overview'
import roleConfig from "../../config/config-role/role";

const PanelBeacon = ({ fx_changePage, filter, setFilter, history }) => {
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');
    const role = Cookies.get('role')
    const [filterFinal, setFilterFinal] = useState({})
    const [dispFilter, setDispFilter] = useState(false)
    const [preFilter, setPreFilter] = useState({})
    const [filterPre, setFilterPre] = useState({});
    const [canUpdate, setCanUpdate] = useState(false) //default false and also this state for btn save mandatory field
    const [edit, setEdit] = useState(false); //default false and also this state is to open the overview details edit form
    const [editValue, setEditValue] = useState() //this state is to store the all the selected value into the state.,
    const [apiLoading, setApiLoading] = useState(false)
    const [pageInd, setPageInd] = useState(false)
    const [beaconData, setBeaconData] = useState([]);

    //this state for error handling on getQuery
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")

    const PreFilter = (value) => {
        const snFilter = { serialNumber: `CONTAINS,${value.toString()}`}
        setPreFilter({ name: { type: "CONTAINS", value: [ value ] } })
        setFilterPre(snFilter)
    }

    const APICalled = async (filterFinal) => {
        try {
            const result = await APIGetBeacons(token, setApiLoading, agencyId, filterFinal)
            if (result.data.status === 200) {
                setBeaconData(result.data.data);
                setApiLoading(false);
            } else {
                setSnOpen(true);
                setMessage("Error: Unable to retrieve data. Please try again later.");
                setSeverity("error") 
                setApiLoading(false);
            }
        } catch(err) {
            setSnOpen(true);
            setMessage("Error: Unable to retrieve data. Please try again later.");
            setSeverity("error");
            setApiLoading(false);
        }
    }

    useEffect(() => {
        if (token) {
            APICalled();
        }
    }, [])

    // enter the filter by key in the words and press "enter"
    const EnterFilter =(e) => {
        if(e.key === "Enter"){
            setFilterFinal(preFilter)
            APICalled(filterPre)
        }
    }

    // this is for FilterClick
    const onFilterClick = (filterVariable) => {      
        // this to do the reducer
        const valueFilter = Object.entries(filterVariable).map((x) => {
            const schema = x[0];
            const values = x[1];
            return {
                [schema]: `${values.type},${values.value.toString()}`
            }
        })
        // this to convert from array to object.
        const changeToObject = valueFilter.reduce((previosObject, currentObject) => {
            return Object.assign(previosObject, currentObject)
        }, {})

        // console.log(changeToObject)
        APICalled(changeToObject);
        setFilterFinal(changeToObject)
        setDispFilter(!dispFilter)
    }

    // console.log('FILTER FINAL', filterFinal);
  
    return apiLoading ? (<Loaders />) : (
        <div>
            <SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/>
            <Overview 
                title={'Beacon'}
                data={beaconData.length > 0 ? beaconData : []} 
                addForm={() => {}} 
                setMainFilter={setFilter} 
                editForm={() => {}} 
                query='' 
                dispFilter={dispFilter}
                setDispFilter={setDispFilter}
                filter={filter}
                filterFinal={filterFinal} 
                setFilterFinal={setFilterFinal} 
                filterList={roleConfig.admin.includes(role) ? filterList.beaconAdmin : filterList.beaconClient} 
                preFilter={preFilter} 
                onFilterClick={onFilterClick}
                fx_PreFilter={PreFilter} 
                EnterFilter={EnterFilter} 
                keyFilter="name" 
                setPreFilter={setPreFilter} 
                setCanUpdate={setCanUpdate} 
                canUpdate={canUpdate} 
                edit={edit} 
                fx_changePage={fx_changePage}
                history={history}
                setEdit={setEdit} 
                setEditValue={setEditValue} 
                editValue={editValue}
                setPageInd={setPageInd}
                APICalled={APICalled}
                isAdmin={roleConfig.admin.includes(role)}
                isDashboard={roleConfig.dashboard.includes(role)}
                role={role}
                roleConfig={roleConfig}
            />
        </div>
    )
}

export default PanelBeacon;
