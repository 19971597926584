import React,{useState, useEffect} from "react";
import { useQuery } from "@apollo/react-hooks";

import { GET_ASSET } from "../../config/config-graphql/Graphql";
import Loaders from "../../component/component-loaders/Loaders"
import Overview from "./Overview"

import { filterList } from "../../config/config-filter/filter"
import SnackBar from '../../config/config-snackbar/SnackBar'

const PanelAsset = ({ filter, size, fx_changePage, setFilter, history, featureDisable }) => { // 👈 the filter props is for the filter that comes from other pages (view all)
    //data - Asset data from neo4j
    const [filterFinal, setFilterFinal] = useState({})
    const [preFilter, setPreFilter] = useState({})
    const [canUpdate, setCanUpdate] = useState(false) //default false
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")

    const [edit, setEdit] = useState(false);
    const [editValue, setEditValue] = useState()
    const [pageInd, setPageInd] = useState(false)
    
    const PreFilter = (value) => {
        setPreFilter({ name: { type: "CONTAINS", value: [ value ] } })
    }

    const EnterFilter =(e) => {
        if(e.key === "Enter"){
            setFilterFinal(preFilter)
        }
    }

    const { data, loading, error } = useQuery(GET_ASSET, { variables: { filter: Object.keys(filterFinal).length !== 0 ? filterFinal : filter }});

    useEffect(() => {
        if(!loading && data && data.get_assets){
            if(pageInd){
                setEdit(!edit)       
                setEditValue(data.get_assets.assets)
            }
        }
        
    }, [loading])

    //object keys filter for get_agencies
    useEffect(() => {
        if(Object.keys(filter).length > 0){
            if(!loading){
                setEditValue(data.get_assets.assets)
                setEdit(!edit)     
            }
        }
    }, [loading])


    useEffect(() => {
        if(error){
            setSnOpen(true)
            setMessage("Error: Unable to retrieve data. Please try again later.")
            setSeverity("error")
        }
    }, [error])
    // console.log("INDEX", edit)

    return loading ? <Loaders /> : error ? (
        <SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/>
    ) : (
        // data.get_assets.features.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        <Overview title="Asset"  data={data.get_assets ? data.get_assets.assets : []} featureList={data.get_assets !== null ? data.get_assets.features.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) : []} fx_changePage={fx_changePage} setLiveFilter={setFilter} setFilterFinal={setFilterFinal} filterFinal={filterFinal} filterList={filterList.asset} preFilter={preFilter} fx_PreFilter={PreFilter} EnterFilter={EnterFilter} setPreFilter={setPreFilter} setCanUpdate={setCanUpdate} canUpdate={canUpdate} edit={edit} setEdit={setEdit} setEditValue={setEditValue} editValue={editValue} pageInd={pageInd} setPageInd={setPageInd} history={history} featureDisable={featureDisable}/>
    )
};

export default PanelAsset;