import React, {useState, useEffect} from 'react'
import Switch from 'react-switch'
import FuelIcons from '../../Image/fuel_tank_new.svg'
import tableConfig from '../../config/config-table/table'
import GreyInfoIcon from '../../Image/grey-info.svg'
import moment from "moment-timezone";
import { Tooltip } from '@material-ui/core';


const {messagePayloadInterval, payloadInterval, radioAccessTechnology, operationMode} = tableConfig.cellularConfiguration;
const IOTConfiguration = ({setIsHovered, isHovered, displayed, setCellularConf, cellularConf, valfuel, handleChangeFuel, device, cellularApply, displayTeltonika}) => {
    const [boardConfig, setBoardConfig] = useState(null)
    const tz = moment.tz.guess();
    moment.tz.setDefault(tz);
    
    //this for set state for config
    useEffect(() => {
        const boardConfig = device[0].board.map(x => x.config)
        setBoardConfig(boardConfig)

    }, [device[0].board])

    //handle change for change selection
    const handleChangeSelection = (name, value) => {
            setCellularConf({...cellularConf, [name]:value})
    }

    //handle change for vibrarion sensor
    const handleVibrationSensor = nextChecked => {   
        setCellularConf({...cellularConf, extSense_Vibration:nextChecked})
    }

    //handlechange for fuel sensor
    const handleChangeFuelSensor = nextChecked => {
        setCellularConf({...cellularConf, extSense_Fuel:nextChecked})
    }

    return (
        <div className={displayTeltonika ? "cellular-container" : "teltonika-container"}>
        <div className="cellular-title-container">
            <p className="cellular-title">Device Configuration</p>
            {
                displayed ? displayTeltonika ? <button className={`${isHovered.apply ? "isHovered-cellular-button-apply" : "cellular-button-apply"}`} onClick={(e) => cellularApply(e)} onMouseEnter={() => setIsHovered((current) => ({...current, apply: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, apply: false}))}>Apply</button> : null : null
            }       
        </div>
        <div className="cellular-detail-container">
            <div>
              
              {
                  displayTeltonika && (
                    <div>
                    <div className="cellular-input-title">Power Management</div>
                    <div className="cellular-input-select">
                        <div className="cellular-lable">Operation Mode 
                        <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" />
                        </div>
                        <select className={`${isHovered.operationMode ? "isHovered-cellular-selection" : "cellular-selection"}`}  name="ignitionMode" value={cellularConf.ignitionMode} onChange={e => handleChangeSelection(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, operationMode: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, operationMode: false}))}>
                            {
                                operationMode.map(x => {
                                    return(
                                        <option value={x.value}>{x.text}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                  )
              }  
               { displayTeltonika && <div className="divider-container"></div> }
               {
                   displayTeltonika && (
                    <div>
                    <div className="cellular-input-title">Messaging </div>
                    <div className="cellular-input-select">
                        <div className="cellular-lable">Messaging Interval <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                        <select className={`${isHovered.msgInterval ? "isHovered-cellular-selection" : "cellular-selection"}`}  name="payloadInterval" value={cellularConf.payloadInterval} onChange={e => handleChangeSelection(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, msgInterval: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, msgInterval: false}))}>
                            {
                                messagePayloadInterval.map(x => {
                                    return (
                                        <option value={x.value}>{x.text}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="cellular-input-select">
                        <div className="cellular-lable">Messaging Interval (Ignition Off) <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                        <select className={`${isHovered.msgIntervalOff ? "isHovered-cellular-selection" : "cellular-selection"}`}  name="payloadIntervalIgnitionOff" value={cellularConf.payloadIntervalIgnitionOff} onChange={e => handleChangeSelection(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, msgIntervalOff: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, msgIntervalOff: false}))}>
                        {
                                messagePayloadInterval.map(x => {
                                    return (
                                        <option value={x.value}>{x.text}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="cellular-input-select">
                        <div className="cellular-lable">Offline Message Logging <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                        <select className={`${isHovered.offlineMsgLog ? "isHovered-cellular-selection" : "cellular-selection"}`}  name="payloadIntervalOffline" value={cellularConf.payloadIntervalOffline} onChange={e => handleChangeSelection(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, offlineMsgLog: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, offlineMsgLog: false}))}>
                            {
                                messagePayloadInterval.map(x => {
                                    return (
                                        <option value={x.value}>{x.text}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
                   )
               }
               {displayTeltonika && <div className="divider-container"></div>} 
                {
                    displayTeltonika && (
                        <div>
                        <div className="cellular-input-title">Connectivity </div>
                        <div className="cellular-input-select">
                            <div className="cellular-lable">Cellular Module Keep Alive <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                            <select className={`${isHovered.cellModuleAlive ? "isHovered-cellular-selection" : "cellular-selection"}`}  name="telitKeepAlive" value={cellularConf.telitKeepAlive} onChange={e => handleChangeSelection(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, cellModuleAlive: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, cellModuleAlive: false}))}>
                                {
                                    payloadInterval.map(x => {
                                        return(
                                            <option value={x.value}>{x.text}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="cellular-input-select">
                            <div className="cellular-lable">Radio Access Technology <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                            <select className={`${isHovered.radioAccess ? "isHovered-cellular-selection" : "cellular-selection"}`}  name="rat" value={cellularConf.rat} onChange={e => handleChangeSelection(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, radioAccess: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, radioAccess: false}))}>
                                {
                                    radioAccessTechnology.map(x => {
                                        return(
                                            <option value={x.value}>{x.text}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    )
                }
                { displayTeltonika && <div className="divider-container"></div> }
                <div className="fuel-sensor-container-with-image">
                    <div>
                        {
                            displayTeltonika && (
                            <div>
                                <div className="cellular-input-title">Fuel Sensor Integration</div>
                                <div className="cellular-input-select">
                                    <div className="cellular-lable">Fuel Sensor <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                                    <Switch onChange={handleChangeFuelSensor} checked={cellularConf.extSense_Fuel}  onColor="#509D91" onHandleColor="#509D91" uncheckedIcon={false} checkedIcon={false} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={35} className="react-switch" id="material-switch"/>
                                </div>
                                {cellularConf.extSense_Fuel ?<div className="cellular-input-select">
                                    <div className="cellular-lable">Fuel Density Ratio <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                                    <input type="number" step="any" className={`${isHovered.fDensityRatio ? "isHovered-border-textbox" : "cellular-text-box"}`} placeholder="-" value={cellularConf.FuelSensingRatio} name="FuelSensingRatio"  onChange={(e) => handleChangeSelection(e.target.name, e.target.value)}  onMouseEnter={() => setIsHovered((current) => ({...current, fDensityRatio: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, fDensityRatio: false}))}/>
                                </div> : null}
                            </div>
                            )
                        }
                        {
                            cellularConf.extSense_Fuel || !displayTeltonika ? <div>
                            <div className="cellular-input-title">Fuel Tank Calibration</div>
                            <div className="cellular-input-select">
                                <div className="cellular-lable">Length (L, m) <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                                <input type="number" className={`${isHovered.length ? "isHovered-border-textbox" : "cellular-text-box"}`} placeholder="-" name="length" value={valfuel.length}  onChange={(e) => handleChangeFuel(e.target.name, parseFloat(e.target.value))}  onMouseEnter={() => setIsHovered((current) => ({...current, length: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, length: false}))}/>
                            </div>
                            <div className="cellular-input-select">
                                <div className="cellular-lable">Width (W, m) <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                                <input type="number" className={`${isHovered.width ? "isHovered-border-textbox" : "cellular-text-box"}`} placeholder="-" name="width" value={valfuel.width}  onChange={(e) => handleChangeFuel(e.target.name, parseFloat(e.target.value))}  onMouseEnter={() => setIsHovered((current) => ({...current, width: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, width: false}))}/>
                            </div>
                            <div className="cellular-input-select">
                                <div className="cellular-lable">Height (H, m) <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                                <input ttype="number" className={`${isHovered.height ? "isHovered-border-textbox" : "cellular-text-box"}`} placeholder="-" name="height" value={valfuel.height}  onChange={(e) => handleChangeFuel(e.target.name, parseFloat(e.target.value))}  onMouseEnter={() => setIsHovered((current) => ({...current, height: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, height: false}))}/>
                            </div>
                        </div> : null
                        }

                    </div>
                    {
                        cellularConf.extSense_Fuel || !displayTeltonika ? 
                        <div className="image-fuel-sensor">
                            <img loading="lazy" src={FuelIcons} alt="fuel" style={{height:"152px", width:"152px"}}/>
                            <div className="text-icon-volumn-one">
                                <div className="text-key-in">Volumn(㎥):</div>
                                <label className="label-text">
                                    {
                                    valfuel.length && valfuel.width && valfuel.height ? parseFloat(valfuel.length * valfuel.width * valfuel.height).toFixed(4) : "-"
                                    }
                                </label>
                            </div>
                            <div className="text-icon-volumn-two">
                                <div className="text-key-in">Volumn(l):</div>
                                <label className="label-text">
                                    {
                                    valfuel.length && valfuel.width && valfuel.height ?  (parseFloat(valfuel.length * valfuel.width * valfuel.height) * 1000).toFixed(2) : "-"
                                    }
                                </label>
                            </div>
                        </div> 
                        : null
                    }

                </div>

                { displayTeltonika && <div className="divider-container"></div>}
                {
                    displayTeltonika && (
                    <div>
                        <div className="cellular-input-title">Misc External Sensor Integration</div>
                        <div className="cellular-input-select">
                            <div className="cellular-lable">Vibration Sensor <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                            <Switch onChange={handleVibrationSensor} checked={cellularConf.extSense_Vibration}  onColor="#509D91" onHandleColor="#509D91" uncheckedIcon={false} checkedIcon={false} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={35} className="react-switch" id="material-switch"/>
                        </div>
                    </div>
                    )
                }
                {
                    displayTeltonika && <div className="divider-container"></div>
                }
                {
                    displayTeltonika && (
                        <div>
                        <div className="cellular-input-title">Latest Activity</div>
                        <div className="cellular-input-select">
                            <div className="cellular-lable">Heartbeat (MQTT only) <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                            <label className="value-label-cellular">{boardConfig ? boardConfig[0].lastHeartbeatTime ? moment(`${boardConfig[0].lastHeartbeatTime }`).tz(tz).set().format('DD/MM/YYYY hh:mm:ss a'): "-" : "-"}</label>
                        </div>
                        <div className="cellular-input-select">
                            <div className="cellular-lable">Telemetry event received <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                            <label className="value-label-cellular">{boardConfig ? boardConfig[0].lastEventTime ? moment(`${boardConfig[0].lastEventTime }`).tz(tz).set().format('DD/MM/YYYY hh:mm:ss a') : "-" : "-"}</label>
                        </div>
                        <div className="cellular-input-select">
                            <div className="cellular-lable">Device state event received <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                            <label className="value-label-cellular">{boardConfig ? boardConfig[0].lastStateTime ? moment(`${boardConfig[0].lastStateTime }`).tz(tz).set().format('DD/MM/YYYY hh:mm:ss a') : "-" : "-"}</label>
                        </div>
                        <div className="cellular-input-select">
                            <div className="cellular-lable">Zone Config ACK (MQTT only) <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                            <label className="value-label-cellular">{boardConfig ? boardConfig[0].lastConfigAckTime ? moment(`${boardConfig[0].lastConfigAckTime }`).tz(tz).set().format('DD/MM/YYYY hh:mm:ss a') : "-" : "-"}</label>
                        </div>
                        <div className="cellular-input-select">
                            <div className="cellular-lable">Config sent <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                            <label className="value-label-cellular">{boardConfig ? boardConfig[0].lastConfigSendTime ? moment(`${boardConfig[0].lastConfigSendTime }`).tz(tz).set().format('DD/MM/YYYY hh:mm:ss a') : "-" : "-"}</label>
                        </div>
                        <div className="cellular-input-select">
                            <div className="cellular-lable">Error <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                            <label className="value-label-cellular">{boardConfig ? boardConfig[0].lastErrorTime ? moment(`${boardConfig[0].lastErrorTime }`).tz(tz).set().format('DD/MM/YYYY hh:mm:ss a') : "-" : "-"}</label>
                        </div>
                        <div className="cellular-input-select">
                            <div className="cellular-lable">Error Status and Message <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px", margin: "0px 0px 0px 5px"}} loading="lazy" /></div>
                            <label className="value-label-cellular">{boardConfig ? boardConfig[0].lastErrorStatus ? boardConfig[0].lastErrorStatus : "-" : "-"}</label>
                        </div>
                    </div>
                    )
                }
            </div>
        </div>
            <style>
            {
                `.cellular-container{
                    padding: 16px;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    margin: 58px 0px 24px 0px;
                }
                .teltonika-container{
                    padding: 16px;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    margin: 0px 0px 24px 0px;
                }
                .cellular-title-container{
                    display: flex;
                    justify-content: space-between;
                    margin: 0px 0px 5px 0px;
                }
                .cellular-title{
                    margin: 0;
                    color: #333333;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 21px;
                    font-weight: 600;
                }
                .cellular-button-apply{
                    box-sizing: border-box;
                    height: 32px;
                    width: 213px;
                    border: 1px solid #509D91;
                    border-radius: 2px;
                    color: #509D91;
                    cursor: pointer;
                    outline: none;
                }
                .isHovered-cellular-button-apply{
                    box-sizing: border-box;
                    height: 32px;
                    width: 213px;
                    border: 1px solid #498f84;
                    border-radius: 2px;
                    color: #498f84;
                    cursor: pointer;
                    font-weight: 600;
                    outline: none;
                    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                }
                .cellular-detail-container{
                    margin: 0px 0px 8px 0px;
                }
                .cellular-input-select{
                    display: grid;
                    grid-template-columns: 200px 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    align-items: center;
                    margin: 0px 0px 8px 0px;
                }
                .cellular-input-title{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 34px;
                    color: #939598;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                    font-weight: 600;
                }
                .sigfox-description{
                    margin: 8px 0px 0px 8px;
                    color: #939598;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                }
                .sigfox-configuration-container{
                    display: flex;
                    align-items: center;
                }
                .cellular-selection{
                    width: 220px;
                    height: 24px;
                    border-style:none;
                    background-color: #FFF;
                    border-radius: 4px;
                    outline: none;
                }
                .isHovered-cellular-selection{
                    width: 220px;
                    height: 24px;
                    border-style:none;
                    background-color: #FFF;
                    border-radius: 4px;
                    outline: none;
                    background-color: rgba(182, 186, 191, 0.2);
                }
                .cellular-lable{
                    display: flex;
                    align-items: center;
                    font-weight: normal;
                    font-size: 14px;
                    height: 25px;
                    color: #939598;
                }
                .divider-container{
                    border: 1px solid #DDDDDD;
                    margin: 8px 0px 8px 0px;
                }
                .fuel-sensor-container-with-image{
                    display: grid; 
                    grid-auto-columns: 1fr; 
                    grid-template-columns: 1fr 1fr; 
                    grid-template-rows: 1fr; 
                    gap: 0px 8px; 
                    grid-template-areas: 
                      ". ."; 
                }
                .image-fuel-sensor{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items:center;
                }
                .cellular-text-box{
                    display: flex;
                    width:100%;
                    align-items: center;
                    border-style: none;
                    height: 25px;
                    border-radius: 4px;
                    outline: none;
                }
                .isHovered-border-textbox{
                    height:24px;
                    width:100%;
                    outline: none;
                    border: 1px solid #b6babf;
                    border-radius: 4px;
                }
                .value-label-cellular{
                    font-size: 13px;
                    line-height: 24px;
                    color: #939598;
                }
                .text-icon-volumn-two{
                    display: grid;
                    grid-template-columns: 1fr 1fr; 
                    grid-template-rows: 1fr; 
                    gap: 0px 0px; 
                    grid-template-areas: 
                      ". ."; 
                }
                `
            }
        </style>
        </div>
    )
}

export default IOTConfiguration
