import React from 'react'

//library
import BaseTable, { AutoResizer } from 'react-base-table'
import 'react-base-table/styles.css'
import styled from 'styled-components'
import ReactTexty from 'react-texty'


const Container = styled.div`
    width: 100%; 
    height: 35vh;  
`

const vehicle_reports = [
    { key: "date", title: "Date", dataKey: "date", width: 150, flexGrow: 1, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> },
    { key: "value", title: "Average Speed (km/h)", dataKey: "value", width: 150, flexGrow: 1, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> },
    { key: "latitude", title: "Latitude", dataKey: "latitude", width: 150, flexGrow: 1, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> },
    { key: "longitude", title: "Longitude", dataKey: "longitude", width: 150, flexGrow: 1, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> }
]

const ReportTable = ({data}) => {
    return (
        <div className="asset-report-table-container">
            <div className="white-box">
                <Container>
                    <AutoResizer>
                        {({ width, height }) => (
                            <BaseTable columns={vehicle_reports} width={width} height={height} data={data} />
                        )}
                    </AutoResizer>
                </Container>
            </div>
            <style>
                {
                    `
                    .vehicle-report-table-container{
                        
                    }
                    `
                }
            </style>
        </div>
    )
}

export default ReportTable
