import React, { useState, useContext, useEffect } from 'react'

//library
import { useQuery } from '@apollo/react-hooks';
import Cookies from 'js-cookie';

//list of material ui
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';
import grey from "@material-ui/core/colors/grey";
import { IconButton, InputAdornment } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import moment from "moment-timezone";
import DateFnsUtils from '@date-io/date-fns';

//list of images
import AssetIcon from '../../Image/asset.svg'
import CalendarIcon from '../../Image/calendar_dark.svg'
import GreySearchIcon from '../../Image/grey_search.svg'

//list of components
import Table from './Table'
import { SEARCH_ASSET_N_GROUPS, GET_REPORT_TELEMETRY } from '../../config/config-graphql/Graphql'
import { APIGetSearchAssetnAssetGroup } from '../../config/config-restAPI/getAPICalled';
import Loaders from '../../component/component-loaders/Loaders'
import { firebaseContext } from "../../config/config-firebase/FirebaseContext"
import { isEmptyObject } from '../../config/config-function/function';

const materialTheme = createMuiTheme({

    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: grey.A400,
            },
        },
        MuiPickerDTTabs: {
            tabs: {
                backgroundColor: grey.A400,
            }
        },
        MuiPickersDay: {
            day: {
                color: grey.A400,
            },
            daySelected: {
                backgroundColor: grey["400"],
            },
            dayDisabled: {
                color: grey["100"],
            },
            current: {
                color: grey["900"],
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: grey["400"],
            },
        },
        MuiPickersClock: {
            pin: {
                backgroundColor: grey["400"],
            }
        },
        MuiPickersClockPointer: {
            pointer: {
                backgroundColor: grey["400"],
            }
        },
    }
});

const autoComplete = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            clearIndicator: {
                // position: "absolute",
                // bottom: "5px",
                // right: "25px"
                "&:hover":{
                    backgroundColor: "#FFFFFF",
                }
            },
            endAdornment:{
                top: "0px",
                height: "100%",
                display: "flex",
                alignItems: "center"
            },
            popupIndicator:{
                "&:hover":{
                    backgroundColor: "#FFFFFF",
                }
            } 
        },
        MuiIconButton:{
            label:{
                height:"24px",
                width: "24px",
                "&:hover":{
                    backgroundColor: "rgba(0,0,0,0.03)",
                    borderRadius: "5px",
                },
            }
        }
    }
});

const useStyles = makeStyles((theme) => ({
    textField: {
        backgroundColor: "#FFFFFF",
        width: 240,
        marginLeft: 0,
    },
    autocomplete: {
        backgroundColor: "#FFFFFF",
        width: 276
    }
}))

const PanelReportTelemetry = ({filter}) => {
    // moment.tz.setDefault("Asia/Kuala_Lumpur")
    // const browserUtcOffset = Math.abs(new Date().getTimezoneOffset() / 60);
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');
    const tz = moment.tz.guess();
    moment.tz.setDefault(tz);
    const classes = useStyles()
    const firebaseConfig = useContext(firebaseContext)
    const [searchValue, setSearchValue] = useState({})
    const [tableView, seTableView] = useState(false)
    const [dataset, setDataset] = useState({
        fromTime: moment()
    })

    const [filterDate] = useState({
        fromTime:  moment().tz(tz).format(),
    })
    const [isHovered, setIsHovered] = useState({
        btnReport: false
    })
    const [assetFlat, setAssetFlat] = useState([]); // list search for desktop (searchAssetNGroup)
    const [assetListSnip, setAssetListSnip] = useState([]); // list search for mobile (searchAssetNGroup)
    const [loading, setLoading] = useState(false);
    const [speed, setSpeed] = useState({})
    const { data: reportData, loading: reportLoading} = useQuery(GET_REPORT_TELEMETRY, { variables: { input: { fromTime: speed.fromTime ? speed.fromTime : "", toTime: speed.toTime ? speed.toTime : "", asset_uuid: typeof speed.value !== "undefined" ? speed.value.uuid : [] } } })
    const { data: searchData, loading: searchLoading, error: searchError } = useQuery(SEARCH_ASSET_N_GROUPS, { variables: { search_key: "", searchType: "ASSET" } })

    // console.log(reportData);

    const APICalled = async (searchType, searchKey) => {
        try {
            const result = await APIGetSearchAssetnAssetGroup(token, setLoading, agencyId, searchType, searchKey, false)
            if (result.data.status === 200) {
                const assetList = result.data.data.assets.length > 0 ? result.data.data.assets.map((x) => {
                    return x && x.name ? {
                        firstLetter: x.name.charAt(0).toUpperCase(),
                        pairId: x.pairId,
                        uuid: x.uuid,
                        name: x.name,
                        paired_assets: x && x.pairedAssets.length > 0 ? x.pairedAssets.map((x) => { return { uuid: x.uuid, name: x.name }}) : [],
                        type: 'asset',
                    } : { firstLetter: "", uuid: "", name: "", pairId: "", paired_assets: [], type: "asset" }
                }) : []
                setAssetFlat(assetList);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch(err) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(token){
            APICalled("ASSET", '')
        }
    }, [])


    //shortcut button
    useEffect(() => {
        if(Object.keys(filter).length > 0){
            setSearchValue({
                asset_uuid: {name: filter.asset_name, paired_assets:filter.paired_assets}
            })
            setSpeed({
                fromTime:  moment(filterDate.fromTime).tz(tz).set({ hour: 0, minute: 0, second: 0 }).format(),
                toTime: moment(filterDate.fromTime).tz(tz).set({ hour: 23, minute: 59, second: 59 }).format(),
                value: filter.uuid
            })
        }else{
            // console.log("go here")
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    // console.log(speed)
    // console.log(searchValue)

 
    const searchOptions = {
        options: !searchLoading && searchData && searchData.searchAssetnGroups && searchData.searchAssetnGroups.asset ? searchData.searchAssetnGroups.asset.map((asset) => { return asset && asset.name ? { uuid: asset.uuid, name: asset.name, paired_assets: asset && asset.paired && asset.paired_assets.length > 0 ? asset.paired_assets.map(x => { return { uuid: x.uuid, name: x.name } }) : [] } : { uuid: "", name: "", blobID: "", paired_assets: [] } }) : []
    };

    //map the asset Ids
    const mappedAssetIds = searchOptions.options.map(x => {
        return [{ uuid: x.uuid, name: x.name, firstLetter: x.firstLetter}, ...x.paired_assets]
    })

    //flat the search options
    const flatAssetIds = {options: mappedAssetIds.flat(2)}

    //flatAssetOptions -- this fixed the nested array object
    const flatAssetOptions = flatAssetIds.options.map((x) => {
        return { uuid: x.uuid, name: x.name, firstLetter: x.name.charAt(0).toUpperCase() }
    })



    const onUserInput = (e, value) => {
        let input = null
        if (value) {
            onChange(e, { value })
            input = { ...dataset, value }
        } else {
            onChange(e)
            input = { ...dataset, ...e }
        }

        setSearchValue({
            fromTime: moment(`${input.fromTime}`).tz(tz).set({ hour: 0, minute: 0, second: 0 }).format(),
            toTime: moment(`${input.fromTime}`).tz(tz).set({ hour: 23, minute: 59, second: 59 }).format(), // Single day search only
            asset_uuid: input.value
        })

    }

    const onChange = (e, value) => {
        value ? setDataset({ ...dataset, ...value }) :
            setDataset({ ...dataset, ...e })
    };
    


    //when click the search button
    const onSearch = () => {
        setSpeed({
            fromTime: searchValue.fromTime,
            toTime: searchValue.toTime,
            value: searchValue.asset_uuid
        })
        seTableView(!tableView)
    }

    const clearIndicatorMUI = () => {
        // setSearchValue({})
        // setDataset({asset_uuid: ""})
    }

    return reportLoading ? ((<div style={{ position: "relative", right: "2px", top: "40px" }}><Loaders /></div>)) : tableView ? <Table searchValue={searchValue} fx_back={() => seTableView(!tableView)} reportData={reportData} reportLoading={reportLoading} searchError={searchError} /> : (
        <div className="r-telemetry-container">
            <div>
                <div className="panel-title">Telemetry Log</div>
                <div className="panel-description">{firebaseConfig.getValueByKey("Report_Telemetry_Description")}</div>
            </div>
            <div className="input-container">
                <div className="asset-input-container">
                    <div>
                        <div className="icon-title">
                            <img loading="lazy" src={AssetIcon} alt="asset-icon" />
                            <div className="asset-title">Asset <p style={{color:"red", margin:"0px 0px 0px 2px"}}>*</p></div>
                        </div>

                        <div className="asset-details">{firebaseConfig.getValueByKey("report_telemetry_asset_decription")}</div>
                        <div className="asset-name-select">
                            <div className="asset-name">Asset Name</div>
                            <div className="asset-selection">
                                <ThemeProvider theme={autoComplete}>
                                    <Autocomplete
                                        {...assetFlat}
                                        name="asset_name"
                                        className={classes.autocomplete}
                                        id="combo-box-demo"
                                        loading={true}
                                        value={dataset.value}
                                        onChange={(e, value) => onUserInput(e, value)}
                                        options={assetFlat.length > 0 ? assetFlat.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
                                        groupBy={(option) => option.firstLetter}  
                                        getOptionLabel={(option) => option.name ? option.name : ""}
                                        renderOption={option => {
                                            return <div className="autocomplete-container">
                                                <img loading="lazy" style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} src={AssetIcon} alt="asset-icon" />
                                                {option.name}
                                            </div>
                                        }}
                                        onInputChange={(event, newInputValue, reason) => {
                                            if(reason === "clear"){
                                                clearIndicatorMUI()
                                            }
                                          }}
                                        style={{ width: "100%" }}
                                        renderInput={(params) =>
                                            <div style={{position: "relative"}} ref={params.InputProps.ref}>
                                            {
                    
                                               !params.inputProps.value && (
                                                <span 
                                                    style={{
                                                        position: "absolute",
                                                        transform: "translate(1%, 96%)",
                                                        marginLeft: "5px"
                                                    }}
                                                    >
                                                <img loading="lazy" style={{ height: "20px", width: "20px" }} src={GreySearchIcon} alt="grey-icon" />
                                            </span>
                                                )
                                            }
                                            <TextField 
                                               {...params} 
                                               name="asset_name" 
                                               placeholder="search" 
                                               variant="outlined" 
                                               inputProps= {!params.inputProps.value ? {
                                                ...params.inputProps,
                                                style: { paddingLeft: "27px", position: "relative", top:"1px" } 
                                             }: {
                                                 ...params.inputProps
                                             }}
                                            />
                                        </div>
                                        }
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search-period-container">
                    <div className="icon-title">
                        <img loading="lazy" src={CalendarIcon} alt="asset-icon" />
                        <div className="asset-title">Search Period <p style={{color:"red", margin:"0px 0px 0px 2px"}}>*</p></div>
                    </div>
                    <div className="asset-details">{firebaseConfig.getValueByKey("report_telemetry_search_description")}</div>
                    <div className="search-period-title">
                        <div className="search-from">
                            <div className="asset-name">Date</div>
                            <div className="asset-selection">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <ThemeProvider theme={materialTheme}>
                                        <KeyboardDatePicker
                                            name="fromTime"
                                            value={dataset.fromTime}
                                            onChange={(e) => onUserInput({ "fromTime": e })}
                                            format="dd/MM/yyyy"
                                            inputVariant="outlined"
                                            className={classes.textField}
                                            style={{ width: "100%" }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton style={{ outline: "none" }}>
                                                            <img loading="lazy" src={CalendarIcon} alt="Icon"/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </ThemeProvider>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button-click" onMouseEnter={() => setIsHovered((current) => ({...current, btnReport: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnReport: false}))}>
                    <button className={isEmptyObject(searchValue) ? "btn-report-disabled" : typeof searchValue.asset_uuid === "undefined"  && searchValue.asset_uuid === "" ? "btn-report-disabled" : `${isHovered.btnReport ? "isHovered-btn-report": "btn-report"}`} onClick={() => onSearch()} disabled={isEmptyObject(searchValue) ? true : typeof searchValue.asset_uuid === "undefined" ? true : false}>
                        Run Report
                        </button>
                </div>
            </div>

            <style>
                {
                    `.r-telemetry-container{
                    padding: 24px;
                 }
                 .panel-title{
                    height: 32px;
                    color: #333333;
                    font-size: 24px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 32px;
                    margin: 0px 0px 4px 0px;
                 }
                 .panel-description{
                    height: 21px;
                    color: #939598;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 21px;
                 }
                 .input-container{
                    position: relative;
                    left: 55%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%); /* for IE 9 */
                    -webkit-transform: translate(-30%, 20%); /* for Safari */
                 }
                 .asset-input-container{
                     margin: 0px 0px 24px 0px;
                     padding: 16px;
                     width: 41.80vw;
                     border-radius: 4px;
                     background-color: #FFFFFF;
                 }
                 .search-period-container{
                    padding: 16px;
                    width: 41.80vw;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    // box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
                 }
                 .asset-title{
                    display: flex;
                    margin: 0px 0px 0px 8px;
                    color: #333333;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 21px;
                 }
                 .asset-details{
                    margin: 8px 0px 16px 0px;
                    color: #939598;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 16px;
                 }
                 .icon-title{
                    display: flex;
                    align-items: center;     
                 }
                 .asset-name-select{
                    display: grid;
                    grid-template-columns: 0.5fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    align-items: center;
                 }
                 .asset-name{
                    height: 19px;
                    width: 77px;
                    color: #939598;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                 }

                .autocomplete-container{
                    display: flex;
                    align-items: center;
                }
                .option-name{
                    margin: 0px 4px 0px 0px;
                }
                .asset-selection{
                }
                .search-period-title{

                }
                .search-from{
                    display: grid;
                    grid-template-columns: 0.5fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    align-items: center;
                    margin: 0px 0px 16px 0px;
                }
                .search-to{
                    display: grid;
                    grid-template-columns: 0.5fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    align-items: center; 
                }
                .button-click{
                    margin: 24px 0px 0px 0px;
                    width: 41.80vw;
                    display: flex;
                    justify-content: center;
                }
                .btn-report{
                    border-style: solid;
                    height: 32px;
                    width: 386px;
                    background-color: #509D91;
                    color: #FFF;
                }
                .isHovered-btn-report{
                    border-style: solid;
                    height: 32px;
                    width: 386px;
                    background-color: #498f84;
                    color: #FFF;
                    font-weight: 600;
                    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                }
                .btn-report-disabled{
                    border-style: solid;
                    height: 32px;
                    width: 386px;
                    background-color: #509D91;
                    color: #FFF;
                    font-weight: bold;
                    opacity: 0.7;
                }
                `
                }
            </style>
        </div>
    )
}

export default PanelReportTelemetry
