import React, { useState, useRef } from 'react'
import Cookies from 'js-cookie';

import DarkNavigationDown from "../../Image/dark_navigation_down.svg"
import SearchIcon from "../../Image/search.svg"
import LightCloseIcon from '../../Image/light_close_icon.svg'
import AddIcon from "../../Image/dark_add.svg";

import AddFunction from "./AddFunction"
import EditFunction from "./EditFunction"
import BeaconCards from './Cards'
// import BulletChart from '../../component/component-bulletchart/BulletChart'
// import FilterBy from "../../component/component-filterby/FilterBy"
import FilterBy from '../../component/component-restAPI-filter/FilterBy'
import SnackBar from '../../config/config-snackbar/SnackBar'

const Overview = ({ title, data, loading, addForm,  dispFilter, setDispFilter, onFilterClick, setMainFilter, editForm, query, Cards, setFilterFinal, setFilterMain, fx_changePage, history, setPageInd, filterFinal={}, filterList, preFilter={}, fx_PreFilter, EnterFilter, keyFilter, setPreFilter, setCanUpdate, canUpdate, setEdit, edit, setEditValue, editValue, APICalled, role, roleConfig, isAdmin, isDashboard }) => {
    const [add, setAdd] = useState(false);
    const chartColors = ["#546A7B", "#0D1F2D", "#9EA3B0", '#E4C3AD', "#939598", "#CADF9E", "#ABB557", "#F46036", "#FAE1DF"]
    const [filterValue, setFilterValue] = useState(Object.keys(filterFinal).length > 0 ? filterFinal : preFilter)
    const wrapperRef = useRef(null);

    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")
    const [hoveredO, setIsHoveredO] = useState({
        add: false,
        filter: false
    })
    
    // console.log(setMainFilter)

    const filterDesign = () => {
        if(Object.keys(filterFinal).length > 0){
            return "filter-tag"
        }else{
            return "filter-no-tag"
        }
    }

    //display filter count
    const filterCount = () => {
        if(filterFinal){
            if(Object.keys(filterFinal).length > 0){
            return <div style={{display:"flex", alignItems:"center"}}>
                {`${Object.keys(filterFinal).length} Filter`}
                <img loading="lazy" src={LightCloseIcon} alt="close-icon" style={{cursor: "pointer", height:"10px", width:"10px", margin:"0px 0px 0px 10px"}} onClick={(e) => onTagRemoveFilter(e)}/>
            </div>
                
            }
            else{
                return null
            }
        }else{
            return null
        }
    }

    const onTagRemoveFilter = (e) => {
        setFilterValue({})
        setPreFilter({})
        setFilterFinal({})  
        setMainFilter({})  
        APICalled(); 
    }
    
    const fxBackEdit = () => {
        setEditValue();
        setEdit(!edit)
    }

    // console.log(filter);


    return add ? ( <AddFunction title={title} fx_back={() => setAdd(!add)} APICalled={APICalled} history={history} setIsHoveredO={setIsHoveredO} setSnOpen={setSnOpen} fx_changePage={fx_changePage} setMessage={setMessage} setSeverity={setSeverity} role={role} roleConfig={roleConfig} isAdmin={isAdmin} isDashboard={isDashboard} /> ) : edit ? ( <EditFunction title={title} fx_back={() => fxBackEdit()} setMainFilter={setMainFilter} fx_changePage={fx_changePage} history={history} setFilterMain={setFilterMain} setIsHoveredO={setIsHoveredO} selected={editValue} APICalled={APICalled} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} role={role} roleConfig={roleConfig} isAdmin={isAdmin} isDashboard={isDashboard} /> ) : (
        <div className="table-container">
            <div className="title-font">{title}</div>
            <SnackBar open={snOpen} setOpen={setSnOpen} severity={severity} message={message} />
            {/* {
                isAdmin && (
                <div className="chart-container">
                    <div className="chart right-space">
                        <BulletChart name="availbl_devices" title="Available" loading={false} data={null} colorList={chartColors} />
                    </div>
                    <div className="chart">
                        <BulletChart name="assigned_devices" title="Assigned" loading={false} data={null} colorList={chartColors} />
                    </div>
                </div>
                )
            } */}
            <div className='buttons'>
                <div className="filter-container">
                    <img loading="lazy" src={SearchIcon} alt="search-icon" />
                    <img loading="lazy" src={DarkNavigationDown} alt="search-icon" onClick={() => setDispFilter(!dispFilter)} className={hoveredO.filter ? "add-hover-button-style" : "add-button-style"} onMouseEnter={() => setIsHoveredO((current) => ({...current, filter: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, filter: false}))}/>
                    <div className="input-filter">
                        <div className={filterDesign()}>
                            {filterCount()}
                        </div>
                        <input type="text" onChange={(e) => fx_PreFilter(e.target.value)} onKeyDown={(e) => EnterFilter(e)} />
                    </div>       
                </div>
                {
                    roleConfig.admin.includes(role) && (
                    <div className="add-button">
                        <div onClick={() => setAdd(!add)}>
                            <img loading="lazy" className={hoveredO.add ? "add-hover-button-style" : "add-button-style"} onMouseEnter={() => setIsHoveredO((current) => ({...current, add: true}))} onMouseLeave={() => setIsHoveredO((current) => ({...current, add: false}))} src={AddIcon} alt="Icon"/>
                        </div>
                    </div>
                    )
                }
                {/* the if else statement for filter component, temporary only */}
                <div style={{position: "absolute"}} ref={wrapperRef}>
                    { dispFilter && <FilterBy   setFilterFinal={setFilterFinal} filterFinal={filterFinal} filterList={filterList} filterValue={filterValue}  setFilterValue={setFilterValue} onFilterClick={onFilterClick}/> }     
                </div>  
            </div>
            <div className="table-responsive">
                {
                    data.length > 0 && data.map((x) => (
                        <BeaconCards data={x} setEdit={setEdit} setEditValue={setEditValue} isAdmin={isAdmin} isDashboard={isDashboard} />
                    ))
                }
            </div>
            <style jsx>
                {    
                    ` .table-container{
                            margin: 24px 24px;
                        }
                        .title-font{
                            height: 32px;
                            color: #333333;
                            font-size: 24px;
                            font-weight: 600;
                            letter-spacing: 0;
                            line-height: 32px;
                            margin: 0px 0px 8px 0px;
                        }
                        .buttons{
                        display: flex;
                        height: "16px"; 
                        align-items: "center";
                        margin: 0px 0px 8px 0px;
                        }
                        .table-boostrap{
                        min-height: 75vh;
                        background-color: #ffffff;
                        }
                        .table-responsive{
                            height:75vh;
                            width: 100%;
                        }
                        .filter-button{
                            font-size: 12px;
                        }
                        .right-space{
                            display: flex;
                            flex-direction: column;
                            margin: 0px 24px 0px 0px;
                          }
                        .pagination-table{
                        position: relative;.
                        }
                        .img-pagination{
                        width: 19px,
                        height: auto;
                        }
                        .row-height{
                            height:
                        }
                        .wrapper{
                        word-wrap: break-word
                        }
                        .chart-container{
                            display:flex;
                            margin: 0px 0px 24px 0px;
                           }
                           .chart{
                            padding: 8px;
                            width: 100%;
                            border-radius: 4px;
                            background-color: #FFFFFF;
                            display: flex;
                            flex-direction: column;
                            justify-content: center
                          }
                        .filter-container{
                        padding: 8px;
                        display: flex;
                        box-sizing: border-box;
                        height: 40px;
                        width: 360px;
                        border: 1px solid #939598;
                        border-radius: 4px;
                        }
                        .add-button{
                            margin: 0px 0px 0px 8px;
                            display: flex;
                            align-items: center;
                        }
                        .filter-tag{
                            display: flex;
                            align-items: center;
                            color: #FFF;
                            border-radius: 4px;
                            width: 85px;
                            height: 24px;
                            text-align: center;
                            background-color: #509D91;
                            justify-content: center;
                            margin: 0px 8px 0px 0px;
                        }
                        .filter-no-tag{
                            height: 0px;
                        }
                        input[type=text] {
                            width: 285px;
                            background: transparent;
                            border: none;
                            outline: none;
                        }
                        .input-filter{
                            display: flex;
                            align-items: center;
                        }
                        .add-button-style{
                            cursor: pointer;
                        }
                        .add-hover-button-style{
                            background-color: rgba(0,0,0,0.03);
                            border-radius: 5px;
                            cursor: pointer;
                        }
                    `
                }
            </style>
        </div>
    )
}

export default Overview
