import React from 'react'
//library
import { useQuery } from "@apollo/react-hooks";
import { snakeCase } from "snake-case"

//component
import {GET_FEATURE} from '../../config/config-graphql/Graphql'

const PanelFeatures = () => {
  const { data : data_feature, loading} = useQuery(GET_FEATURE)
    return (
        <div className="table-container">
            <div className="title-font">Features</div>
            <div>
                {
                    !loading && data_feature && data_feature.get_features.map((x,i)=> {
                        try {
                          return (
                            <div>
                                <div key={i} className="features-containers">
                                    <div className="features-name-containers">
                                        <div className="feature-img">   
                                            <img src={`${x.icon}/icon_${snakeCase(x.name)}_enabled.svg`} alt="f-img"/>
                                        </div>
                                        <div className="feature-name">{x.name}</div>
                                    </div>  
                                    <div className="feature-desc">
                                        <div className="line-box"></div>
                                        <p className="desc-detail">{x.description}</p>
                                    </div>
                                </div>
                            </div>
                        )                        
                        } catch (error) {
                          // console.log(error)
                        }
                        return null
                    })
                }
            </div>
        <style jsx>
            {
                
                ` .table-container{
                        margin: 24px 24px;
                   }
                  .title-font{
                    height: 32px;
                    // width: 108px;
                    margin-bottom: 16px;
                    color: #333333;
                    font-size: 24px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 32px;
                  }  
                  .features-containers{
                    padding: 20px 20px 20px 20px;
                    display: grid;
                    grid-template-columns: 1fr 3fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    margin-bottom: 4px;
                    // height: 64px;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                  }     
                  .feature-img{
                    margin: 0px 12px 0px 0px;
                  }  
                  .feature-desc{
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    overflow: hidden;
                  }
                  .line-box{
                    height: 40px;
                    margin-right: 24px;
                    border: 1px solid #DDDDDD;
                  }
                  .desc-detail{
                    margin: 0px;
                    padding: 0px;
                    color: #939598;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 16px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                  .features-name-containers{
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    overflow: hidden
                  }
                  .feature-name{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                `
            }
        </style>
    </div>
    )
}

export default PanelFeatures
