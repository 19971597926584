import React, {useState, useEffect} from 'react'
import { Base64 } from 'js-base64';
import moment from 'moment-timezone'

//component
import Form from './Form'
import { AllMandatoryFilled, onUserEdit} from "../../config/config-function/function";
import { AGENCY_REDUCER } from "../../config/config-reducer/reducer"
import {requiredField} from '../../config/config-table/table'

const tz = moment.tz.guess()
moment.tz.setDefault(tz)

const FunctionEdit = ({staticBiling, values, onChange, APICalled, displayed, query, fx_back, setEditVariables, setGetVariables, setDeleteVariables, setCanUpdate, canUpdate, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere, setFilterFinal, setPageInd, staticUser }) => {

  const staticDeviceList = values[0].devices ? values[0].devices.map(x => x) : []
  const staticUserAdministration = values[0].admin_user ? values[0].admin_user : ""
  const staticBeaconList = values[0].beacons && values[0].beacons.length > 0 ? values[0].beacons.map(x => x) : [];

  const [typeID, setTypeID] = useState(values[0].sigfoxDeviceTypeId ? [...values[0].sigfoxDeviceTypeId] : [])
  const [userAdmistration, setUserAdmistration] = useState({...values[0].adminUser})
  const [billing, setBilling] = useState(values[0].billing) //will come later
  const [devices, setDevices] = useState(values[0].devices.map(x => {return x.uuid}))
  const [beacons, setBeacons] = useState(values[0].beacons.map(x => {return x.uuid}))
  const [deviceAdd, setDeviceAdd] = useState([])
  const [beaconAdd, setbeaconAdd] = useState([])
  const [originalDevice, setOriginalDevice] = useState(values[0].devices.map(x => {return x.uuid}))
  const [originalBeacon, setOriginalBeacon] = useState(values[0].beacons.map((x) => { return x.uuid }))
  const [deviceRemove, setDeviceRemove] = useState([])
  const [beaconRemove, setBeaconRemove] = useState([])
  const [address, setAddress] = useState({...values[0].address})
  const [countryCode, setCountryCode] = useState(values[0].contactCode ? values[0].contactCode.toString() : "")
  const [contact_number, setContact_number] = useState(`${values[0].contact_code}${values[0].contactNumber}`)
  const [selectedTimezone, setSelectedTimezone] = useState({value: values[0].timezone ? values[0].timezone : Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Kuala_Lumpur"})

  // console.log(moment(staticBiling.subscription_from).tz(tz).format() !== moment(billing.subscription_from).tz(tz).format() || moment(staticBiling.subscription_to).tz(tz).format() !== moment(billing.subscription_to).tz(tz).format()? "empty uuid" : "ada uuid")

  const onUserInput = (e, value, isValue) => {
    let input = onUserEdit(e, value, isValue, onChange, values);
    let emRegex = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)
    const _check_normal = AllMandatoryFilled(requiredField.agency, input[0])
    const _check_user = input[0] && input[0].user ?  AllMandatoryFilled(requiredField.agency_user, input[0].user) : false
    const _check_email = input[0] && input[0].user ? emRegex.test(Base64.decode(input[0].user.email)) : false

    // const _check_password = input[0] && input[0].user && input[0].user.password === "" && false

    // console.log(input)
    _check_normal && _check_user && _check_email ? setCanUpdate(true) : setCanUpdate(false)
    setEditVariables({ agency: !input ?  AGENCY_REDUCER(values[0], countryCode, selectedTimezone, staticUser, displayed) : AGENCY_REDUCER(input[0],countryCode, selectedTimezone, staticUser, displayed)});
    // console.log(input)
  }

  useEffect(() => {
    onUserInput(null, { timezone: selectedTimezone.value}, true)
            //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimezone])

  useEffect(() => {
    onUserInput(null, { sigfoxDeviceTypeId: typeID && typeID.map(x => {return x})}, true)
            //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeID])

  useEffect(() => {
    onUserInput(null, { user: {uuid: userAdmistration.uuid, email: Base64.encode(userAdmistration.email), firstName: userAdmistration.firstName, lastName: userAdmistration.lastName} }, true)
            //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAdmistration])

  useEffect(() => {
    onUserInput(null, { address: {road: address.road, city: address.city, state: address.state, country: address.country, postcode: address.postcode} }, true)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])
  // billing.map(x => {return {uuid: x.uuid, subscription_from: moment(x.subscription_from).tz(tz).format(), subscription_to: moment(x.subscription_to).tz(tz).format()}}) }
  useEffect(() => {
    onUserInput(null, { billing: {uuid: moment(staticBiling.subscriptionFrom).tz(tz).format() !== moment(billing.subscriptionFrom).tz(tz).format() || moment(staticBiling.subscriptionTo).tz(tz).format() !== moment(billing.subscriptionTo).tz(tz).format()? "": billing.uuid, subscriptionFrom: moment(billing.subscriptionFrom).tz(tz).format(), subscriptionTo: moment(billing.subscriptionTo).tz(tz).format()}}, true)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billing])
  return (
    <Form agencies={values} onUserInput={onUserInput} APICalled={APICalled} setOriginalBeacon={setOriginalBeacon} originalBeacon={originalBeacon} setBeacons={setBeacons} beacons={beacons} setOriginalDevice={setOriginalDevice} beaconAdd={beaconAdd} setbeaconAdd={setbeaconAdd} beaconRemove={beaconRemove} setBeaconRemove={setBeaconRemove} staticDeviceList={staticDeviceList} staticBeaconList={staticBeaconList} displayed={displayed} setTypeID={setTypeID} typeID={typeID} query={query} addList={deviceAdd} setAddList={setDeviceAdd} userAdmistration={userAdmistration} setUserAdmistration={setUserAdmistration} setDevices={setDevices} devices={devices} fx_back={fx_back} setDeviceAdd={setDeviceAdd} removeDeviceList={deviceRemove} setRemoveDevice={setDeviceRemove} originalDevice={originalDevice} staticUserAdministration={staticUserAdministration} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} setErrMsg={setErrMsg} setErrOpen={setErrOpen} setErrSevere={setErrSevere} setAddress={setAddress} address={address} setBilling={setBilling} billing={billing} setCountryCode={setCountryCode} countryCode={countryCode} setContact_number={setContact_number} contact_number={contact_number} setFilterFinal={setFilterFinal} setPageInd={setPageInd} setSelectedTimezone={setSelectedTimezone} selectedTimezone={selectedTimezone}/>
  )
}

export default FunctionEdit
