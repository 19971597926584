import React, { useState} from 'react'
import Cookies from 'js-cookie';

//library
// import { useMutation } from "@apollo/react-hooks";

//image
import NavigationIcon from "../../Image/chevron_right.svg"

//component
import Loaders from "../../component/component-loaders/Loaders"
import SnackBar from '../../config/config-snackbar/SnackBar'
// import DeleteCard from '../../component/component-deletecard/DeleteCard'
import SaveCard from '../../component/component-save/SaveCard'
import { APIUpdateAgencies } from '../../config/config-restAPI/patchAPICalled'
import { APIDeleteAgencies } from '../../config/config-restAPI/deleteAPICalled'
import DeleteCard from '../../component/component-deletecardRest/DeleteCard'


const EditForm = ({ title, fx_back, selected, APICalled, Form, setMainFilter, setFilterFinal, setPreFilter, setFilterValue, query, setCanUpdate, canUpdate, featureList, setSnOpen, setMessage, setSeverity,setPageInd}) => {
  // console.log(selected)
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');
    const [values, setValues] = useState(selected.map(a => ({...a})));
    const [editVariables, setEditVariables] = useState({});
    const [getVariables, setGetVariables] = useState({});
    const [loading, setLoading] = useState(false);
    const staticDevicesList = selected[0].devices
    const staticBiling = selected[0] ? selected[0].billing : {}
    const staticUser = selected[0] ? selected[0].admin_user : {}


    //this state to mention if its on edit form
    //edit = true (edit form)
    //edit = false (add form)
    const [displayed] = useState(true)
    const [isHovered, setIsHovered] = useState({
      btnSave: false
    })

    //the errOpen, errMsg and errSevere is for snackbar props for error 
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("")

    const onSubmit = async (e) => {
      try{
        e.preventDefault();
        const response = await APIUpdateAgencies(token, agencyId, editVariables, null, setLoading);
        if (response.data.status === 200) {
          setSnOpen(true);
          setMessage("Record has been successfully added");
          setSeverity("success");
          setLoading(false)
          setPageInd(false)
          setFilterFinal({})
          APICalled();
          fx_back();
        } else {
          setLoading(false)
          setErrOpen(true)
          setErrMsg(`${response.data.status}: ${response.data.error.message}`)
          setErrSevere("error")
        } 
  
      }catch(err){
        console.log(err)
        setLoading(false)
        setErrOpen(true)
        setErrMsg(JSON.stringify(err, null, 2))
        setErrSevere("error")
      }
  
    };

    // console.log(APICalled)


    const onChange = (e, i, value) => {
        const _values = [...values];
        if(value){
            let keys = Object.keys(value)
            keys.map(key => _values[i][key] = value[key])
        } else{
            _values[i][e.target.name] = e.target.value;
        }
        setValues(_values);
    };

    const onDelete = async () => {
      try {
        const reducer = { agencies : [{
          uuid: values[0].uuid,
          id: values[0].id
        }] }
        // console.log('REDUCERRR', reducer);
        const response = await APIDeleteAgencies(token, agencyId, reducer, setLoading);
        // console.log(response);
        if (response.data.status === 200) {
          setSnOpen(true);
          setMessage("Record has been successfully deleted!");
          setSeverity("success");
          setLoading(false)
          setPageInd(false)
          setFilterFinal({})
          APICalled();
          fx_back();
        } else {
          setLoading(false)
          setErrOpen(true)
          setErrMsg(`${response.data.status}: ${response.data.error.message}`)
          setErrSevere("error")
        } 
  
      } catch(err) {
        console.log(err)
        setLoading(false)
        setErrOpen(true)
        setErrMsg('Error!, Unable to update please try again')
        setErrSevere("error")
      }
    }

    const onClickBack = () => {
      setFilterValue({})
      setPreFilter({})
      setFilterFinal({})  
      setMainFilter({})   
      setPageInd(false)
      APICalled()
      fx_back()
    }

    if (loading) return <div style={{position: "relative", right:"2px", top: "40px"}}><Loaders /></div>

    return (
      
        <div className="form-container">
            <div className="navigation-title-placeholder">
                <div className="title-add">
                      <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                       <div onClick={() => onClickBack()} style={{cursor: "pointer"}} className="detail-title first-title">
                         {title}
                       </div>
                       <div className="detail-title">
                          <img loading="lazy" src={NavigationIcon} alt="Back"/>
                       </div>                      
                       <div className="detail-title">Edit {title}</div>
                       <div className="detail-title"></div>

                </div>
                <div className="button-delete-save-container">
                  <DeleteCard
                    canDelete={
                      values[0] && values[0].devices && values[0].devices.length > 0 ? false : true
                    }
                    onDelete={onDelete}
                  />
                  <SaveCard onSubmit={onSubmit} canAdd={canUpdate}/>
                </div>
                 
            </div>
            <Form staticBiling={staticBiling} APICalled={APICalled} values={values} onChange={onChange} displayed={displayed} setEditVariables={setEditVariables} setGetVariables={setGetVariables} query={query} fx_back={fx_back} setCanUpdate={setCanUpdate} canUpdate={canUpdate} featureList={featureList} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} setErrMsg={setErrMsg} setErrOpen={setErrOpen} setErrSevere={setErrSevere} setFilterFinal={setFilterFinal} setPageInd={setPageInd} staticUser={staticUser}/>
            
        <style jsx>
          {`
            .title-add{
              display: flex;
              align-items: center;         
            }
            .form-container{
              margin: 24px;
            }
            .navigation-title-placeholder {
              font-size: 18px;
              font-weight: 545;
              height: 32px;
              margin-bottom: 16px;
              display: flex;
              justify-content: space-between;
            }
            .details {
              height: 40px;
              // margin-left: 24px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.54);
            }
            .autocomplete {
              display: flex;
              height: 45px;
              // margin-left: 24px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.54);
            }
            .textfield {
              position: relative;
              bottom: 40px;
            }
            .detail-title{
              padding: 8px;
            }
            .first-title{
              font-size: 16px; 
              padding-left: 0px;
            }
            .button-delete-save-container{
              display: flex;
            }
          `}
        </style>
      </div>
    )
}

export default EditForm
