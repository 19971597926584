import React, {useState, useEffect} from 'react'

//library
import moment from "moment-timezone";

//list of images
import PositionIcon from "../../Image/place_one.svg"
import MoveIcon from "../../Image/move.svg"
import IdleIcon from "../../Image/device-idle.svg"
import NavigationIcon from "../../Image/chevron_right.svg"
import ExportIcon from '../../Image/export.svg'

//list of component
import NoReportData from '../../component/component-noreport/NoReportData'
import SnackBar from '../../config/config-snackbar/SnackBar'

const Table = ({ fx_back, reportData, loading, trips, reportError }) => {


    //this state for error handling on getQuery
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")

    //export csv file
    let csvFile = "Start_Time, Start_Longitude, Start_Latitude, End_Time, End_Longitude, End_Latitude, Moving_Duration, Idle_Duration, Total_Duration(%)\r\n"

    !loading && reportData && reportData.getTripRpt && reportData.getTripRpt !== null && reportData.getTripRpt.map(x => csvFile += `${moment(x.start_time).format("X") || "N/A"},${parseFloat(x.start_longitude).toFixed(6) || "N/A"},${parseFloat(x.start_latitude).toFixed(6) || "N/A"},${parseFloat(x.end_time).toFixed(6) || "N/A"}, ${parseFloat(x.end_longitude).toFixed(6) || "N/A"}, ${parseFloat(x.end_latitude).toFixed(6) || "N/A"},${x.moving_duration || "N/A"}, ${x.idle_duration || "N/A"}, ${x.total_duration || "N/A"}\r\n`)

    const url = `data:text/json;charset=utf-8,${encodeURIComponent(csvFile)}`


    useEffect(() => {
        if(reportError){
            setSnOpen(true)
            setMessage("Error: Unable to retrieve data. Please try again later.")
            setSeverity("error")
        }
    }, [reportError])

    return (
        <div className="telemetry-table-container">
            <div className="title-log">
                <div className="telemetry-title">
                    <div className="report-title" onClick={() => fx_back()}>Trips</div>
                    <div className="icon-title">
                        <img loading="lazy" src={NavigationIcon} alt="nav-icon" />
                    </div>
                    <div className="asset-title-name">
                        <div>{trips.asset_uuid.name}</div>
                    </div>
                </div>
                <div className="export-container">
                    <img loading="lazy" src={ExportIcon} alt="export-icon" />
                        &nbsp; <a href={url} download="TripsReport.csv" style={{ alignItems: "center", verticalAlign: "bottom" }}>Export Log</a>
                </div>
            </div>
            {
                reportError ? <SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/> : !loading && reportData && reportData.getTripRpt && reportData.getTripRpt !== null && reportData.getTripRpt.length > 0 ?
                    (
                        <div className="table-container">
                            <div className="table-header">
                                <div className="header">
                                    <img loading="lazy" className="img-sizes" src={PositionIcon} alt="icon" />
                                    <div className="body-header">
                                        <div className="text-header">Start Location /</div>
                                        <div className="text-header">Start Time</div>
                                    </div>
                                </div>
                                <div className="header">
                                    <img loading="lazy" className="img-sizes" src={PositionIcon} alt="icon" />
                                    <div className="body-header">
                                        <div className="text-header">End Location /</div>
                                        <div className="text-header">End Time</div>
                                    </div>
                                </div>
                                <div className="header">
                                    <img loading="lazy" className="img-sizes" src={MoveIcon} alt="icon" />
                                    <div className="body-header">
                                        <div className="text-header">Moving Duration </div>
                                    </div>
                                </div>
                                <div className="header">
                                    <img loading="lazy" className="img-sizes" src={IdleIcon} alt="icon" />
                                    <div className="body-header">
                                        <div className="text-header">Idle Duration </div>
                                    </div>
                                </div>
                                <div className="header">
                                    <div className="body-header">
                                        <div className="text-header">Total Duration</div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-body">
                                {
                                    reportData.getTripRpt.length > 0 ? reportData.getTripRpt.map(x => {
                                        return (
                                            <div className="table-body-row">
                                                <div className="header-time">
                                                    <div className="text-header">{x.start_latitude && x.start_longitude ? <a href={`https://www.google.com/maps/search/?api=1&query=${x.start_latitude},${x.start_longitude}`} rel="noopener noreferrer" target="_blank">{`${parseFloat(x.start_latitude.toFixed(5))},${parseFloat(x.start_longitude.toFixed(5))}`}</a> : "-"} </div>
                                                    <div className="text-header">{x.start_time ? moment(x.start_time).format('DD MMM YYYY hh:mm:ss a') : "-"}</div>
                                                </div>
                                                <div className="header-time">
                                                    <div className="text-header">{x.end_latitude && x.end_longitude ? <a href={`https://www.google.com/maps/search/?api=1&query=${x.end_latitude},${x.end_longitude}`} rel="noopener noreferrer" target="_blank">{`${parseFloat(x.end_latitude.toFixed(5))},${parseFloat(x.end_longitude.toFixed(5))}`}</a> : "-"} </div>
                                                    <div className="text-header">{x.end_time ? moment(x.end_time).format('DD MMM YYYY hh:mm:ss a') : "-"}</div>
                                                </div>
                                                <div className="header">
                                                    <div className="text-header">{x.moving_duration ? x.moving_duration : "-"}</div>                                           
                                                </div>
                                                <div className="header">
                                                    <div className="text-header">{x.idle_duration ? x.idle_duration : "-"}</div>
                                                </div>
                                                <div className="header">
                                                    <div className="text-header">{x.total_duration ? x.total_duration : "-"}</div>
                                                </div>
                                            </div>
                                        )
                                    }) : null
                                }
                            </div>
                        </div>
                    ) : <NoReportData />
            }

            <style>
                {
                    `
                    .telemetry-table-container{
                        padding: 16px;
                    }
                    .report-title{
                        margin: 0px 8px 0px 0px;
                        color: #333333;
                        font-size: 16px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 21px;
                        cursor: pointer;
                    }
                    .icon-title{
                        margin: 0px 8px 0px 0px;
                        display: flex;
                    }
                    .asset-title-name{
                        font-size: 18px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 24px;
                    }
                    .telemetry-title{
                        display: flex;
                        align-items: center;
                    }
                    .table-container{
                        display: flex;
                        // display: inline-block;
                        // min-width: 100%
                        width: 100%;
                        flex-direction: column; 
                        overflow-x :auto;
                        white-space: nowrap;
                    }
                    .table-header{
                        padding: 8px;
                        margin: 24px 0px 8px 0px;
                        height: 8vh;
                        background-color: #FFF;
                        display: grid;
                        // grid-template-columns: 1fr 1.5fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 48px;
                        grid-template-areas:
                          ". . . . . ";
                        align-items: center;
                    }
                    .table-body{
                        padding: 8px;
                        height: 58vh;
                        background-color: #FFF;
                        overflow: auto;
                    }
                    .table-body-row{
                        height: 8vh;
                        display: grid;
                        // grid-template-columns: 1fr 1.5fr 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 48px;
                        grid-template-areas:
                          ". . . . .";
                    }
                    .img-sizes{
                        height: 16px;
                        width: 16px;
                    }
                    .header{
                        display: flex;
                        align-items: center;
                    }
                    .header-time{
                        display: flex;
                        flex-direction : column;
                    }
                    .body-header{
                        margin: 0px 4px 0px 4px;
                    }
                    .text-header{
                        color: #333333;
                        font-size: 14px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 24px;
                    }
                    .export-css{
                        color: #333333;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19p
                    }
                    .export-container{
                        display: flex;
                        align-items: center;
                    }
                    .title-log{
                        display: flex;
                        justify-content: space-between;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default Table
