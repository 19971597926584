import React from 'react'

const FormGeofencePolicy = ({asset, isPaired, details }) => {
    const pairedAsset = isPaired ? Array.isArray(asset) ? asset[0].paired_assets[0] : asset.paired_assets[0] : {};
    return (
        <div className="geofence-policy-container">
            <div className="asset-title">Geofence Policy</div>
            <div className="paired-asset-detail">{details}</div>
            <div className="policy-name">
                {
                    Array.isArray(asset) && asset.length === 1 ? asset[0] && asset[0].geofences && asset[0].geofences.map(x => {
                        return(
                            <div>{x.name}</div>
                        )
                    }): asset.geofences && asset.geofences.map(el => {
                        return(
                            <div>{el.name}</div>
                        ) 
                    })
                }
            </div>
            {
                isPaired && (
                    <>
                        <div className="divider"></div>
                        <div className="other-paired-asset">Paired Asset</div>
                        <div className="policy-names">
                            {
                                pairedAsset.geofences && pairedAsset.geofences.length > 0 ? pairedAsset.geofences.map(x => {
                                    return(
                                        <div>{x.name}</div>
                                    )
                                }) : (<div>-</div>)
                            }
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default FormGeofencePolicy
