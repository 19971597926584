import React, {useCallback, useRef} from 'react'
import MapGL from 'react-map-gl';
import {Editor, RENDER_STATE} from "react-map-gl-draw";
import Geocoder from "react-map-gl-geocoder";
import * as turf from '@turf/turf'
import {getFitboundPoint} from '../../component/component-fitbound/Fitbound'

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

const Map = ({setModeId, modeHandler, setModeHandler, selectedFeatureIndex, setSelectedFeatureIndex, editorRef, setEditorRef, geoJSON, setGeoJSON, setMapping, mapping, setPolygons, polygons, setName, name}) => {
    const mapRef = useRef();

    const onSelect = (options) => {
        setSelectedFeatureIndex({selectedFeatureIndex: options && options.selectedFeatureIndex})

        // console.log(options)

        if(options.selectedFeatureIndex !== -1 && options.selectedFeatureIndex !== null){
          const mapCoordinate = options.selectedFeature.geometry.coordinates
          const selectedPolygon = turf.polygon(mapCoordinate)
          const centroidPolygon = turf.centroid(selectedPolygon)
          const fitbound = getFitboundPoint([centroidPolygon])
          setMapping(fitbound)
        }
    }

    const handleViewportChange = useCallback(
        (newViewport) => setMapping(newViewport),
        []
    );
  
    const onUpdate = ({editType, data}) => { 
        const clone = [...polygons]
        const defDatas = data.map((x,i) => {return {...clone[i], area: x }})
        clone.map((x,i) => x.area === data[i])
          
        if (editType === "addFeature"){
          setModeHandler(null)
          setModeId(null)
          setPolygons(defDatas)
        }       
    }

    const getFeatureStyle = ({feature, index, state}) => {
     if(polygons[index] && polygons[index].action && polygons[index].action === "DELETE"){
         return {display: "none"}      
     }

      switch (state) {
        case RENDER_STATE.SELECTED:
            if(selectedFeatureIndex.selectedFeatureIndex === index){
                return{
                    stroke: '#509D91',
                    strokeWidth: 2,
                    fill: 'rgb(101,197,182)',
                    fillOpacity: 0.4,
                    strokeDasharray: '6,8'
                }
            }
            /* falls through */
        case RENDER_STATE.HOVERED:
        case RENDER_STATE.UNCOMMITTED:
        case RENDER_STATE.CLOSING:
          return {
            stroke: '#509D91',
            strokeWidth: 2,
            fill: 'rgb(101,197,182)',
            fillOpacity: 0.2,
            strokeDasharray: '6,8'
          };
          /* falls through */
        default:
          return {
            stroke: '#509D91',
            strokeWidth: 2,
            fill: 'rgb(101,197,182)',
            fillOpacity: 0.2
          };
      }
    }

    const handleGeocoderViewportChange = useCallback(
        (newViewport) => {
          const geocoderDefaultOverrides = { transitionDuration: 1000 };
    
          return handleViewportChange({
            ...newViewport,
            ...geocoderDefaultOverrides
          });
        },
        [handleViewportChange]
    );
    
     
    return (
        <div style={{position: "relative", width: "100%", height:"100%"}}>
            
            <MapGL 
                {...mapping} 
                ref={mapRef}
                width="100%" 
                height="100%" 
                mapboxApiAccessToken={MAPBOX_TOKEN} 
                attributionControl={false}             
                mapStyle="mapbox://styles/asiamobiliti/ckb1yq6080opd1inb3lkdmbii"
                onViewportChange={handleViewportChange}
            >
                <Geocoder
                    mapRef={mapRef}
                    onViewportChange={handleGeocoderViewportChange}
                    mapboxApiAccessToken={MAPBOX_TOKEN}
                    countries="MY,SG,TH"
                    position="top-left" 
                    marker={false}
                
                />
                <Editor
                    // to make the lines/vertices easier to interact with
                    ref={(x) => setEditorRef(x)}
                    clickRadius={12}
                    mode={modeHandler}
                    onSelect={onSelect}
                    onUpdate={onUpdate}
                    featureStyle={getFeatureStyle}
                    features={polygons && polygons.map(polygon => polygon.area) || []}
                    selectedFeatureIndex={selectedFeatureIndex && selectedFeatureIndex.selectedFeatureIndex}
                />
            </MapGL>
            <style>
              {
                `.mapboxgl-ctrl-geocoder--button{
                  background: #ffffff00;
                  position: relative;
                  top: 0;
                }
                .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > *{
                  top: -3px;
                }
                .mapboxgl-ctrl-geocoder {
                  min-width: 277px;
                }
                `
              }
            </style>
        </div>

    )
}

export default Map
