import React, { useState } from 'react'

//library
import { useMutation } from "@apollo/react-hooks";

//image
import NavigationIcon from "../../Image/chevron_right.svg"

//component
import Loaders from "../../component/component-loaders/Loaders"
// import {UPDATE_SIGFOX_CONFIG, GET_DEVICES_STAT} from '../../config/config-graphql/Graphql'
import SaveCard from '../../component/component-save/SaveCard'
import SnackBar from '../../config/config-snackbar/SnackBar'
import {SIGNUP_USER, GET_USERS} from '../../config/config-graphql/Graphql'

const AddForm = ({title, fx_back, FunctionForm, setIsHoveredO, query, setCanUpdate, canUpdate, featureList, setSnOpen, setMessage, setSeverity }) => {

    const [values, setValues] = useState({});
    const [addVariables, setAddVariables] = useState({});
    const [getVariables, setGetVariables] = useState({});
    const [canAdd, setCanAdd] = useState(false) //only can add once all mandatory field filled
    const [displayed, setDisplayed] = useState(false)

    //this state are for snackbar props
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("")

    const [isHovered, setIsHovered] = useState({
      btnSave: false
    })

    const onChange = (e, value) => {
      // console.log(e, value)
        const input = value ? { ...values, ...value }: { ...values, [e.target.name]: e.target.value };
        setValues(input)
    };

    const onSubmit = async (e) => {
      // console.log(addVariables)
      try{
        e.preventDefault();
        const response = await addData();
        if(response.errors){
          setErrOpen(true)
          setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
          setErrSevere("error")
        }
        else{
          setSnOpen(true)
          setMessage("Record has been successfully added")
          setSeverity("success")
          fx_back();
        }  
      }catch(err){
        console.log(JSON.stringify(err, null, 2))
        setErrOpen(true)
        setErrMsg(JSON.stringify(err, null, 2))
        setErrSevere("error")
      }
    };

    const [addData, { loading: addLoading, error }] = useMutation(SIGNUP_USER, {
      variables: addVariables,
      refetchQueries: () => [
          {
              query: GET_USERS,
              variables: {filter: {}},
          },
      ],
    });

    const onClickBack = () => {
      setIsHoveredO({add: false}) 
      fx_back()
    }
    if (addLoading) return <div style={{position: "relative", right:"2px", top: "40px"}}><Loaders /></div>
    return (
        <div className="form-container">
        <div className="navigation-title-placeholder">
            <div className="title-add">
                   <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                   <div onClick={() => onClickBack()} style={{cursor: "pointer"}} className="detail-title first-title">
                     {title}
                   </div>
                   <div className="detail-title">
                      <img loading="lazy" src={NavigationIcon} alt="Back"/>
                   </div>                      
                    <div className="detail-title">Add {title}</div>
            </div>
            <SaveCard onSubmit={onSubmit} canAdd={canAdd}/>
        </div>
        <FunctionForm values={values} onChange={onChange} setAddVariables={setAddVariables} setGetVariables={setGetVariables} setCanAdd={setCanAdd} canAdd={canAdd} displayed={displayed} setDisplayed={setDisplayed} setCanUpdate={setCanUpdate} canUpdate={canUpdate}/>
        
    <style jsx>
      {`
        .title-add{
          display: flex;             
          align-items: center;             
        }
        .form-container{
          margin: 24px;
          display: grid;
        }
        .navigation-title-placeholder {
          font-size: 18px;
          font-weight: 545;
          height: 32px;
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
        }
        .details {
          height: 40px;
          // margin-left: 24px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.54);
        }
        .autocomplete {
          display: flex;
          height: 45px;
          // margin-left: 24px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.54);
        }
        .textfield {
          position: relative;
          bottom: 40px;
        }
        .detail-title{
          padding: 8px;
        }
        .first-title{
          font-size: 16px; 
          padding-left: 0px;
        }
      `}
    </style>
  </div>
    )
}

export default AddForm
