import React, {useEffect, useState} from "react";
import Cookies from 'js-cookie';

import TelegramIcon from '../../Image/telegram-icon.svg'
import WhatsappIcon from '../../Image/whatsapp_icon.svg'
import SlackIcon from '../../Image/slack_icon.svg'
import ChevronLightIcon from '../../Image/chevron_light.svg'
import EmailIcon from '../../Image/email.svg'

import SnackBar from '../../config/config-snackbar/SnackBar'
import Loaders from "../../component/component-loaders/Loaders"
import Overview from './Overview'
import FunctionAdd from './FunctionAdd'
import FunctionEdit from './FunctionEdit'
import { APIGetChannel } from '../../config/config-restAPI/getAPICalled';
import { filterList } from "../../config/config-filter/filter"


const PanelChannel = ({fx_changePage, filter, setFilter, featureDisable}) => {
    const [filterFinal, setFilterFinal] = useState({})
    const [preFilter, setPreFilter] = useState({})
    const [canUpdate, setCanUpdate] = useState(false) //default false and also this state for btn save mandatory field
    const [edit, setEdit] = useState(false); //default false and also this state is to open the overview details edit form
    const [editValue, setEditValue] = useState() //this state is to store the all the selected value into the state.,
    const [apiLoading, setApiLoading] = useState(false);
    const [channelValue, setChannelValue] = useState([]);
    const [errors, setErrors] = useState(false);

    const [dispFilter, setDispFilter] = useState(false) // to display a filter component (REST)
    const [filterPre, setFilterPre] = useState({}); // pre-filter component (REST) this for input

    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');
  
    //this state for error handling on getQuery
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")


    // !loading && console.log(channel_data)

    const PreFilter = (value) => {
        const snFilter = { name: `CONTAINS,${value.toString()}`}
        setPreFilter({ name: { type: "CONTAINS", value: [ value ] } })
        setFilterPre(snFilter)
    }

    const APICalled = async (filterName, filterType) => {
        try {
            const result = await APIGetChannel(token, setApiLoading, agencyId, filterName, filterType)
            if (result.data.status === 200) {
                setChannelValue(result.data.data)
                setApiLoading(false)
            } else {
                setSnOpen(true);
                setMessage("Error: Unable to retrieve data. Please try again later.");
                setSeverity("error") 
                setErrors(true)
                setFilter({})
                setApiLoading(false);
            }
        } catch(err) {
            setSnOpen(true);
            setMessage("Error: Unable to retrieve data. Please try again later.");
            setSeverity("error");
            setFilter({})
            setErrors(true)
            setApiLoading(false);
        }
    }

    useEffect(() => {
        if (token) {
            APICalled('', '');
        }
    }, [])
    
    const EnterFilter =(e) => {
        if(e.key === "Enter"){
            setFilterFinal(preFilter)
            APICalled(filterPre)
        }
    }

    useEffect(() => {
        if(errors){
            setSnOpen(true)
            setMessage("Error: Unable to retrieve data. Please try again later.")
            setSeverity("error")
        }
    }, [errors])

    const onFilterClick = (filterVariable) => {      
        // this to do the reducer
        const valueFilter = Object.entries(filterVariable).map((x) => {
            const schema = x[0];
            const values = x[1];
            return {
                [schema]: `${values.type},${values.value.toString()}`
            }
        })
        // this to convert from array to object.
        const changeToObject = valueFilter.reduce((previosObject, currentObject) => {
            return Object.assign(previosObject, currentObject)
        }, {})

        APICalled(changeToObject.name, '');
        setFilterFinal(changeToObject)
        setDispFilter(!dispFilter)
    }



    return apiLoading ? <Loaders /> : errors ? (<SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/>) : (
        <div>
            <Overview 
             data={channelValue}  
             title="Channel" 
             addForm={FunctionAdd} 
             editForm={FunctionEdit} 
             Cards={Cards}
             APICalled={APICalled}
             onFilterClick={onFilterClick}
             dispFilter={dispFilter}
             setDispFilter={setDispFilter}
             setMainFilter={setFilter} 
             filterFinal={filterFinal} 
             setFilterFinal={setFilterFinal} 
             preFilter={preFilter} 
             fx_PreFilter={PreFilter} 
             EnterFilter={EnterFilter} 
             keyFilter="name" 
             setPreFilter={setPreFilter} 
             setCanUpdate={setCanUpdate} 
             canUpdate={canUpdate} 
             filterList={filterList.channel}
             edit={edit} 
             setEdit={setEdit} 
             setEditValue={setEditValue} 
             editValue={editValue}
             loading={apiLoading}
             featureDisable={featureDisable}
            />
        </div>
    )
}

export default PanelChannel

const Cards = ({data, setEdit, edit, setEditValue}) => {

    const [isHovered, setIsHovered] = useState({
        container: false,
        details: false
    })

     const Icon = ({comm}) => {
        switch (comm) {
            case "Telegram":
                return <img loading="lazy" src={TelegramIcon} alt="msg-icon"/>
            case "slack":
                return <img loading="lazy" src={SlackIcon} alt="WiFi"/>
            case "whatsapp":
                return <img loading="lazy" src={WhatsappIcon} alt="GPS off"/>
            case "Email":
                return <img loading="lazy" src={EmailIcon} alt="email"/>
            default:
                break;
        }       
    }

    const btnDetails = (e) => {
        let channel = []
        channel.push(data)
        e.preventDefault(e);
        setEdit(!edit)
        setEditValue(channel)
    }


  return data && (
    <div className="channel-main-container" onMouseEnter={() => setIsHovered((current) => ({...current, container: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, container: false}))}>
        {/* <div className="checkbox-container-channel">
            <input type="checkbox" className="check-type"/>
        </div> */}
        <div className="channel-o-container">
            <section className="channel-icon-container">
                <Icon comm={data.type}/>
            </section>
            <section>
                {data.name}
            </section>
            <div className="onhover-container">
            
                { isHovered.container && (
                    <div className={`${isHovered.details ? "isHovered-button-edits": "button-edits"}`} onClick={e => btnDetails(e)} onMouseEnter={() => setIsHovered((current) => ({...current, details: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, details: false}))}>
                        <img src={ChevronLightIcon} alt="chev-light"/>
                    </div>
                ) }
            </div>
        </div>

        <style jsx>
        {
          `
            .channel-main-container{
                display: grid;
                height: 56px;
                margin-bottom: 4px;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
                gap: 0px 0px;
                grid-template-areas: ". .";

            }
            .channel-o-container{
                background-color: #FFFFFF;
                border-radius: 4px;
                display: grid;
                align-items: center;
                grid-template-columns: 50px 1fr 43px;
                grid-template-rows: 1fr;
                gap: 0px 0px;
                grid-template-areas:
                  ". . .";            
            }   
            .checkbox-container-channel{
                display: flex;
                align-items: center;
            }
            .onhover-container{
                justify-content: flex-end;
                background-color: #FFFFFF;
                width: 100%;
                height: 100%;
                flex-basis: 4%;
            }
            . check-type{
                margin: 0px 4px 0px 0px;
                height: 18px;
                width: 18px;
                background-color: #509D91;
            }  
            .channel-icon-container{
                display: flex;
                align-items:center;
                justify-content: center;
            } 
            .button-edits{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                border-radius: 0 4px 4px 0;
                background-color: #509D91;
                cursor: pointer;
                margin: 0;
            }
            .isHovered-button-edits{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                border-radius: 0 4px 4px 0;
                background-color: #498f84;
                cursor: pointer;
                margin: 0;
                font-weight: 600;
                box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
            }
        
          `
        }
        </style>
    </div>
  ) 
}