import React, {useEffect, useState} from 'react'


import DarkCloseIcon from '../../Image/close.svg'

import 'react-base-table/styles.css'
import UserVerifiedIcon from '../../Image/userVerified.svg'
import UserNonVerifiedIcon from '../../Image/userNonVerified.svg'


const UserList = ({data, setUserListing, canEdit, featureDisable, types}) => {
    const removeList = (e, i) => {
        e.preventDefault();
        let clone = [...data]
        clone.splice(i, 1)
        setUserListing(clone)
    }

    return (
        <div className="user-list-container">
            <div className="header-container">
                <header className="table-header">First Name</header>
                <header className="table-header">Last Name</header>
                {
                    types !== "Email" ? <header className="table-header">Contact No</header> : <header className="table-header">Email</header>
                }
                <header className="table-header-verified">Verified</header>

            </div>
            <div className="divider-line"></div>
            {data && data.length > 0 ? data.map((x,i, arr) => {
                return(
                    
                            <div className="header-container">
                                <p className="data-table">{x.firstName}</p>
                                <p className="data-table">{x.lastName}</p>
                                {
                                    types !== "Email" ? (
                                        <p className="data-table">{x?.telegram?.tgContactCode ? `${x.telegram.tgContactCode}${x.telegram.tgContactNo}` :  x?.telegram?.contactCode && `${x.telegram.contactCode}${x.telegram.contactNo}`}</p>
                                    ) : (
                                        <p className="data-table-email">{x && x.email }</p>
                                    )
                                } 
                                <div className="third-column">
                                    {
                                            // x && x.telegram && x.telegram.contactVerified && x.telegram.contactVerified ? <img lazy="loading" src={UserVerifiedIcon} alt="check-icon"/> : <img lazy="loading" src={UserNonVerifiedIcon} alt="close-icon"/>
                                            x.verified && x.verified ? <img lazy="loading" src={UserVerifiedIcon} alt="check-icon"/> : <img lazy="loading" src={UserNonVerifiedIcon} alt="close-icon"/>
                                    }
                                    </div> 
                                {featureDisable ? null : <img lazy="loading" src={DarkCloseIcon} alt="close-icon" style={{cursor:"pointer"}} onClick={(e) => removeList(e, i)}/>}
                            </div>

                    

                )
            }) : null}
            <style>
                {
                    `.user-list-container{
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: 1fr 1px 1fr;
                        gap: 8px 0px;
                        grid-template-areas:
                          "."
                          "."
                          ".";
                        margin: 8px 0px 0px 0px;
                        height: 100%;
                    }
                    .header-container{
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr 25px;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". . . . .";
                        margin: 0px 0px 0px 0px;
                    }
                    .table-header{
                        color: #939598;
                        font-size: 13px;
                        letter-spacing: 0;
                        line-height: 24px;
                    }
                    .table-header-verified{
                        color: #939598;
                        font-size: 13px;
                        letter-spacing: 0;
                        line-height: 24px;
                        display: flex;
                        justify-content: center;
                    }
                    .main-container{
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr 25px;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". . . . .";
                        margin: 0px 0px 8px 0px;
                    }
                    .data-table{
                        margin: 0px;
                        color: #333333;
                        font-size: 13px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 24px;
                    }
                    .data-table-email{
                        margin: 0px;
                        color: #333333;
                        font-size: 13px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 24px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .white-box{
                        height: 100%;
                        display: grid;
                        background-color: #FFF;
                        overflow: auto;
                    }
                    .third-column{
                        display: flex;
                        justify-content: center;
                    }
                    .divider-line{
                        border: 1px solid #DDDDDD;
                        margin: 0px 0px 8px 0px;
                    }
                    .divider-line-user{
                        border: 1px solid #DDDDDD;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default UserList
