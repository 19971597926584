import React, { useState, useEffect } from 'react'

//library
import { useQuery } from "@apollo/react-hooks";

//component
import {GET_FIRMWARE} from "../../config/config-graphql/Graphql";

const Assembly = ({staticFirmware={}, data, setAssembly, index, requiredLabel, fieldList, isHovered, setIsHovered}) => {
    const [firmwareOptions, setFirmwareOptions] = useState([])
    const [firmwareValue, setFirmwareValue] = useState(data.firmware ? data.firmware.uuid : "")
    const filter = data.assembly ? { board_class: { type: "IS", value: [data.assembly.board_class] } } : {}
    const {data: dataFirmware, loading: loadFirmware, error: errFirmware} = useQuery(GET_FIRMWARE, {variables: { filter }});

    // console.log(firmwareValue)

    useEffect(() => {
        if(loadFirmware){} //console.log("Loading Firmware")
        else if(errFirmware){} //console.log("An error occured while loading firmware")
        else if(dataFirmware){
            const firmware = dataFirmware.get_firmwares.map((x,i) => {return x})
            setFirmwareOptions(firmware);
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadFirmware])

    const settingValue = (key, value) => {
        setAssembly(index, key, value)
    }

    const handleFirmware = (value) => {
        // console.log(value)
        if(data.firmware){
            if(value === "-"){
                setAssembly(index, "firmware_add", {uuid: ""})
                setFirmwareValue(value)
            }else{
                setAssembly(index, "firmware_add", {uuid: value})
                setFirmwareValue(value)
            }
        }
        else{
            if(value === "-"){
                setFirmwareValue(value)
                setAssembly(index, "firmware_add", { uuid: "" })
            }else if(value){
                setFirmwareValue(value)
                setAssembly(index, "firmware_add", { uuid: value })
            }
            else setFirmwareValue("")
        }
    }

    return  (
        <div>
            <div className="assembly-container">
                <div className="details-assembly asse-text">{data.assembly && data.assembly.board_class ? data.assembly.board_class : ""} • {data.assembly ? data.assembly.assembly_id : ""} </div>
                <div className="details-assembly" >
                    <div className="input-input">Board ID<p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.board_id.required)}</p> </div> 
                    <input type="text" className={`${isHovered.board_id ? "isHovered-border" : "text-box"}`} placeholder="-" name="board_id" value={data.board_id} onChange={(e) => settingValue(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, board_id: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, board_id: false}))}/>
                </div>
                <div className="details-assembly" >
                    <div className="input-input">Firmware</div> 
                    <select className={`${isHovered.firmware ? "isHovered-selection" : "selection-board"}`}  value={firmwareValue} name="firmware" onChange={(e) => handleFirmware(e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, firmware: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, firmware: false}))}>
                        <option>-</option>
                        {
                            firmwareOptions && firmwareOptions.map((x, i) => {
                                return <option value={x.uuid}>{x.version}</option>
                            })
                        }
                    </select>
                </div>
            </div>
             
            <style jsx>
                {
                    ` 
                    .assembly-container{
                        box-sizing: border-box;
                        border: 1px solid #939598;
                        border-radius: 4px;
                        padding: 16px;
                        margin: 16px 0px 0px 0px;
                    }
                    .details-assembly{
                        display: flex;
                        align-items: center;
                        padding: 0px 0px 8px 0px;
                    }
                    .input-input{
                        width: 133px;
                    }
                    .selection-board{
                        border-style:none;
                        background-color: #FFF;
                        height: 24px;
                        width: 30%;
                        margin-bottom: 8px;
                        outline: none;
                    }
                    .text-box{
                        border-style: none;
                        height: 24px;
                        outline: none;
                    }
                    .asse-text{
                        font-size: 16px;
                        font-weight: 600;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default Assembly
