import React, { useReducer, createContext } from "react";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { Base64 } from 'js-base64';

const initialState = { user: null };

/**
 * **If cookies jwtToken exist, decode the token (to get the expiry date)**
 */
if (Cookies.get("jwtToken")) {
    const decodedToken = jwtDecode(Cookies.get("jwtToken")) 
    if (decodedToken.exp < Date.now()) {
        Cookies.remove("jwtToken");
        Cookies.remove("agency-id");
        Cookies.remove("role");
        Cookies.remove("SessionID");
        Cookies.remove("agency-name");
        Cookies.remove("agencyUUID");
        Cookies.remove("_id");
        Cookies.remove("privileges");

    } else {
        initialState.user = decodedToken; 
    }
}

const AuthContext = createContext({
    user: null,
    login: (userData) => {},
    logout: () => {},
    reConnect: (userData) => {},
    checktoken: (token) => {},
    Encode: (str) => {}
});

function authReducer(state, action) {
    switch (action.type) {
        case "LOGIN":
        return {
            ...state,
            user: action.payload,
            userData: action.payload
        };
        case "LOGOUT":
        return {
            ...state,
            user: null,
            userData: null
        };
        case "RECONNECT":
        return{
            ...state,
            user: action.payload
        }
        default:
        return state;
    }
}

function AuthProvider(props) {
    const [state, dispatch] = useReducer(authReducer, initialState);
    const option = { expires: 10 };
    const login = (userData, sessionID, privileges) => {  
        if(sessionID){
            // console.log("Context userAuth sessionID", sessionID)
            Cookies.set("_id", userData.user.uuid, option)
            Cookies.set("jwtToken", userData.token, option);
            Cookies.set("agencyUUID", userData.user.privileges[0].agencyUUID, option)
            Cookies.set("agency-id", userData.user.privileges[0].agencyId, option)
            Cookies.set("role", userData.user.privileges[0].role.replace(/_/g, " "), option)
            Cookies.set("agency-name", userData.user.privileges[0].agencyName, option)
            Cookies.set("SessionID", sessionID, option)
           
        }else{
            Cookies.set("_id", userData.user.uuid, option)
            Cookies.set("jwtToken", userData.token, option);
            Cookies.set("agency-id", userData.user.privileges[0].agencyId, option)
            Cookies.set("role", userData.user.privileges[0].role.replace(/_/g, " "), option)
            Cookies.set("agency-name", userData.user.privileges[0].agencyName ?  userData.user.privileges[0].agencyName : "Asia Mobility Technologies Sdn Bhd", option)
        }


        dispatch({
            type: "LOGIN",
            payload: userData,
        });
    }

    const logout = () => {
        // console.log('logout go here woots')
        Cookies.remove("jwtToken");
        Cookies.remove("agency-id");
        Cookies.remove("role");
        Cookies.remove("SessionID")
        Cookies.remove("agency-name")
        Cookies.remove("agencyUUID")
        Cookies.remove("_id")
        Cookies.remove("privileges")
        dispatch({ type: "LOGOUT" });
    }

    const reConnect = (privileges, sessionID) => {
        Cookies.set("agencyUUID", privileges.agencyUUID)
        Cookies.set("agency-id", privileges.agencyId)
        Cookies.set("role", privileges.role.replace(/_/g, " "))
        Cookies.set("agency-name", privileges.agencyName)
        Cookies.set("SessionID", sessionID)
        dispatch({type: "RECONNECT", payload: privileges})
    }

    const Encode = (str) => Base64.encode(str);

    return (
        <AuthContext.Provider
            value={{ user: state.user, login, logout, Encode, reConnect }}
            {...props}
        />
    );
}

export { AuthContext, AuthProvider };
