import React from 'react'

const OtherModel = ({settingFieldValue, sku, fieldList, onUserInput, isBulkEdit}) => {
    return (
        <div className="smart-container">
           <input type="text" className="smart-lock-text" name="id" value={settingFieldValue(isBulkEdit, sku, fieldList.id.name)} onChange={(e) => onUserInput(e)}/>
            <div className="line-SKU"></div>
            <p className="label-text-SKU">SKU ID</p>
        </div>
    )
}

export default OtherModel
