import React from 'react'

const DeviceInformation = ({requiredLabel, isHovered, settingFieldValue, onUserInput, displayed, device, setIsHovered, valSku, handleChangeSKU, loadSKU, dataSKU, onChangePage, valAgency, isBulkEdit, fieldList}) => {
    return (
        <div className="device-information-container">
        <div className="device-title">Device Information</div>
        <div className="device-input-admin">
            <div className="device-container">
                <div className="device-information">
                    <div className="details-input" >
                        <div className="input-input">Serial Number<p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.sn.required)}</p> </div> 
                        <input type="text" className={`${isHovered.serialNumber ? "isHovered-border" : "text-box"}`} placeholder="-" name="sn" value={settingFieldValue(isBulkEdit, device, fieldList.sn.name)} onChange={(e) => onUserInput(e)} disabled={displayed ? device[0].device_type === "Smartlocks" ? true : false : false} onMouseEnter={() => setIsHovered((current) => ({...current, serialNumber: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, serialNumber: false}))}/>
                    </div>
                    <div className="details-input" >
                        <div className="input-input">SKU<p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.SKU.required)}</p></div> 
                        <select className={`${isHovered.SKU ? "isHovered-selection" : "selection-board"}`}  value={valSku.uuid} name="uuid" onChange={(e) => handleChangeSKU(e.target.name, e.target.value)} disabled={displayed ? device[0].device_type !== "Smartlocks" ? true : false : false} onMouseEnter={() => setIsHovered((current) => ({...current, SKU: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, SKU: false}))}>
                            <option value="">-</option>
                            {
                                !loadSKU && dataSKU.get_skus !== null ? dataSKU.get_skus.sku.map((x,i) => {
                                    return <option value={x.uuid}>{x.ID}</option>
                                }) : null
                            }
                        </select>
                    </div>
                    {
                        displayed ? device[0].device_type !== "Smartlocks" ? (
                            <div className="details-input">
                                <div className="input-input">Agency </div>
                                <a className="link" onClick={onChangePage}>{valAgency.name}</a>
                            </div> 
                        )  : null :null
                    }

                    {
                        displayed ? device[0].device_type !== "Smartlocks" ? 
                        <div className="details-input" >
                            <div className="input-input">Description </div> 
                            <input type="text" className={`${isHovered.description ? "isHovered-border" : "text-box"}`} placeholder="-" name="description" value={settingFieldValue(isBulkEdit, device, fieldList.description.name)} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, description: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, description: false}))}/>
                        </div> : null : <div className="details-input" onMouseEnter={() => setIsHovered((current) => ({...current, description: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, description: false}))}>
                        <div className="input-input">Description </div> 
                            <input type="text" className={`${isHovered.description ? "isHovered-border" : "text-box"}`} placeholder="-" name="description" value={settingFieldValue(isBulkEdit, device, fieldList.description.name)} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, description: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, description: false}))}/>
                        </div>
                    }

                 </div>
            </div>
            {/* <div className="device-details"></div> */}
        </div>
        <style>
            {
                `.device-information-container{               
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    padding: 16px;
                    margin: 0px 0px 24px 0px;
                }
                `
            }
        </style>
    </div>
    )
}

export default DeviceInformation
