import React, {useState} from 'react'

const Telemetics = ({displayed, sku, requiredLabel,fieldList, settingFieldValue, isBulkEdit, onUserInput}) => {
    const [isHovered, setIsHovered] = useState({
        manufacturer: false,
        product: false,
        version: false,
        revision: false,
        project_name: false
    })

    // console.log(isHovered)
    return (
        <div className="telemetics-container">
            <div className="sku-list">
                <div className="list">
                    <div className="label-sku">{displayed ? sku[0].manufacturer : sku.manufacturer}</div>
                    <div className="line-horizontal"></div>
                    <div className="ddl-title">Manufacturer</div>                                  
                </div>
                <div className="list">
                    <div className="label-sku">{displayed ? sku[0].product : sku.product}</div>
                    <div className="line-horizontal"></div>
                    <div className="ddl-title">Product</div>
                </div>
                <div className="list">
                    <div className="label-sku">{displayed ? sku[0].version: sku.version}</div>
                    <div className="line-horizontal"></div>
                    <div className="ddl-title">Version</div>
                </div>
                <div className="list">
                    <div className="label-sku">{displayed ? sku[0].revision : sku.revision}</div>
                    <div className="line-horizontal"></div>
                    <div className="ddl-title">Revision</div>
                </div>
            </div>
            <div className="input-container">
                <div className="details-inputs" >
                    <div className="input-inputs">Manufacturer <p style={{color:"red", margin:"0px 16px 0px 0px"}}>{requiredLabel(fieldList.manufacturer.required)}</p> </div> 
                    <input type="text" className={`${isHovered.manufacturer ? "isHovered-border" : "text-box"}`} placeholder="-" name="manufacturer" value={settingFieldValue(isBulkEdit, sku, fieldList.manufacturer.name)} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, manufacturer: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, manufacturer: false}))}/>
                </div>
                <div className="details-inputs" >
                    <div className="input-inputs">Product <p style={{color:"red", margin:"0px 16px 0px 0px"}}>{requiredLabel(fieldList.product.required)}</p></div> 
                    <input type="text" className={`${isHovered.product ? "isHovered-border" : "text-box"}`} placeholder="-" name="product" value={settingFieldValue(isBulkEdit, sku, fieldList.product.name)} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, product: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, product: false}))}/>
                </div>
                <div className="details-inputs" >
                    <div className="input-inputs">Version <p style={{color:"red", margin:"0px 16px 0px 0px"}}>{requiredLabel(fieldList.version.required)}</p></div> 
                    <input type="text" className={`${isHovered.version ? "isHovered-border" : "text-box"}`} placeholder="-" name="version" value={settingFieldValue(isBulkEdit, sku, fieldList.version.name)} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, version: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, version: false}))}/>
                </div>
                <div className="details-inputs" >
                    <div className="input-inputs">Revision <p style={{color:"red", margin:"0px 16px 0px 0px"}}>{requiredLabel(fieldList.revision.required)}</p></div> 
                    <input type="text" className={`${isHovered.revision ? "isHovered-border" : "text-box"}`} placeholder="-" name="revision" value={settingFieldValue(isBulkEdit, sku, fieldList.revision.name)} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, revision: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, revision: false}))}/>
                </div>
                <div className="details-inputs" >
                    <div className="input-inputs">Project Name <p style={{color:"red", margin:"0px 16px 0px 0px"}}>{requiredLabel(fieldList.project_name.required)}</p></div> 
                    <input type="text" className={`${isHovered.project_name ? "isHovered-border" : "text-box"}`} placeholder="-" name="projectName" value={settingFieldValue(isBulkEdit, sku, fieldList.project_name.name)} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, project_name: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, project_name: false}))}/>
                </div>
            </div>
        </div>

    )
}

export default Telemetics
