import React, { useState, useEffect } from 'react'

//libary
import { useMutation } from "@apollo/react-hooks";

//images
import NavigationIcon from "../../Image/chevron_right.svg"
import SaveCard from '../../component/component-save/SaveCard'

//component
import Form from "./Form"
import { onUserAdd, AllMandatoryFilled  } from "../../config/config-function/function";
import { ASSET_GROUP_REDUCER } from "../../config/config-reducer/reducer";
import { ADD_ASSET_GROUP, GET_ASSETGROUPS } from "../../config/config-graphql/Graphql"
import {requiredField} from '../../config/config-table/table'
import SnackBar from '../../config/config-snackbar/SnackBar'

const AddForm = ({ title, fx_back, setIsHoveredO, setOpenSnackbar, setIsAdd, setMandatoryfield, mandatoryField, setSnOpen, setMessage, setSeverity }) => {
    const [values, setValues] = useState({});
    const [addVariables, setAddVariables] = useState({});
    const [canAdd, setCanAdd] = useState(false) //only can add once all mandatory field filled
    const [displayed, setDisplayed] = useState(false) //this state determined of we are in add form or edit form
    const [add_asset, setAdd_asset] = useState([])

    //this state are for snackbar props
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("")

    const [isHovered, setIsHovered] = useState({
      button: false
    })
    
    const onUserInput = (e, value) => {
      let input = null 
      if(e){
        input = onUserAdd(e, null, false, onChange, values)
        setAddVariables({ new_asset_group: [ASSET_GROUP_REDUCER(input)] });        
      }else if(value){
        input = onUserAdd(e, value, true, onChange, values)
        setAddVariables({ new_asset_group: [ASSET_GROUP_REDUCER(input)] });   
      }
      const _check = AllMandatoryFilled(requiredField.asset_group, input)
      _check ? setMandatoryfield(true) : setMandatoryfield(false)
    };



    //this to call the onUserInput function
    //add_asset stat
    useEffect(() => {
      if(add_asset){
        onUserInput(null, { add_asset: add_asset.map(x => { return { uuid: x}})})
      }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [add_asset])

    //submit onClick event handling
    //error will display code with error message
    const onSubmit = async (e) => {
      try{
        e.preventDefault();
        // console.log("ADD ASSET GROUP", addVariables)
        const response = await addData(); // call the mutation    
        if(response.errors){ //check if response has error.
          setErrOpen(true)
          setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
          setErrSevere("error")
        }
        else{ //success
          setSnOpen(true)
          setMessage("Record has been successfully added")
          setSeverity("success")
          fx_back();
        }  
      }catch(err){// catch error
        console.log(JSON.stringify(err, null, 2));
        setErrOpen(true)
        setErrMsg(JSON.stringify(err, null, 2))
        setErrSevere("error")
      }
    };


    //ADD_ASSET_GROUP useMutation
    //variables is the schema from onUserInput
    //refetchQueries is a graphQL cache after called the mutation
    const [addData] = useMutation(ADD_ASSET_GROUP, {
        variables: addVariables,
        refetchQueries: () => [
            {
                query: GET_ASSETGROUPS,
                variables: { filter: {} },
            },
        ],
    });

    //this function is for input onChange
    //setCanAdd state is for field requirement boolean.
    const onChange = (e, value) => {
      setCanAdd(true)
      const input = value ? { ...values, ...value }: { ...values, [e.target.name]: e.target.value };
      setValues(input)
    };

    const onClickBack = () => {
      setIsHoveredO({add: false})
      fx_back()
    }

    return (
        <div className="form-container">
              <div className="navigation-title-placeholder">
                <div className="title-add">
                    <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                       <div onClick={() => onClickBack()} style={{cursor: "pointer"}} className="detail-title first-title">
                         {title}
                       </div>
                       <div className="detail-title">
                          <img loading="lazy" src={NavigationIcon} alt="Back"/>
                       </div>                      
                        <div className="detail-title">Add {title}</div>
                </div>
                <SaveCard onSubmit={onSubmit} canAdd={mandatoryField}/>
                {/* <button className={!mandatoryField ? "add-button-disabled" : `${isHovered.button ? "isHovered-add-button": "add-button"}`} onClick={onSubmit} disabled={!mandatoryField} onMouseEnter={() => setIsHovered((current) => ({...current, button: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, button: false}))}>Add</button> */}

            </div>
            <Form asset_group={values} onUserInput={onUserInput} setDisplayed={setDisplayed} displayed={displayed} addAssetList={add_asset} setAddAsset={setAdd_asset} canAdd={canAdd}/>
            
        <style>
          {`
           .title-add{
            display: flex;             
            align-items: center;          
          }
          .form-container{
            margin: 24px;
          }
          .navigation-title-placeholder {
            font-size: 18px;
            font-weight: 545;
            height: 32px;
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
          }
          .details {
            height: 40px;
            // margin-left: 24px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.54);
          }
          .autocomplete {
            display: flex;
            height: 45px;
            // margin-left: 24px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.54);
          }
          .textfield {
            position: relative;
            bottom: 40px;
          }
          .detail-title{
            padding: 8px;
          }
          .first-title{
            font-size: 16px; 
          }
          `}
        </style>
      </div>
    )
}

export default AddForm