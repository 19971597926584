import React, {useState} from 'react'

import { useMutation } from "@apollo/react-hooks";

import NavigationIcon from "../../Image/chevron_right.svg"


import Loaders from "../../component/component-loaders/Loaders"
import SnackBar from '../../config/config-snackbar/SnackBar'
import {UPDATE_USER, GET_USERS, DELETE_USER} from '../../config/config-graphql/Graphql'
import DeleteCard from '../../component/component-deletecard/DeleteCard'
import SaveCard from '../../component/component-save/SaveCard'

const EditForm = ({title, setMainFilter, setIsHoveredO, setFilterFinal, setPreFilter, setFilterValue, fx_back, FunctionForm, query, selected, setCanUpdate, canUpdate, setSnOpen, setMessage, setSeverity, featureDisable}) => {
  const [values, setValues] = useState(selected.map(a => ({...a})));
  const [editVariables, setEditVariables] = useState({});
  const [getVariables, setGetVariables] = useState({});
  const [canAdd, setCanAdd] = useState(false) //only can add once all mandatory field filled
  const [displayed, setDisplayed] = useState(true)

  const [isHovered, setIsHovered] = useState({
    btnSave: false
  })


  //this state are for snackbar props
  const [errOpen, setErrOpen] = useState(false)
  const [errMsg, setErrMsg] = useState("")
  const [errSevere, setErrSevere] = useState("")

  const onChange = (e, i, value) => {
      const _values = [...values];
      if(value){
          let keys = Object.keys(value)
          keys.map(key => _values[i][key] = value[key])
      } else{
          _values[i][e.target.name] = e.target.value;
      }
      setValues(_values);
  };

const onSubmit = async (e) => {
    try{
      e.preventDefault();
      const response = await editData({ variables: editVariables });
      if(response.errors){
        setErrOpen(true)
        setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
        setErrSevere("error")
      }
      else{
        setSnOpen(true)
        setMessage("Record has been successfully updated!")
        setSeverity("success")
        fx_back();
      } 
    }catch(err){
      // console.log(JSON.stringify(err, null, 2));
      setErrOpen(true)
      setErrMsg(JSON.stringify(err.message, null, 2))
      setErrSevere("error")
    }
}

const [editData, { loading: editLoading, error }] = useMutation(UPDATE_USER, {
  refetchQueries: () => [
      {
          query: GET_USERS,
          variables: {filter: {} },
      },
  ],
});


// console.log(JSON.stringify(error, null, 2));


  const onClickBack = () => {
    setFilterValue({})
    setPreFilter({})
    setFilterFinal({})  
    setMainFilter({}) 
    setIsHoveredO({add: false}) 
    fx_back()
  }

  if (editLoading) return <div style={{position: "relative", right:"2px", top: "40px"}}><Loaders /></div>
    return (
        <div className="form-container">
        <div className="navigation-title-placeholder">
            <div className="title-add">
                   <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                   <div onClick={() => onClickBack()} style={{cursor: "pointer"}} className="detail-title first-title">
                     {title}
                   </div>
                   <div className="detail-title">
                      <img loading="lazy" src={NavigationIcon} alt="Back"/>
                   </div>                      
                    <div className="detail-title">Edit {title}</div>
            </div>
            <div className="button-delete-save-container">
              {featureDisable ? null : <DeleteCard 
                module="user" 
                query={{getQuery: GET_USERS, deleteQuery: DELETE_USER}} 
                uuid={values[0].uuid} 
                agency_id={values[0].privileges.agencyID}
                schema_uuid="email" 
                fx_back={fx_back} 
                suspend_agency_query="" 
                active={false}
                canDelete={!values[0].masterUser}
                setSnOpen={setSnOpen} 
                setMessage={setMessage}
                setSeverity={setSeverity}
                setErrMsg={setErrMsg} 
                setErrOpen={setErrOpen} 
                setErrSevere={setErrSevere}
              />}
              {featureDisable ? null : <SaveCard onSubmit={onSubmit} canAdd={canAdd}/> }
            </div>
            
        </div>
        <FunctionForm displayed={displayed} values={values} onChange={onChange} setEditVariables={setEditVariables} fx_back={fx_back} setCanAdd={setCanAdd} setCanUpdate={setCanUpdate} canUpdate={canUpdate} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} setErrMsg={setErrMsg} setErrOpen={setErrOpen} setErrSevere={setErrSevere} featureDisable={featureDisable}/>
        
    <style jsx>
      {`
        .title-add{
          display: flex;             
          align-items: center;             
        }
        .form-container{
          margin: 24px;
          display: grid;
        }
        .navigation-title-placeholder {
          font-size: 18px;
          font-weight: 545;
          height: 32px;
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
        }
        .details {
          height: 40px;
          // margin-left: 24px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.54);
        }
        .autocomplete {
          display: flex;
          height: 45px;
          // margin-left: 24px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.54);
        }
        .textfield {
          position: relative;
          bottom: 40px;
        }
        .detail-title{
          padding: 8px;
        }
        .first-title{
          font-size: 16px; 
          padding-left: 0px;
        }
        .button-delete-save-container{
          display: flex;
        }
      `}
    </style>
  </div>
    )
}
export default EditForm
