// agency selector
import React, {useState, useContext} from 'react'
import { useLocation} from "react-router-dom";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { useMediaQuery } from 'react-responsive';

import sidebarPic from '../../Image/login-left-side.png'
import Logo from '../../Image/horizon_logo_login.svg'
import SnackBar from '../../config/config-snackbar/SnackBar'
import {firebaseContext} from '../../config/config-firebase/FirebaseContext'

import axios from 'axios'

import menuConfig from '../../config/config-menu/menu'
import {AuthContext} from '../../config/config-auth/Auth'
import roleConfig from '../../config/config-role/role'

const AgencySelector = ({history, props}) => {
    const isDefault = useMediaQuery({ minWidth: 1025 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    const context = useContext(AuthContext);
    const firebaseConfig = useContext(firebaseContext)
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("")
    const [messages, setMessages] = useState([]);
    const [agencySelect, setAgencySelect] = useState({
        agencyId: ""
    })     
    const location = useLocation()
  
    const privilegesLocation = location.state.login.user.privileges

    // console.log('privilegesLocation', privilegesLocation);
    // console.log('location', location);

    const [success, setSuccess] = useState(false)

    const getDefaultPathBasedOnRole = (role, page) => {
        let path = ""
        //this is to determined the default page based
        outerloop: for(let i=0; i<menuConfig[page].length; i++){    //to loop the menuConfig        
            if(menuConfig[page][i].role.includes(role)){ //check if the parent role same as cookie role and firebaseConfig value is true
                for(let j=0; j<menuConfig[page][i].children.length; j++){ //for loop for children
                    if(menuConfig[page][i].children[j].role.includes(role)){ //check if the children role same as cookie role and firebaseconfig value is true
                        path = menuConfig[page][i].children[j].path
                        
                        break outerloop;   
                    }
                }
            }
        } 

        return path
    }

    const onSubmit = async (e) => {
        try{
            e.preventDefault(); 
            //data for all login state
            const allState = location.state
            
            //reducer for selected privileges
            const selectedPrivileges ={
                token: allState.login.token, 
                user: {
                    uuid: allState.login.user.uuid,
                    firstName: allState.login.user.firstName,
                    lastName: allState.login.user.lastName,
                    privileges: privilegesLocation.filter(x => x.agencyId === agencySelect.agencyId)
                }
            } 

            // console.log('selectedPrivileges', selectedPrivileges)

            const role = selectedPrivileges.user && selectedPrivileges.user.privileges[0].role.replace(/_/g," ") //this to take the fole and replace _ to ""

            // console.log(role);
            if(selectedPrivileges.user.privileges[0].isSuspended === true || selectedPrivileges.user.privileges[0].isSuspended === null && !roleConfig.admin.includes(role) ){ //to check if is Suspended, null or not system admin
                setErrOpen(true)
                setErrMsg("401: Unable to login, agency suspended")
                setErrSevere("error")
            }else{
                if(role){
                    if(roleConfig.admin.includes(role)){
                        context.login(selectedPrivileges, "", allState.login.user.privileges) //store the login response into cookies, in Auth context
                        const path = getDefaultPathBasedOnRole(role, "console") //to get the default path after login success. this go to admin page
                        history.push(`/console/${path}`)
                    }else if(roleConfig.dashboard.includes(role)){
                        if(selectedPrivileges.token){
                            if(role !== "Reports Viewer" && role !== "Land Transport Authority"){ //to check if the role are not report viewer and apad it will go to websocket
                                //generate session ID
                                const postResponse = await axios.post(process.env.REACT_APP_WEBSOCKET_LOGIN, { 
                                    token: selectedPrivileges.token,
                                    agencyID: selectedPrivileges.user.privileges[0].agencyId
                                });    
                                
                                // if sessionID generated it will send the data to context and redirect the page based on the role.
                                if (postResponse.data && postResponse.data.sessionID){                       
                                    let clone = [...messages];
                                    clone.push(postResponse.data ? postResponse.data.message : "An error occured")
                                    setMessages(clone)  
                                    context.login(selectedPrivileges, postResponse.data.sessionID, allState.login.user.privileges, true)  //store the login response with session ID into cookies
                                    const path = getDefaultPathBasedOnRole(role, "dashboard") //to get the default path after login success. this go to dashboard bapge
                                    history.push(`/dashboard/${path}`)
                                }
                                else{
                                    setErrOpen(true)
                                    setErrMsg(`Websocket not connected`)
                                    setErrSevere("error")
                                }
                            }else{ //else only context login
                                context.login(selectedPrivileges, "", allState.login.user.privileges)
                                const path = getDefaultPathBasedOnRole(role, "dashboard") //to get the default path after login success. this go to dashboard bapge
                                history.push(`/dashboard/${path}`)
                            }
                        }
                    }else{
                        history.push("/login")
                    }
                }else{
                    setErrOpen(true)
                    setErrMsg("login unsuccesfull")
                    setErrSevere("error")
                }
            }
            // setSuccess(true)

          }catch(err){
            console.log(JSON.stringify(err, null, 2))
            setErrOpen(true)
            setErrMsg(JSON.stringify(err.message, null, 2))
            setErrSevere("error")
          }
    };

    return (
        <>
        {
            isDefault && (
            <section className="main">
                <div className="content-w3ls left">
                    <div className="text-pic">
                        <div className="image-placeholder">
                            <img loading="lazy" src={sidebarPic} alt="Traffic" className="img-cut"></img>
                        </div>
                        <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                        <div className="left-content">
                            <div className="text-header">
                                <p>{firebaseConfig.getValueByKey("login_header")} </p>
                            </div>
                            <div className="text-bodies">
                                <span>
                                    {firebaseConfig.getValueByKey("login_detail")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-w3ls right">
                    <div className="container">
                        <div className="container-logo">
                            <div className="text-center-logo">
                                <span>
                                    <img loading="lazy" src={Logo} alt="Horizon-logo" className="img-logo" />
                                </span>
                            </div>
                            <div className="header-sign">
                                <p>Select Agency</p>
                            </div>
                            <div>
                                <div className="container-lable-input-f">
                                    <div className="field-group">
                                        <div className="wthree-field">
                                        
                                            <Select style={{width: 569, height: 40, border: "1px solid #999999", borderRadius:"4px"}} placeholder="Select Agency" value={agencySelect.agencyId} onChange={(e) => setAgencySelect((prev) => ({...prev, agencyId:e.target.value}))} disableUnderline displayEmpty>
                                                <MenuItem value="">
                                                    <div style={{padding:"8px", color:"#939598"}}>Select Agency</div>
                                                </MenuItem>
                                                {
                                                    privilegesLocation.map(x => {
                                                        return(
                                                            <MenuItem value={x.agencyId}><div style={{padding:"8px", color:"#939598"}}>{x.agencyName ? x.agencyName : 'Admin Console'}</div></MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                </div>                                
                                <div className="wthree-fields" onClick={(e) => onSubmit(e)}>
                                    <button type="submit" className="btn">
                                        Select
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                <div className="cpr">
                    <span style={{ color: " #939598", fontSize: "14px", fontFamily: "Roboto ,Arial, sans-serif" }}>Copyright © {new Date().getFullYear()} </span> &nbsp;
                    <span style={{ color: "#666666", fontWeight: "bold", fontSize: "14px", fontFamily: "Roboto ,Arial, sans-serif"   }}>
                    Asia Mobility Technologies Sdn Bhd
                    </span>
                    &nbsp;&nbsp;<span style={{ color: " #939598", fontSize: "14px", fontFamily: "Roboto ,Arial, sans-serif"   }}>|</span>
                    &nbsp; &nbsp;
                    <span style={{ color: "#666666", fontWeight: "bold", fontSize: "14px", cursor: "pointer", fontFamily: "Roboto ,Arial, sans-serif"  }} onClick={() => history.push("/privacy_policy")}>
                    {" "}
                    Privacy Policy
                    </span>
                    &nbsp;&nbsp;<span style={{ color: " #939598", fontSize: "14px", fontFamily: "Roboto ,Arial, sans-serif"   }}>|</span>
                    &nbsp;&nbsp;
                    <span style={{ color: "#666666", fontWeight: "bold", fontSize: "14px", cursor:"pointer", fontFamily: "Roboto ,Arial, sans-serif" }} onClick={() => history.push("/terms_of_service")}>
                    Terms of Service
                    </span>
                </div>
                </div>
            </section>
            )
        }
        {
            isMobile && (
            <section className="mainMobile">
                <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                <div className="content-w3ls-mobile">
                    <div className="container-mobile">
                        <div className="container-logo-mobile">
                            <img loading="lazy" src={Logo} alt="Horizon-logo" className="img-logo" />
                            <div className="header-sign-mobile">
                                <p style={{ margin: '0px 0px 16px 0px' }}>Select Agency</p>
                            </div>
                            <div>
                                <div className="container-lable-input-mobile">
                                    <div className="field-group">
                                        <div className="wthree-field">
                                            <Select style={{width: 250, height: 40, border: "1px solid #999999", borderRadius:"4px"}} placeholder="Select Agency" value={agencySelect.agencyId} onChange={(e) => setAgencySelect((prev) => ({...prev, agencyId:e.target.value}))} disableUnderline displayEmpty>
                                                <MenuItem value="">
                                                    <div style={{padding:"8px", color:"#939598"}}>Select Agency</div>
                                                </MenuItem>
                                                {
                                                    privilegesLocation.map(x => {
                                                        return(
                                                            <MenuItem value={x.agencyId}><div style={{padding:"8px", color:"#939598"}}>{x.agencyName}</div></MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                </div>                                
                                <div className="wthree-fields-mobile" style={{ margin: '16px 0px 0px 0px ' }} onClick={(e) => onSubmit(e)}>
                                    <button type="submit" className="btn">
                                        Select
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                <div className="cpr-mobile">
                    <span style={{ color: " #939598", fontSize: "8px", fontFamily: "Roboto ,Arial, sans-serif" }}>Copyright © {new Date().getFullYear()} </span> &nbsp;
                    <span style={{ color: "#666666", fontWeight: "bold", fontSize: "8px", fontFamily: "Roboto ,Arial, sans-serif"   }}>
                    Asia Mobility Technologies Sdn Bhd
                    </span>
                    &nbsp;<span style={{ color: " #939598", fontSize: "8px", fontFamily: "Roboto ,Arial, sans-serif"   }}>|</span>
                    &nbsp;
                    <span style={{ color: "#666666", fontWeight: "bold", fontSize: "8px", cursor: "pointer", fontFamily: "Roboto ,Arial, sans-serif"  }} onClick={() => history.push("/privacy_policy")}>
                    {" "}
                    Privacy Policy
                    </span>
                    &nbsp;<span style={{ color: " #939598", fontSize: "8px", fontFamily: "Roboto ,Arial, sans-serif"   }}>|</span>
                    &nbsp;
                    <span style={{ color: "#666666", fontWeight: "bold", fontSize: "8px", cursor:"pointer", fontFamily: "Roboto ,Arial, sans-serif" }} onClick={() => history.push("/terms_of_service")}>
                    Terms of Service
                    </span>
                </div>
                </div>
            </section>
            )
        }
        {
            isTablet && (
                <section className="mainMobile">
                <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                <div className="content-w3ls-mobile">
                    <div className="container-mobile">
                        <div className="container-logo-mobile">
                            <img loading="lazy" src={Logo} alt="Horizon-logo" className="img-logo" />
                            <div className="header-sign-mobile">
                                <p style={{ margin: '0px 0px 16px 0px' }}>Select Agency</p>
                            </div>
                            <div>
                                <div className="container-lable-input-mobile">
                                    <div className="field-group">
                                        <div className="wthree-field">
                                            <Select style={{width: 250, height: 40, border: "1px solid #999999", borderRadius:"4px"}} placeholder="Select Agency" value={agencySelect.agencyId} onChange={(e) => setAgencySelect((prev) => ({...prev, agencyId:e.target.value}))} disableUnderline displayEmpty>
                                                <MenuItem value="">
                                                    <div style={{padding:"8px", color:"#939598"}}>Select Agency</div>
                                                </MenuItem>
                                                {
                                                    privilegesLocation.map(x => {
                                                        return(
                                                            <MenuItem value={x.agencyId}><div style={{padding:"8px", color:"#939598"}}>{x.agencyName}</div></MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                </div>                                
                                <div className="wthree-fields-mobile" style={{ margin: '16px 0px 0px 0px ' }} onClick={(e) => onSubmit(e)}>
                                    <button type="submit" className="btn">
                                        Select
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                <div className="cpr-mobile">
                    <span style={{ color: " #939598", fontSize: "8px", fontFamily: "Roboto ,Arial, sans-serif" }}>Copyright © {new Date().getFullYear()} </span> &nbsp;
                    <span style={{ color: "#666666", fontWeight: "bold", fontSize: "8px", fontFamily: "Roboto ,Arial, sans-serif"   }}>
                    Asia Mobility Technologies Sdn Bhd
                    </span>
                    &nbsp;<span style={{ color: " #939598", fontSize: "8px", fontFamily: "Roboto ,Arial, sans-serif"   }}>|</span>
                    &nbsp;
                    <span style={{ color: "#666666", fontWeight: "bold", fontSize: "8px", cursor: "pointer", fontFamily: "Roboto ,Arial, sans-serif"  }} onClick={() => history.push("/privacy_policy")}>
                    {" "}
                    Privacy Policy
                    </span>
                    &nbsp;<span style={{ color: " #939598", fontSize: "8px", fontFamily: "Roboto ,Arial, sans-serif"   }}>|</span>
                    &nbsp;
                    <span style={{ color: "#666666", fontWeight: "bold", fontSize: "8px", cursor:"pointer", fontFamily: "Roboto ,Arial, sans-serif" }} onClick={() => history.push("/terms_of_service")}>
                    Terms of Service
                    </span>
                </div>
                </div>
            </section>
            )
        }
            
        <style jsx>
            {`
            .main {
                display: flex;
                flex-direction: row;
                height: 100vh;
                overflow: hidden;
                color: black;
            }
            .mainMobile {
                height: 100vh;
                overflow: hidden;
            }
            ::placeholder {
                color: #939598;
                // padding: 0px 0px 0px 8px;
              }
              
              :-ms-input-placeholder { /* Internet Explorer 10-11 */
               color: #939598;
            //    padding: 0px 0px 0px 8px;
              }
              
              ::-ms-input-placeholder { /* Microsoft Edge */
               color: #939598;
            //    padding: 0px 0px 0px 8px;
              }
            .content-w3ls .left{
                height: 100%;
                position: relative;
            }
            .content-w3ls-mobile{
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 100%;
                width: 100%;
            }
            .container-mobile{
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                padding: 0px 0px 0px 0px;
                height: 100%;
            }
            .checkbox-container{
                display: flex;
                align-items: center;
            }
            .image-placeholder{
                position: relative;
                width: 100%;
                height: 100vh;
                padding-left: 474px;
            }
            .image-placeholder img{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .text-forgot{
                font-size: 13px;
                margin: 0px 0px 16px 0px;
            }
            .text-pic {
                display: flex;
                justify-content: center;
                align-items: center;
                // position: relative;
                height: 100%;
            }

            .left-content{
                // height: 182px;
                // width: 352px;
                position: absolute;
            }

            .text-header {
                text-align:center;
                color: #FFFFFF;
                font-size: 19px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 32px;
                margin: 0px 24px 16px 24px;
            }
            .input-text-email{
                width: 570px;
            }
            .text-bodies {
                text-align: center;
                color: #FFFFFF;
                // height: 57px;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 19px;
                margin: 0px 24px 0px 24px;
            }
            .check-box-container{
                display:flex;
                align-items: center;
            }
            .btn-learn-more{
                 display: flex;
                 justify-content:center;
                 position
                 height: 32px;
                 margin: 17px 0px 0px 0px
            }
            .btn-learn-more .btn-learn{
                text-align: center;
                vertical-align: center;
                width:160px;
                background-color: #FFFFFF;
                margin 0px 0px 0px 0px;
           }
           .learn-more {
                margin: 0px 0px 0px 0px;
                color: #333333;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 31px;
                text-align: center
                
           }
           .container {
                // border-style: solid;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                padding: 0px 0px 0px 0px;
                height: 100%;
                width: 100%;
            }
            .text-center-logo{
                height: 61px;
                width: 210px;
                margin: 0px 0px 11px 0px;
            }
            .container-logo{
                height: 340px;
                // border-style: solid;
                margin: 0px 0px 0px 96px;
            }
            .container-logo-mobile{
                // height: 340px;
                // border-style: solid;
                // margin: 0px 0px 0px 96px;
            }
            .header-sign  {
                margin: 0px 0px 24px 0px;
            }
            .header-sign p {
                color: #3A3A3A;
                font-size: 18px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 24px;
                margin: 0px;
            }
            .header-sign-mobile{
            }
            .container-lable-input-mobile{

            }
            .container-lable-input-f{
                // width: 600px;
                margin: 0px 0px 24px 0px;
            }
            .txtLabel label{
                height: 16px;
                color: #939598;
                font-size: 12px;
                font-weight: 500;
            }
            .field-group .wthree-field input {
                box-sizing: border-box;
                height: 41px;
                border: 1px solid #939598;
                border-radius: 4px;
                background-color: #FFFFFF;
            }
            .checklayout{
                display: flex;
                justify-content: space-between;
                margin: 0px 0px 23px 0px;
            }
            .checklayout .span-me{
                color: #333333;
                font-size: 13px;
                letter-spacing: 0;
                line-height: 19px;
            }
            .checklayout input{
                margin: 0px 8px 0px 0px;
            }

            .wthree-field .btn{
                width: 160px;
                height: 32px;
                background: #333333;
                border: none;
                color: #fff;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 16px;
                text-align: center;
                font-size: 12px;
                cursor: pointer;
            }
            .wthree-fields{
                display: flex;
                justify-content: space-between;
            }
            .wthree-fields .btn{
                height: 32px;
                width: 214px;
                border-radius: 2px;
                background-color: #509D91;
                border: none;
                color: #fff;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 16px;
                text-align: center;
                font-size: 14px;
                cursor: pointer;
            }
            .wthree-fields-mobile {
                display: flex;
                justify-content: space-between;
            }
            .wthree-fields-mobile .btn {
                height: 32px;
                width: 250px;
                border-radius: 2px;
                background-color: #509D91;
                border: none;
                color: #fff;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 16px;
                text-align: center;
                font-size: 14px;
                cursor: pointer;
            }
            .wthree-fields .versioning{
                font-size: 12px;
                color: #939598;
                margin: 0px 0px 0px 0px;
                line-height: 40px;
            } 
            .cpr {
                position: absolute;
                bottom: 0;
                right: 0;
                margin: 0px 28px 16px 0px;              
            }
            .cpr-mobile{
                position: fixed;
                bottom: 0;
                right: 0;
                margin: 0px 8px 4px 0px;   
            }
            `}
        </style>
        </>
    )
}

export default AgencySelector

