import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";

//list of components
import { GET_DEVICES } from "../../config/config-graphql/Graphql";
import { filterList } from "../../config/config-filter/filter"
import Loaders from "../../component/component-loaders/Loaders"
import Overview from "./Overview"
import SnackBar from '../../config/config-snackbar/SnackBar'


const PanelDeviceLive = ({ filter, setFilter, featureDisable }) =>  {
    const [filterFinal, setFilterFinal] = useState({})
    const [viewDetails, setViewDetails] = useState(false)
    const [detailsData, setDetailsData] = useState({})
    const [preFilter, setPreFilter] = useState({})
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")
    const [pageInd, setPageInd] = useState(false)

    const PreFilter = (value) => {
        setPreFilter({ sn: { type: "CONTAINS", value: [ value ] } })
    }

    const EnterFilter =(e) => {
        if(e.key === "Enter"){
            setFilterFinal(preFilter)
        }
    }

    const { data, loading, error } = useQuery(GET_DEVICES, { variables: { filter: filterFinal, type: "All"}});
    // !loading && console.log(data)

    // !loading && console.log(data)

    useEffect(() => {
        if(Object.keys(filter).length > 0){
            setFilterFinal(filter)
        }
    }, [filter])

    useEffect(() => {
        if(error){
            setSnOpen(true)
            setMessage("Error: Unable to retrieve data. Please try again later.")
            setSeverity("error")
        }
    }, [error])

    // !loading && console.log(data)

    useEffect(() => {
  
        if(!loading && data && data.get_devices){
          if(pageInd){
             setViewDetails(!viewDetails)
             setDetailsData(data.get_devices.devices)
          }
        }
        
      }, [loading])

    return loading ? <Loaders /> : error ? (
        <SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/>
    ) : (
        <Overview title="Devices" data={data ? data.get_devices : []} loading={loading} setDetailsData={setDetailsData} detailsData={detailsData} viewDetails={viewDetails} setViewDetails={setViewDetails} setFilterFinal={setFilterFinal} filterFinal={filterFinal} setPageInd={setPageInd} pageInd={pageInd} setFilterFinal={setFilterFinal} filterList={filterList.device_client} preFilter={preFilter} fx_PreFilter={PreFilter} EnterFilter={EnterFilter} setPreFilter={setPreFilter} featureDisable={featureDisable}/>
    )
}

export default PanelDeviceLive

