import React, {useState, useEffect} from 'react'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/react-hooks";
import Cookies from 'js-cookie';


import TelegramIcon from '../../Image/telegram-icon.svg'
import SlackIcon from '../../Image/slack_icon.svg'
import WhatsappIcon from '../../Image/whatsapp_icon.svg'
import devTQrCode from '../../Image/horizon_chatbot_dev_qr.png'
import proTQrCode from '../../Image/horizon_chatbot_prod_qr.png'

import UserSearch from './UserSearch'
import UserList from './UserList'
import tableConfig from "../../config/config-table/table";
import {SEARCH_USER} from "../../config/config-graphql/Graphql";
import { settingFieldValue } from "../../config/config-function/function";
import Notification from './Notification'
import { APIGetUser } from '../../config/config-restAPI/getAPICalled';


const useStyles = makeStyles({
    option: {
      fontSize: 11,
      '& > span': {
        marginRight: 10,
        fontSize: 11,
      },
    },
    underline: {
        "&&&:before": {
          borderBottom: "none"
        },
        "&&:after": {
          borderBottom: "none"
        }
      }
});



const { channel: fieldList } = tableConfig.fieldList;

const Form = ({channels, onUserInput, displayed, canEdit, fx_back, userListing, setUserListing, setTypes, types, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere, loading, featureDisable, notification, setNotification}) => {
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');
    const [displayQR, setDisplayQR] = useState(false) //to display qrCode and user search telegram card
    const [filterSearch, setFilterSearch] = useState("")
    const [loadings, setLoadings] = useState(false);
    const [keySearch, setKeySearch] = useState("")
    const [listUsers, setListUsers] = useState([]);

    const [isHovered, setIsHovered] = useState({
        name: false,
        type: false,
        search: false,
    })

    const isBulkEdit = Array.isArray(channels) && channels.length > 1;

    const { data: search_user, loading: user_loading, error: user_error} = useQuery(SEARCH_USER, {variables:{key: keySearch, channelType: typeof types === "undefined" ? [] : types === "Telegram" ? ["Telegram"] : types === "Email" ? ["Email"] : []}})

    const node_env = process.env.REACT_APP_ENVIRONTMENT //this is to check if the env is dev or prod
    //filter only telegram user
    // let searchTelegramUser = !loading && search_user && search_user.searchUsers && search_user.searchUsers.filter(x => x.telegram)

    useEffect(() => {
        if(displayed ){
            if(channels[0].type === "Telegram"){ //to display grCode and user search telegram card
                setDisplayQR(true)
            }
        }
    }, [loading])

    const APIGetUserCalled = async (chanType, sKey, operation) => {
        try{
            const result = await APIGetUser(token, setLoadings, agencyId, chanType, sKey, operation);
            if (result.data.status === 200) {
                setLoadings(false);
                setListUsers(result.data.data);
            } else {
                setLoadings(false);
            }
        }catch(err){
            setLoadings(false);
        }
    }
    

    const handleUserOpen = (e) => { 
        if(e.target.innerText === "Telegram" || e.target.innerText === "Email"){ //this to display, the qrCode and user search telegram card if select telegram
            let selectValue = e.target.innerText;
            setTypes(selectValue)
            setDisplayQR(true)
            APIGetUserCalled(selectValue, '', 'SEARCH');
        }

        if(e.target.innerText === "-"){ //this if not select telegram, both remove qrCode and user search
            setDisplayQR(false)
            setTypes("")
            APIGetUserCalled('', '', 'SEARCH');
        }
    }

    return (
        <div className="details-container">
            <div>
                <section className="information-container">
                    <p className="channel-title">Channel Information</p>
                    <div className="channel-input">
                        <div className="details-input">
                            <div className="input-input">Name <p style={{color:"red", margin:"0"}}>*</p> </div> 
                            {
                                featureDisable ? <label className="text-box">{settingFieldValue(isBulkEdit, channels, fieldList.name.name)}</label> : <input type="text" className={`${isHovered.name ? "isHovered-border": "text-box"}`} placeholder="-" name="name" value={settingFieldValue(isBulkEdit, channels, fieldList.name.name)} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, name: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, name: false}))}/>
                            }
                            
                        </div>
                        <div className="details-input">
                            <div className="input-input">Type<p style={{color:"red", margin:"0"}}>*</p></div> 
                            <Select style={{width: 200, height: 26, backgroundColor: isHovered.type && "rgba(182, 186, 191, 0.2)", borderRadius: isHovered.type && "4px" }} disableUnderline value={types} disabled={displayed && featureDisable ? true : false} onMouseEnter={() => setIsHovered((current) => ({...current, type: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, type: false}))}>
                                <MenuItem value="" onClick={(e) => handleUserOpen(e)}>-</MenuItem>
                                <MenuItem value="Telegram" onClick={(e) => handleUserOpen(e)}>
                                    <div className="selection-icon">
                                        {/* <img lazy="loading" src={TelegramIcon} alt="msg-icon"/> */}
                                        <p className="selection-text">Telegram</p>
                                    </div>
                                </MenuItem>
                                <MenuItem value="Email" onClick={(e) => handleUserOpen(e)}>
                                    <div className="selection-icon">
                                        <p className="selection-text">Email</p>
                                    </div>
                                </MenuItem>
                            </Select>
                        </div>
                        {
                            displayQR && types !== "Email" &&  <div className="details-input type-space">
                                    <div style={{display:"flex", justifyContent:"center"}}>
                                    <img loading="lazy" src={node_env === "DEVELOPMENT" ? devTQrCode : proTQrCode} alt="qr-code" style={{height: "150px", width:"150px", margin: "0px 8px 0px 0px"}}/>
                                    </div>
                                
                                    <div className="description-container">
                                        <div className="desc-list">
                                            <p style={{margin: "0px"}}>Users will need to register themselves with the chatbot to receive notifications. Here’s how:</p>
                                            <ol>
                                                <li>Scan the QR code on the left to access the Telegram chatbot and enter /start to begin.</li>
                                                <li>Enter /connect to begin the registration process.</li>
                                                <li>Provide your phone number when requested, by tapping on the Send Phone Number button.</li>
                                                <li>Turn on the specific agencies you would like to receive notifications from. You can always change your preference in /settings</li>
                                            </ol>
                                        </div>
                                    </div>
                            </div>
                        }

                    </div>
                </section>
            </div>
            <div>
                {
                    types ? <section className="channel-contact-container">
                        <p className="channel-title">Users</p>
                        <p className="users-details">Search and select a user to include in this communications channel</p>
                        {featureDisable ? null : <UserSearch setUserListing={setUserListing} APIGetUserCalled={APIGetUserCalled} dataTwo={listUsers} types={types} loading={loadings} setFilterSearch={setFilterSearch} userListing={userListing} setKeySearch={setKeySearch} setIsHovered={setIsHovered} isHovered={isHovered}/>}
                        <section>
                            <UserList data={userListing} canEdit={canEdit} setUserListing={setUserListing} featureDisable={featureDisable} types={types}/>
                        </section>
                    </section> : null
                }

                {
                    types === "Email" ? (
                    <section className="notification-email-container">
                        <Notification channels={channels} notification={notification} setNotification={setNotification}/>
                    </section>                     
                    ) : null
                }

            </div>        
            <style>
                {
                    `.details-container{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 24px;
                        grid-template-areas:
                          ". .";
                    }
                    .information-container{
                        padding: 16px;
                        background-color: #FFFFFF;
                        border-radius: 4px;
                        margin: 0px 0px 24px 0px;
                    }
                    .deletion-container{
                        padding: 16px;
                        background-color: #FFFFFF;
                        border-radius: 4px;
                    }
                    .qr-display{
                        display: flex;
                        align-items: center;
                        margin: 8px 0px 0px 0px;
                    }
                    .description-container{

                    }
                    .desc-title{
                        font-size: 15px;
                        font-weight: 600;
                        margin: 0px 0px 4px 0px;
                        color: #939598;
                    }
                    .desc-list{
                        font-size: 13px;
                    }
                    ul li {
                        list-style-type: number;
                    }
                    .channel-contact-container{
                        padding: 16px;
                        background-color: #FFFFFF;
                        border-radius: 4px;
                    }
                    .channel-title{
                        margin: 0px 0px 8px 0px;
                        color: #333333;
                        font-size: 15px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 24px;
                    }
                    .details-input{
                        display: grid;
                        grid-template-columns: 150px 2fr;
                        grid-template-rows: 1fr;
                        align-items: center;
                        grid-template-areas:
                          ". .";
                        overflow: hidden;
                        height: 30px;
                        margin: 0px 0px 8px 0px;
                    }
                    .type-space {
                        margin: 12px 0px 0px 0px;
                        height: 100%;
                    }
                    .input-input{
                        display: flex;
                        // width: 133px;
                    }
                    .text-box{
                        display: flex;
                        align-items: center;
                        border-style: none;
                        height: 24px;
                        outline: none;
                        border-radius: 4px;
                    }
                    .isHovered-border{
                        border: 1px solid #b6babf;
                        height: 24px;
                        border-radius: 4px;
 
                    }
                    .selection-icon{
                        display: flex;
                        align-items: center;
                    }
                    .selection-text{
                        margin: 0px 0px 0px 4px;
                        font-size: 13px;
                    }
                    .btn-delete{
                        width: 70%;
                        background-color: #EA2B1F;
                        color: #FFFFFF;
                        font-weight: 600;
                        margin: 0px;
                    }
                    .div-btn-delete{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 16px 0px 0px 0px;
                    }
                    .users-details{
                        margin: 0px 0px 0px 0px;
                        color: #939598;
                        font-size: 11px;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                    .notification-email-container{
                        padding: 16px;
                        background-color: #FFFFFF;
                        border-radius: 4px;
                        margin: 24px 0px 0px 0px;
                    }
                    .basfair-text{
                        color: #939598;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 24px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default Form
