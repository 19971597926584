import React, { useState, useEffect } from 'react'
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
// import SmartLocks from '../../panel/panel-SKU/OtherModel';

const AssignMany = ({ title, description, staticAssignedList=[], staticAvailableList=[], label, optionList, addList, setAddList, removeList=[], setRemoveList, assignedLabel="Assigned", filterLabel="", filterDropdownOption=[], isSmartLocks, setSLselected, deviceList, featureDisable }) => {

    // console.log("OPTIONLSIT",filterDropdownOption)

    const [dropdownOptions, setDropdownOptions] = useState([])
    const [availableList, setAvailableList] = useState(staticAvailableList)
    const [currentAvailable, setCurrentAvailable] = useState(staticAvailableList)
    const [assignedList, setAssignedList] = useState(staticAssignedList)
    const [currentAssigned, setCurrentAssigned] = useState(staticAssignedList)
    const [removeSelected, setRemoveSelected] = useState([])
    const [addedSelected, setAddedSelected] = useState([])
    // console.log(availableList)

    // console.log("DROPDOWN", dropdownOptions
    
    //to check available list
    useEffect(() => {
        if(availableList.length === 0){
            setAvailableList(staticAvailableList)
            setCurrentAvailable(staticAvailableList)
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staticAvailableList])

    //this is for dropdownlist
    useEffect(() => {
        if(optionList){
            if(filterDropdownOption.length > 0){
                setDropdownOptions(filterDropdownOption)
            }else{
                const optionSet = new Set()
                for (let i = 0; i < optionList.length; i++) {
                    const el = optionList[i];
                    el.option && optionSet.add(el.option)
                }
                const arrSet = Array.from(optionSet)
                setDropdownOptions(arrSet.length > 0 ? arrSet.map(el => { return { value: el.toLowerCase(), label: el }}) : [])  
            }
                       
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionList])

    //for dropdownlist
    const FilterAvailable = (value) => {
        let cloneAvailable = [...availableList]
        if(value === "all"){
            cloneAvailable = [...currentAvailable]
        }else if(value === "none"){
            let filteredNone = optionList.filter(el => !el.option)
            cloneAvailable = filteredNone.map(el => el.value)
        }else{
            let filteredList = optionList.filter(el => el.option && el.option.toLowerCase() === value.toLowerCase())
            cloneAvailable = filteredList.map(el => el.value)
        }
        let filterClone = cloneAvailable.filter(el => currentAvailable.some(x => x === el))
        setAvailableList(filterClone)
    }

    //for dropdownlist
    const FilterAssigned = (value) => {
        let cloneAssigned = [...assignedList]
        if(value === "all"){
            cloneAssigned = [...currentAssigned]
        }else if(value === "none"){
            let filteredNone = optionList.filter(el => !el.option)
            cloneAssigned = filteredNone.map(el => el.value)
        }else{
            let filteredList = optionList.filter(el => el.option && el.option.toLowerCase() === value.toLowerCase())
            cloneAssigned = filteredList.map(el => el.value)
        }
        let filterClone = cloneAssigned.filter(el => currentAssigned.some(x => x === el))
        setAssignedList(filterClone)
    }

    const Assign = (selectedList) => {
            // console.log('selectedList', selectedList);
            const removedList = assignedList.filter(x => !selectedList.includes(x))
            const addedList = selectedList.filter(x => !assignedList.includes(x))
    
            if(removedList.length > 0){//Remove
                let _removeList = [ ...removeList ]
                // console.log(_removeList)
                let removeFromAddList = []
                for (let i = 0; i < removedList.length; i++) {
                    const el = removedList[i];
                    //if remove from addList
                    if(addList.includes(el)){
                        removeFromAddList.push(el)
                    }
    
                    //if not in addList yet and was in static assigned list (currently have relationship in DB)
                    if(staticAssignedList.includes(el)){
                        _removeList.push(el)
                    }
                }
                setRemoveList && setRemoveList(_removeList)
                setRemoveList && setRemoveSelected(_removeList);
                let filterAdd = addList.filter(x => !removeFromAddList.includes(x))
                setAddList(filterAdd)
                setAddedSelected(filterAdd)
            }
            
            if(addedList.length > 0){//Add
                let _addList = [...addList]
                let removeFromRemoveList = []
                for (let i = 0; i < addedList.length; i++) {
                    const el = addedList[i];
                    //if remove from removeList
                    if(removeList.includes(el)){
                        removeFromRemoveList.push(el)
                    }
    
                    //if not in static assign list (don't have relationship in DB yet)
                    if(!staticAssignedList.includes(el)){
                        _addList.push(el)
                    }
                }
                setAddList(_addList)
                setAddedSelected(_addList);
                let filterRemove = removeList.filter(x => !removeFromRemoveList.includes(x))
                setRemoveList && setRemoveList(filterRemove)
                setRemoveList && setRemoveSelected(filterRemove) // this for count
            }
    
            setAssignedList(selectedList)
            setCurrentAssigned(selectedList)
            const notSelected = optionList.filter(el => !selectedList.includes(el.value))
            setCurrentAvailable(notSelected.map(x => x.value))
            setAvailableList(notSelected.map(x => x.value))
            if(isSmartLocks){
                if(removedList.length === 0){
                    const filter = optionList.filter(x => selectedList.includes(x.value))
                    setSLselected(filter)
                }

            }
    }



    // console.log("smartlock Assign", assignedList)

    //for dropdownlist
    const getTotalOption = (isAvailable, type) => {
        if(type === "none"){
            let filteredNone = optionList.filter(el => !el.option)
            if(isAvailable){
                let noneAvailable = filteredNone.filter(el => currentAvailable.some(x => x === el.value))
                return noneAvailable.length;
            } else{
                let noneAssigned = filteredNone.filter(el => currentAssigned.some(x => x === el.value))
                return noneAssigned.length;
            }
        }else{
            let filteredList = optionList.filter(el => el.option && el.option.toLowerCase() === type.toLowerCase())
            if(isAvailable){
                let filteredAvailable = filteredList.filter(el => currentAvailable.some(x => x === el.value))
                return filteredAvailable.length;
            } else{
                let filteredAssigned = filteredList.filter(el => currentAssigned.some(x => x === el.value))
                return filteredAssigned.length;
            }
        }
    }

    // console.log('currentAssign', currentAssigned);

    
    return (
        <div className="assign-many-container" style={isSmartLocks ? {padding: "0px"} : {padding: "16px"}}>
            {
                !isSmartLocks && (
                    <div className="assign-info-container">
                        <div className="title">{title}</div>
                        <div className="title-detail">{description}</div>
                    </div>
                )
            }

                <div className="dropdown-filter-container">
                    <div className="dropdown-available">
                        <div className="flex-marginbottom">Available ({currentAvailable.length})</div>
                       {
                         !isSmartLocks && <select className="selection" onChange={(e) => FilterAvailable(e.target.value)}>
                                <option value="all">All {filterLabel} ({ currentAvailable.length })</option>
                                {
                                    dropdownOptions.map(el => (<option value={el.value}>{el.label} ({ getTotalOption(true, el.value)})</option>))
                                }
                                <option value="none">Uncategorized ({ getTotalOption(true, "none")})</option>
                            </select>
                       } 
                    </div>
                    <div className="dropdown-assigned">
                        <div className="flex-marginbottom">
                            {assignedLabel} ({currentAssigned.length}) 
                            <TotalAssigned addList={addList} removeList={removeList} label={label} removeSelected={removeSelected} addedSelected={addedSelected} />
                        </div>
                        {
                           !isSmartLocks && <select className="selection" onChange={(e) => FilterAssigned(e.target.value)}>
                                <option value="all">All {filterLabel} ({ currentAssigned.length })</option>
                                {
                                    dropdownOptions.length > 0 && dropdownOptions.map(el => (<option value={el.value}>{el.label} ({ getTotalOption(false, el.value)})</option>))
                                }
                                {
                                    dropdownOptions.length > 0 && <option value="none">Uncategorized ({ getTotalOption(false, "none")})</option>
                                }
                            </select>
                        }
                    </div>
                </div>
            <div className={!isSmartLocks && "dual-list-container"}>
                <DualListBox
                    canFilter
                    options={optionList}
                    selected={assignedList}
                    available={availableList}
                    onChange={(e) => Assign(e)}
                    placeholder="Search"
                    icons={{ moveLeft: '<', moveAllLeft: '<<', moveRight: '>', moveAllRight: '>>' }}
                    disabled={featureDisable ? true : false}
                />
            </div>
            <style jsx>
                {
                    `
                    .assign-many-container{
                        border-radius: 4px;
                        background-color: #FFFFFF;
                    }
                    .title{
                        margin: 0px 0px 4px 0px;
                        height: 21px;
                        color: #333333;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 21px;
                    }
                    .title-detail{
                        margin: 
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                    .dropdown-filter-container{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 1% 0px;
                        grid-template-areas: ". .";
                    }
                    .dropdown-available{
                        margin-right: 27px;
                    }
                    .dropdown-assigned{
                        margin-left: 27px;
                    }
                    .flex-marginbottom{
                        display: flex;
                        margin: 0px 0px 8px 0px;
                    }
                    .selection{
                        box-sizing: border-box;
                        height: 32px;
                        width: 372px;
                        border: 1px solid #939598;
                        border-radius: 4px;
                        background-color: #FFFFFF;
                    }
                    .dual-list-container{
                        margin: 16px 0px 0px 0px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default AssignMany

const TotalAssigned = ({ label, removeSelected, addedSelected }) => {
    return (
        <>
        {
            addedSelected && addedSelected.length > 0 && (
                <div className="total total-add">
                    + {addedSelected.length} {label}{addedSelected.length > 1 && "s"}
                </div>
            )
        } 
        {
            addedSelected && addedSelected.length > 0 && removeSelected && removeSelected.length > 0 ? "  |  " : ""
        } 
        {
            removeSelected && removeSelected.length > 0 && (
                <div className="total total-remove">
                    - {removeSelected.length} {label}{removeSelected.length > 1 && "s"}
                </div>
            )
        }
        <style jsx>
            {
                `
                .total{
                    font-size: 14ppx;
                    line-height: 19px;
                    margin: 0 8px;
                }
                .total-add{
                    color: #286551;
                }
                .total-remove{
                    color: #EA2B1F;
                }
                `
            }
        </style>
        </>
    )
}