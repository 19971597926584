import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from "@apollo/react-hooks";



import {DELETE_DRIVER, GET_DRIVER} from "../../config/config-graphql/Graphql";
import { settingFieldValue } from "../../config/config-function/function";

import DeleteCard from '../../component/component-deletecard/DeleteCard'
import DriverInformation from './DriverInformation'
import tableConfig from "../../config/config-table/table";



const { driver: fieldList } = tableConfig.fieldList;


const Form = ({driver, onUserInput, displayed, fx_back, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere, loading, featureDisable}) => {
    const [filterSearch, setFilterSearch] = useState("")
    const [keySearch, setKeySearch] = useState("")

    const isBulkEdit = Array.isArray(driver) && driver.length > 1;

    const [isHovered, setIsHovered] = useState({
        firstName: false,
        lastName: false,
        driveId: false,
    })

    // const isBulkEdit = Array.isArray(channels) && channels.length > 1;

    return (
        <div className="details-container">
            <div>
                <section className="information-container">
                    <DriverInformation featureDisable={featureDisable} isHovered={isHovered} setIsHovered={setIsHovered} driver={driver} onUserInput={onUserInput} isBulkEdit={isBulkEdit} fieldList={fieldList} settingFieldValue={settingFieldValue}/>
                </section>

            </div> 
            <div>
            {
                    featureDisable && displayed ? 
                    <section className="deletion-container">
                        <DeleteCard 
                            title="Delete" 
                            module="driver" 
                            details="" 
                            query={{getQuery: GET_DRIVER, deleteQuery: DELETE_DRIVER}} 
                            uuid={driver[0].uuid} 
                            agency_id=""
                            schema_uuid="driverId" 
                            fx_back={fx_back} 
                            suspend_agency_query="" 
                            active={false}
                            canDelete={true}
                            setSnOpen={setSnOpen} 
                            setMessage={setMessage}
                            setSeverity={setSeverity}
                            setErrMsg={setErrMsg} 
                            setErrOpen={setErrOpen} 
                            setErrSevere={setErrSevere}
                        />
                    </section> : null
                    // displayed ? 
                    // <DeleteDeviceCard /> : null
                }
            </div>       
            <style>
                {
                    `.details-container{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 24px;
                        grid-template-areas:
                          ". .";
                    }
                    .information-container{
                        padding: 16px;
                        background-color: #FFFFFF;
                        border-radius: 4px;
                        margin: 0px 0px 24px 0px;
                    }
                    .deletion-container{
                        padding: 16px;
                        background-color: #FFFFFF;
                        border-radius: 4px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default Form
