import React, {useState, useEffect} from 'react'

//component
import Form from './Form'
import { onUserAdd, AllMandatoryFilled } from "../../config/config-function/function";
import {requiredField} from '../../config/config-table/table'
import { SKU_REDUCER } from "../../config/config-reducer/reducer";

const Add = ({ values, onChange, setAddVariables, displayed, query, fx_back, setCanUpdate, canUpdate }) => {
    const [assemblly, setAssemblly] = useState([])
    const [feature, setFeature] = useState([])
    const [featureAdd, setFeatureAdd] = useState([])
    const [featureRemove, setFeatureRemove] = useState([])
    const [skuID, setSkuID] = useState("")

    const onUserInput = (e, value, isValue) => {
        let input = onUserAdd(e, value, isValue, onChange, values)
        const _check = AllMandatoryFilled(requiredField.sku, input)

        // if product model teltonika and smartlocks
        if ((input && input.productModel === 'Teltonika') || (input && input.productModel === 'Smart_Lock')) {
            input && input.id ? setCanUpdate(true) : setCanUpdate(false) //this are for the required field
        }    
        else {
            let checkAssemblyId = input && input.assemblies && input.assemblies.length > 0 && input.assemblies.filter(x => x.assemblyId === undefined) //this to check if the assemblyid required
            checkAssemblyId && checkAssemblyId.length === 0 && _check ? setCanUpdate(true) : setCanUpdate(false) //this are for the required field
        }
        
        // console.log({
        //     input, value
        // });
        setAddVariables({ skus: input === null ?  [SKU_REDUCER(values, assemblly)]  : [SKU_REDUCER(input, assemblly)] });
    }


    useEffect(() => {
        onUserInput(null, { id: skuID}, true)
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skuID])

    useEffect(() => {
        onUserInput(null, {features: feature.length > 0 ? feature.map((x) => { return { uuid: x}}) : []}, true)
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feature])


    useEffect(() => {
        onUserInput(null, { assemblies: assemblly.map(x => { return { boardClass: x.boardClass, assemblyId: x.assemblyId, schematicId: x.schematicId}})}, true)
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assemblly])


    return (
        <Form sku={values} onUserInput={onUserInput} displayed={displayed} assemblly={assemblly} setAssemblly={setAssemblly} query={query} setFeatureAdd={setFeatureAdd} featureAdd={featureAdd} featureRemove={featureRemove} setFeatureRemove={setFeatureRemove} setSkuID={setSkuID} skuID={skuID} feature={feature} setFeature={setFeature}/>
    )
}

export default Add
