import React, { useState} from 'react'
import Cookies from 'js-cookie';

//library
import { useMutation } from "@apollo/react-hooks";

//image
import NavigationIcon from "../../Image/chevron_right.svg"

//component
import Loaders from "../../component/component-loaders/Loaders"
import SnackBar from '../../config/config-snackbar/SnackBar'
// import DeleteCard from '../../component/component-deletecard/DeleteCard'
import SaveCard from '../../component/component-save/SaveCard'
import { APIUpdateSKU } from '../../config/config-restAPI/patchAPICalled'
import { APIDeletSKU } from '../../config/config-restAPI/deleteAPICalled'
import DeleteCard from '../../component/component-deletecardRest/DeleteCard'


const EditForm = ({ title, fx_back, selected, Form, query, setCanUpdate, canUpdate, featureList, setSnOpen, setMessage, setSeverity, APICalled}) => {
  // console.log('selected', selected);
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');
    const [values, setValues] = useState(selected.map(a => ({...a})));
    const [editVariables, setEditVariables] = useState({});
    const [getVariables, setGetVariables] = useState({});
    const [displayed] = useState(true)
    const [loading, setLoading] = useState(false);

    //the errOpen, errMsg and errSevere is for snackbar props for error 
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("")

    // const [isHovered, setIsHovered] = useState({
    //   btnDetails: false
    // })
    // const onSubmit = async () => {
      // console.log('editVariable', editVariables);
      // try{
      //   const response = await editData({ variables: editVariables });
      //   if(response.errors){
      //     setErrOpen(true)
      //     setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
      //     setErrSevere("error")
      //   }
      //   else{
      //     setSnOpen(true)
      //     setMessage("Record has been successfully updated")
      //     setSeverity("success")
      //     fx_back();
      //   }
      // }catch(err){
      //   console.log(err)
      //   setErrOpen(true)
      //   setErrMsg(JSON.stringify(err.message, null, 2))
      //   setErrSevere("error")
      // }
    // };

    const onSubmit = async (e) => {
      try{
        e.preventDefault();
        // console.log('editVariables', editVariables)
        const response = await APIUpdateSKU(token, agencyId, editVariables, null, setLoading);
        // console.log('response', response);
        if (response.data.status === 200) {
          setSnOpen(true);
          setMessage("Record has been successfully added");
          setSeverity("success");
          setLoading(false)
          APICalled();
          fx_back();
        } else {
          setLoading(false)
          setErrOpen(true)
          setErrMsg(`${response.data.status}: ${response.data.error.message}`)
          setErrSevere("error")
        } 
  
      }catch(err){
        console.log(err)
        setLoading(false)
        setErrOpen(true)
        setErrMsg(JSON.stringify(err, null, 2))
        setErrSevere("error")
      }
  
    };

    const onDelete = async () => {
      try {
        const reducer = { skuId : [values[0].uuid] }
        const response = await APIDeletSKU(token, agencyId, reducer, setLoading);
        if (response.data.status === 200) {
          setSnOpen(true);
          setMessage("Record has been successfully deleted!");
          setSeverity("success");
          setLoading(false)
          APICalled();
          fx_back();
        } else {
          setLoading(false)
          setErrOpen(true)
          setErrMsg(`${response.data.status}: ${response.data.error.message}`)
          setErrSevere("error")
        } 
  
      } catch(err) {
        console.log(err)
        setLoading(false)
        setErrOpen(true)
        setErrMsg('Error!, Unable to update please try again')
        setErrSevere("error")
      }
    }
  

    const onChange = (e, i, value) => {
        const _values = [...values];
        if(value){
            let keys = Object.keys(value)
            keys.map(key => _values[i][key] = value[key])
        } else{
            _values[i][e.target.name] = e.target.value;
        }
        setValues(_values);
    };

    if (loading) return <div style={{position: "relative", right:"2px", top: "40px"}}><Loaders /></div>

    return (
      
        <div className="form-container">
            <div className="navigation-title-placeholder">
                <div className="title-add">
                       <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                       <div onClick={() => fx_back()} style={{cursor: "pointer"}} className="detail-title first-title">
                         {title}
                       </div>
                       <div className="detail-title">
                          <img loading="lazy" src={NavigationIcon} alt="Back"/>
                       </div>                      
                       <div className="detail-title">Edit {title}</div>
                       <div className="detail-title"></div>
                </div>
                <div className="button-delete-save-container">
                   {/* <DeleteCard 
                      uuid={values[0].uuid} 
                      query={query} 
                      fx_back={fx_back} 
                      schema_uuid="sku_uuid" 
                      canDelete={values[0].device_count > 0 ? false : true}
                      setSnOpen={setSnOpen} 
                      setMessage={setMessage}
                      setSeverity={setSeverity}
                      setErrMsg={setErrMsg} 
                      setErrOpen={setErrOpen} 
                      setErrSevere={setErrSevere}
                   /> */}
                   <DeleteCard
                    canDelete={values[0].device_count > 0 ? false : true}
                    onDelete={onDelete}
                   />
                   <SaveCard onSubmit={onSubmit} canAdd={canUpdate}/>
                </div>
                 
            </div>
            <Form values={values} onChange={onChange} displayed={displayed} setEditVariables={setEditVariables} setGetVariables={setGetVariables} query={query} fx_back={fx_back} setCanUpdate={setCanUpdate} canUpdate={canUpdate} featureList={featureList} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} setErrMsg={setErrMsg} setErrOpen={setErrOpen} setErrSevere={setErrSevere}/>
            
        <style jsx>
          {`
            .title-add{
              display: flex;
              align-items: center;            
            }
            .form-container{
              margin: 24px;
              display: grid;
            }
            .navigation-title-placeholder {
              font-size: 18px;
              font-weight: 545;
              height: 32px;
              margin-bottom: 16px;
              display: flex;
              justify-content: space-between;
            }
            .details {
              height: 40px;
              // margin-left: 24px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.54);
            }
            .autocomplete {
              display: flex;
              height: 45px;
              // margin-left: 24px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.54);
            }
            .textfield {
              position: relative;
              bottom: 40px;
            }
            .detail-title{
              padding: 8px;
            }
            .first-title{
              font-size: 16px; 
              padding-left: 0px;
            }
            .button-delete-save-container{
              display: flex;
            }
          `}
        </style>
      </div>
    )
}

export default EditForm
