import React, { useState, useEffect } from 'react'
import { snakeCase } from "snake-case"

import { useQuery } from "@apollo/react-hooks";
import ChevronLightIcon from "../../Image/chevron_light.svg"
import SKUIcon from "../../Image/sku.svg"

import { GET_FEATURE } from "../../config/config-graphql/Graphql";

const OverviewCard = ({data, featureIcons, fx_view, pageInd, featureList, setPageInd, setFilterFinal}) => {

    const { data: feature, loading: featureLoading } = useQuery(GET_FEATURE, { variables: {} });
    const [isHovered, setIsHovered] = useState({
        container: false,
        details: false
    })
    // const [featureImage, setFeatureImage] = useState([])
    const btnDetails = (e) => {
        e.preventDefault(e);
        setPageInd(true)
        setFilterFinal({uuid: data.uuid})
        // fx_view(data)
    }
    // console.log(featureList)
    return data && (
        <div className="device_card-container" onMouseEnter={() => setIsHovered((current) => ({...current, container: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, container: false}))}>
            <div className="card-details">
                <div className="details">
                    <div className="device-sn-sku">
                        <div className="device-sn">{data.sn}</div>
                        <div className="device-sku-id">
                            <img src={SKUIcon} alt="test"/>
                            <div className="device-sku-ids">{data.SKU ? data.SKU.ID : "" }</div>
                        </div>
                    </div>
                </div>
                <div className="line-box"></div>
                <div className="details">
                    <div className="features-container">
                        <div className="feature-title">Features</div>
                        <div className="feature-list">
                            {
                                featureList.length > 0 && featureList.map(feature => {
                                    try{
                                      return featureIcons.includes(feature.icon) ? <img loading="lazy" className="img-feature_icon" src={`${feature.icon}/icon_${snakeCase(feature.name)}_enabled.svg`} alt="v"/> : <img className="img-feature_icon" src={`${feature.icon}/icon_${snakeCase(feature.name)}_disabled.svg`} alt="x"/>
                                    } catch(err){
                                      console.log(err)
                                    } 
                                })
                                                                 
                            }
                        </div>
                    </div>
                </div>
                <div className="onhover-container">
                    { isHovered.container && (
                        
                        <div className={`${isHovered.details ? "isHovered-button-edits": "button-edits"}`} onClick={e => btnDetails(e)} onMouseEnter={() => setIsHovered((current) => ({...current, details: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, details: false}))}>
                            <img src={ChevronLightIcon} alt="test"/>
                        </div>
                    ) }
                </div>
            </div>
            <style>
            {
                `
                .device_card-container{
                    margin-bottom: 4px;
                }
                .card-details{
                    display: grid;
                    grid-template-columns: minmax(0, 1fr) 10px 2fr 43px;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas: ". . . . . .";
                    height: 64px;
                    width: 100%;
                    background-color: #FFFFFF;
                    border-radius: 4px;
                }
                .details{
                    display: flex;
                    flex-direction: column;
                    flex-basis: 100%;
                    flex: 1;
                    padding: 8px;
                    white-space: nowrap;
                    overflow: hidden;
                }
                .device-sn{
                    color: #333333;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 21px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .device-sku-id{
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .feature-list{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis
                }
                .line-box{
                    height: 46px;
                    margin-right: 8px;
                    margin-top: 8px;
                    border: 1px solid #DDDDDD;
                }
                .feature-title{
                    color: #939598;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 16px;
                }
                .img-feature_icon{
                    margin: 0px 16px 0px 0px;
                }
                .device-sku-ids{
                    margin: 0px 0px 0px 8px;
                    color: #333333;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .onhover-container{
                    // display:flex;
                    justify-content: flex-end;
                    width: 100%;
                    flex-basis: 4%;
                }
                .button-edits{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    border-radius: 0 4px 4px 0;
                    background-color: #509D91;
                    cursor: pointer;
                    margin: 0;
                }
                .isHovered-button-edits{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    border-radius: 0 4px 4px 0;
                    background-color: #498f84;
                    cursor: pointer;
                    margin: 0;
                    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                }
                
                `
            }
            </style>
        </div>
     
    )
}

export default OverviewCard
