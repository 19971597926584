import React, { useState, useEffect } from 'react'

const DeviceAssign = ({staticAvailableList, staticAssignedList, optionList, setRemoveList, removeList=[], addList, setAddList, title, description, isAdd}) => {
    const [availableList, setAvailableList] = useState(staticAvailableList)
    const [currentAvailable, setCurrentAvailable] = useState(staticAvailableList)
    const [assignedList, setAssignedList] = useState(staticAssignedList)
    const [currentAssigned, setCurrentAssigned] = useState(staticAssignedList)
    const [listSelected, setListSelected] = useState(staticAssignedList[0])

    //to check available list
    useEffect(() => {
        if(availableList.length === 0){
            setAvailableList(staticAvailableList)
            setCurrentAvailable(staticAvailableList)
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staticAvailableList])

    // handle change
    const handleChange = (selected) => {
        setListSelected(selected);
        if(selected === 'noValue'){
            if(!isAdd){
                setRemoveList(assignedList);
                setAddList([]);
            } else {
                setAddList([])
            }
        }else {
            const removedList = assignedList
            const addedList = [selected];

            if(removedList.length > 0) {
                if(assignedList.includes(selected)){
                    setRemoveList([])
                } else {
                    setRemoveList(removedList)
                }
            }

            if(addedList.length > 0){
                if(assignedList.includes(selected)){
                    setAddList([])
                } else {
                    setAddList(addedList);
                }
            }
        }

    }

    return (
        <div className="device-assignment-container">
            <div className="devices-info-container">
                <div className="title">{title}</div>
                <div className="title-detail">{description}</div>
            </div>
            <div className="device-assignment-dropdown-assigned">
                <select className="device-assignment-selection" value={listSelected} onChange={(e) => handleChange(e.target.value)}>
                    <option value="noValue">-</option>
                    {
                        optionList.map(el => (<option value={el.value}>{el.label}</option>))
                    }
                </select>
            </div>
            <style>
                {
                    `.device-assignment-container{
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        padding: 16px;
                    }
                    .devices-assignment-info-container{

                    }
                    .device-assignment-title{
                        margin: 0px 0px 4px 0px;
                        height: 21px;
                        color: #333333;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 21px;
                    }
                    .device-assignment-title-detail{
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                    .device-assignment-dropdown-assigned{

                    }
                    .device-assignment-selection{
                        box-sizing: border-box;
                        height: 32px;
                        width: 372px;
                        border: 1px solid #939598;
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        margin: 8px 0px 0px 0px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default DeviceAssign
