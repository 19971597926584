import React, { useState, memo, useEffect } from "react";
import PropTypes from "prop-types";
import BaseTable, {
  AutoResizer,
  normalizeColumns,
  Column,
  callOrReturn
} from "react-base-table";
import "react-base-table/styles.css";
import styled from "styled-components";

import SelectionCell from "./SelectionCell";

// const Content = styled.div`
// flex: 1 1 auto;
// height: 223px;
// .BaseTable{
//   box-shadow: 0px 0px #FFFFFF;
// }

// .BaseTable__row {
//   /* color: #9ca2b3; */
// }

// .BaseTable__table-main {
//   outline: none;

//   &::-webkit-scrollbar {
//     width: 5px;
//     background-color: #f5f5f5;
//   }

//   &::-webkit-scrollbar-thumb {
//     border-radius: 2px;
//     background-color: #d0d3db;
//   }
// }

// .BaseTable__header-row {
//   background: #fff;
// }

// .BaseTable__header-cell:hover *:not(span) {
//   color: #6899f8;
//   &:before,
//   &:after {
//     background: #6899f8;
//   }
// }

// .BaseTable__header-cell-text {
//   color: #939598;
//   font-size: 0.9em;
//   user-select: none;

//   &:hover {
//     color: #6899f8;
//   }
// }

// .BaseTable__row-cell{
//   font-size: 12px;

// }
// `;

const Empty = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 16px;
`;


const TableSelect = ({
  data,
  children,
  rowKey,
  sortBy,
  headerHeight,
  rowHeight,
  onColumnSort,
  onDoubleClickRow,
  rowClassName,
  columns,
  selectable,
  setCopy,
  copy,
  selectionColumnProps,
  isCopy,
  removeSelection,
  Content
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const handleSelectChange = ({ selected, rowData, rowIndex }) => {
    const rows = [...selectedRowKeys];
    const copyWholeRow = [...copy]
    const key = rowData[rowKey];


    if (selected) {
      if (!rows.includes(key)) {      
        rows.push(key);
        copyWholeRow.push(rowData)
        
        setSelectedRowKeys(rows);
        setCopy(copyWholeRow)
      }
    } else {
      const index = rows.indexOf(key);
      if (index > -1) {
        rows.splice(index, 1);
        copyWholeRow.splice(index, 1)

        setSelectedRowKeys(rows);
        setCopy(copyWholeRow)
      }
    }

    if (selectedRowKeys === undefined) {
      setSelectedRowKeys(selectedRowKeys);
    }
  };

  //when click cancel button for firmware manifest
  useEffect(() => {
      if(!removeSelection){
        setSelectedRowKeys([])
      }
  }, [removeSelection])

  const selectedRowCurrent = ({ rowData, rowIndex }) => {
    const rowClass = rowClassName
      ? callOrReturn(rowClassName, { rowData, rowIndex })
      : "";
    const key = rowData[rowKey];
    return [rowClass, selectedRowKeys.includes(key) && "row-selected"]
      .filter(Boolean)
      .concat(" ");
  };

  let _columns = columns || normalizeColumns(children);

  if (selectable) {
    const selectionColumn = {
      width: 40,
      flexShrink: 0,
      resizable: false,
      frozen: Column.FrozenDirection.LEFT,
      rowKey,
      align: "right",
      cellRenderer: SelectionCell,
      ...selectionColumnProps,
      key: "__selection__",
      selectedRowKeys,
      onChange: handleSelectChange,
    };
    _columns = [selectionColumn, ..._columns];
  }
  return (
    <Content>
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            data={data}
            width={width}
            height={height}
            headerHeight={headerHeight}
            rowHeight={rowHeight}
            rowClassName={selectedRowCurrent}
            rowKey={rowKey}
            sortBy={sortBy}
            onColumnSort={onColumnSort}
            emptyRenderer={<Empty>No data</Empty>}
            columns={_columns}
            rowEventHandlers={{
              onDoubleClick: onDoubleClickRow
            }}
          >
            {children}
          </BaseTable>
        )}
      </AutoResizer>
    </Content>
  );
};

TableSelect.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  rowKey: PropTypes.string,
  sortBy: PropTypes.object,
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  onColumnSort: PropTypes.func,
  onDoubleClickRow: PropTypes.func,
  rowClassName: PropTypes.func
};

TableSelect.defaultProps = {
  sortBy: {}
};

export default memo(TableSelect);
