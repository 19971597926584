import React, {useState, useEffect, useRef } from 'react'
//library
import {DrawPolygonMode } from "react-map-gl-draw";
import * as turf from '@turf/turf'

//material ui
import {TextField, createMuiTheme} from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Cookies from 'js-cookie';
//image
import BackButton from "../../Image/back.svg";


//component
// import {GET_SEARCH_ALL_ASSET_GROUP, DELETE_POLICIES, SEARCH_POLICIES, EDIT_GEOFENCE, GET_GEOFENCES} from '../../config/config-graphql/Graphql';
import { APIGetSearchAssetnAssetGroup } from '../../config/config-restAPI/getAPICalled';
import { APIDeleteGeofence } from '../../config/config-restAPI/deleteAPICalled';
import { APIUpdateGeofence } from '../../config/config-restAPI/patchAPICalled';
import {getFitboundPoint} from '../../component/component-fitbound/Fitbound'
// import NotificationList from "./NotifcationList"
import AssetListEdit from "./AssetListEdit"
import AssetSearchEdit from "./AssetSearchEdit"
import SnackBar from '../../config/config-snackbar/SnackBar'
import GeofenceAreaEdit from './GeofenceAreaEdit'
import CommunicationEditCard from './CommunicationEditCard'
import DeleteCard from './DeleteCard'

const useStyles = makeStyles({ 
    underline: { 
        "&&&:before": { borderBottom: "none" }, "&&:after": { borderBottom: "none" } 
    },
    input:{
        '&::placeholder': {
            color: '#3A3A3A'
        }
    }

});

const materialTheme = createMuiTheme({
    overrides : {
        MuiInputBase: {
            root: {
                fontSize: "18px",
                color: "#3A3A3A"
            }
            
        },
    }
})

/**
 * Search Component
 * GeoAssetSearch -> the search component
 *      ||
 * GeoListCard     -> the search list component and onClick list
 */


/**
 * Selected List Compoenent
 * GeoAssetList -> display the selected list component and onclick remove function
 */



const DrawPolygon = {id: "drawPolygon", text: "Draw Polygon", handler: DrawPolygonMode}

const EditForm = ({fx_back, data , dataGeofence, APICalled, getLoading, setEditGeofence, editGeofence, modeId, setModeId, modeHandler, setModeHandler, selectedFeatureIndex, setSelectedFeatureIndex, editorRef, setEditorRef, geoJSON, setGeoJSON, setName, name, polygons, setPolygons, polycies, setPolycies, setMapping, mapping, setSnOpen, setMessage, setSeverity, featureDisable}) => {
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');
    const classes = useStyles()
    const [onDrawPolygon, setOnDrawPolygon] = useState(false)
    const [onDrawRadius, setOnDrawRadius] = useState(false)
    const isEdit = true;
    const [onDisplay, setOnDisplay] = useState({})
    const [onkey, setOnKey] = useState("")
    const [assetGroup, setAssetGroup] = useState({})
    const [onSelected, setOnSelected] = useState([])
    const [onSelectedAdd, setOnSelectedAdd] = useState([]);
    const [onSelectedRemove, setOnSelectedRemove] = useState([]);
    const [addNotification, setAddNotification] = useState({
        enter: [],
        leave: [],
        smartLock: [],
    })
    const [removeNotification, setRemoveNotification] = useState({
        enter: [],
        leave: [],
        smartLock: [],
    })
    const [removePolygon, setRemovePolygon] = useState([]);
    const [allAssetnGroup, setAllAssetnGroup] = useState([]);
    const [listSelected, setListSelected] = useState(null)
    const [loading, setLoading] = useState(false)

    const [polyciesN, setPolyciesN] = useState("")

    //this state are for snackbar props
    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("")
    const [isHovered, setIsHovered] = useState({
        btnSave: false,
        btnBack: false
    })

    const [priEnter, setPriEnter] = useState({
        enter: false
    })
    const [priLeavs, setPriLeavs] = useState({
        leaves: false
    })

    const wrapperRef = useRef(null);
 
    const [relationship, setRelationship] = useState({
        asset_add: [],
        asset_remove: []
    })
    const [relationshipGroup, setRelationshipGroup] = useState({
        asset_group_add: [],
        asset_group_remove: []
    })

    const [notification, setNotification] = useState({
        enter: dataGeofence && dataGeofence[0].notification && dataGeofence[0].notification.enter && dataGeofence[0].notification.enter.length > 0 ? dataGeofence[0].notification.enter : [],
        leave: dataGeofence && dataGeofence[0].notification && dataGeofence[0].notification.leave && dataGeofence[0].notification.leave.length > 0 ? dataGeofence[0].notification.leave : [],
        smartLock: dataGeofence && dataGeofence[0].notification && dataGeofence[0].notification.generatePin && dataGeofence[0].notification.generatePin.length > 0 ? dataGeofence[0].notification.generatePin : []
    })

    const APISearchAssetnGroupCalled = async (searchType, onkey, selectedConvert) => {
        try {
            const result = await APIGetSearchAssetnAssetGroup(token, setLoading, agencyId, searchType, onkey, selectedConvert)
            if (result.data.status === 200) {
                const assetList = result.data.data.assets.length > 0 ? result.data.data.assets.map((x) => {
                    return x && x.name ? {
                        firstLetter: x.name.charAt(0).toUpperCase(),
                        pairId: x.pairId,
                        uuid: x.uuid,
                        name: x.name,
                        paired_assets: x && x.pairedAssets.length > 0 ? x.pairedAssets.map((x) => { return { uuid: x.uuid, name: x.name }}) : [],
                        type: 'asset',
                    } : { firstLetter: "", uuid: "", name: "", pairId: "", paired_assets: [], type: "asset" }
                }) : []
                const assetGroupList = result.data.data.groups.length > 0 ? result.data.data.groups.map((x) => {
                    return x && x.name ? {
                        firstLetter: x.name.charAt(0).toUpperCase(), 
                        uuid: x.uuid, 
                        pairId: x.uuid, 
                        name: x.name,
                        type: 'group',
                        paired_asset: [] } : {
                            firstLetter: "", uuid: "", name: "", description: "", type: "group",paired_assets: []
                        }
                    }) : []
                const searchOptions = {
                    options: assetList.length > 0 || assetGroupList.length > 0 ? assetList.concat(assetGroupList) : []
                }

                const flatAssetIds = searchOptions.options.flat(2)
                setAllAssetnGroup(flatAssetIds);
                setListSelected(result.data.data.selected ? result.data.data.selected : null)
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch(err) {
            setLoading(false);
        }
            
    }

    useEffect(() => {
        if (token) {
            APISearchAssetnGroupCalled('ALL', '', '');
        }
    }, [])

    useEffect(() => {
        dataGeofence && dataGeofence[0].notification && setPriEnter({enter: dataGeofence[0].notification.enter})
        dataGeofence && dataGeofence[0].notification && setPriLeavs({leaves: dataGeofence[0].notification.leave})
        dataGeofence && setPolyciesN(dataGeofence[0].name)
        
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGeofence])

    useEffect(() => {
        if (dataGeofence && dataGeofence.length > 0){
            const clone = dataGeofence && dataGeofence[0].polygons.map(x => {return {uuid: x.uuid, name: x.name, area: x.area}}) // baru bukak     
            // would like to combine area and name here but due to deconstruct iisue
            setPolygons(clone) // baru bukak
        }

        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGeofence])

    //this useEffect for fitbound polygons
    useEffect(() => {
        if(Array.isArray(polygons)){
            const fullPolygons = polygons && polygons.map(x => turf.polygon(x.area.geometry.coordinates))
            const centralPolygons = fullPolygons && fullPolygons.map(x => turf.centroid(x))
            const fitbound = getFitboundPoint(centralPolygons)
            setMapping(fitbound)
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps


    }, [polygons])

    //to get the value from the getGeofence asset and push to onSelected array
    const settingSelected = async () => {
        if(dataGeofence) {
            let selected = [];
            dataGeofence[0]?.assets?.map((x) => selected.push(x.uuid));
            dataGeofence[0]?.assetGroups?.map((x) => selected.push(x.uuid));
            setOnSelected(selected);
            const convert = selected.toString();
            APISearchAssetnGroupCalled('WITH_SELECTED', '', convert);
        }
    }

    //this is for displaying assets from getGeofence. (promise cannot add in useEffect)
    useEffect(() => {
        !getLoading && settingSelected();

        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGeofence])


    useEffect(() => {
        document.addEventListener("mousedown", handleClick); 

        return () => {
            document.removeEventListener("mousedown", handleClick);
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleClick = (e) => {
        if (wrapperRef && wrapperRef.current && wrapperRef.current.contains(e.target)) {
            return;
        }
          // outside click
          setSelectedFeatureIndex({selectedFeatureIndex: null})
    }

    const btnPolygon = (id) => {
        const modeID = id === modeId ? null : id;
        const modeHandlers = modeID ? new DrawPolygon.handler() : null;
        setModeHandler(modeHandlers)
        setModeId(modeID)
        setOnDrawRadius(false)
        setOnDrawPolygon(modeID ? true : false)
    }

    const onDelete = (index) => {
        const cloneJson = [...polygons]
        if(index !== null && index >= 0){
            editorRef.deleteFeatures(index)
            cloneJson.splice(index, 1)
            setPolygons(cloneJson)
            const intPolygon = polygons[index];
            setRemovePolygon([...removePolygon, intPolygon])
          }
    }

    const onRemovePolicies = async (x) => {
        try{
            const reducer = {
                geofenceUuid: [dataGeofence[0].uuid]
            }
            const result = await APIDeleteGeofence(token, agencyId, reducer, setLoading)
            if (result.data.status) {
                setSnOpen(true)
                setMessage("Record has been successfully deleted")
                setSeverity("success")
                setPolygons([])
                setListSelected([]);
                setAllAssetnGroup([]);
                setPolyciesN("");
                setOnDisplay({});
                setAssetGroup({});
                APISearchAssetnGroupCalled('ALL','','');
                APICalled('','')
                fx_back();  
            } else {
                setErrOpen(true)
                setErrMsg('Error, Please try again')
                setErrSevere("error") 
            }
        }catch(err){
            console.log(JSON.stringify(err, null, 2));
            setErrOpen(true)
            setErrMsg(JSON.stringify(err.message, null, 2))
            setErrSevere("error")
        }

    }

    const btnConfirm = async (e) => {
        try{
            e.preventDefault();
            //to filter original value to new value
            const notifications = notification && {enter: notification.enter.map(x => {return {uuid: x.uuid}}), leave: notification.leave.map(x => {return {uuid: x.uuid}}), generatePin: notification.smartLock.map(x => {return {uuid: x.uuid}})}
            const notificationAdd = Object.keys(addNotification).length > 0 ? {enter: addNotification.enter.map(x => {return {uuid: x}}), leave: addNotification.leave.map(x => {return {uuid: x}}), generatePin: addNotification.smartLock.map(x => {return {uuid: x}})} : null
            const noficiationRemove = Object.keys(removeNotification).length > 0 ? {enter: removeNotification.enter.map(x => {return {uuid: x}}), leave: removeNotification.leave.map(x => {return {uuid: x}}), generatePin: removeNotification.smartLock.map(x => {return {uuid: x}})} : null
            const addAssetList = onSelectedAdd ? onSelectedAdd.length > 0 ? onSelectedAdd.filter((x) => x.type === 'asset') : [] : []
            const removeAssetList = onSelectedRemove ? onSelectedRemove.length > 0 ? onSelectedRemove.filter((x) => !x.assets) : [] : []
            const addAssetGList = onSelectedAdd ? onSelectedAdd.length > 0 ? onSelectedAdd.filter((x) => x.type === 'group') : [] : []
            const removeAssetGList = onSelectedRemove ? onSelectedRemove.length > 0 ? onSelectedRemove.filter((x) => x.assets) : [] : []
            const addPolygons = polygons.filter((x) => !x.uuid);
            //------------------------------------------------------------------------------
            const reducer = {
                geofence : {
                    uuid: dataGeofence[0].uuid,
                    name: polyciesN,
                    // assets: listSelected ? listSelected.asset.length > 0 ? listSelected.asset.map((x) => x.uuid) : [] : [],
                    // assetGroups: listSelected ? listSelected.group && listSelected.group.length > 0 ? listSelected.group.map((x) => x.uuid) : [] : [],
                    // notification: notifications,
                    addNotification: notificationAdd,
                    removeNotification: noficiationRemove,
                    addPolygons: addPolygons,
                    removePolygons: removePolygon.map((x) => x.uuid),
                    addAssets: addAssetList.length > 0 && addAssetList.map((x) => x.uuid),
                    removeAssets: removeAssetList.length > 0 && removeAssetList.map((x) => x.uuid),
                    addAssetGroups:  addAssetGList.length > 0 && addAssetGList.map((x) => x.uuid),
                    removeAssetGroups: removeAssetGList.length > 0 && removeAssetGList.map((x) => x.uuid),
                }
            }

            // console.log(reducer);
            // console.log(removeNotification);

            const result = await APIUpdateGeofence(token, agencyId, reducer, setLoading) 
            // console.log(result);
            if (result.data.status === 200) {
                setSnOpen(true)
                setMessage("Record has been successfully updated")
                setSeverity("success")
                setPolygons([])
                APISearchAssetnGroupCalled('ALL','','');
                APICalled('','');
                fx_back();
            } else {
                setErrOpen(true);
                setErrMsg('Error, Please try again later');
                setErrSevere("error");
            }

        }catch(err){
            console.log(JSON.stringify(err, null, 2));
            setErrOpen(true)
            setErrMsg(JSON.stringify(err.message, null, 2))
            setErrSevere("error")
        }
 
    }

    const onChangeText = (e, i) => {
        let test = [...polygons]
        test[i].name = e.target.value  
        setPolygons([...test])
    }

    const backButton = () => {
        APISearchAssetnGroupCalled('ALL', '', '');
        fx_back()
    }

    // console.log(listSelected);

    const btnPolyList = (index, polygon) => {
        setSelectedFeatureIndex({selectedFeatureIndex: index})
        const selectedPolygon = turf.polygon(polygon.area.geometry.coordinates)
        const centralPolygons = turf.centroid(selectedPolygon)
        const fitbound = getFitboundPoint([centralPolygons])
        setMapping(fitbound)
    }

    // console.log({
    //     addNotification, removeNotification, notification
    // })

    
    return (
        <div className="edit-container">
            <div className="title-back">
                <div className="input-title">
                    <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                    <img loading="lazy" src={BackButton}se alt="back-button" onClick={() => backButton()} className={isHovered.btnBack ? "back-hover-button-style" : "back-button-style"} onMouseEnter={() => setIsHovered((current) => ({...current, btnBack: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnBack: false}))}/>
                    <ThemeProvider theme={materialTheme}>
                        <TextField className="text-id" InputProps={{classes}} value={polyciesN} placeholder={polyciesN} color="#3A3A3A" onChange={e => setPolyciesN(e.target.value)} fullWidth/>
                    </ThemeProvider>
                </div>
                {
                    featureDisable ? null : (
                    <div className="btn-confirm" onMouseEnter={() => setIsHovered((current) => ({...current, btnSave: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnSave: false}))}>
                        <button className={polyciesN ? `${isHovered.btnSave ? "isHovered-confirm": "confirm"}` : "disabled"} disabled={polyciesN ? false : true} onClick={(e) => btnConfirm(e)}>Save</button>
                    </div> 
                    )
                }

            </div>
            <GeofenceAreaEdit wrapperRef={wrapperRef} selectedFeatureIndex={selectedFeatureIndex} polygons={polygons} onChangeText={onChangeText} btnPolyList={btnPolyList} onDelete={onDelete} btnPolygon={btnPolygon} modeId={modeId} featureDisable={featureDisable}/>
            <div className="assign-assets-list-edit">
                <header className="assign-assets-text-edit">Assign Assets</header>
                {
                    featureDisable ? null : <AssetSearchEdit dataTwo={allAssetnGroup && allAssetnGroup} APICalled={APISearchAssetnGroupCalled} searchLoading={loading} onkey={onkey} setOnKey={setOnKey} onSelected={onSelected} setOnSelected={setOnSelected} onSelectedAdd={onSelectedAdd} setOnSelectedAdd={setOnSelectedAdd} onSelectedRemove={onSelectedRemove} setOnSelectedRemove={setOnSelectedRemove} onDisplay={onDisplay} setOnDisplay={setOnDisplay} assetGroup={assetGroup} setAssetGroup={setAssetGroup} relationship={relationship} setRelationship={setRelationship} relationshipGroup={relationshipGroup} setRelationshipGroup={setRelationshipGroup}/>
                }
                
                <AssetListEdit dataTwo={listSelected} APICalled={APISearchAssetnGroupCalled} getData={dataGeofence} searchLoading={loading} onkey={onkey} setOnKey={setOnKey} onSelected={onSelected} setOnSelected={setOnSelected} setOnSelectedAdd={setOnSelectedAdd} onSelectedAdd={onSelectedAdd} onSelectedRemove={onSelectedRemove} setOnSelectedRemove={setOnSelectedRemove} onDisplay={onDisplay} setOnDisplay={setOnDisplay} assetGroup={assetGroup} setAssetGroup={setAssetGroup} setEditGeofence={setEditGeofence} editGeofence={editGeofence} relationship={relationship} setRelationship={setRelationship} relationshipGroup={relationshipGroup} setRelationshipGroup={setRelationshipGroup} featureDisable={featureDisable}/>
            </div>
            <CommunicationEditCard setNotification={setNotification} notification={notification} featureDisable={featureDisable} setAddNotification={setAddNotification} addNotification={addNotification} setRemoveNotification={setRemoveNotification} removeNotification={removeNotification} isEdit={isEdit} dataGeofence={dataGeofence} />
            {
                featureDisable ? null : <DeleteCard polycies={polycies} onRemovePolicies={onRemovePolicies}/>
            }
            <style jsx>
                {
                    `
                    .edit-container{
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-auto-rows: minmax(min-content, max-content);
                        gap: 20px 0px;
                        grid-template-areas:
                          "."
                          "."
                          "."
                          ".";
                        background-color: #F3F3F3;
                    }
                    .title-back{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 2fr 1fr; 
                        grid-template-rows: 1fr; 
                        gap: 0px 0px; 
                        grid-template-areas: 
                          ". .";
                        align-items: center;
                        justify-content: space-between;
                        padding: 20px 16px 0px 16px;
                    }
                    .input-title{
                        display: flex;
                        align-items: center;
                    }
                    .input-checkbox{
                        display: flex;
                        margin: 0px 0px 8px 0px;
                    }
                    .back-button{
                        cursor: pointer;
                    }
                    .text-id{
                        font-size: 14px;
                        margin: 0px 0px 0px 8px;
                    }
                    .polygon-name{
                        position: relative;
                        top: 2px;
                    }
                    .btn-confirm{
                        text-align: right;                   
                    }
                    .btn-list-area{
                        display: flex;
                        align-items: center;
                        margin: 8px 0px 0px 0px;
                    }

                    .assign-assets-list-edit{
                        margin: 0px 16px 0px 16px;
                        background-color: #FFFFFF;
                        padding: 16px;
                        border-radius: 4px;
                    }
                    .assign-assets-text-edit{
                        margin: 0px;
                        font-size: 14px;
                        font-weight : 600;
                        margin: 0px 0px 8px 0px;
                    }
                    .confirm{
                        // height: 32px;
                        width: 130px;
                        color: #FFFFFF;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 17px;
                        text-align: center;
                        background-color: #509D91;
                        border-radius: 2px;
                        cursor: pointer;
                    }
                    .isHovered-confirm{
                        width: 130px;
                        color: #FFFFFF;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 17px;
                        text-align: center;
                        background-color: #498f84;
                        border-radius: 2px;
                        cursor: pointer;
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                    }
                    .disabled{
                        // height: 32px;
                        width: 130px;
                        color: #FFFFFF;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 17px;
                        text-align: center;
                        background-color: #509D91;
                        opacity: 0.7;
                        border-radius: 2px;
                        cursor: pointer;
                    }
                    .communication-container{
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: 30px minmax(0fr, 1fr) minmax(0fr, 1fr) minmax(0fr, 1fr);
                        gap: 0px 0px;
                        grid-template-areas:
                          "."
                          "."
                          "."
                          ".";
                        margin: 0px 16px 0px 16px;
                        background-color: #FFFFFF;
                        padding: 16px;
                        border-radius: 4px;
                    }
                    .communication-text{
                        margin: 0px;
                        font-size: 14px;
                        font-weight : 600;
                        margin: 0px 0px 8px 0px;
                    }
                    .delete-text{
                        margin: 0px;
                        font-size: 14px;
                        font-weight : 600;
                        margin: 0px 0px 10px 0px;
                    }
                    .delete-policy-container{
                        margin: 0px 16px 0px 16px;
                        background-color: #FFFFFF;
                        padding: 16px;
                        border-radius: 4px;
                    }
                    .delete-div-policy-container{
                        display: flex;
                        justify-content: center;
                        cursor: pointer;
                    }
                    .delete-btn-policy{
                        background-color: #EA2B1F;
                        color: #FFF;
                        width: 50%;
                    }
                    .isHovered-delete-btn-policy{
                        background-color: #d1261b;
                        font-weight: 600;
                        color: #FFF;
                        width: 50%;
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                    }
                    .geofence-title-edit{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 0px 0px 8px 0px;
                    }
                    .geofence-area-text-edit{
                        margin: 0px;
                        font-size: 14px;
                        font-weight : 600;
                    }
                    .geofence-area-container-edit{
                        display: flex;
                        flex-direction: column;
                        background-color: #FFF;
                        margin: 0px 16px 0px 16px;
                        padding: 16px;
                        border-radius: 4px;
                        background-color: #FFFFFF;
                    }
                    .btn-list-area{
                        display: flex;
                    }
                    .draw-polygon{
                        display: flex;
                        margin: 0px 0px 0px 8px;
                        font-size: 13px;
                        color: #509D91;
                    }
                    .btn-clear{
                        height: 16px;
                        color: #509D91;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                    .content-list-area{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        height: 32px;
                    }
                    .content-list-area-polygon{
                        padding: 4px 4px 4px 4px;
                        border-style : groove none groove groove;
                        border-width: thin;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        cursor: pointer
                    }
                    .content-list-area-polygon-active{
                        padding: 4px 4px 4px 4px;
                        border-style : groove none groove groove;
                        border-width: thin;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                        cursor: pointer;
                        background-color: #509D91;
                    }
                    .content-list-area-radius{
                        padding: 4px 4px 4px 4px;
                        border-style : groove;
                        border-width: thin;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        cursor:pointer;
                    }
                    .content-list-area-radius-active{
                        padding: 4px 4px 4px 4px;
                        border-style : groove;
                        border-width: thin;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        cursor:pointer;
                        background-color: #509D91;
                    }
                    .triggers-asset{
                        margin: 0px 0px 4px 0px;
                    }
                    .triggers p{
                        margin: 0px 0px 4px 0px;
                        font-size:12px;
                        color: #939598;
                    }
                    .triggers-asset p {
                        font-size: 13px;
                        color: #333333;
                        letter-spacing: 0;
                        margin: 0px 0px 0px 8px;
                        line-height: 21px
                    }
                    .triggers{
                        display: flex;
                        flex-direction: column;
                        margin: 16px 16px 16px 16px;
                    }
                    .list-polygons{
                    }
                    .polygon-container-hover{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        border-radius: 4px;
                    }
                    .close-icon{
                        cursor: pointer;
                        display: flex;
                    }
                    .remove-icon{
                        margin: 20px 0px 0px 0px;
                        cursor: pointer;
                    }
                    .input-text{ 
                        width: 100%; 
                        border-style: none;
                        outline: none;
                        padding: 0px 8px 0px 8px;        
                    }
                    .input-text-highlight{
                        width: 100%;
                        border-style: none;
                        color: #509D91;
                        outline: none;
                        padding: 0px 8px 0px 8px;  
                    }
                    .back-button-style{
                        cursor: pointer;
                        padding: 4px;
                    }
                    .back-hover-button-style{
                        background-color: rgba(0,0,0,0.03);
                        border-radius: 5px;
                        padding: 4px;
                        cursor: pointer;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default EditForm
