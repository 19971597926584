import React from 'react'
import Loaders from '../../component/component-loaders/Loaders'

const UserSearchList = ({data, loading, viewSearchResult, dataTwo, types, setViewSearchResult, setUserListing, userListing}) => {
    return (
        <div className={`asset-list-container-user ${viewSearchResult && "asset-list-active-user"}`}id="scrollable">
            {
              loading ? (<div style={{position:"relative", bottom: "20px"}}><Loaders /></div>) : (
             <><List data={data} setUserListing={setUserListing} dataTwo={dataTwo} types={types} userListing={userListing} setViewSearchResult={setViewSearchResult}/></>
             )
            }
         <style jsx>
            {
                `
                .asset-list-container-user::-webkit-scrollbar {
                    display: none;
                }
                .asset-list-container-user{
                    position: absolute;
                    background-color: white;
                    width: 331px;
                    min-height: 100px;
                    max-height: 430px;
                    overflow: auto;
                    scrollbarwidth: none;
                    msoverflowstyle: none;
                    padding: 10px;
                    box-sizing: border-box;
                    visibility: hidden;
                    opacity: 0;
                    border-top-right-radius: 0px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    transition: visibility 0s, opacity 0.5s linear;
                    box-shadow: 0px 1px 3px 0px #00000020;
                    z-index: 6;
                }

                .asset-list-container-user.asset-list-active-user{
                    width: 331px;
                    visibility: visible;
                    opacity: 1;
                    transition: visibility 1s, opacity 0.5s linear;
                    margin: 0px 0px 0px 0px;
                }
                .asset-list-content{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
                .asset-list-title-id{
                    display: flex; 
                    flex-direction: column;
                }
                `
            }
        </style>
        </div>

)
} 

export default UserSearchList

const List = ({data, setUserListing, userListing, dataTwo, types, setViewSearchResult}) => {

    // console.log('userListing', userListing);
    const onClickUser = (e, value) => {
        e.preventDefault();
        let clone = [...userListing]
        clone.push(value)
        // console.log(clone);
        setUserListing(clone.filter((tag, index, array) => array.findIndex(t => t.uuid === tag.uuid || t.telegram.contactNo === tag.telegram.contactNo) === index))
        setViewSearchResult(false)
    }
    return(
    <div>
        <div className="asset-list-title-id">
            {
                    <div>
                        {
                             dataTwo.length > 0 ? dataTwo.map((docs, index) => {
                                return(
                                    <div className="asset-list-details" onClick={e => onClickUser(e,docs)}>
                                        <div className="icon-label">
                                            {
                                                types === 'Email' ? <div className="asset-name">{docs.email}</div> : 
                                                <div className="asset-name">{`${docs.telegram.contactCode}${docs.telegram.contactNo}`}</div>
                                            }
                                            
                                        </div>
                                </div>

                                )
                            }) : (
                            <div className="asset-list-details">No user</div>
                            )
                            // types === 'Email' ? 
                        }  
                       
                    </div>
            }

        </div>
        <style jsx>
            {
                `
                .asset-list-content{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
                .asset-list-title-id{
                    display: flex; 
                    flex-direction: column;
                }
                .asset-list-details{
                    margin: 8px 0px 0px 0px;
                    height: 30px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border-radius: 5px;
                    cursor: pointer;
                    position: relative;
                }
                .asset-list-details img{
                }
                .icon-label{
                    border-radius: 5px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 30px;
                    padding: 10px;
                    box-sizing: border-box;
                    white-space: nowrap;
                    overflow: hidden;
                }
                .icon-label .asset-name{
                    font-size: 14px;
                    line-height: 23px;
                    color: #3A3A3A;
                    margin-right: 10px;
                    margin-left: 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                }
                .icon-label .asset-id{
                    color: #939598;
                    position: relative;
                    top: 1px;
                    right: 2px;
                    font-size: 12px;
                    line-height: 17px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 120px;
                }
                `
            }
        </style>      
    </div>   
    )
}
