import React from 'react'

function DriverInformation({featureDisable, isHovered, setIsHovered, onUserInput, driver, isBulkEdit, fieldList, settingFieldValue}) {

    // console.log(isHovered)
    return (
        <div>
            <p className="driver-title">Channel Information</p>
            <div className="driver-input">
                <div className="driver-details-input">
                    <div className="drive-input-input">First Name <p style={{color:"red", margin:"0"}}>*</p> </div> 
                    {
                        featureDisable ? <label className="text-box">{settingFieldValue(isBulkEdit, driver, fieldList.firstName.name)}</label> : <input type="text" className={`${isHovered.firstName ? "isHovered-border": "text-box"}`} placeholder="-" name="firstName" value={settingFieldValue(isBulkEdit, driver, fieldList.firstName.name)} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, firstName: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, firstName: false}))}/>
                    }
                </div>
                <div className="driver-details-input">
                    <div className="drive-input-input">Last Name <p style={{color:"red", margin:"0"}}>*</p> </div> 
                    {
                        featureDisable ? <label className="text-box">{settingFieldValue(isBulkEdit, driver, fieldList.lastName.name)}</label> : <input type="text" className={`${isHovered.lastName ? "isHovered-border": "text-box"}`} placeholder="-" name="lastName" value={settingFieldValue(isBulkEdit, driver, fieldList.lastName.name)} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, lastName: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, lastName: false}))}/>
                    }
                </div>
                <div className="driver-details-input">
                    <div className="drive-input-input">Driver ID <p style={{color:"red", margin:"0"}}>*</p> </div> 
                    {
                        featureDisable ? <label className="text-box">{settingFieldValue(isBulkEdit, driver, fieldList.driverId.name)}</label> : <input type="text" maxLength="6" className={`${isHovered.driverId ? "isHovered-border": "text-box"}`} placeholder="-" name="driverId" value={settingFieldValue(isBulkEdit, driver, fieldList.driverId.name)} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, driverId: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, driverId: false}))}/>
                    }
                </div>
            </div>
            <style>
                {
                    `.driver-title{
                        margin: 0px 0px 8px 0px;
                        color: #333333;
                        font-size: 15px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 24px;
                    }
                    .driver-details-input{
                        display: grid;
                        grid-template-columns: 150px 2fr;
                        grid-template-rows: 1fr;
                        align-items: center;
                        grid-template-areas:
                          ". .";
                        overflow: hidden;
                        height: 30px;
                        margin: 0px 0px 8px 0px;
                    }
                    .text-box{
                        display: flex;
                        align-items: center;
                        border-style: none;
                        height: 24px;
                        outline: none;
                        border-radius: 4px;
                    }
                    .isHovered-border{
                        border: 1px solid #b6babf;
                        height: 24px;
                        border-radius: 4px;
 
                    }
                    .drive-input-input{
                        display: flex;
                        color: #939598;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default DriverInformation
