import React, { useContext, useState } from "react";

//image
import ChevronLightIcon from "../../Image/chevron_light.svg"
import GreyAssetIcon from "../../Image/asset_grey.svg"
import GreyLiveIcon from "../../Image/live_grey.svg"
import GreyAssetGroupIcon from "../../Image/asset_group_grey.svg";

//component
import menuConfig from "../../config/config-menu/menu";
import { WebsocketContext } from "../../config/config-websocket/Websocket"

const OverviewCard = ({data, setEdit, edit, setPageInd, setFilterFinal, add, setAdd, setEditValue, fx_changePage, setLiveFilter, history}) => {
    const payloadContext = useContext(WebsocketContext);    
    const { openAssetGroupCard } = payloadContext;
    const [isHovered, setIsHovered] = useState({
        container: false,
        details: false
    })

    const btnDetails = (e, value) => {
        e.preventDefault(e);
        setPageInd(true)
        setFilterFinal({uuid: value.uuid})
    }

    const ViewLive = (ag) => {
        fx_changePage(menuConfig.dashboard[1].key, menuConfig.dashboard[1].children[1].key)
        openAssetGroupCard(true, ag)
        history.push("/dashboard/live/map")
        // setLiveFilter({ group_uuid: ag.uuid })
    }
    return(
        <div onMouseEnter={() => setIsHovered((current) => ({...current, container: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, container: false}))}>
                {
                   Object.keys(data).length > 0 ?
                            <div className="asset-container">
                                <div className="container-device">
                                    {/* <div className="checkbox-container">
                                        <input type="checkbox" className="check-type"/>
                                    </div> */}
                                    <div className="asset-detail-container">
                                        <div className="device-o-container">
                                            <div className="device-detail">
                                                <div className="device-name">{data.name}</div>
                                            </div>
                                            <div className="line-box"></div>
                                            <div className="agency-detail">
                                                <div className="asset_group-description">{data.description}</div>
                                            </div>
                                            <div className="line-box"></div>
                                            <div className="features">
                                                <div className="flashed-container">
                                                    <div className="count-container">
                                                        <img src={GreyAssetIcon} alt="test" style={{width: "16px", height: "16px"}}/>
                                                        <div className="flashed">Assets</div>
                                                    </div>
                                                    <div className="count-number">{data.asset_count}</div>
                                                </div>

                                            </div>
                                            {
                                                isHovered.container && 
                                                <div className="onhover-container" onMouseEnter={() => setIsHovered((current) => ({...current, details: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, details: false}))}>
                                                    
                                                    <div className={`${isHovered.details ? "isHovered-button-edits": "button-edits"}`} onClick={e => btnDetails(e, data)}>
                                                            <img src={ChevronLightIcon} alt="test"/>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                        <div className="container-live-view" onClick={() => ViewLive(data)}>
                                            <img src={GreyLiveIcon} alt="live-img"/>
                                            <div className="live-view-text" >Live View</div>
                                        </div>
                                    </div> 
                                </div>
                            </div>

                    : <EmptyAssetGroup />
                }
            <style>
            {
                `
                    .asset-container{
                    }
                    .device-o-container{
                        display: grid;
                        grid-template-columns: 1fr 10px 1.5fr 10px 0.5fr 43px;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas: ". . . . . .";
                        height: 60px;
                        border-radius: 4px;
                        background-color: #FFFFFF;

                    }
                    .feature-detail{
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                    .detail-feature{
        
                    }
                    .asset_group-description{
                        white-space:nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .checkbox-container{
                        display: flex;
                        padding: 8px 0px 0px 0px;
                    }
                    .check-type{
                        margin: 0px 4px 0px 0px;
                        height: 18px;
                        width: 18px;
                        background-color: #509D91;
                    }
                    .container-device{
                        display: grid;
                        margin-bottom: 6px;
                        grid-template-columns:1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas: ". ."
                    }
                    .device-detail{
                        padding: 8px;
                        display: flex;
                        align-items: center;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .line-box{
                        height: 46px;
                        margin-right: 8px;
                        margin-top: 8px;
                        border: 1px solid #DDDDDD;
                    }
                    .agency-detail{
                        display: flex;
                        align-items: center;
                        padding: 8px;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .features{
                        padding: 0px 0px 0px 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .device-name{
                        color: #333333;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 21px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .device-id{
                        margin: 0px 0px 0px 8px;
                        color: #333333;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .device-name-id{
                        display: flex;
                        align-items: center;
                    }
                    .agency-name{
                        margin-left: 8px;
                    }
                    .onhover-container{
                        display:flex;
                        justify-content: flex-end;
                        width: 100%;
                    }
                    .featuImage{
                        display: flex;
                        margin: 4px 0px 0px 0px;
                    }
                    .button-edits{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 43px;
                        height: 60px;
                        border-radius: 0 4px 4px 0;
                        background-color: #509D91;
                        cursor: pointer;
                    }
                    .isHovered-button-edits{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 43px;
                        height: 60px;
                        border-radius: 0 4px 4px 0;
                        background-color: #498f84;
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                        cursor: pointer;
                    }
                    .img-feature{
                        margin: 0px 16px 0px 0px;
                    }
                    .container-live-view{
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        height: 40px;
                        padding: 0px 24px 0px 0px;
                        border-radius: 0 0 4px 4px;
                        background-color: rgb(147, 149, 152, 0.1);
                    }
                    .asset-detail-container{
                        box-shadow: 0 0px 0px 0px rgba(0,0,0,0.2)
                    }
                    .live-view-text{
                        margin: 0px 0px 0px 8px;
                        color: #939598;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                    .flashed-container{
                        display:flex;
                        flex-direction: column;
                        padding: 8px
                    }
                    .count-container{
                        display: flex;
                    }
                    .flashed{
                        margin: 0px 0px 0px 4px;
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                    .count-number{
                        color: #333333;
                        font-size: 24px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 32px;
                        text-align: center;
                    }
                `
            }
            </style>
        </div>
    )
}

export default OverviewCard

const EmptyAssetGroup = () => {
    return (
        <div className="empty-asset-group-container">
            <img className="empty-asset-group-image" src={GreyAssetGroupIcon} alt="No Asset Group" />
            <div className="empty-asset-group-label">Start Adding Your Asset Group</div>
            <style jsx>
                {`
                .empty-asset-group-container{
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                .empty-asset-group-image{
                    width: 128px;
                    height: 128px;
                }
                .empty-asset-group-label{
                    font-size: 24px;
                    line-height: 32px;
                    color: #939598;
                }
                `}
            </style>
        </div>
    )
}