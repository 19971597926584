import React, {useState} from 'react'

import LightSyncIcon from '../../Image/light-sync.svg'
import PopUpSync from './PopUpSync'
import SmartLockDetails from './SmartLockDetails'
import SnackBar from '../../config/config-snackbar/SnackBar'


const Overview = ({details, loading, data, dateSync, getQuery}) => {
    const [modal, setModal] = useState(false)

    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")
    const [isHovered, setIsHovered] = useState({
        sync: false
    })

    return (
        <div className="igloohome-container">
            <SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/>
            <div className="title-with-btn">
                <header className="title">Igloohome</header>
                <div className="btn-div-sync" onMouseEnter={() => setIsHovered((current) => ({...current, sync: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, sync: false}))}>
                    <button className={`${isHovered.sync ? "isHovered-btn-sync" : "btn-sync"}`} onClick={() => setModal(!modal)}>
                        <img loading="lazy" src={LightSyncIcon} alt="sync-icon" className="image-sync" />
                        Sync
                    </button>                    
                </div>

            </div>

            <SmartLockDetails details={details} dateSync={dateSync}/>
            {
                modal ? <PopUpSync modal={modal} setModal={setModal} data={data} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} getQuery={getQuery}/> : null
            }
            
            <style>
                {
                    `.igloohome-container{
                        padding: 24px;
                        height: 83vh;                    
                    }
                    .title{
                        color: #333333;
                        font-size: 24px;
                        letter-spacing: 0;
                        height: 24px;
                        font-weight: 600;
                        line-height: 32px;
                    }
                    .btn-sync{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 32px;
                        width: 213px;
                        border-radius: 2px;
                        background-color: #509D91;
                        color: #FFF;
                        font-weight: 600;
                        cursor: pointer;
                    }
                    .isHovered-btn-sync{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 32px;
                        width: 213px;
                        border-radius: 2px;
                        background-color: #498f84;
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                        color: #FFF;
                        font-weight: 600;
                        cursor: pointer;
                    }
                    .title-with-btn{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 0px 0px 16px 0px;
                        font-size: 14px;
                    }
                    .image-sync{
                        margin: 0px 8px 0px 0px;
                    }
                    .btn-div-sync{
                        display: flex;
                        align-items: center;
                    }
                    .text-last-sync{
                        font-size: 11px;
                        color: #939598;
                        margin: 0px 8px 0px 0px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default Overview
