import React, { useState, useImperativeHandle, useRef, useEffect, useContext  } from 'react'
import MapGL, { Marker, FlyToInterpolator, NavigationControl, WebMercatorViewport} from 'react-map-gl';
import DeckGL, { ScatterplotLayer } from 'deck.gl';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import { useMediaQuery } from 'react-responsive';

import * as d3 from 'd3-ease';
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/react-hooks"
import MiddleEllipsis from "react-middle-ellipsis";
import { firebaseContext } from "../../config/config-firebase/FirebaseContext"

import { FETCH_ALL_PAYLOAD } from "../../config/config-graphql/Graphql"
import MoveIcon from "../../Image/move.svg"
import StopIcon from "../../Image/stop.svg"
import IdleIcon from "../../Image/device-idle.svg"
import TowingIcon from "../../Image/towing.svg"
import UndefinedIcon from "../../Image/default.svg"
import DarkTime from '../../Image/dark_time.svg';

import RedDirectionIcon from '../../Image/Stop-direction.svg'
import GreenDirectionIcon from '../../Image/Green_direction.svg'
import YellowDirectionIcon from '../../Image/yellow_direction.svg'
import TowingDirectionIcon from '../../Image/Towing-direction.svg'
import GreyDirectionIcon from '../../Image/Grey_direction.svg'

import useSupercluster from "use-supercluster";
import { WebsocketContext } from "../../config/config-websocket/Websocket";
import firebase from '../../config/config-firebase-auth/firebase'
import { isEmptyArray } from '../../config/config-function/function';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const useStyles = makeStyles({
    box: {
        height: "100%",
        width: "100%",
    },
    marker: {
      backgroundColor: "#4a4946",
      width: "70px",
      textAlign: "center",
      color: "white",
      fontWeight: 500,
      opacity: 0.7
    },
    popUpBox: {
      fontSize: "13px",
      fontWeight: 500,
      opacity: 0.7,
      padding: "3%"
    },
    clusterMarker: {
      color: '#fff',
      background: '#1978c8',
      borderRadius: '70%',
      padding: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    crimerMarker: {
        background: 'none',
        border: 'none'
    }
  })

const Map = ({ref, filter, setSearchValue}) => {
    const isDefault = useMediaQuery({ minWidth: 1025 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    const firebaseConfig = useContext(firebaseContext)
    const payloadContext = useContext(WebsocketContext);    
    const {  data: websocketPayload, new_payload_id, selected_asset_info} = payloadContext;
    const { data, loading } = useQuery(FETCH_ALL_PAYLOAD, { variables: {pairId: "" } });
    const [radiusDetail, setRadiusDetail] = useState(null)
    const [selected, setSelected] = useState(null)
    const [payloadData, setPayloadData] = useState(null)
    const [dataOption, setDataOption] = useState({ options: []})
    const [displayLastSeen, setDisplayLastSeen] = useState(false);
    const [isFirstRun, setIsFirstRun] = useState(true)
    const classes = useStyles();
    const mapRef = useRef();
    const analytics = firebase.analytics();

//    !loading && console.log(data);
//    console.log("websocket", websocketPayload);

    // console.log(websocketPayload)

    // console.log(selected_asset_info);

    // websocketPayload && console.log({
    //     snapshotState: websocketPayload.snapshotState,
    //     asset_name: websocketPayload.asset.name,
    //     paired_asset: websocketPayload.asset.paired_assets
    // })

    // new schema points
    const points = dataOption.options.map(docs => {
        if((docs.latitude !== null ||  docs.longitude !== null) && (docs.latitude >= -90 && docs.latitude <= 90) && (docs.longitude >= -180 && docs.longitude <= 180) && (docs.snapshotState !== "removed")){
            return ({
                type: "Feature",
                properties: { 
                    cluster: false,
                    pairId: docs.pairId,
                    geoAccuracy: docs.geoAccuracy,
                    geolocationStatus: docs.geolocationStatus,
                    motionInference: docs.motionInference,
                    lastSeenTimestamp: docs.lastSeenTimestamp,
                    timestamp: docs.timestamp,
                    assetName: docs && docs.primaryAsset && docs.primaryAsset.assetName ? docs.primaryAsset.assetName : "",
                    heading: docs.heading,
                    assetPairId: docs && docs.primaryAsset && docs.primaryAsset.pairId ? docs.primaryAsset.pairId : "",
                    assetId: docs && docs.primaryAsset && docs.primaryAsset._id ? docs.primaryAsset._id : "",
                },
                geometry: { type: "Point", coordinates: [ docs.longitude, docs.latitude]}
            })
        }else {
            return []
        }
    });

    //this function is for first render fitbounds
    const getBoundsForPoints = (points) => {
        

        const filterPoints = points.filter(n => n && n.length !== 0)
        if(!isEmptyArray(filterPoints)){
            const pointsLong = filterPoints.map(point => point.geometry.coordinates[0])
            
            const pointsLat = filterPoints.map(point =>  point.geometry.coordinates[1]) 
       
            const cornersLongLat = [
                [(Math.min.apply( null,pointsLong)), (Math.min.apply(null, pointsLat))],
                [(Math.max.apply(null, pointsLong)), (Math.max.apply(null, pointsLat))]
            ]
 
            const viewport = new WebMercatorViewport({ width: 800, height: 600 })
                     
            const checkCorners = (cornerLongLat) => {
                if(Array.isArray(points) && points.length > 0){
                    return isFinite(cornerLongLat[0][0]) && isFinite(cornerLongLat[0][1]) && isFinite(cornerLongLat[1][0]) && isFinite(cornerLongLat[1][1])
                }         
            }

            if(checkCorners(cornersLongLat)){
                const { longitude, latitude, zoom } = viewport.fitBounds(cornersLongLat, { padding: 200, maxZoom: 13 }) 
                return { longitude, latitude, zoom }        
            }
        }
        else{
            return { longitude: 101.6869, latitude: 3.1390, zoom:10 }
        }
    }
    const [mapping, setMapping] = useState({ width: "100%", height: "100%", latitude: 3.0858333333333334, longitude: 101.58666666666666, pitch: 0, bearing: 0, zoom: 10, });

    // first render map for fitbound useEffect
    useEffect(() => {
        if(Array.isArray(points) && points.length > 0 && isFirstRun){
          const boundary = getBoundsForPoints(points)
          setMapping({ width: "100%", height: "100%", ...boundary})    
          setIsFirstRun(false)
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [points])

    //if data from fetchAllPayload API ready
    useEffect(() => {
        if(!loading && data && data.fetchAllPayload){
            setPayloadData(data.fetchAllPayload)
            setDataOption({
                options: data.fetchAllPayload.map(x => x)
            })
        }

        // console.log("gql", payloadData)
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    //if new data comes from Subscription API
    useEffect(() => {
        onNewSubscribeData(new_payload_id)
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [new_payload_id])

    // console.log("live map", dataOption)


    //function from new data from subscribtion useEffect
    const onNewSubscribeData = async (id) => {
        if(id && (Array.isArray(payloadData) || payloadData)){
            const clone_data = [...payloadData]
            const new_data = [];
        
            // //if the device is not on the map yet
            if(clone_data.filter(x => x.pairId === websocketPayload.pairId).length < 1){ //if sn not exist in payloadData yet
                // console.log("if sn not exist in payload yet")
                clone_data.push(websocketPayload)
                // console.log({clone_data: clone_data}, {ws: websocketPayload})
                // console.log("ws not in map")
                onDataChanges(websocketPayload);
                setDataOption({ options: clone_data.map(x => x) })
                setPayloadData(clone_data); 
            }
            // //if the device is already on the map
            else{
                clone_data && clone_data.map(x => {
                    if(x.pairId === websocketPayload.pairId){
                        new_data.push(websocketPayload);
                    } else{
                        new_data.push(x);
                    }
                    return x;
                })  
                // console.log("ws if already in map", testWS)
                onDataChanges(websocketPayload);
                const result = await Promise.all(new_data);
                setDataOption({ options: result.map(x => x) })
                setPayloadData(result);
            }

        }
    }

    // this useEffect comes from list card, asset status card etc
    useEffect(() => {
        // console.log(selected_asset_info)
        if(!selected_asset_info){
            // console.log("from shortcut button if !selected_asset_info")
            setSelected(null)
            setRadiusDetail(null)            
        }
        if(selected_asset_info){ //[status, accuracy, id, lon, lat]
            // console.log("if selected_asset_info, from shortcut button ")
            if(selected_asset_info.latitude || selected_asset_info.longitude){
                setSelected(selected_asset_info.pairId)
                onDataChanges(selected_asset_info)
            }
            else{
                setSelected(selected_asset_info.pairId)
                onDataChanges(selected_asset_info)
            }

        }       
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected_asset_info])

    const onDataChanges = (new_data) => {
        if(selected === new_data.pairId){
            if((new_data.latitude || new_data.longitude)&&(new_data.latitude >= -90 && new_data.latitude <= 90) && (new_data.longitude >= -180 && new_data.longitude <= 180)){
                const data = [new_data.status, new_data.accuracy, new_data.asset_name, new_data.pairId, new_data.longitude, new_data.latitude]
                setRadiusDetail(data)
                updateMap(data) 
            }         
        }
    }
    
    //props button click
    useImperativeHandle(ref, () => ({ 
        _goToNYC(e) {         
            dataOption.map(docs => {
                return (e === docs.pairId) ? (
                    updateMap(docs)
                ) : null
            })     
        }
    })); 
    

    const updateMap = (data) => {
        // console.log("update Map", data)
        const viewport = {
          ...mapping,
          longitude: data[4],
          latitude: data[5],
          zoom: 15,
          transitionDuration: 700,
          transitionInterpolator: new FlyToInterpolator(),
          transitionEasing: d3.easeCubic
        };
        setMapping(viewport)
    }

    //----firebase analytics----
    const analyticsMarkerGame = (data) => {
        analytics.logEvent("map_marker", {pairId: data})
    }

    //this to create bounds for cluster
    const bounds = mapRef.current && mapRef.current.getMap() ? mapRef.current.getMap().getBounds().toArray().flat() : null


    const { clusters, supercluster } = useSupercluster({ points, bounds, zoom: mapping.zoom, options: { radius: 75, maxZoom: 20 } })

    //this to create a radius
    const layers2= new ScatterplotLayer({
        id: 'scatterplot-layer',
        pickable:true,
        data: radiusDetail,
        radiusScale: 1,
        radiusMinPixels: 0.25,
        opacity: 0.005,
        getRadius: d => radiusDetail[1],
        getPosition: d => [radiusDetail[4], radiusDetail[5]],
        getFillColor: [54, 79, 79],
    })

    const onClick = (e, data) => {
        // console.log('dataaaa', data);
        e.preventDefault();
        // console.log(payloadContext.selected_asset)
        if(payloadContext.selected_asset && data[3] === payloadContext.selected_asset.pairId){ //if marker selected before, unselect
            // console.log("go here onClick button")
            payloadContext.closeAssetCard();
            setSelected(null);
            setRadiusDetail(null);
            setSearchValue({})
        } else{  //selected map
            // console.log("go here onClick button else")
            payloadContext.selectAsset({ pairId: data[3], getAssetInfo: true }, {});
            setSelected(data[3])
            setRadiusDetail(data)  
            setSearchValue({ pairId: data[10], uuid: data[9], name: data[8], paired_asset: '' })          
        }

        updateMap(data)
        analyticsMarkerGame(data[3]);


    }

    // console.log(clusters);


    //marker icon status stop = red, move = green, idle = yellow, towing = blue, else = grey
    const settingIcon = (status, gps_wifi) => {
        if(gps_wifi === "Wifi") return UndefinedIcon;
        switch (status) {
            case 100:
                return StopIcon;
            case 200:
                return MoveIcon;
            case 400:
                return IdleIcon;
            case 500:
                return TowingIcon;
            default:
                return UndefinedIcon;
        }
    }

    const geoLocationStatus = (geolocation) => {
        switch (geolocation) {
            case 0:
                return "GPS" 
            case 16:
                return "Wifi"
            case 17:
                return "NoWifi"
            default:
                return "Garbage"
        }
    }

    const settingDirectionIcon = (status, gps_wifi) => {
        if(gps_wifi === "Wifi") return GreyDirectionIcon;
        switch (status) {
            case 100:
                return RedDirectionIcon;
            case 200:
                return GreenDirectionIcon;
            case 400:
                return YellowDirectionIcon;
            case 500:
                return TowingDirectionIcon;
            default:
                return GreenDirectionIcon;
        }
    }

    const lastSeenDisplay = (value, latitude, longitude) => {
        let wild;;
        if (geoLocationStatus(value[2])) {
            // this to check if status is GPS
            if ((geoLocationStatus(value[2]) === "GPS") || (geoLocationStatus(value[2]) === "Wifi")) {
                wild = false; // not display the history location
                // console.log('this will go to false, bcs GPS or Wifi --- false')
              // to check if the status noWifi or garbage
            } else if((geoLocationStatus(value[2]) === "NoWifi") || (geoLocationStatus(value[2]) === "Garbage")) {
                // if lastSeenTimestamp, lat and lon have value
                if(value[7] && latitude && longitude) {
                    wild = true // display the history location
                    // console.log('if ada lastSeen, latitude and longitude --- true')
                } else {
                    // console.log('else, either lastSeen, latitude, longitude no value --- false')
                    wild = false // not display the history location
                }
            } else {
                // console.log('else if bukan, gps, wifi, nowifi dan garbage --- false')
                wild = false // not display the history location
            }
        } else {
            wild = false // not display the history location
            // console.log('else kalau takde geolocation ---- false')
        }
        return wild;
    }

    //marker status if more than one hour no radar animation display else display animation
    const checkHours = (timestamp) => {
        const remoteTimestamp = firebaseConfig.getValueByKey("live_minute_rule")
        const convertTimestamp = moment(timestamp).format("X")
        const rTimestampInt = remoteTimestamp ? parseInt(remoteTimestamp) : false
        
        const day = new Date(convertTimestamp * 1000);
        const curDay = new Date();

        if(rTimestampInt){
            if(curDay - day <= rTimestampInt){
                return (
                    <div className="radar-box">
                        <div className="pointers"></div> 
                    </div>
                )
            }else{
                return null
            }
        }else{
            return null
        }
    }

    return (
        <div className={classes.box}>
         <MapGL
            {...mapping}
            maxZoom={20}
            width="100%"
            height="100%"
            mapStyle="mapbox://styles/asiamobiliti/ckb1yq6080opd1inb3lkdmbii"
            attributionControl={false}
            onViewportChange={newViewport => {
            setMapping({ ...newViewport });
            }}
            mapboxApiAccessToken={MAPBOX_TOKEN}
            ref={mapRef}
        >
            {
                isDefault && (
                    <div className="navigation-control">
                        <NavigationControl />
                    </div>
                )
            }

            {/* looping if lat and lon is not null display Icon else dont display */}
            {clusters.map(cluster => {
                // console.log(cluster);
            const datas = [cluster.properties.motionInference,
                cluster.properties.geoAccuracy,
                cluster.properties.geolocationStatus,
                cluster.properties.pairId,
                cluster.geometry.coordinates[0],
                cluster.geometry.coordinates[1],
                cluster.properties.timestamp,
                cluster.properties.lastSeenTimestamp,
                cluster.properties.assetName,
                cluster.properties.assetId,
                cluster.properties.assetPairId,
            ]
            const [longitude, latitude] = cluster.geometry.coordinates;

            // console.log("clister", cluster)

            const {
                cluster: isCluster,
                point_count: pointCount
            } = cluster.properties;

            if (isCluster) {
                return ( 
                <Marker
                    key={cluster.properties.pairId}
                    latitude={Number(latitude)}
                    longitude={Number(longitude)}
                    offsetTop={-20}
                    offsetLeft={-18}
                    
                >
                    <div
                    key={cluster.properties.pairId}
                    className={classes.clusterMarker}
                    style={{
                        width: `${10 + (pointCount / points.length) * 20}px`,
                        height: `${10 + (pointCount / points.length) * 20}px`
                    }}
                    onClick={() => {
                        const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.properties.pairId),
                        20
                        );
                        setMapping({
                        ...mapping,
                        latitude,
                        longitude,
                        expansionZoom,
                        zoom: 18,
                        transitionInterpolator: new FlyToInterpolator({
                            speed: 2
                        }),
                        transitionDuration: "auto"
                        });
                    }}
                    >
                    {pointCount}
                    </div>
                </Marker>
                );
            }

            return (
                <Marker
                    key={cluster.properties.pairId}
                    latitude={Number(latitude)}
                    longitude={Number(longitude)}
                    offsetTop={-12}
                    offsetLeft={-11}
                    >
                    <div>
                        <div key={cluster.properties.pairId} className="marker-container" onClick={e => onClick(e, datas)} >

                            {checkHours(datas[6])} 
                            {
                                lastSeenDisplay(datas, latitude, longitude) ? null : cluster.properties.heading ? 
                                <div className="radar-box">
                                    <div className="pointers-static" style={{transform: `rotate(${cluster.properties.heading}deg)`}}>
                                        <img loading="lazy" src={settingDirectionIcon(cluster.properties.motionInference, geoLocationStatus(cluster.properties.geolocationStatus))} className={`${!selected ? "marker-opacity-selected" : (selected === cluster.properties.pairId) ? "marker-opacity-selected" : "marker-opacity-non-selected"}`} style={{height: "10px", width: "10px"}} alt="arrow"/>
                                    </div> 
                                </div> : null
                            }
                            {
                                lastSeenDisplay(datas, latitude, longitude) ? (
                                    <img loading="lazy" src={DarkTime} className={`${ !selected ? "marker-opacity-selected" : (selected === cluster.properties.pairId) ? "hint__dot marker-opacity-selected" : "marker-opacity-non-selected"} remove-border`} alt="Marker" />
                                ) : (
                                    <img loading="lazy" src={settingIcon(cluster.properties.motionInference, geoLocationStatus(cluster.properties.geolocationStatus))} className={`${ !selected ? "marker-opacity-selected" : (selected === cluster.properties.pairId) ? "hint__dot marker-opacity-selected" : "marker-opacity-non-selected"} remove-border`} alt="Marker"/>
                                )
                            }
                            <Tooltip title={cluster.properties.assetName} placement="bottom" arrow={true}>
                                <div className={`${!selected ? "ellipsis-marker marker-opacity-selected" : (selected === cluster.properties.pairId) ? " ellipsis-marker marker-opacity-selected" : " ellipsis-marker marker-opacity-non-selected"}` }>
                                    <MiddleEllipsis>
                                        <span style={{fontSize: "11px", fontWeight: "700", display: "flex", alignItems:"center"}}>{cluster.properties.assetName}</span>
                                    </MiddleEllipsis>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </Marker> 
            );
            })}

            {/* display the radius detail when click the icon */}
            
            { (radiusDetail ? (!radiusDetail[1] ? setRadiusDetail(null) : <DeckGL viewState={mapping} layers={[layers2]} />) : null)}

        </MapGL> 
        <style >
            {
                `   
                    .hint__dot{
                        outline: 0;
                        border: none;
                        border-radius: 30px;
                        transition: all .5s ease-out;
                        animation-name: grow;
                        animation-duration: 900ms; 
                        animation-timing-function: ease-out; 
                        animation-delay: 0;
                        animation-direction: alternate;
                        animation-iteration-count: infinite;
                        animation-fill-mode: none;
                        animation-play-state: running;
                    }
                    .ellipsis-marker{
                        position: absolute;
                        top: 30px;
                        background: #DDDDDD;
                        border: 1px solid #939598;
                        border-radius: 4px;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        font-weight: 700;
                        width: 64px; 
                        height: 14px; 
                        white-space: nowrap;

                    }
                    .marker-container{
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                    }
                    .animation-grow{
                        transition: all .5s ease-out;
                        animation-name: grow;
                        animation-duration: 900ms; 
                        animation-timing-function: ease-out; 
                        animation-delay: 0;
                        animation-direction: alternate;
                        animation-iteration-count: infinite;
                        animation-fill-mode: none;
                        animation-play-state: running;
                    }
                    @keyframes grow {
                        0%   {transform: scale(1);}
                        25%   {transform: scale(1.1);}
                        50%  {transform: scale(1.4);}
                        75%  {transform: scale(1.1);}
                        100% {transform: scale(1);}
                    }
                    
                    .radar-box{
                        width: 24px;
                        height: 24px;
                        position: relative;
                        background-size: 24px 24px;
                        position: absolute;
                        cursor: pointer;
                    }


                    .pointers{
                        position: absolute;
                        z-index: 1024;
                        left: 10.5820106%;
                        right: 10.5820106%;
                        top: 10.5820106%;
                        bottom: 50%;
                        will-change: transform;
                        transform-origin: 50% 100%;
                        border-radius: 50% 50% 0 0 / 100% 100% 0 0;
                        background-image: linear-gradient(135deg, 
                          rgba(255, 255, 255, 1) 20%, 
                          rgba(255, 255, 255, 0.05) 70%,
                          rgba(255, 255, 255, 0) 100%
                          );
                        clip-path: polygon(100% 0, 
                          100% 10%, //控制扇形角度 100% => 20deg
                          50% 100%, 0 100%, 0 0);
                        
                        animation: rotate360 1.5s infinite linear;
                    }
                    .pointers-static{
                        display: flex;
                        justify-content: center;
                        position: absolute;
                        z-index: 1024;
                        left: 11.582011%;
                        right: 10.5820106%;
                        top: -31.417989%;
                        bottom: 50%;
                        will-change: transform;
                        transform-origin: 50% 100%;
                        border-radius: 50% 50% 0 0 / 100% 100% 0 0;
                        background-image: linear-gradient(0deg, 
                          rgba(255, 255, 255, 0) 20%, 
                          rgba(255, 255, 255, 0) 70%,
                          rgba(255, 255, 255, 0) 100%
                          );
                        clip-path: polygon(100% 0, 
                          100% 10%, //控制扇形角度 100% => 20deg
                          50% 100%, 0 100%, 0 0);
                        
                        // animation: rotate360 1.5s infinite linear;
                        // transform: rotate(0deg);
                    }

                    @keyframes rotate360 {
                        0% {
                          transform: rotate(0deg);
                        }
                        to {
                          transform: rotate(-360deg);
                        }
                      }
                    .marker-opacity-selected{
                        // width: 100px;
                        opacity: 1.0;
                    }
                    .marker-opacity-non-selected{
                        opacity: 0.65;
                    }
                    .navigation-control{
                        position: absolute;
                        right : 16px;
                        bottom: 16px;
                        z-index: 10;
                        box-shadow: 0px 1px 3px 0px #00000020;
                    }
                    .mapboxgl-marker{
                        z-index: 4;
                    }
                    .aura-icon{
                        position: relative;
                        width: 16px;
                        bottom: 18px;
                        left: 29px;
                    }
                    
                `
            }
        </style>
        </div>
    )
}

export default Map
