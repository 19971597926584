import React,{useState, useContext, useMemo} from 'react'
import { snakeCase } from "snake-case"

//image
import ChevronLightIcon from "../../Image/chevron_light.svg"
import AssetGroupIcon from "../../Image/asset_groups.svg"
import GeofeningIcon from "../../Image/geofencing.svg"
import DeviceIcon from "../../Image/device.svg"
import SmartlockIcon from '../../Image/smart_lock_dark.svg'


import {firebaseContext} from '../../config/config-firebase/FirebaseContext'

const geofenceSize = 2 //total geofence to be displayed
const deviceSize = 1 //total devices to be displayed

const Details = ({data, collapse, CollapseCard, assetFeatureIcons,  featureList, ViewDetails, SelectAsset, setPageInd, setFilterFinal, setMainAssetSelection, featureDisable} ) => {
    const [isHovered, setIsHovered] = useState({
        container: false,
        details: false
    })

    
    const firebaseConfig = useContext(firebaseContext)


    // console.log(assetFeatureIcons)
    // console.log(featureList)
    // console.log(featureList.map(x => assetFeatureIcons.includes(x.uuid)))
    // clg

    const onCollapse = (e) => {
        CollapseCard(e)
        setMainAssetSelection(data)
    }

    //seperate smartlocks dvc and telematics dvc
    const telemetics_dvc = data && data.asset_device && data.asset_device.filter(x => x.device_type !== "Smartlocks")
    const smartlock_dvc = data && data.asset_device && data.asset_device.filter(x => x.device_type === "Smartlocks")


    return (
        <div className="card-container" onMouseEnter={() => setIsHovered((current) => ({...current, container: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, container: false}))} style={{ height: !collapse && "65px"}}>
            {/* <div className="checkbox-container" >
                <input type="checkbox" className="check-type" onChange={(e) => SelectAsset(e)}/>
            </div> */}
            <div style={{width: "100%"}} >
            <div className="asset-container">
            <div className="details asset-name-type" onClick={(e) => onCollapse(e)}>
                        <div className="collapsible">
                            <div className="asset-name">{data.name}</div>
                            <div className="row-label-value asset-type-details">
                        <div className="input-label">Type </div>
                        <div className="row-value asset-type">{data.type ? data.type : "-"}</div>
                        </div>                        
                        </div>
                <div className="collapse-on-active" style={{maxHeight: collapse ? null : "0"}}>
                    <div className="row-label-value asset-type-details">
                        <div className="input-label">Model </div>
                        <div className="row-value asset-model">{data.model ? data.model : "-"}</div>
                    </div>
                    <div className="row-label-value asset-type-details">
                        <div className="input-label">Manufacturer </div>
                        <div className="row-value asset-manufacturer">{data.manufacturer ? data.manufacturer : "-"}</div>
                    </div>
                    <div className="asset-description">
                        {firebaseConfig.getValueByKey("client_asset_overview_detail")}
                    </div>                        
                </div>
            </div>
            <div className="line-box" style={{height: !collapse && "47px"}}></div>
            <div className="details asset_group-geofences-container" onClick={(e) => CollapseCard(e)}>
                <div className={`${collapse && "smaller-flex-details"} asset-group-container collapsible`}>
                    <div className="input-label">Asset Group</div>
                    <div className="details-with-image asset-group-details">
                        <img loading="lazy" src={AssetGroupIcon} alt="asset-group"/>
                        <div className="details-with-image-name asset-group-name">{data.asset_group && data.asset_group.name ? data.asset_group.name : "-"}</div>
                    </div>
                </div>
                <div className={`${collapse && "larger-flex-details"} collapse-on-active`} style={{maxHeight: collapse ? null : "0"}} >
                    <div className="divide-bottom"></div>
                    <div className="details geofences">
                        <div className="input-label">Geofence Policy ({data.geofences ? data.geofences.length : 0})</div>
                        {
                            data.geofences && data.geofences.slice(0, geofenceSize).map(x => {
                                return(
                                    <div className="details-with-image list-of-policy">
                                        <img loading="lazy" src={GeofeningIcon} alt="geo-icon"/>
                                        <div className="details-with-image-name geofence-name">{x.name ? x.name : "-"}</div>
                                    </div>
                                )
                            })
                        }
                        { (data.geofences && data.geofences.length - geofenceSize) > 0 && <div className="view-more">{data.geofences && data.geofences.length - geofenceSize} More</div> }
                    </div>
                </div>

            </div>
            <div className="line-box" style={{height: !collapse && "47px"}}></div>
            <div className="details devices-features" onClick={(e) => CollapseCard(e)}>
                <div className={`${collapse && "larger-flex-details"} asset-devices collapsible`}>
                    <div className="input-label">Devices({telemetics_dvc.length})</div>  
                    {
                        telemetics_dvc.length > 0 ?  telemetics_dvc.slice(0, deviceSize).map(x => {
                            return(
                                <div className="list-of-devices">
                                    <div className="details-with-image device-details">
                                        <img loading="lazy" src={DeviceIcon} alt="geo-icon"/>
                                        <div className="details-with-image-name  device-sn">{x.sn}</div>
                                    </div>                                                                
                                </div>
                            )
                        }) : ( 
                            <div className="list-of-devices">
                                <div className="details-with-image device-details">
                                    <img loading="lazy" src={DeviceIcon} alt="geo-icon"/>
                                    <div className="details-with-image-name  device-sn"> - </div>
                                </div>                                                                
                            </div>
                        ) 
                    } 
                    { collapse && <div className="input-label">Smartlocks({smartlock_dvc.length})</div> } 
                    {
                        collapse ? smartlock_dvc.length > 0 ? smartlock_dvc.slice(0, deviceSize).map(x => {
                            return(
                                <div className="list-of-devices">
                                    <div className="details-with-image device-details">
                                        <img loading="lazy" src={SmartlockIcon} alt="geo-icon"/>
                                        <div className="details-with-image-name  device-sn">{x.sn}</div>
                                    </div>                                                                
                                </div>
                            )
                        }) : ( 
                            <div className="list-of-devices">
                                <div className="details-with-image device-details">
                                    <img loading="lazy" src={SmartlockIcon} alt="geo-icon"/>
                                    <div className="details-with-image-name  device-sn"> - </div>
                                </div>                                                                
                            </div>
                        ) : null
                    }
                    { (collapse && data && data.asset_device && data.asset_device.length - deviceSize) > 0 && <div className="view-more margin-space">{data && data.asset_device && data.asset_device.length - deviceSize} More</div> }
                </div>
                <div className={`${collapse && "smaller-flex-details"} collapse-on-active`} style={{maxHeight: collapse ? null : "0"}}>
                    <div className="divide-bottom"></div> 
                    <div className="feature-list">
                        <div className="details-with-image device-details column-details">
                            <div className="input-label asset-group-title">Features</div>  
                            <div className="feature-icon-list">
                                {
                                    featureList.length > 0 && featureList.map(feature => {
                                        // console.log(feature)
                                        try{
                                            return assetFeatureIcons.includes(feature.uuid) ? <img loading="lazy" className="img-feature" style={{width:"20px", height:"20px"}}  src={`${feature.icon}/icon_${snakeCase(feature.name)}_enabled.svg`} alt="v"/> : <img loading="lazy" className="img-feature" style={{width:"20px", height:"20px"}}  src={`${feature.icon}/icon_${snakeCase(feature.name)}_disabled.svg`} alt="v"/>
                                        }catch(err){
                                            console.log("Feature", err)
                                        }
                                    })
                                }                                    
                            </div>

                        </div>    
                    </div>                          
                </div>
            </div>
            <div className="details-button-container collapse-on-active" style={{maxHeight: collapse ? null : "0"}}>
            {
                isHovered.container && (
                    <div className={`${isHovered.details ? "isHovered-button-details": "button-details"}`} onClick={e => ViewDetails(e, data)} onMouseEnter={() => setIsHovered((current) => ({...current, details: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, details: false}))}>
                        <img loading="lazy" src={ChevronLightIcon} alt="test"/>
                    </div>                        
                )
            }
            </div>                            
            </div>     
    
            </div>
        </div>
    )
}

export default Details
