import React, {useState, useEffect} from 'react'

//component
import Form from './Form'
import { onUserEdit, AllMandatoryFilled } from "../../config/config-function/function";
import {requiredField} from '../../config/config-table/table'
import { SKU_REDUCER } from "../../config/config-reducer/reducer";

const Edit = ({ values, onChange, setEditVariables, displayed, query, fx_back, setCanUpdate, canUpdate, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere }) => {
    const [assemblly, setAssemblly] = useState([...values[0].assemblies]) 
    const [featureAdd, setFeatureAdd] = useState([])
    const [featureRemove, setfeatureRemove] = useState([])
    const [skuID, setSkuID] = useState(values[0].ID)
    const [feature, setFeature] = useState(values[0].features ? [...values[0].features] : [])

    const onUserInput = (e, value, isValue) => {
        let input = onUserEdit(e, value, isValue, onChange, values)

        const _check = AllMandatoryFilled(requiredField.sku, input[0])


        if ((input[0] && input[0].productModel === 'Teltonika') || (input[0] && input[0].productModel === 'Smart_Lock')) {
            input[0] && input[0].id ? setCanUpdate(true) : setCanUpdate(false)
        } else {
            _check ? setCanUpdate(true) : setCanUpdate(false)
        }

        setEditVariables({ skus: !input ?  [SKU_REDUCER(values[0], assemblly)] : [SKU_REDUCER(values[0], assemblly)] });
    };

    // console.log('values', values);

    useEffect(() => {
        onUserInput(null, { id: skuID}, true)

        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skuID])

    // useEffect(() => {
    //     onUserInput(null, {feature_add : feature.map(x => {return {uuid: x}})}, true)

    //     //---this is to disable the warning for useEffect----//
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [feature])

    // useEffect(() => {
    //     onUserInput(null, { feature_remove: featureRemove}, true)

    //     //---this is to disable the warning for useEffect----//
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [featureRemove])

    useEffect(() => {
        onUserInput(null, { features: feature.length > 0 ? feature.map((x) => { return { uuid: x}}) : []}, true)
    }, [feature])

    useEffect(() => {
        onUserInput(null, { assemblies: Array.isArray(assemblly) ? assemblly.map(x => { return { boardClass: x.boardClass, assemblyId: x.assemblyId, schematicId: x.schematicId }}) : []}, true)
        
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assemblly])

    return <Form sku={values} onUserInput={onUserInput} displayed={displayed} assemblly={assemblly} setAssemblly={setAssemblly} query={query} fx_back={fx_back} featureAdd={featureAdd} featureRemove={featureRemove} setFeatureAdd={setFeatureAdd} setfeatureRemove={setfeatureRemove} skuID={skuID} setSkuID={setSkuID} feature={feature} setFeature={setFeature} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} setErrMsg={setErrMsg} setErrOpen={setErrOpen} setErrSevere={setErrSevere}/>
}

export default Edit
