import axios from 'axios';

const url = process.env.REACT_APP_RESTAPI;
const xApiKey = process.env.REACT_APP_XAPIKEY;

export const APICreateAgencies = async (agencyData, token, agencyId, setLoading) => {
    setLoading(true);
    const result = await axios.post(`${url}/agency`, agencyData, {
        headers: {
            'x-api-key': xApiKey,
            'Authorization':  token,
            'agency-id': agencyId,
        },
    }).catch((error) => error);
    return result;
}

export const APICreateBeacon = async (beaconData, token, agencyId, setLoading) => {
    setLoading(true);
    const result = await axios.post(`${url}/beacon`, beaconData, {
        headers: {
            'x-api-key': xApiKey,
            'Authorization':  token,
            'agency-id': agencyId, 
        },
    }).catch((error) => error);
    return result;
}

export const APISendTeltonikaCommand = async (commandData, token, agencyId, setLoading) => {
    setLoading(true);
    const result = await axios.post(`${url}/teltonikaCommand`, commandData, {
        headers: {
            'x-api-key': xApiKey,
            'Authorization':  token,
            'agency-id': agencyId, 
        },
    }).catch((error) => error.response.status);
    return result;
}

export const APICreateSKU = async (skuData, token, agencyId, setLoading) => {
    setLoading(true);
    const result = await axios.post(`${url}/skus`, skuData, {
        headers: {
            'x-api-key': xApiKey,
            'Authorization':  token,
            'agency-id': agencyId, 
        },
    }).catch((error) => error);
    return result;
}

export const APICreateGeofence = async (geofenceData, token, agencyId, setLoading) => {
    setLoading(true);
    const result = await axios.post(`${url}/geofence`, geofenceData, {
        headers: {
            'x-api-key': xApiKey,
            'Authorization':  token,
            'agency-id': agencyId, 
        },
    }).catch((error) => error);
    return result;
}

export const APICreateChannel = async (channelData, token, agencyId, setLoading) => {
    setLoading(true);
    const result = await axios.post(`${url}/channel`, channelData, {
        headers: {
            'x-api-key': xApiKey,
            'Authorization':  token,
            'agency-id': agencyId,  
        },
    }).catch((error) => error);
    return result;
}