import React, { useState } from 'react'
import GreyGeofencIcon from "../../Image/grey_geofencing.svg"

const GeofenceSearch = ({setKeySearch, keySearch, APICalled}) => {
    const [seachProtocol, setSeachProtocol] = useState("")
    // console.log('searchProtocol', seachProtocol);

    const onEnterKey = (event) => {       
        if (event.key === "Enter"){ 
            setKeySearch(event.target.value);
            APICalled(event.target.value, '')
        }      
    }
    return (
        <div className="search-policy-list">
            <input type="text" className="geo-search" onKeyDown={onEnterKey} value={keySearch} placeholder="Search Geofence" onChange={(e) => setKeySearch(e.target.value)}/>
            <style jsx>
                {
                    `
                        .geo-search{
                            width: 100%;
                            box-shadow: 0px 1px 3px 0px #00000020;
                            border: 0px solid #ffffff;
                            border-radius: 4px;
                            font-size: 14px;
                            background-color: white;
                            background-image: url(${GreyGeofencIcon});
                            background-position: 8px 8px;
                            background-repeat: no-repeat;
                            padding: 14px 40px 14px 40px;
                            -webkit-transition: width 0.4s ease-in-out;
                            transition: width 0.4s ease-in-out; 
                            -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
                            -moz-box-sizing: border-box;    /* Firefox, other Gecko */
                            box-sizing: border-box;         /* Opera/IE 8+ */            
                        }
                        .search-policy-list{
                            
                        }
                    `
                }
            </style>
        </div>
    )
}

export default GeofenceSearch
