import React, {useState, useEffect} from 'react'
import moment from "moment";


import Overview from './Overview'

import { useQuery } from "@apollo/react-hooks"
import {GET_DEVICES, CHECK_SMARTLOCK_SYNC} from '../../config/config-graphql/Graphql'
import Loaders from "../../component/component-loaders/Loaders"
import SnackBar from '../../config/config-snackbar/SnackBar'

moment.updateLocale('en', {
    relativeTime : {
        future: (diff) => (diff === 'a few second(s) ago' ? diff : `in ${diff}`),
        past: (diff) => (diff === 'a few second(s) ago' ? diff : `${diff}`),
        s:  'a few second(s) ago',
        ss: "a few second(s) ago",
        m:  "a minute ago",
        mm: "%d minutes ago",
        h:  "an hour ago",
        hh: "%d hours ago",
        d:  "a day ago",
        dd: "%d days ago",
        w:  "a week ago",
        ww: "%d weeks ago",
        M:  "a month ago",
        MM: "%d months ago",
        y:  "a year ago",
        yy: "%d years ago"
    }
});
moment.relativeTimeThreshold('s', 59);
moment.relativeTimeThreshold('m', 60);


const PanelIgloohome = ({ filter }) => {

    const { data, loading, error } = useQuery(GET_DEVICES, { variables: { filter:  {}, type: "Smartlocks" }});
    const { data: datacheck, loading:loadingCheck, error:errorCheck } = useQuery(CHECK_SMARTLOCK_SYNC, { variables: {}});
    const [details, setDetails] = useState([])
    const [dateSync, setDateSync] = useState()

    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")

    // !loading && console.log(data)

    useEffect(() => {
        if(!loading && data && data.get_devices){
            let latestDate = new Date(Math.max(...data.get_devices.devices.map(e => new Date(e.date_smartlock_pair))))
            setDateSync(moment(latestDate).fromNow(true))
            setDetails(data.get_devices.devices)
        }
    }, [loading])

    // console.log(typeof dateSync)

    useEffect(() => {
        if(error){
            setSnOpen(true)
            setMessage("Error: Unable to retrieve data. Please try again later.")
            setSeverity("error")
        }
    }, [error])
    return loading ? <Loaders /> : error ? <SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/> :(
        <Overview details={details} loading={loading} data={datacheck && datacheck.check_smartlock_sync ? datacheck.check_smartlock_sync : []} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} dateSync={dateSync} getQuery={{checkDevice: GET_DEVICES, checkSmartlock:  CHECK_SMARTLOCK_SYNC}}/>
    )
}

export default PanelIgloohome
