import React from 'react'

const Policy = ({history}) => {
    return (
        <div className="policy-container">
            <div className="main-container-policy">
                <h1>Privacy Policy</h1>
                <section>
                    <b>ASIA MOBILITY TECHNOLOGIES SDN BHD</b> is committed to maintaining robust privacy protections for its users. 
                    Our Privacy Policy is designed to help you understand how we collect, use and safeguard the information you
                    provide to us. By accepting our Privacy Policy and Terms of Use, you consent to our collection, 
                    storage and use of your personal information as described in this Privacy Policy.
                </section>
                <h2>1. INFORMATION WE COLLECT</h2>
                <section className="section-container">We collect “Non-Personal Information” and “Personal Information.”</section>
                <section className="section-container">
                    Non-Personal Information includes information that cannot be used to personally identify you, 
                    such as anonymous usage data, general demographic information we may collect, referring/exit pages and URLs, 
                    platform types, preferences you submit and preferences that are generated based on the data you submit and 
                    number of clicks.
                </section>
                <section className="section-container">
                    Personal Information includes your email, phone number and date of birth, 
                    which you submit to us through the registration process.
                </section>
                <h3>Information collected via your interaction with us</h3>
                <section className="section-container">
                    In an effort to improve the quality of the service, we track information provided to us by your browser 
                    or phone when you use our services. This includes information such as the website you came from (known as the “referring URL”),
                    the type of browser you use, the device from which you connected to our services, the time and date of access, 
                    and other information that does not personally identify you. We track this information using cookies, 
                    or small text files which include an anonymous unique identifier. Cookies are sent to a user’s browser from 
                    our servers and are stored on the user’s computer hard drive. Sending a cookie to a user’s browser enables us 
                    to collect Non-Personal information about that user and keep a record of the user’s preferences when utilizing 
                    our services, both on an individual and aggregate basis.
                </section>
                <section className="section-container">
                    The Company may use both persistent and session cookies; persistent cookies remain on your computer 
                    after you close your session and until you delete them, while session cookies expire when you close your browser.
                </section>
                <h3>Information you provide us by registering for an account</h3>
                <section className="section-container">
                    To use our service, you will need to create a personal profile, which includes your email address, 
                    phone number, gender, date of birth, location and other information. You will also be creating a user 
                    name and a password. By registering, you are authorizing us to collect, store and use your email 
                    address in accordance with this Privacy Policy.
                </section>
                <h2>2. HOW WE USE AND SHARE INFORMATION</h2>
                <h3>Personal Information</h3>
                <section className="section-container">
                    We do not sell, trade, rent or otherwise share your Personal Information with third parties, 
                    other than necessary to provide our website services and communicate directly with you, concerning 
                    our services. In general, the Personal Information you provide to us is used to help us communicate 
                    with you. For example, we use Personal Information to contact users in response to questions, solicit 
                    feedback from users, provide technical support, and inform users about promotional offers.
                </section>
                <h3>Non-Personal Information</h3>
                <section className="section-container">
                    In general, we use Non-Personal Information to help us improve the service and customize the user 
                    experience. We also aggregate Non-Personal Information in order to track trends and analyze use patterns on the Site. 
                    This Privacy Policy does not limit in any way our use or disclosure of Non-Personal Information and we reserve the right 
                    to use and disclose such Non-Personal Information to our partners, advertisers and other third parties at our 
                    discretion.
                </section>
                <section className="section-container">
                    In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our 
                    assets, your Personal Information may be among the assets transferred.
                </section>
                <section className="section-container">
                    You acknowledge and consent that such transfers may occur and are permitted by this Privacy Policy, and that 
                    any acquirer of our assets may continue to process your Personal Information as set forth in this Privacy Policy.
                </section>
                <section className="section-container">
                    If our information practices change at any time in the future, we will post the policy changes to the website 
                    so that you may opt out of the new information practices. We suggest that you check the website periodically if 
                    you are concerned about how your information is used.
                </section>
                <h2>3. HOW WE PROTECT INFORMATION</h2>
                <section className="section-container">
                    We implement security measures designed to protect your information from unauthorized access. 
                    Your account is protected by your account password and we urge you to take steps to keep your personal 
                    information safe by not disclosing your password. We further protect your information from potential 
                    security breaches by implementing certain technological security measures including encryption, 
                    firewalls and secure socket layer technology.
                </section>
                <section className="section-container">
                    However, these measures do not guarantee that your information will not be accessed, disclosed,
                    altered or destroyed by breach of such firewalls and secure server software. 
                    By using our Service, you acknowledge that you understand and agree to assume these risks.
                </section>
                <h2>4. YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION</h2>
                <section className="section-container">
                    You have the right at any time to prevent us from contacting you for marketing purposes. 
                    When we send a promotional communication to a user, the user can opt out of further promotional 
                    communications by following the unsubscribe instructions provided in each promotional email.
                </section>
                <section className="section-container">
                    Please note that notwithstanding the promotional preferences you indicate by either unsubscribing 
                    or opting out in the Settings section of the Site, we may continue to send you administrative emails 
                    including, for example, periodic updates to our Privacy Policy.
                </section>
                <h2>5. LINKS TO OTHER WEBSITES</h2>
                <section className="section-container">
                    As part of providing you service, we may provide links to or compatibility with other websites or applications. 
                    However, we are not responsible for the privacy practices employed by those websites or the information or content 
                    they contain. This Privacy Policy applies solely to information collected by us through this website and service. 
                    We encourage our users to read the privacy statements of other websites before proceeding to use them.
                </section>
                <h2>6. CHANGES TO OUR PRIVACY POLICY</h2>
                <section className="section-container">
                    The Company reserves the right to change this policy and our Terms of Service at any time. 
                    We will notify you of significant changes to our Privacy Policy by sending a notice to the primary 
                    email address specified in your account or by placing a prominent notice on our website. 
                    Significant changes will go into effect 14 days following such notification. Non-material changes or 
                    clarifications will take effect immediately. You should periodically check the website and this privacy 
                    page for updates.
                </section>
                <h2>7. CONTACT US</h2>
                <section className="section-container">
                    If you have any questions regarding this Privacy Policy, please email <a href="mailto:privacy@asiamobiliti.com">privacy@asiamobiliti.com</a>
                </section>
                <section className="section-container">Last Updated: This Privacy Policy was last updated on March 29th, 2021.</section>
                <div className="btn-back-div">
                    <div className="btn-back-container" onClick={() => history.push("/login")}>Back</div>
                </div>

            </div>           
            <style>
                {
                    `.policy-container{
                        padding: 24px 50px 24px 50px;
                        display: flex;
                        justify-content: center;
                    }
                    .main-container-policy{
                        width: 50%;
                        
                    }
                    .section-container{
                       margin: 0px 0px 24px 0px;
                    }
                    .btn-back-div{
                        display: flex;
                        justify-content: center;
                    }
                    .btn-back-container{
                        display: flex;
                        justify-content: center;
                        align-items:center;
                        height: 30px;
                        width: 100px;
                        color: #FFFFFF;
                        background: #509D91;
                        border-radius: 2px;
                        cursor: pointer;
                    }
                    input[type="button"]{
                        outline:none;
                     }
                    `
                }
            </style>
        </div>
    )
}

export default Policy
