import React from 'react'
import {DrawPolygonMode} from "react-map-gl-draw";

//images
import DarkPolygonIcon from "../../Image/polygon_dark.svg"
import CloseIcon from "../../Image/close.svg";
import GreenCloseIcon from '../../Image/green_close_button.svg'
import GreenAddIcon from '../../Image/green_add.svg'
import GreyAddIcon from '../../Image/grey_add.svg'
import GreenPolygonsIcon from '../../Image/green_polygons.svg'

const DrawPolygon = {id: "drawPolygon", text: "Draw Polygon", handler: DrawPolygonMode}

const GeofenceAreaAddCard = ({polygons, wrapperRef, selectedFeatureIndex, btnPolyList, btnPolygon, onChangeText, onDelete, onDrawPolygon, modeId}) => {

    return (
        <div className="geofence-area-container">
            <header className="geofence-title">
                <p className="geofence-area-text">Geofence Area</p>
            </header>
            {
                Object.keys(polygons) ? 
                    (
                        <div ref={wrapperRef} className="list-polygons" >
                            {
                                polygons.map((x,i) => {
                                    return  (
                                        <div key={i} onClick={() => btnPolyList(i,x)} className="polygon-container-hover">
                                            <div style={{width: "100%", display:"flex"}}>
                                                <img loading="lazy" src={selectedFeatureIndex.selectedFeatureIndex !== i ? DarkPolygonIcon : GreenPolygonsIcon} alt="dark-polygon" className="dark-polygon-img"/>
                                                <input type="text" className={selectedFeatureIndex.selectedFeatureIndex !== i ? 'input-text' : 'input-text-highlight'} value={polygons[i].name} onChange={(e) => onChangeText(e, i)} placeholder="Enter Area Name"/>
                                            </div>
                                            <div onClick={() => onDelete(i)} className="close-icon">
                                                <img loading="lazy" src={selectedFeatureIndex.selectedFeatureIndex !== i ? CloseIcon : GreenCloseIcon} alt="close-icon" />
                                            </div>                                                                                     
                                        </div>
                                    )
                                })
                            }

                        </div>
                    ) : <div></div>
            }
            <div className="btn-list-area">
                <img loading="lazy" src={modeId ? GreyAddIcon : GreenAddIcon} alt="Icon" onClick={() => btnPolygon(DrawPolygon.id)} style={{cursor: "pointer"}} className=""/>
                <div>
                    <p className="draw-polygon">Draw Polygon</p>
                </div>
            </div>
        </div>
    )
}

export default GeofenceAreaAddCard