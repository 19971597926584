import React, { useState, useContext, useEffect} from 'react'

//image
import AlertRedIcon from '../../Image/alert_red.svg'
import GreySearchIcon from '../../Image/grey_search.svg'

//library
import moment from "moment-timezone";
import DateFnsUtils from '@date-io/date-fns';
import Cookies from "js-cookie";

//material ui
import TextField from '@material-ui/core/TextField';
import Autocomplete from "@material-ui/lab/Autocomplete";
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import grey from "@material-ui/core/colors/grey";
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, InputAdornment } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

//component
import { firebaseContext } from "../../config/config-firebase/FirebaseContext"
import Loaders from "../../component/component-loaders/Loaders"
import SnackBar from '../../config/config-snackbar/SnackBar'
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader"

import { isEmptyObject } from '../../config/config-function/function';


//this theme for material UI calendar (overwrite the css)
const apadMaterialTheme = createMuiTheme({

    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: grey.A400,
            },
        },
        MuiPickerDTTabs: {
            tabs: {
                backgroundColor: grey.A400,
            }
        },
        MuiPickersDay: {
            day: {
                color: grey.A400,
            },
            daySelected: {
                backgroundColor: grey["400"],
            },
            dayDisabled: {
                color: grey["100"],
            },
            current: {
                color: grey["900"],
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: grey["400"],
            },
        },
        MuiPickersClock: {
            pin: {
                backgroundColor: grey["400"],
            }
        },
        MuiPickersClockPointer: {
            pointer: {
                backgroundColor: grey["400"],
            }
        },
    }
});

//this for overwrite the css for material ui autocomplete
const component = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            clearIndicator: {
                "&:hover":{
                    backgroundColor: "#FFFFFF",
                }
            },
            endAdornment:{
                top: "0px",
                height: "100%",
                display: "flex",
                alignItems: "center"
            },
            popupIndicator:{
                "&:hover":{
                    backgroundColor: "#FFFFFF",
                }
            } 
        },
        MuiIconButton:{
            label:{
                height:"24px",
                width: "24px",
                "&:hover":{
                    backgroundColor: "rgba(0,0,0,0.03)",
                    borderRadius: "5px",
                },
            }
        }
    }
});

const loaderOveride = css`
  position: absolute;
  top: 225px;
  right: -10px;
  margin: 0 auto;
  border-color: red;
`;

//this for custom css for textfield and autocomplete
const useStyles = makeStyles((theme) => ({
    textField: {
        backgroundColor: "#FFFFFF",
        // width: 240,
        // height: 40,
        marginLeft: 0,
        outline: "none"
    },
    autocomplete: {
        backgroundColor: "#FFFFFF",
        // outline: "none"
    }
}))

//dropdownlist value for alert type

const PanelReportAPAD = () => {
    const classes = useStyles()
    const firebaseConfig = useContext(firebaseContext)
    const tz = moment.tz.guess();
    moment.tz.setDefault(tz);

    //state for chnage page to driver table card
    const [routesN, setRoutesN] = useState([])
    const [dateValidate, setdateValidate] = useState(false)
    const [btndis, setBtndis] = useState(false)
    const lRType = [
        {type:"SNR", name:"Summary By Network Report", firstLetter: "S"},
        {type:"SM", name:"Summary Report", firstLetter: "S"},
        {type:"TR", name:"Trip Report", firstLetter: "T"},
        {type:"SD", name:"Sales Details", firstLetter: "S"},
        {type:"SR", name:"Summary By Route", firstLetter: "S"},
    ]

    const [isHovered, setIsHovered] = useState({
        btnReport: false
    })

    //this state for error handling on getQuery
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")
    const [loading, setLoading] = useState(false)

    const requestOptions = {
        method: 'GET',
        headers: {
          // 'Content-Type': 'application/json',
          'agency': Cookies.get("agency-id"),
          'token': Cookies.get("jwtToken")
        },
      }; 
    

    
      

    //state value for search period, asset and alert type
    const [dataset, setDataset] = useState({
        fromTime: moment().startOf('month').tz(tz).format(),
        toTime: moment().endOf('month').tz(tz).format(),
        currentTime: moment().tz(tz).format(),
        route: [],
        reportType: {}
    })



    //this to display the route Number list
    useEffect(() => {
        const url = `${process.env.REACT_APP_BASFAIR_ENDPOINT}/v1/getRoutes`
        fetch(
            url, requestOptions //this to add in the endpint
          )
          .then(response => response.json())
          .then(data => {
              if(data && data.length > 0){
                const searchOptions = data.map((x) => {
                        return x.routeId ? {firstLetter: x.routeId.charAt(0).toUpperCase(), routeId: x.routeId, routeLongName: x.routeLongName} : {firstLetter: "", routeId: "", routeLongName: ""}
                    })
                
                setRoutesN(searchOptions)
              }       
          })    
          .catch(error => {return Promise.reject({ Error: 'Something Went Wrong', error, }, snOpen(true), setMessage("Something Went Wrong"), setSeverity("error"))});
    }, [])

    ////----this is to check the validation based on dataset.fromtime and dataset.totime)----//////
    useEffect(() => {
        checkValidation()
    }, [dataset.fromTime])

    useEffect(() => {
        checkValidation()
    }, [dataset.toTime])
    ////-------/////

    //onUserInput (onChange event) for all "e" for date and "value" for route and report type
    const onUserInput = (e, value) => {
        value ? setDataset({ ...dataset, ...value }) :
        setDataset({ ...dataset, ...e })
    }

    //this to generate all the report 
    const generateReport = (e) => {
        e.preventDefault()
        setLoading(true)
        setBtndis(true)
        const fromDate = moment(`${dataset.fromTime}`).tz(tz).set({ hour: 0, minute: 0, second: 0 }).format()
        const toDate = moment(`${dataset.toTime}`).tz(tz).set({ hour: 0, minute: 0, second: 0 }).format()
        const curDate = dataset.currentTime
        const route = dataset.route.length > 0 ? dataset.route.map(x => `"${x.routeId}"`) : routesN.map(x => `"${x.routeId}"`)
        const type = dataset.reportType.type


        //generate summary Network report
        if(type === "SNR"){
            const url = `${process.env.REACT_APP_BASFAIR_ENDPOINT}/v1/lta/summaryByNetworkReport?startDate=${encodeURIComponent(fromDate)}&endDate=${encodeURIComponent(toDate)}&currDate=${encodeURIComponent(curDate)}&route=[${route}]` 
            fetch(
                url, requestOptions //this to add in the endpint
            )
              .then(res => res.blob())
              .then(blob => {
                const href = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'SummaryNetworkReport.xlsx'); //or any other extension
                document.body.appendChild(link)
                link.click();
                setLoading(false)
                setBtndis(false)
            })
            .catch(error => {return Promise.reject({ Error: 'Something Went Wrong', error }, snOpen(true), setMessage("Something Went Wrong"), setSeverity("error"))});
        }

        //generate summary report
        if(type === "SM"){
            const url =  `${process.env.REACT_APP_BASFAIR_ENDPOINT}/v1/lta/summaryReport?startDate=${encodeURIComponent(fromDate)}&endDate=${encodeURIComponent(toDate)}&currDate=${encodeURIComponent(curDate)}&route=[${route}]`
            fetch(
                url, requestOptions //this to add in the endpint
            )
              .then(res => res.blob())
              .then(blob => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'SummaryReport.xlsx'); //or any other extension
                document.body.appendChild(link)
                link.click();
                setLoading(false)
                setBtndis(false)
              })
              .catch(error => {return Promise.reject({ Error: 'Something Went Wrong', error }, snOpen(true), setMessage("Something Went Wrong"), setSeverity("error"))});
        }

        ////generate summary Trip report
        if(type === "TR"){
            const url = `${process.env.REACT_APP_BASFAIR_ENDPOINT}/v1/lta/tripReport?startDate=${encodeURIComponent(fromDate)}&endDate=${encodeURIComponent(toDate)}&currDate=${encodeURIComponent(curDate)}&route=[${route}]`  
            fetch(
                url, requestOptions //this to add in the endpint
            )
              .then(res => res.blob())
              .then(blob => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'TripReport.xlsx'); //or any other extension
                document.body.appendChild(link)
                link.click();
                setLoading(false)
                setBtndis(false)
              })
              .catch(error => {return Promise.reject({ Error: 'Something Went Wrong', error }, snOpen(true), setMessage("Something Went Wrong"), setSeverity("error"))});
        }

        //generate sales detail report
        if(type === "SD"){
            const url = `${process.env.REACT_APP_BASFAIR_ENDPOINT}/v1/lta/salesDetailsReport?startDate=${encodeURIComponent(fromDate)}&endDate=${encodeURIComponent(toDate)}&currDate=${encodeURIComponent(curDate)}&route=[${route}]`
            fetch(
                url, requestOptions //this to add in the endpint
            )
              .then(res => res.blob())
              .then(blob => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'SalesDetailsReport.xlsx'); //or any other extension
                document.body.appendChild(link)
                link.click();
                setLoading(false)
                setBtndis(false)
              })
              .catch(error => {return Promise.reject({ Error: 'Something Went Wrong', error }, snOpen(true), setMessage("Something Went Wrong"), setSeverity("error"))});
        }

        ////generate summary route
        if(type === "SR"){
            const url = `${process.env.REACT_APP_BASFAIR_ENDPOINT}/v1/lta/summaryByRouteReport?startDate=${encodeURIComponent(fromDate)}&endDate=${encodeURIComponent(toDate)}&currDate=${encodeURIComponent(curDate)}&route=[${route}]` 
            fetch(
                url, requestOptions //this to add in the endpint
            )
              .then(res => res.blob())
              .then(blob => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'SummaryRoute.xlsx'); //or any other extension
                document.body.appendChild(link)
                link.click();
                setLoading(false)
                setBtndis(false)
              })
              .catch(error => {return Promise.reject({ Error: 'Something Went Wrong', error }, snOpen(true), setMessage("Something Went Wrong"), setSeverity("error"))});
        }       
    }

    //display checkValidation
    const checkValidation = () => {
        const fromDate = moment(dataset.fromTime).format('YYYY-MM-DD') //take from fromTime format it in YYYY-MM-DD
        const toDate = moment(dataset.toTime).format('YYYY-MM-DD') //take from toTime format it in YYYY-MM-DD

        //to display how many days range (int)
        const date = moment(toDate).diff(fromDate, 'days')

        //to check if date validate for 31 days
        if(date > 31){
            return setdateValidate(true)
        }else{

            //to check if the date are negative int (this is due to if the user select to date lesser than from date )
            if(date >= 0){
                return setdateValidate(false)
            }else{
                return setdateValidate(true)
            }       
        }
    }

    return  (
    <div className="apad-container">
            <div>
                <div className="apad-panel-title">APAD Report</div>
                <div className="apad-panel-description">{firebaseConfig.getValueByKey("report_apad")}</div>
            </div>

            <SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/>
            <main className="apad-input-container">
                <div className="apad-search-report-input-container">
                    <div className="apad-icon-title">
                        <div className="apad-title-container">Search Period</div>
                        {
                            dateValidate ?  <div className="validation-date">
                            <img loading="lazy" src={AlertRedIcon} alt="alert-icon"/> <p style={{margin:"0px 0px 0px 4px"}}>Search Period must be within 31 day duration</p>
                            </div> : null
                        }
                    </div>

                    <div className="search-period-title">
                        <div className="search-from">
                            <div className="asset-name">From</div>
                            <div className="asset-selection">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <ThemeProvider theme={apadMaterialTheme}>
                                        <KeyboardDatePicker
                                            name="fromTime"
                                            value={dataset.fromTime}
                                            onChange={(e) => onUserInput({ "fromTime": e })}
                                            format="dd/MM/yyyy"
                                            inputVariant="outlined"
                                            className={classes.textField}
                                            style={{ width: "100%" }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton style={{ outline: "none" }}>
                                                            <CalendarTodayRoundedIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </ThemeProvider>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="search-to">
                            <div className="asset-name">To</div>
                            <div className="asset-selection">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <ThemeProvider theme={apadMaterialTheme}>
                                        <KeyboardDatePicker
                                            name="toTime"
                                            value={dataset.toTime}
                                            onChange={(e) => onUserInput({ "toTime": e })}
                                            format="dd/MM/yyyy"
                                            inputVariant="outlined"
                                            className={classes.textField}
                                            style={{ width: "100%" }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton style={{ outline: "none" }}>
                                                            <CalendarTodayRoundedIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </ThemeProvider>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    </div>
                </div>
                {
                            loading ? <FadeLoader color={"#36D7B7"} css={loaderOveride} />: null
                }
                <div className="apad-search-report-input-container" style={{margin: "24px 0px 24px 0px"}}>
                    <div className="apad-icon-title">
                        <div className="apad-title-container">Report Configuration</div>
                    </div>
                    <div className="search-period-title">
                        <div className="search-from">
                            <div className="asset-name">Route</div>
                            <div className="asset-selection">
                                <ThemeProvider theme={component}>
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={routesN.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                        getOptionLabel={(option) => option.routeId ? `${option.routeLongName}` : ""}
                                        groupBy={(option) => option.firstLetter}
                                        onChange={(e, value) => onUserInput(e, {"route": value})}
                                        renderInput={(params) => (

                                            <div style={{position: "relative"}} ref={params.InputProps.ref}>
                                            {
                    
                                               !params.inputProps.value && (
                                                <span 
                                                    style={{
                                                        position: "absolute",
                                                        transform: "translate(1%, 100%)",
                                                        marginLeft: "5px"
                                                    }}
                                                    >
                                                <img loading="lazy" style={{ height: "20px", width: "20px" }} src={GreySearchIcon} alt="grey-icon" />
                                            </span>
                                                )
                                            }
                                            <TextField 
                                               {...params} 
                                               name="asset_name" 
                                               placeholder={dataset.route.length > 0 ? "" : "ALL"}
                                               variant="outlined" 
                                               inputProps= {!params.inputProps.value ? {
                                                ...params.inputProps,
                                                style: { paddingLeft: "27px", position: "relative", top:"1px" } 
                                             }: {
                                                 ...params.inputProps
                                             }}
                                            />
                                        </div>
                                        )}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                        <div className="search-to">
                            <div className="asset-name">Report Type</div>
                            <div className="asset-selection">
                                <ThemeProvider theme={component}>
                                    <Autocomplete
                                        {...lRType}
                                        name="asset_name"
                                        className={classes.autocomplete}
                                        id="combo-box-demo"
                                        loading={true}
                                        value={dataset.reportType}
                                        onChange={(e, value) => onUserInput(e,  value !== null ? {"reportType": value} : {"reportType": {}})}
                                        getOptionLabel={(option) => option && option.name ? option.name : ""}
                                        options={lRType.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                        groupBy={(option) => option.firstLetter}
                                        style={{ width: "100%" }}
                                        renderInput={(params) =>
                                            <div style={{position: "relative"}} ref={params.InputProps.ref}>
                                            {
                    
                                               !params.inputProps.value && (
                                                <span 
                                                    style={{
                                                        position: "absolute",
                                                        transform: "translate(1%, 95%)",
                                                        marginLeft: "5px"
                                                    }}
                                                    >
                                                <img loading="lazy" style={{ height: "20px", width: "20px" }} src={GreySearchIcon} alt="grey-icon" />
                                            </span>
                                                )
                                            }
                                            <TextField 
                                               {...params} 
                                               name="asset_name" 
                                               placeholder="search"
                                               variant="outlined" 
                                               inputProps= {!params.inputProps.value ? {
                                                ...params.inputProps,
                                                style: { paddingLeft: "27px"} 
                                             }: {
                                                 ...params.inputProps
                                             }}
                                            />
                                        </div>
                                        }
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {console.log(checkValidation())} */}
                <div className="button-click" onClick={(e)=> generateReport(e)} onMouseEnter={() => setIsHovered((current) => ({...current, btnReport: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnReport: false}))}>
                    <button className={isEmptyObject(dataset) ? "btn-report-disabled" : isEmptyObject(dataset.reportType) ? "btn-report-disabled" : btndis ? "btn-report-disabled" : dateValidate ? "btn-report-disabled" :  `${isHovered.btnReport ? "isHovered-btn-report": "btn-report"}`}
                        disabled={isEmptyObject(dataset) ? true : isEmptyObject(dataset.reportType) ? true : btndis ? true : dateValidate ? true : false}
                    >
                        Run Report

                    </button>
                </div>
            </main>


            <style jsx>
                {
                `
                .apad-container{
                    padding: 24px;
                 }
                 .apad-panel-title{
                    height: 32px;
                    color: #333333;
                    font-size: 24px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 32px;
                    margin: 0px 0px 4px 0px;
                 }
                 .apad-panel-description{
                    height: 21px;
                    color: #939598;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 21px;
                 }
                 .validation-date{
                     color: red;
                     font-size: 13px;
                     padding: 4px 0px 4px 0px;
                     display:flex;
                     align-items:center;
                     line-height: 9px;
                 }
                 .apad-input-container{
                    position: relative;
                    left: 42%;
                    top: 42%;
                    transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%); /* for IE 9 */
                    -webkit-transform: translate(-30%, 25%); /* for Safari */
                    width: 804px;
                    display: flex;
                    flex-direction: column;
                 }
                 .apad-search-report-input-container{
                    padding: 16px;
                    background: #FFFFFF;
                    border-radius: 4px;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    margin: 24px 0px 0px 0px;
                 }
                 .asset-input-container{
                     margin: 24px 0px 24px 0px;
                     padding: 16px;
                     width: 41.80vw;
                     border-radius: 4px;
                     background-color: #FFFFFF;
                 }
                 .search-period-container{
                    padding: 16px;
                    width: 41.80vw;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    // box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
                 }
                 .geofence-policy-container{
                    padding: 16px;
                    width: 41.80vw;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    margin: 0px 0px 24px 0px;
                    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
                 }
                 .alert-container{
                    margin: 24px 0px 0px 0px;
                    padding: 16px;
                    width: 41.80vw;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    margin: 0px 0px 24px 0px;
                    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2);
                 }
                 .driver-container{
                    margin: 16px 0px 0px 0px;
                    padding: 8px;
                    box-sizing: border-box;
                    width: 100%;
                    border: 1px solid #939598;
                    border-radius: 4px;
                 }
                 .apad-title-container{
                    color: #333333;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 21px;
                 }
                 .asset-details{
                    margin: 8px 0px 16px 0px;
                    color: #939598;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 16px;
                 }
                 .apad-icon-title{
                    display: flex;
                    // align-items: center;
                    flex-direction: column;
                    margin: 0px 0px 16px 0px;
                 }
                 .asset-name-select{
                    display: grid;
                    grid-template-columns: 0.5fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    align-items: center;
                 }

                 .alert-name-checkbox{
                     display:flex;
                     flex-direction: column;
                 }
                 .input-checkbox{
                     display: flex;
                     align-items: center;
                     margin: 8px 0px 8px 0px
                 }
                 .text-policy{
                     margin: 0px 0px 0px 8px;
                 }
                 .asset-name{
                    height: 19px;
                    color: #939598;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                 }

                .autocomplete-container{
                    display: flex;
                    align-items: center;
                }
                .option-name{
                    margin: 0px 4px 0px 0px;
                }
                .asset-selection{
                }
                .search-period-title{

                }
                .search-from{
                    display: grid;
                    grid-template-columns: 0.5fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    align-items: center;
                    margin: 0px 0px 16px 0px;
                }
                .search-to{
                    display: grid;
                    grid-template-columns: 0.5fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    align-items: center; 
                }
                .button-click{
                    display: flex;
                    justify-content: center;
                }
                .btn-report{
                    border-style: solid;
                    width: 386px;
                    background-color: #509D91;
                    color: #FFF;
                    font-weight: bold;
                }
                .isHovered-btn-report{
                    border-style: solid;
                    height: 32px;
                    width: 386px;
                    background-color: #498f84;
                    color: #FFF;
                    font-weight: 600;
                    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                    cursor: pointer;
                }
                .btn-report-disabled{
                    border-style: solid;
                    height: 32px;
                    width: 386px;
                    background-color: #509D91;
                    color: #FFF;
                    font-weight: bold;
                    opacity: 0.7;
                }
                `
                }
            </style>
        </div>
    )
}
export default PanelReportAPAD
