const filterType = {
    date: "date",
    tags: "tags",
    boolean: "bool",
    select: "select"
}

export const filterTypeOption = filterType

//this is for all the module filter list
export const filterList = {
    agency: [
        { label: "ID", key: "id", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterType.tags },
        { label: "Name", key: "name", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterType.tags },
        { label: "Status Tag", key: "isActive", option: [ { label: "Is", value: "IS"}], type: filterType.boolean, matchCase:{ truthy: ["active"], falsy: ["suspended"] } },
        // { label: "Email", key: "email", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterType.tags },
        { label: "Subscription Start", key: "subscriptionFrom", option: [{ label: "Before", value: "BEFORE"}, { label: "After", value: "AFTER"}], type: filterType.date },
        { label: "Subscription End", key: "subscriptionTo", option: [{ label: "Before", value: "BEFORE"}, { label: "After", value: "AFTER"}], type: filterType.date },
    ],
    asset: [
        { label: "Asset Name", key: "name", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Asset Type", key: "type", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Asset Model", key: "model", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Asset Manufacturer", key: "manufacturer", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Asset Group Name", key: "group_name", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Geofence Policy Name", key: "geofence_name", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Device Serial Number", key: "sn", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Feature Name", key: "feature_name", option: [{ label: "Is", value: "IN"}], type: filterTypeOption.tags },
    ],
    beaconAdmin: [
        { label: "Serial Number", key: "serialNumber", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags},
        { label: "Agency Name", key: "agencyName", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags},
        { label: "Agency ID", key: "agencyId", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags},
        { label: "skuId", key: "skuId", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags}
    ],
    beaconClient: [
        { label: "Serial Number", key: "serialNumber", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags},
        { label: "skuId", key: "skuId", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags}
    ],
    asset_group: [
        { label: "Name", key: "name", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Asset Name", key: "asset_name", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
    ],
    firmware:[
        { label: "Name", key: "name", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Board Class", key: "board_class", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Release Date", key: "release_date", option: [{ label: "Before", value: "BEFORE"}, { label: "After", value: "AFTER"}], type: filterTypeOption.date },
    ],
    SKU:[
        { label: "SKU ID", key: "id", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Product Model", key: "productModel", option: [{ label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Product Category", key: "productCategory", option: [{ label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Assembly ID", key: "assemblyId", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Schematic ID", key: "schematicId", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Feature Name", key: "features", option: [{ label: "Is", value: "IS"}], type: filterTypeOption.tags },
    ],
    device_admin:[
        { label: "Serial Number", key: "sn", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Agency Name", key: "agency_name", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "SKU ID", key: "sku_id", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Board ID", key: "board_id", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Feature Name", key: "feature_name", option: [{ label: "Is", value: "IS"}], type: filterTypeOption.tags },  
    ],
    device_client:[
        { label: "Serial Number", key: "sn", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "SKU ID", key: "sku_id", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Board ID", key: "board_id", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterType.tags },
        { label: "Feature Name", key: "feature_name", option: [{ label: "Is", value: "IN"}], type: filterTypeOption.tags },  
    ],
    channel:[
        { label: "Name", key: "name", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        // { label: "Channel Type", key: "Type", option: [{ label: "Telegram", value: "Telegram"}, { label: "Email", value: "Email"}, { label: "Slack", value: "Slack"}, { label: "WhatsApp", value: "WhatsApp"}, { label: "SMS", value: "SMS"}], type: filterTypeOption.select }
    ],
    users:[
        { label: "First Name", key: "firstName", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Last Name", key: "lastName", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Email", key: "email", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags }
    ],
    driver:[
        { label: "First Name", key: "firstName", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Last Name", key: "lastName", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags },
        { label: "Driver ID", key: "driverId", option: [{ label: "Contain", value: "CONTAINS"}, { label: "Is", value: "IS"}], type: filterTypeOption.tags }
    ]
}