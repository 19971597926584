import React, { useContext, useEffect, useState } from 'react'

import moment from "moment"
import Cookies from 'js-cookie';

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';
import { useQuery } from '@apollo/react-hooks';
import { createMuiTheme, makeStyles } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { WebsocketContext } from "../../config/config-websocket/Websocket";
// import { SEARCH_ASSET_N_GROUPS } from '../../config/config-graphql/Graphql'
import { APIGetSearchAssetnAssetGroup } from '../../config/config-restAPI/getAPICalled';
import Loaders from "../../component/component-loaders/Loaders"
import menuConfig from "../../config/config-menu/menu";
import SnackBar from '../../config/config-snackbar/SnackBar'
import ImageComponent from '../../component/component-image/ImageComponent'

import PositionIcon from "../../Image/place_one.svg"
import SpeedIcon from '../../Image/black_speed.svg'
import AssetIcon from "../../Image/asset.svg"
import AssetGroupIcon from "../../Image/asset_groups.svg";
import TelemetryIcon from "../../Image/telemetry_green.svg"
import MapGreenIcon from "../../Image/map_green.svg"
import ReportTelemetryIcon from "../../Image/report_telemetry_green.svg"
import PaireAssetDarkIcon from "../../Image/pair_dark.svg"
import GpsGrey from '../../Image/gps_grey.svg'
import GeolocationGrey from '../../Image/geolocation_grey.svg'
import GpsOffsGrey from '../../Image/gps_off_grey.svg'
import GreySearchIcon from '../../Image/grey_search.svg'

import MoveIcon from "../../Image/move.svg"
import StopIcon from "../../Image/stop.svg"
import IdleIcon from "../../Image/device-idle.svg"
import TowingIcon from '../../Image/towing.svg'
import LocationNotFoundIcon from "../../Image/default.svg"
import MotionStateIcon from "../../Image/motion_state_dark.svg"


import dvcBattHigh from "../../Image/battery-high.svg"
import dvcBattMed from "../../Image/battery-medium.svg"
import dvcBattLow from "../../Image/battery-low.svg"
import dvcBattUnk from "../../Image/battery-unknown.svg";
import dvcBattCharge from "../../Image/charging.svg"
import dvcBattDebug from '../../Image/dark_debugging.svg'
import dvcbattDark from "../../Image/battery_dark.svg"

import BatteryHigh from '../../Image/green-battery.svg'
import BatteryMedium from '../../Image/yellow-battery.svg'
import BatteryLow from '../../Image/red-battery.svg'
import BatteryUnknownIcon from '../../Image/grey-battery.svg'
import BatteryDark from '../../Image/black-battery.svg'

import FuelHighIcon from '../../Image/green-fuel.svg'
import FuelMediumIcon from '../../Image/yellow-fuel.svg'
import FuelLowIcon from '../../Image/red-fuel.svg'
import FuelDarkIcon from '../../Image/black-fuel.svg'




const autoComplete = createMuiTheme({
    overrides: {
        MuiAutocomplete: {
            clearIndicator: {
                // position: "absolute",
                // bottom: "5px",
                // right: "25px"
                "&:hover":{
                    backgroundColor: "#FFFFFF",
                }
            },
            endAdornment:{
                top: "0px",
                height: "100%",
                display: "flex",
                alignItems: "center"
            },
            popupIndicator:{
                "&:hover":{
                    backgroundColor: "#FFFFFF",
                }
            }    
        },
        MuiIconButton:{
            label:{
                height:"24px",
                width: "24px",
                "&:hover":{
                    backgroundColor: "rgba(0,0,0,0.03)",
                    borderRadius: "5px",
                },
            }
        }
    }
});

const useStyles = makeStyles((theme) => ({
    textField: {
        backgroundColor: "#FFFFFF",
        width: 240,
        marginLeft: 0,
        outline: "none"
    },
    autocomplete: {
        backgroundColor: "#FFFFFF",
        width: 276,
        underline: { "&&&:before": { borderBottom: "none" }, "&&:after": { borderBottom: "none" } }
    },

}))

const TableAssetStatus = ({ data, loading, setCallAPIParams, searchValue, setSearchValue, error, setError, setSetLimit, setFilter, fx_changePage, history }) => {
    const classes = useStyles();

    // console.log(data);
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');
    //this state for error handling on getQuery
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")
    const [loadings, setLoading] = useState(false);
    const [assetFlat, setAssetFlat] = useState([]); // list search for desktop (searchAssetNGroup)
    const [assetListSnip, setAssetListSnip] = useState([]); // list search for mobile (searchAssetNGroup)

    // const { data: searchData, loading: searchLoading } = useQuery(SEARCH_ASSET_N_GROUPS, { variables: { search_key: "", searchType: "ALL" } })

    // const searchOptions = {
    //     options: !searchLoading && searchData && searchData.searchAssetnGroups && searchData.searchAssetnGroups.group ? searchData.searchAssetnGroups.group.map((asset) => { return asset && asset.name ? { firstLetter: asset.name.charAt(0).toUpperCase(), uuid: asset.uuid, name: asset.name, description: asset.description } : { firstLetter: "", uuid: "", name: "", description: "" } }) : []
    // };

    const setValueDevice = (value) => {
        return value && value.uuid ? { uuid: value.uuid, name: value.name, description: value.description } : {}
    }

    const onUserInput = (e, value) => {
        // console.log(value);
        if (value) {
            setSearchValue({ uuid: value.uuid, name: value.name, description: value.description })
            setCallAPIParams(true)
        } else {
            setSearchValue({})
            setCallAPIParams(true)
        }
    }

    const APICalled = async (searchType, searchKey) => {
        try {
            const result = await APIGetSearchAssetnAssetGroup(token, setLoading, agencyId, searchType, searchKey, false)
            if (result.data.status === 200) {
                const assetGroupList = result.data.data.groups.length > 0 ? result.data.data.groups.map((x) => {
                    return x && x.name ? {
                        firstLetter: x.name.charAt(0).toUpperCase(), 
                        uuid: x.uuid, 
                        pairId: x.uuid, 
                        name: x.name,
                        type: 'group',
                        paired_asset: [] } : {
                            firstLetter: "", uuid: "", name: "", description: "", type: "group",paired_assets: []
                        }
                    }) : []
                setAssetFlat(assetGroupList);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch(err) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(token){
            APICalled("ALL", '')
        }
    }, [])
    

    useEffect(() => {
        if(error){
            setSnOpen(true)
            setMessage("Error: Unable to retrieve data. Please try again later.")
            setSeverity("error")
            setError(false)
        }
    }, [error])



    return (
        <div className="table-container">
            <div className="title">Asset Status</div>
            <div className="title-sub">At-a-glance real-time status view of all available assets</div>
            <div className="click-container">

                <ThemeProvider theme={autoComplete}>
                    <Autocomplete
                        {...assetFlat}
                        name="asset_name"
                        id="combo-box-demo"
                        defaultValue="ALL"
                        loading={true}
                        autoHighlight={true}
                        value={setValueDevice(searchValue)}
                        onChange={(e, value) => onUserInput(e, value)}
                        options={assetFlat.length > 0 ? assetFlat.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
                        groupBy={(option) => option.firstLetter}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => {
                            return (
                                
                                <div className="autocomplete-container">
                                    <img loading="lazy" style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} src={AssetGroupIcon} alt="asset-icon" />
                                    {option.name}
                                </div>
                            )
                        }}
                        style={{ width: 342 }}
                        className={classes.autocomplete}
                        renderInput={(params) =>
                            <div style={{position: "relative"}} ref={params.InputProps.ref}>
                            {
                               !params.inputProps.value && (
                                <span 
                                    style={{
                                        position: "absolute",
                                        transform: "translate(1%, 90%)",
                                        marginLeft: "5px"
                                    }}
                                    >
                                <img loading="lazy" style={{ height: "20px", width: "20px" }} src={GreySearchIcon} alt="grey-icon" />
                            </span>
                                )
                            }
                            <TextField 
                               {...params} 
                               name="asset_name" 
                               placeholder="Asset" 
                               variant="outlined" 
                               inputProps= {!params.inputProps.value ? {
                                ...params.inputProps,
                                style: { paddingLeft: "27px", position: "relative", top:"1px" } 
                             }: {
                                 ...params.inputProps
                             }}
                            />
                        </div>  
                        }
                    />
                </ThemeProvider>

            </div>
            <Table data={data} loading={loading} searchValue={searchValue} setFilter={setFilter} fx_changePage={fx_changePage} setSnOpen={setSnOpen} snOpen={snOpen} message={message} severity={severity} error={error} history={history}/>

            <style jsx>
                {
                `
                .table-container{
                    position: relative;
                    // top: 20px;
                    margin: 24px;
                }
                .title{
                    margin: 0px 0px 8px 0px;
                    color: #333333;
                    font-size: 24px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 32px;
                }
                .title-sub{
                    margin: 0px 0px 8px 0px;
                    color: #939598;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 16px;
                }
                .box-shadow{
                    // box-shadow: 0px 2px 4px 0px #00000050;
                }
                .click-container{
                    display: flex;
                    // justify-content: space-between;
                    align-itmes: center;
                }
                .box-map{
                    margin: 0px 0px 0px 8px;
                    display: flex;
                    align-items: center;
                }
                .box-map-text{
                    margin: 0px 0px 0px 8px;
                    color: #509D91;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 16px;
                }
                .bootstrap-container{
                    margin: 8px 0px 0px 0px;
                }
                .header-classes{
                    color: #333333;
                }
                .wrapper-classes{
                    border-bottom-style: hidden !important;
                    overflow-y: scroll;
                }
                .input-text{
                    box-sizing: border-box;
                    height: 40px;
                    width: 360px;
                    border: 1px solid #939598;
                    border-radius: 4px;
                }
                .autocomplete-container{
                    display: flex;
                    align-items: center;
                }
                `
                }
            </style>
        </div>
    )
}

const Table = ({ searchValue, loading, data, setFilter, fx_changePage, setSnOpen, snOpen, message, severity, error, history }) => {



    const payloadContext = useContext(WebsocketContext);
    const { selectAsset} = payloadContext;

    // console.log("TABLE", data)

    const setBatteryStatus = (voltage) => {
        return voltage ? voltage <= 100.00 && voltage >= 45.00 ? "high" : voltage <= 45.00 && voltage >= 25.00 ? "medium" : voltage <= 25.00 && voltage >= 0.00 ? "low" : "unknown" : "unknown"
    }

    const setGeoLocationStatus = (geolocation) => {
        switch (geolocation) {
            case 0:
                return "GPS" 
            case 16:
                return "Wifi"
            case 17:
                return "NoWifi"
            default:
                return "Garbage"
        }
    };

    const setMotionInference = (motion) => {
        switch (motion) {
            case 100 :
                return "Stop" 
            case 200 :
                return "Moving"
            case 400 :
                return "Idle"
            case 500 :
                return "In Tow"
            default:
                return "Undetermined"
        }
    }

    const setMotionInferenceIcon = (motion) => {
        switch (motion) {
            case 100 :
                return StopIcon 
            case 200 :
                return MoveIcon
            case 400 :
                return IdleIcon
            case 500 :
                return TowingIcon
            default:
                return LocationNotFoundIcon
        }
    }
    

    const settingBatteryIcon = (status) => {
        switch (status) {
            case "high":
                return <ImageComponent src={BatteryHigh} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} /> 
            case "medium":
                return <ImageComponent src={BatteryMedium} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
            case "low":
                return <ImageComponent src={BatteryLow} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} /> 
            case "unknown":
                return <ImageComponent src={BatteryUnknownIcon} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
            default:
                break;
        }
    }

    const dvcBatteryIcon = (status) => {
        switch (status) {
            case "high":
                return <ImageComponent src={dvcBattHigh} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} /> 
            case "medium":
                return <ImageComponent src={dvcBattMed} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
            case "low":
                return <ImageComponent src={dvcBattLow} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} /> 
            case "unknown":
                return <ImageComponent src={dvcBattUnk} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
            default:
                break;
        }
    }

    const convertTimestamp = (timestamp) => {
        const ctimestamp = moment(timestamp).format("X");
        const actualData = moment.unix(ctimestamp).format('DD/MM/YYYY hh:mm:ss a');
        return actualData;
    }

    const mapClick = (data) => {
        selectAsset({pairId :data._id, getAssetInfo: false}, {asset_name: data.primaryAsset.assetName, pairId: data._id, paired_asset: data.secondary && data.secondaryAssset});
        fx_changePage(menuConfig.dashboard[1].key, menuConfig.dashboard[1].children[1].key)
        history.push("/dashboard/live/map")
    }

    const telemetryClick = (data) => {
        setFilter({pairId: data._id, name: data.primaryAsset.assetName})
        fx_changePage(menuConfig.dashboard[1].key, menuConfig.dashboard[1].children[2].key)
        history.push("/dashboard/live/telemetry")
    }

    const reportClick = (data) => {
        setFilter({uuid: data._id, asset_name: data.primaryAsset.assetName, paired_asset: data.secondary && data.secondary})
        fx_changePage(menuConfig.dashboard[3].key, menuConfig.dashboard[3].children[7].key)
        history.push("/dashboard/report/report_telemetry")
    }

    return (
        <div>
            <div className="header-table">
                <div className="text-header">
                    <ImageComponent src={AssetIcon} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
                    <div className="text-payload-status">Assets</div>
                </div>
                <div className="text-header">
                    <ImageComponent src={PositionIcon} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
                    <div className="text-payload-status">Geolocation</div>
                </div>
                <div className="text-header-geolocation-status">
                    <ImageComponent src={MotionStateIcon} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
                    <div className="text-payload-status">Motion State</div>
                </div>
                <div className="text-header">
                    <ImageComponent src={SpeedIcon} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
                    <div className="text-payload-status">Speed (km/h)</div>
                </div>
                <div className="text-header">
                    <ImageComponent src={BatteryDark} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
                    <div className="text-payload-status">Asset Battery (volts)</div>
                </div>
                <div className="text-header">
                    <ImageComponent src={dvcbattDark} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
                    <div className="text-payload-status">Device Battery (volts)</div>
                </div>
                <div className="text-header">
                    <ImageComponent src={FuelDarkIcon} style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} alt="icon" onClick={false} />
                    <div className="text-payload-status">Fuel (litres)</div>
                </div>
                <div className="text-header">
                    <div className="text-payload-status">Connectivity</div>
                </div>
                <div className="last-header"></div>
            </div>
            <div>
                {
                    loading ? (<div style={{ position: "relative", right: "2px", top: "40px" }}><Loaders /></div>) : error ? (<SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/>) : !loading && data && data.map(x => {
                        const asset_pair = x && x.secondaryAssset ? x.secondaryAsset : {}
                        // console.log(x)
                        return (
                            <div className="card-table">
                                <div className="card-body">
                                    <div className="card-asset-row">
                                        {x.primaryAsset && x.primaryAsset.assetName ? asset_pair && Object.keys(asset_pair).length > 0 ?

                                            <div className="pair-asset">
                                                <div className="asset-name">{x.primaryAsset.assetName}</div>
                                                {x.primaryAsset.assetName !== asset_pair.assetName ? <div className="asset-pair-name"><div className="paired-symbol">↹</div>{`${asset_pair.assetName}`}</div> : null}
                                            </div> :
                                            <div className="asset-name-primary">{x.primaryAsset.assetName}</div> : ""}
                                        <div className="date-time-asset">{x.timestamp ? convertTimestamp(x.timestamp) : "No Recent Activity"}</div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="card-asset-row">
                                        {x.address ? x.address.localDisplay : ""}
                                        {x.latitude && x.longitude ? <a href={`https://www.google.com/maps/search/?api=1&query=${x.latitude},${x.longitude}`} rel="noopener noreferrer" target="_blank">{`${parseFloat(x.latitude).toFixed(6)},${parseFloat(x.longitude).toFixed(6)}`}</a> : ""}
                                        <div className="geolocation-subtitle">
                                            {x.geolocationStatus ? <img src={setGeoLocationStatus(x.geolocationStatus).toLowerCase() === "gps" ? GpsGrey : setGeoLocationStatus(x.geolocationStatus).toLowerCase() === "wifi" ? GeolocationGrey : setGeoLocationStatus(x.geolocation).toLowerCase() === "nowifi" ? GpsOffsGrey : setGeoLocationStatus(x.geolocation).toLowerCase() === "garbage" ? GpsOffsGrey : GpsOffsGrey} style={{ height: "14px", width: "14px" }} alt="icon" className="img-icon-geolocation-status" /> : null}
                                            <div className="text-payload-status">{x.geolocationStatus ? setGeoLocationStatus(x.geolocationStatus).toLowerCase() === "gps" ? "GPS" : setGeoLocationStatus(x.geolocationStatus).toLowerCase() === "wifi" ? "WiFi" : setGeoLocationStatus(x.geolocationStatus).toLowerCase() === "nowifi" ? "No Location Service" : setGeoLocationStatus(x.geolocationStatus).toLowerCase() === "garbage" ? "No Location Service" : "No Location Service" : null}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {x.motionInference ? <img src={setMotionInferenceIcon(x.motionInference)} style={{ height: "16px", width: "16px" }} alt="icon" className="img-icon" /> : null}
                                    <div className="text-payload-status">{x.motionInference ? setMotionInference(x.motionInference) : ""}</div>
                                </div>
                                <div className="card-body">
                                    <div className="text-payload-status">{x.kmh ? x.kmh.toFixed(2) : ""}</div>
                                </div>
                                <div className="card-body">
                                    {x.dcVoltPerc || x.dcVolt ? settingBatteryIcon(setBatteryStatus(parseInt(x.dcVoltPerc))) : null}
                                    <div className="text-payload-status">{x.dcVolt ? x.dcVolt.toFixed(2) : ""}</div>
                                </div>
                                <div className="card-body">
                                    {x.devSuppState || x.battVoltPerc  ? x.devSuppState === 100 ? <ImageComponent src={dvcBattCharge} alt="dvc-bat-chg" style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} onClick={false}/>  : x.devSuppState === 200 && x.devSuppState === 300 ? dvcBatteryIcon(setBatteryStatus(parseInt(x.battVoltPerc))) : x.devSuppState === 400 ?  <ImageComponent src={dvcBattDebug} alt="dvc-bat-chg" style={{ height: "16px", width: "16px", margin: "0px 4px 0px 0px" }} onClick={false}/>  : dvcBatteryIcon(setBatteryStatus(parseInt(x.battVoltPerc))) : null}
                                    <div className="text-payload-status">{x.battVolt ? x.battVolt.toFixed(2) : ""}</div>
                                </div>
                                <div className="card-body">
                                    {x.fuelTankPerc ? <img src={x.fuelTankPerc >= 70 ? FuelHighIcon : x.fuelTankPerc >= 30 ? FuelMediumIcon : x.fuelTankPerc <= 29 ? FuelLowIcon : FuelLowIcon} style={{ height: "16px", width: "16px" }} alt="icon" className="img-icon" /> : null}
                                    <div className="text-payload-status">{x.fuelVolume ? x.fuelVolume.toFixed(2) : ""}</div>
                                </div>
                                <div className="card-body">
                                    <div className="text-payload-status">{x.connClass ? x.connClass[0].toUpperCase() + x.connClass.substring(1) : ""}</div>
                                </div>
                                <div className="button-link">
                                    <img src={MapGreenIcon} alt="icon" style={{ cursor: "pointer" }} className="img-icon" onClick={() => mapClick(x)} />
                                    <img src={TelemetryIcon} alt="icon" style={{ cursor: "pointer" }} className="img-icon" onClick={() => telemetryClick(x)} />
                                    <img src={ReportTelemetryIcon} alt="icon" style={{ cursor: "pointer" }} className="img-icon spacing" onClick={() => reportClick(x)} />
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <style jsx global>
                {
                    `
                    .header-table{
                        margin: 8px 0px 8px 0px;
                        border-radius: 4px 4px 0 0;
                        background-color: #FFFFFF;
                        display: grid;
                        grid-template-columns: 2.0fr 3.0fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". . . . . . . .";
                          min-height: 64px;
                          max-height: 70;
                    }  
                    .img-icon{
                        margin: 0px 4px 0px 0px;
                    }
                    .text-payload-status{
                        position:relative;
                        top:2px;
                    }
                    .text-header{
                        display: flex;
                        align-items: center;
                        padding: 8px 0px 8px 12px;
                        color: #333333;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .card-body{
                        display: flex;
                        align-items: center;
                        padding: 8px 0px 8px 12px;
                        color: #424242;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .text-header-geolocation-status{
                        display: flex;
                        align-items: center;
                        padding: 8px 0px 8px 12px;
                        color: #333333;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 19px;
                        max-width: 360px;
                        min-width: 20%;
                    }
                    .card-table{
                        height: flex;;
                        border-radius: 4px 4px 0 0;
                        margin: 0px 0px 8px 0px;
                        background-color: #FFFFFF;
                        display: grid;
                        grid-template-columns: 2.0fr 3.0fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". . . . . . . .";
                    }
                    .button-link{
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }  
                    .spacing{
                        margin: 0px 16px 0px 4px;
                    }
                    .date-time-asset{
                        display: flex;
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .geolocation-subtitle{
                        display: flex;
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .card-asset-row{
                        display: flex;
                        flex-direction: column;
                        color: #424242;
                        font-size: 14px;
                        letter-spacing: 0;
                        line-height: 19px;
                    }
                    .pair-asset{
                        display:flex;
                        align-items: center;
                    }
                    .asset-name{
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 130px;
                        margin: 0px 4px 0px 0px;
                    }
                    .asset-pair-name{
                        display: flex;
                        align-items: center;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 100px;
                        margin: 0px 0px 0px 4px;
                    }
                    .img-icon-geolocation-status{
                        position:relative;
                        top:1px;
                        margin: 0px 4px 0px 0px;
                    }
                    .last-header{
                        width:110px;
                    }
                    .paired-symbol{
                        color: #F5A623;
                        font-size: 14px;
                        margin: 0px 8px 0px 0px;
                    }
                    `
                }
            </style>
        </div>

    )
}

export default TableAssetStatus
