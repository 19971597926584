import React, {useContext, useState} from 'react'

//component
import Details from './Details'
import menuConfig from "../../config/config-menu/menu"
import { WebsocketContext } from "../../config/config-websocket/Websocket"
import { useQuery } from "@apollo/react-hooks";

//image
import GreyLiveIcon from "../../Image/live_grey.svg"
import PairedIcon from "../../Image/pair_green.svg"
import GreyPairedIcon from '../../Image/pair_grey.svg'
import TelemetryGreyIcon from "../../Image/telemetry_grey.svg"
import TimelineGreyIcon from "../../Image/timeline_grey.svg"
import UnpairGreenIcon from "../../Image/unpair_green.svg"


const ListCardDetails = ({ data, mainAsset=false, featureList, CollapseCard, collapse, assetFeatureIcons, ViewDetails, lastInColumn=false, setPageInd, setFilterFinal, isPaired, ViewModal, fx_changePage, setLiveFilter, history, setMainAssetSelection, featureDisable }) => {


    const asset_device = data && data.asset_device
    const payloadContext = useContext(WebsocketContext);
    const [asset, setAsset] = useState([])    
    const { selectAsset } = payloadContext;
    
    const SelectAsset = (e) => {
        e.preventDefault();
    }



    const ViewLive = () => {
        selectAsset({blobID: data.blobID, getAssetInfo: false}, {blobID: data.blobID, asset_name: data.name,  paired_asset: data.paired_asset})
        setLiveFilter({ asset_uuid: data.uuid, blob_id: data.blob_id })
        fx_changePage(menuConfig.dashboard[1].key, menuConfig.dashboard[1].children[1].key)
        history.push("/dashboard/live/map")
    }

    const ViewTimeline = () => {
        // fx_changePage(menuConfig.dashboard[1].key, menuConfig.dashboard[1].children[0].key)
        // selectAsset(data, true)
        // setLiveFilter({ asset_uuid: data.uuid, blob_id: data.blob_id })
    }

    const ViewTelemetry = () => {
        selectAsset({pairId: data.blobID, getAssetInfo: false}, {pairId: data.blobID, asset_name: data.name,  paired_asset: data.paired_asset})
        setLiveFilter({ pairId: data.blobID, name: data.name })
        fx_changePage(menuConfig.dashboard[1].key, menuConfig.dashboard[1].children[2].key)
        history.push("/dashboard/live/telemetry")


    }

    const clickView = () => {
        if(Array.isArray(asset_device) && asset_device.length !== 0){
            ViewModal()
            setMainAssetSelection(data)
        }
        else{
           return null
        }
    }

    return (
        <div>
            <div className="card-content">
                <Details data={data} collapse={collapse} CollapseCard={CollapseCard} assetFeatureIcons={assetFeatureIcons} featureList={featureList} ViewDetails={ViewDetails} SelectAsset={SelectAsset} setPageInd={setPageInd} setFilterFinal={setFilterFinal} setMainAssetSelection={setMainAssetSelection} featureDisable={featureDisable}/>
                {
                    data.paired_assets && data.paired_assets.length > 0 && (
                        <>
                            <div className="paired-borderline">
                                <img loading="lazy" src={ PairedIcon } alt="paired-asset" style={{height: "16px", width: "16px"}}/>
                                <div className="divide-bottom"></div> 
                            </div>    
                            <Details data={data.paired_assets[0]} featureList={featureList} collapse={collapse} CollapseCard={CollapseCard} assetFeatureIcons={assetFeatureIcons} ViewDetails={ViewDetails} SelectAsset={SelectAsset} setPageInd={setPageInd} setFilterFinal={setFilterFinal} setMainAssetSelection={setMainAssetSelection}/> 
                        </>                   
                    )
                }
                {
                    collapse && (
                        // disabled-paired-label   pairing-label
                        <div className="pairing-live_shortcut-container">
                            {featureDisable ?  <div></div>:
                            <div className="pairing-icon" style={{cursor:"pointer"}} onClick={() => clickView()}>
                                <img loading="lazy" src={Array.isArray(asset_device) &&  asset_device.length === 0 ? GreyPairedIcon : isPaired ? UnpairGreenIcon : PairedIcon } alt="paired-asset"/>
                                <div className={Array.isArray(asset_device) && asset_device.length === 0 ? "disabled-paired-label" : "pairing-label"}>{Array.isArray(asset_device) && asset_device.length === 0 ? "Pair Asset" : isPaired ? "Unpair Asset" : "Pair Asset" }</div>
                            </div>
                            }
                            <div className="live-icons">
                                <div className="live-icon" style={{cursor:"pointer"}} onClick={ViewLive}>
                                    <img loading="lazy" src={GreyLiveIcon} alt="live"/>
                                    <div className="live-label live-shortcut-label">Live View</div>
                                </div>
                                {/* <div className="live-icon" style={{cursor:"pointer"}} onClick={ViewTimeline}>
                                    <img loading="lazy" src={TimelineGreyIcon} alt="timeline"/>
                                    <div className="timeline-label live-shortcut-label">Timeline</div>
                                </div> */}
                                <div className="live-icon icon-last" style={{cursor:"pointer"}} onClick={ViewTelemetry}>
                                    <img loading="lazy" src={TelemetryGreyIcon} alt="telemetry"/>
                                    <div className="telemetry-label live-shortcut-label">Telemetry</div>
                                </div>
                            </div>
                        </div>                            
                    )
                }
              
            </div>
                  
        </div>
    )
}

export default ListCardDetails
