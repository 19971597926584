// index SKU
import React, { useState, useEffect } from 'react'

//library
import { useQuery } from "@apollo/react-hooks";
import { snakeCase } from "snake-case"
import Cookies from 'js-cookie';

//image
import OrionIcon from "../../Image/orion.svg"
import DeviceGreyIcon from "../../Image/device-grey.svg"
import ChevronLightIcon from "../../Image/chevron_light.svg"
import PulsarIcon from "../../Image/pulsar.svg"
import GreenSmartLockIcon from '../../Image/green-smartlocks.svg'
import GreenBeacon from '../../Image/green-beacons.svg';

//component
import FunctionAdd from './FunctionAdd'
import FunctionEdit from './FunctionEdit'
import Overview from './Overview'
import Loaders from "../../component/component-loaders/Loaders"
import tableConfig from "../../config/config-table/table";
import { GET_SKU, skuQuery} from "../../config/config-graphql/Graphql";
import { getRequiredField } from "../../config/config-function/function";
import { filterList } from "../../config/config-filter/filter"
import { APIGetSKU } from '../../config/config-restAPI/getAPICalled';
import SnackBar from '../../config/config-snackbar/SnackBar'

const { sku:fieldList } = tableConfig.fieldList; 
const requiredFiled = getRequiredField(fieldList)

const PanelSKU = ({ fx_changePage, setFilter, filter }) => {// 👈 the filter props is for the filter that comes from other pages (view all)
    //data = SKU data from neo4j
    const [filterFinal, setFilterFinal] = useState({})
    const [preFilter, setPreFilter] = useState({})
    const [canUpdate, setCanUpdate] = useState(true) //This is for the mandatory field. default false
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")
    const [featureList, setFeatureList] = useState([])

    const [filterPre, setFilterPre] = useState({}); // for new Filter (REST)
    const [dispFilter, setDispFilter] = useState(false) // to display the filter component (REST)


    const [apiLoading, setApiLoading] = useState(false);
    const [error, setError] = useState(false)
    const [skuData, setSkuData] = useState([]);
    const [skuFeatures, setSkuFeatures] = useState([]);
    // const { data, loading, error } = useQuery(GET_SKU, { variables: { filter : filterFinal}});
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');

    // this function are for filter
    const PreFilter = (value) => {
        const snFilter = { id: `CONTAINS,${value.toString()}`}
        setPreFilter({ id: { type: "CONTAINS", value: [ value ] } })
        setFilterPre(snFilter);
    }

    // this when filter on Enter
    const EnterFilter =(e) => {
        if(e.key === "Enter"){
            setFilterFinal(preFilter)
            APICalled(filterPre)
        }
    }

    const APICalled = async (filterFinal) => {
        try {
            const result = await APIGetSKU(token, setApiLoading, agencyId, filterFinal)
            if (result.data.status === 200) {
                setSkuData(result.data.data.sku);
                setSkuFeatures(result.data.data.features);
                setApiLoading(false);
            } else {
                setSnOpen(true);
                setMessage("Error: Unable to retrieve data. Please try again later.");
                setSeverity("error") 
                setError(true)
                setApiLoading(false);
            }
        } catch(err) {
            setSnOpen(true);
            setMessage("Error: Unable to retrieve data. Please try again later.");
            setSeverity("error");
            setError(true)
            setApiLoading(false);
        }
    }

    useEffect(() => {
        if (token) {
            APICalled();
        }
    }, [])

    useEffect(() => {
        if(error){
            setSnOpen(true)
            setMessage("Error: Unable to retrieve data. Please try again later.")
            setSeverity("error")
            setError(false)
        }
    }, [error])

    // this is for FilterClick
    const onFilterClick = (filterVariable) => {      
        // this to do the reducer
        const valueFilter = Object.entries(filterVariable).map((x) => {
            const schema = x[0];
            const values = x[1];
            return {
                [schema]: `${values.type},${values.value.toString()}`
            }
        })
        // this to convert from array to object.
        const changeToObject = valueFilter.reduce((previosObject, currentObject) => {
            return Object.assign(previosObject, currentObject)
        }, {})    
        // console.log(changeToObject)
        APICalled(changeToObject);
        setFilterFinal(changeToObject)
        setDispFilter(!dispFilter)
    }

    return apiLoading ? <Loaders/> : error ? (<SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/>):(
        <div>
            <Overview
              title="SKU"
              onFilterClick={onFilterClick}
              data={skuData}
              APICalled={APICalled}
              featureList={skuFeatures}
              dispFilter={dispFilter}
              setDispFilter={setDispFilter}
              loading={apiLoading}
              addForm={FunctionAdd}
              editForm={FunctionEdit}
              query={skuQuery}
              Cards={Cards}
              setFilterFinal={setFilterFinal}
              filterFinal={filterFinal}
              filterList={filterList.SKU}
              preFilter={preFilter}
              fx_PreFilter={PreFilter}
              EnterFilter={EnterFilter}
              keyFilter="id"
              setPreFilter={setPreFilter}
              requiredFiled={requiredFiled}
              setCanUpdate={setCanUpdate}
              canUpdate={canUpdate}
            />
        </div>
    )
}

export default PanelSKU


const Cards = ({data, featureList, setEdit, edit, add, setAdd, setEditValue}) => {

    const _feature = featureList && featureList.length > 0 ? featureList.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) : []

    const [isHovered, setIsHovered] = useState({
        container:false,
        details: false
    })

    const getFeatures = (sku) => {
        return Array.from(sku && sku.features).map(uuid => {
          let feature = _feature.find(el => el.uuid === uuid)
          if(feature) return feature.icon
          return feature.icon
        })
      }
   
    const btnDetails = (e, value) => {
        let sku = []
        sku.push(value)
        e.preventDefault(e);
        setEdit(!edit)
        setEditValue(sku)
    }

    return (
        <div onMouseEnter={() => setIsHovered((current) => ({...current, container: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, container: false}))}>
            <div className="sku-container" >
                <div className="sku-name-container">       
                    <div className="sku-name">{data.id}</div>
                    <div className="board-name">
                        {
                            data.productCategory === 'Beacon' ? <img src={GreenBeacon} alt="beacon" /> : data.productModel ? <img src={data.productModel === "Orion" || data.productModel === 'Teltonika' ?  OrionIcon : data.productModel === "Pulsar" ? PulsarIcon : data.productModel ? GreenSmartLockIcon  : ""} alt="test" /> : null
                        }
                        <div className="board-detail">{data.productModel}</div>
                    </div>
                    <div className="project-name">{data.projectName}</div>
                </div>
                <div className="sku-line-box"></div>
                <div className="sku-feature-container">
                    
                    <div className="feature-details">
                        <div className="features-text">Features</div>
                        <div className="features-image">
                            {
                                Array.isArray(_feature) ? _feature.map(feature => {
                                    try{
                                        return getFeatures(data).includes(feature.icon) ? <img loading="lazy" className="img-feature" src={`${feature.icon}/icon_${snakeCase(feature.name)}_enabled.svg`} alt="v"/> : <img className="img-feature" src={`${feature.icon}/icon_${snakeCase(feature.name)}_disabled.svg`} alt="x"/>
                                        } catch(err){
                                        console.log(err)
                                        } 
                                }) : null
                            }
                        </div>
                    </div>
                </div>
                <div className="sku-line-box"></div>
                <div className="count-flashed-box">

                        <div className="flashed-container">
                            <div className="count-container">
                                <img src={DeviceGreyIcon} alt="test" style={{width: "16px", height: "16px"}}/>
                                <div className="flashed">Assigned</div>
                            </div>
                            <div className="count-number">{data.assignedCount}</div>
                        </div>
                        

                </div>
                {
                    isHovered.container && 
                    <div className="onhover-container">
                        <div className={`${isHovered.details ? "isHovered-button-edits" : "button-edits"}`} onClick={e => btnDetails(e, data)} onMouseEnter={() => setIsHovered((current) => ({...current, details: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, details: false}))}>
                                <img src={ChevronLightIcon} alt="test"/>
                        </div>
                </div>
                }

            </div>
            <style jsx>
            {
                `
                .sku-container{
                    display: grid;
                    grid-template-columns: minmax(0, 1fr) 5px minmax(0, 2fr) 5px 1fr 43px;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". . . . . .";
                    margin-bottom: 8px;
                    border-radius: 8px;
                    height: 88px;
                    background-color: #FFF;
                    // box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2);
                    align-items: center;
                }
                .sku-name-container{
                    padding: 0px 0px 0px 8px;
                    align-items: center;
                    white-space: nowrap;
                    overflow: hidden;
                }
                .sku-name{
                    height: 24px;
                    color: #333333;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .board-name{
                    display: flex;
                    align-items: center;
                    height: 24px;
                    white-space: nowrap;
                    overflow: hidden;
                }
                .board-name .board-detail{
                    margin: 0px 0px 0px 4px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .project-name{
                    height: 24px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .sku-line-box{
                    height: 72px;
                    margin-right: 8px;
                    border: 1px solid #DDDDDD;
                }
                .sku-feature-container{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    overflow: hidden;
                }
                .feature-details{
                    padding: 0px 0px 0px 8px;
                }
                .features-text{
                    height: 16px;
                    color: #939598;
                    font-size: 12px;
                    letter-spacing: 0;
                }
                .features-image{
                    height: 24px;
                    margin: 4px 0px 0px 0px;
                }
                .img-feature{
                    margin: 0px 16px 0px 0px;
                }
                .sku-flashed-box{
                    display: flex;
                    align-items: center;
                    width: 207px
                }
                .count-flashed-box{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                 }
                .flashed-container{
                    display:flex;
                    flex-direction: column;
                    width: 100%;
                }
                .count-container{
                    display: flex;
                    justify-content: center;
                    
                }
                .flashed{
                    margin: 0px 0px 0px 4px;
                    color: #939598;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 16px;
                }
                .count-number{
                    color: #333333;
                    font-size: 24px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 32px;
                    text-align: center;
                }
                .button-edits{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 43px;
                    min-height: 88px;
                    border-radius: 0 4px 4px 0;
                    background-color: #509D91;
                    cursor: pointer;
                 }
                 .isHovered-button-edits{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 43px;
                    min-height: 88px;
                    border-radius: 0 4px 4px 0;
                    background-color: #498f84;
                    cursor: pointer;
                    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                 }
                `
            }
            </style>
        </div>
    )
}
