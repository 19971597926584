// form SKU
import React, {useState, useContext, useEffect} from 'react'
import { useQuery } from "@apollo/react-hooks";

//image
import AddGreenIcon from "../../Image/add_icon_green.svg"

//component
import Assembly from "./Assembly"
import FeatureSelect from "../../component/component-featureselect/FeatureSelect"
import tableConfig from "../../config/config-table/table";
import { settingFieldValue, requiredLabel} from "../../config/config-function/function";
import {firebaseContext} from "../../config/config-firebase/FirebaseContext"
import Telemetics from './Telemetics'
import OtherModel from './OtherModel'
import { GET_ASSEMBLIES } from "../../config/config-graphql/Graphql";

const { sku:fieldList } = tableConfig.fieldList; 


const Form = ({ sku, onUserInput, displayed, assemblly, setAssemblly, query, fx_back, setFeatureAdd, featureAdd, featureRemove, setfeatureRemove, skuID, setSkuID, feature, setFeature, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere}) => {
    const [selectedSkuFeatures, setSelectedSkuFeatures] = useState([])
    const [featureOption, setFeatureOption] = useState([]);
    const [indexAsemblies, setindexAsemblues] = useState(0)
    const isBulkEdit = Array.isArray(sku) && sku.length > 1;
    const firebaseConfig = useContext(firebaseContext)
    const [optionList, setOptionList] = useState([])
    // this need to change after update.
    const [checkTelTonika, setCheckTelTonika] = useState(sku.length > 0 ? 
        sku[0].productModel === 'Teltonika' ? true : sku[0].productModel === 'Smart_Lock' ? true : false : false
    );

    //t
    const { data: getAssemblies, loading: loadingAssemblies, error: errAssemblies } = useQuery(GET_ASSEMBLIES, { variables: {field: {assembly_id: assemblly && assemblly[indexAsemblies] && assemblly[indexAsemblies].assembly_id, schematic_id: assemblly && assemblly[indexAsemblies] && assemblly[indexAsemblies].schematic_id}}});
    
    //take value from remote-config
    const productSelection = firebaseConfig.getValueByKey("product_model_category")
    const selection = JSON.parse(productSelection)


    //this useEffect is for display autocomplete 
    useEffect(() => {
        if(errAssemblies){
            setOptionList([])
        }
        else if(!loadingAssemblies && getAssemblies){

            //autocomplete display list for assembly_id
            if(assemblly && assemblly[indexAsemblies] && assemblly[indexAsemblies].assembly_id){
                let option = getAssemblies && getAssemblies.get_assemblies && getAssemblies.get_assemblies.map(el => { return {value: el.assemblyId, label: el.assemblyId }})
                option ? setOptionList(option) : setOptionList([])
            }

            //autocomplete display list for schematic_id
            if(assemblly && assemblly[indexAsemblies] && assemblly[indexAsemblies].schematic_id){
                let option = getAssemblies && getAssemblies.get_assemblies && getAssemblies.get_assemblies.map(el => { return {value: el.schematicId, label: el.schematicId }})
                option ? setOptionList(option) : setOptionList([])
            }

        } else{
            setOptionList([])
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // !loading && console.log(data)
    }, [loadingAssemblies])

    useEffect(() => {
        if(displayed){

        }
    }, [checkTelTonika])

    let modelSelection = selection.categories.filter(select => {
        return !displayed ?  select.value === sku.productCategory : select.value === sku[0].productCategory
    })

    const settingAssembly = (i, assembly) => {
        let clone = [...assemblly]
        clone[i] = assembly
        setAssemblly(clone)
    }

    const addAssembly = () => {
        let assembly = [...assemblly]
        assembly.push({})
        setAssemblly(assembly)
    }

    //function input select for product category, if select smartlocks it will auto check smartlock feature
    const onChangePC = (e) => {
        onUserInput(e)
        if(e.target.value === "Smartlocks" ){
            let clone = [...feature]
            // filter smart lock to get the uuid
            const featureSmartlock = featureOption.length > 0 && featureOption.filter((x) => x.name === 'Smart Lock');
            if (featureSmartlock.length > 0) {
                clone.push(featureSmartlock[0].uuid);
                setFeature(clone)
            }
            setCheckTelTonika(true)
        } else if (e.target.value === 'Beacon') {
             setCheckTelTonika(true)
             setFeature([])
        } else {
            setCheckTelTonika(false)
            setFeature([])
        }
    }

    const onChangePM = (e) => {
        onUserInput(e)
        if(e.target.value === 'Teltonika'){
            setAssemblly([{ assemblyId: "", schematicId: "", uuid: "", boardClass: "Cellular"} ])
            setCheckTelTonika(true);
        }else if(e.target.value === 'Smart_Lock'){
            setCheckTelTonika(true);
            setAssemblly([]);
        }else {
            setCheckTelTonika(false);
            setAssemblly([])
        }
    }

    return(
        <div className="sku-main-container">
            <div className="sku-form-container">
                <div className="sku-Id-container">
                    <div className="form-title-sku">SKU ID </div>   
                        <div className="sku-form-details-container"> {firebaseConfig.getValueByKey("admin_sku_id")}</div>             
                    <div className="sku-form-sub-container">
                        <div className="sku-input-container">
                            <div className="ddl-container">
                                <div className="ddl-detail">
                                    <div className="input-select">
                                        <div className="ddl-title">Product Category</div>
                                        <div className="ddl-input">
                                            <select className="select-input" value={settingFieldValue(isBulkEdit, sku, fieldList.product_category.name)} name="productCategory" onChange={(e, value) => onChangePC(e)}>
                                                <option>-</option>
                                                {selection.categories.map((x,i) => 
                                                    <option value={x.value}>{x.categories}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="input-selects">
                                        <div className="ddl-title">Product Model</div>
                                        <div className="ddl-input">
                                        <select className="select-input" value={settingFieldValue(isBulkEdit, sku, fieldList.product_model.name)} name="productModel" onChange={(e, value) => onChangePM(e)}>
                                                <option>-</option>
                                                { !displayed ? sku.productCategory === "-" ? <option>-</option> : sku.productCategory ? modelSelection[0].model.map((x,i) => 
                                                        <option value={x.value}>{x.model}</option>
                                                ) : <option>-</option> : sku[0].productCategory === "-" ? <option>-</option> : sku[0].productCategory  && modelSelection.length > 0 ?  modelSelection[0].model.map((x,i) => 
                                                    <option value={x.value}>{x.model}</option>
                                                ) : <option>-</option>}
                                            </select>
                                        </div>
                                    </div>
                                   
                                </div>  

                            </div>

                            </div>
                            {
                                modelSelection.length > 0 ? checkTelTonika ? <OtherModel sku={sku} requiredLabel={requiredLabel} fieldList={fieldList} settingFieldValue={settingFieldValue} isBulkEdit={isBulkEdit} onUserInput={onUserInput}/> 
                                : <Telemetics displayed={displayed} sku={sku} requiredLabel={requiredLabel} fieldList={fieldList} settingFieldValue={settingFieldValue} isBulkEdit={isBulkEdit} onUserInput={onUserInput}/> : null
                            }
                    </div>
                </div>
                <div className="sku-features-container">
                        <div className="box-info">
                            <div className="title">Features & Sensors</div>
                            <div className="title-detail">{firebaseConfig.getValueByKey("admin_sku_feature")}</div>
                        </div>
                        <FeatureSelect skuSelection={displayed ? sku[0].productCategory : sku.productCategory} staticFeatureList={selectedSkuFeatures} setSelectedSkuFeatures={setSelectedSkuFeatures} setFeatureAdd={setFeatureAdd} featureAdd={featureAdd} featureRemove={featureRemove} setfeatureRemove={setfeatureRemove} displayed={displayed} data={sku} feature={feature} setFeature={setFeature} isSKU={true} setFeatureOption={setFeatureOption} featureOption={featureOption}/>
                    </div>
            </div>
            <div className="another-cards">
                <div className="two-cards">
                {
                    modelSelection.length > 0 ? !checkTelTonika ? 
                    <div className="sku-assembly-container">
                        <div className="box-info">
                            <div className="title">Assembly</div>
                            <div className="title-detail">{firebaseConfig.getValueByKey("admin_sku_assembly")}</div>
                            {assemblly.map((item, i) => (
                                <div key={i} style={{marginBottom:"8px"}}>
                                    <Assembly sku={sku} settingFieldValue={settingFieldValue} displayed={displayed} fieldList={fieldList} isBulkEdit={isBulkEdit} onUserInput={onUserInput} assemblies={item} setAssemblly={settingAssembly} index={i} setindexAsemblues={setindexAsemblues} dataAutocomplete={!loadingAssemblies && getAssemblies && getAssemblies.get_assemblies} optionList={optionList}/>
                                </div>
                            ))}
                            {!displayed ? <div className="btn-properties" onClick={addAssembly}>
                                <img src={AddGreenIcon} alt="test" />
                                <div className="add-properties">Add Assembly</div>
                          </div> : null}

                        </div>
                    </div>
                     : null : null
                }

                </div>
            </div>     
            <style jsx>
                {
                    `.sku-main-container{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 24px;
                        grid-template-areas:
                          "sku-Id-container sku-assembly-container"
                          "sku-feature-container delete-sku-container";
                        border-radius: 4px;
                    }
                    .assembly-container{
                        box-sizing: border-box;
                        height: 121px;
                        border: 1px solid #939598;
                        border-radius: 4px;
                        padding: 8px;
                    }
                    .telemetics-container{
                        
                    }
                    .smart-lock-text{
                        border-style: none;
                        height: 24px;
                        outline: none;
                        display: block;
                        text-align: center;
                        font-size: 18px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 24px;
                    }
                    .smart-container{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                        padding: 0px 75px 0px 75px;
                        margin: 16px 0px 0px 0px;
                    }
                    .label-text-SKU{
                        margin:0px;
                        font-size: 11px;
                        letter-spacing: 0;
                        line-height: 16px;
                        text-align: center;
                        color: #939598;
                    }
                    .line-SKU{
                        box-sizing: border-box;
                        height: 1px;
                        border: 1px solid #939598;
                    }
                    .form-title-sku{
                        font-weight: 600;
                        margin: 0px 0px 4px 0px;
                    }
                    .sku-form-container{

                        // display: flex;
                    }
                    .isHovered-border{
                        height: 25px;
                        outline: none;
                        border: 1px solid #b6babf;
                        border-radius: 4px;
                        width: 100%;
                    }
                    .sku-form-sub-container{
                        display: grid;
                    }
                    .sku-input-container{
                        display: flex;
                        flex-direction: column;
                    }
                    .ddl-container{
                    }
                    .input-container{
                    }
                    .sku-form-details-container{
                        color: #939598;
                        margin: 0px 0px 16px 0px;
                        font-size: 12px;
                        letter-spacing: 0;
                    }
                    .sku-Id-container{
                        padding: 16px;
                        background-color: #FFFFFF;
                        border-radius: 4px;
                    }
                    .two-cards{
                        display: flex;
                        flex-direction: column;
                    }
                    .sku-features-container{
                        padding: 16px;
                        margin: 24px 0px 0px 0px;                       
                        border-radius: 4px;
                        background-color: #FFFFFF;
                    }
                    .sku-assembly-container{
                        padding: 16px;
                        // height: 409px;
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        margin: 0px 0px 24px 0px;
                    }
                    .custom-properties{
                        padding: 16px 16px 16px 16px;
                        height: 137px;
                        // width: 804px;
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        margin: 0px 24px 0px 0px;
                    }
                    .p-custom-properties{
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 16p
                    }
                      .title-custom-properties{
                        height: 21px;
                        color: #333333;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 21px;
                    }
                    .another-cards{
                        // margin: 24px 0px 24px 0px;
                    }
                    .delete-sku-container{
                        border-radius: 4px;
                        background-color: #FFFFFF;
                        padding: 16px;
                    }
                    .input-select{
                        // width: 372px;
                        margin: 0px 24px 0px 0px;
                    }
                    .input-selects{

                    }
                    .ddl-title{
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 
                    }
                    .ddl-input{

                    }
                    .ddl-detail{

                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". .";
                        margin: 0px 0px 4px 0px;
                    }
                    .select-input{
                        width: 100%;
                        height: 32px;
                        border: 1px solid #939598;
                        border-radius: 4px;
                        box-sizing: border-box;
                        background-color: #FFFFFF;
                        outline: none;
                    }
                    .sku-list{
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". . . .";
                        margin: 0px 0px 8px 0px;
                    }
                    .list{
                        text-align: center;
                    }
                    .line-horizontal{
                        box-sizing: border-box;
                        border-bottom-style: solid;
                        height: 1px;
                        width: 70%;
                        margin: 0 auto;
                        border: 1px solid #97979;
                    }
                    .label-sku{
                        height: 42px;
                        color: #333333;
                        font-size: 20px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 32px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .details-inputs{
                        display: grid;
                        grid-template-columns: 150px 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". .";
                        height: 35px;
                    }
                    .input-inputs{
                        display:flex;
                        align-items: center;
                    }
                    .text-box{
                        border-style: none;
                        height: 25px;
                        outline: none;
                        border-radius: 4px;
                        width: 100%;
                    }
                    .title{
                        margin: 0px 0px 4px 0px;
                        height: 21px;
                        color: #333333;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 21px;
                    }
                    .title-detail{
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 16px;
                        margin: 0px 0px 16px 0px;
                      }
                    .btn-properties{
                        margin: 16px 0px 0px 0px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }
                    .add-properties{
                        margin: 0px 0px 0px 6px;
                        color: #509D91;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 16px;
                        cursor: pointer;
                    }
                    .details-assembly{
                        display: grid;
                        grid-template-columns: 150px 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". .";
                        height: 30px;
                    }
                    .selection-board{
                        border-style:none;
                        background-color: #FFF;
                        outline: none;
                    }
                    `
                }
            </style>
        </div>
    )
}


export default Form

