import React from "react";

//library
import  { Redirect } from 'react-router-dom'
import Cookies from "js-cookie";

//component
import roleConfig from "../../config/config-role/role";
import Layout from '../../component/component-layout/Layout';
import menuConfig from '../../config/config-menu/menu'


export default function AdminPage({ history}) {
    const role = Cookies.get("role")
    const token = Cookies.get("jwtToken")
    const agencyID = Cookies.get("agency-id")
    const agencyName = Cookies.get("agency-name")

    //this to check the page settings
    const settingPage = () => {

        //this to check the role, and redirect it, it will check if there is a role, agencyID and agencyName in cookies
        if(token && agencyID && agencyName){
            if(!roleConfig.admin.includes(role)){
                if(roleConfig.dashboard.includes(role)){
                    const path = getDefaultPathBasedOnRole(role, "dashboard")
                    return <Redirect to={`/dashboard/${path}`} />
                } else{
                    return <Redirect to="/login" />
                }
            } else{
                const URL = window.location.hostname === "localhost" ? "http://localhost:3000" : process.env.REACT_APP_MAIN_URL 
                if(window.location.href === (`${URL}/console/`) || window.location.href === (`${URL}/console`)){
                    const path = getDefaultPathBasedOnRole(role, "console")
                    return <Redirect to={`/console/${path}`}/>
                }
                return <Layout page="console" history={history}></Layout>
            }
        }else{
            return <Redirect to="/login" />
        }
       
    }

    const getDefaultPathBasedOnRole = (role, page) => {
        let path = ""
        //this is to determined the default page based
        outerloop: for(let i=0; i<menuConfig[page].length; i++){    //to loop the menuConfig        
            if(menuConfig[page][i].role.includes(role)){ //check if the parent role same as cookie role and firebaseConfig value is true
                for(let j=0; j<menuConfig[page][i].children.length; j++){ //for loop for children
                    if(menuConfig[page][i].children[j].role.includes(role)){ //check if the children role same as cookie role and firebaseconfig value is true
                        path = menuConfig[page][i].children[j].path
                        
                        break outerloop;   
                    }
                }
            }
        } 

        return path
    }

    return ( 
        <>
        { settingPage() }
        </>
               
    );
}