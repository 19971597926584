import React, { useRef, useState, useLayoutEffect, useEffect } from 'react'

/* Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

//Notes
//1. data need name and total
const BulletChart = ({ name, title, loading, data, colorList }) => {

  const className = `chart-${name}`;
  const chartRef = useRef(null);

  const [chartData, setChartData] = useState([])
  const arrangeData = (name, from, to, index, total) => {
    return { category: "", from, to, name, fill: am4core.color(colorList[index]), total }
  }

  // console.log(data)

  useEffect(() => {
    if (!loading && data) {
      let totalAll = data.reduce(((prev, cur) => prev + cur.total), 0)
      let currentTotal = 0;
      let _data = data.map((el, i) => {
        let from = currentTotal / totalAll * 100;
        currentTotal = currentTotal + el.total
        return arrangeData(el.name, from, currentTotal / totalAll * 100, i, el.total)
      })
      setChartData(_data)
    }
            //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useLayoutEffect(() => {
    let chart = am4core.create(className, am4charts.XYChart);

    if (chartData.length === 0 || typeof chartData === 'undefined') {
      // Add fake data
      let placeholderData = [{
        category: "",
        color: am4core.color("#dadada"),
        opacity: 0.3,
        strokeDasharray: "4,4",
        tooltip: "",
        from: 0,
        to: 100,
        name: "No Data",
        visible: true
      }];

      chart.data = placeholderData
      chart.maskBullets = false;

      // Create Label
      let label = chart.createChild(am4core.Label);
      label.text = "No Data";
      label.fontSize = 12;
      label.align = "center";
      label.isMeasured = false;
      label.horizontalCenter = "middle";
      label.x = am4core.percent(50);
      label.y = am4core.percent(50);

      // Create axes
      let yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      yAxis.dataFields.category = "category";
      yAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.labels.template.disabled = true;
      yAxis.renderer.ticks.template.disabled = true;

      let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
      xAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.labels.template.disabled = true;
      xAxis.renderer.ticks.template.disabled = true;
      xAxis.min = 0;
      xAxis.max = 100;

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = "to";
      series.dataFields.openValueX = "from";
      series.dataFields.categoryY = "category";
      series.columns.template.propertyFields.fill = "color";
      series.columns.template.propertyFields.fillOpacity = "opacity";
      series.columns.template.propertyFields.stroke = "color";
      series.columns.template.propertyFields.strokeDasharray = "strokeDasharray";
      series.columns.template.propertyFields.readerValueText = "total"
      series.columns.template.height = am4core.percent(50);

      chartRef.current = chart;

      return () => {
        chart.dispose();
      };

    } else {

      // Add data
      chart.data = chartData
      chart.maskBullets = false;

      // Create axes
      let yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
      yAxis.dataFields.category = "category";
      yAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.labels.template.disabled = true;

      let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
      xAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.labels.template.disabled = true;
      xAxis.min = 0;
      xAxis.max = 100;

      // Ranges/labels
      chart.events.on("beforedatavalidated", function (ev) {
        let data = chart.data;
        for (var i = 0; i < data.length; i++) {
          let range = xAxis.axisRanges.create();
          range.value = data[i].to;
          range.label.text = data[i].total;
          range.label.horizontalCenter = "right";
          range.label.paddingLeft = 5;
          range.label.paddingTop = 5;
          range.label.fontSize = 12;
          range.grid.strokeOpacity = 0.2;
          range.tick.length = 18;
          range.tick.strokeOpacity = 0.2;
        }
      });

      // Create series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = "to";
      series.dataFields.openValueX = "from";
      series.dataFields.categoryY = "category";
      series.columns.template.propertyFields.fill = "fill";
      series.columns.template.propertyFields.readerValueText = "total"
      series.columns.template.strokeOpacity = 0;
      series.columns.template.height = am4core.percent(100);

      // let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      // valueLabel.label.text = "{total}";
      // valueLabel.label.horizontalCenter = "right"
      // valueLabel.label.dy = 30;
      // valueLabel.label.fontSize = 14;
      // valueLabel.label.paddingTop = 5;
      // valueLabel.label.paddingBottom = 5;

      // Legend
      let legend = new am4charts.Legend();
      legend.parent = chart.chartContainer;
      legend.itemContainers.template.clickable = false;
      legend.itemContainers.template.focusable = false;
      legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
      legend.align = "right";
      legend.data = chart.data;

      chartRef.current = chart;

      return () => {
        chart.dispose();
      };
    }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData])

  return (
    <div>
      <div>
        <div className="title">{title}</div>
        <div id={className} style={{ width: "100%", height: "100%", alignItems: "center", margin: "0px 0px 0px 0px" }}></div>
      </div>
      <style jsx>
        {
          `
            .title{
                color: #333333;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 19px;
                text-align: center;
            }
            `
        }
      </style>
    </div>
  )
}

export default BulletChart
