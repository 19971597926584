import React, {useState} from 'react'
import Switch from 'react-switch'
import { useMutation } from "@apollo/react-hooks";

import AlertGreyIcon from "../../Image/alert_grey.svg"
import tableConfig from '../../config/config-table/table'
import {UPDATE_SIGFOX_CONFIG, DELETE_SIGFOX_CONFIG, GET_DEVICES} from '../../config/config-graphql/Graphql'
import Loaders from "../component-loaders/Loaders"

const { payloadInterval,payloadIntervalDL2, payloadIntervalDL3, payloadIntervalDL4,  downlinkFrequent, gpsSeekPeriod } = tableConfig.sigfoxDownlink; 
const SigfoxConfig = ({setSigfoxConf, sigfoxConf, staticBoardID, displayed, setErrMsg, setErrOpen, setErrSevere, setIsHovered, isHovered , details}) => {
    const handleChangeStatPayload = (name, value) => {
        // console.log(typeof value)
        setSigfoxConf({...sigfoxConf, [name] :parseInt(value)})
    }

    const handleChangeMotionPayload = (name, value) => {
        setSigfoxConf({...sigfoxConf, [name]: parseInt(value)})
    }

    const handleChangeGPSSeek = (name, value) => {
        setSigfoxConf({...sigfoxConf, [name] : parseInt(value)})
    }

    const handleChangeDownFreq = (name, value) => {
        setSigfoxConf({...sigfoxConf, [name]: parseInt(value)})
    }

    const handleChangeVariable = nextChecked => {
        setSigfoxConf({...sigfoxConf, variableInterval: nextChecked})
    }

    const handleChangeReset = nextChecked => {
        setSigfoxConf({...sigfoxConf, revertDefault: nextChecked})
    }

    const applyBtn = async (e) => {
        try{
            if(displayed){ //edit

                //if no changes of sigfox board_id
                if(sigfoxConf.deviceId.includes(staticBoardID[0].deviceId)){
                    const response = await updateSigfox();
                    if(response.errors){
                        setErrOpen(true)
                        setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
                        setErrSevere("error")
                    }
                    else{
                        setErrOpen(true)
                        setErrMsg("Sigfox Configuration Successfully Updated")
                        setErrSevere("success")
                    }
                }else{ // if changes it will remove the current boardID and update the new one
                    const delResponse = await deleteSigfox()
                    const upResponse = await updateSigfox()

                    if(delResponse.errors){
                        setErrOpen(true)
                        setErrMsg(`${delResponse.errors[0].extensions.code}: ${delResponse.errors[0].message}`)
                        setErrSevere("error")
                      }else if(upResponse.errors){
                        setErrOpen(true)
                        setErrMsg(`${upResponse.errors[0].extensions.code}: ${upResponse.errors[0].message}`)
                        setErrSevere("error")
                      }else{
                        setErrOpen(true)
                        setErrMsg("Sigfox Configuration Successfully Updated")
                        setErrSevere("success")
                    }  
                }
            }else{//add
                const response = await updateSigfox();
                if(response.errors){
                    setErrOpen(true)
                    setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
                    setErrSevere("error")
                }
                else{
                    setErrOpen(true)
                    setErrMsg("Sigfox Configuration Successfully Updated")
                    setErrSevere("success")
                }
            }
        }catch(err){
            console.log(JSON.stringify(err, null, 2))
            setErrOpen(true)
            setErrMsg(JSON.stringify(err, null, 2))
            setErrSevere("error")
        }

    }

    const [updateSigfox, { loading: upLoading, error: upError }] = useMutation(UPDATE_SIGFOX_CONFIG, {
        variables: {input: sigfoxConf},
        refetchQueries: () => [
            {
                query: GET_DEVICES,
                variables: {filter: {}, type:"All"},
            },
        ],
    });

    

    const [deleteSigfox, { loading: delLoading, error: delError }] = useMutation(DELETE_SIGFOX_CONFIG, {
        variables: {input: staticBoardID && staticBoardID[0].deviceId ? staticBoardID[0].deviceId : []},
        refetchQueries: () => [
            {
                query: GET_DEVICES,
                variables: {filter: {}, type: "All"},
            },
        ],
    });

    if (upLoading && delLoading) return <div style={{position: "relative", right:"2px", top: "40px"}}><Loaders /></div>
    return (
        <div className="sigfox-container">
        <div className="sigfox-title-container">
            <p className="sigfox-title">Sigfox Configuration</p>
            {
                displayed ? <button className={`${isHovered.apply ? "isHovered-sigfox-button-apply" : "sigfox-button-apply"}`} onClick={(e) => applyBtn(e)} onMouseEnter={() => setIsHovered((current) => ({...current, apply: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, apply: false}))}>Apply</button> : null
            }       
        </div>
        <div className="sigfox-detail-container">
            <div className="sigfox-input-select">
                <div className="sigfox-input-title">Stationary Payload Interval</div>
                <select className={`${isHovered.statInt ? "isHovered-sigfox-selection" : "sigfox-selection"}`} value={sigfoxConf.payloadInterval} name="payloadInterval" onChange={e => handleChangeStatPayload(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, statInt: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, statInt: false}))}>
                {

                    sigfoxConf.downlinkFrequency === 1 ? 
                        payloadInterval.map(x => {
                            return <option value={x.value} disabled={x.disable}>{x.text}</option>
                        }) : sigfoxConf.downlinkFrequency === 2 ? 
                        payloadIntervalDL2.map(x => {
                            return <option value={x.value} disabled={x.disable}>{x.text}</option>
                        }) : sigfoxConf.downlinkFrequency === 3 ?
                        payloadIntervalDL3.map(x => {
                            return <option value={x.value} disabled={x.disable}>{x.text}</option>
                        }) : sigfoxConf.downlinkFrequency === 4 ? 
                        payloadIntervalDL4.map(x => {
                            return <option value={x.value} disabled={x.disable}>{x.text}</option>
                        }) : payloadInterval.map(x => {
                            return <option value={x.value} disabled={x.disable}>{x.text}</option>
                        })
                }
                </select>
            </div>
            <div className="sigfox-input-select">
                <div className="sigfox-input-title">In-Motion Payload Interval</div>
                <select className={`${isHovered.inMotionInt ? "isHovered-sigfox-selection" : "sigfox-selection"}`} value={sigfoxConf.payloadIntervalMotion} name="payloadIntervalMotion" onChange={e => handleChangeMotionPayload(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, inMotionInt: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, inMotionInt: false}))}>
                    {
                        sigfoxConf.downlinkFrequency === 1 ? 
                            payloadInterval.map(x => {
                                return <option value={x.value} disabled={x.disable}>{x.text}</option>
                            }) : sigfoxConf.downlinkFrequency === 2 ? 
                            payloadIntervalDL2.map(x => {
                                return <option value={x.value} disabled={x.disable}>{x.text}</option>
                            }) : sigfoxConf.downlinkFrequency === 3 ?
                            payloadIntervalDL3.map(x => {
                                return <option value={x.value} disabled={x.disable}>{x.text}</option>
                            }) : sigfoxConf.downlinkFrequency === 4 ? 
                            payloadIntervalDL4.map(x => {
                                return <option value={x.value} disabled={x.disable}>{x.text}</option>
                            }) : payloadInterval.map(x => {
                                return <option value={x.value} disabled={x.disable}>{x.text}</option>
                            })
                    }
                </select>
            </div>
            <div className="sigfox-input-select">
                <div className="sigfox-input-title">GPS Seek Period</div>
                <select className={`${isHovered.gps ? "isHovered-sigfox-selection" : "sigfox-selection"}`} value={sigfoxConf.gpsSeekPeriod} name="gpsSeekPeriod" onChange={e => handleChangeGPSSeek(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, gps: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, gps: false}))}>
                    {
                        gpsSeekPeriod.map(x => {
                            return <option value={x.value}>{x.text}</option>
                        })
                    }
                </select>
            </div>
            <div className="sigfox-input-select">
                <div className="sigfox-input-title">Variable Messaging Interval</div>
                <Switch onChange={handleChangeVariable} checked={sigfoxConf.variableInterval} onColor="#509D91" onHandleColor="#509D91" uncheckedIcon={false} checkedIcon={false} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={35} className="react-switch" id="material-switch"/>
            </div>
            <div className="sigfox-input-select">
                <div className="sigfox-input-title">Downlink Frequency</div>
                <select className={`${isHovered.dLink ? "isHovered-sigfox-selection" : "sigfox-selection"}`} value={sigfoxConf.downlinkFrequency} name="downlinkFrequency" onChange={e => handleChangeDownFreq(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, dLink: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, dLink: false}))}>
                    {
                        downlinkFrequent.map(x => {
                            return <option value={x.value}>{x.text}</option>
                        })
                    }
                </select>
            </div>
            <div className="sigfox-input-select">
                <div className="sigfox-input-title">Reset to Default</div>
                <Switch onChange={handleChangeReset} checked={sigfoxConf.revertDefault} onColor="#509D91" onHandleColor="#509D91" uncheckedIcon={false} checkedIcon={false} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={35} className="react-switch" id="material-switch"/>
            </div>
        </div>
        <div className="sigfox-configuration-container">
            <img loading="lazy" src={AlertGreyIcon} alt="alert-icon"/>
            <p className="sigfox-description">{details}</p>
        </div>
        <style>
            {
                `.sigfox-container{
                    padding: 16px;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    margin: 0px 0px 24px 0px;
                }
                .sigfox-title-container{
                    display: flex;
                    justify-content: space-between;
                    margin: 0px 0px 5px 0px;
                }
                .sigfox-title{
                    margin: 0;
                    color: #333333;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 21px;
                    font-weight: 600;
                }
                .button-apply{
                    box-sizing: border-box;
                    height: 33px;
                    width: 161px;
                    border: 1px solid #509D91;
                    border-radius: 2px;
                    color: #509D91;
                    cursor: pointer;
                    outline: none;
                }
                .isHovered-button-apply{
                    box-sizing: border-box;
                    height: 33px;
                    width: 161px;
                    border: 1px solid #498f84;
                    border-radius: 2px;
                    color: #498f84;
                    cursor: pointer;
                    font-weight: 600;
                    outline: none;
                    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                }
                .sigfox-detail-container{
                    margin: 0px 0px 8px 0px;
                }
                .sigfox-input-select{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    align-items: center;
                }
                .sigfox-input-title{
                    display: flex;
                    align-items: center;
                    width: 175px;
                    height: 34px;
                    color: #939598;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                }
                .sigfox-description{
                    margin: 8px 0px 0px 8px;
                    color: #939598;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                }
                .sigfox-configuration-container{
                    display: flex;
                    align-items: center;
                }
                .sigfox-selection{
                    width: 130px;
                    height: 24px;
                    border-style:none;
                    background-color: #FFF;
                    border-radius: 4px;
                    outline: none;
                }
                .isHovered-sigfox-selection{
                    width: 130px;
                    height: 24px;
                    border-style:none;
                    background-color: #FFF;
                    border-radius: 4px;
                    outline: none;
                    background-color: rgba(182, 186, 191, 0.2);
                }
                .sigfox-button-apply{
                    box-sizing: border-box;
                    height: 32px;
                    width: 212px;
                    border: 1px solid #509D91;
                    border-radius: 2px;
                    color: #509D91;
                    cursor: pointer;
                    outline: none;
                }
                .isHovered-sigfox-button-apply{
                    box-sizing: border-box;
                    height: 32px;
                    width: 212px;
                    border: 1px solid #498f84;
                    border-radius: 2px;
                    color: #498f84;
                    cursor: pointer;
                    font-weight: 600;
                    outline: none;
                    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                }
                `
            }
        </style>
    </div>
    )
}

export default SigfoxConfig
