import React, {useState, useEffect} from 'react'
import Form from './Form'

import { AllMandatoryFilled, onUserEdit} from "../../config/config-function/function";

import { DRIVER_REDUCER } from "../../config/config-reducer/reducer";
import {requiredField} from '../../config/config-table/table'

const FunctionEdit = ({values, onChange, displayed, setEditVariables, setCanAdd, fx_back, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere, featureDisable}) => {

    const onUserInput = (e, value, isValue) => {
        let input = onUserEdit(e, value, isValue, onChange, values);
        const _check_normal = AllMandatoryFilled(requiredField.driver, input[0])
        // console.log(_check_normal)
        _check_normal ? setCanAdd(true) : setCanAdd(false)
        setEditVariables({updatedDriver: input === null ? DRIVER_REDUCER(values[0]) : DRIVER_REDUCER(input[0])})
    }

    return (
        <Form driver={values} onUserInput={onUserInput} displayed={displayed} fx_back={fx_back}  setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} setErrMsg={setErrMsg} setErrOpen={setErrOpen} setErrSevere={setErrSevere} featureDisable={featureDisable}/>
    )
}
export default FunctionEdit
