import React, { useState, useContext, useRef, useEffect } from 'react'
import { Tooltip } from '@material-ui/core';
import DeviceMovingIcon from "../../Image/move.svg";
import DeviceIdleIcon from "../../Image/device-idle.svg";
import DeviceStopIcon from "../../Image/stop.svg";
import DeviceTowingIcon from '../../Image/towing.svg'
import DeviceUnknownIcon from "../../Image/default.svg";
import AssetIcon from '../../Image/dark-asset.svg'
import GreyInfoIcon from '../../Image/grey-info.svg'
import DarkLiveIcon from '../../Image/live.svg'

import ExpandMore from '../../Image/expand_more.svg'

import { WebsocketContext } from "../../config/config-websocket/Websocket"
import { firebaseContext } from "../../config/config-firebase/FirebaseContext"
import cloneDeep from 'lodash.clonedeep'
import Loaders from '../../component/component-loaders/Loaders'
import ImageComponent from '../../component/component-image/ImageComponent'

//set the icon based on the device status
const deviceStatus = { 
    moving: <img loading="lazy" src={DeviceMovingIcon} alt="Moving Device"/>, 
    idle: <img loading="lazy" src={DeviceIdleIcon} alt="Idle Device"/>, 
    towing: <img loading="lazy" src={DeviceTowingIcon} alt="Towing Device" />,
    stop: <img loading="lazy" src={DeviceStopIcon} alt="Stop Device"/> ,
    unknown: <img loading="lazy" src={DeviceUnknownIcon} alt="Unknown Device"/> 
}

const AssetGroupListStatus = ({ apiData, apiDataTotalAsset, apiLoading, apiError }) => {
    const payloadContext = useContext(WebsocketContext);   
    const firebaseConfig = useContext(firebaseContext) 
    const { data: websocketPayload, new_payload_id , view_asset_group_card, openAssetGroupCard, selected_asset_group} = payloadContext;
    const [dataOption, setDataOption] = useState({ options: []}); //the information to be displayed on the card
    const [totalAsset, setTotalAsset] = useState(0)
    const [totalActiveAsset, setTotalActiveAsset] = useState(0)
    const [callAssetStatsAPI, setCallAssetStatsAPI] = useState(false)
    const seconds = parseInt(firebaseConfig.getValueByKey("total_asset_count_seconds"))

    const movingDevice = (data) => { return data.length > 0 ? data.filter(row => row.motionInference === 200) : []}
    const idleDevice = (data) => {return data.length > 0 ? data.filter(row => row.motionInference === 400) : []}
    const towingDevice = (data) => {return data.length > 0 ? data.filter(row => row.motionInference === 500 ) : []}
    const stopDevice = (data) => { return data.length > 0 ? data.filter(row => row.motionInference === 100) : [] }
    const unknownDevice = (data) => {return data.length > 0 ? data.filter(row => (row.motionInference !== 100) && (row.motionInference !== 200) && (row.motionInference !== 400) && (row.motionInference !==  500) ) : []}
    /**
     * Executed once upon completion of page load
     */
    useEffect(() => {
        if (!apiLoading) {
                // console.log('go here to update');
                setDataOption({options : Object.keys(apiData).length > 0 ? apiData.assets.map(x => x).sort((a, b) => {
                    if (isFinite(b.pairId - a.pairId)) { // sort -Infinity, NaN, Infinity to the end in random order
                        return b.pairId - a.pairId
                    } else {
                        return isFinite(b.pairId) ? -1 : 1;
                    }
                }) : {}})

                // setDataOption({ options: apiData.assets });
                setTotalAsset(apiData && Object.keys(apiData).length > 0 ? apiData.totalAssets : 0)
                //display total tactive asset
                setTotalActiveAsset(apiData && Object.keys(apiData).length > 0 ? apiData.totalActiveAssets : 0)
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiLoading])

    useEffect(() => {
        onNewSubscribeData(new_payload_id, websocketPayload)

        setCallAssetStatsAPI(true) // setCallAssetStatsAPI set to true
        setTimeout(() => {
            callsCountAsset() //call the function (API)
        }, seconds);
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [new_payload_id])


    const callsCountAsset = () => { //function call for API
        if(callAssetStatsAPI){ 
            if(!apiLoading && apiDataTotalAsset && Object.keys(apiDataTotalAsset).length > 0){
                setTotalAsset(apiDataTotalAsset.totalAssets) //display total Asset count
                setTotalActiveAsset(apiDataTotalAsset.totalActiveAssets) // display total active asset
                setCallAssetStatsAPI(false)
            }
            setCallAssetStatsAPI(false)
        }
    }

    const onNewSubscribeData = async (id, newPayload) => {

        if (dataOption.length > 0) {

            // Clone dataPayload array for good measure
            const dataArrayClone = cloneDeep(dataOption)

            // Get array index of the matched blobID
            const dataFind = dataArrayClone.indexOf(dataArrayClone.find(x => x.pairId === newPayload.pairId))

            // Insert new payload data into the new array, *dataArrayClone* at index, *dataFind*
            if (dataFind >= 0) {
                const newObj = websocketPayload
                dataArrayClone[dataFind] = newObj

                setDataOption(reducer(dataArrayClone))
            }

        }
    }
    //this is for websocket
    const reducer = (data) => {
        return {
            name: data && data.primaryAsset && data.primaryAsset.assetName,
            pairId: data && data.pairId,
            paired_assets: data && data.secondaryAsset && [{
                 name: data.secondaryAsset.assetName,
                 pairId: data.secondaryAsset.pairId,
            }],
            motionInference: data && data.motionInference,
            uuid: data && data.pairId,
        }
    }
    return (
        <div>
            <div className={`asset-group-card-containers`} id="scrollable">
                {
                    apiLoading ? (<div style={{position:"relative", bottom:"19px"}}><Loaders /></div>) : (
                        <>
                        <div className="asset-group-contents">
                            <div className="total-asset">
                                <div className="text-total-asset">
                                    <div className="span-text-asset">Total Asset</div>
                                    <Tooltip title={firebaseConfig.getValueByKey("total_asset")} placement="top" arrow={true}>
                                            <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px"}} loading="lazy" />
                                    </Tooltip>
                                </div>
                                <div className="total-number">
                                    <ImageComponent src={AssetIcon} alt="aIcon" style={false} onClick={false}/>
                                    <div className="span-text-number">{totalAsset}</div>
                                </div>
                            </div>
                            <div className="total-asset">
                                <div className="text-total-asset">
                                    <div className="span-text-asset">Active Asset</div>
                                    <Tooltip title={firebaseConfig.getValueByKey("total_active_asset")} placement="top" arrow={true}>
                                        <img src={GreyInfoIcon} alt="aIcon" style={{height:"11px", width: "11px"}} loading="lazy"/>
                                    </Tooltip>
                                </div>
                                <div className="total-number">
                                    <ImageComponent src={DarkLiveIcon} alt="aIcon" style={false} onClick={false}/>
                                    <div className="span-text-number">{totalActiveAsset}</div>
                                </div>
                            </div>
                        </div>
                        <div className="device-statuses">
                            {   
                                !apiLoading && dataOption && dataOption.options ? <AssetList group={selected_asset_group} label="Moving" status="moving" data={movingDevice(dataOption.options)} icon={deviceStatus.moving} loading={apiLoading}/> : ""
                            }
                            {
                                !apiLoading && dataOption && dataOption.options  ? <AssetList group={selected_asset_group} label="Idle" status="idle" data={idleDevice(dataOption.options)} icon={deviceStatus.idle} loading={apiLoading}/> : ""
                            } 
                            {
                                !apiLoading && dataOption && dataOption.options ? <AssetList label="In Tow" status="towing" data={towingDevice(dataOption.options)} icon={deviceStatus.towing} loading={apiLoading} /> : ""
                            }
                            {
                                !apiLoading && dataOption && dataOption.options ? <AssetList group={selected_asset_group} label="Stop" status="stop" data={stopDevice(dataOption.options)} icon={deviceStatus.stop} loading={apiLoading}/> : ""
                            } 
                            {
                                !apiLoading && dataOption && dataOption.options ? <AssetList group={selected_asset_group} label="Undetermined" status="Undetermined" data={unknownDevice(dataOption.options)} icon={deviceStatus.unknown} loading={apiLoading}/> : ""
                            }
                            
                        </div> 
                        </>
                    )
                }             
                <style jsx>
                    {
                        `
                        .asset-group-card-containers::-webkit-scrollbar{
                            display: none;
                        }
                        .asset-group-card-containers{
                            background-color: #FFF; 
                            margin: 16px 0px 0px 0px;
                        }
                        .asset-group-card-containers.asset-group-card-actives{
                            visibility: visible;
                            opacity: 1;
                            padding: 8px;
                            transition: visibility 1s, opacity 0.5s linear;
                        }
                        .asset-group-contents{
                            display: grid; 
                            grid-auto-columns: 1fr; 
                            grid-template-columns: 1fr 1fr; 
                            grid-template-rows: 1fr; 
                            gap: 0px 24px; 
                            grid-template-areas: 
                              ". ."; 
                            align-items: center;
                            color: #333333;
                            font-size: 16px;
                            letter-spacing: 0;
                            line-height: 21px;
                            margin: 0px 0px 16px 0px;
                        }
                        .asset-group-title-ids{
                            display: flex;
                            margin: 0px 0px 0px 4px;
                        }
                        .asset-group-title-ids .asset-group-titles{
                            display: flex;
                            align-items: center;
                            font-size: 16px;
                            line-height: 23px;
                            color: #3A3A3A;
                            font-weight: 500;
                            margin: 0px 0px 0px 8px;
                            letter-spacing: 0;
                            line-height: 21px;
                            width: 100%;
                        }
                        .asset-group-title-ids .asset-group-ids{
                            color: #939598;
                            position: relative;
                            font-size: 12px;
                            line-height: 17px;
                        }
                        .device-statuses{
                            // margin-left: 8px;
                        }
                        .stickys{
                            position: -webkit-sticky;
                            position: sticky;
                            top: 0;
                        }
                        .close-icon-buttons img{
                            cursor: pointer;
                            border-radius: 5px;
                            // padding: 5px;
                            transition: all ease 300ms;
                        }
                        .close-icon-buttons img:hover{
                            background: #CCCCCC;
                        }
                        .asset-group-detail{
                            display: flex;
                        }
                        //styling for the scrollbar
                        /* width */
                        #scrollable::-webkit-scrollbar {
                        width: 8px;

                        }

                        /* Track */
                        #scrollable::-webkit-scrollbar-track {
                        background: white; 
                        }
                        
                        /* Handle */
                        #scrollable::-webkit-scrollbar-thumb {
                        background: #CCC; 
                        }

                        /* Handle on hover */
                        #scrollable::-webkit-scrollbar-thumb:hover {
                        background: #333; 
                        }
                        .total-asset{
                            background: #FFFFFF;
                            border: 1px solid #DDDDDD;
                            box-sizing: border-box;
                            border-radius: 4px;
                            padding: 8px;
                          }
                          .text-total-asset{
                            display: flex;
                            align-items: center;
                            margin: 0px 0px 4px 0px;
                          }
                          .total-number{
                            display: flex;
                            align-items: center;
                          }
                          .total-active-asset{
        
                          }
                          .span-text-asset{
                            font-size: 10px;
                            color: #939598;
                            margin: 0px 5px 0px 0px;
                            line-height: 16px;
                          }
                          .span-text-number{
                            font-size: 24px;
                            color: #333333;
                            margin: 0px 0px 0px 8px;
                            line-height: 26px;
                          }
                        `
                    }
                </style>
            </div>
            {/* </OnHoverScrollContainer> */}
        </div>
        
    )
}

const AssetList = ({ group, label, icon, status, loading, data }) => {

    const payloadContext = useContext(WebsocketContext);    
    const { selected_asset_info, closeAssetCard, selectAsset  } = payloadContext;
    const labelColor = { moving: "#286551", idle: "#F5A623", stop: "#EA2B1F", towing: "#00abe7", unknown: "#666666" }
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [setRotate, setRotateState] = useState("accordion__icon_asset_group_card");

    const content = useRef(null)
    const onClick = (docs, asset_name) => {
        if(selected_asset_info && selected_asset_info.pairId === docs.pairId){ //if the box was selected before
            selectAsset({ pairdId:"", assetName: asset_name, getAssetInfo: false }, null)
            closeAssetCard();
        } else{
            selectAsset({pairId :docs.pairId, assetName: asset_name, getAssetInfo: false}, {asset_name: asset_name, pairId: docs.pairId, paired_asset: docs.paired_assets})
        }     
    }
    const accordion = () =>{
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(
          setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
        setRotateState(
            setActive === "active" ? "accordion__icon_asset_group_card" : "accordion__icon_asset_group_card rotate"
        );
    }

    const checkedCount = (data) => { // paired asset count +1 for status count
        if(data){
            const totalAsset = data.reduce((accumulator, currentValue) => {
                if(currentValue.paired_assets && currentValue.paired_assets.length > 0){
                    return accumulator + 2;
                } else return accumulator + 1;
            }, 0)
    
            return totalAsset  
        }
    } 
    return (
        (
            <div className="asset-group-containers">
                <div className="asset-icon-labels">
                    <div className="asset-device-label-icons-labels">
                        { icon }
                        <div className="asset-labels" style={{color: labelColor[status]}}>{ label }&nbsp;({checkedCount(data)}) </div>  
                    </div>                       
                    <div className={`${setRotate}`} style={{cursor:"pointer", color:"#939598"}} onClick={accordion}>
                        <img loading="lazy" src={ExpandMore} alt="Icon" style={{margin:"0px"}}/>
                    </div>                 
                </div>
                <div ref={content}  style={{ maxHeight: `${setHeight}` }} className="device-asset-lists">
                    {
                        data && data.map((docs, index) => {
                            const asset_pair = docs.paired_assets ? docs.paired_assets.map(x => {return x}) :  []
                            const asset_name = docs && docs.name ? docs.name :   ""
                            return (
                                <div className={`device-asset-detailed`} key={docs.pairId} onClick={(e) => onClick(docs, asset_name)}>
                                    <div className="asset-names">{asset_name}</div>
                                    <div className="asset-id-paired-asset">{asset_pair.length > 0 ? 
                                        asset_name !== asset_pair[0].name ? <div className="pair-asset"><div className="paired-symbol">↹</div>{`${asset_pair[0].name}`}</div> : ""
                                        : ""}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <style>
                    {
                        `
                        .asset-dropdown{
                            //float:
                        }
                        .asset-group-containers{
                            margin: 7px 0px 0px 0px;
                            font-size: 16px;
                            line-height: 19px;
                        }
                        .asset-icon-labels{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            margin: 16px 0px 0px 0px ;
                        }
                        .asset-labels{
                            position: relative;
                            top:3px;
                        }
                        .asset-icon-labels img{
                            margin-right: 8px;
                        }
                        .device-asset-detailed{
                            height: 32px;
                            padding: 10px 0px 10px 32px;
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            border-radius: 5px;
                            cursor: pointer;
                            position: relative;
                            // left: 25px;
                        }
                        .device-asset-detailed:hover{
                            background: #CCCCCC;
                        }
                        .accordion__icon_asset_group_card{
                            margin: 0px;
                        }
                        .rotate {
                            margin:0px;
                            transform: rotate(180deg);
                          }
                        .device-asset-lists{
                            display: flex;
                            flex-direction: column;
                            overflow : hidden;
                            // overflow-y: scroll;
                            
                            transition: max-height 0.6s ease;
                        }
                        .asset-group-label{
                            display: flex;
                            justify-content: space-between;
                        }
                        .asset-device-label-icons-labels{
                            display:flex;
                        }
                        .device-asset-detailed.active .asset-names, .device-asset-detailed.active .asset-ids{
                            // background: #333333;
                            // color: #FFFFFF;
                        }
                        .device-asset-detailed .asset-names{
                            color: #333333;
                            font-size: 14px;
                            line-height: 16px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 175px;
                        }                        
                        .device-asset-detailed .asset-ids{
                            color: #939598;
                            position: relative;
                            top: 1px;
                            left: 5px;
                            line-height: 18px;
                            font-size: 12px;
                            margin-right: 10px;
                        }
                        .device-asset-detailed:hover{
                            // background: #CCC;
                            // color: #FFFFFF;
                        }
                        .device-asset-details .asset-id-paired-asset{
                            position: relative;
                            left: 5px;
                            line-height: 18px;
                            font-size: 12px;
                            margin-right: 10px;
                            overflow: hidden;
                            white-space: nowrap;
                        }
                        .pair-asset{
                            display: flex;
                            align-items: center;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 120px;
                            font-size: 12px;
                        }
                        .paired-symbol{
                            color: #F5A623;
                            font-size: 14px;
                            margin: 0px 8px 0px 0px;
                        }

                        `
                    }
                </style>
            </div>                                
        )
    )
}

export default AssetGroupListStatus
