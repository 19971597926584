import axios from 'axios';

const url = process.env.REACT_APP_RESTAPI;
const xApiKey = process.env.REACT_APP_XAPIKEY;

export const APIUpdateBeacon = async (token, agencyId, beaconData, beaconId, setLoading) => {
    setLoading(true);
    const result = await axios.patch(`${url}/beacon`, beaconData, {
      headers: {
        'x-api-key': xApiKey,
        'Authorization':  token,
        'agency-id': agencyId,
      },
    }).catch((error) => error);
    return result;
};

export const APIUpdateAgencies = async (token, agencyId, agencyData, id, setLoading) => {
  setLoading(true);
  const result = await axios.patch(`${url}/agency`, agencyData, {
    headers: {
      'x-api-key': xApiKey,
      'Authorization':  token,
      'agency-id': agencyId,
    },
  }).catch((error) => error);
  return result;
};

export const APISuspendAgencies = async (token, agencyId, agencyData, setLoading) => {
  setLoading(true);
  const result = await axios.patch(`${url}/suspendAgency`, agencyData, {
    headers: {
      'x-api-key': xApiKey,
      'Authorization':  token,
      'agency-id': agencyId,
    },
  }).catch((error) => error);
  return result;
}

export const APIUpdateSKU = async (token, agencyId, skuData, skuId, setLoading) => {
  setLoading(true);
  const result = await axios.patch(`${url}/skus`, skuData, {
    headers: {
      'x-api-key': xApiKey,
      'Authorization':  token,
      'agency-id': agencyId,
    },
  }).catch((error) => error);
  return result;
}

export const APIUpdateGeofence = async (token, agencyId, geofenceData, setLoading) => {
  setLoading(true);
  const result = await axios.patch(`${url}/geofence`, geofenceData, {
    headers: {
      'x-api-key': xApiKey,
      'Authorization':  token,
      'agency-id': agencyId,
    },
  }).catch((error) => error);
  return result;
}

export const APIUpdateChannel = async (token, agencyId, channelData, setLoading) => {
  setLoading(true);
  const result = await axios.patch(`${url}/channel`, channelData, {
    headers: {
      'x-api-key': xApiKey,
      'Authorization':  token,
      'agency-id': agencyId,
    },
  }).catch((error) => error);
  return result;
}