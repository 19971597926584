import React from 'react'

const TermCondition = ({history}) => {
    return (
        <div className="terms-container">
            <div className="main-container-terms">
                <h1>General Terms of Service for the Horizon</h1>
                <section className="section-container">These Terms were last updated on March 29th, 2021 </section>
                <section className="section-container">
                    Acceptance of the General Terms of Service is mandatory for the use of the Horizon, 
                    collectively known as “Horizon” owned by Asia Mobility Technologies Sdn Bhd, a company registered in Malaysia, 
                    with the company number 201801039006, with its operating address at 03-09-06, 9th Floor, Tower 3 
                    UOA Business Park, Jalan Pengaturcara U1/51A, 40150 Shah Alam, Selangor, Malaysia, that shall be known 
                    below as “Asia Mobiliti”.
                </section>
                <section className="section-container">
                    1. By using the Software, you agree to be bound by these Terms. If you do not agree to any part of 
                    these Terms, do not use the Horizon
                </section>
                <section className="section-container">
                    2. You agree that these Terms may be changed at any time without prior notice. The revised Terms shall 
                    become effective upon posting to https://horizonapp.cloud/
                </section>
                <section className="section-container">
                    3. All information provided in Horizon is based on data obtained from various data sources. 
                    While Asia Mobiliti has taken the effort to check and verify the data, there may be inaccuracies and 
                    changes. Asia Mobiliti does not guarantee the accuracy of the information and is not liable for any 
                    error or omission in the information provided. Asia Mobiliti is not liable for any loss or injury, 
                    direct or indirect, including loss of income, profit, opportunity or time as a result of relying on 
                    any information available through Horizon. Horizon and information contained therein are provided on 
                    an “as is” and “as available” basis without any warranties of any kind.
                </section>
                <section className="section-container">
                    4. Your account is personal to you and you shall not share your account information or allow 
                    access to your account by third parties. You agree that you will be responsible for all activity that 
                    occurs using your access credentials and that you will be responsible for any losses incurred by Asia 
                    Mobiliti or other users by the unauthorized use of your account.
                </section>
                <section className="section-container">
                    5. All software, text, graphics, maps, data, images and trademarks in Horizon is the property of or has been 
                    licensed by Asia Mobiliti. You cannot copy or reproduce any part of Horizon without Asia Mobiliti’s written 
                    permission.
                </section>
                <section className="section-container">
                    6. You agree that the use of Horizon is for the intended purpose of tracking vehicles,
                    assets etc. You will not impose an unreasonable load on Horizon by the use of any
                    device, software, algorithm or routine. You will not attempt to decipher, decompile,
                    disassemble or reverse engineer any part of Horizon.
                </section>
                <section className="section-container">
                    7. You agree to defend, indemnify and hold harmless Asia Mobiliti from and against any claims, actions or 
                    demands, including and without limitation, reasonable legal and accounting fees, arising or resulting from 
                    your breach of these Terms or your use of Horizon. Asia Mobiliti reserves the right to assume the exclusive 
                    defence and control of any matter which is subject to indemnification under this section. In such case, you 
                    agree to cooperate with any reasonable requests assisting Asia Mobiliti’s defence of such matte
                </section>
                <section className="section-container">
                    8. By accepting these Terms of Service, you confirm that you have reviewed and understand our Privacy 
                    Policy, and you acknowledge that any information shared by, or collected from or about, you may be used
                    by Asia Mobiliti in accordance with our Privacy Policy, as it is updated or changed from time to time.
                </section>
                <section className="section-container">
                    9. These Terms of Service shall be governed by Malaysian law. Any dispute arising from or related to 
                    these Terms shall be subject to the jurisdiction of the courts of Malaysia.
                </section>
                <section className="section-container">
                    10. If any provision of these Terms is found to be invalid by any court having competent jurisdiction, 
                    the invalidity of such provision shall not affect the validity of the remaining provisions of these 
                    Terms, which shall remain in full force and effect.
                </section>
                <section className="section-container">
                    11. Failure of Asia Mobiliti to act on or enforce any provision of these Terms shall not be construed 
                    as a waiver of that provision or any other provision in these Terms. No waiver shall be effective 
                    against Asia Mobiliti unless made in writing, and no such waiver shall be construed as a waiver in any 
                    other or subsequent instance.
                </section>
                <section className="section-container">
                    However, these measures do not guarantee that your information will not be accessed, disclosed,
                    altered or destroyed by breach of such firewalls and secure server software. 
                    By using our Service, you acknowledge that you understand and agree to assume these risks.
                </section>
                <section className="section-container">
                    12. Except as expressly agreed by Asia Mobiliti and you, these Terms constitute the entire agreement 
                    between you and Asia Mobiliti with respect to the subject matter and supersedes all previous or 
                    contemporaneous agreements, whether written or oral, between the parties with respect to the subject 
                    matter. These Terms will inure to the benefit of our successors, assigns, licensees, and 
                    sublicensees.
                </section>
                <div className="btn-back-div">
                    <div className="btn-back-container" onClick={() => history.push("/login")}>Back</div>
                </div>
                
                
            </div>

            <style>
                {
                    `.terms-container{
                        padding: 24px 50px 24px 50px;
                        display: flex;
                        justify-content: center;
                    }
                    .section-container{
                       margin: 0px 0px 24px 0px;
                    }
                    .main-container-terms{
                        width: 50%;
                        
                    }
                    .btn-back-div{
                        display: flex;
                        justify-content: center;
                    }
                    .btn-back-container{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 30px;
                        width: 100px;
                        color: #FFFFFF;
                        background: #509D91;
                        border-radius: 2px;
                        cursor: pointer;
                    }
                    input[type="button"]{
                        outline:none;
                     }
                    `
                }
            </style>
        </div>
    )
}

export default TermCondition
