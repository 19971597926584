import React, {useState, useEffect} from 'react'

import Form from './Form'
import { onUserAdd, AllMandatoryFilled } from "../../config/config-function/function";
import { DEVICE_REDUCER, UPDATE_SIGFOX_REDUCER, UPDATE_CELLULAR_REDUCER} from "../../config/config-reducer/reducer";
import {requiredField} from '../../config/config-table/table'

const FunctonAdd = ({values, onChange, setAddVariables, displayed, setGetVariables, setCanAdd, query, setCanUpdate, canUpdate, featureList, setSigfoxVariable, sigfoxVariable, setErrMsg, setErrOpen, setErrSevere, setCellularVariable}) => {
    
    const [valSku, setValSku] = useState({})
    const [valAgency, setValAgency] = useState({})  
    const [valfuel, setValfuel] = useState({})
    const [valBoard, setValBoard] = useState([])

    const [firmwareAdd, setFirmwareAdd] = useState({})
    const [firmwareRemove, setFirmwareRemove] = useState({})
    const [feature, setFeature] = useState([])
    const [agencyAdd, setAgencyAdd] = useState({})
    const [featureAdd, setFeatureAdd] = useState([])
    const [featureRemove, setFeatureRemove] = useState([])
    const [deviceIDs, setDeviceIDs] = useState([])
    const [sigfoxConf, setSigfoxConf] = useState({
        deviceId: [],
        payloadInterval: 3600,
        payloadIntervalMotion: 600,
        gpsSeekPeriod: 60,
        downlinkFrequency: 4,
        variableInterval: true,
        revertDefault: false
    })

    const [cellularConf, setCellularConf] = useState({
        deviceId: "",
        payloadInterval: 10,
        payloadIntervalIgnitionOff: 10,
        payloadIntervalOffline: 10,
        telitKeepAlive: 10,
        ignitionMode: true,
        extSense_Fuel: true,
        FuelSensingRatio: 0,
        extSense_Vibration: true,
        rat: 2
    })

    //this to take boardClass sigfox value for sigfox configuration. If the board has value "Sigfox" it will return else empty array
    const assemblyClass = valBoard.map(x => x.assembly.board_class)
    const removeDuplicate = assemblyClass.filter((value, index) => assemblyClass.indexOf(value) === index)
    const sigfoxFilter = removeDuplicate.filter(x => x === "Sigfox")
    const cellularFilter = removeDuplicate.filter(x => x === "Cellular")

    const onUserInput = (e, value, isValue) => {
        let input = null
        if(e){
            input = onUserAdd(e, null, false, onChange, values)
            setAddVariables({ new_device: [DEVICE_REDUCER(input)] });   
        }else if(value){
            input = onUserAdd(e, value, true, onChange, values)
            setAddVariables({ new_device: [DEVICE_REDUCER(input)] }); 
            // setSigfoxVariable(sigfoxFilter.length > 0 ? {input: UPDATE_SIGFOX_REDUCER(input.input, deviceIDs)} : {}) 
            // setCellularVariable(cellularFilter.length > 0 ? {input: UPDATE_CELLULAR_REDUCER(input.cellularInput, deviceIDs)} : {})   
        }

        const _check_normal = AllMandatoryFilled(requiredField.device, input)
        const _check_sku = input && input.SKU ? AllMandatoryFilled(requiredField.device_sku, input.SKU) : false
        const _check_board = input && input.assembly && input.assembly.some(b => b.id)

        _check_normal && _check_sku && _check_board ? setCanUpdate(true) : setCanUpdate(false)
    };

    useEffect(() => {
        onUserInput(null, { add_agency: agencyAdd }, true)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valAgency])

    useEffect(() => {
        onUserInput(null, { fuel_sensor: {length: valfuel.length, width: valfuel.width, height: valfuel.height} }, true)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valfuel])

    useEffect(() => {
        onUserInput(null, { add_features :  feature.map(x => {return {uuid: x}})}, true)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feature])

    //left board_class for sigfox configuration
    useEffect(() => {
        onUserInput(null, { SKU: {uuid: valSku.uuid}, assembly: valBoard && valBoard.map((x,i) => {return {assembly: x.assembly ? {uuid: x.assembly.uuid} : {}, id: x.assembly && x.assembly.board_class === "Sigfox" ?  x.board_id ? x.board_id.toString().toUpperCase().padStart(8,"0") : "" : x.board_id, board_class : x.assembly && x.assembly.board_class ? x.assembly.board_class : "", firmware_add: x.firmware_add ? {uuid: x.firmware_add.uuid} : {}}})}, true)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valBoard])

    useEffect(() => {
        onUserInput(null, { remove_features : featureRemove}, true)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featureRemove])

    // useEffect(() => {
    //     onUserInput(null, { input : sigfoxConf}, true)
    //                     //---this is to disable the warning for useEffect----//
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [sigfoxConf])

    // useEffect(() => {
    //     onUserInput(null, { cellularInput : cellularConf}, true)
    //                     //---this is to disable the warning for useEffect----//
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [cellularConf])

    return <Form 
            device={values} 
            onUserInput={onUserInput} 
            query={query} 
            displayed={displayed} 
            setValSku={setValSku} 
            valSku={valSku} 
            valAgency={valAgency} 
            setValAgency={setValAgency} 
            valfuel={valfuel} 
            setValfuel={setValfuel} 
            valBoard={valBoard} 
            setAgencyAdd={setAgencyAdd} 
            setValBoard={setValBoard} 
            featureAdd={featureAdd} 
            featureRemove={featureRemove} 
            setFeatureAdd={setFeatureAdd} 
            setFeatureRemove={setFeatureRemove} 
            firmwareAdd={firmwareAdd} 
            firmwareRemove={firmwareRemove} 
            setFirmwareAdd={setFirmwareAdd} 
            setFirmwareRemove={setFirmwareRemove} 
            feature={feature} 
            setFeature={setFeature}
            featureList={featureList}
            setSigfoxConf={setSigfoxConf}
            sigfoxConf={sigfoxConf}
            setDeviceIDs={setDeviceIDs}
            setErrMsg={setErrMsg} 
            setErrOpen={setErrOpen} 
            setErrSevere={setErrSevere}
            setCellularConf={setCellularConf}
            cellularConf={cellularConf}
        />
}

export default FunctonAdd
