import React, {useState, useEffect} from 'react'
import moment from 'moment'
import BaseTable, { AutoResizer } from 'react-base-table'
import 'react-base-table/styles.css'
import styled from 'styled-components'
import ReactTexty from 'react-texty'

const tz = moment.tz.guess()
moment.tz.setDefault(tz)



const Container = styled.div`
    width: 100%; 
    height: 35vh;  
`

const smart_lock = [
    { key: "sn", title: "Serial Number", dataKey: "sn", width: 150, flexGrow: 1, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> },
    { key: "date_smartlock_pair", title: "Date Added", dataKey: "date_smartlock_pair", width: 150, flexGrow: 1, cellRenderer: ({ cellData }) => <ReactTexty>{cellData? moment(cellData).format('DD/MM/YYYY hh:mm:ss a') : ""}</ReactTexty> }
]

const SmartLockDetails = ({details, dateSync}) => {
    let count = details && details.length
  
    return (
        <div className="smart-lock-container">
            <main className="details-card">
                <section className="card-container">
                    <p className="card-title-igloohome">Smart Lock</p>
                    <p className="detail-count-minute-igloohome">{count}</p>
                </section>
                <section className="card-container">
                    <p className="card-title-igloohome">Last Added</p>
                    <p className="detail-count-minute-igloohome">{dateSync === "Invalid date" ? null : dateSync}</p>
                </section>
            </main>
            <Container>
                    <AutoResizer>
                        {({ width, height }) => (
                            <BaseTable columns={smart_lock} width={width} height={height} data={details} />
                        )}
                    </AutoResizer>
            </Container>
            <style>
                {
                    `.details-card{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 24px 24px;
                        grid-template-areas:
                          ". .";
                        margin: 0px 0px 16px 0px;
                    }
                    .card-container{
                        background-color: #FFF;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        border-radius: 4px;
                    }
                    .card-title-igloohome{
                        color: #939598;
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 21px;
                        text-align: center;
                        margin: 10px 0px 0px 0px;
                        
                    }
                    .detail-count-minute-igloohome{
                        color: #333333;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 32px;
                        text-align: center;
                        margin: 0px 0px 10px 0px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default SmartLockDetails
