//Menu
import React from "react";

//list of panels client
import PanelMap from '../../panel/panel-live-map/index'
import PanelTelemetry from '../../panel/panel-live-telemetry/index'
import PanelAssetStatus from '../../panel/panel-live-asset-status/index'
import PanelDeviceClient from '../../panel/panel-device-client/index'
import PanelGeofence from '../../panel/panel-geofence/index'
import PanelReportTelemetry from '../../panel/panel-report-telemetry/index'
import PanelReportAPAD from '../../panel/panel-report-APAD/index'
import PanelReportGeofence from '../../panel/panel-report-geofence/index'
import PanelReportTrips from '../../panel/panel-report-trips/index'
import PanelReportAssetUtilisation from '../../panel/panel-report-asset-utilisation/index'
import PanelReportFuelLog from '../../panel/panel-report-fuel-log/index'
import PanelReportVehicleSpeed from '../../panel/panel-report-vehicle-speed/index'
import PanelReportAlerts from '../../panel/panel-report-alerts'
import PanelAsset from '../../panel/panel-asset/index'
import PanelAssetGroup from '../../panel/panel-asset-group/index'
import PanelFeatures from '../../panel/panel-features/index'
import PanelFirmware from '../../panel/panel-firmware/index'
import PanelSKU from '../../panel/panel-SKU/index'
import PanelDeviceAdmin from '../../panel/panel-device-admin/index'
import PanelAgencies from '../../panel/panel-agencies/index'
import PanelIgloohome from '../../panel/panel-igloohome/index'
import PanelChannel from '../../panel/panel-channel/index'
import PanelUsers from '../../panel/panel-users/index'
import PanelDriver from '../../panel/panel-driver/index'
import PanelBeacon from '../../panel/panel-beacon-admin/index'

//list of images;

//Dark Icon
import FirmwareIcon from "../../Image/firmware.svg"
import DeviceIcon from "../../Image/device.svg"
import SKUSubIcon from "../../Image/sku.svg"
import AlertsIcon from "../../Image/alert.svg"
import AssetUtliIcon from "../../Image/asset_utilisation.svg"
import SpeedingIcon from "../../Image/speeding.svg"
import GeoFencingIcon from "../../Image/geofencing.svg"
import FeaturesIcon from "../../Image/feature.svg"
import TelemetryIcon from "../../Image/telemety_dark.svg"
import MapIcon from "../../Image/map_dark.svg"
import ReportTelemetryIcon from '../../Image/report_telemetry_dark.svg'
import ReportTripIcon from '../../Image/report_trip_dark.svg'
import Agencies from '../../Image/agencies.svg'
import DarkAssetGroup from '../../Image/dark-asset-group.svg'
import DarkAsset from '../../Image/dark-asset.svg'
import DarkAssetStatus from '../../Image/dark-asset-status.svg'
import DarkFuelReport from '../../Image/dark-fuel-report.svg'
import DarkGeofenceReport from '../../Image/dark-geofence-report.svg'
import DarkSmartLocks from '../../Image/dark-smartlocks.svg'
import DarkChannel from '../../Image/dark-channel.svg'
import DarkUsers from '../../Image/user.svg'
import DarkAPAD from '../../Image/dark-bus.svg'
import DarkDriver from '../../Image/dark_driver.svg'
import DarkBeacons from '../../Image/dark-beacons.svg';

//Green Icon
import GreenTelemetryIcon from '../../Image/green-telemetry.svg'
import GreenGeofence from '../../Image/green-geofence.svg'
import GreenDevice from '../../Image/green-device.svg'
import GreenAsset from '../../Image/green-asset.svg'
import GreenAssetUtilisation from '../../Image/green-asset-ulitisation.svg'
import GreenAssetStatus from '../../Image/green-asset-status.svg'
import GreenAssetGroup from '../../Image/green-asset-group.svg'
import GreenMap from '../../Image/green-map.svg'
import GreenTelemetryRepor from '../../Image/green-telemery-report.svg'
import GreenSpeedReport from '../../Image/green-speed-report.svg'
import GreenTripsReport from '../../Image/green-trip-report.svg'
import GreenGeofenceReport from '../../Image/green-geofence-report.svg'
import GreenFuelReport from '../../Image/green-fuel-report.svg'
import GreenAlertReport from '../../Image/green-alerts-report.svg'
import GreenSKU from '../../Image/green-sku.svg'
import GreenFirmware from '../../Image/green-firmware.svg'
import GreenFeature from '../../Image/green-feature-sensor.svg'
import GreenAgencies from '../../Image/green_agencies.svg'
import GreenSmartLocks from '../../Image/green-smartlocks.svg'
import GreenChanel from '../../Image/green_channel.svg'
import GreenUsers from '../../Image/green-user.svg'
import GreenAPAD from '../../Image/green-bus.svg'
import GreenDriver from '../../Image/green_driver.svg'
import GreenBeacons from '../../Image/green-beacons.svg'


const menuConfig = {
    dashboard: [
      {
        icon: "",
        label: "",
        key: "db_default", 
        firebaseKey: "db_default", 
        PanelSubModule: "", 
        role: [],       
        children: [],
        path: ""    
        },
        {
            icon: "",
            label: "LIVE",
            key: "db_live", 
            firebaseKey: "panel_live",  
            role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer"],       
            children: [
              {
                label: "",
                PanelSubModule: "",
                key: "panel_live_default",
                icon: "",
                lightIcon: "",
                firebaseKey: "panel_live_default",
                role: [],
                path: ""
              },
              {
                label: "Map",
                PanelSubModule: PanelMap,
                key: "l_map",
                icon: <img loading="lazy" src={MapIcon} alt="Live"/>,
                lightIcon: <img loading="lazy" src={GreenMap} alt="Live"/>,
                firebaseKey: "panel_live_map",
                role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer"],
                path: "live/map"
              },
              {
                label: "Telemetry",
                PanelSubModule : PanelTelemetry,
                key: "h_telemetry",
                icon: <img loading="lazy" src={TelemetryIcon} alt="Telemetry"/>,
                lightIcon: <img loading="lazy" src={GreenTelemetryIcon} alt="Telemetry"/>,
                firebaseKey: "panel_historical_telemetry",
                role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer"],
                path: "live/telemetry"
              },
              {
                label: "Asset Status",
                PanelSubModule : PanelAssetStatus,
                key: "l_asset_status",
                icon: <img loading="lazy" src={DarkAssetStatus} alt="Telemetry"/>,
                lightIcon: <img loading="lazy" src={GreenAssetStatus} alt="Telemetry"/>,
                firebaseKey: "panel_live_asset_status",
                role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer"],
                path: "live/asset_status"
              },
            ],
            
        },
        {
          icon: "",
          label: "ASSET & DEVICE",
          key: "asset",
          firebaseKey: "panel_asset_management",
          role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer"],
          children: [
            {
              label: "",
              PanelSubModule: "",
              key: "asset_default",
              icon: "",
              lightIcon: "",
              firebaseKey: "asset_default",
              role: [],
              path: ""
            },
            {
              label: "Asset",
              PanelSubModule: PanelAsset,
              key: "as_asset",
              icon: <img loading="lazy" src={DarkAsset} alt="Icon"/>,
              lightIcon: <img loading="lazy" src={GreenAsset} alt="Icon"/>,
              firebaseKey: "panel_asset",
              role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer"],
              path:"asset_mgmt/asset"
            },
            {
              label: "Asset Group",
              PanelSubModule: PanelAssetGroup,
              key: "as_assetGroup",
              icon: <img loading="lazy" src={DarkAssetGroup} alt="Icon"/>,
              lightIcon: <img loading="lazy" src={GreenAssetGroup} alt="Icon"/>,
              firebaseKey: "panel_asset_group",
              role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer"],
              path: "asset_mgmt/asset_group"
            },
            {
              icon: <img loading="lazy" src={DeviceIcon} alt="Icon"/>,
              lightIcon: <img loading="lazy" src={GreenDevice} alt="Icon"/>,
              label: "Device",
              key:"device",
              PanelSubModule: PanelDeviceClient,
              children: [],
              firebaseKey: "panel_live_device",
              role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer"],
              path:"asset_mgmt/device"
            },
            {
              label: "Beacon",
              PanelSubModule : PanelBeacon,
              key: "h_beacon",
              icon: <img loading="lazy" src={DarkBeacons} alt="Icon"/>,
              lightIcon: <img loading="lazy" src={GreenBeacons} alt="Icon"/>,
              firebaseKey: "panel_live_beacon",
              role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer"],
              path:"asset_mgmt/beacon"
            }
          ],
        },
        {
        icon: "",
        label: `REPORT`,
        key: "report",
        firebaseKey: "panel_report",
        role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer", "Reports Viewer"],
        children: [
          {
            label: "",
            PanelSubModule: "",
            key: "report_default",
            icon: "",
            lightIcon: "",
            firebaseKey: "report_default",
            role: [],
            path: ""
          },
          {
            label: "Asset Utilisation",
            PanelSubModule: PanelReportAssetUtilisation,
            key: "rep_asset",
            icon: <img loading="lazy" src={AssetUtliIcon} alt="Icon"/>,
            lightIcon: <img loading="lazy" src={GreenAssetUtilisation} alt="Icon"/>,
            firebaseKey: "panel_report_asset",
            role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer", "Reports Viewer"],
            path: "report/asset_utilisation"
          },
          {
            label: "Vehicle Speed",
            PanelSubModule: PanelReportVehicleSpeed,
            key: "rep_vehicle",
            icon: <img loading="lazy" src={SpeedingIcon} alt="Icon"/>,
            lightIcon: <img loading="lazy" src={GreenSpeedReport} alt="Icon"/>,
            firebaseKey: "panel_report_vehicle",
            role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer", "Reports Viewer"],
            path: "report/vehicle_speed"
          },
          {
            label: "Geofence Log",
            PanelSubModule: PanelReportGeofence,
            key: "rep_geofence",
            icon: <img loading="lazy" src={DarkGeofenceReport} alt="Icon"/>,
            lightIcon: <img loading="lazy" src={GreenGeofenceReport} alt="Icon"/>,
            firebaseKey: "panel_report_geofence_log",
            role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer", "Reports Viewer"],
            path: "report/geofence_log"
          },
          {
            label: "Trips",
            PanelSubModule: PanelReportTrips,
            key: "rep_trips_2",
            icon: <img loading="lazy" src={ReportTripIcon} alt="Icon"/>,
            lightIcon: <img loading="lazy" src={GreenTripsReport} alt="Icon"/>,
            firebaseKey: "panel_report_trips",
            role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer", "Reports Viewer"],
            path: "report/report_trips"
          },
          {
            label: "Fuel Usage",
            PanelSubModule: PanelReportFuelLog,
            key: "rep_fuel",
            icon: <img loading="lazy" src={DarkFuelReport} alt="Icon"/>,
            lightIcon: <img loading="lazy" src={GreenFuelReport} alt="Icon"/>,
            firebaseKey: "panel_report_fuel_usage",
            role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer", "Reports Viewer"],
            path: "report/fuel_usage"
          },
          {
            label: "Telemetry Log",
            PanelSubModule: PanelReportTelemetry,
            key: "rep_telemetry",
            icon: <img loading="lazy" src={ReportTelemetryIcon} alt="Icon"/>,
            lightIcon: <img loading="lazy" src={GreenTelemetryRepor} alt="Icon"/>,
            firebaseKey: "panel_report_telemetry",
            role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer", "Reports Viewer"],
            path: "report/report_telemetry"
          },
          {
            label: "Alerts",
            PanelSubModule: PanelReportAlerts,
            key: "rep_alert",
            icon: <img loading="lazy" src={AlertsIcon} alt="Icon"/>,
            lightIcon: <img loading="lazy" src={GreenAlertReport} alt="Icon"/>,
            firebaseKey: "panel_report_alerts",
            children:[],
            role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer", "Reports Viewer"],
            path: "report/alerts"
          },
        ]
      }
      ,{
        icon: "",
        label: `GEOFENCE`,
        key: "geofence",
        firebaseKey: "panel_geofencing_geofence",
        role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer"],
        children: [
          {
            label: "",
            PanelSubModule: "",
            key: "geofence_default",
            icon: "",
            lightIcon: "",
            firebaseKey: "geofence_default",
            role: [],
            path: ""
          },
          {            
            label: "Policies",
            PanelSubModule: PanelGeofence,
            key: "rep_geofence",
            icon: <img loading="lazy" src={GeoFencingIcon} alt="Icon"/>,
            lightIcon: <img loading="lazy" src={GreenGeofence} alt="Icon"/>,
            firebaseKey: "panel_report_telemetry",
            role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer"],
            path: "geofence/policies"
        }
        ],
      },
      {
        icon: "",
        label: `COMMUNICATIONS`,
        firebaseKey: "panel_communication_channel",
        key: "communication",
        role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer"],
        children: [
          {
            label: "",
            PanelSubModule: "",
            key: "channel_default",
            icon: "",
            lightIcon: "",
            firebaseKey: "channel_default",
            role: [],
            path: ""
          },
          {
          label: "Channel",
          PanelSubModule : PanelChannel,
          key: "h_channel",
          icon: <img loading="lazy" src={DarkChannel} alt="Icon"/>,
          lightIcon: <img loading="lazy" src={GreenChanel} alt="Icon"/>,
          firebaseKey: "panel_channel",
          role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer"],
          path: "communication/channel"
        }
      ]
      },
      {
        icon: "",
        label: `USERS MANAGEMENT`,
        firebaseKey: "panel_user_management",
        key: "user_management",
        role: ["Agency Administrator", "Agency Viewer",],
        children: [
          {
            label: "",
            PanelSubModule: "",
            key: "users_default",
            icon: "",
            lightIcon: "",
            firebaseKey: "users_default",
            role: [],
            path: ""
          },
          {
          label: "Users",
          PanelSubModule : PanelUsers,
          key: "h_users",
          icon: <img loading="lazy" src={DarkUsers} alt="Icon"/>,
          lightIcon: <img loading="lazy" src={GreenUsers} alt="Icon"/>,
          firebaseKey: "panel_user",
          role: ["Agency Administrator", "Agency Viewer"],
          path: "users_mgmt/users"
        }
      ]
      },
      {
        icon: "",
        label: `TRANSIT PAY`,
        firebaseKey: "panel_basfare_module",
        key: "basfare_module",
        role: ["Agency Administrator", "Agency Viewer", "Reports Viewer", "Land Transport Authority"],
        children: [
          {
            label: "",
            PanelSubModule: "",
            key: "basfare_default",
            icon: "",
            lightIcon: "",
            firebaseKey: "basfare_default",
            role: [],
            path: ""
          },
          {
            label: "APAD Report",
            PanelSubModule: PanelReportAPAD,
            key: "rep_APAD",
            icon: <img loading="lazy" src={DarkAPAD} alt="Icon"/>,
            lightIcon: <img loading="lazy" src={GreenAPAD} alt="Icon"/>,
            firebaseKey: "panel_report_apad",
            role: ["Agency Administrator", "Agency Viewer", "Asset Manager", "Asset Viewer", "Reports Viewer", "Land Transport Authority"],
            path:"report/apad_report"
          },
          {
          label: "Driver",
          PanelSubModule : PanelDriver,
          key: "h_driver",
          icon: <img loading="lazy" src={DarkDriver} alt="Icon" />,
          lightIcon: <img loading="lazy" src={GreenDriver} alt="Icon"/>,
          firebaseKey: "panel_basfare",
          role: ["Agency Administrator", "Agency Viewer"],
          path: "basfare/driver"
        }
      ]
      }
    ], console: [
      {
        icon: "",
        label: "",
        key: "cl_default", 
        firebaseKey: "cl_default", 
        PanelSubModule: "", 
        role: [],       
        children: [],
        path: ""
        },
      {
        icon: "",
        label: `AGENCY MANAGEMENT`,
        firebaseKey: "panel_agencies",
        key: "agency",
        role: ["System Administrator"],
        children: [
          {
            label: "",
            PanelSubModule: "",
            key: "agency_default",
            icon: "",
            lightIcon: "",
            firebaseKey: "agency_default",
            role: [],
            path: ""
          },
          {
          label: "Agencies",
          firebaseKey:  "panel_agencies",
          PanelSubModule: PanelAgencies,
          icon: <img loading="lazy" src={Agencies} alt="Icon"/>,
          lightIcon: <img loading="lazy" src={GreenAgencies} alt="Icon"/>,
          role: ["System Administrator"],
          path:"agency_mgmt/agencies"
        }
      ],
      },
      {
        icon: "",
        label: `DEVICE MANAGEMENT`,
        firebaseKey: "panel_device_management",
        key: "device",
        role: ["System Administrator"],
        children: [
          {
            label: "",
            PanelSubModule: "",
            key: "device_default",
            icon: "",
            lightIcon: "",
            firebaseKey: "device_default",
            role: [],
            path: ""
          },
          {
          label: "Device",
          key: "device",
          firebaseKey:  "panel_device_management",
          PanelSubModule: PanelDeviceAdmin,
          icon: <img loading="lazy" src={DeviceIcon} alt="Icon"/>,
          lightIcon: <img loading="lazy" src={GreenDevice} alt="Icon"/>,
          children: [],
          role: ["System Administrator"],
          path: "device_mgmt/device"
        },{
          label: "SKU",
          key: "sku",
          firebaseKey: "panel_sku_management",
          PanelSubModule: PanelSKU,
          icon: <img loading="lazy" src={SKUSubIcon} alt="Icon"/>,
          lightIcon: <img loading="lazy" src={GreenSKU} alt="Icon"/>,
          children: [],
          role: ["System Administrator"],
          path: "device_mgmt/sku"
        },{
          label: "Firmware",
          PanelSubModule: PanelFirmware,
          key: "firmware",
          firebaseKey:  "panel_device_management_firmware",
          icon: <img loading="lazy" src={FirmwareIcon} alt="Icon"/>,
          lightIcon: <img loading="lazy" src={GreenFirmware} alt="Icon"/>,
          children: [],
          role: ["System Administrator"],
          path: "device_mgmt/firmware"
        },{
          label: "Features & Sensors",
          PanelSubModule: PanelFeatures,
          key: "feature",
          firebaseKey:  "panel_features",
          icon: <img loading="lazy" src={FeaturesIcon} alt="Icon"/>,
          lightIcon: <img loading="lazy" src={GreenFeature} alt="Icon"/>,
          children: [],
          role: ["System Administrator"],
          path: "device_mgmt/feature_sensors"
        },{
          label: "Telemetry",
          PanelSubModule : "",
          key: "h_telemetry",
          icon: "",
          lightIcon: "",
          firebaseKey: "panel_admin_telemetry",
          children:[],
          role: ["System Administrator"],
          path: "device_management/telemetry"
        },{
          label: "Beacon",
          PanelSubModule : PanelBeacon,
          key: "h_beacon",
          icon: <img loading="lazy" src={DarkBeacons} alt="Icon"/>,
          lightIcon: <img loading="lazy" src={GreenBeacons} alt="Icon"/>,
          firebaseKey: "panel_beacon",
          role: ["System Administrator"],
          path:"device_management/beacon"
        }
      ]
      },
      {
        icon: "",
        label: `3RD PARTY INTEGRATION`,
        firebaseKey: "panel_third_party_integration",
        key: "device",
        role: ["System Administrator"],
        children: [
          {
            label: "",
            PanelSubModule: "",
            key: "third_default",
            icon: "",
            lightIcon: "",
            firebaseKey: "third_default",
            role: [],
            path: ""
          },
          {
          label: "Igloohome",
          PanelSubModule : PanelIgloohome,
          key: "h_igloohome",
          icon: <img loading="lazy" src={DarkSmartLocks} alt="Icon"/>,
          lightIcon: <img loading="lazy" src={GreenSmartLocks} alt="Icon"/>,
          firebaseKey: "panel_igloohome",
          role: ["System Administrator"],
          path:"integrations/igloohome"
        }]
      },
    ]

}

for (let page of menuConfig.dashboard) {
  let idx = menuConfig.dashboard.indexOf(page)
  for (let child of page.children) {
    child['parentIdx'] = idx
    // console.log(child)
  }
}

for (let page of menuConfig.console){
  let idx = menuConfig.console.indexOf(page)
  for(let child of page.children){
    child['parentIdx'] = idx
  }
}

export const pageIndex ={
    /**
     * **Dashboard module**
     */
    dashboard: 0,
    db_live: 0, //Submodule of Dashboard
    db_historical: 1,//Submodule of Dashboard

    /**
     * **User module**
     */
    user: 1,
    us_user: 0,//Submodule of User
    us_role: 1,//Submodule of User

    /**
     * **Agency module**
     * hello
     */
    agency: 2,

    /**
     * **Asset module**
     */
    asset: 3,
    as_asset: 0,//Submodule of Asset
    as_assetGroup: 1,//Submodule of Asset

    device: 4,
    dv_device: 0,
    dv_firmware: 1,
}

export default menuConfig;
