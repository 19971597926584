import React from 'react'

import DarkCloseIcon from '../../Image/close.svg'
import TelegramIcon from '../../Image/telegram-icon.svg'
import SlackIcon from '../../Image/slack_icon.svg'
import WhatsappIcon from '../../Image/whatsapp_icon.svg'

const ListChannelLeaveCont = ({notification, setNotification, featureDisable, setAddNotification, addNotification, setRemoveNotification, removeNotification, isEdit}) => {

    const Icon = ({comm}) => {
        switch (comm) {
            case "Telegram":
                return <img loading="lazy" src={TelegramIcon} alt="msg-icon"/>
            case "slack":
                return <img loading="lazy" src={SlackIcon} alt="msg-icon"/>
            case "whatsapp":
                return <img loading="lazy" src={WhatsappIcon} alt="msg-icon"/>
            default:
                break;
        }  
    }

    const removeList = (e, i, x) => {
        e.preventDefault();
        let clone = [...notification.leave]
        clone.splice(i, 1)
        setNotification({...notification, leave: clone})
        if(isEdit){
            if (addNotification.leave.includes(x.uuid)) {
                let test = addNotification.leave.filter((i) => i !== x.uuid);
                setAddNotification({...addNotification, leave: test.uuid});
            }
    
            if (!removeNotification.leave.includes(x.uuid)) {
                setRemoveNotification({...removeNotification, leave: [...removeNotification.leave, x.uuid]})
            }
        }

    }
    return (
        <div className="enter-container">
            {
                notification.leave.map((x, i) => {
                    return (
                        <section className="enter-list">
                            <div className="enter-list-container">
                                <Icon comm={x.type} />
                                <p>{x.name}</p>
                            </div>
                            {featureDisable ? null : <img lazy="loading" src={DarkCloseIcon} alt="close-icon" onClick={(e) => removeList(e, i, x)}/>}
                        </section>
                    )
                })
            }
            <style>
                {
                    `
                    .enter-list{
                        display: flex;
                        justify-content: space-between;
                    }
                    .enter-list-container{
                        display: flex;
                        align-items: center;
                    }
                    .enter-container{
                        margin: 8px 0px 8px 0px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default ListChannelLeaveCont
