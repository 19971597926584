// FORM AGENCIES
import React, { useState, useEffect, useContext, useMemo} from 'react'

//libary
import ReactTagInput from "@pathofdev/react-tag-input";
import { useQuery } from "@apollo/react-hooks";
import PhoneInput from 'react-phone-input-2'
import countryList from 'react-select-country-list'
import 'react-phone-input-2/lib/style.css'
import 'react-dual-listbox/lib/react-dual-listbox.css';
import TimezoneSelect from 'react-timezone-select'


//component
import AssignMany from '../../component/component-assignmany/AssignMany';
// import DeleteCard from "../../component/component-deletecard/DeleteCard"
import DeleteCard from '../../component/component-deletecardRest/DeleteCard';
import tableConfig from "../../config/config-table/table";
// import {SUSPENDED_AGENCIES, GET_AVAILABLE_DEVICES} from "../../config/config-graphql/Graphql";
import { settingFieldValue, requiredLabel } from "../../config/config-function/function";
import {firebaseContext} from '../../config/config-firebase/FirebaseContext'
import AgencyAdmin from './AgencyAdmin'
import Billing from './Billing'
import { APIGetAvailableBeacons, APIGetAvailableDevices } from '../../config/config-restAPI/getAPICalled';
import Cookies from 'js-cookie';
import { APISuspendAgencies } from '../../config/config-restAPI/patchAPICalled';

const DeviceDropdownOption = [ { label: "Orion", value: "Orion" }, { label: "Pulsar", value: "Pulsar"}]
const { agencies: fieldList } = tableConfig.fieldList;
const Form = ({ agencies, beaconAdd, setbeaconAdd, beaconRemove, staticBeaconList=[], setBeaconRemove, APICalled, setOriginalBeacon, originalBeacon, setBeacons, beacons, onUserInput, displayed, staticDeviceList=[], query, setTypeID, typeID, fx_back, userAdmistration, setUserAdmistration, setDevices, devices, addList, setAddList, removeDeviceList, setRemoveDevice, originalDevice, staticUserAdministration, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere, setAddress, address, billing, setBilling, setCountryCode, setContact_number, contact_number ,countryCode, setFilterFinal, setPageInd, setSelectedTimezone, selectedTimezone}) => {
  // const { data: dvData, loading: dvLoading, error: dvError } = useQuery(GET_AVAILABLE_DEVICES, { variables: {type: "All"} });

  // !dvLoading && console.log(dvData)


  const token = Cookies.get('jwtToken');
  const agencyId = Cookies.get('agency-id');
  const isBulkEdit = Array.isArray(agencies) && agencies.length > 1;

  const [selectedDevice, setSelectedDevice] = useState([])
  const [selectedBeacon, setSelectedBeacon] = useState([])
  const [staticAvailableBeacon, setStaticAvailableBeacon] = useState([])
  const [staticAvailableDevices, setStaticAvailableDevices] = useState([])
  const [beaconAValue, setBeaconAValue] = useState([])
  const [devicesAValue, setDevicesAValue] = useState([])
  const [loading, setLoading] = useState(false);
  const [loadingAvailable, setLoadingAvailable] = useState(false);
  const [beaconOptions, setBeaconOptions] = useState([]);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [availableDevices, setAvailableDevices] = useState([]);
  const [availableBeacons, setAvailableBeacons] = useState([])
  const firebaseConfig = useContext(firebaseContext)

  // console.log(billing)
  //this is for display all the country
  const coutryOptions = useMemo(() => countryList().getData(), [])

  const [country, setCountry] = useState('')


  // console.log({
  //   beaconOptions, beaconAdd, beaconRemove, staticBeaconList, setbeaconAdd, setBeaconRemove
  // })

  let regex = new RegExp(/^(?!admin|system)(?!\/\.\ \"\$\*\<\>\:\|\?)(?=[a-zA-Z0-9]{1})((\w)|([-])(\w)){3,63}$/gm)

  const [isHovered, setIsHovered] = useState({
    id: false,
    name: false,
    contact_number: false,
    streetAddress: false,
    city: false,
    state: false,
    postCode: false,
    country: false,
    email: false,
    login_password: false,
    firstName: false,
    lastName: false,
    billing_from: false,
    billing_to: false,
    deviceTypeId: false,
  })

  const APICalledDevices = async (filter) => {
    try {
        const result = await APIGetAvailableDevices(token, setLoadingAvailable, agencyId)
        // console.log('result', result);
        if (result.data.status === 200) {
          setDevicesAValue(result.data.data);
          setLoadingAvailable(false);
        } else {
            setSnOpen(true);
            setMessage("Error: Unable to retrieve data. Please try again later.");
            setSeverity("error") 
            setLoadingAvailable(false);
        }
    } catch(err) {
        setSnOpen(true);
        setMessage("Error: Unable to retrieve data. Please try again later.");
        setSeverity("error");
        setLoadingAvailable(false);
    }
}


  const APICalledBeacon = async (filter) => {
    try {
        const result = await APIGetAvailableBeacons(token, setLoading, agencyId)
        if (result.data.status === 200) {
            setBeaconAValue(result.data.data);
            setLoading(false);
        } else {
            setSnOpen(true);
            setMessage("Error: Unable to retrieve data. Please try again later.");
            setSeverity("error") 
            setLoading(false);
        }
    } catch(err) {
        setSnOpen(true);
        setMessage("Error: Unable to retrieve data. Please try again later.");
        setSeverity("error");
        setLoading(false);
    }
}

  useEffect(() => {
    if(token) {
      APICalledBeacon();
      APICalledDevices();
    }
  }, [])


  useEffect(() => {
    if (!loadingAvailable) {
      const _availableDevices = [];

      // Only loop through to populate _availableDevices if .get_available_devices is not empty (null)
      if ( devicesAValue.length > 0) {
        // devicesAValue.map(x => { return { uuid: x.uuid, sn: x.sn }})
        setStaticAvailableDevices(devicesAValue.map(x => x.uuid))
        for (let i = 0; i < devicesAValue.length; i++) {
          let el = devicesAValue[i];
          let option = { value: el.productModel, label: `${el.productModel}(${(devicesAValue.filter(e => e.productModel === "Orion").length)})` }
          if (!_availableDevices.find(x => x.value === option.value)) {
            _availableDevices.push(option)
          }
          // console.log(option);
        }
      }

      setAvailableDevices(_availableDevices)

      const selectedDvOptions = agencies[0] && agencies[0].devices.map(el => { return { value: el.uuid, label: el.sn, option: el.productModel } })
      const _selected = selectedDvOptions && selectedDvOptions.map(el => el.value)
      setSelectedDevice(_selected)

      let options = devicesAValue && devicesAValue.length > 0 ? devicesAValue.map(el => { return { value: el.uuid, label: el.sn, option: el.productModel } }) : []
      selectedDvOptions ? setDeviceOptions([...options, ...selectedDvOptions]) : setDeviceOptions(options)
    }
      //---this is to disable the warning for useEffect----//
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingAvailable])

  useEffect(() => {
    if (!loading) {
      const _availableBeacons = [];

      // Only loop through to populate _availableDevices if .get_available_devices is not empty (null)
      if ( beaconAValue.length > 0) {
        // devicesAValue.map(x => { return { uuid: x.uuid, sn: x.sn }})
        setStaticAvailableBeacon(beaconAValue.map(x => x._id))
        for (let i = 0; i < beaconAValue.length; i++) {
          let option = { value: 'beacon', label: `Beacons (${beaconAValue.length})` }
          if (!_availableBeacons.find(x => x.value === option.value)) {
            _availableBeacons.push(option)
          }
        }
      }

      setAvailableBeacons(_availableBeacons)

      const selectedBeaOptions = agencies[0] && agencies[0].beacons.length > 0 && agencies[0].beacons.map(el => { return { value: el.uuid, label: el.serialNumber, option: el.productModel } })
      const _selected = selectedBeaOptions && selectedBeaOptions.map(el => el.value)
      setSelectedBeacon(_selected)

      let options = beaconAValue && beaconAValue.length > 0 ? beaconAValue.map(el => { return { value: el._id, label: el.serialNumber, option: 'Beacons' } }) : []
      selectedBeaOptions ? setBeaconOptions([...options, ...selectedBeaOptions]) : setBeaconOptions(options)
    }
      //---this is to disable the warning for useEffect----//
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])


  // console.log('selectedDevices', selectedDevice);
  //this is to settingUp User Admin
  const settingUserAdmin = (key, value) => {
    let clone = { ...userAdmistration }
    clone[key] = value
    setUserAdmistration(clone)
  }

  //this is using for onUserInput for address
  const settingAddress = (key, value) => {
    let clone = {...address}
    clone[key] = value
    setAddress(clone)
  }

  const settingBiling = (key, value) => {
    let clone = {...billing}
    clone[key] = value
    setBilling(clone)

  }

  useEffect(() => {
    if(beaconAdd){

      if(displayed){ //edit 
        //staticDeviceList are the assign devices
        //concatenating both list together
        const staticBeacon = staticBeaconList.map(x => x.uuid)
        // console.log('staticDevices', staticDevices);

        //concatenating newly added devices
        const addedBeacon = staticBeacon.concat(beaconAdd)

        //filtering agains remove device list
        const updatedBeacon = addedBeacon.filter(x => !beaconRemove.includes(x))
        // console.log('updatedDevices', updatedDevice);
        // console.log('addList', addList)
        onUserInput(null, { beacon_add: updatedBeacon.map(x => {return { uuid: x}})}, true)
      }else{ //add
        onUserInput(null, { beacon_add: beaconAdd.map(x => {return { uuid: x} })}, true)
      }

    }
  }, [beaconAdd])

  useEffect(() => {
    if(beaconRemove){
      onUserInput(null, { beacon_remove: beaconRemove.map(x => { 
          return { uuid: x}

      })}, true)
  }
  }, [beaconRemove])
  
  

  //this useEffect for devices_add schema onUserInput
  useEffect(() => {
    if(addList){

      if(displayed){ //edit 
        //staticDeviceList are the assign devices
        //concatenating both list together
        const staticDevices = staticDeviceList.map(x => x.uuid)
        // console.log('staticDevices', staticDevices);

        //concatenating newly added devices
        const addedDevice = staticDevices.concat(addList)

        //filtering agains remove device list
        const updatedDevice = addedDevice.filter(x => !removeDeviceList.includes(x))
        // console.log('updatedDevices', updatedDevice);
        // console.log('addList', addList)
        onUserInput(null, { devices_add: updatedDevice.map(x => {return { uuid: x}})}, true)
      }else{ //add
        onUserInput(null, { devices_add: addList.map(x => {return { uuid: x} })}, true)
      }

    }
      //---this is to disable the warning for useEffect----//
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addList])

  //this useEffect is for device_remove schema onUserInput
  useEffect(() => {
    if(removeDeviceList){
        onUserInput(null, { devices_remove: removeDeviceList.map(x => { 
            return { uuid: x}

        })}, true)
    }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeDeviceList])

  const onSuspended = async () => {
    try {
      const reducer = { agency : {
        uuid: agencies[0].uuid,
        id: agencies[0].id,
        active: !agencies[0].isActive
      }}
      const response = await APISuspendAgencies(token, agencyId, reducer, setLoading);
      if (response.data.status === 200) {
        setSnOpen(true);
        setMessage("The agency status updated!");
        setSeverity("success");
        setLoading(false)
        setPageInd(false)
        setFilterFinal({})
        APICalled();
        fx_back();
      } else {
        setLoading(false)
        setErrOpen(true)
        setErrMsg(`${response.data.status}: ${response.data.error.message}`)
        setErrSevere("error")
      } 
    } catch(err) {
      setLoading(false)
      setErrOpen(true)
      setErrMsg('Unable to suspend the agency, Please try again')
      setErrSevere("error")
    }

  }

  // console.log(deviceOptions)

  const contactNumber = (e, value) => {
    let contryCode = value.dialCode
    // let codeLength = value.dialCode.length
    // const number = e.substring(codeLength)
    if(e){
      onUserInput(null, {contactNumber: e}, true)
    }

    setCountryCode(contryCode)
  }

  // console.log('beaconAdd', beaconAdd);

  // console.log('staticAvailableList', staticAvailableDevices);

  return (
    <div>
      <form className="agencies-container">
      {/* {
                  displayed ? <DeleteCard 
                  module="agencies" 
                  buttonType="suspended"
                  query={query} 
                  uuid={agencies[0].uuid} 
                  agency_id={agencies[0].id} 
                  schema_uuid="agency" 
                  fx_back={fx_back} 
                  suspend_agency_query={SUSPENDED_AGENCIES} 
                  active={agencies[0].isActive} 
                  canDelete={staticDeviceList && staticDeviceList.length > 0 ? false: true}
                  setSnOpen={setSnOpen} 
                  setMessage={setMessage}
                  setSeverity={setSeverity}
                  setErrMsg={setErrMsg} 
                  setErrOpen={setErrOpen} 
                  setErrSevere={setErrSevere}
                  setFilterFinal={setFilterFinal}
                  setPageInd={setPageInd}
                  /> : null
                } */}
                {
                  displayed && <DeleteCard
                   buttonType="suspended"
                   active={agencies[0].isActive}
                   onSuspend={onSuspended}
                  />
                }
        <div className="agency">
          <div className="agency-information">
            <div className="box-info">
              <div className="information-suspended-container">
                <div className="title">Agency Information</div>
              </div>   
            </div>
            <div className="input-information">
              <div className="details-input" >
                <div className="input-input">ID <p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.id.required)}</p></div>
                <div className="text-box" >
                  <input type="text" className={`${isHovered.id ? "isHovered-border" : "text-box"}`} placeholder="-" name="id" value={settingFieldValue(isBulkEdit, agencies, fieldList.id.name)} onChange={(e) => onUserInput(e)} disabled={displayed ? true : false} onMouseEnter={() => setIsHovered((current) => ({...current, id: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, id: false}))}/>
                  <p className="error-for-id">{agencies && agencies.id && !regex.test(agencies.id) ? "*Error" : null}</p>
                </div>
              </div>
              <div className="details-input" >
                <div className="input-input">Name <p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.name.required)}</p></div>
                <input type="text" className={`${isHovered.name ? "isHovered-border" : "text-box"}`} placeholder="-" name="name" value={settingFieldValue(isBulkEdit, agencies, fieldList.name.name)} onChange={(e) => onUserInput(e)} onMouseEnter={() => setIsHovered((current) => ({...current, name: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, name: false}))}/>
              </div>
              <div className="details-input" >
                <div className="input-input">Contact Number <p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.contact_number.required)}</p></div>
                <PhoneInput searchClass="search-class" country={'my'} value={displayed ? contact_number : settingFieldValue(isBulkEdit, agencies, fieldList.contact_number.name)} onChange={(e, value) => contactNumber(e, value)} enableAreaCodeStretch enableSearch={true} onMouseEnter={() => setIsHovered((current) => ({...current, contact_number: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, contact_number: false}))}/>
              </div>
              <div className="details-input" >
                <div className="input-input">Timezone <p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.timezone.required)}</p></div>
                <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} />
              </div>
            </div>
          </div>
          <div className="agency-information">
          <div className="address-input">
              <div className="details-input">
                <div className="input-input">Street Address</div>
                <input type="text" className={`${isHovered.streetAddress ? "isHovered-border" : "text-box"}`} placeholder="-" name="road" value={address.road} onChange={(e) => settingAddress(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, streetAddress: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, streetAddress: false}))}/>
              </div>
              <div className="details-input">
                <div className="input-input">City</div>
                <input type="text" className={`${isHovered.city ? "isHovered-border" : "text-box"}`} placeholder="-" name="city" value={address.city} onChange={(e) => settingAddress(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, city: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, city: false}))}/>
              </div>
              <div className="details-input">
                <div className="input-input">State </div>
                <input type="text" className={`${isHovered.state ? "isHovered-border" : "text-box"}`} placeholder="-" name="state" value={address.state} onChange={(e) => settingAddress(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, state: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, state: false}))}/>
              </div>
              <div className="details-input" >
                <div className="input-input">Postcode</div>
                <input type="text" className={`${isHovered.postCode ? "isHovered-border" : "text-box"}`} placeholder="-" name="postcode" value={address.postcode} onChange={(e) => settingAddress(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, postCode: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, postCode: false}))}/>
              </div>
              <div className="details-input" >
                <div className="input-input">Country</div>
                <select className={`${isHovered.country ? "isHovered-selection" : "selection-board-agency"}`} value={address.country} name="country" onChange={(e) => settingAddress(e.target.name, e.target.value)} onMouseEnter={() => setIsHovered((current) => ({...current, country: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, country: false}))}>
                  <option value="">-</option>
                  {
                      coutryOptions.map((x,i) => {
                        return <option value={x.label}>{x.label}</option>
                      })
                  }
                </select>
              </div>
            </div>
          </div>

        </div>
        <div className="administration-agency">
          <AgencyAdmin description={firebaseConfig.getValueByKey("admin_agency_administrator")} requiredLabel={requiredLabel} userAdmistration={userAdmistration} settingUserAdmin={settingUserAdmin} displayed={displayed} staticUserAdministration={staticUserAdministration} fieldList={fieldList} setIsHovered={setIsHovered} isHovered={isHovered}/>
          <Billing description={firebaseConfig.getValueByKey("admin_agency_billing")} displayed={displayed} agencies={agencies} fieldList={fieldList} settingFieldValue={settingFieldValue} isBulkEdit={isBulkEdit} settingBiling={settingBiling} setIsHovered={setIsHovered} isHovered={isHovered}/>
        </div>
          <AssignMany 
            title="Device Assignment"
            description={firebaseConfig.getValueByKey("admin_agency_device_assign")}
            label="Device"
            optionList={deviceOptions}
            staticAssignedList={staticDeviceList.map(x => x.uuid)}
            staticAvailableList={staticAvailableDevices}
            addList={addList}
            setAddList={setAddList}
            removeList={removeDeviceList}
            setRemoveList={setRemoveDevice}
            filterDropdownOption={DeviceDropdownOption}
            isSmartLocks={false}
          />
        <div className="msc-information">
          <div className="sigfox-container">
            <div className="sigfox-int">
              <div className="box-info">
                <div className="title">Sigfox Backend Integration</div>
                <div className="title-detail">{firebaseConfig.getValueByKey("admin_agency_sigfox_integration")}</div>
              </div>
              <div className="input-information">
                <div className="details-input" >
                  <div className="input-input">Device Type ID</div>
                  <div className={`${isHovered.deviceTypeId ? "isHovered-selection" : "tag-input"}`} onMouseEnter={() => setIsHovered((current) => ({...current, deviceTypeId: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, deviceTypeId: false}))}>
                    <ReactTagInput tags={typeID} onChange={(newFeature) => setTypeID(newFeature)} placeholder="+" onMouseEnter={() => setIsHovered((current) => ({...current, deviceTypeId: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, deviceTypeId: false}))}/>
                  </div>          
                </div>
              </div>
            </div>
          </div>
          
          {/* {
              displayed ?
                <div className="delete-container">
                  <DeleteCard 
                    title="Delete or Suspend Agency" 
                    module="agencies" 
                    details={firebaseConfig.getValueByKey("admin_agency_suspend")} 
                    query={query} 
                    uuid={agencies[0].uuid} 
                    agency_id={agencies[0].id} 
                    schema_uuid="agency" 
                    fx_back={fx_back} 
                    suspend_agency_query={SUSPENDED_AGENCIES} 
                    active={agencies[0].isActive} 
                    canDelete={staticDeviceList && staticDeviceList.length > 0 ? false: true}
                    setSnOpen={setSnOpen} 
                    setMessage={setMessage}
                    setSeverity={setSeverity}
                    setErrMsg={setErrMsg} 
                    setErrOpen={setErrOpen} 
                    setErrSevere={setErrSevere}
                    setFilterFinal={setFilterFinal}
                    setPageInd={setPageInd}
                  />
                </div> : null
            } */}
        </div>
        {/* UI for beacon-container */}
        <div className="beacon-container">
            {/* <AssignMany
                title="Beacon Assignment"
                description="" // have to add in the rescription in firebase config
                label="Beacon"
                optionList={beaconOptions}
                staticAssignedList={staticBeaconList.map(x => x.uuid)} // this need to change
                staticAvailableList={staticAvailableBeacon}
                addList={beaconAdd} // this need to change
                setAddList={setbeaconAdd} // this need to change
                removeList={beaconRemove} // this need to change
                setRemoveList={setBeaconRemove} // this need to change
                filterDropdownOption={DeviceDropdownOption}
                isSmartLocks={false} // this need to change
            /> */}
            <AssignMany 
              title="Beacon Assignment"
              description=""
              label="Beacon"
              optionList={beaconOptions}
              staticAssignedList={staticBeaconList.map(x => x.uuid)}
              staticAvailableList={staticAvailableBeacon}
              addList={beaconAdd}
              setAddList={setbeaconAdd}
              removeList={beaconRemove}
              setRemoveList={setBeaconRemove}
              filterDropdownOption={[]}
              isSmartLocks={false}
            />
          </div>
      </form>
      <style jsx>
        {
          `
              .sync-button{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin: 16px 0px 0px 0px;
                cursor: pointer;
              }
              .edit-password{
                width: 100%;
              }
              .agencies-container{
                display: flex;
                flex-direction: column;
              }
              .error-for-id{
                margin: 0px;
                font-size: 10px;
                color: red;
              }
              .agency{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr;
                gap: 0px 16px;
                grid-template-areas:
                  ". .";
                margin: 0px 0px 24px 0px;
                background-color: #FFF;
              }
              .selection-board-agency{
                border-style:none;
                background-color: #FFF;
                height: 24px;
                width: 60%;
                margin: 0px;
                outline: none;
                border-radius: 4px;
            }
            .beacon-container{
              margin: 24px 0px 0px 0px;
            }
            .isHovered-selection{
              background-color: rgba(182, 186, 191, 0.2);
              border-radius: 4px;
              border-style:none;
              height: 24px;
              width: 60%;
              margin: 0px;
              outline: none;
            }
              .administration-agency{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr;
                gap: 0px 16px;
                grid-template-areas:
                  ". .";
                margin: 0px 0px 24px 0px;
              }
              .visibility-password{
                margin: 0px;
                padding: 0px;
                cursor: pointer;
              }
              .agency-information{
                border-radius: 4px;
                background-color: #FFFFFF;
                padding: 16px;
              }
              .adding-more-padding{
                padding: 0px 2px 0px 0px;
              }
              .agency-administration{
                border-radius: 4px;
                background-color: #FFFFFF;
                padding: 16px;
              }
              .agency-device-management{
                border-radius: 4px;
                background-color: #FFFFFF;
                margin: 24px 0px 0px 0px;
                padding: 16px;
              }
              .msc-information{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr;
                gap: 0px 16px;
                grid-template-areas:
                  ". .";
                margin: 24px 0px 0px 0px;
              }
              .igloohome-int{
                border-radius: 4px;
                background-color: #FFFFFF;
                margin: 0px 24px 0px 0px;
                padding: 16px;
              }
              .sigfox-int{
                // height: 175px;
                border-radius: 4px;
                background-color: #FFFFFF;
                padding: 16px;
              }
              .box-info{
                margin: 0px 0px 16px 0px;
              }
              .title{
                margin: 0px 0px 4px 0px;
                height: 21px;
                color: #333333;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 21px;
              }
              .title-detail{
                margin: 0px 0px 8px 0px;
                color: #939598;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 16px;

              }
              .input-information{
                margin: 8px 0px 0px 0px;
              }
              .address-input{
                margin: 36px 0px 0px 0px;
              }
              .input-input{
                display: flex;
                align-items: center;
                // width: 133px;
              }
              .details-input{
                display: grid;
                grid-template-columns: 150px 1fr;
                grid-template-rows: 1fr;
                gap: 0px 0px;
                grid-template-areas:
                  ". .";
                margin: 0px 0px 8px 0px;
                height: 30px;
              }
              .details-input-admin{
                display: grid;
                grid-template-columns: 150px 1fr 24px;
                grid-template-rows: 1fr;
                gap: 0px 0px;
                grid-template-areas:
                  ". . .";
                margin: 0px 0px 8px 0px;
                height: 30px;
              }
              .text-box{
                border-style: none;
                // width: 100%;
                border-radius: 4px;
                outline: none;
                height: 24px;
              }
              .isHovered-border{
                height: 24px;
                outline: none;
                border: 1px solid #b6babf;
                border-radius: 4px;
              }
              .text-box-date{
                border-style: none;
                height: 24px;
                width: 200px;
                outline: none;
                font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
                border-radius: 4px;
              }
              .isHovered-date{
                border-style: none;
                height: 24px;
                width: 200px;
                outline: none;
                font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
                border-radius: 4px;
                background-color: rgba(182, 186, 191, 0.2);
              }
              .id-textbox{
                border-style: none;
                width: 100%;
                outline: none;
                border-radius: 4px;
              }
              .isHovered-id{
                border-style: none;
                outline: none;
                border-radius: 4px;
              }
              .igloohome-det{

              }
              .igloohome-title{

              }
              .igloogime-story{

              }
              .tag-input{
                background-color: #FFFFF;
              }
              .isHovered-tag{
                background-color: rgba(182, 186, 191, 0.2);
              }
              .iglohoome-api{
                width: 240px;
                color: #939598;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 19px;
              }
              .password-label{
                display: flex;
                align-items: center;
                margin: 0px;
                color: #939598;
                font-size: 14px;
                font-style: italic;
                letter-spacing: 0;
                line-height: 16px;
              }
              .iglohoome{
                margin: 0px 0px 16px 0px;
                display: flex;
                align-items: center;
              }
              .text-boxs{
                border-style: none;
                height: 24px;
                outline: none;
              }
              .ddl-container{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr;
                gap: 1% 0px;
                grid-template-areas: ". .";
              }
              .ddl-available{
                // width: 832px;
              }
              .available{
                display: flex;
                margin: 0px 0px 8px 0px;
              }
              .selection{
                box-sizing: border-box;
                height: 32px;
                width: 372px;
                border: 1px solid #939598;
                border-radius: 4px;
                background-color: #FFFFFF;
              }
              .igloohome-line{
                box-sizing: border-box;
                height: 1px;
                border: 1px solid #939598;
              }
              .smartlock-container{
                display: flex;
                justify-content: space-between;
                margin-top: 8px;
              }
              .icons-container{
                display: flex;
                align-items: center;
              }
              .smart-lock{
                color: #333333;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0;
              }
              .sync-text{
                color: #509D91;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 16px;
              }
              .dual-list-box{
                margin: 16px 0px 0px 0px;
              }
              .ddl-available-assigned{
                margin: 0px 0px 0px 27px;
              }

              .react-dual-listbox {
                height: 313px;
              }

              .react-tag-input {
                border-style: none;
                font-size: 14px;
                margin: 0px 0px 8px 0px;
                position: relative;
                top: 5px;
              }
              .react-tag-input:hover{
                background-color: rgba(182, 186, 191, 0.2);
                border-style: none;
                font-size: 14px;
                margin: 0px 0px 8px 0px;
                position: relative;
                top: 5px;
              }
              .react-tag-input__tag {
                background: #509D91;
                color: #FFF;
              } 
              .react-tag-input__tag__remove{
                background: #509D91;
                color: #FFF;
              }
              .react-tag-input__tag__remove:before {
                background-color: #FFF;
              }
              .react-tag-input__tag__remove:after {
                background-color: #FFF;
              }

              .react-tel-input{
                width: 0%;
              }

              .react-tel-input .form-control{
                border-bottom: none;
                border-top: none;
                border-right: none;
                height: 26px;
              }
              .react-tel-input .form-control:hover{
                border-bottom: none;
                border-top: none;
                border-right: none;
                background-color: rgba(182, 186, 191, 0.2);
                height: 26px;
              }
              .react-tel-input .flag-dropdown{
                border-bottom: none;
                border-top: none;
                border-right: none;
                border-left: none;
              }
              .react-tel-input .selected-flag{
                padding: 0px;
              }
              .react-tel-input .flag-dropdown{
                background-color: #FFF;
              }
              .react-tel-input .country-list .search-emoji {
                width: 10%;
              }
              .react-tel-input .country-list .search {
                display: flex;
              }
              .react-tel-input .country-list .country {
                display: flex;
                align-items: center;
              }
              .react-tel-input .country-list .flag {
                width: 13%;
                margin-top:0px;
              }
              .react-tel-input .country-list .flag {
                font-size:14px;
              }
              .react-tel-input .country-list .country .dial-code{
                font-size:14px;
              }
              .react-tel-input .country-list .country-name{
                font-size:14px;
              }
              .sync-icon{
                margin: 0px 4px 0px 0px;
              }
              .icons-icons{
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .custom-properties{
                height: 137px;
                border-radius: 4px;
                background-color: #FFFFFF;
                padding: 16px;
                margin: 16px 24px 0px 0px;
              }
              .biling-container{
                border-radius: 4px;
                background-color: #FFFFFF;
                padding: 16px;
              }
              .assembly-container{
                box-sizing: border-box;
                border: 1px solid #939598;
                border-radius: 4px;
                padding: 16px;
                margin: 16px 0px 0px 0px;
              }
              .details-assembly{
                display: grid;
                grid-template-columns: 150px 1fr;
                grid-template-rows: 1fr;
                gap: 0px 0px;
                grid-template-areas:
                  ". .";
                margin: 0px 0px 8px 0px;
                height: 30px;
              }
              .input-input{
                // width: 268px;
              }
              .delete-container{
                border-radius: 4px;
                background-color: #FFFFFF;
                padding: 16px;
              }
              .react-tag-input__tag__content{
                font-size: 12px;
              }
              .css-yk16xz-control {
                border-style: none;
              }

              .css-yk16xz-control:hover {
                border-style: none;
                background-color: rgba(182, 186, 191, 0.2);
              }
              .css-1okebmr-indicatorSeparator{
                  align-self: stretch;
                  background-color: hsl(0, 0%, 80%);
                  margin-bottom: 8px;
                  margin-top: 8px;
                  width: 0px;
                  box-sizing: border-box;
              }
              .css-g1d714-ValueContainer {
                padding:0px;
              }
              .css-1pahdxg-control:hover{
                border-color: #FFFFFF;
              }
              .css-1pahdxg-control{
                border-color: #FFFFFF;
                box-shadow: 0 0 0 1px #FFFFFF;
              }
              .css-tj5bde-Svg{
                color: black;
              }
              .information-suspended-container{
                display: flex;
                justify-content: space-between;
              }
              `
        }
      </style>
    </div>
  )
}

export default Form

