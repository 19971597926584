import React, {useState, useEffect} from 'react'

import Form from './Form'

import { AllMandatoryFilled, onUserAdd} from "../../config/config-function/function";

import { CHANNEL_REDUCER, DRIVER_REDUCER } from "../../config/config-reducer/reducer";
import {requiredField} from '../../config/config-table/table'

const FunctionAdd = ({values, onChange, setAddVariables, setGetVariables, setCanAdd, canAdd,  displayed,  setDisplayed, setCanUpdate, canUpdate}) => {


  const onUserInput = (e, value, isValue) => {
      let input = onUserAdd(e, value, isValue, onChange, values)
      // console.log("ADD", input)
      const _check_normal = AllMandatoryFilled(requiredField.driver, input)
      
      _check_normal ? setCanAdd(true) : setCanAdd(false)
      setAddVariables({newDriver: input === null ? DRIVER_REDUCER(values) : DRIVER_REDUCER(input)})
  }

    
    return (
        <Form driver={values} onUserInput={onUserInput} displayed={displayed}/>
    )
}
export default FunctionAdd
