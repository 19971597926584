import React, {useState, useEffect} from 'react'
import Cookies from 'js-cookie';
import { useQuery } from '@apollo/react-hooks';
import { useMediaQuery } from 'react-responsive';
import { FIND_ACTIVE_ASSET, FIND_TOTAL_ACTIVE_ASSET } from  '../../config/config-graphql/Graphql'
import { APIGetActiveAsset } from '../../config/config-restAPI/getAPICalled';
import AssetDetails from './AssetDetails';
import AllAssetListStatus from './AllAssetListStatus'
import AssetGroupListStatus from './AssetGroupListStatus';
import MapSearch from "./MapSearch";

const AssetListing = ({selected_asset_group, view_asset_group_card, view_asset_card, fx_changePage, setFilter, filter, history, setSearchValue, searchValue }) => {
    const isDefault = useMediaQuery({ minWidth: 1025 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    const [listAssets, setListAssets] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [groupName, setGroupName] = useState("")
    const [dataTotalAsset, setDataTotalAsset] = useState({});
    const [viewGroup, setViewGroup] = useState(false)
    const [searchVariables, setSearchVariables] = useState("")
    const [assetSelection, setAssetSelection] = useState(null);
    const token = Cookies.get('jwtToken');
    const agencyId = Cookies.get('agency-id');

    const APICalled = async (groupName) => {
        try {
            const result = await APIGetActiveAsset(token, setLoading, agencyId, groupName)
            if (result.data.status === 200) {
                const { data } = result.data;
                const reducer = Object.keys(data).length > 0 ? {
                    totalAssets: data.totalAssets,
                    totalActiveAssets: data.totalActiveAssets,
                } : {}
                setListAssets(data);
                setDataTotalAsset(reducer)
                setLoading(false);
            } else {
                setLoading(false);
                setError(true);
            }
        } catch(err) {
            setLoading(false);
            setError(true)
        }
    }
    
    // this to checked if there is there is selected_asset_group
    useEffect(() => {
        if(token){
            APICalled(selected_asset_group.name);
        }
    }, [selected_asset_group])


    return (
        <div>
            {
                isDefault && (
                    <div className="asset-listing-container">
                        {
                            view_asset_card ? <AssetDetails setSearchValue={setSearchValue} fx_changePage={fx_changePage} setFilter={setFilter} filter={filter} history={history}/> : 
                            (
                                <div>
                                    <MapSearch viewGroup={viewGroup} setViewGroup={setViewGroup} searchValue={searchValue} setSearchValue={setSearchValue} searchVariables={searchVariables} setSearchVariables={setSearchVariables} setGroupName={setGroupName}/>
                                    {view_asset_group_card ? <AssetGroupListStatus setSearchValue={setSearchValue} viewGroup={viewGroup} setViewGroup={setViewGroup} fx_back={() => setViewGroup(!viewGroup)} setGroupName={setGroupName} searchVariables={searchVariables} setSearchVariables={setSearchVariables} apiData={listAssets} apiDataTotalAsset={dataTotalAsset} apiLoading={loading} apiError={error} /> 
                                    : <AllAssetListStatus setGroupName={setGroupName} apiData={listAssets} apiDataTotalAsset={dataTotalAsset} apiLoading={loading} apiError={error} />
                                    }
                                </div>
                            )
                        }
                    </div>
                )
            }
            {
                isMobile && (
                    <div className="asset-listing-container">
                        {
                            <div>
                                {/* <MapSearch viewGroup={viewGroup} setViewGroup={setViewGroup} searchValue={searchValue} setSearchValue={setSearchValue} searchVariables={searchVariables} setSearchVariables={setSearchVariables} setGroupName={setGroupName} assetSelection={assetSelection} setAssetSelection={setAssetSelection} /> */}
                                {
                                    !view_asset_card && <AllAssetListStatus setGroupName={setGroupName} apiData={listAssets} apiDataTotalAsset={dataTotalAsset} apiLoading={loading} apiError={error}/>
                                
                                }
                                {
                                    view_asset_card && (
                                        <AssetDetails setSearchValue={setSearchValue} fx_changePage={fx_changePage} setFilter={setFilter} filter={filter} history={history} setAssetSelection={setAssetSelection} assetSelection={assetSelection} />
                                    )
                                }
                            </div>
                        }
                    </div>
                )
            }
            {
                isTablet && (
                    <div className="asset-listing-container">
                        {
                            <div>
                                <MapSearch viewGroup={viewGroup} setViewGroup={setViewGroup} searchValue={searchValue} setSearchValue={setSearchValue} searchVariables={searchVariables} setSearchVariables={setSearchVariables} setGroupName={setGroupName} setAssetSelection={setAssetSelection} assetSelection={assetSelection}/>
                                {
                                    !view_asset_card && <AllAssetListStatus setGroupName={setGroupName} apiData={listAssets} apiDataTotalAsset={dataTotalAsset} apiLoading={loading} apiError={error} />
                                
                                }
                                {
                                    view_asset_card && (
                                        <AssetDetails setSearchValue={setSearchValue} fx_changePage={fx_changePage} setFilter={setFilter} filter={filter} history={history} setAssetSelection={setAssetSelection} assetSelection={assetSelection}/>
                                    )
                                }
                            </div>
                        }
                    </div>
                )
            }
                <style>
                    {
                        `
                         .asset-listing-container{
                            //  width:468px;
                             padding:16px;
                         }
                         .asset-listing-child-container{
                            position: relative;
                            bottom: 0;
                            z-index: 900;
                            box-shadow: 30px 0px 0px 0px #00000050;
                         }
                        `
                    }
                </style>
        </div>
    )
}

export default AssetListing
