import React, {useState, useRef} from 'react'


//image
import DarkAssetGroupIcon from '../../Image/dark-asset-group.svg'
import DarkAssetIcon from '../../Image/dark-asset.svg'
import CloseIcon from "../../Image/close.svg";
import ExpandMore from '../../Image/expand_more.svg'


const AssetListAdd = ({ data, dataTwo, APICalled, onkey, setOnKey, onSelected, getData, setOnSelected, setEditGeofence, editGeofence, setAddGeofence, addGeofence, onDisplay, setOnDisplay, assetGroup, setAssetGroup}) => {

    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [setRotate, setRotateState] = useState("accordion__icon_asset_list_add");
    const content = useRef(null)
    const [dataSet] = useState({...getData})

    // console.log(data);

    // console.log('dataTwoooooo', dataTwo);

    const accordion = () =>{
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(
          setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
        setRotateState(
            setActive === "active" ? "accordion__icon_asset_list_add" : "accordion__icon_asset_list_add rotate"
        );
    }

    
    const onDelete = (docs) => {
        let select = [...onSelected];
        let selIndex = select.findIndex(x => x === docs.uuid);
        if (selIndex !== -1){
            select.splice(selIndex, 1);
            setOnSelected(select)
            const convert = select.toString();
            APICalled('WITH_SELECTED', '', convert);
        }       
    }

    const onDeleteEdit = (docs) => {

    }


    return(
        <div> 
            {
                addGeofence ? (
                    <div className="group-container">
                        {
                            dataTwo?.group?.map((doc, i) => {
                                return(
                                <div >
                                    <div className="asset-list-details-group">
                                        <div className="group-icon">
                                            <div className="icon-label">
                                                <img src={DarkAssetGroupIcon} loading="lazy" alt="asset-icon"/>
                                                <div className="asset-name">{doc.name}</div>
                                            </div>
                                            <div className="btn-icon">
                                                <div className={`${setRotate}`} style={{cursor:"pointer", color:"#939598"}} onClick={accordion}>
                                                    <img loading="lazy" src={ExpandMore} alt="Icon"/>
                                                </div>   
                                                <div onClick={() => onDelete(doc)} className="close-icon">
                                                    <img loading="lazy" src={CloseIcon} alt="close-icon" />
                                                </div>                                            
                                            </div>                                       
                                        </div>
                                        <div ref={content} style={{ maxHeight: `${setHeight}` }} className="device-asset-list">
                                            {
                                                doc?.assets?.map((docs, i) => {
                                                    return(
                                                        <div className="detail-list">
                                                            <div className="asset-name">{docs.name}</div>
                                                        </div>
                                                    ) 
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                         }
                         {
                            dataTwo?.asset?.map((doc, i) => {
                                return (
                                    <div>
                                        <div className="asset-list-details">
                                            <div className="icon-label">
                                                <img src={DarkAssetIcon} loading="lazy" alt="asset-icon"/>
                                                <div className="asset-name">{doc.name}</div>
                                            </div>
                                            <div onClick={() => onDelete(doc)} className="close-icon">
                                                <img loading="lazy" src={CloseIcon} alt="close-icon" />
                                            </div>  
                                        </div>
                                    </div>
                                ) 
                            })
                        }
                    </div>
                ) : editGeofence ? (
                    <div className="group-container">
                        {
                            dataTwo?.group?.map((doc, i) => {
                                return(
                                <div >
                                    <div className="asset-list-details-group">
                                        <div className="group-icon">
                                            <div className="icon-label">
                                                <img src={DarkAssetGroupIcon} loading="lazy" alt="asset-icon"/>
                                                <div className="asset-name">{doc.properties.name}</div>
                                            </div>
                                            <div className="btn-icon">
                                                <div className={`${setRotate}`} style={{cursor:"pointer", color:"#939598"}} onClick={accordion}>
                                                    <img loading="lazy" src={ExpandMore} alt="Icon"/>
                                                </div>   
                                                <div onClick={() => onDelete(doc)} className="close-icon">
                                                    <img loading="lazy" src={CloseIcon} alt="close-icon" />
                                                </div>                                            
                                            </div>                                       
                                        </div>
                                        <div ref={content} style={{ maxHeight: `${setHeight}` }} className="device-asset-list">
                                            {
                                                doc?.assets?.map((docs, i) => {
                                                    return(
                                                        <div className="detail-list">
                                                            <div className="asset-name">{docs.name}</div>
                                                        </div>
                                                    ) 
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                )
                            })
                         }
                        {
                            dataSet?.asset?.map((doc, i) => {
                                return (
                                    <div>
                                        <div className="asset-list-details">
                                            <div className="icon-label">
                                                <img src={DarkAssetIcon} loading="lazy" alt="asset-icon"/>
                                                <div className="asset-name">{doc.name}</div>
                                            </div>
                                            <div onClick={() => onDeleteEdit(doc)} className="close-icon">
                                                <img loading="lazy" src={CloseIcon} alt="close-icon" />
                                            </div>  
                                        </div>
                                    </div>
                                ) 
                            })
                        }  
                        {
                            dataTwo?.asset?.map((doc, i) => {
                                return (
                                    <div>
                                        <div className="asset-list-details">
                                            <div className="icon-label">
                                                <img src={DarkAssetIcon} loading="lazy" alt="asset-icon"/>
                                                <div className="asset-name">{doc.name}</div>
                                            </div>
                                            <div onClick={() => onDelete(doc)} className="close-icon">
                                                <img loading="lazy" src={CloseIcon} alt="close-icon" />
                                            </div>  
                                        </div>
                                    </div>
                                ) 
                            })
                        }
                {

                }
                    </div>
                ) : (
                    <div></div>
                )
  
            }            
        
            <style jsx>
                {
                    `
                    .asset-list-details{
                         margin: 10px 0px 0px 0px;
                        height: 30px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        border-radius: 5px;
                        // cursor: pointer;
                        position: relative;
                        // right: 10px;
                    }
                    .asset-list-details-group{
                        margin:10px 0px 0px 0px;
                    }
                    .asset-list-details img{
                    }
                    .icon-label{
                        border-radius: 5px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        height: 30px;
                        // padding: 10px;
                        box-sizing: border-box;
                    }
                    .icon-label .asset-name{
                        font-size: 14px;
                        line-height: 23px;
                        color: #3A3A3A;
                        margin-right: 10px;
                        margin-left: 10px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .icon-label .asset-id{
                        color: #939598;
                        position: relative;
                        top: 1px;
                        right: 2px;
                        font-size: 12px;
                        line-height: 17px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 120px;
                    }
                    .accordion__icon_asset_list_add{
                        width: 24px;
                        height: 24px;
                    }
                    .rotate {
                        transform: rotate(180deg);
                    }
                    .group-container{
                        display: flex;
                        flex-direction: column;

                    }
                    .group-icon{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    .btn-icon{
                        display: flex;
                    }
                    .detail-list{
                        height: 32px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        border-radius: 5px;
                        cursor: pointer;
                        position: relative;
                    }
                    .detail-list .asset-name{
                        font-size: 14px;
                        line-height: 23px;
                        color: #3A3A3A;
                        margin-right: 10px;
                        margin-left: 10px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 120px;
                    }
                    .detail-list .asset-id{
                        color: #939598;
                        position: relative;
                        top: 1px;
                        right: 2px;
                        font-size: 12px;
                        line-height: 17px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 120px;
                    }
                    .device-asset-list{
                        display: flex;
                        flex-direction: column;
                        overflow : hidden;
                        // overflow-y: scroll;
                        
                        transition: max-height 0.6s ease;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default AssetListAdd