import React, {useEffect, useState} from 'react'

import DarkCloseIcon from '../../Image/close.svg'
import TelegramIcon from '../../Image/telegram-icon.svg'
import SlackIcon from '../../Image/slack_icon.svg'
import WhatsappIcon from '../../Image/whatsapp_icon.svg'

const ListChannelEnterCont = ({notification, setNotification, featureDisable, setAddNotification, addNotification, setRemoveNotification, removeNotification, isEdit, dataGeofence}) => {
    // console.log(notification.enter)
    // console.log(data)

    const [dataList, setDataList] = useState([])

    useEffect(() => {
        if(notification && notification.enter && notification.enter.length > 0){
            const listUniq = notification.enter.filter((tag, index, array) => array.findIndex(t => t.uuid == tag.uuid) == index);
            setDataList(listUniq)
        }
    }, [notification.enter])

    const Icon = ({comm}) => {
        switch (comm) {
            case "Telegram":
                return <img loading="lazy" src={TelegramIcon} alt="msg-icon"/>
            case "slack":
                return <img loading="lazy" src={SlackIcon} alt="msg-icon"/>
            case "whatsapp":
                return <img loading="lazy" src={WhatsappIcon} alt="msg-icon"/>
            default:
                break;
        }  
    }
    const removeList = (e, i, x) => {
        e.preventDefault();
        let clone = [...notification.enter]
        clone.splice(i, 1)
        setNotification({...notification, enter: clone})
        if(isEdit){
            if (!removeNotification.enter.includes(x.uuid)) {
                setRemoveNotification({...removeNotification, enter: [...removeNotification.enter, x.uuid]})
            }

            if (addNotification.enter.includes(x.uuid)) {
                let test = addNotification.enter.filter((i) => i !== x.uuid);
                setAddNotification({...addNotification, enter: test.length > 0 ? test.uuid : []});
            }      
        }
    }

    // console.log(first)
    return (
        <div className="enter-container">
            {
                notification.enter.map((x, i) => {
                    return (
                        <section className="enter-list">
                            <div className="enter-list-container">
                                <Icon comm={x.type} />
                                <p>{x.name}</p>
                            </div>
                            {
                                featureDisable ? null : <img lazy="loading" src={DarkCloseIcon} alt="close-icon" onClick={(e) => removeList(e, i, x)}/>
                            }
                            
                        </section>
                    )
                })
            }
            <style>
                {
                    `
                    .enter-list{
                        display: flex;
                        justify-content: space-between;
                    }
                    .enter-list-container{
                        display: flex;
                        align-items: center;
                    }
                    .enter-container{
                        margin: 8px 0px 8px 0px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default ListChannelEnterCont
