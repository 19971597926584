import React, { useEffect, useState } from 'react'

import Form from './Form'
import { onUserEdit, AllMandatoryFilled } from "../../config/config-function/function";
import { DEVICE_REDUCER, UPDATE_SIGFOX_REDUCER, UPDATE_CELLULAR_REDUCER } from "../../config/config-reducer/reducer";
import {requiredField} from '../../config/config-table/table'


const FunctionEdit = ({ values, setPageInd, onChange, setEditVariables, displayed, initialValue, setFilterMain, fx_changePage, setFilterFinal, query, fx_back, setCanUpdate, featureList, sigfoxVariable, setSigfoxVariable, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere, history, setCellularVariable, setGenModal, genModal, editData, editVariables}) => {
    
    const staticAgency = values[0].agency;
    const staticBoardID = values[0].board[0].config ? values[0].board.map(x => x.config) : []
    let sigfox_config = values[0].board[0].config ? values[0].board.map(x => x.config) : []
    let cellular_config = values[0].board[0].config ? values[0].board.map(x => x.config): []
    let boardDeviceId = values[0].board[0].board_id
    
    const [valSku, setValSku] = useState({...values[0].SKU})
    const [valAgency, setValAgency] = useState({...values[0].agency})  
    const [valfuel, setValfuel] = useState({...values[0].fuel_sensor})
    const [valBoard, setValBoard] = useState([...values[0].board])

    const [originalValBoard, setOriginalValBoard] = useState([...values[0].board])
    
    const [feature, setFeature] = useState(values[0].features ? [...values[0].features] : [])
    const [featureAdd, setFeatureAdd] = useState([])
    const [featureRemove, setFeatureRemove] = useState([])


    const [deviceIDs, setDeviceIDs] = useState(sigfox_config.length > 0 ? [sigfox_config[0].deviceId] : [])
    const [cellDeviceId, setCellDeviceId] = useState(values[0].board[0].board_id)
    const [originalAgency, setOriginalAgency] = useState({...values[0].agency})
    const [agencyAdd, setAgencyAdd] = useState({})
    const [agencyRemove, setAgencyRemove] = useState({})
    const [firmwareAdd, setFirmwareAdd] = useState({})
    const [firmwareRemove, setFirmwareRemove] = useState({})
    const [sigfoxConf, setSigfoxConf] = useState({
        deviceId: sigfox_config.length > 0 ? [sigfox_config[0].deviceId] : [],
        downlinkFrequency: sigfox_config.length > 0 ? sigfox_config[0].downlinkFrequency ? sigfox_config[0].downlinkFrequency : 4 : 4,
        gpsSeekPeriod: sigfox_config.length > 0 ? sigfox_config[0].gpsSeekPeriod ? sigfox_config[0].gpsSeekPeriod : 60 : 60,
        payloadInterval: sigfox_config.length > 0 ? sigfox_config[0].payloadInterval ? sigfox_config[0].payloadInterval : 3600 : 3600,
        payloadIntervalMotion: sigfox_config.length > 0 ? sigfox_config[0].payloadIntervalMotion ? sigfox_config[0].payloadIntervalMotion : 600 : 600,
        revertDefault: sigfox_config.length > 0 ? typeof sigfox_config[0].revertDefault == "boolean" ? sigfox_config[0].revertDefault : false : false,
        variableInterval: sigfox_config.length > 0  ? typeof sigfox_config[0].variableInterval === "boolean" ? sigfox_config[0].variableInterval : true : true
    })

    const [cellularConf, setCellularConf] = useState({
        deviceId: boardDeviceId,
        payloadInterval: cellular_config.length > 0 ? cellular_config[0].payloadInterval ? cellular_config[0].payloadInterval : "10" : "10",
        payloadIntervalIgnitionOff: cellular_config.length > 0 ? cellular_config[0].payloadIntervalIgnitionOff ? cellular_config[0].payloadIntervalIgnitionOff : "15" : "15",
        payloadIntervalOffline: cellular_config.length > 0 ? cellular_config[0].payloadIntervalOffline ? cellular_config[0].payloadIntervalOffline : "10" : "10",
        telitKeepAlive: cellular_config.length > 0 ? cellular_config[0].telitKeepAlive ? cellular_config[0].telitKeepAlive : "900" : "900",
        ignitionMode: cellular_config.length > 0 ? typeof cellular_config[0].ignitionMode == "boolean" ? cellular_config[0].ignitionMode.toString() : "true" : "true",
        extSense_Fuel: cellular_config.length > 0 ? typeof cellular_config[0].extSense_Fuel == "boolean" ? cellular_config[0].extSense_Fuel : false : false,
        FuelSensingRatio: cellular_config.length > 0 ? cellular_config[0].FuelSensingRatio ? cellular_config[0].FuelSensingRatio : 1 : 1,
        extSense_Vibration: cellular_config.length > 0 ? typeof cellular_config[0].extSense_Vibration == "boolean" ? cellular_config[0].extSense_Vibration : false : false,
        rat: cellular_config.length > 0 ? cellular_config[0].rat ? cellular_config[0].rat : "10" : "10"
    })

    const assemblyClass = values[0].device_type === "Telematics" ? valBoard.map(x => x.assembly ? x.assembly.board_class : null ) : []
    const removeDuplicate = assemblyClass.filter((value, index) => assemblyClass.indexOf(value) === index)
    const sigfoxFilter = removeDuplicate.filter(x => x === "Sigfox")
    const cellularFilter = removeDuplicate.filter(x => x === "Cellular")
    
    useEffect(() => {
        onUserInput(null, { SKU: {uuid: valSku.uuid, product_model: valSku.product_model} }, true)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valSku])

    useEffect(() => {
        onUserInput(null, { fuel_sensor: {length: valfuel.length, width: valfuel.width, height: valfuel.height} }, true)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valfuel])

    useEffect(() => {
        onUserInput(null, { add_features :  feature.map(x => {return {uuid: x}})}, true)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feature])


    useEffect(() => {
        onUserInput(null, { remove_features: featureRemove}, true)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featureRemove])
    

    //left board_class for sigfox configuration
    useEffect(() => {
        values[0].device_type === "Telematics" && onUserInput(null, { assembly: valBoard &&  valBoard.map((x,i) => {return {uuid: x.uuid, assembly: x.assembly ? {uuid: x.assembly.uuid} : {uuid: ''}, 
        id: x.assembly && x.assembly.board_class === "Sigfox" ? x.board_id ? x.board_id.toString().toUpperCase().padStart(8,"0") : "" : x.board_id, 
        board_class : x.assembly && x.assembly.board_class ? x.assembly.board_class : "", 
        firmware_add: x.firmware_add ? {uuid: x.firmware_add.uuid} : x.firmware ? {uuid: x.firmware.uuid} : {}, 
        firmware_remove: x.firmware_remove ? {uuid: x.firmware_remove.uuid} : {}}})}, true)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valBoard])
    


    useEffect(() => {
        onUserInput(null, { add_agency: agencyAdd}, true)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agencyAdd])

    useEffect(() => {
        onUserInput(null, { remove_agency: agencyRemove}, true)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agencyRemove])

    useEffect(() => {
        onUserInput(null, { input : sigfoxConf}, true)
                        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sigfoxConf])

    useEffect(() => {
        onUserInput(null, {  cellularInput: cellularConf}, true)
                        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cellularConf])
    

    const onUserInput = (e, value, isValue) => {
        const input = onUserEdit(e, value, isValue, onChange, values)

        if(input.device_type === "Telematics"){
            const _check_normal = AllMandatoryFilled(requiredField.device, input[0]) // to check the mandatory field
            const _check_sku = input[0] && input[0].SKU ? AllMandatoryFilled(requiredField.device_sku, input[0].SKU) : false // this to check for SKU mandatory field
            const _check_board = input[0] && input[0].board && input[0].board.some(b => b.board_id) // this for board mandatory field

            _check_normal && _check_sku && _check_board ? setCanUpdate(true) : setCanUpdate(false)
        }else{
            const _check_sku = input[0] && input[0].SKU ? AllMandatoryFilled(requiredField.device_sku, input[0].SKU) : false
            _check_sku ? setCanUpdate(true) : setCanUpdate(false)
            // setCanUpdate(true)
        }

        setEditVariables({ updated_device: [DEVICE_REDUCER(input[0])]})
        setSigfoxVariable(sigfoxFilter.length > 0 ? {input :UPDATE_SIGFOX_REDUCER(input[0].input, deviceIDs)} : {})
        setCellularVariable(cellularFilter.length > 0 ? {input: UPDATE_CELLULAR_REDUCER(input[0].cellularInput, values[0].board[0].board_id)} : {})

    };


    return <Form 
            device={values} 
            setPageInd={setPageInd} 
            onUserInput={onUserInput} 
            setFilterMain={setFilterMain} 
            fx_changePage={fx_changePage} 
            setFilterFinal={setFilterFinal} 
            initialValue={initialValue} 
            displayed={displayed} 
            setValSku={setValSku} 
            valSku={valSku} 
            valAgency={valAgency} 
            setValAgency={setValAgency} 
            valfuel={valfuel} 
            setValfuel={setValfuel} 
            valBoard={valBoard} 
            setValBoard={setValBoard} 
            featureAdd={featureAdd} 
            featureRemove={featureRemove} 
            setFeatureAdd={setFeatureAdd} 
            setFeatureRemove={setFeatureRemove} 
            query={query} 
            fx_back={fx_back} 
            originalValBoard={originalValBoard} 
            setOriginalValBoard={setOriginalValBoard} 
            setFirmwareAdd={setFirmwareAdd} 
            setFirmwareRemove={setFirmwareRemove} 
            feature={feature} 
            setFeature={setFeature} 
            setOriginalAgency={setOriginalAgency} 
            firmwareAdd={firmwareAdd} 
            originalAgency={originalAgency} 
            firmwareRemove={firmwareRemove} 
            setAgencyAdd={setAgencyAdd} 
            setAgencyRemove={setAgencyRemove} 
            staticAgency={staticAgency} 
            featureList={featureList} 
            setSigfoxConf={setSigfoxConf} 
            sigfoxConf={sigfoxConf} 
            staticBoardID={staticBoardID} 
            setDeviceIDs={setDeviceIDs} 
            setSnOpen={setSnOpen} 
            setMessage={setMessage} 
            setSeverity={setSeverity} 
            setErrMsg={setErrMsg} 
            setErrOpen={setErrOpen} 
            setErrSevere={setErrSevere} 
            history={history} 
            setCellularConf={setCellularConf} 
            cellularConf={cellularConf}
            setGenModal={setGenModal}
            genModal={genModal}
            editData={editData}
            editVariables={editVariables}
         />
}

export default FunctionEdit
