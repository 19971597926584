import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';;

// const GreenCheckbox = withStyles({
// root: {
//     color: green[400],
//     '&$checked': {
//     color: green[600],
//     padding: '0px'
//     },
// },
// checked: {},
// })((props) => <Checkbox color="default" {...props} />);

const Notification = ({channels, notification, setNotification}) => {

    const handleChange = (event) => {
        setNotification({ ...notification, [event.target.name]: event.target.checked });
    };

    return (
        <div>
            <p className="channel-title">Notification Types</p>
            {/* <p className="users-details"></p> */}
            <p className="basfair-text">Basfare</p>
            <div className="checkbox-container">
                <Checkbox checked={notification.generateEOT} onChange={handleChange} name="generateEOT"/>
                <div className="text-list-container">Upon generation of Basfare End of Trip (EOT) Report</div>          
            </div>
            <div className="checkbox-container">
                <Checkbox checked={notification.generateEOD} onChange={handleChange} name="generateEOD"/>
                <div className="text-list-container">Upon generation of Basfare End of Day (EOD) Report</div>
            </div>
            <style>
                {
                    `.checkbox-container{
                        display: grid; 
                        grid-auto-columns: 1fr; 
                        grid-template-columns: 30px 1fr; 
                        grid-template-rows: 1fr; 
                        gap: 0px 0px; 
                        grid-template-areas: 
                          ". .";
                        align-items:center;
                        margin:  0px 0px 12px 0px;
                    }
                    .text-list-container{

                    }
                    .rc-checkbox .rc-checkbox-checked{
                        height: 18px;
                        width: 18px;
                    }
                    .rc-checkbox-checked .rc-checkbox-inner {
                        background-color: #509D91;
                        border-color: #509D91;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default Notification
