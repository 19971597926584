/**
 * CSVToArray parses any String of Data including '\r' '\n' characters,
 * and returns an array with the rows of data.
 * @param {String} CSV_string - the CSV string you need to parse
 * @param {String} delimiter - the delimeter used to separate fields of data
 * @returns {Array} rows - rows of CSV where first row are column headers
 * 
 */

const csvToArray = (CSV_string, delimiter) => {
  delimiter = (delimiter || ","); // user-supplied delimeter or default comma

  var pattern = new RegExp( // regular expression to parse the CSV values.
    ( // Delimiters:
      "(\\" + delimiter + "|\\r?\\n|\\r|^)" +
      // Quoted fields.
      "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
      // Standard fields.
      "([^\"\\" + delimiter + "\\r\\n]*))"
    ), "gi"
  );

  var rows = [[]];  // array to hold our data. First row is column headers.
  // array to hold our individual pattern matching groups:
  var matches = false; // false if we don't find any matches
  // Loop until we no longer find a regular expression match
  while (matches = pattern.exec( CSV_string )) {
    var matched_delimiter = matches[1]; // Get the matched delimiter
    // Check if the delimiter has a length (and is not the start of string)
    // and if it matches field delimiter. If not, it is a row delimiter.
    if (matched_delimiter.length && matched_delimiter !== delimiter) {
      // Since this is a new row of data, add an empty row to the array.
      rows.push( [] );
    }
    var matched_value;
    // Once we have eliminated the delimiter, check to see
    // what kind of value was captured (quoted or unquoted):
    if (matches[2]) { // found quoted value. unescape any double quotes.
     matched_value = matches[2].replace(
       new RegExp( "\"\"", "g" ), "\""
     );
    } else { // found a non-quoted value
      matched_value = matches[3];
    }
    // Now that we have our value string, let's add
    // it to the data array.
    rows[rows.length - 1].push(matched_value);
}
  return rows; // Return the parsed data Array
}
export const parseCSV = (str, delimiter) => {
// console.log("str and delimiter", str, delimiter)
//Use csvToArray function to convert all value from csv file to array
const arr = csvToArray(str, delimiter); 
// console.log("csv arr",  arr)
// //arr should now have all cell value in array
// //The first index should be the headers row
// //Seperate the header row from the data row,
const header = arr[0].map(x => x.trim());
const data = arr.filter((el, index) => index > 0)
// //Now, header should be list of header column
// //data should be value for each row
return data.map(el => {
  //temp is a temporary array that keeps the row value;
  let temp = [...el];
  // console.log("data", temp)
  //Set an empty object first
  el = {}
  //For each header column, set the value from temp array
  //header[i] should have value from temp[i]s
  if(temp[0] !== '') {
    for (let i = 0; i < header.length; i++) {
      //For header column that have `.` character in it, 
      //it is expected for it to return a nested object
      //Get list of nested object we'll have by splitting the header column by `.`
      let keys = header[i].split(".");
      // console.log("keys", keys)
      // //If value have `;` character, convert to array
      let value = typeof temp[i] !== 'undefined' && temp[i].includes(";") ? temp[i].split(";") : temp[i]
      //If don't need nested object, should have only 1 content in the `keys` array
      if(keys.length === 1) el[keys[0]] = value;
      //For nested object,
      else{
        //Get the main key first
        let mainKey = keys[0]
        //arrange the nested object
        let obj = keys.reverse().reduce((res, key) => ({[key]: res}), value);
        //If mainKey already exist in object, push the value to mainKey
        if(Object.keys(el).includes(mainKey)) el[mainKey] = { ...el[mainKey], ...obj[mainKey] };
        else el = { ...el, ...obj }
      }
    }
    return el;
  }
})
}