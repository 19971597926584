import React, {useState} from 'react'

//component
import {requiredLabel } from "../../config/config-function/function";

const Assembly = ({ displayed, fieldList, assemblies, setAssemblly, index, setindexAsemblues, dataAutocomplete, optionList}) =>  {

    const [isHovered, setIsHovered] = useState({
        board_class: false,
        assembly_id: false,
        schematic_id: false,
    })

    const settingValue = (key, value) => {
            assemblies[key] = value
            setAssemblly(index, assemblies)
            setindexAsemblues(index) //this for autocomplete

    }


    return (
        <div>
            <div className="assembly-container">
                <div className="details-assembly" >
                    <div className="input-inputs">Board Class {requiredLabel(fieldList.board_class.required)}</div> 
                    <select className={`${isHovered.board_class ? "isHovered-selection" : "selection-board"}`} name="boardClass"  value={assemblies.boardClass}  onChange={(e, value) => settingValue("boardClass", e.target.value)} disabled={displayed ? true : false} onMouseEnter={() => setIsHovered((current) => ({...current, board_class: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, board_class: false}))}>
                        <option>-</option>
                        <option value="Cellular">Cellular</option>
                        <option value="Sigfox">Sigfox</option>
                    </select>
                </div>
                <div className="details-assembly">
                    <div className="input-inputs-SKU">Assembly ID<p style={{color:"red", margin:"0px 16px 0px 0px"}}>*</p></div> 
                    <input type="text" list="data-assembly" className={`${isHovered.assembly_id ? "isHovered-border-assembly" : "text-box-assembly"}`} placeholder="-"  name="assembly_id" value={assemblies.assemblyId} onChange={(e, value) => settingValue("assemblyId", e.target.value)} disabled={displayed ? true : false} onMouseEnter={() => setIsHovered((current) => ({...current, assembly_id: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, assembly_id: false}))}/>
                    <datalist id="data-assembly">
                        {
                            optionList && optionList.map((item, key) => 
                                <option key={key} value={item.value}/>
                            )
                        }
                    </datalist>
                </div>
                <div className="details-assembly">
                    <div className="input-inputs-SKU">Schematic ID {requiredLabel(fieldList.schematic_id.required)}</div> 
                    <input type="text" list="data-schematic" className={`${isHovered.schematic_id ? "isHovered-border-assembly" : "text-box-assembly"}`} placeholder="-" value={assemblies.schematicId} name="schematic_id" onChange={(e, value) => settingValue("schematicId", e.target.value)} disabled={displayed ? true : false} onMouseEnter={() => setIsHovered((current) => ({...current, schematic_id: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, schematic_id: false}))}/>
                    <datalist id="data-schematic">
                        {
                            optionList && optionList.map((item, key) => 
                                <option key={key} value={item.value}/>
                            )
                        }
                    </datalist>
                </div>
            </div>
            <style jsx>
                {
                    ` 
                    .assembly-container{
                        box-sizing: border-box;
                        height: 121px;
                        border: 1px solid #939598;
                        border-radius: 4px;
                        padding: 16px;
                    }
                    .details-assembly{
                        display: flex;
                        align-items: center;
                        padding: 0px 0px 8px 0px;
                    }
                    .input-inputs-SKU{
                        display:flex;
                        align-items: center;
                    }
                    .selection-board{
                        border-style:none;
                        background-color: #FFF;
                        outline: none;
                        width: 100px;
                        height: 24px;
                    }
                    .isHovered-selection{
                        background-color: rgba(182, 186, 191, 0.2);
                        border-radius: 4px;
                        border-style:none;
                        width: 100px;
                        height: 24px;
                        margin: 0x 4px 0px
                    }
                    .isHovered-border-assembly{
                        height: 25px;
                        outline: none;
                        border: 1px solid #b6babf;
                        border-radius: 4px;
                    }
                    .text-box-assembly{
                        border-style: none;
                        height: 25px;
                        outline: none;
                        border-radius: 4px;
                        width: 100%;
                        margin: 0px 4px 0px 0px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default Assembly
