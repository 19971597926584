import React, { useEffect, useState, useContext } from "react";

//library
import { useQuery } from "@apollo/react-hooks";

//component
import { AVAILABLE_ASSETS} from "../../config/config-graphql/Graphql";
import {requiredLabel} from "../../config/config-function/function";
import {firebaseContext} from '../../config/config-firebase/FirebaseContext'
// import DeleteCard from "../../component/component-deletecard/DeleteCard"
import tableConfig from "../../config/config-table/table";
import AssignMany from "../../component/component-assignmany/AssignMany"

const { asset_group: fieldList } = tableConfig.fieldList;

const Form = ({ asset_group, onUserInput, displayed, staticAssetList=[], addAssetList, setAddAsset, removeAssetList, setRemoveAsset, featureDisable }) => {
    const [options, setOptions] = useState([]);
    const [staticAvailableList, setStaticAvailableList] = useState([])
    const { data: _availableAssets, loading: assetsLoading, error } = useQuery(AVAILABLE_ASSETS, { variables: {filter: {}}});
    const firebaseConfig = useContext(firebaseContext)

    const [isHovered, setIsHovered] = useState({
        name: false,
        description: false,
    })

    useEffect(() => {
        if(assetsLoading){
            // console.log("Loading")
        }
        else if(error){
            console.log("An error occured")
        }
        else if(_availableAssets && _availableAssets.get_available_assets){
            setStaticAvailableList(_availableAssets.get_available_assets.map(el => el.uuid))
            let _options = _availableAssets.get_available_assets.map(el => { 
                return el ? { value: el.uuid, label: el.name, option: el.type ? el.type : null } : 
                { value: "", label: "", option:null } 
            });
            _options = [..._options, ...staticAssetList ? staticAssetList.map(el => { 
                return el ? { value: el.uuid, label: el.name, option: el.type ? el.type : null } : 
                { value: "", label: "", option: null }
            }) : [] ]
            _options ? setOptions(_options) : setOptions([])

            // console.log(_options)

        } else if(staticAssetList){
            let _options = [ ...staticAssetList ? staticAssetList.map(el => { return el ? { value: el.uuid, label: el.name, option: el.type ? el.type : null } : { value: "", label: "", option: null }}) : [] ]
            setOptions(_options)
        }
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetsLoading])


    return (
        <div>
            <div className="first-box-container-asset-group">
                <div className="asset-title">Asset Group Information</div>
                <div className="asset-input">
                    <div className="asset-container">
                        <div className="device-information">
                            <div className="details-input" >
                                <div className="input-input">Name <p style={{color:"red", margin:"0"}}>{requiredLabel(fieldList.name.required)}</p></div> 

                                {featureDisable ? <label className="label-box">{Array.isArray(asset_group) && asset_group.length === 1 ? asset_group[0].name : asset_group.name}</label> : <input type="text" className={`${isHovered.name ? "isHovered-border" : "text-box"}`}  placeholder="-" name="name" onChange={(e) => onUserInput(e)} value={Array.isArray(asset_group) && asset_group.length === 1 ? asset_group[0].name : asset_group.name} onMouseEnter={() => setIsHovered((current) => ({...current, name: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, name: false}))}/>}
                            </div>                       
                            <div className="details-input">
                                <div className="input-input">Description </div> 
                                {featureDisable ? <label className="label-box">{Array.isArray(asset_group) && asset_group.length === 1 ? asset_group[0].description : asset_group.description}</label> : <input type="text" className={`${isHovered.description ? "isHovered-border" : "text-box"}`}  placeholder="-" name="description" onChange={(e) => onUserInput(e)} value={Array.isArray(asset_group) && asset_group.length === 1 ? asset_group[0].description : asset_group.description} onMouseEnter={() => setIsHovered((current) => ({...current, description: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, description: false}))}/>}
                            </div>
                         </div>
                    </div>
                    <div className="device-details">{firebaseConfig.getValueByKey("client_asset_group_information")}</div>
                </div>

            </div>
            <AssignMany 
                title="Asset Grouping"
                description={firebaseConfig.getValueByKey("client_asset_grouping")}
                label="Asset"
                optionList={options}
                staticAssignedList={staticAssetList.map(el => el.uuid)}
                staticAvailableList={staticAvailableList}
                addList={addAssetList}
                setAddList={setAddAsset}
                removeList={removeAssetList}
                setRemoveList={setRemoveAsset}
                assignedLabel="Grouped"
                filterLabel="Asset Type"
                featureDisable={featureDisable}
            />
            {/* <div className="third-box">
                {
                    displayed  && !featureDisable ? 
                    <div className="delete-container">
                        <DeleteCard fx_back={fx_back} 
                            title="Delete Asset Group" 
                            details={firebaseConfig.getValueByKey("client_delete_asset_group")} 
                            module="asset-group" 
                            fx_delete={fx_delete} 
                            query={{deleteQuery: DELETE_ASSET_GROUP, getQuery: GET_ASSETGROUPS}} 
                            canDelete={staticAssetList && staticAssetList.length > 0 ? false : true }
                            setSnOpen={setSnOpen} 
                            setMessage={setMessage}
                            setSeverity={setSeverity}
                            setErrMsg={setErrMsg} 
                            setErrOpen={setErrOpen} 
                            setErrSevere={setErrSevere}
                        />
                    </div> : <div></div>
                }
            </div> */}
        <style>
            {
                `
                .asset-title{
                    height: 32px;
                    width: 100%;
                    color: #333333;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 16px;
                }
                .first-box-container-asset-group{               
                    height: 125px;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    padding: 16px;
                    margin: 0px 0px 24px 0px;
                }
                .asset-input{
                    display: flex;
                }
                .asset-container{
                    width: 100%;
                    margin: 0px 16px 0px 0px;
                }
                .input-input{
                    display: flex;
                    align-items: center;
                }
                .text-box{
                    border-style: none;
                    height: 24px;
                    outline: none;
                    width: 100%;
                    border-radius: 4px;
                }
                .isHovered-border{
                    height: 24px;
                    outline: none;
                    border: 1px solid #b6babf;
                    border-radius: 4px;
                }
                .required-field{
                    margin: 0px;
                }
                .device-details{
                    height: 32px;
                    width: 100%;
                    color: #939598;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 16px;
                    margin: 0px 0px 0px 32px;
                }
                .details-input{
                    display: grid;
                    grid-template-columns: 150px 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    margin: 0px 0px 8px 0px;
                    height: 30px;
                }
                .asset-device-management{
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    margin: 24px 0px 0px 0px;
                    padding: 16px;
                }
                .title{
                    margin: 0px 0px 4px 0px;
                    height: 21px;
                    color: #333333;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 21px;
                }
                .title-detail{
                    color: #939598;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 16px;
                    margin: 0px 0px 8px 0px;
                }
                .ddl-container{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 1% 0px;
                    grid-template-areas: ". .";
                }
                .ddl-available-assigned{
                    margin: 0px 0px 0px 27px;
                }
                .available{
                    display: flex;
                    align-items: center;
                    margin: 16px 0px 8px 0px;
                }
                .label-box{
                    display: flex;
                    align-items:center;
                }
                .selection{
                    box-sizing: border-box;
                    height: 32px;
                    width: 372px;
                    border: 1px solid #939598;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                }
                .dual-list-box{
                    margin: 16px 0px 0px 0px;
                }
                .asset-icon{
                    margin: 0px 8px 0px 0px;
                }
                .custom-properties{
                    padding: 16px 16px 16px 16px;
                    height: 137px;
                    // width: 804px;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    margin: 0px 24px 0px 0px;
                }
                .third-box{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    margin: 16px 0px 0px
                }
                .delete-container{
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    padding: 16px;
                }
                .total-add{
                    color: #286551;
                    font-size: 14ppx;
                    line-height: 19px;
                    margin: 0 8px;
                }
                .total-remove{
                    color: #EA2B1F;
                    font-size: 14ppx;
                    line-height: 19px;
                    margin: 0 8px;
                }
                `
            }
        </style>
        </div>
    );
};

export default Form
