import axios from 'axios';

const url = process.env.REACT_APP_RESTAPI;
const xApiKey = process.env.REACT_APP_XAPIKEY;

export const APIDeleteBeacon = async (token, agencyId, beaconData, setLoading) => {
    setLoading(true);
    const result = await axios.delete(`${url}/beacons`, {
      headers: {
        'x-api-key': xApiKey,
        'Authorization':  token,
        'agency-id': agencyId,
      }, 
      data: beaconData
    }).catch((error) => error);
    return result;
};

export const APIDeleteAgencies = async (token, agencyId, agencyData, setLoading) => {
  setLoading(true);
  const result = await axios.delete(`${url}/agencies`, {
    headers: {
      'x-api-key': xApiKey,
      'Authorization':  token,
      'agency-id': agencyId,
    }, data: agencyData,
  }).catch((error) => error);
  return result;
};


export const APIDeletSKU = async (token, agencyId, skuData, setLoading) => {
  setLoading(true);
  const result = await axios.delete(`${url}/skus`, {
    headers: {
      'x-api-key': xApiKey,
      'Authorization':  token,
      'agency-id': agencyId,
    }, data: skuData
  }).catch((error) => error);
  return result;
};

export const APIDeleteGeofence = async (token, agencyId, geofenceData, setLoading) => {
  setLoading(true);
  const result = await axios.delete(`${url}/geofences`, {
    headers: {
      'x-api-key': xApiKey,
      'Authorization':  token,
      'agency-id': agencyId,
    }, data: geofenceData
  }).catch((error) => error);
  return result;
}

export const APIDeleteChannel = async (token, agencyId, channelData, setLoading) => {
  setLoading(true);
  const result = await axios.delete(`${url}/channels`, {
    headers: {
      'x-api-key': xApiKey,
      'Authorization':  token,
      'agency-id': agencyId,
    }, data: channelData
  }).catch((error) => error);
  return result;
}