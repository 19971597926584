import React, {useState, useEffect} from 'react'
import { Base64 } from 'js-base64';
import moment from 'moment-timezone'


//component
import Form from './Form'
import {  AllMandatoryFilled, onUserAdd  } from "../../config/config-function/function";
import { AGENCY_REDUCER } from "../../config/config-reducer/reducer";
import {requiredField} from '../../config/config-table/table'

const tz = moment.tz.guess()
moment.tz.setDefault(tz)

const FunctionAdd = ({ values, onChange, setAddVariables, query,  displayed, setGetVariables, setCanAdd, setCanUpdate, canUpdate }) => {
  const [typeID, setTypeID] = useState([])
  const [userAdmistration, setUserAdmistration] = useState({})
  const [billing, setBilling] = useState({})
  const [devices, setDevices] = useState([])
  const [beacons, setBeacons] = useState([])
  const [originalDevice, setOriginalDevice] = useState([])
  const [deviceAdd, setDeviceAdd] = useState([])
  const [beaconAdd, setbeaconAdd] = useState([])
  const [address, setAddress] = useState({})
  const [countryCode, setCountryCode] = useState("")
  const [contact_number, setContact_number] = useState("")
  const [selectedTimezone, setSelectedTimezone] = useState({value: Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Kuala_Lumpur"})

  const onUserInput = (e, value, isValue) => {

    let input = onUserAdd(e, value, isValue, onChange, values)
    let regex = new RegExp(/^(?!admin|system)(?!\/\.\ \"\$\*\<\>\:\|\?)(?=[a-zA-Z0-9]{1})((\w)|([-])(\w)){3,63}$/gm)
    let emRegex = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)

    const _check_normal = AllMandatoryFilled(requiredField.agency, input)
    const _check_user = input && input.user ?  AllMandatoryFilled(requiredField.agency_user, input.user) : false
    const _check_id =  input && input.id ? regex.test(input.id) : false
    const _check_email = input && input.user ? emRegex.test(Base64.decode(input.user.email)) : false

    _check_normal && _check_user && _check_id && _check_email ? setCanUpdate(true) : setCanUpdate(false)

    setAddVariables({ agency: input === null ?  AGENCY_REDUCER(values, countryCode, selectedTimezone, null, false) : AGENCY_REDUCER(input, countryCode, selectedTimezone, null, false) });
  }

  useEffect(() => {
    onUserInput(null, { timezone: selectedTimezone.value}, true)
            //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimezone])

  useEffect(() => {
    onUserInput(null, { sigfoxDeviceTypeId: typeID && typeID.map(x => {return x})}, true)
            //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeID])

  useEffect(() => {
    onUserInput(null, { user: {email: Base64.encode(userAdmistration.email) ,firstName: userAdmistration.firstName, lastName: userAdmistration.lastName} }, true)
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAdmistration])

  useEffect(() => {
    onUserInput(null, { address: {road: address.road, city: address.city, postcode: address.postcode, state: address.state, country: address.country} }, true)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  useEffect(() => {
    onUserInput(null, { billing: {uuid: "", subscriptionFrom: moment(billing.subscriptionFrom).tz(tz).format(), subscriptionTo: moment(billing.subscriptionTo).tz(tz).format()}}, true)
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billing])



  return (
    <Form agencies={values} setBeacons={setBeacons} beacons={beacons} setbeaconAdd={setbeaconAdd} beaconAdd={beaconAdd} setOriginalDevice={setOriginalDevice} onUserInput={onUserInput} displayed={displayed} query={query} setTypeID={setTypeID} typeID={typeID} userAdmistration={userAdmistration} setUserAdmistration={setUserAdmistration} setDevices={setDevices} devices={devices} originalDevice={originalDevice} addList={deviceAdd} setAddList={setDeviceAdd} setAddress={setAddress} address={address} setBilling={setBilling} billing={billing} setCountryCode={setCountryCode} setContact_number={setContact_number} setSelectedTimezone={setSelectedTimezone} selectedTimezone={selectedTimezone}/>
  )
}

export default FunctionAdd
