import React,{useState, useEffect} from 'react'
import Form from './Form'

import { AllMandatoryFilled, onUserEdit} from "../../config/config-function/function";
import { USER_REDUCER } from '../../config/config-reducer/reducer';
import {requiredField} from '../../config/config-table/table'

import { Base64 } from 'js-base64';
import Cookies from "js-cookie";

const FunctionEdit = ({values, onChange, displayed, setEditVariables, setGetVariables, setCanAdd, query, fx_back, setCanUpdate, canUpdate, featureList, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere, featureDisable}) => {
    // let contactCodeInt = values[0].telegram.contactCode
    // let contactCodeString = String(values[0].telegram.contactCode)
    // let combination = `${contactCodeString}${values[0].telegram.contactNo}`
    const agencyUUID = Cookies.get("agencyUUID")
    const [privileges, setPrivileges] = useState({...values[0].privileges[0]})
    const [telegram, setTelegram] = useState({...values[0].telegram})
    const [password, setPassword] = useState("dummy")
    const [contactCode, setContactCode] = useState(values[0].telegram.contactCode ? values[0].telegram.contactCode: 0)
    const [contact_number, setContact_number] = useState("")

    // const contactCodeInt = values[0].telegram.contactCode.toString()

    // console.log("contactCide", contactCodeInt)

    const onUserInput = (e, value, isValue) => {
        let input = onUserEdit(e, value, isValue, onChange, values);
       //to check for disabled button
        if(input[0].privileges && input[0].privileges.role === "Notifications"){ // if role notification, remove password
            const _check_normal = AllMandatoryFilled(requiredField.userNotification, input[0])
            _check_normal ? setCanAdd(true) : setCanAdd(false)
        }else{
            const _check_normal = AllMandatoryFilled(requiredField.user, input[0]) // else display notification field
            _check_normal ? setCanAdd(true) : setCanAdd(false)
        }

        setEditVariables({input: input === null ? USER_REDUCER(values[0]) : USER_REDUCER(input[0])})

    }

    useEffect(() => {
        setContact_number(`${values[0].telegram.contactCode ? values[0].telegram.contactCode === 0 ? "" : values[0].telegram.contactCode.toString() : ""}${values[0].telegram.contactNo}`)
    }, [values[0]])



    useEffect(() => {
        onUserInput(null, { telegram: {username: telegram.username, contactNo: telegram.contactNo, contactCode: contactCode, contactVerified: telegram.contactVerified, chatId: telegram.chatId} }, true)
          //---this is to disable the warning for useEffect----//
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [telegram])

    useEffect(() => {
        onUserInput(null, { privileges: {agencyUUID: agencyUUID, agencyID: privileges.agencyId, chatBotEnabled: privileges.chatBotEnabled, role: privileges.role} }, true)
          //---this is to disable the warning for useEffect----//
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [privileges])

    useEffect(() => {
        onUserInput(null, { password: Base64.encode(password) }, true)
          //---this is to disable the warning for useEffect----//
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password])
    return (
        <Form users={values} onUserInput={onUserInput} displayed={displayed} fx_back={fx_back} setPrivileges={setPrivileges} privileges={privileges} setTelegram={setTelegram} telegram={telegram} setPassword={setPassword} password={password} setSnOpen={setSnOpen} setMessage={setMessage} setSeverity={setSeverity} setErrMsg={setErrMsg} setErrOpen={setErrOpen} setErrSevere={setErrSevere} setContactCode={setContactCode} contact_number={contact_number} featureDisable={featureDisable}/>
    )
}
export default FunctionEdit
