import React, { useState, useEffect } from "react";

//library
import moment from "moment-timezone";
import { useQuery } from "@apollo/react-hooks";

//image
import Calendar from "../../Image/calendar_dark.svg"
import DeviceGreyIcon from "../../Image/device-grey.svg"
import ChevronLightIcon from "../../Image/chevron_light.svg"

//component
import FunctionAdd from './FunctionAdd'
import FunctionEdit from './FunctionEdit'
import Overview from "./Overview"
import Loaders from "../../component/component-loaders/Loaders"
import { filterList } from "../../config/config-filter/filter"
import { GET_FIRMWARE, ADD_FIRMWARE, UPDATE_FIRMWARE, DELETE_FIRMWARE } from "../../config/config-graphql/Graphql";
import SnackBar from '../../config/config-snackbar/SnackBar'


const PanelFirmware = ({ filter }) => {// 👈 the filter props is for the filter that comes from other pages (view all)
    const [filterFinal, setFilterFinal] = useState({})
    const [preFilter, setPreFilter] = useState({})
    const [canUpdate, setCanUpdate] = useState(false) // this is for the form mandatory field, default false
    const { data, loading, error } = useQuery(GET_FIRMWARE, { variables: { filter: filterFinal }});
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")

    // console.log(filterFinal)
    const PreFilter = (value) => {
        setPreFilter({ name: { type: "CONTAINS", value: [ value ] } })
    }

    const EnterFilter =(e) => {
        if(e.key === "Enter"){
            setFilterFinal(preFilter)
        }
    }

    // console.log(JSON.stringify(error, null, 2));
    useEffect(() => {
        if(error){
            setSnOpen(true)
            setMessage("Error: Unable to retrieve data. Please try again later.")
            setSeverity("error")
        }
    }, [error])

    return loading ? <Loaders /> : error ? (
        <SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/>
    ) : (
        <Overview
            title="Firmware"
            data={data.get_firmwares !== null ? data.get_firmwares : []}
            loading={loading}
            addForm={FunctionAdd}
            editForm={FunctionEdit}
            query={{ addQuery: ADD_FIRMWARE, getQuery: GET_FIRMWARE, editQuery: UPDATE_FIRMWARE , deleteQuery: DELETE_FIRMWARE}}
            Cards={Cards}
            filterList={filterList.firmware}
            setFilterFinal={setFilterFinal}
            filterFinal={filterFinal}
            preFilter={preFilter} 
            fx_PreFilter={PreFilter} 
            EnterFilter={EnterFilter}
            keyFilter="name"
            setPreFilter={setPreFilter}
            setCanUpdate={setCanUpdate}
            canUpdate={canUpdate}
        />
    )
};

export default PanelFirmware;


const Cards = ({data, setEdit, edit, add, setAdd, setEditValue}) => {

    const [isHovered, setIsHovered] = useState({
        container:false,
        details: false
    })

  
    const btnDetails = (e, value) => {

        let firmware = []

        firmware.push(value)
        e.preventDefault(e);
        // console.log("EDIT", value)
        setEdit(!edit)
        setEditValue(firmware)
    }


    return(
        <div onMouseEnter={() => setIsHovered((current) => ({...current, container: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, container: false}))}>
            <div className="card-container">
                <div className="name-container">
                    <div className="firmware-name">{data.version}</div>
                    <div className="firmware-id">{data.board_class}</div>
                </div>
                <div className="line-box"></div>
                <div className="release-date-box">
                    <div className="calendar-icon">
                        <img src={Calendar} alt="test"/>
                    </div>
                    <div className="datetime">{moment(data.release_date).format("DD MMMM YYYY")}</div>
                </div>
                <div className="line-box"></div>
                <div className="count-flashed-box">

                        <div className="flashed-container">
                            <div className="count-container">
                                <img src={DeviceGreyIcon} alt="test" style={{width: "16px", height: "16px"}}/>
                                <div className="flashed">Flashed</div>
                            </div>
                            <div className="count-number">{data.device_count}</div>
                        </div>
                </div>
                {
                    isHovered.container && 
                    <div className="onhover-container">
                        <div className={`${isHovered.details ? "isHovered-button-edits" : "button-edits"}`} onClick={e => btnDetails(e, data)} onMouseEnter={() => setIsHovered((current) => ({...current, details: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, details: false}))}>
                                <img src={ChevronLightIcon} alt="test"/>
                        </div>
                </div>
                }
            </div>
            <style jsx>
            {
                `
                .flashed{
                    margin: 0px 0px 0px 4px;
                    color: #939598;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 16px;
                }
                .count-container{
                    display: flex;
                }
                .count-number{
                    color: #333333;
                    font-size: 24px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 32px;
                    text-align: center;
                }
                 .card-container{
                    margin-bottom: 4px;
                    display: grid;
                    grid-template-columns: minmax(0, 2fr) 10px minmax(0, 2fr) 10px 1fr 43px;
                    grid-template-rows: 1fr;
                    gap: 1% 0px;
                    grid-template-areas:
                      ". . . . . .";
                    height: 64px;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    // box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2);
                 }
                 .line-box {
                    height: 46px;
                    margin: 8px;
                    border: 1px solid #DDDDDD;
                 }
                 .line-box-two{
                    height: 40px;
                    margin-right: 82px;
                    border: 1px solid #DDDDDD;
                 }
                 .calendar-icon{
                     display: flex;
                     align-items: center;
                     margin-right: 4px;
                 }
                 .datetime{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                 }
                 .board-class{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                 }
                 .release-date-box{
                    margin: 8px;
                    white-space: nowrap;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                 }
                 .date-release{
                     display: flex;
                     align-items: center;
                 }
                 .count-flashed-box{
                    display: flex;
                    justify-content: center;
                 }
                 .name-container{
                     display:flex;
                     flex-direction: column;
                     margin: 8px;
                     white-space: nowrap;
                     overflow: hidden;
                 }
                 .firmware-name{
                    height: 24px;
                    color: #333333;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 21px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                 }
                 .firmware-id{
                    height: 24px;
                    color: #333333;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                 }
                 .button-edits{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 43px;
                    height: 64px;
                    border-radius: 0 4px 4px 0;
                    background-color: #509D91;
                    cursor: pointer;
                 }
                 .isHovered-button-edits{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 43px;
                    height: 64px;
                    border-radius: 0 4px 4px 0;
                    background-color: #498f84;
                    cursor: pointer;
                    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                 }
                 .count{
                    // margin-right: 57px;
                 }
                 .onhover-container{
                    // width: 100%;
                 }
                 .flashed-container{
                     display:flex;
                     flex-direction: column;
                     padding: 8px
                 }
                `
            }
            </style>
        </div>
    )
}