import React, {useState, useMemo, useCallback} from 'react'
import { useMutation } from "@apollo/react-hooks";
import {useDropzone} from 'react-dropzone';

import DarkDownloadIcon from '../../Image/dark_download.svg'
import GreyUpload from '../../Image/grey_upload.svg'
import DarkUpload from '../../Image/dark_upload.svg'

import {ADD_DEVICES, GET_DEVICES, GET_DEVICES_STAT, SEARCH_DEVICES} from '../../config/config-graphql/Graphql'
import {parseCSV} from '../../config/config-csvtoarray/csvToArray'
import SnackBar from '../../config/config-snackbar/SnackBar'

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#F2F2F2',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };

const BulkUpload = ({open, setBulk, setModalComplete }) => {

    const [file, setFile] = useState(null);
    const [bulkarr, setBulkarr] = useState(null)

    const [errOpen, setErrOpen] = useState(false)
    const [errMsg, setErrMsg] = useState("")
    const [errSevere, setErrSevere] = useState("") 


    const [isHovered, setIsHovered] = useState({
        btnYes: false,
        btnNo: false,
        download: false
    })

    //to convert to csv file
    let csvFile = "sn,name,description,add_agency.id,SKU.id,boards.id,boards.board_class,boards.assembly.id,boards.firmware_add.version,fuel_sensor.length,fuel_sensor.width,fuel_sensor.height,add_features.name\r\n"
    const url = `data:text/json;charset=utf-8,${encodeURIComponent(csvFile)}`


    //acceptedfile (convert csv to array )
    const onDrop = useCallback(acceptedFile => {
      const reader = new FileReader();
      let _file = acceptedFile[0];
      reader.onload = async (e) => {
      let arr = parseCSV(reader.result, ",")  //using csvtoarray function
      setBulkarr(arr) //arr value push into state
    }
    reader.readAsText(_file);
    setFile(_file) //this to display a file name
    },[])
    
    //useDropZone properties
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
      acceptedFiles
    } = useDropzone({accept: '.csv', onDrop, maxFiles: 1});
  
    //this is for the dropzone style
    const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }), [
      isDragActive,
      isDragReject,
      isDragAccept
    ]);
    
    //btn upload to called bulk upload
    const btnUpload = async (e) => {
        e.preventDefault()
        try{
            if(file){
                //reducer for gql
                const reducer = bulkarr.map(x => {
                    if(typeof x !== 'undefined'){
                        return{
                        
                            SKU: x.SKU,
                            add_agency: x.add_agency.id ? x.add_agency : {},
                            boards: [x.boards],
                            description: x.description,
                            fuel_sensor: x.fuel_sensor && {height: x.fuel_sensor.height ? parseFloat(x.fuel_sensor.height): 0, width: x.fuel_sensor.width ? parseFloat(x.fuel_sensor.width): 0, length: x.fuel_sensor.length ? parseFloat(x.fuel_sensor.length) : 0},
                            name: x.name,
                            sn: x.sn,
                            add_features: x.add_features.name ? typeof x.add_features.name === "string" ? [{name: x.add_features.name}] :  x.add_features.name.length > 0 ? x.add_features.name.map(x => {return{name: x}}) : [] : []
                        }
                    }
                })

                //this to fileter if array return undefined
                const clearnArray = reducer.filter(Boolean)
                
                const response = await addData({variables: {new_device : clearnArray}})

                //if the response return error display the error
                if(response.errors){
                    setErrOpen(true)
                    setErrMsg(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
                    setErrSevere("error")
                }
                setFile(null)
                setBulk(false)
                setModalComplete(true)
            }
        }catch(err){
            console.log(JSON.stringify(err, null, 2));
            setErrOpen(true)
            setErrMsg(JSON.stringify(err.message, null, 2))
            setErrSevere("error")
        }

    }

    //useMutation addData for add_device
    const [addData, { loading: addLoading, error }] = useMutation(ADD_DEVICES, {
        refetchQueries: () => [
            {
                query: GET_DEVICES,
                variables: {filter: {}, type:"All"},
            },
            {
                query: SEARCH_DEVICES,
                variables:{searchKey:  "", boardClass: "Cellular" }
            },
            {
              query: GET_DEVICES_STAT
            },
        ],
        awaitRefetchQueries: true,
    });

    //function for close button and switch modal
    const switchModal = () => {
        if(file){
            acceptedFiles.splice(0, 1) //this to remove the csv value
            setFile(null) //this to remove the file name
        }
        setBulk(false)
    }

    return (
        <div className="bulk-modal-container"style={{display: !open && "none"}} >
            <div className="bulk-modal-background" onClick={() => switchModal()}></div>
            <div className="bulk-modal-content">
                <SnackBar open={errOpen} setOpen={setErrOpen} severity={errSevere} message={errMsg}/>
                <div className="bulk-body-modal">
                    <div className="bulk-modal-title">Bulk Upload</div>
                    <div className="bulk-modal-details">To import the list of devices onto Horizon, kindly download the template, fill in the details and upload the file in .CSV format. </div>
                    <div className="bulk-modal-template-download" onMouseEnter={() => setIsHovered((current) => ({...current, download: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, download: false}))}>
                        <img loading="lazy" src={DarkDownloadIcon} alt="download"/>
                        <a href={url} download="bulkAdd_template.csv" className={isHovered.download ? "isHovered-bulk-download-template-text" :"bulk-download-template-text"}>Download template</a>
                    </div>
                    <div className="bulk-upload-container">
                        <div {...getRootProps({style})}>
                            <input {...getInputProps()} />
                            {file ? <img loading="lazy" src={DarkUpload} alt="dark"/>  : <img loading="lazy" src={GreyUpload} alt="grey"/>}
                            {file ? null : <div style={{margin:"10px 0px 0px 0px"}}>Upload File</div> }
                            {file ? null : <div style={{margin:"8px 0px 0px 0px"}}>drag and drop your .CSV file here</div> }
                            <div style={{margin:"10px 0px 0px 0px", color:"#000000"}}>{file ? file.name : ""}</div>
                        </div>
                    </div>
                    <div className="bulk-modal-btn">
                        <div className={isHovered.btnNo ? "isHovered-bulk-btn-no" : "bulk-btn-no"} onClick={() => switchModal()} style={{margin: "0px 24px 0px 0px"}} onMouseEnter={() => setIsHovered((current) => ({...current, btnNo: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnNo: false}))}>Cancel</div>
                        <div className={file ? isHovered.btnYes ? "isHovered-bulk-btn-yes" : "bulk-btn-yes" : "bulk-btn-disabled"} onClick={(e) => btnUpload(e)}  onMouseEnter={() => setIsHovered((current) => ({...current, btnYes: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, btnYes: false}))}>Upload</div>
                    </div>
                </div>
            </div>
            <style>
                {
                    `.bulk-modal-container{
                        position: fixed;
                        z-index: 1300;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                    }
                    .bulk-modal-background{
                        z-index: -1;
                        position: fixed;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                        background-color: rgba(0, 0, 0, 0.5);
                        -webkit-tap-highlight-color: transparent;
                    }
                    .bulk-modal-content{
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        width: 687px;
                        border-radius: 4px;
                        transform: translate(-50%, -50%);
                        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
                        background: #FFFFFF;
                        display: block;
                    }
                    .bulk-body-modal{
                        padding: 16px;
                    }
                    .bulk-modal-title{
                        display: flex;
                        justify-content: center;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 24px;
                        margin: 0px 0px 8px 0px;
                    }
                    .bulk-modal-btn{
                        display: flex;
                        justify-content: center;
                        margin: 24px 0px 0px 0px;
                    }
                    .bulk-btn-no{
                        border-radius: 2px;
                        border: 1px solid #509D91;
                        font-size: 14px;
                        height: 32px;
                        width: 213px;
                        color: #509D91;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items:center;
                    }
                    .bulk-btn-yes{
                        background: #509D91;
                        border-radius: 2px;
                        color: #FFFFFF;
                        font-size: 14px;
                        height: 34px;
                        width: 213px;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items:center;
                    }
                    .bulk-btn-disabled{
                        background: #DDDDDD;
                        border-radius: 2px;
                        color: #939598;
                        font-size: 14px;
                        height: 34px;
                        width: 213px;
                        display: flex;
                        justify-content: center;
                        align-items:center;
                    }
                    .isHovered-bulk-btn-no{
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                        color: #498f84;
                        border-radius: 2px;
                        border: 1px solid #498f84;
                        font-size: 14px;
                        height: 32px;
                        width: 213px;
                        font-weight: 600;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items:center;
                    }
                    .isHovered-bulk-btn-yes{
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                        background: #509D91;
                        font-size: 14px;
                        border-radius: 2px;
                        color: #FFFFFF;
                        height: 34px;
                        width: 213px;
                        font-weight: 600;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items:center;
                    }
                    .bulk-modal-details{
                        text-align:center;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 24px;
                        color: #939598;
                        margin: 0px 0px 20px 0px;
                    }
                    .bulk-modal-template-download{
                        display: flex;
                        justify-content: center;
                        align-items:center;
                        cursor: pointer;
                        margin: 0px 0px 4px 0px;
                    }
                    .bulk-download-template-text{
                        font-size: 13px;
                        line-height: 24px;
                        color: #509D91;
                        margin: 0px 0px 0px 4px;
                        text-decoration: none;
                    }
                    .isHovered-bulk-download-template-text{
                        font-weight: bold;
                        font-size: 13px;
                        line-height: 24px;
                        color: #509D91;
                        margin: 0px 0px 0px 4px;
                        text-decoration: none;
                    }
                    .bulk-upload-container{

                    }
                    `
                }
            </style>
        </div>
    )
}

export default BulkUpload
