import React, {useEffect, useState} from "react";

// import { useQuery } from "@apollo/react-hooks";

import moment from "moment-timezone";

import CalendarGreyIcon from "../../Image/calendar_grey.svg"
import DeviceGreyIcon from "../../Image/device-grey.svg"
import UserGreyIcon from "../../Image/user_grey.svg"
import ChevronLightIcon from "../../Image/chevron_light.svg"



import FunctionAdd from './FunctionAdd'
import FunctonEdit from './FunctionEdit'
import Overview from "./Overview"
import Loaders from "../../component/component-loaders/Loaders"
// import {GET_AGENCIES, agenciesQuery} from "../../config/config-graphql/Graphql";
import { filterList } from "../../config/config-filter/filter"
import { APIGetAgencies } from '../../config/config-restAPI/getAPICalled'
import SnackBar from '../../config/config-snackbar/SnackBar'
import Cookies from 'js-cookie';

const PanelAgencies = ({ fx_changePage, filter, setFilter }) => {

  const [filterFinal, setFilterFinal] = useState({})
  const [preFilter, setPreFilter] = useState({})
  const [canUpdate, setCanUpdate] = useState(false) //default false and also this state for btn save mandatory field
  const [edit, setEdit] = useState(false); //default false and also this state is to open the overview details edit form
  const [editValue, setEditValue] = useState() //this state is to store the all the selected value into the state.,
  const [pageInd, setPageInd] = useState(false)
  const [apiLoading, setApiLoading] = useState(false);
  const [agencyValue, setAgencyValue] = useState([]);

  const [dispFilter, setDispFilter] = useState(false) // to display a filter component (REST)
  const [filterPre, setFilterPre] = useState({}); // pre-filter component (REST) this for input

  //this state for error handling on getQuery
  const [snOpen, setSnOpen] = useState(false)
  const [message, setMessage] = useState("")
  const [severity, setSeverity] = useState("")
  const [errors, setErrors] = useState(false)

  const token = Cookies.get('jwtToken');
  const agencyId = Cookies.get('agency-id');
  
  const PreFilter = (value) => {
    const snFilter = { name: `CONTAINS,${value.toString()}`}
    setPreFilter({ name: { type: "CONTAINS", value: [ value ] } })
    setFilterPre(snFilter)
  }

  const APICalled = async (filter, filterParams) => {
    try {
      // console.log(filter, filterParams);
        const result = await APIGetAgencies(token, setApiLoading, agencyId, filter, filterParams)
        if (result.data.status === 200) {
          if (pageInd) {
            setEditValue(result.data.data)
            setAgencyValue(result.data.data)
            setApiLoading(false)
          } else {
            setAgencyValue(result.data.data);
            setApiLoading(false);
          }
        } else {
            setSnOpen(true);
            setMessage("Error: Unable to retrieve data. Please try again later.");
            setSeverity("error") 
            setErrors(true)
            setPageInd(false)
            setFilter({})
            setApiLoading(false);
        }
    } catch(err) {
        setSnOpen(true);
        setMessage("Error: Unable to retrieve data. Please try again later.");
        setSeverity("error");
        setPageInd(false)
        setFilter({})
        setErrors(true)
        setApiLoading(false);
    }
}


useEffect(() => {
  if (token) {
      APICalled('', {});
  }
}, [])


  useEffect(() => {
      if(pageInd){
          setEdit(!edit)       
          APICalled(filterFinal)
      }
    
}, [pageInd])


  // enter the filter by key in the words and press "enter"
  const EnterFilter =(e) => {
      if(e.key === "Enter"){
          setFilterFinal(preFilter)
          APICalled(false, filterPre)
      }
  }

useEffect(() => {
  if(errors){
      setSnOpen(true)
      setMessage("Error: Unable to retrieve data. Please try again later.")
      setSeverity("error")
      setErrors(false)
  }
}, [errors])

    // this is for FilterClick
    const onFilterClick = (filterVariable) => {      
      // this to do the reducer
      const valueFilter = Object.entries(filterVariable).map((x) => {
          const schema = x[0];
          const values = x[1];
          return {
              [schema]: `${values.type},${values.value.toString()}`
          }
      })
      // this to convert from array to object.
      const changeToObject = valueFilter.reduce((previosObject, currentObject) => {
          return Object.assign(previosObject, currentObject)
      }, {})

      // console.log(changeToObject)
      APICalled('', changeToObject);
      setFilterFinal(changeToObject)
      setDispFilter(!dispFilter)
  }

  return (apiLoading ? <Loaders /> : errors ? (<SnackBar open={snOpen} setOpen={setSnOpen} message={message} severity={severity}/>): (
    <div>
      <Overview 
        title="Agencies" 
        data={agencyValue} 
        onFilterClick={onFilterClick}
        dispFilter={dispFilter}
        setDispFilter={setDispFilter}
        addForm={FunctionAdd} 
        setMainFilter={setFilter} 
        editForm={FunctonEdit} 
        // query={agenciesQuery} 
        Cards={Cards} 
        filterFinal={filterFinal} 
        setFilterFinal={setFilterFinal} 
        filterList={filterList.agency} 
        preFilter={preFilter} 
        fx_PreFilter={PreFilter} 
        EnterFilter={EnterFilter} 
        keyFilter="name" 
        setPreFilter={setPreFilter} 
        setCanUpdate={setCanUpdate} 
        canUpdate={canUpdate} 
        edit={edit} 
        setEdit={setEdit} 
        setEditValue={setEditValue} 
        editValue={editValue}
        setPageInd={setPageInd}
        APICalled={APICalled}
      />
    </div>
  )
  );
};

export default PanelAgencies;


const Cards = ({data, setEdit, edit, add, setAdd, setEditValue, setFilterFinal, setPageInd}) => {

    // console.log(data)
  const [isHovered, setIsHovered] = useState({
    container: false,
    details: false
  })
  const btnDetails = (e, value) => {
    setPageInd(true)
    setFilterFinal({uuid: value.uuid})
}

  return(
    <div onMouseEnter={() => setIsHovered((current) => ({...current, container: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, container: false}))}>
          <div className="agencies-main-container">
              <div className="first-box">                  
                  <div className="agencies-details">
                    <div className="agencies-name">{data.name}</div>
                    <div className="agencies-id">{data.id}</div>
                  </div>
                  <div className={`status-container ${data.isActive ? "status-active" : "suspended"}`}>
                    <div className={`status-subs ${data.isActive ? "status-active" : "status-suspended"}`}>{ data.isActive ? "Active" : "Suspended"}</div>
                  </div>
              </div>
              <div className="line-box"></div>
              <div className="agencies-count-devices"> 
                <div className="flashed-container">
                    <div className="count-container">
                          <img src={DeviceGreyIcon} alt="test" style={{width: "16px", height: "16px"}}/>
                          <div className="flashed">Device</div>
                    </div>
                    <div className="count-number">{data.totalDevices}</div>
                </div>                  
              </div>
              <div className="line-box"></div>
              <div className="agencies-count-devices">
              <div className="flashed-container">
                    <div className="count-container">
                          <img src={UserGreyIcon} alt="test" style={{width: "16px", height: "16px"}}/>
                          <div className="flashed">User</div>
                    </div>
                    <div className="count-number">{data.totalUsers}</div>
                </div>  
              </div>
              <div className="line-box"></div>
              <div className="subscription-date">
                <div className="devices-container">
                  <div className="symbol-subscription">
                    <div className="sub-container">
                            <img src={CalendarGreyIcon} alt="test" style={{width: "16px", height: "16px"}}/>
                            <div className="flashed-subs">Subscription Period</div>
                      </div>
                      <div className="count-date">{data.billing.subscriptionFrom && data.billing.subscriptionTo ? `${moment(data.billing.subscriptionFrom).format("DD/MM/YYYY")} - ${moment(data.billing.subscriptionTo).format("DD/MM/YYYY")}` : ""}</div>
                  </div>
                </div>
              </div>
              {
                isHovered.container &&  <div className="onhover-container" onMouseEnter={() => setIsHovered((current) => ({...current, details: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, details: false}))}>
                <div className={`${isHovered.details ? "isHovered-button-edits" : "button-edits"}`} onClick={e => btnDetails(e, data)}>
                      <img src={ChevronLightIcon} alt="test"/>
                </div>
              </div>
              }

          </div>
        <style jsx>
        {
          `
            .agencies-main-container{
                padding: 0px 0px 0px 8px;
                margin-bottom: 4px;
                display: grid;
                height: 64px;
                width: 100%;
                grid-template-columns: minmax(0, 3fr) 10px 1fr 10px 1fr 10px 1fr 43px;
                grid-template-rows: 1fr;
                gap: 1% 0px;
                grid-template-areas:
                  ". . . . . . . .";
                height: 64px;
                border-radius: 4px;
                background-color: #FFFF;
                // box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2);
            }
            .first-box{
              display: flex;
              align-items: center;
              justify-content: space-between;
              // width: 910px;
            }
            .agencies-id{
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .agencies-details{
              display: flex;
              flex-direction: column;
              white-space: nowrap;
              overflow: hidden;
            }
            .status-container{
              margin: 0px 8px 0px 0px;
              display: flex;
              align-items: center;
              justify-content: center;
              box-sizing: border-box;
              height: 25px;
              width: auto;
              padding: 0 10px;
              border-radius: 4px;
            }
            .status-container.status-active{
              border: 1px solid #286551;
              background-color: #fff;
            }
            .status-container.suspended{
              border: 1px solid #EA2B1F;
            }
            .agencies-count-devices{
              display: flex;
              align-items: center;
              justify-content: center;
              // width: 191px;
            }
            .line-box{
              height: 48px;
              margin: 8px;
              border: 1px solid #DDDDDD;
            }
            .devices-container{
              margin: 0px 0px 0px 40px;
            }
            .symbol{
              display: flex;
            }
            .count{

            }
            .users-container{

            }
            .subscription-date{
              display: flex;
              align-items: center;
              justify-content: center;
              // width: 319px;
            }
            .symbol-subscription{
              display: flex;
              align-items: center;
              flex-direction: column;
              // margin: 0px 0px 0px 81 px;
            }
            .date{

            }
            .onhover-container{
              display:flex;
              justify-content: flex-end;
              width: 100%;
           }
           .button-edits{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 43px;
            height: 64px;
            border-radius: 0 4px 4px 0;
            background-color: #509D91;
            cursor: pointer;
          }
          .isHovered-button-edits{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 43px;
            height: 64px;
            border-radius: 0 4px 4px 0;
            background-color: #498f84;
            cursor: pointer;
            box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
          }
          .subscription-box{

          }
          .agencies-name{
            flex: 1 2 auto;
            color: #333333;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 21px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            
          }
          .status-subs{
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
            text-align: center;
          }
          .status-active{
            color: #286551;
          }
          .status-suspended{
            color: #EA2B1F;
          }
          .flashed-container{
            display:flex;
            flex-direction: column;
          }
          .count-container{
            display: flex;
          }
          .flashed{
            margin: 0px 0px 0px 4px;
            color: #939598;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
          }
          
          .count-number{
            color: #333333;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 32px;
            text-align: center;
          }
          .count-date{
            height: 31px;
            width: 178px;
            color: #333333;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 21px;
            display: flex;
            align-items: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .sub-container{
            display: flex;
          }
          .flashed-subs{
            width: 110px;
            color: #939598;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 16px;
          }
          `
        }
        </style>
    </div>
  )
}
