import React, { useState } from 'react'
import ChevronLightIcon from "../../Image/chevron_light.svg"
import BattGreen from '../../Image/green-batt-full.svg'
import BattYellow from '../../Image/yellow-batt-middle.svg'
import BattRed from '../../Image/red-batt-empty.svg'
import BattGrey from '../../Image/grey-unknown-battery.svg'
import AgencyIcon from "../../Image/agencies.svg"
import WareHouse from '../../Image/dark-warehouse.svg'

const BeaconCards = ({ data, setEdit, setEditValue, isAdmin, isDashboard }) => {
    const [isHovered, setIsHovered] = useState({
        container: false,
        details: false,
    })
    // console.log(data);
    const btnDetails = (e, value) => {
        setEditValue(value);
        setEdit(true);
    }
    const battPerc = (value) => {
        switch (value) {
            case 99:
                return BattGreen
            case 50:
                return BattYellow
            case 30:
                return BattRed
            case 25:
                return BattRed
            default:
                return BattGrey
        }
    }
    return (
        <div onMouseEnter={() => setIsHovered((current) => ({...current, container: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, container: false}))}>
            <div className="beacon-main-container">
                <div className="beacon-first-box">                  
                    <div className="beacon-details">
                        <div className="beacon-name">{data.serialNumber}</div>
                        <div className="beacon-id">{data.sku.skuId}</div>
                    </div>
                </div>
                <div className="line-box"></div>
                {
                    isAdmin ? (
                    <div className="beacon-agency-detail">
                        <img src={AgencyIcon} alt="agengyIcon"/>
                        <div className="beacon-agency-name">{data.agency ? data.agency.agencyName : "" }</div>
                    </div>
                    ) : (
                    <div className="beacon-agency-detail">
                        <img src={WareHouse} alt="warehouse" />
                        <div className="beacon-warehouse-name">
                            <div>{data && data.locationName ? data.locationName : "-"}</div>
                            {
                                data && data.coordinate && Object.keys(data.coordinate).length > 0 ? (
                                    <a className="link" target="_blank" href={`https://maps.google.com/?q=${data.coordinate.latitude},${data.coordinate.longitude}`}>{data && data.coordinate ? `${data.coordinate.latitude}, ${data.coordinate.longitude}` : '-'}</a>
                                ) : '-'
                            }

                        </div>
                    </div>
                    )
                }

                <div className="line-box"></div>
                <div className="beacon-batt-detail">
                    <img src={battPerc(null)} alt="test" className="beacon-batt-img" />
                    <div className="beacon-number-detail">{"-"}</div>
                    <div className="beacon-symbol-detail">%</div>
                    <div className="beacon-lastupdate">{"-"}</div>
                </div>
                {
                    isHovered.container &&  
                    <div className="onhover-container" onMouseEnter={() => setIsHovered((current) => ({...current, details: true}))} onMouseLeave={() => setIsHovered((current) => ({...current, details: false}))}>
                        <div className={`${isHovered.details ? "isHovered-button-edits" : "button-edits"}`} onClick={e => btnDetails(e, data)}>
                            <img src={ChevronLightIcon} alt="test"/>
                        </div>
                    </div>
                }
            </div>
            <style jsx>
                {
                `
                    .beacon-main-container{
                        padding: 0px 0px 0px 8px;
                        margin-bottom: 4px;
                        display: grid;
                        height: 64px;
                        width: 100%;
                        grid-template-columns: minmax(0, 0.5fr) 8px minmax(0, 0.5fr) 8px minmax(0, 1fr) 40px;
                        grid-template-rows: 1fr;
                        gap: 1% 0px;
                        grid-template-areas: ". . . . . .";
                        height: 64px;
                        border-radius: 4px;
                        background-color: #FFFF;
                        // box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2);
                    }
                    .link{
                        color: blue;
                        text-decoration: none;
                        cursor: pointer;
                        font-size: 13px;
                    }
                    .beacon-first-box{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        // width: 910px;
                    }
                    .beacon-batt-img{
                        padding: 0px 0px 0px 0px;
                    }
                    .beacon-id{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .beacon-details{
                        display: flex;
                        flex-direction: column;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .line-box{
                        height: 48px;
                        margin: 8px;
                        border: 1px solid #DDDDDD;
                    }
                    .onhover-container{
                        display:flex;
                        justify-content: flex-end;
                        width: 100%;
                    }
                    .button-edits{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 43px;
                        height: 64px;
                        border-radius: 0 4px 4px 0;
                        background-color: #509D91;
                        cursor: pointer;
                    }
                    .isHovered-button-edits{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 43px;
                        height: 64px;
                        border-radius: 0 4px 4px 0;
                        background-color: #498f84;
                        cursor: pointer;
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                    }
                    .beacon-name{
                        flex: 1 2 auto;
                        color: #333333;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 21px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .beacon-lastupdate{
                        margin-left: 30px;
                        font-size: 14px;
                        color: #939598;
                        font-weight: 400;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .beacon-agency-detail{
                        display: flex;
                        align-items: center;
                        padding: 16px;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .beacon-batt-detail{
                        display: flex;
                        align-items: center;
                        padding: 16px;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .beacon-symbol-detail{
                        font-size: 13px;
                        position: relative;
                        top: 3px;
                        line-height: 24px;
                        font-weight: 400;
                        margin: 0px 0px 0px 15px;
                        color: #939598;
                    }
                    .beacon-number-detail{
                        margin: 0px 0px 0px 20px;
                        font-size: 24px;
                        font-weight: 700;
                    }
                    .beacon-agency-name{
                        margin-left: 10px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .beacon-warehouse-name{
                        margin-left: 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                `
                }
            </style>
        </div>
    )
}

export default BeaconCards
