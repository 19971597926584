import React from 'react'

//library
import BaseTable, { AutoResizer } from 'react-base-table'
import 'react-base-table/styles.css'
import styled from 'styled-components'
import ReactTexty from 'react-texty'


const Container = styled.div`
    width: 100%; 
    height: 35vh;  
`

const asset_reports = [
    { key: "time", title: "Time", dataKey: "time", width: 150, flexGrow: 1, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> },
    { key: "duration", title: "Duration (min)", dataKey: "duration", width: 150, flexGrow: 1, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> },
    { key: "rate", title: "Rate (%)", dataKey: "rate", width: 150, flexGrow: 1, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> }
]

const ReportTable = ({data}) => {
    return (
        <div className="asset-report-table-container">
            <div className="white-box">
                <Container>
                    <AutoResizer>
                        {({ width, height }) => (
                            <BaseTable columns={asset_reports} width={width} height={height} data={data} />
                        )}
                    </AutoResizer>
                </Container>
            </div>
            <style>
                {
                    `
                    .asset-report-table-container{
                        
                    }
                    `
                }
            </style>
        </div>
    )
}

export default ReportTable
