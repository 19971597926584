import React, {useRef, useLayoutEffect} from 'react'

/* Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

      /* Chart code */
      // Themes begin
      am4core.useTheme(am4themes_animated);
      // Themes end

const DeviceChart = () =>  {

    const charts = useRef(null);
    const chartsTwo = useRef(null);
      // Create chart instance

      useLayoutEffect(() => {
        let chart = am4core.create("chartdiv", am4charts.XYChart);

        let chart_two = am4core.create("chartdiv_2", am4charts.XYChart);

        // Add data
        chart.data = [{
          "category": "",
          "from": 0,
          "to": 15,
          "name": "Stage #1",
          "fill": am4core.color("#0ca948")
        }, {
          "category": "",
          "from": 15,
          "to": 75,
          "name": "Stage #2",
          "fill": am4core.color("#93da49")
        }, {
          "category": "",
          "from": 75,
          "to": 90,
          "name": "Stage #3",
          "fill": am4core.color("#ffd100")
        }, {
          "category": "",
          "from": 90,
          "to": 95,
          "name": "Stage #4",
          "fill": am4core.color("#cd213b")
        }, {
          "category": "",
          "from": 95,
          "to": 100,
          "name": "Stage #5",
          "fill": am4core.color("#9e9e9e")
        }];
        
        chart_two.data = [{
            "category": "",
            "from": 0,
            "to": 15,
            "name": "Stage #1",
            "fill": am4core.color("#0ca948")
          }, {
            "category": "",
            "from": 15,
            "to": 75,
            "name": "Stage #2",
            "fill": am4core.color("#93da49")
          }, {
            "category": "",
            "from": 75,
            "to": 90,
            "name": "Stage #3",
            "fill": am4core.color("#ffd100")
          }, {
            "category": "",
            "from": 90,
            "to": 95,
            "name": "Stage #4",
            "fill": am4core.color("#cd213b")
          }, {
            "category": "",
            "from": 95,
            "to": 100,
            "name": "Stage #5",
            "fill": am4core.color("#9e9e9e")
          }];
        // Create axes
        let yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        yAxis.dataFields.category = "category";
        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.labels.template.disabled = true;
  
        let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.labels.template.disabled = true;
        xAxis.min = 0;
        xAxis.max = 100;

        // Create axes
        let yAxises = chart_two.yAxes.push(new am4charts.CategoryAxis());
        yAxises.dataFields.category = "category";
        yAxises.renderer.grid.template.disabled = true;
        yAxises.renderer.labels.template.disabled = true;
  
        let xAxises = chart_two.xAxes.push(new am4charts.ValueAxis());
        xAxises.renderer.grid.template.disabled = true;
        xAxises.renderer.grid.template.disabled = true;
        xAxises.renderer.labels.template.disabled = true;
        xAxises.min = 0;
        xAxises.max = 100;
  
        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueX = "to";
        series.dataFields.openValueX = "from";
        series.dataFields.categoryY = "category";
        series.columns.template.propertyFields.fill = "fill";
        series.columns.template.strokeOpacity = 0;
        series.columns.template.height = am4core.percent(100);

        // Create series
        let serieses = chart_two.series.push(new am4charts.ColumnSeries());
        serieses.dataFields.valueX = "to";
        serieses.dataFields.openValueX = "from";
        serieses.dataFields.categoryY = "category";
        serieses.columns.template.propertyFields.fill = "fill";
        serieses.columns.template.strokeOpacity = 0;
        serieses.columns.template.height = am4core.percent(100);
  
  
        // Legend
        let legend = new am4charts.Legend();
        legend.parent = chart.chartContainer;
        legend.itemContainers.template.clickable = false;
        legend.itemContainers.template.focusable = false;
        legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
        legend.align = "right";
        legend.data = chart.data;

        // Legend
        let legends = new am4charts.Legend();
        legends.parent = chart_two.chartContainer;
        legends.itemContainers.template.clickable = false;
        legends.itemContainers.template.focusable = false;
        legends.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
        legends.align = "right";
        legends.data = chart_two.data;

        charts.current = chart;
        chartsTwo.current = chart_two
          return () => {
              chart.dispose();
              chart_two.dispose();
          };
      }, [])
     
    return (
        <div className="bar-container">
        <div className="charts-cards space">
            <div className="title">Available</div>
            <div id="chartdiv" style={{ width: "100%", height: "100%", alignItems: "center", margin: "0px 0px 0px 0px"}}></div>
        </div>
        <div className="charts-cards ">
            <div className="title">Assigned</div>
            <div id="chartdiv_2" style={{ width: "100%", height: "100%", alignItems: "center", margin: "0px 0px 0px 0px"}}></div>
        </div>
        <style jsx>
          {
              `.bar-container{
                  display:flex;
                  margin: 0px 0px 24px 0px;
              }
              .charts-cards{
                  padding: 8px;
                  height: 129px;
                  width: 100%;
                  border-radius: 4px;
                  background-color: #FFFFFF;
                  display: flex;
                  flex-direction: column;
                  justify-content: center
                
              }
              .space{
                  display: flex;
                  flex-direction: column;
                  margin: 0px 24px 0px 0px;
              }
              .title{
                  color: #333333;
                  font-size: 14px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 19px;
                  text-align: center;
              }
              `
          }
        </style>
    </div>
    )
}

export default DeviceChart
