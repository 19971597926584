import React, {useState} from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from "@apollo/react-hooks";
import grey from "@material-ui/core/colors/grey";
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { IconButton, InputAdornment } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import ReactTexty from 'react-texty'
import styled from "styled-components";
import Loaders from '../../component/component-loaders/Loaders'

import CalendarIcon from '../../Image/calendar_dark.svg';
// import SmartLocks from '../panel-SKU/OtherModel';
import SearchChannel from './SearchChannel'
import SnackBar from '../../config/config-snackbar/SnackBar'
import {TableSelect, Column} from '../../component/component-tableSelect/index'
import { GENERATE_SMARTLOCK_PIN, GET_DEVICES, UPDATE_ASSET, GET_ASSET, ADD_ASSET} from "../../config/config-graphql/Graphql"

const materialTheme = createMuiTheme({

    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: grey.A400,
            },
        },
        MuiPickerDTTabs: {
            tabs: {
                backgroundColor: grey.A400,
            }
        },
        MuiPickersDay: {
            day: {
                color: grey.A400,
            },
            daySelected: {
                backgroundColor: grey["400"],
            },
            dayDisabled: {
                color: grey["100"],
            },
            current: {
                color: grey["900"],
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: grey["400"],
            },
        },
        MuiPickersClock: {
            pin: {
                backgroundColor: grey["400"],
            }
        },
        MuiPickersClockPointer: {
            pointer: {
                backgroundColor: grey["400"],
            }
        },
    }
});


const columns = [
    { key: "sn", title: "SN", dataKey: "sn", flexGrow: 1, width: 100, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> },
    // { key: "name", title: "Name", dataKey: "name", flexGrow: 1, width: 100, cellRenderer: ({ cellData }) => <ReactTexty>{cellData}</ReactTexty> },
]

const Content = styled.div`
flex: 1 1 auto;
height: 480px;
.BaseTable{
  box-shadow: 0px 0px #FFFFFF;
}

.BaseTable__row {
  /* color: #9ca2b3; */
}

.BaseTable__table-main {
  outline: none;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #d0d3db;
  }
}

.BaseTable__header-row {
  background: #fff;
}

.BaseTable__header-cell:hover *:not(span) {
  color: #6899f8;
  &:before,
  &:after {
    background: #6899f8;
  }
}

.BaseTable__header-cell-text {
  color: #939598;
  font-size: 0.9em;
  user-select: none;

  &:hover {
    color: #6899f8;
  }
}

.BaseTable__row-cell{
  font-size: 12px;

}
`;

const PinModal = ({asset, isAdd, modal, setModal, slDefault, setSlDefault}) =>  {
    // const classes = useStyles()
    const browserUtcOffset = Math.abs(new Date().getTimezoneOffset() / 60)

    const [date, setDate] = useState({
        fromDate: moment().utcOffset(browserUtcOffset, true).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).hour(-24).toISOString(),
        toDate: moment().utcOffset(browserUtcOffset, true).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString()
    })
    const [finalData, setFinalData] = useState()
    const [selectedSmartlock, setSelectedSmartlock] = useState([])
    // console.log(slDefault)

    //this snOpen, message and severity is for snackbar props
    const [snOpen, setSnOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")

    const btnConfirm = async (e) => {
        e.preventDefault();
        let finalStDate = moment(`${date.fromDate}`).utcOffset(browserUtcOffset, true).toISOString()
        let finalEnDate = moment(`${date.toDate}`).utcOffset(browserUtcOffset, true).toISOString()

        // console.log(object)


        let serialNumber = selectedSmartlock.map(x => {return x.sn})
        const finalData = {
            input: {
                sn: serialNumber,
                startDate: finalStDate,
                endDate: finalEnDate
            }
        }

        if(!isAdd){

            const response = await addData({variables: finalData})
            // console.log(response)
            if(response.errors){
                setSnOpen(true)
                setMessage(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
                setSeverity("error")
              }
              else{
                setSnOpen(true)
                setMessage("Record has been successfully added")
                setSeverity("success")
                setSlDefault(response.data.generate_smartlock_pin)
              }
        }
        else{

        }

        const response = await addData({variables: finalData})
        if(response.errors){
            setSnOpen(true)
            setMessage(`${response.errors[0].extensions.code}: ${response.errors[0].message}`)
            setSeverity("error")
          }
          else{
            setSnOpen(true)
            setMessage("Record has been successfully added")
            setSeverity("success")
          }
    }

    const onUserInput = (e) => {
        setDate({ ...date, ...e })
    }

    const [addData, {loading:pinloading, error:pinErr}] = useMutation(GENERATE_SMARTLOCK_PIN, {
        refetchQueries: () => [
            {
                query: GET_DEVICES,
                variables: { filter: {}},
            },
        ],
    });


    return  (
        <div className="modal-container" style={{display: !modal && "none"}}>
            <div className="modal-background" onClick={() => setModal(!modal)}></div>
            <div className="modal-content">
            {
                pinloading ? <Loaders /> : (
                    <>
                    <SnackBar open={snOpen} setOpen={setSnOpen} severity={severity} message={message}/>
                    <header className="title-header">Generate Pin</header>
                    <main className="sync-container">
                        <section className="add-smart-container">
                            <p className="title-text-modal">Smart Lock</p>
                            <div className="selection-container">
                                <div className="smartLocks-details-selection-container">
                                <TableSelect data={slDefault} rowKey="sn" selectable setCopy={setSelectedSmartlock} copy={selectedSmartlock} removeSelection={true} headerHeight={35} rowHeight={34} Content={Content}>
                                    {columns.map(({ dataKey, ...restProps }) => (
                                        <Column key={dataKey} dataKey={dataKey} {...restProps} />
                                    ))}
                                </TableSelect>                                  
                                </div>
                            </div>
                        </section>
                        <section>
                            <p className="title-text-modal">PIN Valid Duration</p>
                            <div className="valid-timestamp">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <ThemeProvider theme={materialTheme}>
                                            <KeyboardDateTimePicker
                                                name="fromTime"
                                                value={date.fromDate}
                                                onChange={(e) => onUserInput({"fromDate": e})}
                                                inputVariant="outlined"
                                                // className={classes.textField}
                                                style={{ width: "100%" }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton style={{ outline: "none" }}>
                                                                <img loading="lazy" src={CalendarIcon} alt="Icon"/>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </ThemeProvider>
                                    </MuiPickersUtilsProvider>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <ThemeProvider theme={materialTheme}>
                                            <KeyboardDateTimePicker
                                                name="fromTime"
                                                value={date.toDate}
                                                onChange={(e) => onUserInput({"toDate": e})}
                                                inputVariant="outlined"
                                                // className={classes.textField}
                                                style={{ width: "100%" }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton style={{ outline: "none" }}>
                                                                <img loading="lazy" src={CalendarIcon} alt="Icon"/>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </ThemeProvider>
                                    </MuiPickersUtilsProvider> 
                            </div>
                            
                        </section>
                    </main>
                    <footer className="modal-footer">
                        <section className="footer-details">
                        </section>
                        <section className="btn-footer">
                            <button className="btn-igloo-confirm" onClick={(e)=> btnConfirm(e)}>Confirm</button>
                        </section>

                    </footer>
                    </>
                )
            }

            </div>
            <style>
                {
                    `.modal-container{
                        position: fixed;
                        z-index: 1300;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                    }
                    .modal-background{
                        z-index: -1;
                        position: fixed;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        left: 0px;
                        background-color: rgba(0, 0, 0, 0.5);
                        -webkit-tap-highlight-color: transparent;
                    }
                    .modal-content{
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        width: 600px;
                        height: 700px;
                        border-radius: 4px;
                        padding: 19px;
                        transform: translate(-50%, -50%);
                        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
                        background-color: #FFFFFF;
                        display: block;
                    }
                    .sync-container{
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: 1fr 0fr 1fr;
                        gap: 20px 24px;
                        grid-template-areas:
                          "."
                          "."
                          ".";
                          height: 90%;
                    }
                    .footer-details{
                        display: flex;
                        align-items: center;
                        margin: 0px 0px 16px 0px;
                    }
                    .title-text-modal{
                        font-size: 15px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 24px;
                        margin: 0px 0px 8px 0px;
                    }
                    .title-header{
                        text-align: center;
                        font-size: 18px;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 24px;
                        color: #333333;
                    }
                    .selection-container{
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .selection-smart-locks{
                        display: flex;
                        align-items: center;
                        margin: 0px 0px 4px 0px;
                    }
                    .btn-footer{
                        display: flex;
                        justify-content: center;
                    }
                    .btn-igloo-confirm{
                        height: 32px;
                        width: 213px;
                        border-radius: 2px;
                        background-color: #509D91;
                        color: #FFF;
                        display: block;
                        cursor: pointer;
                    }
                    .valid-timestamp{
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-template-rows: 1fr;
                        gap: 0px 24px;
                        grid-template-areas:
                          ". .";
                    }
                    .igloohome-details{
                        font-size: 13px;
                        letter-spacing: 0;
                        color: #939598;
                        margin: 0px 0px 0px 8px;
                    }
                    .smartLocks-details-selection-container{
                        white-space: nowrap;
                        overflow: scroll;
                        overflow-x: hidden;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default PinModal