import React, { useState, useEffect, useContext} from 'react'
//library
import { useMutation } from "@apollo/react-hooks";

//component
import { GET_ASSET, UPDATE_ASSET } from "../../config/config-graphql/Graphql";
import PairedModal from "./PairedModal"
import UnpairedModal from "./UnpairedModal"
import ListCardDetails from "./ListCardDetails"
import { useQuery } from "@apollo/react-hooks";
import {firebaseContext} from '../../config/config-firebase/FirebaseContext'


const ListCard = ({ data, collapsedAsset, setCollapsedAsset, featureList, fx_changePage, setLiveFilter, setFilterFinal, setPageInd, setSnOpen, setMessage, setSeverity, history, featureDisable }) => {
    const [collapse, setCollapse] = useState(false)
    const [assetFeatureIcons, setAssetFeatureIcons] = useState([])
    const [isPaired, setIsPaired] = useState(false)
    const [pairedAssets, setPairedAssets] = useState({})
    const [pairedAssetFeatureIcons, setPairedAssetFeatureIcons] = useState([])
    const [viewModal, setViewModal] = useState(false)
    const firebaseConfig = useContext(firebaseContext)
    const [mainAssetSelection, setMainAssetSelection] = useState({})
    const [assetListTwo, setAsset] = useState([])  



    const {data: assetData, loading:assetLoading, error:assetError} = useQuery(GET_ASSET, {variables: {filter:{}}})
    
    useEffect(() => {
        if(!assetLoading && assetData.get_assets && assetData.get_assets.assets){
            const assetList = assetData && assetData.get_assets && assetData.get_assets.assets
            setAsset(assetList)
        }
    }, [assetLoading])

    
    const [updateAsset] = useMutation(UPDATE_ASSET, {
        refetchQueries: () => [
            {
                query: GET_ASSET,
                variables: { filter: {} },
            },
        ],
    });

    // useEffect(() => {
    //     console.log(data)
    // }, [])

    useEffect(() => {
        if(collapsedAsset  === data.uuid){
            setCollapsedAsset(data.uuid)
        } else{
            setCollapse(false)
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapsedAsset])
    
    useEffect(() => {
        // console.log(data)
        if(data){
            let _features = new Set();
            data && data.asset_device && data.asset_device.length > 0 && data.asset_device.map(dv => {
                dv.features && dv.features.map(el => {
                    let icon = getFeatureIcon(el);
                    icon && _features.add(icon)
                    return null
                })
                return null
            })

            data && data.asset_device && data.asset_device.length > 0 && data.asset_device.map(dv => {
                dv.SKU && dv.SKU.features && dv.SKU.features.map(el => {
                    // console.log(el)
                    let icon = getFeatureIcon(el)
                    // console.log(icon)
                    icon && _features.add(icon)
                    return null
                })
                return null
            })

            // console.log(_features)
            setAssetFeatureIcons(Array.from(_features))

            if(data.paired_assets && data.paired_assets.length > 0){
                setIsPaired(true)
                setPairedAssets(data.paired_assets[0])
                let paired_features = []
                Array.isArray(data.paired_asset) && data.paired_assets[0].asset_device.map(dv => {
                    dv.features.map(el => {
                        let icon = getFeatureIcon(el);
                        icon && paired_features.push(icon)
                        return null
                    })
                    return null
                })
                setPairedAssetFeatureIcons(paired_features)
            } else{
                setIsPaired(false)
                setPairedAssets({})
                setPairedAssetFeatureIcons([])
            }
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const CollapseCard = (e) => {
        let currently = !collapse
        setCollapse(currently)
        currently && setCollapsedAsset(data.uuid)
        !currently && setCollapsedAsset("")
    }

    const ViewDetails = (e, data) => {
        e.preventDefault(e);
        // console.log(data)
        setPageInd(true)
        // console.log(data)
        setFilterFinal({uuid: data.uuid})
    }

    const getFeatureIcon = (name) => {
        if(featureList){
            let feature = featureList.find(el => el.uuid === name)
            return feature ? feature.uuid : ""     
        } else{
            return null
        }
    }



    const PairingAsset = async (selected) => {
        const updated_asset =[{
            uuid: data.uuid,
            pair_asset: { uuid: selected.value }
        }]
        const response = await updateAsset({ variables: { updated_asset } })
        SwitchModal();
    }

    const UnpairingAsset = async () => {
        const updated_asset =[{
            uuid: data.uuid,
            unpair_asset: { uuid: pairedAssets.uuid }
        }]
        const response = await updateAsset({ variables: { updated_asset } })
        SwitchModal();
    }

    const SwitchModal = () => {
        setViewModal(!viewModal)
    }
    return (
        <div className="overview_card-container">
            <ListCardDetails data={data} featureList={featureList} mainAsset CollapseCard={CollapseCard} collapse={collapse} assetFeatureIcons={assetFeatureIcons} withDetailsButton ViewDetails={ViewDetails} lastInColumn={isPaired ? false : true} isPaired={isPaired} ViewModal={SwitchModal} fx_changePage={fx_changePage} setLiveFilter={setLiveFilter} pairedAssetFeatureIcons={pairedAssetFeatureIcons} setPageInd={setPageInd} setFilterFinal={setFilterFinal} history={history} setMainAssetSelection={setMainAssetSelection} featureDisable={featureDisable}/>               
            <PairedModal open={!isPaired && viewModal} fx_close={SwitchModal} fx_pairAsset={PairingAsset} mainAsset={data} details={firebaseConfig.getValueByKey("client_pair_asset_popup")} mainAssetSelection={mainAssetSelection} assetListTwo={assetListTwo} detailsPage={false}/>
            <UnpairedModal open={isPaired && viewModal} fx_close={SwitchModal} fx_unpair={UnpairingAsset} />
            <style>
                {
                    `.overview_card-container{
                       display: flex;
                        flex-direction: column;
                        margin-bottom: 6px;
                        overflow: hidden;
                        transition: height 600ms ease-out;
                    }
                    .card-container{
                        display: flex;
                    }
                    .column-details{
                        flex-direction: column;
                    }
                    .img-feature{
                        margin: 0px 8px 0px 0px;
                    }
                    .feature-icon-list{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .asset-container{
                        display: grid;
                        grid-template-columns: 1fr 10px 1fr 10px 1fr 43px;
                        grid-template-rows: 1fr;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". . . . . .";
                        width: 100%;
                        background-color: #FFFFFF;
                        // box-shadow: 0px 3px 8px rgba(0,0,0,0.2);
                        cursor: pointer;
                        border-radius: 4px;

                    }
                    .paired-borderline{
                        background-color: #FFFFFF;
                        box-shadow: -3px 5px 5px -3px rgba(0,0,0,0.2);
                    }
                   
                    .checkbox-container{
                        margin: 8px 8px 8px 0px;
                    }
                    .details{
                        display: flex;
                        flex-direction: column;
                        flex-basis: 100%;
                        flex: 1;
                        padding: 8px;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .asset-name{
                        height: 24px;
                        color: #333333;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0;
                        line-height: 21px;
                        margin: 0px 0px 4px 0px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .input-label{
                        height: 16px;
                        width: 172px;
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 16px;
                        flex: 2;
                    }
                    .asset-group-title{
                        margin: 0px 0px 4px 0px;
                    }
                    .asset-description{
                        color: #939598;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                    .line-box{
                        // height: 160px;
                        margin-right: 8px;
                        margin-top: 8px;
                        margin-bottom: 8px;
                        border: 1px solid #DDDDDD;
                    }
                    .divide-bottom{
                        box-sizing: border-box;
                        height: 1px;
                        width: 100%;
                        border: 1px solid #DDDDDD;
                    }
                    .row-label-value{
                        display: flex;
                        align-items: center;
                        margin: 0px 0px 8px 0px;
                    }
                    .row-value{
                        flex: 3;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .details-with-image{
                        display: flex;
                        margin: 0px 0px 8px 0px;
                    }
                    .details-with-image-name{
                        color: #333333;
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 21px;
                        margin: 0px 0px 0px 8px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .details-button-container{
                        display:flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        flex-basis: 3%;
                    }
                    .feature-list{
                        display: flex;
                        flex-direction: column;
                        flex-basis: 100%;
                        flex: 1;
                        padding: 8px 8px 8px 0px;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .button-details{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        border-radius: 0 4px 4px 0;
                        background-color: #509D91;
                        cursor: pointer;
                        width: 43px;
                    }
                    .isHovered-button-details{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        border-radius: 0 4px 4px 0;
                        background-color: #498f84;
                        cursor: pointer;
                        width: 43px;
                        box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                    }
                    .larger-flex-details{
                        flex: 2;
                    }
                    .smaller-flex-details{
                        flex: 1;
                    }
                    .collapsible{
                    }
                    .collapse-on-active{
                        overflow: hidden;
                        transition: all ease 300ms;
                    }
                    .view-more{
                        color: #509D91;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                    }
                    .margin-space{
                        margin: 0px 0px 4px 0px;
                    }
                    .pairing-live_shortcut-container{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: 40px;
                        padding: 0px 16px 0px 16px;
                        border-radius: 0 0 4px 4px;
                        background-color: rgb(147, 149, 152, 0.1);
                        border-radius: 0 4px;
                        margin-bottom:4px;
                        // margin-left: 30px;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .live-icons{
                        display: flex;
                    }
                    .live-icon{
                        margin-right: 24px;
                    }
                    .icon-last{
                        margin-right: 0 !important;
                    }
                    .pairing-icon, .live-icon{
                        display: flex;
                        align-items: center;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    .pairing-label{
                        margin: 0px 0px 0px 8px;
                        color: #509D91;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 16px;

                    }
                    .disabled-paired-label{
                        margin: 0px 0px 0px 8px;
                        color: #939598;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 16px;
                    }
                    .live-shortcut-label{
                        margin: 0px 0px 0px 8px;
                        color: #939598;
                        font-size: 14px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 16px;

                    }
                    .paired-borderline{
                        display: flex;
                        align-items: center;
                        // margin-left: 30px;
                        height: 12px;
                    }
                    .paired-borderline img{
                        margin: 7px;
                    }
                    `
                }
            </style>
        </div>
    )
}

export default ListCard

