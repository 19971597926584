import React from 'react'
import IconNoData from "../../Image/no-data.svg";


const NoReportData = () => {
    return (
        <div style={{ position: "absolute", top: "50%", left: "50%"}}>
            <div style={{textAlign:"center"}}>
                <img loading="lazy" src={IconNoData} alt="No data" style={{alignItems:"center"}} loading="lazy"/>
                <p style={{color:"#939598", fontSize:"24px"}}>No data for the search period</p>
            </div>            
        </div>

    )
}

export default NoReportData
