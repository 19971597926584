import React, { useEffect, useState, useContext } from "react"

//library
import { useQuery } from "@apollo/react-hooks";

//component
import { GET_AVAILABLE_DEVICES, GET_ASSETGROUPS, GET_DEVICES } from "../../config/config-graphql/Graphql";
import {firebaseContext} from '../../config/config-firebase/FirebaseContext'
// import DeleteCard from "../../component/component-deletecard/DeleteCard"
import PairedModal from "./PairedModal"
import UnpairedModal from "./UnpairedModal"
import FormAssetInformation from "./FormAssetInformation"
import FormPairAsset from './FormPairAsset'
import FormGeofencePolicy from './FormGeofencePolicy'
import FormFeatures from './FormFeatures'
import AssignMany from "../../component/component-assignmany/AssignMany";
import FormSmartLockCard from './FormSmartLockCard'
import DeviceAssign from './DeviceAssign'


const DeviceDropdownOption = [ { label: "Orion", value: "Orion" }, { label: "Pulsar", value: "Pulsar"}]


const Form = ({ asset, onUserInput, staticDeviceList=[], staticSmartLockList=[], staticDeviceSmartLock=[], setLiveFilter, fx_changePage,  addDeviceList, setAddDevice, removeDeviceList, setRemoveDevice, featureList, fx_selectAssetGroup, fx_pairAsset, fx_unpair, isAdd=false, setSnOpen, setMessage, setSeverity, setErrMsg, setErrOpen, setErrSevere, history, featureDisable,  mainAssetSelection}) => {
    const { data: dvData, loading: dvLoading, error: dvError } = useQuery(GET_AVAILABLE_DEVICES, { variables: {type: "Telematics"}, });
    const { data: dvSmart, loading: dvLoadSmart, error: dvErrSmart } = useQuery(GET_AVAILABLE_DEVICES, { variables: {type: "Smartlocks"}, });
    const { data: grData, loading: grLoading, error: grError } = useQuery(GET_ASSETGROUPS, { variables: { filter: {} }, });
    const { data: mainDvc, loading: mainDvcLoad, error: mainDvcError } = useQuery(GET_DEVICES, { variables: { filter: {}, type: "Smartlocks" }, });

    // console.log(asset)

    const [isHovered, setIsHovered] = useState({
        name: false,
        type: false,
        asset_group: false,
        model: false,
        manufacturer: false,
        description: false
    })

    // console.log(isHovered)


    let _isPaired = false;
    if(Array.isArray(asset)){
        _isPaired = asset[0].paired_assets && asset[0].paired_assets.length > 0
    } else if(asset){
        _isPaired = asset.paired_assets && asset.paired_assets.length > 0
        
    }
        
    const [staticAvailableDevices, setStaticAvailableDevices] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const [deviceOptions, setDeviceOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([])
    const [isPaired, setIsPaired] = useState(_isPaired)

    const [staticAvailableSmartLocks, setStaticAvailableSmartLocks] = useState([])
    const [smartLocksOptions, setSmartLocksOptions] = useState([])
    const [sLselected, setSLselected] = useState([])
    const [filterType, setFilterType] = useState({})
    const [slDefault, setSlDefault] = useState([])
    const [firstLoadSL, setFirstLoadSL] = useState([])

    const firebaseConfig = useContext(firebaseContext)
    const [setStaticSL, setSetStaticSL] = useState([])

    const [smartlockSchema, setSmartlockSchema] = useState([])

    //device telematics
    useEffect(() => {
        if(dvError){
            // console.log("An error occured")
        }
        else if(dvLoading){
            // console.log("Loading")
        }
        else if(dvData && dvData.get_available_devices){
            // console.log(dvData);
            setStaticAvailableDevices(dvData.get_available_devices.map(x => x.uuid))
            const _availableDevices = [];
            for (let i = 0; i < dvData.get_available_devices.length; i++) {
                let el = dvData.get_available_devices[i];
                let option = { value: el.product_model, label: `${el.product_model}(${(dvData.get_available_devices.filter(e => e.product_model === "Orion").length)})` }
                if(!_availableDevices.find(x => x.value === option.value )){
                    _availableDevices.push(option)
                }
            }
                      
            const selectedDvOptions = staticDeviceList.map(el => { return { value: el.uuid, label: el.sn, option: el.SKU ? el.SKU.product_model : null }})
            // const _selected = selectedDvOptions && selectedDvOptions.map(el => el.value)

            let options = dvData.get_available_devices ? dvData.get_available_devices.map(el => { return { value: el.uuid, label: el.sn, option: el.product_model}}) : []
            selectedDvOptions ? setDeviceOptions([ ...options, ...selectedDvOptions ]) : setDeviceOptions(options)

        } else{
            const selectedDvOptions = asset[0] && asset[0].asset_device.map(el => { return { value: el.uuid, label: el.sn, option: el.SKU ? el.SKU.product_model : null }})
            // const _selected = selectedDvOptions && selectedDvOptions.map(el => el.value)
            selectedDvOptions ? setDeviceOptions([ ...selectedDvOptions ]) : setDeviceOptions([])

        }
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dvLoading])

    // !dvLoadSmart && console.log(dvSmart)


    //device smartlocks
    useEffect(() => {
        if(dvErrSmart){
            // console.log("An error occured")
        }
        else if(dvLoadSmart){
            // console.log("Loading")
        }
        else if(dvSmart && dvSmart.get_available_devices){
            // console.log(dvData);
            setStaticAvailableSmartLocks(dvSmart.get_available_devices.map(x => x.uuid))
            const _availableDevices = [];
            for (let i = 0; i < dvSmart.get_available_devices.length; i++) {
                let el = dvSmart.get_available_devices[i];
                let option = { value: el.uuid, label: `${el.product_model}` }
                if(!_availableDevices.find(x => x.value === option.value )){
                    _availableDevices.push(option)
                }
            }
                        
            const selectedDvOptions = staticDeviceSmartLock.map(el => { return { value: el.uuid, label: el.sn, option: null}})
            // const _selected = selectedDvOptions && selectedDvOptions.map(el => el.value)

            let options = dvSmart.get_available_devices ? dvSmart.get_available_devices.map(el => { return { value: el.uuid, label: el.sn, option: el.product_model}}) : []
            selectedDvOptions ? setSmartLocksOptions([ ...options, ...selectedDvOptions ]) : setSmartLocksOptions(options)

        } else{
            const selectedDvOptions = staticDeviceSmartLock.map(el => { return { value: el.uuid, label: el.sn, option:  null }})
            // const _selected = selectedDvOptions && selectedDvOptions.map(el => el.value)
            selectedDvOptions ? setSmartLocksOptions([ ...selectedDvOptions ]) : setSmartLocksOptions([])

        }
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dvLoadSmart])

    // console.log(staticAvailableSmartLocks)



    useEffect(() => {
        if(!isAdd){
            let deviceType = !isAdd && asset[0].asset_device.filter(x => x.device_type === "Smartlocks")
            setSlDefault(deviceType.map(x => {
                 if(x.smartlockPin && x.smartlockPin.pin){
                     return {sn: x.sn, startDate: x.smartlockPin.startDate, endDate: x.smartlockPin.endDate, pin: x.smartlockPin.pin}
                 }else{
                     return []
                 }
             } )) 
        }

        // console.log(deviceType)
    }, [])

    // console.log("test this data", slDefault)

    useEffect(() => {
        if(!grLoading && !grError && grData){
            const groupList = grData.get_asset_groups;
            let options = groupList && groupList.map(el => { return { value: el.uuid, label: el.name, selected: asset[0] && asset[0].asset_group ? el.uuid === asset[0].asset_group.uuid : false}})
            setGroupOptions(options)
        }
        //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grLoading])

    //open popup modal for pair and unpair asset
    const OpenModal = () => {
        setOpenModal(!openModal)
    }

    const isBulkEdit = Array.isArray(asset) && asset.length > 1;

    //to store the pairing asset value into state
    const PairingAsset = (selectedValue, fx_closeModal) => {
        fx_pairAsset(selectedValue, fx_closeModal)
        setIsPaired(true)
    }


    //to store the unPair asset value into state
    const UnpairAsset = (fx_closeModal) => {
        fx_unpair(fx_closeModal);
        setIsPaired(false)
    }

    useEffect(() => {
        if(addDeviceList){
            
            
            let combine = deviceOptions.concat(smartLocksOptions)
            onUserInput(null, { add_devices: addDeviceList.map(x => {
                let details = combine.find(el => el.value === x)
                return { uuid: x }

            })})


            // console.log(staticDeviceList)

            //This could be a temporary code to handle the issue where the backend API is 
            //overriding ALL changes made, based on the "add_device" field

            // if(staticDeviceList.length > 0 || staticSmartLockList.length > 0){
            //     //staticDeviceList & staticSmartLockList are the assign devices
            //     //concatenating both list together
            //     const staticTelematics = staticDeviceList.map(x => x.uuid)
            //     const staticSmartlocks = staticSmartLockList.map(x => x.uuid)
            //     const staticDevices = staticTelematics.concat(staticSmartlocks)
                
            //     //concatenating newly added devices
            //     const addedDevices = staticDevices.concat(addDeviceList)
            
            //     //filtering agains remove device list
            //     const updatedDevices = addedDevices.filter(x => !removeDeviceList.includes(x))
            //     setSmartlockSchema(updatedDevices) //this to send dv list to formSmartlockCard
    
            //     onUserInput(null, { add_devices: updatedDevices.map(x => {
            //         return { uuid: x, label:x.label}
            //     })})
            // }else{
            //     onUserInput(null, { add_devices: addDeviceList.map(x => {
            //         return { uuid: x, label:x.label}
            //     })})
            // }

        }
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [addDeviceList])

    //    console.log(addDeviceList)

    // useEffect(() => {
    //     if(addDeviceList){
    //         onUserInput(null, { add_devices: addDeviceList.map(x => { 
    //             let details = deviceOptions.find(el => el.value === x)
    //             return { uuid: x, sn: details.label }
    //         })})
    //     }
    //             //---this is to disable the warning for useEffect----//
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, [addDeviceList])

    
      useEffect(() => {
        if(removeDeviceList){
            let combine = deviceOptions.concat(smartLocksOptions)
            onUserInput(null, { remove_devices: removeDeviceList.map(x => { 
                let details = combine.find(el => el.value === x)
                return { uuid: x}
            })})
        }
                //---this is to disable the warning for useEffect----//
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [removeDeviceList])


    return (
        <div>
            <FormAssetInformation asset={asset} setLiveFilter={setLiveFilter} fx_changePage={fx_changePage} isBulkEdit={isBulkEdit}  groupOptions={groupOptions} fx_selectAssetGroup={fx_selectAssetGroup} onUserInput={onUserInput} isAdd={isAdd} details={firebaseConfig.getValueByKey("client_asset_information")} setIsHovered={setIsHovered} isHovered={isHovered} history={history} featureDisable={featureDisable}/>
            <div className="form-asset-device-pair-container">
                <DeviceAssign 
                    title="Device Assignment"
                    staticAvailableList={staticAvailableDevices}
                    staticAssignedList={staticDeviceList.map(x => x.uuid)}
                    description={firebaseConfig.getValueByKey("client_device_assignment")}
                    optionList={deviceOptions}
                    setRemoveList={setRemoveDevice}
                    removeList={removeDeviceList}
                    addList={addDeviceList}
                    setAddList={setAddDevice}
                    isAdd={isAdd}
                />
                <FormPairAsset asset={asset} fx_OpenModal={OpenModal} isPaired={isPaired} details={firebaseConfig.getValueByKey("client_pair_asset")} featureDisable={featureDisable}/>
            </div>
            <FormSmartLockCard 
                asset={asset} 
                isAdd={isAdd} 
                dvLoading={dvLoading} 
                device={smartLocksOptions}
                staticDeviceSmartLock={staticDeviceSmartLock}
                staticAvailableList={staticAvailableSmartLocks}
                staticAvailableSmartLocks={staticAvailableSmartLocks} 
                add_smartLocks={addDeviceList}
                setAdd_smartLocks={setAddDevice}
                remove_smartLocks={removeDeviceList}
                setRemove_smartLocks={setRemoveDevice}
                isSmartLocks={true} 
                setSLselected={setSLselected}
                sLselected={sLselected}
                setSlDefault={setSlDefault}
                slDefault={slDefault}
                setFirstLoadSL={setFirstLoadSL}
                firstLoadSL={firstLoadSL}
                staticSmartLockList={staticSmartLockList} 
                setStaticAvailableSmartLocks={setStaticAvailableSmartLocks} 
                smartlockSchema={smartlockSchema}
                setSnOpen={setSnOpen} 
                setMessage={setMessage} 
                setSeverity={setSeverity} 
                setErrMsg={setErrMsg} 
                setErrOpen={setErrOpen} 
                setErrSevere={setErrSevere}
                removeDeviceList={removeDeviceList}
                featureDisable={featureDisable}
            />

            {
                !isAdd && (
                    <div className="third-box-container">
                        <div className="first-sub-container">
                            <FormGeofencePolicy asset={asset} isPaired={isPaired} details={firebaseConfig.getValueByKey("client_asset_geofence")}/>
                        </div>
                        <div className="second-sub-container">
                            <FormFeatures asset={asset} featureList={featureList} isPaired={isPaired} details={firebaseConfig.getValueByKey("client_asset_feature")}/>
                            {/* {
                                featureDisable ? null : (
                                <div className="delete-device-container">
                                    <DeleteCard 
                                        title="Delete Asset" 
                                        fx_delete={fx_delete} 
                                        query={{deleteQuery: DELETE_ASSET, getQuery: GET_ASSET}}
                                        fx_back={fx_back} 
                                    />
                                </div>
                                )
                            } */}

                        </div>
                    </div>                    
                )
            }

            <PairedModal open={!isPaired && openModal} fx_close={() => setOpenModal(!openModal)} fx_pairAsset={PairingAsset} mainAsset={Array.isArray(asset) ? asset[0] : asset} details={firebaseConfig.getValueByKey("client_pair_asset_popup")} mainAssetSelection={mainAssetSelection} detailsPage={true}/>
            <UnpairedModal open={isPaired && openModal} fx_close={() => setOpenModal(!openModal)} fx_unpair={UnpairAsset} details={firebaseConfig.getValueByKey("client_unpair_asset_popup")}/>
        <style>
            {
                `
                .asset-title{
                    color: #333333;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 16px;
                    margin: 0px 0px 8px 0px;

                }
                .first-box-container{               
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    padding: 16px;
                    margin: 0px 0px 24px 0px;
                }
                .asset-input{
                    display: flex;
                }
                .asset-container{
                    width: 100%;
                    margin: 0px 16px 0px 0px;
                }
                .input-input{
                    display: flex;
                    align-items: center;
                }
                .text-box{
                    border-style: none;
                    height: 24px;
                    outline: none;
                    border-radius: 4px;
                }
                .label-box{
                    display:flex;
                    align-items:center;
                    border-style: none;
                    height: 100%;
                    outline: none;
                    border-radius: 4px;
                }
                .isHovered-border{
                    height: 24px;
                    outline: none;
                    border: 1px solid #b6babf;
                    border-radius: 4px;
                  }
                .device-details{
                    height: 32px;
                    width: 100%;
                    color: #939598;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 16px;
                    margin: 0px 0px 0px 32px;
                }
                .details-input-asset{
                    display: grid;
                    grid-template-columns: 150px 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    margin: 0px 0px 8px 0px;
                    height: 30px;
                }
                .asset-device-management{
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    margin: 24px 0px 0px 0px;
                    padding: 16px;
                }
                .link-asset-group{
                    height: 24px;
                    color: blue;
                    text-decoration: underline;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                }
                .title{
                    margin: 0px 0px 4px 0px;
                    height: 21px;
                    color: #333333;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 21px;
                }
                .title-detail{
                    color: #939598;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 16px;
                }
                .ddl-container{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 1% 0px;
                    grid-template-areas: ". .";
                }
                .ddl-available-assigned{
                    margin: 0px 0px 0px 27px;
                }
                .available{
                    display: flex;
                    align-items: center;
                    margin: 16px 0px 8px 0px;
                }
                .selection{
                    box-sizing: border-box;
                    height: 32px;
                    width: 372px;
                    border: 1px solid #939598;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                }
                .dual-list-box{
                    margin: 16px 0px 0px 0px;
                }
                .asset-icon{
                    margin: 0px 8px 0px 0px;
                }
                .custom-properties{
                    padding: 16px 16px 16px 16px;
                    height: 137px;
                    // width: 804px;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    margin: 0px 24px 0px 0px;
                }
                .third-box{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas:
                      ". .";
                    margin: 16px 0px 0px
                }
                .delete-container{
                    height: 137px;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    padding: 16px;
                }
                .selection-board{
                    border-style:none;
                    background-color: #FFF;
                    height: 24px;
                    width: 30%;
                    margin-bottom: 8px;
                    outline: none;
                }
                .asset-paired-asset{
                    display: grid;
                    grid-template-columns: 4fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 0px;
                    grid-template-areas: ". .";
                    border-radius: 4px;
                    background-color: #FFFFFF;
                }
                .asset-paired-asset-input{
                    padding: 16px;
                }
                .asset-paired-asset-details{
                    margin: 0px 0px 16px 0px;
                }
                .paired-asset-title{
                    color: #F5A623;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 21px;
                }
                .paired-asset-detail{
                    color: #939598;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 16px;
                }
                .asset-text{
                    color: #939598;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                }
                .pair-asset-icon{
                    display: flex;
                    align-items: center;

                }
                .pair-asset-icon-text{
                    margin: 0px 0px 0px 8px;
                    color: #509D91;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 16px;
                }
                .onhover-container{
                    display:flex;
                    justify-content: flex-end;
                    width: 100%;
                }
                .button-edits{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 43px;
                    height: 134px;
                    border-radius: 0 4px 4px 0;
                    background-color: #509D91;
                    cursor: pointer;
                }
                .isHovered-btn-edits{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 43px;
                    height: 134px;
                    border-radius: 0 4px 4px 0;
                    background-color: #498f84;
                    box-shadow: 5px 5px 5px -3px rgba(0,0,0,0.2);
                    cursor: pointer;
                }
                .third-box-container{
                    margin: 16px 0px 0px 0px;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr;
                    gap: 0px 24px;
                    grid-template-areas:
                      ". .";
                }
                .first-sub-container{

                }
                .second-sub-container{

                }
                .feature-container{
                    padding: 16px;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    margin: 0px 0px 16px 0px;
                }
                .smart-lock-container{
                    padding: 16px;
                    margin: 16px 0px 0px 0px;
                    border-radius: 4px;
                    background-color: #FFFFFF;
                }
                .geofence-policy-container{
                    padding: 16px;
                    border-radius: 4px;
                    background-color: #FFFFFF; 
                }
                .delete-device-container{
                    border-radius: 4px;
                    background-color: #FFFFFF;
                    // margin: 16px 0px 0px 0px;
                    padding: 16px;
                }
                .policy-name{
                    margin: 16px 0px 0px 0px;
                    color: #424242;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                }
                .divider{
                    margin: 16px 0px 8px 0px;
                    border-bottom: 1px solid #979797;
                }
                .other-paired-asset{
                    color: #F5A623;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 16px;
                }
                .policy-names{
                    margin: 8px 0px 0px 0px;
                    color: #424242;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px; 
                }
                .feature-img{
                    margin: 8px 0px 0px 0px;
                    display: flex;
                    flex-direction: column;
                }
                .total-add{
                    color: #286551;
                    font-size: 14ppx;
                    line-height: 19px;
                    margin: 0 8px;
                }
                .total-remove{
                    color: #EA2B1F;
                    font-size: 14ppx;
                    line-height: 19px;
                    margin: 0 8px;
                }
                input[type=text] {
                    background: transparent;
                    border: none;
                    outline: none;
                }
                .form-asset-device-pair-container{
                    display: grid; 
                    grid-template-columns: 1fr 1fr; 
                    grid-template-rows: 1fr; 
                    gap: 0px 16px; 
                    grid-template-areas: 
                      ". ."; 
                }
                `
            }
        </style>
        </div>
    );
};

export default Form
