import React, { useContext, useState } from 'react'
import Map from './Map'
import { useMediaQuery } from 'react-responsive';

import { WebsocketContext } from "../../config/config-websocket/Websocket";
import AssetListing from './AssetListing';
import MapSearch from './MapSearch';

const PanelLive = ({ setFilter ,filter, fx_changePage, size, history }) => {
    const isDefault = useMediaQuery({ minWidth: 1025 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    const [searchValue, setSearchValue] = useState({})
    const [groupName, setGroupName] = useState("")
    const [viewGroup, setViewGroup] = useState(false)
    const [searchVariables, setSearchVariables] = useState("")
    const [assetSelection, setAssetSelection] = useState(null);
    const payloadContext = useContext(WebsocketContext);
    const { view_asset_card, view_asset_group_card, selected_asset_group} = payloadContext;
    // console.log(size);
    return (
        <div>
          {
            isDefault && (
              <div className="live-container">
                <div className="live-map" style={{height: `${size}px`}}>
                    <Map filter={filter} setSearchValue={setSearchValue} />  
                </div>
                <div className="live-list" style={{height: `${size}px`}}>
                    <AssetListing
                      selected_asset_group={selected_asset_group}
                      view_asset_group_card={view_asset_group_card}
                      view_asset_card={view_asset_card}
                      fx_changePage={fx_changePage}
                      setFilter={setFilter}
                      filter={filter}
                      history={history}
                      setSearchValue={setSearchValue}
                      searchValue={searchValue}
                      setGroupName={setGroupName}
                      setViewGroup={setViewGroup}
                      setSearchVariables={setSearchVariables}
                      setAssetSelection={setAssetSelection}
                      assetSelection={assetSelection}
                      searchVariables={searchVariables}
                      viewGroup={viewGroup}
                    />
                </div>
              </div>
            )
          }
          {
            isMobile && (
              <div className="live-container-mobile" style={view_asset_card ? {
                height: `${size}px`,
                display: "grid",
                gridAutoColumns: "1fr", 
                gridTemplateColumns: "1fr", 
                gridTemplateRows: "1fr 280px", 
                gap: "0px 0px", 
              } : {
                height: `${size}px`,
                display: "grid",
                gridAutoColumns: "1fr", 
                gridTemplateColumns: "1fr", 
                gridTemplateRows: "1fr 113px",
                gap: "0px 0px", 
              }}>
                <div className="live-map">
                  {/* this is for map */}
                  <div className="textfield-map-mobile">
                    <MapSearch viewGroup={viewGroup} setViewGroup={setViewGroup} searchValue={searchValue} setSearchValue={setSearchValue} searchVariables={searchVariables} setSearchVariables={setSearchVariables} setGroupName={setGroupName} assetSelection={assetSelection} setAssetSelection={setAssetSelection} />
                  </div>
                    <Map filter={filter} setSearchValue={setSearchValue} />  
                </div>
                <div className="live-list-mobile">
                  {/* this is for assetlisting */}
                    <AssetListing
                     selected_asset_group={selected_asset_group}
                     view_asset_group_card={view_asset_group_card}
                     view_asset_card={view_asset_card}
                     fx_changePage={fx_changePage}
                     setFilter={setFilter}
                     filter={filter}
                     history={history}
                     setSearchValue={setSearchValue}
                     searchValue={searchValue}
                     setGroupName={setGroupName}
                     setViewGroup={setViewGroup}
                     setSearchVariables={setSearchVariables}
                     setAssetSelection={setAssetSelection}
                     assetSelection={assetSelection}
                     searchVariables={searchVariables}
                     viewGroup={viewGroup}
                    />
                </div>
              </div>
            )
          }
          {
            isTablet && (
              <div className="live-container-mobile" style={view_asset_card ? {
                height: `${size}px`,
                display: "grid",
                gridAutoColumns: "1fr", 
                gridTemplateColumns: "1fr", 
                gridTemplateRows: "1fr 280px", 
                gap: "0px 0px", 
              } : {
                height: `${size}px`,
                display: "grid",
                gridAutoColumns: "1fr", 
                gridTemplateColumns: "1fr", 
                gridTemplateRows: "1fr 113px",
                gap: "0px 0px", 
              }}>
                <div className="live-map">
                  {/* this is for map */}
                    <Map filter={filter} setSearchValue={setSearchValue} />  
                </div>
                <div className="live-list-mobile">
                  {/* this is for assetlisting */}
                    <AssetListing
                      selected_asset_group={selected_asset_group}
                      view_asset_group_card={view_asset_group_card}
                      view_asset_card={view_asset_card}
                      fx_changePage={fx_changePage}
                      setFilter={setFilter}
                      filter={filter}
                      history={history}
                      setSearchValue={setSearchValue}
                      searchValue={searchValue}
                      setGroupName={setGroupName}
                      setViewGroup={setViewGroup}
                      setSearchVariables={setSearchVariables}
                      setAssetSelection={setAssetSelection}
                      assetSelection={assetSelection}
                      searchVariables={searchVariables}
                      viewGroup={viewGroup}
                     />
                </div>
              </div>
            )
          }
            <style>
                {
                    `
                      .live-container{
                        display: grid;
                        grid-template-columns: 4fr 468px;
                        gap: 0px 0px;
                        grid-template-areas:
                          ". .";
                      }
                      .live-container-mobile {
                        grid-template-areas: 
                          "."
                          "."; 
                      }
                      .textfield-map-mobile {
                        position: absolute;
                        top: 48px;
                        left: 0;
                        right: 0;
                        width: 95%;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 16px;
                        text-align: center;
                        background-color: #FFFFFF;
                        z-index: 46;
                      }
                      .live-map{
                        height: 100%;
                      }
                      .live-list{
                        position: relative;
                        right: 0px;
                        background-color: #FFFFFF;  
                        overflow: scroll;   
                      }
                      .live-list-mobile{
                        background-color: #FFFFFF;  
                        overflow: scroll;
                        position: relative;
                        bottom: 0;
                        box-shadow: 0px -4px 4px -1px #00000050;
                      }
                   `
                }
            </style>
        </div>
    )
}

export default PanelLive
